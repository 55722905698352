import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Divider } from '@mui/material'
import { ROUTES } from '../../../../constants'
import Button from 'components/Shared/Button/Button'
import PasswordRules from 'components/Shared/PasswordRules/PasswordRules'
import IOCustomForm from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import useProfileForm from './useProfileForm'
import './styles.scss'

const ProfileForm = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const {
    name,
    lastName,
    email,
    emailCobza1,
    emailCobza2,
    newPassword,
    isFormValid,
    updateInfoMutation,
    updatePassMutation,
    setName,
    setLastName,
    setEmail,
    setEmailCobza1,
    setEmailCobza2,
    setCurrentPassword,
    setNewPassword,
    setValidRules,
    setConfirmPassword,
    onSubmit,
  } = useProfileForm()

  return (
    <article className="profile-form-component">
      <IOCustomForm onSubmit={onSubmit} reset>
        <div className="section info">
          <p>Información general</p>
          <div className="form-row">
            <TextField
              name="name"
              label="Nombre(s)"
              value={name}
              onChange={(v: string) => {
                setName(v)
              }}
              inputProps={{ maxLength: 100 }}
              fullWidth
              required
            />
            <TextField
              name="lastname"
              label="Apellidos"
              value={lastName}
              onChange={(v: string) => {
                setLastName(v)
              }}
              inputProps={{ maxLength: 100 }}
              fullWidth
              required
            />
            <TextField
              name="email"
              label="Email"
              fieldType="email"
              value={email}
              onChange={(v: string) => setEmail(v)}
              inputProps={{ maxLength: 100 }}
              fullWidth
              required
            />
          </div>
          <div className="form-row">
            <TextField
              name="emailCobza1"
              label="Email Cobranza"
              fieldType="email"
              value={emailCobza1}
              onChange={(v: string) => setEmailCobza1(v)}
              inputProps={{ maxLength: 100 }}
              fullWidth
              required
            />
            <TextField
              name="emailCobza2"
              label="Email Otro"
              fieldType="email"
              validate={['isEmail', 'isEmailFormat']}
              value={emailCobza2}
              onChange={(v: string) => setEmailCobza2(v)}
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
          </div>
        </div>
        <div className="section password">
          <div className="title-block">
            <p>Contraseña</p>
            <span>Ingresa tu contraseña actual para crear una nueva.</span>
          </div>
          <div className="form-content">
            <div className="form-block inputs">
              <TextField
                name="currentPassword"
                label="Tu contraseña actual"
                fieldType="password"
                onChange={(v: string) => setCurrentPassword(v)}
                inputProps={{ maxLength: 20 }}
                fullWidth
                required
              />
              <TextField
                name="newPassword"
                label="Tu nueva contraseña"
                fieldType="password"
                onChange={(v: string) => setNewPassword(v)}
                inputProps={{ maxLength: 20 }}
                fullWidth
              />
              <TextField
                name="cNewPassword"
                label="Confirma tu nueva contraseña"
                fieldType="password"
                onChange={(v: string) => setConfirmPassword(v)}
                inputProps={{ maxLength: 20 }}
                fullWidth
              />
            </div>
            <div className="form-block rules">
              <PasswordRules
                variant="large"
                password={newPassword}
                onValidity={(v: boolean) => setValidRules(v)}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="actions">
          <Button
            variant="hover"
            color="secondary"
            onClick={() => navigate(ROUTES.HOME)}
            disabled={
              updateInfoMutation.isLoading || updatePassMutation.isLoading
            }
            disableRipple>
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            className="save-profile"
            variant="contained"
            type="submit"
            color="secondary"
            activity={
              updateInfoMutation.isLoading || updatePassMutation.isLoading
            }
            disabled={!isFormValid}
            disableRipple>
            Guardar cambios
          </Button>
        </div>
      </IOCustomForm>
    </article>
  )
}

export default ProfileForm
