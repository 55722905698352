import { IPaymentMethodTab, TPaymentMethods } from 'models/payments'
import './styles.scss'

interface IPaymentMethodTabProps {
  tab: IPaymentMethodTab
  handleSelectPaymentMethod: (paymentMethod: TPaymentMethods) => () => void
}

const PaymentMethodTab = ({
  tab: { icon: Icon, label, disabled, target },
  handleSelectPaymentMethod,
}: IPaymentMethodTabProps) => {
  const disabledClassName = disabled ? 'disabled' : ''

  return (
    <button
      className={`payment-method-tab-component ${disabledClassName}`}
      disabled={disabled}
      onClick={handleSelectPaymentMethod(target)}>
      <Icon color={disabled ? '#cfcfcf' : undefined} />
      <span className={disabledClassName}>{label}</span>
    </button>
  )
}

export default PaymentMethodTab
