import { useRef, useState } from 'react'
import _ from 'lodash'
import { Box, Button, ButtonProps, Typography } from '@material-ui/core'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { CONCILIATION_ACCEPTED_FORMATS } from '../../../constants'
import { CONCILIATION_CFDI_ACCEPTED_FORMATS } from '../../../constants'
import { CONCILIATION_LEDGER_ACCEPTED_FORMATS } from '../../../constants'
import useDialog from 'hooks/Shared/useDialog'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './styles.scss'

type ActionType = {
  label: string
  onClick: () => void
  props?: ButtonProps
}

interface OptionsProps {
  accept?: string
  multiple?: boolean
  maxSize?: number
  maxFiles?: number
}

export const verifyFiles = (files: FileList, options: OptionsProps) => {
  if (!files.length) return 'No se seleccionaron archivos'
  if (options.accept) {
    const accept = options.accept.split(',').map(type => type.trim())
    const filesTypes = _.map(files, file => file.type)
    const isValid = _.every(filesTypes, fileType =>
      _.includes(accept, fileType)
    )

    if (!isValid) {
      if (options.accept === CONCILIATION_ACCEPTED_FORMATS) {
        return 'Formato de archivo no válido. Solo se aceptan estados de cuenta en formato PDF'
      }
      if (options.accept === CONCILIATION_CFDI_ACCEPTED_FORMATS) {
        return 'Formato de archivo no válido. Solo se aceptan CFDIs en formato XML'
      }
      if (options.accept === CONCILIATION_LEDGER_ACCEPTED_FORMATS) {
        return 'Formato de archivo no válido. Solo se aceptan archivos de cuentas contables en formato XLS'
      }
      return `Formato de archivo no válido.`
    }
  }
  if (options.maxSize) {
    const filesSize = _.map(files, file => file.size)
    const isValid = _.every(
      filesSize,
      fileSize => fileSize <= Number(options.maxSize)
    )
    if (!isValid) {
      if (options.multiple) {
        const oversized = _.filter(
          filesSize,
          fileSize => fileSize > Number(options.maxSize)
        )
        if (oversized.length) {
          const names = oversized.reduce((acc, fileSize, index) => {
            const file = files[index]
            if (acc.length) {
              return `${acc}, "${file.name}"`
            } else {
              return `"${file.name}"`
            }
          }, '')
          return `Los siguientes archivos exceden el límite: ${names}. El tamaño máximo es de 10MB por archivo.`
        } else {
          return 'Algunos archivos son demasiado grandes, el tamaño máximo es de 10MB'
        }
      }
      return 'Archivo demasiado grande, el tamaño máximo es de 10MB'
    }
  }
  if (options.maxFiles !== undefined) {
    if (files.length > options.maxFiles && options.multiple === true) {
      return `Solo se pueden subir ${options.maxFiles} archivos simultaneamente`
    }
  }
  return null
}

export interface IDropzoneProps {
  title?: string
  subtitle?: string
  onChange?: (files: FileList) => void
  actions?: ActionType[]
  className?: string
  style?: React.CSSProperties
  buttonLabel?: string
  buttonProps?: ButtonProps
  options?: OptionsProps
  isAccountMovement?: boolean
}

// forwardRef

const Dropzone = ({
  title = 'Arrastra y suelta un archivo',
  subtitle = 'o selecciona un archivo',
  onChange,
  actions,
  className = '',
  style,
  buttonLabel = 'Seleccionar',
  buttonProps,
  options = {
    accept: '',
    multiple: true,
    maxSize: 0,
    maxFiles: 0,
  },
  isAccountMovement,
}: IDropzoneProps) => {
  const { toggleAccountMovementsModal } = useConciliationsContainerContext()

  const dialog = useDialog()

  const [error, setError] = useState('')
  const [isDragging, setIsDragging] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputRef.current?.click()
  }

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.files = null
    }
  }

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const verify = verifyFiles(files as FileList, options)
    if (verify) {
      await dialog.primaryAsync({
        title: 'Hay un problema',
        subtitle: verify,
        primaryButtonText: 'Continuar',
      })
      setError(verify)
      clearInput()
      setTimeout(() => {
        setError('')
      }, 3000)
    } else {
      clearInput()
      if (onChange) onChange(files as FileList)
    }
  }

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (files.length) {
      const synteticEvent = {
        target: {
          files,
        },
      }
      handleOnChange(synteticEvent as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    setError('')
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  return (
    <Box
      className={`dropzone-component ${isDragging ? 'dragging' : ''} ${className}`}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handleOnDrop}
      style={style}>
      <input
        type="file"
        id="file-input"
        ref={inputRef}
        onChange={handleOnChange}
        accept={options.accept}
        multiple={options.multiple}
      />
      <Box textAlign="center">
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {subtitle}
        </Typography>
      </Box>
      {error && <Box className="error-message">{error}</Box>}
      <Box display="flex" gridGap={10} flexDirection={'column'}>
        {isAccountMovement ? (
          <>
            <Button
              onClick={handleClick}
              variant="outlined"
              className="dropzone-button"
              {...buttonProps}>
              <FileUploadIcon fontSize="large" />
              <hr />
              <div className="legend">
                <h1>Cargar PDF</h1>
                <p>
                  Para estados de cuenta de bancos que permiten procesado
                  automático.
                </p>
              </div>
            </Button>
            <Button
              onClick={toggleAccountMovementsModal}
              variant="outlined"
              className="dropzone-button"
              {...buttonProps}>
              <FileUploadIcon fontSize="large" />
              <hr />
              <div className="legend">
                <h1>Cargar Excel</h1>
                <p>Carga el archivo de Excel con los movimientos bancarios.</p>
              </div>
            </Button>
          </>
        ) : (
          <Button
            onClick={handleClick}
            variant="outlined"
            className="dropzone-button"
            {...buttonProps}>
            {buttonLabel}
          </Button>
        )}
        {_.map(actions, (action, index) => (
          <Button
            key={index}
            onClick={action.onClick}
            variant={action.props?.variant || 'contained'}
            disableElevation={action.props?.disableElevation || true}
            className={action.props?.className || 'dropzone-button-action'}
            {...action.props}>
            {action.label}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

export default Dropzone
