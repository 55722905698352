import { Box } from '@material-ui/core'
import Accountants from 'components/Management/Accountants/Accountants/Accountants'
import AccountantsProvider from 'components/Management/Accountants/AccountantsContextProvider/AccountantsContextProvider'

const AccountantsContainer = () => {
  return (
    <AccountantsProvider>
      <Box marginY={8}>
        <Accountants />
      </Box>
    </AccountantsProvider>
  )
}

export default AccountantsContainer
