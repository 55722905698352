import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStoreEventState, TStoreEvents } from './storeEvent.models'

const initialState: IStoreEventState = {}

const storeEventSlice = createSlice({
  name: 'storeEventSlice',
  initialState: initialState,
  reducers: {
    setStoreEvent(state, action: PayloadAction<TStoreEvents>) {
      state.eventType = action.payload
    },
    resetStoreEvent(state) {
      Object.assign(state, initialState)
    },
  },
})

export const { setStoreEvent, resetStoreEvent } = storeEventSlice.actions

export default storeEventSlice.reducer
