import { SET_MANAGEMENT_ACCOUNTANTS } from 'actions/conciliations';
import { ManagementStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';
import update from 'immutability-helper';

const initialState: ManagementStore = {
  accountants: {
    list: []
  }
};

const defaultAction: ReduxAction<any> = {
  type: '',
  value: {
    name: '',
    entries: []
  }
};

const management = (
  state = initialState,
  action = defaultAction
) => {
  switch (action.type) {
    case SET_MANAGEMENT_ACCOUNTANTS:
      return update(state, { accountants: { list: { $set: action.value} } })
    default:
      return state
  }
};

export default management;