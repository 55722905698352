import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import ConciliationTableFilter from '../ConciliationTableFilter/ConciliationTableFilter'

const LedgerAccountsFilters = () => {
  const { ledgers } = useConciliationsContainerContext()

  const { ledgerFilters, setLedgerFilters } = ledgers

  const dispatch = useDispatch()

  const handleFilterChange = (values: any) => {
    dispatch(
      setLedgerFilters({
        ...ledgerFilters,
        text: values.name,
        date: values.date,
        status: values.status,
      })
    )
  }

  const fields = useMemo(
    () => ({
      name: {
        name: 'name',
        placeholder: 'Buscar por nombre o código',
        enabled: true,
        defaultValue: ledgerFilters.text,
      },
      fromDate: {
        name: 'fromDate',
        enabled: false,
        defaultValue: '',
      },
      toDate: {
        name: 'toDate',
        enabled: false,
        defaultValue: '',
      },
    }),
    [ledgerFilters.text]
  )

  return (
    <ConciliationTableFilter
      onChange={handleFilterChange}
      fields={fields}
      singleton
    />
  )
}

export default LedgerAccountsFilters
