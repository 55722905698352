//import _ from 'lodash';
//import HTTPClient from 'lib/helpers/HTTPClient';
import { httpErrorParser } from 'lib/helpers/utilities';

import { DOCUMENTS_TO_REQUEST_CATALOGUE } from '../constants';

import { ReduxAction } from 'models/thunk';
import RequestedDocument, { RequestedDocumentCatalogue } from 'models/documentRequest';
import { Path } from 'models/documentation';

export const SET_ORIGIN = '@DocumentRequest/SET_ORIGIN';
export const SET_ACTIVE_TAB = '@DocumentRequest/SET_ACTIVE_TAB';
export const SET_MONTH = '@DocumentRequest/SET_MONTH';
export const SET_YEAR = '@DocumentRequest/SET_YEAR';
export const SET_CATALOGUE = '@DocumentRequest/SET_CATALOGUE';
export const SET_INITIAL_LIST = '@DocumentRequest/SET_INITIAL_LIST';
export const SET_SELECTED_LIST = '@DocumentRequest/SET_SELECTED_LIST';
export const ADD_TO_SELECTED_LIST = '@DocumentRequest/ADD_TO_SELECTED_LIST';
export const REMOVE_FROM_SELECTED_LIST = '@DocumentRequest/REMOVE_FROM_SELECTED_LIST';
export const SET_ACCOUNTING_ID = '@DocumentRequest/SET_ACCOUNTING_ID';
export const RESET_DOCUMENT_REQUEST = '@DocumentRequest/RESET_DOCUMENT_REQUEST';

//const api = process.env.REACT_APP_APIURL || "/api/";

//#region Acciones para persistir informacion funcional de Document Request
export const setOrigin = (value: string): ReduxAction<string> => ({
    type: SET_ORIGIN,
    value
})
export const setActiveTab = (value: number): ReduxAction<number> => ({
    type: SET_ACTIVE_TAB,
    value
})
export const setMonth = (value?: number): ReduxAction<number | undefined> => ({
    type: SET_MONTH,
    value
})
export const setYear = (value?: number): ReduxAction<number | undefined> => ({
    type: SET_YEAR,
    value
})
export const setCatalogue = (value: RequestedDocumentCatalogue): ReduxAction<RequestedDocumentCatalogue> => ({
    type: SET_CATALOGUE,
    value
})
export const setInitialList = (value: number[]): ReduxAction<number[]> => ({
    type: SET_INITIAL_LIST,
    value
})
export const setSelectedList = (value: RequestedDocument[]): ReduxAction<RequestedDocument[]> => ({
    type: SET_SELECTED_LIST,
    value
})
export const addToSelectedList = (value: RequestedDocument[]): ReduxAction<RequestedDocument[]> => ({
    type: ADD_TO_SELECTED_LIST,
    value
})
export const removeFromSelectedList = (value: RequestedDocument[]): ReduxAction<RequestedDocument[]> => ({
    type: REMOVE_FROM_SELECTED_LIST,
    value
})
export const setAccountingId = (value: number): ReduxAction<number> => ({
    type: SET_ACCOUNTING_ID,
    value
})
export const setPath = (value: Path[]): ReduxAction<Path[]> => ({
    type: SET_ACCOUNTING_ID,
    value
})
export const resetDocumentRequest = (): ReduxAction<undefined> => ({
    type: RESET_DOCUMENT_REQUEST,
    value: undefined
})
//#endregion

export const getRequestDocumentCatalogue = () => async (dispatch: any): Promise<RequestedDocumentCatalogue> => {
    //const client = HTTPClient.getClient();
    try {
        dispatch(setCatalogue(DOCUMENTS_TO_REQUEST_CATALOGUE));
        return DOCUMENTS_TO_REQUEST_CATALOGUE;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}