import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { toPeriodString } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { getConciliatedTransactionsByPeriod } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

export const useQueryConciliations = () => {
  const {
    workspaceInfo,
    conciliationPeriod,
    conciliations,
    updateLoadingStates,
  } = useConciliationsContainerContext()

  const { setConciliationRows } = conciliations

  const dispatch = useDispatch()
  const dialog = useDialog()

  const queryConciliations = async (
    workspace: number,
    period: { month: number; year: number }
  ) => {
    updateLoadingStates('conciliations', true)
    try {
      const data = await getConciliatedTransactionsByPeriod(
        workspace,
        toPeriodString(period, true)
      )
      updateLoadingStates('conciliations', false)
      const reader = new FileReader()
      reader.onload = () => {
        const text = reader.result as string
        const movements = JSON.parse(text)
        dispatch(
          setConciliationRows(
            _.map(movements, (movement: any, index: number) => ({
              ...movement,
              reference: _.get(movement, 'reference', ''),
              id: _.get(movement, 'idTransaction', index),
              ledger: movement.ledgerAccounts || undefined,
            }))
          )
        )
      }
      reader.readAsText(data)
    } catch (error: any) {
      updateLoadingStates('conciliations', false)
      dispatch(setConciliationRows([]))
      if (error.status === 404) {
        return
      }
      await dialog.dangerAsync({
        subtitle:
          'No se han podido obtener la lista de movimientos conciliados.',
        title: 'generic.error.title',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const refetch = async () =>
    queryConciliations(workspaceInfo.activeWorkspace, conciliationPeriod)

  return {
    queryConciliations,
    refetch,
  }
}

export default useQueryConciliations
