import { useSelector } from 'react-redux'
import ArrowBack from 'assets/arrow_back.svg'
import {
  selectIsRecurringPayment,
  selectSelectedPayment,
} from 'store/Payments/payments.selectors'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import Button from 'components/Shared/Button/Button'
import ConektaForm from '../ConektaForm/ConektaForm'
import './styles.scss'

const SelectedPlanPayment = () => {
  const { billingInformation, goBackToPaymentsOverview } =
    usePaymentsContainerContext()

  const isRecurringPayment = useSelector(selectIsRecurringPayment)
  const selectedPlan = useSelector(selectSelectedPayment)

  return (
    <div className="selected-plan-payment-component">
      <article className="payment-information">
        <div className="header">
          <Button
            startIcon={<img src={ArrowBack} alt="flecha hacia la izquierda" />}
            variant="hover"
            onClick={goBackToPaymentsOverview}>
            Volver
          </Button>
        </div>
        <span>{isRecurringPayment ? 'Domiciliar servicio' : 'Pagando'}</span>
        <div className="payment-info">
          <div>
            <span>{selectedPlan?.name ?? ''}</span>
            <span>[{selectedPlan?.description ?? ''}]</span>
          </div>
          <span>
            {Intl.NumberFormat('es-MX', {
              currency: 'MXN',
              style: 'currency',
            }).format(selectedPlan?.amount ?? 0)}
          </span>
        </div>
        {isRecurringPayment && (
          <p>
            Este servicio se cobrará automáticamente en cada periodo con la
            tarjeta bancaria seleccionada. Puede cancelar o modificar esta
            domiciliación en cualquier momento.
          </p>
        )}
      </article>
      <hr />
      {billingInformation && (
        <article className="payment-form">
          <ConektaForm />
        </article>
      )}
    </div>
  )
}

export default SelectedPlanPayment
