import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import _ from 'lodash'

import { setUserData, setUserRole } from 'actions/user'

import { InformationForm, PasswordForm } from 'models/account'
import { Profile } from 'models/user'
import { ROLES } from '../constants'

const api = process.env.REACT_APP_APIURL || '/api'

export const getProfile =
  (noSave: boolean = false) =>
  async (dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.get(`${api}/profile`)
      const profile: Profile = await petition.json()
      !noSave && dispatch(setUserData(profile))
      return profile
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getRole =
  (noSave: boolean = false) =>
  async (dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.get(`${api}/users/roles`)
      const data = await petition.json()
      const roleId = _.get(data, '0.idRole', 4)
      const role = _.find(ROLES, r => r.id === roleId)

      !noSave && role && dispatch(setUserRole(role))
      return role
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const changeInformation =
  (form: InformationForm) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        name: form.name,
        lastname: form.lastname,
        personsList: [
          {
            address: 'usa',
            birthday: '2021-04-25',
            curp: 'aqui va la curp',
            phone: '123',
            rfc: 'aqui rfc',
          },
        ],
      }
      const petition = await client.put(`${api}/profile`, body)
      const profile: Profile = await petition.json()
      return profile
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const changePassword =
  (form: PasswordForm) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        password: form.oldPassword,
        recoverPassword: form.newPassword,
      }
      const petition = await client.put(`${api}/profile/newPassword`, body)
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }
