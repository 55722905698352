import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  Select,
  TextField,
} from '@material-ui/core'
import { createTransaction, updateTransaction } from 'services/conciliations'
import { OCRContext } from 'containers/Conciliations/OCRProcessContainer/OCRProcessContainer'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { OCRBankStatement, OCRBankStatementMovement } from 'models/redux'
import _ from 'lodash'
import { FC, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

interface TransactionFormFields {
  day: number
  month: number
  year: number
  concept?: string
  rfc?: string
  ordenante?: string
  type: string
  amount: number
}

const TRANSACTION_TYPES = {
  deposit: 'deposit',
  withdrawal: 'withdrawal',
}

const TRANSACTION_LIST = [
  {
    label: 'Depósito',
    value: TRANSACTION_TYPES.deposit,
  },
  {
    label: 'Retiro',
    value: TRANSACTION_TYPES.withdrawal,
  },
]

const schema = object().shape({
  month: number().required().min(1).max(12),
  day: number()
    .required()
    .min(1)
    .max(31)
    .test(
      'day-month',
      'El día no es válido para el mes seleccionado',
      function (value) {
        const { month } = this.parent
        const date = new Date(this.parent.year, month - 1, value)
        return date.getMonth() === month - 1
      }
    ),
  year: number().required().min(2000).max(2050),
  concept: string(),
  rfc: string(),
  ordenante: string(),
  type: string().required(),
  amount: number().required('El monto es requerido'),
})

const MODES = {
  edit: 'edit',
  create: 'create',
}

const OCRTransactionForm: FC<{
  mode: 'edit' | 'create'
}> = ({ mode }) => {
  const { OCR } = useConciliationsContainerContext()
  const { drawer, closeDrawer } = useContext(OCRContext)

  const [isLoading, setIsLoading] = useState(false)

  const transaction = drawer.type.value as OCRBankStatementMovement
  const dialog = useDialog()
  const dispatch = useDispatch()

  const transactionDate = new Date(transaction.date)

  const defaultValues = useMemo(
    () => {
      if (mode === MODES.create) {
        return {}
      }
      return {
        day: transactionDate.getDate(),
        month: transactionDate.getMonth() + 1,
        year: transactionDate.getFullYear(),
        concept: transaction.concept,
        ordenante: transaction.ordBen,
        rfc: transaction.rfcOrdBen,
        amount: transaction.deposit || transaction.withdrawal,
        type: transaction.deposit
          ? TRANSACTION_TYPES.deposit
          : TRANSACTION_TYPES.withdrawal,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // mode,
      // transaction.concept,
      // transaction.deposit,
      // transaction.ordBen,
      // transaction.rfcOrdBen,
      // transaction.withdrawal,
      // transactionDate
    ]
  )

  const methods = useForm<TransactionFormFields>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const errors = methods.formState.errors

  const handleCreateTransaction = async (data: TransactionFormFields) => {
    const statement = drawer.type.value as OCRBankStatement
    const body = {
      idFilesBankStatement: statement.idFileBankStatement,
      date: new Date(data.year, data.month - 1, data.day).toISOString(),
      concept: data.concept || '',
      rfcOrdBen: data.rfc || '',
      ordBen: data.ordenante || '',
      originReference: '',
      deposit:
        data.type === TRANSACTION_TYPES.deposit ? Number(data.amount) : 0,
      withdrawal:
        data.type === TRANSACTION_TYPES.withdrawal ? Number(data.amount) : 0,
    }
    setIsLoading(true)
    try {
      const data = await createTransaction(body)
      const movements = _.cloneDeep(OCR.OCRMovements)
      movements.push(data)
      dispatch(OCR.setOCRMovements(movements))
      setIsLoading(false)
      closeDrawer()
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (value: string) => {
    const number = parseFloat(value)
    if (isNaN(number)) {
      return
    }
    methods.setValue('amount', number)
  }

  const handleSubmit = async (data: TransactionFormFields) => {
    if (mode === MODES.create) {
      return await handleCreateTransaction(data)
    }
    const body = {
      ...transaction,
      date: new Date(data.year, data.month - 1, data.day).toISOString(),
      concept: data.concept || '',
      ordBen: data.ordenante || '',
      rfcOrdBen: data.rfc || '',
    }
    if (transaction.deposit) {
      body.deposit = Number(data.amount)
    }
    if (transaction.withdrawal) {
      body.withdrawal = Number(data.amount)
    }
    if (data.type === TRANSACTION_TYPES.deposit && !transaction.deposit) {
      body.deposit = Number(data.amount)
      body.withdrawal = Number(0)
    } else if (
      data.type === TRANSACTION_TYPES.withdrawal &&
      !transaction.withdrawal
    ) {
      body.deposit = Number(0)
      body.withdrawal = Number(data.amount)
    }
    setIsLoading(true)
    try {
      await updateTransaction(body)
      OCR.updateOCRMovement(body)
      closeDrawer()
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        subtitle:
          'No se ha podido actualizar este movimiento. Inténtalo mas tarde.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        {isLoading && <LinearProgress variant="indeterminate" />}
        <Box padding={3} display="flex" flexDirection="column" gridGap={38}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                {...methods.register('day')}
                label="Día"
                fullWidth
                error={!!errors.day}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...methods.register('month')}
                label="Mes"
                fullWidth
                error={!!errors.month}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...methods.register('year')}
                label="Año"
                fullWidth
                error={!!errors.year}
              />
            </Grid>
          </Grid>
          <Box>
            <Box
              fontSize={12}
              fontWeight={300}
              color="gray.100"
              marginBottom={1}>
              Concepto
            </Box>
            <TextField
              {...methods.register('concept')}
              id="concept"
              placeholder="Agregar un concepto"
              fullWidth
              multiline
              variant="outlined"
              InputProps={{
                rows: 8,
                style: {
                  border: '1px solid #777',
                  padding: '10px 8px',
                },
              }}
            />
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...methods.register('rfc')}
                label="RFC
                ordenante/beneficiario"
                fullWidth
                error={!!errors.rfc}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...methods.register('ordenante')}
                label="Ordenante/beneficiario"
                fullWidth
                error={!!errors.ordenante}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel shrink id="type">
                  Tipo de movimiento
                </InputLabel>
                <Select
                  {...methods.register('type')}
                  id="type"
                  label="Tipo de movimiento"
                  fullWidth
                  native
                  error={!!errors.type}>
                  {_.map(TRANSACTION_LIST, item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                {...methods.register('amount')}
                label="Monto"
                fullWidth
                error={!!errors.amount}
                type="number"
              /> */}
              <NumericField
                {...methods.register('amount')}
                onChange={handleChange}
                fullWidth
                format="money"
                defaultValue={methods.getValues().amount}
                label="Monto"
                error={!!errors.amount}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" width={1}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              type="submit"
              disabled={isLoading}
              className="secondary-alt-button">
              {mode === MODES.create
                ? 'Agregar movimiento'
                : 'Corregir movimiento'}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default OCRTransactionForm
