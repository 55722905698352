import update from 'immutability-helper';
import { 
  SET_FILTER_VALUE,
  RESET_FILTER,
  SET_EMPLOYEES_LIST,
  SET_SELECTED_EMPLOYEE,
  SET_PAGINATION_VALUE
} from "actions/employees";

import { EmployeesStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialEmployees: EmployeesStore = {
  filter: {
    search: "",
  },
  list: [],
  pagination: {
    page: 0,
    size: 10,
    total: 0
  },
  selected: null
};

const defaultAction: ReduxAction<any> = {
  type: "",
  value: ""
}

const employees = (state = initialEmployees, action = defaultAction) => {
  switch (action.type) {
    // #region Filters Form
    case SET_FILTER_VALUE:
      return update(state, {
        filter: {
          [action.value.name]: { $set: action.value.value }
        }
      });
    case SET_PAGINATION_VALUE:
      return update(state, {
        pagination: {
          [action.value.name]: { $set: action.value.value }
        }
      });
    case RESET_FILTER:
      return update(state, { 
        filter: { $merge: { search: initialEmployees.filter.search } } 
      });
    // #endregion
    case SET_EMPLOYEES_LIST:
      return update(state, { list: { $set: action.value } });
    case SET_SELECTED_EMPLOYEE:
      return update(state, { selected: { $set: action.value } });
    default:
      return state
  }
};

export default employees;