import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { ConciliationFilterType } from 'models/redux'
import useDialog from 'hooks/Shared/useDialog'
import { getCFDIsByWorkspace } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const useGetCFDIs = () => {
  const {
    workspaceInfo,
    cfdis,
    conciliations,
    updateTotalElements,
    updateLoadingStates,
    updateLastSync,
  } = useConciliationsContainerContext()

  const { cfdiFilters, setConciliationCfdiRows } = cfdis

  const { displayReconciled } = conciliations

  const dispatch = useDispatch()
  const dialog = useDialog()

  const clearCFDIs = () => {
    dispatch(setConciliationCfdiRows([]))
    updateLastSync('')
  }

  const handleSuccess = (data: any) => {
    if (_.get(data, 'cfdis', []).length === 0) {
      return clearCFDIs()
    }

    dispatch(
      setConciliationCfdiRows(
        _.map(_.get(data, 'cfdis', []), (cfdi, index) => ({
          ...cfdi,
          id: index,
        }))
      )
    )
    const sync = _.get(data, 'lastSinc', '')
    if (sync !== '') {
      updateLastSync(sync)
    } else {
      updateLastSync('')
    }
    updateTotalElements(data.totalElements)
    updateLoadingStates('cfdis', false)
  }

  const handleError = async (error: any) => {
    updateLoadingStates('cfdis', false)
    clearCFDIs()
    if (error.status === 404) {
      if (cfdiFilters.display) {
        return
      }
      return
    }
    await dialog.dangerAsync({
      title: 'generic.error.title',
      subtitle: 'No se han podido obtener la lista de CFDIs.',
      primaryButtonText: 'Continuar',
    })
  }

  const getCFDIs = async (
    workspace: number,
    filters?: ConciliationFilterType,
    showConciliated?: boolean
  ) => {
    updateLoadingStates('cfdis', true)

    let parsedFilters = {
      showConciliated,
    } as any

    if (filters && filters.display) {
      parsedFilters = {
        ...parsedFilters,
        param: filters.text,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        status: filters.status,
      }
    }

    getCFDIsByWorkspace(workspace, parsedFilters)
      .then(handleSuccess)
      .catch(handleError)
  }

  const refetch = async () => {
    await getCFDIs(
      workspaceInfo.activeWorkspace,
      cfdiFilters,
      displayReconciled
    )
  }

  return {
    getCFDIs,
    refetch,
  }
}

export default useGetCFDIs
