import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import { useAddCategory } from 'hooks/queries/categories'

import './style.scss'

import { CategoryForm } from 'models/category'

interface Props {
  workspaceId: number
  onRedirect: (action: string) => void
}

const AddCategoryContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const [valid, setValid] = useState(false)

  const addMutation = useAddCategory(workspaceId, {
    onSuccess: () => onRedirect('success'),
  })

  return (
    <>
      <Card elevation={1} className="container-add-category">
        <Form
          onSubmit={async (form: CategoryForm) => addMutation.mutate(form)}
          // onSubmit={async (form: CategoryForm) => console.log(form)}
          onValidity={(v: boolean) => setValid(v)}>
          <div className="content">
            <p>
              Completa la siguiente información para agregar una nueva categoría
              al catálogo
            </p>
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  label="Nombre"
                  inputProps={{ maxLength: 32 }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={8}>
                <p className="form-label">Descripción</p>
                <TextField
                  variant="outlined"
                  name="description"
                  inputProps={{ maxLength: 500 }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  withCounter
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="addCategoryBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={addMutation.isLoading}
              disabled={!valid}>
              Agregar
            </Button>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default AddCategoryContainer
