import { createContext, useContext, useMemo } from 'react'
import { Grid } from '@mui/material'
import { TConciliationTabs } from 'models/conciliations'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { conciliationTabs } from 'lib/utils/Conciliations/conciliations.utils'
import SydTabs from 'components/Shared/SydTabs/SydTabs'
import AccountStatementHistoryContainer from '../AccountStatementHistoryContainer/AccountStatementHistoryContainer'
import CFDIHistoryContainer from '../CDFDIHistoryContainer/CFDIHistoryContainer'
import ConciliationReportsContainer from '../ConciliationsReportsContainer/ConciliationReportsContainer'
import ConciliationsTablesContainer from '../ConciliationsTablesContainer/ConciliationsTablesContainer'
import OCRProcessContainer from '../OCRProcessContainer/OCRProcessContainer'
import useConciliationsContainer, {
  IUseConciliationsContainer,
} from './useConciliationsContainer'

interface IConciliationsContainerProps {
  isScreenLoading: boolean
  updateScreenLoading: (isLoading: boolean) => void
}

export const ConciliationsContainerContext =
  createContext<IUseConciliationsContainer | null>(null)

const ConciliationsContainer = ({
  isScreenLoading,
  updateScreenLoading,
}: IConciliationsContainerProps) => {
  const conciliationsContainerState = useConciliationsContainer(
    isScreenLoading,
    updateScreenLoading
  )

  const { screen } = conciliationsContainerState

  const { activeTab, handleSetTab } = screen

  const tabs: Record<number, () => JSX.Element> = useMemo(
    () => ({
      0: ConciliationsTablesContainer,
      1: ConciliationReportsContainer,
      2: AccountStatementHistoryContainer,
      3: CFDIHistoryContainer,
      4: OCRProcessContainer,
    }),
    []
  )

  const ActiveTabElement = useMemo(
    () => tabs[activeTab] || (() => <></>),
    [activeTab, tabs]
  )

  const contextValue = useMemo(
    (): IUseConciliationsContainer => conciliationsContainerState,
    [conciliationsContainerState]
  )

  const onChangeTab = (
    _e: React.ChangeEvent<{}>,
    newValue: TConciliationTabs
  ) => handleSetTab(newValue)

  return (
    <ConciliationsContainerContext.Provider value={contextValue}>
      <Grid item xs={12}>
        {(activeTab === CONCILIATION_TABS.CONCILIATIONS ||
          activeTab === CONCILIATION_TABS.REPORTS) && (
          <>
            <div className="title-container">
              <h1>Conciliaciones</h1>
            </div>
            <SydTabs
              tabs={conciliationTabs}
              activeTab={activeTab}
              onChangeTab={onChangeTab}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <ActiveTabElement />
      </Grid>
    </ConciliationsContainerContext.Provider>
  )
}

export const useConciliationsContainerContext = () => {
  const context = useContext(ConciliationsContainerContext)

  if (!context) {
    throw new Error(
      'useConciliationsContainerContext must be used within a ConciliationsContainerContext'
    )
  }

  return context
}

export default ConciliationsContainer
