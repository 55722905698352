import { useCallback } from 'react'
import { getRemindersService, sendReminderService } from 'services/Accounting/accounting.services'
import { IAccountingLocalState } from 'containers/Accounting/AccountingContainer/useAccounting'

interface IUseHandleAccountingRemindersProps {
  workspaceId: number
  accountingReduxId?: number
  updateAccountingState: (
    update:
      | Partial<IAccountingLocalState>
      | ((prevState: IAccountingLocalState) => IAccountingLocalState)
  ) => void
}

interface IUSeHandleAccountingReminders {
  handleGetAccountingReminders: () => Promise<void>
  handleSendAccountingReminders: () => Promise<void>
  handleShowAccountingReminders: (param: boolean) => void
}

const useHandleAccountingReminders = ({
  workspaceId,
  accountingReduxId,
  updateAccountingState,
}: IUseHandleAccountingRemindersProps): IUSeHandleAccountingReminders => {
  const handleGetAccountingReminders = useCallback(async () => {
    updateAccountingState(prevState => ({
      ...prevState,
      accountingHistoryReminders: {
        ...prevState.accountingHistoryReminders,
        isLoading: true,
        isError: false,
      },
    }))

    if (workspaceId && accountingReduxId) {
      try {
        const response = await getRemindersService(
          workspaceId,
          accountingReduxId
        )
        updateAccountingState({
          accountingHistoryReminders: {
            isLoading: false,
            isError: false,
            data: response,
          },
        })
      } catch (error) {
        updateAccountingState(prevState => ({
          ...prevState,
          accountingHistoryReminders: {
            ...prevState.accountingHistoryReminders,
            isLoading: false,
            isError: true,
          },
        }))
      }
    } else {
      updateAccountingState(prevState => ({
        ...prevState,
        accountingHistoryReminders: {
          ...prevState.accountingHistoryReminders,
          isLoading: false,
          isError: true,
        },
      }))
    }
  }, [workspaceId, accountingReduxId, updateAccountingState])

  const handleSendAccountingReminders = useCallback(async () => {
    updateAccountingState({
      isSendAccountingReminderLoading: true,
    })
    if (workspaceId && accountingReduxId) {
      try {
        const response = await sendReminderService(
          workspaceId,
          accountingReduxId
        )
        updateAccountingState({
          accountingReminderEmailSent: response.reminderSent,
          showAccountingReminderSuccess: true,
        })
        handleGetAccountingReminders()
      } catch (error) {
        updateAccountingState({
          isSendAccountingReminderError: true,
        })
      } finally {
        updateAccountingState({
          isSendAccountingReminderLoading: false,
        })
      }
    } else {
      updateAccountingState({
        isSendAccountingReminderLoading: false,
        isSendAccountingReminderError: true,
      })
    }
  }, [
    workspaceId,
    accountingReduxId,
    handleGetAccountingReminders,
    updateAccountingState,
  ])

  const handleShowAccountingReminders = (param: boolean) =>
    updateAccountingState({
      showAccountingReminderSuccess: param,
    })

  return {
    handleGetAccountingReminders,
    handleSendAccountingReminders,
    handleShowAccountingReminders,
  }
}

export default useHandleAccountingReminders
