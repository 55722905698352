import { KeyboardEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { UseFormReturn, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IConektaCard, TPaymentMethods } from 'models/payments'
import { PAYMENT_METHODS } from 'lib/enums/payments.enums'
import { IConektaForm } from 'lib/forms/payments/payments.form.models'
import { paymentValidationSchema } from 'lib/forms/payments/payments.schemas'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import useConektaTokenizeCard from 'hooks/Payments/useConektaTokenizeCard'
import {
  selectIsRecurringPayment,
  selectSelectedPayment,
} from 'store/Payments/payments.selectors'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'

interface IUseConektaForm {
  selectedPaymentMethod: TPaymentMethods
  isPaymentLoading: boolean
  methods: UseFormReturn<IConektaForm, any, undefined>
  handleSelectPaymentMethod: (paymentMethod: TPaymentMethods) => () => void
  handleUpdateCardNumber: (e: KeyboardEvent<HTMLInputElement>) => void
  handleUpdateCardExpiration: (e: KeyboardEvent<HTMLInputElement>) => void
  handleUpdateCardCvc: (e: KeyboardEvent<HTMLInputElement>) => void
  onSubmit: (data: IConektaForm) => void
}

const useConektaForm = (): IUseConektaForm => {
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const selectedPlan = useSelector(selectSelectedPayment)
  const isRecurringPayment = useSelector(selectIsRecurringPayment)

  const {
    billingInformation,
    refetchBillingInformation,
    refetchPaymentsHistory,
    goBackToPaymentsOverview,
  } = usePaymentsContainerContext()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<TPaymentMethods>(PAYMENT_METHODS.TARJETA)

  const methods = useForm<IConektaForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      number: '',
      expiration: '',
      cvc: '',
    },
    resolver: yupResolver(paymentValidationSchema),
  })

  const { watch, setValue } = methods

  const cardNumber = watch('number')
  const cardExpiration = watch('expiration')
  const cardCvc = watch('cvc')

  const { mutate, isLoading: isPaymentLoading } = useConektaTokenizeCard({
    refetchBillingInformation,
    refetchPaymentsHistory,
    goBackToPaymentsOverview,
    activeWorkspace,
    idPlan: selectedPlan.idPlan,
    periodEnd: billingInformation?.endPeriod ?? '',
    periodStart: billingInformation?.startPeriod ?? '',
    recurrent: isRecurringPayment,
  })

  const handleSelectPaymentMethod = (paymentMethod: TPaymentMethods) => () =>
    setSelectedPaymentMethod(paymentMethod)

  const handleUpdateCardNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    let digits = cardNumber.replace(/\s+/g, '')

    if (key === 'Backspace') {
      digits = digits.slice(0, -1)
    } else if (!key.match(/[0-9]/) || digits.length >= 16) {
      e.preventDefault()
      return
    } else {
      digits += key
    }
    let formattedValue = digits.match(/.{1,4}/g)?.join(' ') ?? ''

    e.preventDefault()
    setValue('number', formattedValue, { shouldValidate: true })
  }

  const handleUpdateCardExpiration = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    let digits = cardExpiration.replace(/\D/g, '')

    if (key === 'Backspace') {
      digits = digits.substring(0, digits.length - 1)
    } else if (!key.match(/[0-9]/) || digits.length >= 4) {
      e.preventDefault()
      return
    } else {
      digits += key
    }

    let formattedValue = ''
    if (digits.length <= 2) {
      formattedValue = digits
    } else {
      formattedValue = `${digits.substring(0, 2)}/${digits.substring(2)}`
    }

    e.preventDefault()
    setValue('expiration', formattedValue, { shouldValidate: true })
  }

  const handleUpdateCardCvc = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    let digits = cardCvc

    if (key === 'Backspace') {
      digits = digits.substring(0, digits.length - 1)
    } else if (!key.match(/[0-9]/) || digits.length >= 3) {
      e.preventDefault()
      return
    } else {
      digits += key
    }

    e.preventDefault()
    setValue('cvc', digits, { shouldValidate: true })
  }

  const onSubmit = (data: IConektaForm) => {
    const { name, number, expiration, cvc } = data

    const expirationDate: string[] = expiration.split('/')

    const requestBody: IConektaCard = {
      cvc,
      exp_month: expirationDate[0],
      exp_year: expirationDate[1],
      name,
      number: number.replaceAll(' ', ''),
    }

    mutate(requestBody)
  }

  return {
    selectedPaymentMethod,
    isPaymentLoading,
    methods,
    handleSelectPaymentMethod,
    handleUpdateCardNumber,
    handleUpdateCardExpiration,
    handleUpdateCardCvc,
    onSubmit,
  }
}

export default useConektaForm
