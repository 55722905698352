import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import { FC, Fragment, useMemo, useState } from 'react'
import './styles.scss'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import { UploadedFile } from 'models/general'
import {
  CIF_ACCEPTED_FILES,
  PDF_ACCEPTED_FORMATS,
  PERSONA_FISICA,
} from '../../../constants'
import CloseIcon from '@material-ui/icons/Close'
import { AutocompleteResponse, getAutocompleteData } from 'services/clients'
import { useDispatch, useSelector } from 'react-redux'
import { selectActiveWorkspace, selectCatalogue } from 'lib/helpers/selectors'
import { toast } from 'react-toastify'
import { SetValueConfig, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { setCatalogue } from 'actions/catalogues'
import { Dialog } from '@mui/material'
import useDialog from 'hooks/Shared/useDialog'
import { errorToastOptions } from 'lib/utils/toast.utils'

const CIFButton: FC<{
  setExtractedInfo: (value: boolean) => void
}> = ({ setExtractedInfo }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<UploadedFile>()
  const [isOpen, setIsOpen] = useState(false)
  const workspaceId = useSelector(selectActiveWorkspace)
  const { setValue, setFocus } = useFormContext()
  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))
  const dispatch = useDispatch()
  const dialog = useDialog()

  const handleFile = (files: UploadedFile[]) => {
    const file = files[0]
    file.mime === PDF_ACCEPTED_FORMATS
      ? setFile(file)
      : dialog.danger({
          title: 'Archivo inválido',
          message: 'Por favor seleccione un archivo válido',
        })
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOpen = () => {
    handleToggle()
  }

  const normalizeFileContent = (file: string) => {
    const [, content] = file.split(',')
    return content
  }

  const handleRemove = () => setFile(undefined)

  const handleAutocomplete = (data: AutocompleteResponse) => {
    const options: SetValueConfig = {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    }
    if (data.regimen) {
      const { typePerson, regimen } = data
      const taxSystem =
        typePerson === PERSONA_FISICA ? taxSystemFisica : taxSystemMoral
      const catalogueType =
        typePerson === PERSONA_FISICA ? 'regimenesFisica' : 'regimenesMoral'

      if (_.find(taxSystem, { value: regimen.value }) === undefined) {
        dispatch(setCatalogue(catalogueType, _.concat(regimen, taxSystem)))
      }
      setValue('taxResidence', data.regimen.value, options)
    }
    setExtractedInfo(true)
    handleToggle()
    handleRemove()
    setValue('type', data.typePerson, options)
    setFocus('type')
    setValue('zipCode', data.cp, options)
    setValue('name', data.name, options)
    setValue('rfc', data.rfc, options)
  }

  const handleFileUpload = async () => {
    try {
      if (!file) return
      setIsLoading(true)
      const autocompleteData = await getAutocompleteData(workspaceId, {
        file: normalizeFileContent(file.base64),
        name: file.name,
      })
      handleAutocomplete(autocompleteData)
    } catch (error: any) {
      toast.error('Ocurrió un error al subir el archivo', errorToastOptions)
      handleRemove()
    } finally {
      setIsLoading(false)
    }
  }

  const dropzoneLabel = useMemo(() => {
    if (file && !isLoading) return `${file.name}.${file.extension}`
    if (file && isLoading) return `Cargando ${file.name}.${file.extension}`
    return 'Subir archivo CIF.pdf'
  }, [file, isLoading])

  return (
    <Fragment>
      <Button className="cif-button-component" onClick={handleOpen}>
        Completar usando el CIF
      </Button>
      <Dialog
        open={isOpen}
        className="dialog"
        fullWidth
        maxWidth="sm"
        keepMounted
        PaperComponent={({ children }) => (
          <Box className="dialog-paper">{children}</Box>
        )}>
        <Box className="close">
          <IconButton
            onClick={() => {
              handleRemove()
              handleToggle()
            }}
            disabled={isLoading}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent className="dialog-content">
          <Box className="title">Sube el CIF</Box>
          <Box className="subtitle">
            Sube tu archivo CIF en formato PDF para que se autocomplete la
            información de manera automática.
          </Box>
          <Box className="dropzone-parent">
            <FileUploader
              label={dropzoneLabel}
              onValue={handleFile}
              accept={CIF_ACCEPTED_FILES}
              limit={1}
              color="secondary"
              className="dropzone"
              disabled={file !== undefined}
              loading={isLoading}
            />
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          {file !== undefined ? (
            <Button
              className="button cancel"
              onClick={handleRemove}
              disabled={isLoading}>
              Remover
            </Button>
          ) : (
            <Button
              className="button cancel"
              onClick={handleToggle}
              disabled={isLoading}>
              Cancelar
            </Button>
          )}
          <Button
            className="button primary"
            disabled={file === undefined || isLoading}
            onClick={handleFileUpload}>
            Autocompletar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default CIFButton
