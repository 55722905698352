import { Fragment } from 'react'
import { Button, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import HistoryFilters from '../AccountStatementHistoryToolbarFilters/AccountStatementHistoryToolbarFilters'

const BackButton = () => {
  const {
    screen: { handleSetTab },
  } = useConciliationsContainerContext()

  const onBack = () => handleSetTab(CONCILIATION_TABS.CONCILIATIONS)

  return (
    <Button
      startIcon={<ArrowBackIcon />}
      onClick={onBack}
      variant="text"
      style={{
        color: '#000000',
        fontWeight: 'normal',
      }}
      size="medium">
      Volver
    </Button>
  )
}

const AccountStatementHistoryToolbar = () => {
  return (
    <Fragment>
      <BackButton />
      <Typography variant="h5" style={{ marginTop: 26 }}>
        Estados de cuenta
      </Typography>
      <HistoryFilters />
    </Fragment>
  )
}

export default AccountStatementHistoryToolbar
