import { PropTypes } from '@material-ui/core'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { IWorkspaceRequest } from 'services/Shared/shared.models'

export type ContextColors =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'info'
  | 'success'
  | 'warning'
  | 'primaryAsync'
  | 'dangerAsync'

export type ExtendedColorPalette = PropTypes.Color | ContextColors
export type ExtendedIconColorPalette =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'action'
  | 'disabled'
  | 'error'
  | ContextColors
export type ExtendedButtonVariant =
  | 'text'
  | 'outlined'
  | 'contained'
  | 'link'
  | 'hover'
export enum LoginScreenForms {
  LOGIN = 'login',
  RECOVER = 'recover',
  REGISTER = 'register',
}

export type Plan =
  | 'facturacion'
  | 'contabilidad'
  | 'nomina'
  | 'repse'
  | 'declaracion'
  | 'regularizacion'
  | ''

export enum Persona {
  fisica = 'fisica',
  moral = 'moral',
}

export enum WorkspaceRolesNames {
  admin = 'ADMIN',
  contadorSr = 'CONTADOR SR',
  contadorJr = 'CONTADOR JR',
}

export interface ZipCode {
  idZipcode: number
  cp: string
  zipcode?: string
  state?: string
  municipality?: string
}
export interface State {
  idState: number
  state: string
}
export interface Municipality {
  idMunicipality: number
  municipality: string
}
export interface Address {
  idAddress: number
  municipalities: Municipality
  states: State
  zipcodes: ZipCode
  createdAt?: string
  lastUpdate?: string
  enabled: boolean
}

export interface UploadedFile {
  uuid: string
  wsId?: number
  name: string
  description?: string
  fullName: string
  extension: string
  mime: string
  base64: string
  activity?: boolean
  error?: boolean
  status?: 'valid' | 'invalid'
  documentType?: number
}

export interface File {
  idFile: number
  base64: string
  fileName: string
  type: string
  extension: string
  createdAt: string
  lastUpdate: string
  enabled: boolean
}
export interface Archive extends Partial<File> {
  data: string
}

export interface ParsedArchive {
  originalName: string
  file: string
  idParentFile?: string
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface QueryOptions<
  IReturnData,
  IEventualData = IReturnData,
  IError = unknown,
> extends Omit<
    UseQueryOptions<IReturnData, IError, IEventualData, QueryKey>,
    'queryKey' | 'queryFn'
  > {
  onBefore?: () => void
  noSave?: boolean
  noDialog?: boolean
  refreshOn?: any[]
  customKey?: string
}

export interface MutationOptions<
  IReturnData,
  IVariables,
  IError = unknown,
  IContext = unknown,
> extends Omit<
    UseMutationOptions<IReturnData, IError, IVariables, IContext>,
    'mutationFn'
  > {
  onBefore?: (variables: IVariables) => void
  noSave?: boolean
  noDialog?: boolean
  noCheck?: boolean
}

export interface CSVConfigurator<T> {
  accesor: string
  label?: string
  formatter?: (value: any, array: T) => any
}

export enum NotificationType {
  TEXT = 'text',
  VALIDITY = 'validity',
  INVITATION = 'invitation',
}

export interface Notification {
  id: number
  type: NotificationType
  message: string
  relatedId: number
  read: boolean
}

export interface List<T> {
  list: T
  page: number
  totalPages: number
  total: number
}

export interface Pagination {
  page: number
  size: number
  total: number
}

export interface IDropdownOption {
  label: string
  value: string | number
}
export interface IHeaderDownloadOption {
  id: string
  name: string
  onClick: () => void
}

export type TWorkspaceParams = IWorkspaceRequest[] & number[] & number
