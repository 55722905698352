import React from 'react'
import { useSelector } from 'react-redux'

import Activity from 'components/Shared/Activity/Activity'

import { selectLocalLoader } from 'lib/helpers/selectors'

interface Props {}

const LoaderHub: React.FC<Props> = props => {
  const loader = useSelector(selectLocalLoader)
  const node = loader.show ? (
    <Activity size={60} color="primary" message={loader.message} />
  ) : null
  return node
}

export default LoaderHub
