import React from 'react';

import Account from '@material-ui/icons/AccountCircle';
import './style.scss';

interface CommentProps {
    name: string
    date: string
    value: string
    className?: string
}

const Comment: React.FC<CommentProps> = ({ 
    name, 
    date, 
    value, 
    className = ""
}) => {
  return (
    <div className={`component-comment ${className}`}>
        <div className="header">
            <Account className="avatar" fontSize="inherit" color="primary" />
            <h4 className="name">{name}</h4>
            <span className="date">{date}</span>
        </div>
        <div className="comment">{value}</div>
    </div>
  )
}

export default Comment;