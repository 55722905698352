import { Box, Grid } from '@material-ui/core';
import { FC } from 'react';
import GenerateReportButton from '../ConciliationReportsGenerateReportButton/ConciliationReportsGenerateReportButton';

const ConciliationReportsToolbar: FC = () => {
  return (
    <Grid item>
      <Box
        marginY={4}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box fontSize={18} fontWeight={500} color="#444" flex={1}>Conciliaciones anteriores</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <GenerateReportButton />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ConciliationReportsToolbar;