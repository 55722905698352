export const SET_GLOBAL_LOADER = '@Loader/SET_GLOBAL_LOADER';
export const SET_LOCAL_LOADER = '@Loader/SET_LOCAL_LOADER';

/**
 * Show/Hide the Global Loader.
 */
export const setGlobalLoader = (show: boolean, message: string = "") => ({
    type: SET_GLOBAL_LOADER,
    value: { show, message },
})


/**
 * Show/Hide the Local Loader.
 */
 export const setLocalLoader = (show: boolean, message: string = "") => ({
    type: SET_LOCAL_LOADER,
    value: { show, message },
})