import React, { useEffect, useState } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import MUIAutocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import MUITextField from '@material-ui/core/TextField';

import './style.scss';

//#region Types and Interfaces
interface CatalogueEntry {
  [key: string]: any
}

interface Props {
  ioProps: IOInputProps
  style: React.CSSProperties
  onSearch: (value: string) => Promise<any>
  setValue: (value: CatalogueEntry | null) => void
  value: CatalogueEntry | null
  noOptionsText?: string
  loadingText?: string
  className?: string
  label?: string
  placeholder?: string
  variant?: "filled" | "outlined" | "standard"
  size?: "medium" | "small"
  fullWidth?: boolean
  disabled?: boolean
  disableCloseOnSelect?: boolean
  disableClearable?: boolean
  required?: boolean
  inputProps: object
  InputProps: {
    startAdornment?: React.ReactNode,
    endAdornment?: React.ReactNode,
  }
  addon?: any
  groupBy?: (option: any) => string
}
//#endregion

const _AutocompleteField: React.FC<Props> = (props) => {
  const {
    ioProps,
    setValue,
    value,
    onSearch: _onSearch,
    className,
    label,
    variant,
    placeholder,
    addon,
    groupBy,
    InputProps = {
      startAdornment: null,
      endAdornment: null
    },
    ...rest
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState([]);

  const onSearch = _.throttle(_onSearch, 1000);

  const getInformation = async (search: string) => {
    setLoading(true);
    try {
      onSearch.cancel();
      const list = await onSearch(search);
      setResults(list);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const onChange = (_e: any, v: CatalogueEntry | null) => {
    setValue(v);
  }

  const obtainDefaultOptionLabel = (o: CatalogueEntry) => (o?.label || "");

  useEffect(() => {
    if (search.length >= 3) {
      getInformation(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const renderInput = ({ InputProps: InputPropsC, ...params }: AutocompleteRenderInputParams) => {
    let endAdornment = InputPropsC.endAdornment as any;
    if (InputProps.endAdornment) {
      const _endAdornment = React.createElement(
        endAdornment.type,
        { ...endAdornment.props, children: [...endAdornment.props.children, InputProps.endAdornment] }
      );
      endAdornment = _endAdornment;
    }

    return (
      <>
        <MUITextField
          {...params}
          id={`searchField-${ioProps.name}`}
          className="text-field"
          variant={variant}
          placeholder={placeholder}
          label={label}
          InputProps={{
            ...InputPropsC,
            startAdornment: InputProps.startAdornment ? <>{InputProps.startAdornment}{InputPropsC.startAdornment}</> : InputPropsC.startAdornment,
            endAdornment: endAdornment,
          }}
        />
        {addon}
      </>
    )
  }

  return (
    <div className={`component-search-field ${className || ""}`.trim()}>
      <MUIAutocomplete
        options={results}
        value={value || null}
        inputValue={search}
        onChange={onChange}
        onInputChange={(_e: any, v: string) => setSearch(v)}
        loading={loading}
        filterOptions={(x) => x}
        handleHomeEndKeys={false}
        getOptionLabel={obtainDefaultOptionLabel}
        renderInput={renderInput}
        clearText="Limpiar"
        groupBy={groupBy ? groupBy : undefined}
        {...rest}
      />
    </div>
  )
}

const Autocomplete = withIO("search")(_AutocompleteField);
export default Autocomplete;
