import { TConciliationTabs } from 'models/conciliations'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { ISydTab } from 'components/Shared/SydTabs/SydTabs'

export const CONCILIATION_TABS_WILDCARDS = {
  [CONCILIATION_TABS.CONCILIATIONS]: 'conciliations',
  [CONCILIATION_TABS.REPORTS]: 'reports',
  [CONCILIATION_TABS.ACCOUNT_STATEMENT_HISTORY]: 'account-statement-history',
  [CONCILIATION_TABS.CFDI_HISTORY]: 'cfdi-history',
  [CONCILIATION_TABS.OCR_PROCESS]: 'ocr-process',
} as const

export const conciliationTabLabels: Partial<Record<TConciliationTabs, string>> =
  {
    [CONCILIATION_TABS.CONCILIATIONS]: 'Por conciliar',
    [CONCILIATION_TABS.REPORTS]: 'Reportes',
  } as const

export const conciliationTabs: Readonly<ISydTab[]> = [
  {
    id: 'conciliation-tab-1',
    label: conciliationTabLabels[CONCILIATION_TABS.CONCILIATIONS] as string,
  },
  {
    id: 'conciliation-tab-2',
    label: conciliationTabLabels[CONCILIATION_TABS.REPORTS] as string,
  },
] as const

export const loadingStatesTargetMap = {
  statements: 'isLoadingStatements',
  cfdis: 'isLoadingCFDIs',
  ledgers: 'isLoadingLedgerAccounts',
  conciliations: 'isLoadingConciliations',
  accountMovements: 'accountMovementsIsLoading',
} as const
