import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import DocumentsContainer from 'containers/Documents/DocumentsContainer'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../constants'

import './style.scss'

interface Props {}

const DocumentsScreen: React.FC<Props> = props => {
  const navigate = useNavigate()

  const workspaceId = useSelector(selectActiveWorkspace)

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'add') navigate(ROUTES.ADD_WORKSPACE)
    if (action === 'edit') navigate(`${ROUTES.WORKSPACE_DETAILS}?id=${id}`)
    if (action === 'error') navigate(ROUTES.HOME, { replace: true })
  }

  return (
    <>
      <Grid container spacing={0} className="screen-documentation">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Documentos</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DocumentsContainer
            workspaceId={workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DocumentsScreen
