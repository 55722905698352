import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { UploadedFile } from 'models/general'
import {
  AccountingForm,
  IAccountingFileType,
  IAccountingStepFiveCategoryFileStatus,
  IAccountingStepFiveUploadColumn,
  IContinueButtonProps,
  TAccountingFileTypes,
} from 'models/accounting.models'
import { ACCOUNTING_STEP_FIVE_MAX_UPLOADS } from '../../../constants'
import {
  getAccountingFileCategoryType,
  getFilesToValidate,
  getInvalidFiles,
  getTotalCategoryFiles,
  getUploadedAndValidatedFiles,
  getUploadedFiles,
  getUploadingFile,
  getValidFiles,
} from 'lib/utils/Accounting/accounting.utils'
import { errorToastOptions } from 'lib/utils/toast.utils'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import useAccountingStepFiveUploadColumns from './useAccountingStepFiveUploadColumns'
import useAccountingStepFiveValidationSteps from './useAccountingStepFiveValidationSteps'
import ISydDocument from 'models/shared.models'

export interface IAccountingStepFiveState {
  limitFileView: boolean
  clientCommentsDialog: boolean
  validCommentsDialog: boolean
  validRenameDialog: boolean
  additionalDocuments: boolean
  firstCategoryFileStatuses: IAccountingStepFiveCategoryFileStatus[]
  thirdCategoryFileStatuses: IAccountingStepFiveCategoryFileStatus[]
  selectedFile?: ISydDocument | UploadedFile
}

export interface IUseAccountingStepFive extends IAccountingStepFiveState {
  totalFirstCategoryFiles: ISydDocument[]
  proxyFinancialStatesFiles: UploadedFile[]
  uploadedFirstCategoryFiles: ISydDocument[]
  uploadedFourthCategoryFiles: ISydDocument[]
  uploadedPreviousStepsRequiredFiles: ISydDocument[]
  uploadedFinancialStatesFiles: ISydDocument[]
  withFinancialStates: boolean
  listForFileNameValidation: (string | undefined)[]
  firstCategoryFilesToValidate: ISydDocument[]
  uploadedAndValidatedFirstCategoryFiles: ISydDocument[]
  dynamicFileUploadColumns: IAccountingStepFiveUploadColumn[]
  continueButtonProps: IContinueButtonProps
  updateStepFiveState: (
    update:
      | Partial<IAccountingStepFiveState>
      | ((prevState: IAccountingStepFiveState) => IAccountingStepFiveState)
  ) => void
  fileUploadHandler: (
    totalUploadItems: ISydDocument[],
    additionalValidFiles?: ISydDocument[],
    additionalInvalidFiles?: ISydDocument[],
    withStepSubmit?: boolean
  ) => (files: UploadedFile[]) => void
  handleCommentsFormSubmit: (form: any) => Promise<any>
  handleRenameFormSubmit: (form: any) => Promise<any>
  financialStatesFileType?: IAccountingFileType
}

const useAccountingStepFive = (): IUseAccountingStepFive => {
  const {
    isAllAccountingTasksComplete,
    filesToUpload,
    filesInProcess,
    handleFileUpload,
    handleFileRename,
    handleFileValidation,
    handleStepSubmit,
  } = useAccountingContext()

  const firstFileCategoryType = getAccountingFileCategoryType(4)
  const secondFileCategoryType = getAccountingFileCategoryType(5)
  const thirdFileCategoryType = getAccountingFileCategoryType(6)
  const fourthFileCategoryType = getAccountingFileCategoryType(9)
  const financialStatesFileType = getAccountingFileCategoryType(3)

  const totalFirstCategoryFiles = useMemo(
    () => getTotalCategoryFiles(filesInProcess, firstFileCategoryType),
    [filesInProcess, firstFileCategoryType]
  )
  const totalSecondCategoryFiles = getTotalCategoryFiles(
    filesInProcess,
    secondFileCategoryType
  )
  const totalThirdCategoryFiles = useMemo(
    () => getTotalCategoryFiles(filesInProcess, thirdFileCategoryType),
    [filesInProcess, thirdFileCategoryType]
  )
  const totalFourthCategoryFiles = getTotalCategoryFiles(
    filesInProcess,
    fourthFileCategoryType
  )

  const [accountingStepFiveState, setAccountingStepFiveState] =
    useState<IAccountingStepFiveState>({
      limitFileView: true,
      clientCommentsDialog: false,
      validCommentsDialog: false,
      validRenameDialog: false,
      additionalDocuments: totalFourthCategoryFiles.length > 0,
      firstCategoryFileStatuses: totalFirstCategoryFiles.map(f => ({
        id: f.idWsfile,
        status: f.statusText,
      })),
      thirdCategoryFileStatuses: totalThirdCategoryFiles.map(f => ({
        id: f.idWsfile,
        status: f.statusText,
      })),
    })

  const {
    additionalDocuments,
    selectedFile,
    firstCategoryFileStatuses,
    thirdCategoryFileStatuses,
  } = accountingStepFiveState

  const updateStepFiveState = useUpdateStateFunction<IAccountingStepFiveState>(
    setAccountingStepFiveState
  )

  const uploadingFirstFile = getUploadingFile(
    filesToUpload,
    firstFileCategoryType
  )
  const uploadingSecondFile = getUploadingFile(
    filesToUpload,
    secondFileCategoryType
  )
  const uploadingThirdFile = getUploadingFile(
    filesToUpload,
    thirdFileCategoryType
  )
  const uploadingFourthFile = getUploadingFile(
    filesToUpload,
    fourthFileCategoryType
  )

  const proxyFinancialStatesFiles = filesToUpload.filter(
    f => financialStatesFileType?.type === f?.description
  )

  const uploadedFirstCategoryFiles = getUploadedFiles(
    filesInProcess,
    firstFileCategoryType
  )

  const uploadedSecondCategoryFiles = getUploadedFiles(
    filesInProcess,
    secondFileCategoryType
  )

  const uploadedThirdCategoryFiles = getUploadedFiles(
    filesInProcess,
    thirdFileCategoryType
  )

  const uploadedFourthCategoryFiles = getUploadedFiles(
    filesInProcess,
    fourthFileCategoryType
  )

  const uploadedPreviousStepsRequiredFiles = filesInProcess.filter(
    f =>
      ![
        financialStatesFileType?.type,
        firstFileCategoryType?.type,
        secondFileCategoryType?.type,
        thirdFileCategoryType?.type,
      ].includes(f?.description as TAccountingFileTypes)
  )

  const uploadedFinancialStatesFiles = filesInProcess.filter(
    f => financialStatesFileType?.type === f?.description
  )

  const withFinancialStates = uploadedFinancialStatesFiles.reduce(
    (acc, f) => acc && f.validated,
    uploadedFinancialStatesFiles.length > 0
  )

  const listForFileNameValidation = [
    ...uploadedFirstCategoryFiles,
    ...uploadedSecondCategoryFiles,
    ...uploadedThirdCategoryFiles,
    ...uploadedFourthCategoryFiles,
    ...uploadedPreviousStepsRequiredFiles,
    ...uploadedFinancialStatesFiles,
  ].map(d => d?.name)

  const firstCategoryFilesToValidate = getFilesToValidate(
    filesInProcess,
    firstFileCategoryType
  )
  const thirdCategoryFilesToValidate = getFilesToValidate(
    filesInProcess,
    thirdFileCategoryType
  )

  const firstCategoryValidFiles = getValidFiles(
    totalFirstCategoryFiles,
    firstCategoryFileStatuses
  )

  const thirdCategoryValidFiles = getValidFiles(
    totalThirdCategoryFiles,
    thirdCategoryFileStatuses
  )

  const firstCategoryInvalidFiles = getInvalidFiles(
    totalFirstCategoryFiles,
    firstCategoryFileStatuses
  )

  const thirdCategoryInvalidFiles = getInvalidFiles(
    totalThirdCategoryFiles,
    thirdCategoryFileStatuses
  )

  const uploadedAndValidatedFirstCategoryFiles = getUploadedAndValidatedFiles(
    filesInProcess,
    firstFileCategoryType
  )

  const uploadedAndValidatedThirdCategoryFiles = getUploadedAndValidatedFiles(
    filesInProcess,
    thirdFileCategoryType
  )

  const runValidationServiceOnSelectedFiles = useCallback(
    (
      validFiles: ISydDocument[],
      invalidFiles: ISydDocument[],
      withStepSubmit?: boolean
    ) => {
      validFiles.forEach((file, i) => {
        handleFileValidation(file, 'valid', true)
      })

      invalidFiles.length > 0 && handleStepSubmit(6, '', [], invalidFiles)

      withStepSubmit &&
        !invalidFiles.length &&
        isAllAccountingTasksComplete &&
        handleStepSubmit(6)
    },
    [isAllAccountingTasksComplete, handleFileValidation, handleStepSubmit]
  )

  const { dynamicFileUploadColumns } = useAccountingStepFiveUploadColumns({
    totalFirstCategoryFiles,
    totalSecondCategoryFiles,
    totalThirdCategoryFiles,
    totalFourthCategoryFiles,
    uploadedSecondCategoryFiles,
    uploadedThirdCategoryFiles,
    firstCategoryFilesToValidate,
    thirdCategoryFilesToValidate,
    firstCategoryValidFiles,
    firstCategoryInvalidFiles,
    thirdCategoryValidFiles,
    thirdCategoryInvalidFiles,
    uploadedAndValidatedFirstCategoryFiles,
    uploadedAndValidatedThirdCategoryFiles,
    firstCategoryFileStatuses,
    uploadedFirstCategoryFiles,
    thirdCategoryFileStatuses,
    uploadedFourthCategoryFiles,
    firstFileCategoryType,
    secondFileCategoryType,
    thirdFileCategoryType,
    fourthFileCategoryType,
    uploadingFirstFile,
    uploadingSecondFile,
    uploadingThirdFile,
    uploadingFourthFile,
  })

  const { continueButtonProps } = useAccountingStepFiveValidationSteps({
    totalThirdCategoryFiles,
    additionalDocuments,
    firstCategoryFileStatuses,
    thirdCategoryFileStatuses,
    uploadedFirstCategoryFiles,
    uploadedThirdCategoryFiles,
    uploadedFourthCategoryFiles,
    firstCategoryFilesToValidate,
    thirdCategoryFilesToValidate,
    firstCategoryValidFiles,
    thirdCategoryValidFiles,
    firstCategoryInvalidFiles,
    thirdCategoryInvalidFiles,
    uploadedAndValidatedFirstCategoryFiles,
    uploadedAndValidatedThirdCategoryFiles,
    runValidationServiceOnSelectedFiles,
  })

  const fileUploadHandler =
    (
      totalUploadItems: ISydDocument[],
      additionalValidFiles?: ISydDocument[],
      additionalInvalidFiles?: ISydDocument[]
    ) =>
    (files: UploadedFile[]) => {
      if (
        files.filter(file => filesInProcess.some(f => f.name === file.name))
          .length > 0
      ) {
        toast.error(
          'No puedes subir más de un archivo con el mismo nombre durante el proceso',
          errorToastOptions
        )
        return
      }

      if (totalUploadItems.length >= ACCOUNTING_STEP_FIVE_MAX_UPLOADS) {
        toast.error(
          `No puedes subir más de ${ACCOUNTING_STEP_FIVE_MAX_UPLOADS} archivos por documento`,
          errorToastOptions
        )

        return
      }

      runValidationServiceOnSelectedFiles(
        additionalValidFiles ?? [],
        additionalInvalidFiles ?? []
      )

      handleFileUpload(files)
    }

  const handleCommentsFormSubmit = async (form: AccountingForm) => {
    handleStepSubmit(6, form.comment, [], firstCategoryInvalidFiles)
    updateStepFiveState({
      clientCommentsDialog: false,
    })
  }

  const handleRenameFormSubmit = async (form: AccountingForm) => {
    if (filesInProcess.some(f => f.name === form.name)) {
      toast.error(
        'No puedes subir más de un archivo con el mismo nombre durante el proceso',
        errorToastOptions
      )
      return
    }

    selectedFile && handleFileRename(form.name, selectedFile)
    updateStepFiveState({ selectedFile: undefined })
  }

  useEffect(() => {
    updateStepFiveState({
      firstCategoryFileStatuses: totalFirstCategoryFiles.map(f => ({
        id: f.idWsfile,
        status: f.statusText,
      })),
      thirdCategoryFileStatuses: totalThirdCategoryFiles.map(f => ({
        id: f.idWsfile,
        status: f.statusText,
      })),
    })
  }, [totalFirstCategoryFiles, totalThirdCategoryFiles, updateStepFiveState])

  return {
    ...accountingStepFiveState,
    totalFirstCategoryFiles,
    proxyFinancialStatesFiles,
    uploadedFirstCategoryFiles,
    uploadedFourthCategoryFiles,
    uploadedPreviousStepsRequiredFiles,
    uploadedFinancialStatesFiles,
    withFinancialStates,
    listForFileNameValidation,
    firstCategoryFilesToValidate,
    uploadedAndValidatedFirstCategoryFiles,
    dynamicFileUploadColumns,
    continueButtonProps,
    updateStepFiveState,
    fileUploadHandler,
    handleCommentsFormSubmit,
    handleRenameFormSubmit,
    financialStatesFileType,
  }
}

export default useAccountingStepFive
