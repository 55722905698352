import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
// import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
// import Button from 'components/Shared/Button/Button';
import FileCard from 'components/Shared/FileCard/FileCard'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'

import { useEmisorFiles } from 'hooks/queries/emisores'

import { downloadFileFromData } from 'lib/helpers/utilities'

import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import './style.scss'

import Workspace from 'models/workspace'
import RazonSocial from 'models/razonSocial'

interface Props {
  workspace: Workspace
  emisor: RazonSocial
  onRedirect: (action: string) => void
  className?: string
}

const WorkspaceInformationContainer: React.FC<Props> = ({
  workspace,
  emisor,
  onRedirect,
  className = '',
}) => {
  const intl = useIntl()

  const filesQuery = useEmisorFiles(workspace.id, emisor)

  // const [validForm, setValidForm] = useState(false);
  const [showCiec, setShowCiec] = useState(false)

  const formatFilename = (name: string, extension: string) => {
    const cleanName = _.replace(name, /(.key)|(.cer)|(.KEY)|(.CER)/g, '')
    return `${cleanName}.${_.toUpper(extension)}`
  }
  const formatDate = (dateString?: string) => {
    const date = dateString ? new Date(_.split(dateString, '.')[0]) : new Date()
    const formatted = intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }

  const filesFiel = _.filter(filesQuery?.data, f => f.type.indexOf('Fiel') > -1)
  const filesCsd = _.filter(filesQuery?.data, f => f.type.indexOf('Csd') > -1)

  return (
    <div className={`container-workspace-information ${className}`}>
      <Form onSubmit={() => true} onValidity={() => true}>
        <div className="section">
          <p className="plan">
            {intl.formatMessage({ id: `workspace.plan.${workspace.plan}` })}
          </p>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <NumericField
                name="facturacion"
                label="Promedio mensual de facturación"
                value={workspace.invoiceAmount}
                format="money"
                fullWidth
                inputProps={{ readOnly: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <NumericField
                name="cantidadFacturas"
                label="Cantidad de facturas al mes"
                value={workspace.invoiceQuantity}
                format="quantity"
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <p className="title">Información fiscal</p>
          <Grid container spacing={3}>
            {filesQuery.isLoading && (
              <Grid item xs={12} className="loading-container">
                <CircularProgress color="primary" size={60} />
              </Grid>
            )}
            {!filesQuery.isLoading &&
              _.map(filesFiel, f => (
                <Grid key={f.idFile} item xs={3}>
                  <FileCard
                    title={formatFilename(f.fileName, f.extension)}
                    subtitle={formatDate(f.createdAt)}
                    onDownload={() =>
                      downloadFileFromData(f.fileName, f.base64)
                    }
                    type="File"
                  />
                </Grid>
              ))}
            {!filesQuery.isLoading &&
              _.map(filesCsd, f => (
                <Grid key={f.idFile} item xs={3}>
                  <FileCard
                    title={formatFilename(f.fileName, f.extension)}
                    subtitle={formatDate(f.createdAt)}
                    onDownload={() =>
                      downloadFileFromData(f.fileName, f.base64)
                    }
                    type="File"
                  />
                </Grid>
              ))}
          </Grid>
        </div>
        {!!emisor?.ciec && (
          <div className="section">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fieldType={showCiec ? 'alphanumeric' : 'password'}
                  name="ciec"
                  label="CIEC"
                  value={emisor.ciec}
                  fullWidth
                  inputProps={{ readOnly: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowCiec(ps => !ps)}>
                          {showCiec ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {/* <Divider />
                <div className="actions">
                    <Button
                        variant="hover"
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        disabled={!validForm}
                    >
                        Guardar cambios
                    </Button>
                </div> */}
      </Form>
    </div>
  )
}

export default WorkspaceInformationContainer
