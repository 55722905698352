import { ToastOptions, toast } from 'react-toastify'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { TWorkspaceParams } from 'models/general'
import { IApiError } from 'services/Shared/shared.models'
import { getComplianceOpinionsService } from 'services/Shared/shared.services'
import { downloadMultiWorkspaceCFDIs } from 'services/Management/management.services'
import { downloadFileFromData } from 'lib/helpers/utilities'

interface ICreateDownloadMutationProps {
  errorToastOptions: ToastOptions
  loadingToastOptions: ToastOptions
  service:
    | typeof downloadMultiWorkspaceCFDIs
    | typeof getComplianceOpinionsService
  fileName: string
  loadingMessage: string
  errorMessage?: string
  onError?: (error: IApiError) => void
}

const useCreateCFDIComplianceDownloadMutation = ({
  errorToastOptions,
  loadingToastOptions,
  service,
  fileName,
  loadingMessage,
  errorMessage,
  onError,
}: ICreateDownloadMutationProps): UseMutationResult<
  string,
  IApiError,
  TWorkspaceParams
> => {
  const mutation = useMutation<string, IApiError, TWorkspaceParams>(
    (workspaces: TWorkspaceParams) => service(workspaces),
    {
      onMutate: () => {
        toast.loading(loadingMessage, {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: response => {
        toast.dismiss('loading')
        downloadFileFromData(
          `${fileName}.zip`,
          `data:application/octet-stream;base64,${response}`
        )
      },
      onError: error => {
        toast.dismiss('loading')
        if (onError) {
          onError(error)
          return
        }
        errorMessage && toast.error(errorMessage, errorToastOptions)
      },
    }
  )

  return mutation
}

export default useCreateCFDIComplianceDownloadMutation
