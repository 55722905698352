import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import _ from 'lodash'

import {
  uploadArchiveInPath,
  renameArchive,
  deleteArchive,
  getFolderContentByPath,
  createFolderInPath,
} from 'services/documentation'
import { setDocumentationList } from 'actions/documentation'

import Help from '@material-ui/icons/Help'
import ErrorI from '@material-ui/icons/ErrorOutline'

import { Path } from 'models/documentation'
import { QueryOptions, MutationOptions, Archive } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'
import { IApiError } from 'services/Shared/shared.models'
import ISydDocument from 'models/shared.models'
import { getDocumentationFileByIdService } from 'services/Shared/shared.services'

// #region Path Hooks
const useDocumentList = (
  workspaceId: number,
  path: Path[],
  options: QueryOptions<ISydDocument[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<ISydDocument[], any>(
    ['get-documents-list', workspaceId, path, ...refreshOn],
    () => {
      onBefore && onBefore()
      const parsedPath =
        _.join(
          _.map(path, p => p.goTo),
          '/'
        ) || '/'
      return getFolderContentByPath(workspaceId, parsedPath)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setDocumentationList(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la lista de archivos para el workspace seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useAddDocument = (
  workspaceId: number,
  path: Path[],
  options: MutationOptions<void, Archive, IApiError> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<void, IApiError, Archive>(
    file => {
      showAdditionalInformationLoading()
      const parsedPath =
        _.join(
          _.map(path, p => p.goTo),
          '/'
        ) || '/'
      return uploadArchiveInPath(workspaceId, file, parsedPath)
    },
    {
      onSuccess: (data, file, ctx) => {
        dialog.close()
        onSuccess && onSuccess(data, file, ctx)
      },
      onError: async (error, file, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }
          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo cargar el archivo.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(file)
              return
            }
          }

          onError && onError(error, file, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export const useAddFolder = (
  workspaceId: number,
  path: Path[],
  options: MutationOptions<void, string> = {}
) => {
  const dialog = useDialog()

  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, string>(
    name => {
      onBefore && onBefore(name)
      const parsedPath = _.join(
        _.map(path, p => p.goTo),
        '/'
      )
      return createFolderInPath(workspaceId, name, parsedPath)
    },
    {
      ...rest,
      onError: async (error, name, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo crear la carpeta.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(name)
            return
          }
        }

        onError && onError(error, name, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region File Hooks
export const useDocument = (
  workspaceId: number,
  file?: ISydDocument,
  options: QueryOptions<ISydDocument> = {}
) => {
  const { onSuccess, onError, noDialog, customKey } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const query = useQuery<ISydDocument, any>(
    [customKey ?? 'get-document', workspaceId, file?.idWsfile],
    () => {
      if (!file) throw new Error('Archivo cargado incorrectamente')
      showAdditionalInformationLoading()
      return getDocumentationFileByIdService({
        workspaceId,
        fileId: file.idWsfile,
      })
    },
    {
      enabled: false,
      onSuccess: data => {
        dialog.close()
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo obtener la información del archivo.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              query.refetch()
              return
            }
          }

          onError && onError(error)
        }, 600)
      },
    }
  )

  return query
}

export const useRenameDocument = (
  workspaceId: number,
  file?: ISydDocument,
  options: MutationOptions<void, string> = {}
) => {
  const dialog = useDialog()

  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, string>(
    name => {
      if (!file) throw new Error('Archivo cargado incorrectamente')
      onBefore && onBefore(name)
      return renameArchive(workspaceId, file.idWsfile, name)
    },
    {
      ...rest,
      onError: async (error, name, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo renombrar el archivo seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(name)
            return
          }
        }

        onError && onError(error, name, ctx)
      },
    }
  )

  return mutation
}

export const useDeleteDocument = (
  workspaceId: number,
  options: MutationOptions<void, ISydDocument> = {}
) => {
  const dialog = useDialog()

  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, ISydDocument>(
    file => {
      onBefore && onBefore(file)
      return deleteArchive(workspaceId, file.idWsfile)
    },
    {
      ...rest,
      onMutate: async file => {
        if (!noCheck) {
          const resp = await dialog.dangerAsync({
            title: '¿Estás seguro?',
            subtitle:
              file.fileType === 1
                ? `Estás a punto de eliminar la carpeta: ${file.path}`
                : `Estás a punto de eliminar el archivo: ${file.name}`,
            icon: Help as TModalIcon,
            primaryButtonText: 'Si, eliminar',
            secondaryButtonText: 'Cancelar',
          })
          if (!resp) throw new Error('mutation-cancelled')
        }
        onMutate && onMutate(file)
      },
      onError: async (error, file, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              file.fileType === 1
                ? `No se pudo eliminar la carpeta ${file.path}.`
                : `No se pudo eliminar el archivo ${file.name}.`,
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(file)
            return
          }
        }

        onError && onError(error, file, ctx)
      },
    }
  )

  return mutation
}
// #endregion

export default useDocumentList
