import _ from 'lodash';
import moment from 'moment';
import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";
import {
    getInventoryFlag,
    getInvoiceAmount,
    getInvoiceQuantity,
    getRoleInWorkspace,
    parseWorkspace
} from 'lib/helpers/utilities';

import { SUBSCRIPTION_STATUS_PAYMENT_CATALOGUE } from '../constants';
import { SUBSCRIPTION_STATUS_INVOICE_CATALOGUE } from '../constants';

import Workspace, { NewWorkspaceForm, InvitationForm, EditColaboratorForm, Comment, UserNotifications, Billing, SubscriptionPlan } from 'models/workspace';
import { Profile } from 'models/user';

export interface WorkspaceParams {
    name?: string
    enabled?: boolean
}

export interface BillingParams {
    amount: number
    statusInvoice: number
    statusPayment: number
}

const route = process.env.REACT_APP_APIURL || "/api/";

export const getWorkspaceById = async (id: number, idUser: number): Promise<Workspace> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${id}/details`);
        const data = await petition.json();
        const information = data["workspace:"];
        const details = { userList: data["userList:"], invitationList: data["InvitationList:"] };
        const role = getRoleInWorkspace(idUser, data["userList:"]);
        const ws = parseWorkspace(information, role, details);
        return ws;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const getWorkspaceList = async (employeeId?: number, workspaceId?: number, status?: boolean): Promise<Workspace[]> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${route}/workspaces?`;
        if (!_.isNil(status)) url = `${url}status=${status}&`;
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);
        const data = await petition.json();

        const petition2 = await client.get(`${route}/workspaces/${workspaceId}/score/accountantdetail?idUser=${employeeId}`);
        const data2 = await petition2.json();
        const accStatus = data2.workspaces;

        const wsList = _.map(data, (ws) => parseWorkspace(ws.workspaces, ws.roles, undefined, _.find(accStatus, (as) => as.idWorkspace === ws.workspaces.idWorkspace)));
        return wsList;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const createWorkspace = async (form: NewWorkspaceForm): Promise<Workspace> => {
    const client = HTTPClient.getClient();
    try {
        let files: any = [];
        files.push({ ...form.fielCer, type: "cerFiel" });
        files.push({ ...form.fielKey, type: "keyFiel" });
        !!form.csdCer && files.push({ ...form.csdCer, type: "cerCsd" });
        !!form.csdKey && files.push({ ...form.csdKey, type: "keyCsd" });

        const body = {
            name: form.workspace,
            acceptContract: form.acceptContract,
            plan: form.plan,
            additionalPlan: `${form.additionalPlan.join(",")}`,
            free: form.freeTrial,
            invoiceAmount: form.plan === "facturacion" ? `${getInvoiceAmount(form)}` : undefined,
            invoiceQuantity: form.plan === "facturacion" ? `${getInvoiceQuantity(form)}` : undefined,
            withInventory: getInventoryFlag(form),
            promotionalCode: form.promotionalCode,
            rfcs: [{
                idType: form.type?.idCatalogValues,
                name: form.name,
                rfc: form.rfc,
                taxSystem: "1231231",
                idTaxResidence: form.taxSystem?.idCatalogValues,
                zipCode: form.zipCode,
                files: files,
                passwordFiel: form.passwordFiel,
                ciec: form.ciec,
                passwordCsd: form.passwordCsd,
            }]
        }

        const petition = await client.post(`${route}/workspaces`, body);
        const data = await petition.json();
        return parseWorkspace(data);
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const updateWorkspaceName = async (workspaceId: number, name: string): Promise<Workspace> => {
    const client = HTTPClient.getClient();
    try {
        const body = { name };
        const petition = await client.put(`${route}/workspaces/${workspaceId}`, body);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const updateWorkspace = async (workspaceId: number, params: WorkspaceParams): Promise<Workspace> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.put(`${route}/workspaces/${workspaceId}`, params);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const deleteWorkspace = async (workspaceId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${route}/workspaces/${workspaceId}`);
        await petition.data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const acceptInvitation = async (invitationId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.post(`${route}/invitations/${invitationId}`)
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const rejectInvitation = async (invitationId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${route}/invitations/${invitationId}`)
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCollaboratorList = async (workspaceId: number): Promise<Profile[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/users`);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const inviteCollaborator = async (workspaceId: number, form: InvitationForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            email: form.email,
            message: "",
            rol: form.role
        }
        const petition = await client.post(`${route}/workspaces/${workspaceId}/add_user`, body)
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const updateCollaborator = async (workspaceId: number, colaboratorId: number, form: EditColaboratorForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            roleName: form.role
        }
        const petition = await client.put(`${route}/workspaces/${workspaceId}/details/${colaboratorId}`, body);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const deleteCollaborator = async (workspaceId: number, colaboratorId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${route}/workspaces/${workspaceId}/details/${colaboratorId}`);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCommentListForWorkspace = async (workspaceId: number): Promise<Comment[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/comments`);
        const data = await petition.json();
        const list: Comment[] = _.map(data, (c) => ({
            id: c.idComments,
            comment: c.comment,
            createdAt: c.createdAt,
            lastUpdate: c.lastUpdate,
            enabled: c.enabled,
            user: { 
                id: c.users.idUser, 
                name: c.users.name,
                lastname: c.users.lastname,
                lastname2: c.users.lastname2,
                email: c.users.email,
                lastLogin: c.users.lastLogin,
                createdAt: c.users.createdAt,
                lastUpdate: c.users.lastUpdate,
                enabled: c.users.enabled
            },
            workspace: {
                id: c.workspaces.idWorkspace,
                name: c.workspaces.name,
                plan: c.workspaces.plan,
                createdAt: c.workspaces.createdAt,
                lastUpdate: c.workspaces.lastUpdate,
                enabled: c.workspaces.enabled
            }
        }));
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const addCommentToWorkspace = async (workspaceId: number, comment: string): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.post(`${route}/workspaces/${workspaceId}/comments`, { comment });
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getUserNotificationsList = async (workspaceId: number): Promise<UserNotifications[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/usersNotifications`);
        const list: UserNotifications[] = await petition.json();
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const changeUserNotification = async (workspaceId: number, userId: number, roleId: number, typeId: number, consecutive: number, value: boolean): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.post(`${route}/workspaces/${workspaceId}/usersNotifications`, [{
            consecutive,
            userId,
            roleId,
            notificationTypeId: typeId,
            status: value ? 1 : 0
        }]);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getBillingList = async (workspaceId: number): Promise<Billing[]> => {
    const client = HTTPClient.getClient();
    try {
        const today = moment();
        const petition = await client.get(`${route}/workspaces/${workspaceId}/billing?year=${today.year()}`);
        const data = await petition.json();

        const list = _.map(data, (d): Billing => {
            const statusInvoice = (!_.isNil(d.statusInvoice) && Number(d.statusInvoice) < 3 && Number(d.statusInvoice) > 0) ? d.statusInvoice : 0;
            const statusPayment = (!_.isNil(d.statusPayment) && Number(d.statusPayment) < 3 && Number(d.statusPayment) > 0) ? d.statusPayment : 0;
            return {
                idBilling: d.idBilling,
                date: moment(`${d.year}-${_.padStart(d.month, 2, "0")}-01`),
                amount: d.amount,
                statusInvoice: _.find(SUBSCRIPTION_STATUS_PAYMENT_CATALOGUE, (o) => o.value === statusInvoice),
                statusPayment: _.find(SUBSCRIPTION_STATUS_INVOICE_CATALOGUE, (o) => o.value === statusPayment),
                createdAt: d.createdAt,
                lastUpdate: d.lastUpdate,
                enabled: d.enabled
            }
        });

        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const editBilling = async (workspaceId: number, billingId: number, params: BillingParams): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/billing/${billingId}`, params);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const uploadBillingFile = async (workspaceId: number, file: string): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.post(`${route}/workspaces/${workspaceId}/billing/upload`, file, { headers: { "Content-Type": "text/plain" } });
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getPlan = async (workspaceId: number): Promise<SubscriptionPlan> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/plan`);
        const data : SubscriptionPlan= await petition.json();

        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}