import { toast } from 'react-toastify'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { TWorkspaceParams } from 'models/general'
import { successToastOptions, errorToastOptions } from 'lib/utils/toast.utils'
import { getCIFService } from 'services/Shared/shared.services'
import { IApiError } from 'services/Shared/shared.models'

const useDownloadCIF = (): UseMutationResult<
  void,
  IApiError,
  TWorkspaceParams
> => {
  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const mutation = useMutation<void, IApiError, TWorkspaceParams>(
    async (workspaceId: TWorkspaceParams) => await getCIFService(workspaceId),
    {
      onMutate: () => {
        toast.loading('Preparando descarga del CIF', {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: () => {
        toast.dismiss('loading')
        toast.success(
          'Se ha iniciado la descarga del CIF, le notificaremos cuando termine',
          successToastOptions
        )
      },
      onError: error => {
        toast.dismiss('loading')
        toast.error(
          'Hubo un error al iniciar la descarga del CIF',
          errorToastOptions
        )
      },
    }
  )

  return mutation
}

export default useDownloadCIF
