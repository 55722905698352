import { TextField } from '@material-ui/core'
import { useCallback, useState } from 'react'
import SendI from '@material-ui/icons/Send'
import './style.scss'

export interface AccountingCommentInputProps {
  handleSendMessage: (message: string) => void
}

const AccountingCommentInput = (props: AccountingCommentInputProps) => {
  const { handleSendMessage } = props
  const [message, setMessage] = useState<string>('')

  const handleChange = useCallback((e: any) => {
    setMessage(e.target.value)
  }, [])

  const onSend = useCallback(() => {
    if (!message) return
    handleSendMessage(message)
    setMessage('')
  }, [message, handleSendMessage])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        onSend()
      }
    },
    [onSend]
  )

  return (
    <div className="accounting-comment-input">
      <div className="accounting-comment-input-text">
        {message !== '' && <span>Escribe un mensaje</span>}
        <TextField
          autoComplete="off"
          className={`input-text`}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          value={message}
          placeholder={'Escribe un mensaje'}
          InputProps={{ disableUnderline: true }}
          multiline
          fullWidth
        />
      </div>

      <div className="accounting-comment-send" onClick={onSend}>
        <SendI />
      </div>
    </div>
  )
}

export default AccountingCommentInput
