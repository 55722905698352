import HTTPClient from 'lib/helpers/HTTPClient';
import _ from 'lodash';

import { httpErrorParser } from 'lib/helpers/utilities';

import RazonSocial, { RazonSocialForm } from 'models/razonSocial';

export const SET_RAZONES_SOCIALES_LIST = '@RazonesSociales/SET_RAZONES_SOCIALES_LIST';
export const SET_SELECTED_RAZON_SOCIAL = '@Products/SET_SELECTED_RAZON_SOCIAL';

const api = process.env.REACT_APP_APIURL || "/api/";

export const setRazonesSocialesList = (value: RazonSocial[]): object => ({
    type: SET_RAZONES_SOCIALES_LIST,
    value
})

export const setSelectedRazonSocial = (value: RazonSocial | null = null): object => ({
    type: SET_SELECTED_RAZON_SOCIAL,
    value
})

export const getRazonSocialById = (workspaceId: number, razonSocialId: number, noSave: boolean = false) => async (dispatch: any): Promise<RazonSocial> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/rfcs/${razonSocialId}`);
        const razonSocial: RazonSocial = await petition.json();
        !noSave && dispatch(setSelectedRazonSocial(razonSocial));
        return razonSocial;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getRazonSocialFiles = (workspaceId: number, razonSocialId: number) => async () => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/rfcs/${razonSocialId}/files`);
        const data = await petition.json();
        const filesPromises = _.map(data, async (f) => {
            const p = await client.get(`${api}/workspaces/${workspaceId}/rfcs/${razonSocialId}/files/${f.idFile}`);
            const file = await p.text();
            console.log('file', file)
            return ({
                ...f,
                base64: file
            })
        });
        const files = await Promise.all(filesPromises);
        return files;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getRazonesSocialesList = (workspaceId: number, noSave: boolean = false) => async (dispatch: any): Promise<RazonSocial[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/rfcs`);
        const data = await petition.json();
        const list: RazonSocial[] = data["Rfcs:"];
        !noSave && dispatch(setRazonesSocialesList(list));
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const createRazonSocial = (workspaceId: number, form: RazonSocialForm) => async (): Promise<RazonSocial> => {
    const client = HTTPClient.getClient();
    try {
        let files: any = [];
        files.push({ ...form.fielCer, type: "cerFiel" });
        files.push({ ...form.fielKey, type: "keyFiel" });
        !!form.csdCer && files.push({ ...form.csdCer, type: "cerCsd" });
        !!form.csdKey && files.push({ ...form.csdKey, type: "keyCsd" });

        const body = {
            idType: form.type?.idCatalogValues,
            name: form.name,
            rfc: _.toUpper(form.rfc),
            taxSystem: "1231231",
            zipCode: form.zipCode,
            idTaxResidence: form.taxSystem?.idCatalogValues,
            files: files,
            passwordFiel: form.passwordFiel,
            ciec: form.ciec,
            passwordCsd: form.passwordCsd,
        }
        const petition = await client.post(`${api}/workspaces/${workspaceId}/rfcs`, body);
        const data: RazonSocial = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const updateRazonSocial = (workspaceId: number, razonSocialId: number, form: RazonSocialForm) => async (): Promise<RazonSocial> => {
    const client = HTTPClient.getClient();
    try {
        const body = {

        }
        const petition = await client.put(`${api}/workspaces/${workspaceId}/rfcs/${razonSocialId}`, body);
        const data: RazonSocial = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const deleteRazonSocial = (workspaceId: number, razonSocialId: number) => async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${api}/workspaces/${workspaceId}/rfcs/${razonSocialId}`);
        await petition.data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}