import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import {
  deleteProduct,
  getProductsList,
  getProductById,
  createProduct,
  updateProduct,
} from 'services/products'
import { setProductsList, setSelectedProduct } from 'actions/products'

import Help from '@material-ui/icons/Help'
import ErrorI from '@material-ui/icons/ErrorOutline'

import Product, { ProductForm } from 'models/product'
import { MutationOptions, QueryOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

const useProductsList = (
  workspaceId: number,
  options: QueryOptions<Product[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Product[], any>(
    ['get-products-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getProductsList(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setProductsList(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la lista de productos y servicios para el workspace seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useProduct = (
  workspaceId: number,
  productId: number,
  options: QueryOptions<Product> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Product, any>(
    ['get-product', productId, workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getProductById(workspaceId, productId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setSelectedProduct(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del producto.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useAddProduct = (
  workspaceId: number,
  options: MutationOptions<void, ProductForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, ProductForm>(
    form => {
      onBefore && onBefore(form)
      return createProduct(workspaceId, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo agregar este producto o servicio.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useUpdateProduct = (
  workspaceId: number,
  product?: Product,
  options: MutationOptions<void, ProductForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, ProductForm>(
    form => {
      if (!product) throw new Error('Producto cargado incorrectamente')
      onBefore && onBefore(form)
      return updateProduct(workspaceId, product.idItem, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar la información del producto o servicio seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useDeleteProduct = (
  workspaceId: number,
  options: MutationOptions<void, Product> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<void, any, Product>(
    async product => {
      const resp = await dialog.dangerAsync({
        title: '¿Estás seguro?',
        subtitle: 'Estás a punto de eliminar este producto o servicio.',
        icon: Help as TModalIcon,
        primaryButtonText: 'Si, eliminar',
        secondaryButtonText: 'Cancelar',
      })
      if (resp) {
        showAdditionalInformationLoading()
        return await deleteProduct(workspaceId, product.idItem)
      }
      if (!resp) throw new Error('mutation-cancelled')
    },
    {
      onSuccess: (data, product, ctx) => {
        dialog.close()
        onSuccess && onSuccess(data, product, ctx)
      },
      onError: async (error, product, ctx) => {
        if (error.message === 'mutation-cancelled') return

        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }
          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo eliminar el producto o servicio seleccionado.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(product)
              return
            }
          }

          onError && onError(error, product, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export default useProductsList
