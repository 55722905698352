import HTTPClient from 'lib/helpers/HTTPClient';
import Catalogue, { Slug, WizardCatalogue } from 'models/catalogues';
import _ from 'lodash';

/** Tipo de accion para agregar un nuevo catalogo al Store de Redux */
export const SET_CATALOGUE = "@Catalogues/SET_CATALOGUE";
export const RESET_CATALOGUES = "@Catalogues/RESET_CATALOGUES";

const api = process.env.REACT_APP_APIURL || "/api";

/**
 * Guarda un nuevo catalogo en el Store de Redux
 *
 */
export const setCatalogue = (name: Slug, entries: Catalogue): object => ({
    type: SET_CATALOGUE,
    value: { name, entries }
})

export const resetCatalogues = (): object => ({
    type: RESET_CATALOGUES,
    value: { }
})

export const searchInCatalogue = (name: Slug, value: string) => async () => {
    const client = HTTPClient.getClient();
    const response = await client.get(`${api}/catalogs/categories/findby?slug=${_.toLower(name)}&search=${value}`);
    const catalogue: Catalogue = await response.json();
    return catalogue;
}

export const getCatalogue = (name: Slug, noSave: boolean = false) => async (dispatch: any) => {
    const client = HTTPClient.getClient();
    const response = await client.get(`${api}/catalogs?slug=${_.toLower(name)}`);
    const catalogue: Catalogue = await response.json();
    !noSave && dispatch(setCatalogue(name, catalogue));
    return catalogue;
}

export const getWizardCatalogue = (typeId = "", divisionId = "", groupId = "", classId = "") => async (_dispatch: any) => {
    const client = HTTPClient.getClient();
    let catalogue: WizardCatalogue = [];

    if (!typeId && !divisionId && !groupId && !classId){
        const response = await client.get(`${api}/pstypes`);
        const data = await response.json();    
        catalogue = _.map(data, (d) => ({ id: d.idPstypes, label: d.name }));
    } else if (typeId && !divisionId && !groupId && !classId) {
        const response = await client.get(`${api}/pstypes/${typeId}/psdivision`);
        const data = await response.json();    
        catalogue = _.map(data, (d) => ({ id: d.idPsdivision, label: d.name }));
    } else if (typeId && divisionId && !groupId && !classId) {
        const response = await client.get(`${api}/pstypes/${typeId}/psdivision/${divisionId}/psgroups`);
        const data = await response.json();    
        catalogue = _.map(data, (d) => ({ id: d.idPsgroups, label: d.name }));
    } else if (typeId && divisionId && groupId && !classId) {
        const response = await client.get(`${api}/pstypes/${typeId}/psdivision/${divisionId}/psgroups/${groupId}/psclases`);
        const data = await response.json();    
        catalogue = _.map(data, (d) => ({ id: d.idPsclasses, label: d.name }));
    } else if (typeId && divisionId && groupId && classId) {
        const response = await client.get(`${api}/pstypes/${typeId}/psdivision/${divisionId}/psgroups/${groupId}/psclases/${classId}/psitems`);
        const data = await response.json();    
        catalogue = _.map(data, (d) => ({ id: d.idPsitems, label: d.name }));
    }

    return catalogue;
}

export const getCatalogues = () => async (dispatch: any): Promise<any> => {
    return Promise.all([
        // dispatch(getCatalogue("items", true)),
        dispatch(getCatalogue("tiposPersona")),
        dispatch(getCatalogue("regimenesFisica")),
        dispatch(getCatalogue("regimenesMoral")),
        dispatch(getCatalogue("aduanas")),
        dispatch(getCatalogue("unidades")),
        dispatch(getCatalogue("formasPago")),
        dispatch(getCatalogue("impuestos")),
        dispatch(getCatalogue("metodosPago")),
        dispatch(getCatalogue("monedas")),
        dispatch(getCatalogue("paises")),
        dispatch(getCatalogue("tiposComprobantes")),
        dispatch(getCatalogue("tiposFactores")),
        dispatch(getCatalogue("tiposRelaciones")),
        dispatch(getCatalogue("usosCfdi")),
        dispatch(getCatalogue("usosCfdi4")),
        dispatch(getCatalogue("exportacion")),
        dispatch(getCatalogue("regimenFiscal")),
        dispatch(getCatalogue("objImp")),
        dispatch(getCatalogue("periodicidad")),
        dispatch(getCatalogue("meses")),
    ]);
}