import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { Payment, PaymentFiles } from 'models/payments'
import { downloadFileFromData } from 'lib/helpers/utilities'
import { successToastOptions } from 'lib/utils/toast.utils'
import { IApiError } from 'services/Shared/shared.models'
import { TPaymentInvoiceFileTypeRequest } from 'services/Payments/payments.models'
import { getPaymentInvoice } from 'services/Payments/payments.services'

interface IuseDownloadPaymentInvoiceMutation {
  payment: Payment
  fileType: TPaymentInvoiceFileTypeRequest
}

const useDownloadPaymentInvoice = (activeWorkspace: number) => {
  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const mutation = useMutation<
    PaymentFiles,
    IApiError,
    IuseDownloadPaymentInvoiceMutation
  >(
    ({ payment, fileType }) => {
      if (!payment || !fileType) {
        throw new Error(
          'No hay suficiente información para descargar el archivo'
        )
      }

      return getPaymentInvoice(activeWorkspace, payment, fileType)
    },
    {
      onMutate: () => {
        toast.loading('Descargando factura', {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: (files, variables) => {
        const { payment, fileType } = variables
        downloadFileFromData(
          `${payment?.payDay ?? 'factura'}.${fileType}`,
          files[fileType]
        )
      },
      onError: () => {
        toast.error('No se pudo descargar la factura')
      },
      onSettled: () => toast.dismiss('loading'),
    }
  )

  return mutation
}

export default useDownloadPaymentInvoice
