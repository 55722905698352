import React, { useState } from 'react';
import _ from 'lodash';

import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import './style.scss';

export interface DropdownCheckboxGroupProps {
    id: number
    label: string
    list: {
        id: number
        label: string
        description?: string
        selected: boolean
        show?: boolean
    }[]
    onGroupCheck: (id: number, value: boolean) => void
    onCheck: (sectionId: number, id: number, value: boolean) => void
}

export interface CheckboxAltProps {
    id: number
    label: string
    description?: string
    selected: boolean
    sectionId: number
    onCheck: (sectionId: number, id: number, value: boolean) => void
}

export const CheckboxAlt: React.FC<CheckboxAltProps> = ({ id, label, description, selected, sectionId, onCheck, ...props }) => {
    return (
        <div className="component-checkbox-alt">
            <Checkbox 
                color="primary"
                checked={selected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheck(sectionId, id, e.target.checked)}
            />
            <p className="container-text">
                <span className="text">{label}</span>
                {description ? <span className="description"> - {description}</span> : null}
            </p>
        </div>
    )
}

const DropdownCheckboxGroup: React.FC<DropdownCheckboxGroupProps> = ({ id, label, list, onGroupCheck, onCheck, ...props}) => {
    const [open, setOpen] = useState(true);

    const isGroupChecked = _.reduce(list, (acc, l) => acc && l.selected, list.length > 0);

    return (
        <div className="component-dropdown-checkbox-group" key={id}>
            <div className="dropdown-header">
                <Checkbox 
                    color="primary" 
                    checked={isGroupChecked} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onGroupCheck(id, e.target.checked)} 
                />
                <p className="title">{label}</p>
                <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => setOpen((open) => !open)}
                >
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </div>
            <Collapse in={open}>
                <div className="dropdown-container">
                    {_.map(list, (l, i) => (
                        !l.show ? undefined :
                        <CheckboxAlt 
                            id={l.id}
                            label={l.label}
                            description={l?.description}
                            selected={l.selected}
                            sectionId={id}
                            onCheck={onCheck}
                            key={i}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    )
}

export default DropdownCheckboxGroup;