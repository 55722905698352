import { FC, Fragment } from 'react'
import { ParsedArchive } from 'models/general'
import { CONCILIATION_LEDGER_ACCEPTED_FORMATS } from '../../../constants'
import { parseFileList, parseUploadedFiles } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import {
  UploadArchiveToPeriodResponse,
  uploadLedgerAccountFile,
} from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import Dropzone from 'components/Conciliations/Dropzone/Dropzone'
import MenuItemFileInput from '../MenuItemFileInput/MenuItemFileInput'

const LedgerAccountsFileWrapper: FC<{
  mode: 'dropzone' | 'option'
  onContinuousClick?: () => void
}> = ({ mode, onContinuousClick }) => {
  const { workspaceInfo, updateScreenLoading } =
    useConciliationsContainerContext()

  const { setDrawerFilelist } = useConciliationsContainerContext()

  const dialog = useDialog()

  const onUpload = async (data: UploadArchiveToPeriodResponse) => {
    const documents = parseUploadedFiles(data.documents)
    setDrawerFilelist({
      ocrMode: 'ledger',
      documents,
      onConfirm: () => {},
    })
  }

  const handleFileChange = async (files: FileList) => {
    updateScreenLoading(true)
    try {
      const archives = (await parseFileList(files)) as ParsedArchive[]
      const data = await uploadLedgerAccountFile(
        workspaceInfo.activeWorkspace,
        archives
      )
      updateScreenLoading(false)
      onUpload(data)
    } catch (error: any) {
      updateScreenLoading(false)
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo cargar el archivo. Inténtalo mas tarde.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  if (mode === 'option')
    return (
      <Fragment>
        <MenuItemFileInput
          onChange={handleFileChange}
          acceptedFormats={CONCILIATION_LEDGER_ACCEPTED_FORMATS}
          onContinuousClick={onContinuousClick}
        />
      </Fragment>
    )

  return (
    <Dropzone
      title="Por el momento no hay cuentas contables"
      subtitle="Para comenzar, arrastra o selecciona un archivo desde tu computadora"
      onChange={handleFileChange}
      options={{
        accept: CONCILIATION_LEDGER_ACCEPTED_FORMATS,
        multiple: true,
      }}
    />
  )
}

export default LedgerAccountsFileWrapper
