/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core'
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import './styles.scss'
import { GridCloseIcon } from '@material-ui/data-grid'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants'
import { useSelector } from 'react-redux'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import InvoicesTable from '../InvoicesTable/InvoicesTable'
import { getCfdiList } from 'services/invoices'
import Invoice from 'models/invoice'

interface FinderContextProps {
  list: Invoice[]
  setList: Dispatch<SetStateAction<Invoice[]>>
  value: string | undefined
  setValue: Dispatch<SetStateAction<string | undefined>>
  handleCancel: () => void
  handleSelect: (invoice: Invoice) => void
  ui: {
    title: string
    subtitle: string
  }
}

export const FinderContext = createContext<FinderContextProps>(
  {} as FinderContextProps
)

export const useQueryCFDI = (param?: string) => {
  const { setList } = useContext(FinderContext)
  const workspaceId = useSelector(selectActiveWorkspace)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<any>()
  const [hasSearched, setHasSearched] = useState(false)

  const query = async () => {
    setLoading(true)
    setHasSearched(!!param)
    try {
      const response = await getCfdiList(workspaceId, 0, 50, undefined, param)
      setList(response.list)
      setData(response.list)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    data,
    query,
    hasSearched,
  }
}

interface Props {
  onSelect: (invoice: Invoice) => void
  onCancel?: () => void
  title?: string
  subtitle?: string
}

const Search: FC = () => {
  const { value, setValue } = useContext(FinderContext)
  const { query, loading, hasSearched } = useQueryCFDI(value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      query()
    }
  }

  return (
    <Box marginTop={8}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              placeholder="Buscar por Folio, Serie, UUID o Razón Social."
              label="Buscar"
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              onChange={handleChange}
              onKeyUp={handleEnter}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={loading || (!value && !hasSearched)}
            onClick={query}>
            {loading ? <CircularProgress size={24} /> : 'Buscar'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const Header: FC = () => {
  const {
    handleCancel,
    ui: { title, subtitle },
  } = useContext(FinderContext)
  return (
    <Box className="header">
      <Box className="heading">
        <Box className="title">{title}</Box>
        <Box className="subtitle">{subtitle}</Box>
      </Box>
      <IconButton color="secondary" onClick={handleCancel}>
        <GridCloseIcon fontSize="large" />
      </IconButton>
    </Box>
  )
}

const CFDIFinder: FC<Props> = ({
  onSelect,
  onCancel,
  title = 'Nuevo CFDI',
  subtitle = 'Para comenzar, selecciona el tipo de comprobante.',
}) => {
  const [list, setList] = useState<Invoice[]>([])
  const [value, setValue] = useState<string | undefined>()
  const navigate = useNavigate()

  const handleCancel = () => {
    if (onCancel) onCancel()
    else {
      navigate(ROUTES.HOME, { replace: true })
    }
  }

  const handleSelect = (invoice: Invoice) => {
    onSelect(invoice)
  }

  const valueToProvide = useMemo(
    () => ({
      value,
      list,
      setList,
      setValue,
      handleCancel,
      handleSelect,
      ui: {
        title,
        subtitle,
      },
    }),
    [value, list]
  )

  return (
    <FinderContext.Provider value={valueToProvide}>
      <Box className="cfdi-finder-component">
        <Header />
        <Search />
        <InvoicesTable />
      </Box>
    </FinderContext.Provider>
  )
}

export default CFDIFinder
