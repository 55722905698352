import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { IntlProvider } from 'react-intl'
import 'moment/locale/es-mx'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@mui/material/styles'
import { getMessages } from 'i18n'
import DialogProvider from 'providers/DialogProvider/DialogProvider'
import LoaderProvider from 'providers/LoaderProvider/LoaderProvider'
//import reportWebVitals from './reportWebVitals';
import { theme } from './theme/material'
import { persistor, store } from './store/store'
import App from './App'

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlProvider
        messages={getMessages('es-MX')}
        locale="es-MX"
        defaultLocale="es-MX"
        onError={() => {}}>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <LoaderProvider>
                  <DialogProvider>
                    <App />
                  </DialogProvider>
                </LoaderProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
