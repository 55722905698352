import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
} from '@material-ui/core'
import BankIcon from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { ConciliationMovement as ConciliationMovementType } from 'models/conciliations'
import { toCurrency } from 'lib/helpers/utilities'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import Deconciliate from 'components/Conciliations/Deconciliate/Deconciliate'
import './styles.scss'

interface IItemProps {
  Icon: typeof LocalAtmIcon
  reference: string
  concept: string
  amount?: number
}

const Item = ({ Icon, reference, concept, amount }: IItemProps) => (
  <Box className="movement-item">
    <Icon className="movement-icon" />
    <Box className="movement-item-content">
      <Box className="movement-item-header">
        <Box className="movement-item-title">{reference}</Box>
        <Box flex={1} />
        <Box>{amount ? toCurrency(amount) : null}</Box>
      </Box>
      <Box className="movement-item-body">
        <Box>{concept}</Box>
      </Box>
    </Box>
  </Box>
)

const STATUS = {
  ACTIVE: 'Vigente',
  CANCELED: 'Cancelado',
  PENDING: 'Pendiente',
}

interface IConciliationMovementProps {
  conciliation: ConciliationMovementType
}

const ConciliationMovement = ({ conciliation }: IConciliationMovementProps) => {
  const { conciliations, setDrawerConciliation } =
    useConciliationsContainerContext()

  const { conciliationRows, setConciliationRows } = conciliations

  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState(true)

  const counterLabel = useMemo(() => {
    if (conciliation.cfdis)
      return `${conciliation.cfdis.length} CFDI${conciliation.cfdis.length > 1 ? 's' : ''}`
    if (conciliation.ledger)
      return `${conciliation.ledger.length} cuenta contable${conciliation.ledger.length > 1 ? 's' : ''}`
    return ''
  }, [conciliation])

  const isCancelled = useMemo(() => {
    if (conciliation.cfdis === undefined) return false
    return _.some(conciliation.cfdis, cfdi => cfdi.status === STATUS.CANCELED)
  }, [conciliation])

  const onDeconciliate = () => {
    const filtered = _.filter(conciliationRows, c => c.id !== conciliation.id)
    dispatch(setConciliationRows(filtered))
    setDrawerConciliation(filtered)
  }

  return (
    <Accordion
      defaultExpanded
      className="movement-accordion"
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      elevation={0}>
      <AccordionSummary className="movement-accordion-summary">
        <Box
          className={`movement-accordion-header ${isCancelled ? 'cancelled' : ''}`}>
          <ExpandMoreIcon
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
          <Box>1 Movimiento &nbsp;|&nbsp; {counterLabel}</Box>
          {isCancelled && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="Cancelado"
              className="cancelled-label"
            />
          )}
          <Box flex={1} />
          <Box>{toCurrency(conciliation.amount)}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className="movement-accordion-details">
        <Item
          Icon={BankIcon}
          reference={`REF: ${conciliation.reference}`}
          amount={conciliation.amount}
          concept={conciliation.concept}
        />
        {_.map(conciliation.cfdis, cfdi => (
          <Item
            key={cfdi.uuid}
            Icon={LocalAtmIcon}
            reference={`UUID: ${cfdi.uuid}`}
            amount={cfdi.amount}
            concept={cfdi.concept}
          />
        ))}
        {_.map(conciliation.ledger, ledger => (
          <Item
            key={ledger.code}
            Icon={LocalAtmIcon}
            reference={`Código: ${ledger.code}`}
            concept={ledger.name}
          />
        ))}
        <Box display="flex" justifyContent="flex-end">
          <Deconciliate
            movementId={conciliation.id}
            onComplete={onDeconciliate}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const ConciliationDetailsContent = () => {
  const { drawer } = useConciliationsContainerContext()

  if (!drawer.conciliation) return null
  return (
    <Box>
      {_.map(
        drawer.conciliation,
        (conciliation: ConciliationMovementType, index: number) => (
          <ConciliationMovement
            key={`${conciliation.id}-conciliation-movement-${index}`}
            conciliation={conciliation}
          />
        )
      )}
    </Box>
  )
}

export default ConciliationDetailsContent
