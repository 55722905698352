import Avatar from 'assets/account_circle.svg'
import SimmpleI from 'assets/favicon.png'
import Activity from 'components/Shared/Activity/Activity'
import './styles.scss'

interface IProfileHeaderProps {
  name: string
  email: string
  id?: number
  rate?: number
  className?: string
  fetching?: boolean
}

const ProfileHeader = ({
  name,
  email,
  id,
  className,
  fetching,
}: IProfileHeaderProps) => {
  return (
    <article className={`component-profile-header ${className ?? ''}`}>
      {fetching && <Activity size={30} local />}
      <div className="avatar">
        <img src={Avatar} alt="avatar" />
      </div>

      <div className="profile-info">
        {Boolean(id) && (
          <img className="employee-logo" src={SimmpleI} alt="Simmple" />
        )}
        {name && <span className='name'>{name}</span>}
        {email && <span className='email'>{email}</span>}
      </div>
    </article>
  )
}

export default ProfileHeader
