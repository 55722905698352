import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { months } from '../../../constants'
import { FC, useContext, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { AccountStatementHistoryContext } from 'components/Conciliations/AccountStatementHistory/AccountStatementHistory'
import _ from 'lodash'
import { parseRows } from 'components/Conciliations/AccountStatementHistoryTable/AccountStatementHistoryTable'
import { getStatementFilesByParams } from 'services/conciliations'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const AccountSelector: FC = () => {
  const [value, setValue] = useState<string | null>('')
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])
  const { rows, filters, setFilters } = useContext(
    AccountStatementHistoryContext
  )
  const { workspaceInfo } = useConciliationsContainerContext()

  const onChange = (event: React.ChangeEvent<{ value: any }>) =>
    setValue(event.target.value)

  useEffect(
    () => {
      if (workspaceInfo.activeWorkspace !== null) {
        setValue(null)
        setFilters({
          ...filters,
          account: '',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaceInfo.activeWorkspace /*, filters, setFilters*/]
  )

  useEffect(() => {
    setFilters({
      ...filters,
      account: value || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value /*, filters, setFilters*/])

  useEffect(() => {
    setOptions(
      _.map(
        _.uniqBy(
          _.filter(rows, row => row.bank !== '' && row.account !== ''),
          'account'
        ),
        row => ({
          label: `${row.bank} ${row.account.slice(-4)}`,
          value: row.account,
        })
      )
    )
  }, [rows])

  return (
    <Grid item xs={12} md={4}>
      <FormControl
        fullWidth
        style={{
          marginTop: '-16px',
        }}>
        <InputLabel
          id="account-input"
          style={{
            padding: '4px 14px',
          }}>
          Cuenta
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          fullWidth
          labelId="account-input"
          inputProps={{
            placeholder: 'Cuenta',
          }}
          input={
            <Input
              disableUnderline
              style={{
                border: '2px solid #999',
                padding: '3px 14px',
                borderRadius: 4,
              }}
            />
          }>
          <MenuItem value="" selected>
            Todos
          </MenuItem>
          {options.map((account, index) => (
            <MenuItem key={account.value} value={account.value}>
              {account.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

const YearSelector: FC = () => {
  const [value, setValue] = useState<number>(-1)
  const { filters, setFilters } = useContext(AccountStatementHistoryContext)

  useEffect(() => {
    if (value !== -1) {
      const state = { ...filters, year: value }
      if (state.month === 0) {
        state.month = 1
      }
      setFilters(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value /*, filters, setFilters*/])

  useEffect(() => {
    if (filters.year !== 0 && filters.year !== value && value === -1) {
      setValue(filters.year)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*value, */ filters])

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
  }

  const years = [2020, 2021, 2022, 2023, 2024, 2025]

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        fullWidth
        input={
          <Input
            disableUnderline
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Año
        </MenuItem>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const MonthSelector: FC = () => {
  const [value, setValue] = useState<number>(-1)
  const { filters, setFilters } = useContext(AccountStatementHistoryContext)

  useEffect(() => {
    if (value !== -1) {
      const state = { ...filters, month: value }
      if (state.year === 0) {
        state.year = new Date().getFullYear()
      }
      setFilters(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value /*, filters, setFilters*/])

  useEffect(() => {
    if (filters.month !== 0 && filters.month !== value && value === -1) {
      setValue(filters.month)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*value, */ filters])

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
  }

  const parsedMonths = Object.keys(months).map((key: string) => ({
    label: months[Number(key)],
    value: Number(key) - 1,
  }))

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        input={
          <Input
            disableUnderline
            placeholder="Mes"
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Mes
        </MenuItem>
        {parsedMonths.map(month => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const ActionButton: FC = () => {
  const {
    workspaceInfo: { activeWorkspace },
  } = useConciliationsContainerContext()
  const { filters, isLoading, setIsLoading, setRows, rows } = useContext(
    AccountStatementHistoryContext
  )
  const dialog = useDialog()

  const fetchRows = async () => {
    setIsLoading(true)
    try {
      const data = await getStatementFilesByParams(
        activeWorkspace,
        filters.account,
        filters.month,
        filters.year
      )
      setRows(parseRows(data.files))
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo descargar el archivo seleccionado. Intenta nuevamente.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (activeWorkspace !== null) {
      fetchRows()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspace /*, fetchRows*/])

  const onClick = () => {
    fetchRows()
  }

  useEffect(() => {
    if (
      filters.account === '' &&
      filters.month === 0 &&
      filters.year === 0 &&
      rows.length === 0
    ) {
      fetchRows()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters /*, rows, fetchRows*/])

  return (
    <Grid item xs={12} md={2}>
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        onClick={onClick}
        startIcon={
          isLoading ? (
            <CircularProgress
              size={18}
              variant="indeterminate"
              style={{ color: '#fff' }}
            />
          ) : (
            <SearchIcon />
          )
        }
        disabled={isLoading}
        style={{
          color: '#fff',
        }}>
        {isLoading ? 'Cargando...' : 'Buscar'}
      </Button>
    </Grid>
  )
}

const AccountStatementHistoryToolbarFilters: FC = () => {
  return (
    <Grid
      container
      spacing={1}
      style={{
        marginBlock: 20,
      }}>
      <AccountSelector />
      <MonthSelector />
      <YearSelector />
      <ActionButton />
    </Grid>
  )
}

export default AccountStatementHistoryToolbarFilters
