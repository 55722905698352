import { useContext } from 'react'
import { DialogContext } from 'providers/DialogProvider/DialogProvider'

export const useDialog = () => {
  const dialogContext = useContext(DialogContext)
  if (!dialogContext) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return dialogContext
}

export default useDialog
