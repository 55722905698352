import { useSelector } from 'react-redux'
import { GridColDef } from '@material-ui/data-grid'
import ArrowBack from 'assets/arrow_back.svg'
import CreditCardIcon from 'assets/CreditCardIcon'
import Delete from 'assets/delete.svg'
import { selectSelectedRecurrentPlan } from 'store/Payments/payments.selectors'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import Button from 'components/Shared/Button/Button'
import SydTable from 'components/Shared/SydTable/SydTable'
import './styles.scss'

const RecurrentPlanInformation = () => {
  const { goBackToPaymentsOverview, openRecurrentPlanCancellationModal } =
    usePaymentsContainerContext()

  const selectedRecurrentPlan = useSelector(selectSelectedRecurrentPlan)

  const recurrentPlanColumns: GridColDef[] = [
    {
      field: 'concept',
      headerName: 'Concepto',
      minWidth: 140,
      flex: 0,
      sortable: false,
      renderCell: ({ row: { concept } }) => concept,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: ({ row: { description } }) => description,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      minWidth: 120,
      flex: 0,
      sortable: false,
      renderCell: ({ row: { amount } }) => `$${amount}`,
    },
    {
      field: 'annotations',
      headerName: 'Anotaciones',
      minWidth: 480,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { annotations } }) => (
        <div className="annotations">{annotations}</div>
      ),
    },
    {
      field: 'lastDigits',
      headerName: 'Tarjeta',
      minWidth: 130,
      flex: 0,
      sortable: false,
      renderCell: ({ row: { lastDigits } }) => (
        <div className="card-number">
          <CreditCardIcon color="#444444" />
          <span>{lastDigits}</span>
        </div>
      ),
    },
    {
      field: '',
      headerName: 'Eliminar',
      minWidth: 140,
      flex: 0,
      sortable: false,
      renderCell: () => (
        <div className="delete-action">
          <img src={Delete} alt="icono de eliminar" />
          <button onClick={openRecurrentPlanCancellationModal}>Eliminar</button>
        </div>
      ),
    },
  ]

  return (
    <div className="recurrent-plan-information-component">
      <div className="header">
        <Button
          startIcon={<img src={ArrowBack} alt="flecha hacia la izquierda" />}
          variant="hover"
          onClick={goBackToPaymentsOverview}>
          Volver
        </Button>
      </div>
      <span className="table-title">Servicio domiciliado</span>
      {selectedRecurrentPlan && (
        <SydTable
          className="recurrent-plan-information-table"
          columns={recurrentPlanColumns}
          rowCount={1}
          rows={[selectedRecurrentPlan]}
          checkboxSelection={false}
          disableColumnMenu
          noBorder
        />
      )}
    </div>
  )
}

export default RecurrentPlanInformation
