import { IAccountingStepFiveCategoryFileStatus } from 'models/accounting.models'
import { formatDate, formatName } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import { useAccountingStepFiveContext } from '../AccountingStepFive/AccountingStepFive'
import { IAccountingStepFiveState } from '../AccountingStepFive/useAccountingStepFive'
import FileValidationCard from '../FileValidationCard/FileValidationCard'
import './styles.scss'
import ISydDocument from 'models/shared.models'

interface IAccountingFileDropzoneContentMultipleProps {
  uploadItemId: number
  uploadedFiles: ISydDocument[]
  fileStatuses?: IAccountingStepFiveCategoryFileStatus[]
  fileStatusesKey?: string
  filesToValidate?: ISydDocument[]
  uploadedAndValidatedFiles?: ISydDocument[]
}

const AccountingFileDropzoneContentMultiple = ({
  uploadItemId,
  uploadedFiles,
  fileStatuses,
  fileStatusesKey,
  filesToValidate,
  uploadedAndValidatedFiles,
}: IAccountingFileDropzoneContentMultipleProps) => {
  const { updateStepFiveState } = useAccountingStepFiveContext()

  const uploadedFile = uploadedFiles.find(
    file => file.idWsfile === uploadItemId
  )
  const fileToValidate = filesToValidate?.find(
    file => file.idWsfile === uploadItemId
  )
  const fileStatus = fileStatuses?.find(
    status => status.id === uploadItemId
  )?.status
  const uploadedAndValidatedFile = uploadedAndValidatedFiles?.find(
    file => file.idWsfile === uploadItemId
  )

  const toValidateOrValidatedFile = fileToValidate ?? uploadedAndValidatedFile

  const { handleFileDelete, handleFileDownload } = useAccountingContext()

  const updateFileStatuses = (key: string) => (value?: 'valid' | 'invalid') => {
    updateStepFiveState(prevState => {
      const fileStatuses = prevState[key as keyof IAccountingStepFiveState]

      if (Array.isArray(fileStatuses)) {
        return {
          ...prevState,
          [key as keyof IAccountingStepFiveState]: fileStatuses.map(status => {
            return status.id === uploadItemId
              ? { ...status, status: value }
              : status
          }),
        }
      }
      return prevState
    })

    key === 'firstCategoryFileStatuses' &&
      value === 'invalid' &&
      updateStepFiveState({
        clientCommentsDialog: true,
      })
  }

  return (
    <div className="accounting-file-dropzone-content-multiple-component">
      {uploadedFile && (
        <FileCardAlt
          key={uploadedFile.idWsfile}
          title={formatName(uploadedFile)}
          subtitle={formatDate()}
          type={'File'}
          onFileClick={() => handleFileDownload(uploadedFile)}
          actions={[
            {
              label: 'Renombrar archivo',
              action: () => updateStepFiveState({ selectedFile: uploadedFile }),
            },
            {
              label: 'Eliminar archivo',
              action: () => handleFileDelete(uploadedFile),
            },
          ]}
          error={uploadedFile.error}
          loading={uploadedFile.activity}
          className="file-zone"
        />
      )}
      {toValidateOrValidatedFile && fileStatusesKey && (
        <FileValidationCard
          variant="outlined"
          title={formatName(toValidateOrValidatedFile)}
          valid={fileStatus === 'valid'}
          invalid={fileStatus === 'invalid'}
          loading={toValidateOrValidatedFile.activity}
          onValidate={updateFileStatuses(fileStatusesKey)}
          onDownload={() => handleFileDownload(toValidateOrValidatedFile)}
          className="file-zone"
        />
      )}
    </div>
  )
}

export default AccountingFileDropzoneContentMultiple
