import { toast } from 'react-toastify'
import { SERVER_SENT_OPERATIONS } from 'lib/enums/shared.enums'
import { successToastOptions } from 'lib/utils/toast.utils'
import { ISSEEventData } from 'services/Shared/shared.models'
import useDialog from '../useDialog'

const useSynchronizationSSENotification = (): ((
  typedEventData: ISSEEventData
) => void) => {
  const dialog = useDialog()

  const notifySinchronization = (typedEventData: ISSEEventData) => {
    const { idOperation } = typedEventData
    const { numWorkspacesSuccess, workspacesError } = typedEventData.data

    if (numWorkspacesSuccess > 0) {
      const successMessage =
        idOperation === SERVER_SENT_OPERATIONS.CFDIS
          ? 'CFDIs sincronizados correctamente'
          : numWorkspacesSuccess > 1
            ? 'Estatus de opiniones sincronizados correctamente'
            : 'Estatus de opinión sincronizado correctamente'
      toast.success(successMessage, successToastOptions)
    }
    if (workspacesError.length > 0) {
      const errorMessage =
        idOperation === SERVER_SENT_OPERATIONS.COMPLIANCE
          ? 'No fue posible sincronizar el estatus de las opiniones de cumplimiento para los siguientes espacios de trabajo:'
          : 'No fue posible sincronizar los CFDIs para los siguientes espacios de trabajo:'

      dialog.danger({
        title: 'Error al sincronizar',
        message: errorMessage,
        modalContent: (
          <div className="error-details">
            {workspacesError.map(({ idWorkspace, error }) => (
              <p>
                {idWorkspace}: {error}
              </p>
            ))}
          </div>
        ),
      })
    }
  }

  return notifySinchronization
}

export default useSynchronizationSSENotification
