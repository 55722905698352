import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import {
  createClient,
  deleteClient,
  getClientById,
  getClientsList,
  updateClient,
} from 'services/clients'
import { setClientsList, setSelectedClient } from 'actions/clients'

import Help from '@material-ui/icons/Help'
import ErrorI from '@material-ui/icons/ErrorOutline'

import Client, { ClientForm } from 'models/client'
import { QueryOptions, MutationOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'
import { IApiError } from 'services/Shared/shared.models'

const useClientsList = (
  workspaceId: number,
  options: QueryOptions<Client[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Client[], any>(
    ['get-clients-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getClientsList(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setClientsList(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de Clientes.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useClient = (
  workspaceId: number,
  clientId: number,
  options: QueryOptions<Client> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Client, any>(
    ['get-client', clientId, workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getClientById(workspaceId, clientId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setSelectedClient(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del cliente.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useAddClient = (
  workspaceId: number,
  options: MutationOptions<Client, ClientForm> = {}
): UseMutationResult<Client, IApiError, ClientForm> => {
  const dialog = useDialog()
  const { onSuccess, onError } = options

  const mutation = useMutation<Client, any, ClientForm>(
    form => createClient(workspaceId, form),
    {
      onSuccess: (data, form, ctx) => {
        onSuccess && onSuccess(data, form, ctx)
      },
      onError: async (error, form, ctx) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useUpdateClient = (
  workspaceId: number,
  client?: Client,
  options: MutationOptions<void, ClientForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, ClientForm>(
    form => {
      if (!client) throw new Error('Cliente cargado incorrectamente')
      onBefore && onBefore(form)
      return updateClient(workspaceId, client.id, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar la información del cliente seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useDeleteClient = (
  workspaceId: number,
  options: MutationOptions<void, Client> = {}
) => {
  const dialog = useDialog()

  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const { noDialog, onSuccess, onError } = options

  const mutation = useMutation<void, any, Client>(
    async client => {
      const resp = await dialog.dangerAsync({
        title: '¿Estás seguro?',
        subtitle: 'Estás a punto de eliminar a este cliente.',
        icon: Help as TModalIcon,
        primaryButtonText: 'Si, eliminar',
        secondaryButtonText: 'Cancelar',
      })
      if (resp) {
        showAdditionalInformationLoading()
        return await deleteClient(workspaceId, client.id)
      }
      if (!resp) throw new Error('mutation-cancelled')
    },
    {
      onSuccess: (data, client, ctx) => {
        dialog.close()
        onSuccess && onSuccess(data, client, ctx)
      },
      onError: async (error, emisor, ctx) => {
        if (error.message === 'mutation-cancelled') return

        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo eliminar el cliente seleccionado.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(emisor)
              return
            }
          }

          onError && onError(error, emisor, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export default useClientsList
