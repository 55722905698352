import _ from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Download from '@mui/icons-material/GetApp'
import { Grid } from '@mui/material'
import { ACCOUNTING_FILE_TYPE_CATALOGUE } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import { TaskAlt } from 'components/Shared/CustomIcons'
import './styles.scss'

const AccountingStepSix = () => {
  const {
    stepChangeLoading,
    filesInProcess,
    goToDocumentation,
    handleStepBack,
    handleFileDownload,
  } = useAccountingContext()

  const thirdFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, ['id', 6])
  const thirdFile = _.find(
    filesInProcess,
    f => thirdFileType?.type === f?.description
  )

  return (
    <div className="step-container step-six">
      <div className="content">
        <Grid container>
          <Grid item xs={12} className="step-icon-container">
            <TaskAlt className="icon" color="secondary" fontSize="inherit" />
          </Grid>
          <Grid item xs={12} className="step-header-container">
            <h1>Estas al corriente con tus impuestos</h1>
          </Grid>
          <Grid item xs={12} className="step-message-container">
            <p>
              Tus impuestos fueron pagados correctamente. Puedes descargar el
              Acuse de Pago
              <br />o ver todos los documentos en{' '}
              <span className="link" onClick={goToDocumentation}>
                Gestión Documental
              </span>
            </p>
          </Grid>
          <Grid item xs={12} className="step-actions">
            <Button
              id="stepFiveDownloadBtn"
              variant="outlined"
              color="warning"
              startIcon={<Download />}
              onClick={() =>
                !_.isNil(thirdFile) && handleFileDownload(thirdFile)
              }>
              Descargar Acuse de Pago
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="screen-actions">
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleStepBack}
          activity={stepChangeLoading}>
          Ir a la sección anterior
        </Button>
      </div>
    </div>
  )
}

export default AccountingStepSix
