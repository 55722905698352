import { Menu, MenuProps, withStyles } from "@material-ui/core";

const StyledMenu = withStyles({
  paper: {
    borderRadius: '0',
    minWidth: '200px',
    '& .MuiList-root': {
      '& .MuiListItem-root': {
        gap: 12
      }
    }
  },
})(
  (props: MenuProps) => (
    <Menu
      getContentAnchorEl={null}
      elevation={1}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  )
);

export default StyledMenu;