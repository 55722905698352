import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import ConciliationTableFilter from '../ConciliationTableFilter/ConciliationTableFilter'

const AccountMovementsFilters = () => {
  const { statements } = useConciliationsContainerContext()

  const { statementFilters, setConciliationStatementFilters } = statements

  const dispatch = useDispatch()

  const handleFilterChange = (values: any) => {
    dispatch(
      setConciliationStatementFilters({
        ...statementFilters,
        text: values.name,
        fromDate: values.fromDate,
        toDate: values.toDate,
        type: values.type,
      })
    )
  }

  const fields = useMemo(
    () => ({
      name: {
        name: 'name',
        placeholder:
          'Buscar por RFC, referencia bancaria, monto, beneficiario u ordenante',
        enabled: true,
        defaultValue: statementFilters.text,
      },
      fromDate: {
        name: 'fromDate',
        enabled: true,
        defaultValue: statementFilters.fromDate,
      },
      toDate: {
        name: 'toDate',
        enabled: true,
        defaultValue: statementFilters.toDate,
      },
      type: {
        name: 'type',
        placeholder: 'Tipo de movimiento',
        enabled: true,
        defaultValue: statementFilters.type || '',
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Retiro',
            value: 'withdrawal',
          },
          {
            label: 'Depósito',
            value: 'deposit',
          },
        ],
      },
    }),
    [statementFilters]
  )

  return (
    <Box marginTop={1}>
      <ConciliationTableFilter onChange={handleFilterChange} fields={fields} />
    </Box>
  )
}

export default AccountMovementsFilters
