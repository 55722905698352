import React from 'react'
import MUIAlert, { AlertProps as MUIAlertProps } from '@material-ui/lab/Alert';
import MUIAlertTitle from '@material-ui/lab/AlertTitle';

import './style.scss';

interface AlertProps extends MUIAlertProps {
    title?: string
    message?: string
    float?: boolean
}

const Alert: React.FC<AlertProps> = (props) => {
    const {
        className,
        title,
        message,
        float,
        children,
        ...rest
    } = props;

    return (
        <MUIAlert className={`component-alert ${className || ""} ${float ? "float" : ""}`.trim()} {...rest}>
            {Boolean(title) &&
                <MUIAlertTitle>
                    {title}
                </MUIAlertTitle>
            }
            {message || children}
        </MUIAlert>
    )
}

export default Alert;
