import { Box, IconButton } from '@material-ui/core'
import { useMemo } from 'react'
import { useAccountantsContext } from '../AccountantsContextProvider/AccountantsContextProvider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import _ from 'lodash'
import './styles.scss'

const AccountantsTableFooter = () => {
  const { accountants, page, elements, isLoading, updateAccountantsState } =
    useAccountantsContext()

  const disabledNextButton = useMemo(() => {
    if (isLoading) return true
    if (accountants.length < elements) return true
    if (_.isEmpty(accountants)) return true
    return false
  }, [elements, accountants, isLoading])

  const disabledPrevButton = useMemo(() => {
    if (isLoading) return true
    if (page === 0) return true
    return false
  }, [page, isLoading])

  const onNext = () => {
    updateAccountantsState({ page: page + 1 })
  }

  const onPrev = () => {
    if (page === 0) return
    updateAccountantsState({ page: page - 1 })
  }

  return (
    <Box className="nav-footer">
      <Box>{`${page + 1} - ${elements}`}</Box>
      <IconButton
        disabled={disabledPrevButton}
        onClick={onPrev}
        className="nav-button">
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        disabled={disabledNextButton}
        onClick={onNext}
        className="nav-button">
        <ChevronRightIcon />
      </IconButton>
    </Box>
  )
}

export default AccountantsTableFooter
