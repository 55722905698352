import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaymentsStore } from 'models/redux'
import { PaymentStep } from 'lib/enums/payments.enums'
import {
  IPlanInformation,
  ISelectedRecurrentPlan,
} from 'models/payments'

const initialState: PaymentsStore = {
  paymentStep: PaymentStep.BILLING_INFORMATION,
  isRecurringPayment: false,
}

const paymentsSlice = createSlice({
  name: 'paymentsSlice',
  initialState,
  reducers: {
    setPaymentStep(state, action: PayloadAction<PaymentStep>) {
      state.paymentStep = action.payload
    },
    setSelectedRecurrentPlan(
      state,
      action: PayloadAction<ISelectedRecurrentPlan>
    ) {
      state.selectedRecurrentPlan = action.payload
    },
    setIsRecurringPayment(state, action: PayloadAction<boolean>) {
      state.isRecurringPayment = action.payload
    },
    setSelectedPlan(state, action: PayloadAction<IPlanInformation>) {
      state.selectedPlan = action.payload
    },
  },
})

export const {
  setPaymentStep,
  setSelectedRecurrentPlan,
  setSelectedPlan,
  setIsRecurringPayment,
} = paymentsSlice.actions

export default paymentsSlice.reducer
