import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { IOInputProps } from 'react-io-forms'
import _ from 'lodash'

import Card from '@material-ui/core/Card'

import Upload from '@material-ui/icons/Publish'
import Check from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import './style.scss'

import { Archive } from 'models/general'
interface dropCardProps extends IOInputProps {
  accept?: string
  limit?: number
  base64?: boolean
  invalid?: boolean
  onFileChange?: (file: Archive) => void
}

interface DropWrapperProps extends dropCardProps {
  children: React.ReactNode
}

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const extractValue = async (
  e: any,
  files: FileList | null,
  onFileChange: any,
  inputFile: any
) => {
  e.stopPropagation()
  e.preventDefault()
  if (files && files.length > 0) {
    const file = files[0]
    const resultado = {
      fileName: file.name,
      extension: _.split(file.name, '.').pop(),
      data: await getBase64(file),
    }
    if (onFileChange) {
      onFileChange(resultado as Archive)
    }
  } else {
    inputFile.current.value = null
  }
}

const onDragOver = (e: any) => {
  e.stopPropagation()
  e.preventDefault()
  e.dataTransfer.dropEffect = 'copy'
}

const onDrop = (e: any, onFileChange: any, inputFile: any) => {
  e.stopPropagation()
  e.preventDefault()
  extractValue(e, e.dataTransfer.files, onFileChange, inputFile)
}

const onClick = (value: any, inputFile: any, force?: boolean) => {
  if (force || _.isEmpty(value)) {
    inputFile.current.value = null
    inputFile.current.click()
  }
}

const DropCard: React.FC<dropCardProps> = ({
  invalid,
  value,
  accept = '',
  limit = 4,
  onFileChange,
  ...props
}) => {
  const intl = useIntl()
  const inputFile: any = useRef()

  return (
    <Card
      className={`component-drop-card ${_.isEmpty(value) ? 'no-value' : ''} ${!_.isEmpty(value) ? 'valid' : ''} ${invalid ? '' : ''}`}
      onClick={() => onClick(value, inputFile)}
      onDrop={e => onDrop(e, onFileChange, inputFile)}
      onDragOver={onDragOver}
      elevation={0}>
      <input
        ref={inputFile}
        onChange={e => extractValue(e, e.target.files, onFileChange, inputFile)}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
      />
      <div className="icon-container">
        {_.isEmpty(value) ? (
          <Upload fontSize="inherit" />
        ) : invalid ? (
          <Error fontSize="inherit" />
        ) : (
          <Check fontSize="inherit" />
        )}
      </div>
      <div className="text-container">
        <h5 className="name-label">
          {intl.formatMessage({ id: 'drop.upload.file' })}
        </h5>
        {!_.isEmpty(value) && (
          <p
            className="remove-label"
            onClick={() => onClick(value, inputFile, true)}>
            {intl.formatMessage({ id: 'form.file.remove' })}
          </p>
        )}
      </div>
    </Card>
  )
}

export const DropWrapper = (props: DropWrapperProps) => {
  const { value, invalid, onFileChange, accept = '', children } = props
  const inputFile: any = useRef()
  return (
    <div
      className={`component-drop-wrapper ${_.isEmpty(value) ? 'no-value' : ''} ${!_.isEmpty(value) ? 'valid' : ''} ${invalid ? '' : ''}`}
      onDrop={e => onDrop(e, onFileChange, inputFile)}
      onDragOver={onDragOver}>
      {children}
      <input
        ref={inputFile}
        onChange={e => extractValue(e, e.target.files, onFileChange, inputFile)}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default DropCard
