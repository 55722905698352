import { Box, LinearProgress, Tooltip, Typography } from '@material-ui/core'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { orange, red } from '@material-ui/core/colors'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSelectionModel,
} from '@material-ui/data-grid'

import SwapVerticalIcon from '@material-ui/icons/SwapVert'
import { CFDIStatement } from 'models/conciliations'
import { CFDIRow } from 'models/redux'

import { toCurrency, useCustomDataGridStyle } from 'lib/helpers/utilities'
import {
  getActualSelectedCFDIs,
  getActualSelectedStatements,
} from 'hooks/useConciliate'
import useDialog from 'hooks/Shared/useDialog'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { OrangeCheckbox } from '../AccountMovementsTable/AccountMovementsTable'
import CFDIFileWrapper from '../CFDIFileWrapper/CFDIFileWrapper'
import GridTableFooter from '../GridTableFooter/GridTableFooter'
import './styles.scss'

const SelectFile = () => {
  return (
    <Box height={300}>
      <CFDIFileWrapper />
    </Box>
  )
}

const STATUSES = {
  paid: 'paid',
  canceled: 'canceled',
  charged: 'charged',
  partCharged: 'part-charged',
  partPaid: 'part-paid',
  pendingCanceled: 'pending-canceled',
}

const STATUS = [
  {
    status: STATUSES.canceled,
    label: 'Cancelado',
    color: red[500],
    abbr: 'C',
  },
  {
    status: STATUSES.pendingCanceled,
    label: 'Pendiente por cancelar',
    color: red[500],
    abbr: '',
  },
  {
    status: STATUSES.partCharged,
    label: 'Cobrado parcialmente',
    color: orange[500],
    abbr: 'P',
  },
  {
    status: STATUSES.partPaid,
    label: 'Pagado parcialmente',
    color: orange[500],
    abbr: 'P',
  },
  {
    status: STATUSES.paid,
    label: 'Pagado',
    color: '#6AD958',
    abbr: '',
  },
  {
    status: STATUSES.charged,
    label: 'Cobrado',
    color: '#6AD958',
    abbr: '',
  },
]

const CFDIMovementsTable = () => {
  const {
    isLoadingCFDIs,
    cfdiSuggestions,
    statements,
    cfdis,
    totalElements,
    setDrawerCFDI,
  } = useConciliationsContainerContext()

  const { selectedStatementRows } = statements

  const {
    cfdiRows,
    selectedCfdiRows,
    cfdiActiveSection,
    setConciliationCfdiRows,
    setConciliationSelectedCfdiRows,
  } = cfdis

  const dispatch = useDispatch()

  const hasStatements = useMemo(() => cfdiRows.length > 0, [cfdiRows])

  const classes = useCustomDataGridStyle()

  const getColor = (status: string) => {
    return _.find(STATUS, { status })?.color || '#DDDDDD'
  }

  const getContent = (status: string) => {
    return _.find(STATUS, { status })?.abbr || ''
  }

  const getTooltip = (status: string) => {
    return _.find(STATUS, { status })?.label || 'Vigente'
  }

  const getIsPartiallyConcilied = (params: GridCellParams) => {
    return params.getValue(params.id, 'amountRemaining') !== undefined
  }

  const getIsOut = (params: GridCellParams) => {
    if (getIsPartiallyConcilied(params) === true) {
      const value = params.getValue(params.id, 'amountRemaining') as number
      if (value < 0) return true
      return false
    }
    const isOut = params.getValue(params.id, 'out') as boolean
    return isOut
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Box
            className="cell"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gridGap={8}>
            <Tooltip
              title={getTooltip(
                params.getValue(params.id, 'statusPayment') as string
              )}>
              <Box
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 100,
                  backgroundColor: getColor(
                    params.getValue(params.id, 'statusPayment') as string
                  ),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 10,
                  color: 'white',
                }}>
                <Box>
                  {getContent(
                    params.getValue(params.id, 'statusPayment') as string
                  )}
                </Box>
              </Box>
            </Tooltip>
            <Typography>{params.formattedValue}</Typography>
          </Box>
        )
      },
    },
    {
      field: 'receiver',
      headerName: 'Razón social',
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      field: 'concept',
      headerName: 'Concepto',
      flex: 1,
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Box
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              width: '100%',
            }}
            onClick={() => setDrawerCFDI(params.row)}>
            {params.value || 'Sin concepto'}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Monto',
      type: 'number',
      align: 'right',
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Box
            className="cell"
            display="flex"
            justifyContent="flex-end"
            color={getIsOut(params) === true ? 'black' : 'secondary.dark'}
            title={
              getIsOut(params) === true
                ? 'El monto conciliado es menor al monto total'
                : 'Monto total'
            }>
            {getIsOut(params) === true &&
            getIsPartiallyConcilied(params) === false
              ? '-'
              : ''}
            {getIsPartiallyConcilied(params) === true
              ? toCurrency(
                  params.getValue(params.id, 'amountRemaining') as number
                )
              : toCurrency(params.value as number)}
          </Box>
        )
      },
    },
    {
      field: 'suggested',
      headerName: 'Sugerido',
      hide: true,
    },
  ]

  useEffect(() => {
    dispatch(
      setConciliationSelectedCfdiRows(
        _.map(_.filter(cfdiRows, { conciliated: true }), (_: CFDIRow) => _.id)
      )
    )
  }, [cfdiRows, dispatch, setConciliationSelectedCfdiRows])

  const dialog = useDialog()

  const getIsConcilied = (params: GridRowParams) => {
    const isConcilied = params.getValue(params.id, 'conciliated') as boolean
    return isConcilied
  }

  const actualSelected = useMemo(() => {
    return _.map(
      _.filter(
        cfdiRows,
        (row: any) =>
          selectedCfdiRows.includes(row.id) && row.conciliated === false
      ),
      (row: any) => row.id
    )
  }, [cfdiRows, selectedCfdiRows])

  const handleSelectionModelChange = async (params: GridSelectionModel) => {
    const selectedTransactions = getActualSelectedStatements(statements)
    const selectedCFDIs = getActualSelectedCFDIs({
      ...cfdis,
      selectedCfdiRows: params as number[],
    })
    if (
      selectedTransactions.length > 1 &&
      selectedCFDIs.length > 1 &&
      cfdiActiveSection === 0
    ) {
      return await dialog.primaryAsync({
        title: 'Acción no permitida',
        subtitle:
          'Sólo puedes seleccionar un CFDI cuando varios movimientos bancarios son seleccionados.',
        primaryButtonText: 'Aceptar',
      })
    }
    dispatch(setConciliationSelectedCfdiRows(params))
  }
  const getIsSuggestion = (params: GridRowParams) => {
    const currentValue = params.getValue(params.id, 'uuid') as string
    const isSuggestion = _.find(
      cfdiSuggestions,
      (suggestion: any) => suggestion.uuid === currentValue
    )
    return isSuggestion && selectedStatementRows.length === 1
  }

  useEffect(() => {
    const suggestionUUIDs = new Set(
      cfdiSuggestions.map(suggestion => suggestion.uuid)
    )

    let lastId = cfdiRows.length

    const suggestionRows: any[] = cfdiSuggestions
      .filter(
        suggestion =>
          suggestion.uuid &&
          cfdiRows.find(
            (row: CFDIStatement) => row.uuid === suggestion.uuid
          ) === undefined
      )
      .map(suggestion => ({
        ...suggestion,
        id: lastId++,
      }))

    const rows = [...suggestionRows, ...cfdiRows]

    dispatch(
      setConciliationCfdiRows(
        rows.map(row => ({
          ...row,
          suggested: suggestionUUIDs.has(row.uuid),
        }))
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*cfdiRows,*/ cfdiSuggestions /*, dispatch, setConciliationCfdiRows*/])

  const hydratedRows = useMemo(() => {
    return cfdiRows
  }, [cfdiRows])

  const sortModel = useMemo(() => {
    if (_.isEmpty(selectedStatementRows) || _.isEmpty(cfdiSuggestions)) return

    const field = _.isEmpty(cfdiSuggestions) ? 'date' : 'suggested'

    return [
      {
        field,
        sort: 'desc' as 'desc',
      },
    ]
  }, [cfdiSuggestions, selectedStatementRows])

  return (
    <Box className="account-movements-table-component">
      <DataGrid
        rows={hydratedRows}
        classes={classes}
        columns={columns}
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedCfdiRows}
        onSelectionModelChange={handleSelectionModelChange}
        headerHeight={70}
        density="compact"
        loading={isLoadingCFDIs}
        sortModel={sortModel}
        style={{
          border: 'none',
          fontSize: 14,
        }}
        getRowClassName={params => {
          const isConcilied = getIsConcilied(params)
          const isSuggested = getIsSuggestion(params)
          return `
              ${isConcilied && 'concilied'}
              ${isSuggested && 'suggested'}
            `
        }}
        hideFooter
        components={{
          NoRowsOverlay: () => <SelectFile />,
          ColumnResizeIcon: () => null,
          ColumnSortedAscendingIcon: SwapVerticalIcon,
          ColumnSortedDescendingIcon: SwapVerticalIcon,
          Checkbox: OrangeCheckbox,
          LoadingOverlay: LinearProgress,
        }}
      />
      {hasStatements && (
        <GridTableFooter
          rows={cfdiRows}
          selectedRows={actualSelected}
          property="total"
          totalElements={totalElements}
        />
      )}
    </Box>
  )
}

export default CFDIMovementsTable
