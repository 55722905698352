import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import { CardProps } from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import ColaboratorCard from 'components/Workspaces/CollaboratorCard/CollaboratorCard'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import SelectField from 'components/FormComponents/IOComponents/SelectField/SelectField'

import { selectProfile } from 'lib/helpers/selectors'

import {
  useAddCollaborator,
  useCollaboratorList,
  useDeleteCollaborator,
  useUpdateCollaborator,
} from 'hooks/queries/workspaces'

import { nameBuilder } from 'lib/helpers/utilities'

import Add from '@material-ui/icons/Add'
import './style.scss'

import { WorkspaceRolesNames } from 'models/general'
import Workspace, {
  EditColaboratorForm,
  InvitationForm,
} from 'models/workspace'

interface Props extends CardProps {
  workspace: Workspace
  onRedirect: (action: string, id?: number) => void
}

const CollaboratorsContainer: React.FC<Props> = ({
  workspace,
  onRedirect,
  className = '',
}) => {
  const profile = useSelector(selectProfile)

  // #region Queries
  const listQuery = useCollaboratorList(workspace.id, {
    onError: () => onRedirect('error'),
  })
  // #endregion

  // #region Mutation
  const addMutation = useAddCollaborator(workspace.id, {
    onSuccess: () => {
      listQuery.refetch()
      setColaboratorId(undefined)
      setValidForm(false)
    },
  })
  const editMutation = useUpdateCollaborator(workspace.id, {
    onSuccess: () => {
      listQuery.refetch()
      setColaboratorId(undefined)
      setValidForm(false)
    },
  })
  const deleteMutation = useDeleteCollaborator(workspace.id, {
    onSuccess: () => listQuery.refetch(),
  })
  // #endregion

  const [colaboratorId, setColaboratorId] = useState<number>()
  const [validForm, setValidForm] = useState(false)

  const isAdmin = workspace?.currentRole.roleName === 'ADMIN'
  const selectedColaborator = _.find(
    workspace?.userList,
    user => user.idUser === colaboratorId
  )

  return (
    <div className={`container-collaborators-list ${className}`}>
      <div className="title-container">
        <p>Colaboradores ({listQuery?.data?.length || 0})</p>
        <Button
          variant="outlined"
          color="warning"
          startIcon={<Add />}
          onClick={() => setColaboratorId(0)}
          disabled={!isAdmin}>
          Colaborador
        </Button>
      </div>
      <Grid container spacing={4} className="colaborators-list">
        {listQuery.isLoading && (
          <Grid item xs={12} className="loading-container">
            <CircularProgress color="primary" size={60} />
          </Grid>
        )}
        {!(listQuery.isLoading || listQuery.isError) &&
          _.map(listQuery?.data, (user, i) => (
            <Grid key={i} item xs={12} md={4}>
              <ColaboratorCard
                id={user.idUser}
                fullname={nameBuilder(
                  user.name || '',
                  user.lastname || '',
                  user?.lastname2
                )}
                email={user.email}
                role={_.map(
                  _.split(user.role?.roleName, ' '),
                  _.capitalize
                ).join(' ')}
                isEmployee={Boolean(user.isSimmple)}
                rate={user.stars || 0}
                onEdit={isAdmin ? id => setColaboratorId(id) : undefined}
                onDelete={
                  isAdmin &&
                  profile.idUser !== user.idUser &&
                  user.role?.roleName !== 'ADMIN'
                    ? () => deleteMutation.mutate(user)
                    : undefined
                }
              />
            </Grid>
          ))}
      </Grid>

      {/** Add Collab Dialog */}
      <SydDialog
        open={!_.isUndefined(colaboratorId) && colaboratorId === 0}
        title="Invitar colaborador"
        subtitle="Ingresa el correo electrónico y selecciona un rol para invitar a un nuevo colaborador a este espacio de trabajo"
        primaryButtonText="Invitar"
        loadingPrimaryButton={addMutation.isLoading}
        disablePrimaryButton={!validForm}
        secondaryButtonText="Cancelar"
        disableSecondaryButton={addMutation.isLoading}
        onCancel={() => {
          setColaboratorId(undefined)
          setValidForm(false)
        }}
        form="inviteForm"
        sideButtons>
        <Form
          id="inviteForm"
          reset
          onSubmit={async (form: InvitationForm) => addMutation.mutate(form)}
          onValidity={(v: boolean) => setValidForm(v)}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Correo electrónico"
                validate={['isEmail']}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                name="role"
                label="Rol"
                options={[
                  { label: 'Administrador', value: WorkspaceRolesNames.admin },
                  {
                    label: 'Contador Sr.',
                    value: WorkspaceRolesNames.contadorSr,
                  },
                  {
                    label: 'Contador Jr.',
                    value: WorkspaceRolesNames.contadorJr,
                  },
                ]}
                filterOut={(v: any) => v.value}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>

      {/** Edit Collab Dialog */}
      <SydDialog
        open={!_.isUndefined(colaboratorId) && colaboratorId > 0}
        title="Editar colaborador"
        subtitle="Puedes cambiar el rol de este colaborador en este espacio de trabajo"
        primaryButtonText="Guardar cambios"
        loadingPrimaryButton={editMutation.isLoading}
        disablePrimaryButton={!validForm}
        secondaryButtonText="Cancelar"
        disableSecondaryButton={editMutation.isLoading}
        onCancel={() => {
          setColaboratorId(undefined)
          setValidForm(false)
        }}
        form="editForm"
        sideButtons>
        <Form
          id="editForm"
          reset
          onSubmit={async (form: EditColaboratorForm) => {
            if (!colaboratorId) return
            return editMutation.mutate({
              form,
              collabId: colaboratorId,
            })
          }}
          onValidity={(v: boolean) => setValidForm(v)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SelectField
                name="role"
                label="Rol"
                options={[
                  { label: 'Administrador', value: WorkspaceRolesNames.admin },
                  {
                    label: 'Contador Sr.',
                    value: WorkspaceRolesNames.contadorSr,
                  },
                  {
                    label: 'Contador Jr.',
                    value: WorkspaceRolesNames.contadorJr,
                  },
                ]}
                value={selectedColaborator?.role}
                filterIn={(v: any) => ({ value: v?.roleName })}
                filterOut={(v: any) => v.value}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>
    </div>
  )
}

export default CollaboratorsContainer
