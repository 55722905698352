import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useAppDispatch } from 'store/hooks'
import { setClientId } from 'store/SSE/sse.reducer'
import { IApiError } from 'services/Shared/shared.models'
import { initializeSSEInstance } from 'services/Shared/shared.services'

const useInitializeSSEClientId = (): UseMutationResult<
  string,
  IApiError,
  void
> => {
  const dispatch = useAppDispatch()

  const mutation = useMutation<string, IApiError, void>(
    () => initializeSSEInstance(),
    {
      onSuccess: clientId => {
        dispatch(setClientId(clientId))
      },
    }
  )

  return mutation
}

export default useInitializeSSEClientId
