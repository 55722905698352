import _ from 'lodash'
import Success from '@mui/icons-material/Check'
import Error from '@mui/icons-material/PriorityHigh'
import { TAccountingStepStatus } from 'models/accounting.models'
import './style.scss'

interface Step {
  label: string
  onClick?: (step: number) => void
  status?: TAccountingStepStatus
}

interface IStepperProps {
  steps: Step[]
  hideLabels?: boolean
}

const Stepper = ({ steps, hideLabels, ...props }: IStepperProps) => {
  return (
    <div className="component-stepper">
      <div className="stepper-container">
        {_.map(steps, ({ label, onClick = undefined, status = '' }, i) => (
          <div className="step-container" key={i}>
            <h4
              onClick={() => onClick && onClick(i + 1)}
              className={`value ${status || ''} ${onClick ? 'clickable' : ''}`.trim()}>
              {status === 'success' && <Success />}
              {status === 'error' && <Error />}
              {(!status || status === 'current') && i + 1}
            </h4>
            {!hideLabels && (
              <p className={`label ${status || ''}`.trim()}>{label}</p>
            )}
          </div>
        ))}
      </div>
      <div className="connector-container">
        {_.map(steps, ({ status = '' }, i) => {
          if (!i) return
          return (
            <div key={i} className={`connector-line ${status || ''}`.trim()} />
          )
        })}
      </div>
    </div>
  )
}

export default Stepper
