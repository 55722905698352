import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import AddWorkspaceForm from 'containers/Workspaces/AddWorkspaceFormContainer/AddWorkspaceFormContainer';

import { ROUTES } from '../../../constants';

import CreateWorkspaceImage from 'assets/SMPL_Create_Workspace_885x200.png';
import Logo from 'assets/logo.svg';
import './style.scss';

interface Props { };

const AddWorkspaceScreen: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const onRouteChange = (action: string, id: number = 0) => {
        if (action === "error")
            navigate(ROUTES.HOME, { replace: true });
        if (action === "cancel" || action === "success")
            navigate(ROUTES.HOME);
    }

    return (
        <Grid container spacing={0} direction="row" justifyContent="center" className="screen-create-workspace">
            <Grid item xs={12} className="logo-container">
                <img src={Logo} alt="Logo" />
            </Grid>
            <Grid item xs={12} className="card-container">
                <Card elevation={1}>
                    <CardMedia className="image" image={CreateWorkspaceImage} />
                    <AddWorkspaceForm onRedirect={onRouteChange} />
                </Card>
            </Grid>
        </Grid>
    )
}

export default AddWorkspaceScreen;