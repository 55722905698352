import { FC, Fragment, useMemo } from 'react'
import _ from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { toCurrency } from 'lib/helpers/utilities'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import DetailsItem from '../DetailsItem/DetailsItem'

const CFDIMovementDrawerContent: FC = () => {
  const { drawer } = useConciliationsContainerContext()

  const details = useMemo(() => {
    if (!drawer.cfdi) return null
    return [
      {
        label: 'Fecha',
        value: drawer.cfdi.date,
      },
      {
        label: 'Hora',
        value: drawer.cfdi.hour,
      },
      {
        label: 'Serie - folio',
        value: `${drawer.cfdi.serie} - ${drawer.cfdi.invoice}`,
      },
      {
        label: 'UUID',
        value: drawer.cfdi.uuid,
      },
      {
        label: 'Receptor',
        value: drawer.cfdi.receiver,
      },
      {
        label: 'RFC Recepetor',
        value: drawer.cfdi.receiverRFC || 'N/A',
      },
      {
        label: 'Emisor',
        value: drawer.cfdi.transmitter,
      },
      {
        label: 'RFC Emisor',
        value: drawer.cfdi.issuerRfc || 'N/A',
      },
      {
        label: 'Subtotal',
        value: toCurrency(
          drawer.cfdi.subtotal,
          _.get(drawer.cfdi, 'currency', 'MXN')
        ),
      },
      {
        label: 'Impuestos',
        value: toCurrency(
          drawer.cfdi.taxes,
          _.get(drawer.cfdi, 'currency', 'MXN')
        ),
      },
      {
        label: 'Total',
        value: toCurrency(
          drawer.cfdi.total,
          _.get(drawer.cfdi, 'currency', 'MXN')
        ),
      },
      {
        label: 'Tipo de operación',
        value: drawer.cfdi.type,
      },
    ]
  }, [drawer.cfdi])

  if (!details) {
    return <Typography>No se ha encontrado el CFDI</Typography>
  }

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gridGap={22}>
        {details.map((detail, index) => (
          <DetailsItem key={index} label={detail.label} value={detail.value} />
        ))}
      </Box>
    </Fragment>
  )
}

export default CFDIMovementDrawerContent
