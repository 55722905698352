import { useQuery /*, useMutation*/ } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import _ from 'lodash'

import { getEmployeeById, getEmployeesList } from 'services/employees'
import {
  setEmployeesList,
  setPaginationValue,
  setSelectedEmployee,
} from 'actions/employees'
import { selectSelectedEmployee } from 'lib/helpers/selectors'

import ErrorI from '@material-ui/icons/ErrorOutline'

import Employee from 'models/employee'
import { List, QueryOptions /*, MutationOptions*/ } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useEmployeesList = (
  workspaceId: number,
  page: number,
  size: number,
  search?: string,
  options: QueryOptions<List<Employee[]>> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<List<Employee[]>, any>(
    ['get-employees-list', ...refreshOn],
    () => {
      onBefore && onBefore()
      return getEmployeesList(workspaceId, page, size, search)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setEmployeesList(data.list))
        !noSave && dispatch(setPaginationValue('total', data.total))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de Contadores.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useEmployee = (
  workspaceId: number,
  employeeId: number,
  options: QueryOptions<Employee> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const employee = useSelector(selectSelectedEmployee) || undefined

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Employee, any>(
    ['get-employee', employeeId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getEmployeeById(workspaceId, employeeId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave &&
          !_.isNil(employee) &&
          dispatch(
            setSelectedEmployee({
              ...employee,
              assignedWorkspaces: data.assignedWorkspaces,
            })
          )
        !noSave && _.isNil(employee) && dispatch(setSelectedEmployee(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del contador.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export default useEmployeesList
