import {
  Button,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Select,
} from '@material-ui/core'
import { months } from '../../../constants'
import { FC, useContext, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { CFDIHistoryContext } from 'components/Conciliations/CFDIHistory/CFDIHistory'
import { getUploadedCFDIFilesHistory } from 'services/conciliations'
import useDialog from 'hooks/Shared/useDialog'
import _ from 'lodash'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const YearSelector: FC = () => {
  const { setPeriod, period } = useContext(CFDIHistoryContext)
  const [value, setValue] = useState<number>(period.year)

  useEffect(
    () => {
      setValue(period.year)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*period.year*/
    ]
  )

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
    setPeriod({ ...period, year: value })
  }

  const years = [2020, 2021, 2022, 2023, 2024, 2025]

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        fullWidth
        input={
          <Input
            disableUnderline
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Año
        </MenuItem>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const MonthSelector: FC = () => {
  const { setPeriod, period } = useContext(CFDIHistoryContext)
  const [value, setValue] = useState<number>(period.month)

  useEffect(
    () => {
      setValue(period.month)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*period.month*/
    ]
  )

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
    setPeriod({ ...period, month: value })
  }

  const parsedMonths = Object.keys(months).map((key: string) => ({
    label: months[Number(key)],
    value: Number(key),
  }))

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        input={
          <Input
            disableUnderline
            placeholder="Mes"
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Mes
        </MenuItem>
        {parsedMonths.map(month => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const ActionButton: FC = () => {
  const { period, setIsLoading, isLoading, setFiles } =
    useContext(CFDIHistoryContext)
  const { workspaceInfo } = useConciliationsContainerContext()
  const dialog = useDialog()

  const onClick = async () => {
    setIsLoading(true)
    setFiles([])
    try {
      const data = await getUploadedCFDIFilesHistory(
        workspaceInfo.activeWorkspace,
        period
      )
      setIsLoading(false)
      setFiles(
        _.map(data, (file, index) => ({
          ...file,
          id: index,
        }))
      )
    } catch (error: any) {
      setIsLoading(false)
      if (error.status === 404) {
        return
      }
      await dialog.primaryAsync({
        title: 'generic.error.title',
        subtitle: 'No se han podido obtener el historial de archivos.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  useEffect(() => {
    if (workspaceInfo.activeWorkspace !== null) {
      onClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceInfo.activeWorkspace /*, onClick*/])

  return (
    <Grid item xs={12} md={2}>
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        onClick={onClick}
        startIcon={
          isLoading ? (
            <CircularProgress size={20} style={{ color: '#fff' }} />
          ) : (
            <SearchIcon />
          )
        }
        disabled={isLoading || period.year === -1 || period.month === -1}
        style={{
          color: '#fff',
        }}>
        Buscar
      </Button>
    </Grid>
  )
}

const CFDIHistoryFilters: FC = () => {
  return (
    <Grid
      container
      spacing={1}
      style={{
        marginBlock: 20,
      }}>
      <MonthSelector />
      <YearSelector />
      <ActionButton />
    </Grid>
  )
}

export default CFDIHistoryFilters
