import { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useManagementWorkspacesContext } from '../../ManagementWorkspaces/ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import WorkspaceSimpleFilters from '../WorkspaceSimpleFilters/WorkspaceSimpleFilters'
import { WorkspacesFiltersFormModel } from 'lib/forms/WorkspacesFiltersFormUtils'
import './styles.scss'
import WorkspacesAdvancedFilters from '../WorkspacesAdvancedFilters/WorkspacesAdvancedFilters'
import { useSelector } from 'react-redux'
import { selectActiveWorkspace } from 'lib/helpers/selectors'

const WorkspacesFiltersForm: FC = () => {
  const { fetchManagementWorkspaces } = useManagementWorkspacesContext()

  const activeWorkspace = useSelector(selectActiveWorkspace)

  const methods = useFormContext()

  const [isAdvanceSearch, setIsAdvanceSearch] = useState<boolean>(false)
  const { setValue } = methods

  const onSubmit = useCallback(
    (data: WorkspacesFiltersFormModel) => {
      fetchManagementWorkspaces(1)
    },
    [fetchManagementWorkspaces]
  )

  const handleResetForm = useCallback(() => {
    setValue('param', '')
    setValue('workspace-status', undefined)
    setValue('closing-status', undefined)
    setValue('team', undefined)
    setValue('opinion', undefined)
    setValue('plan', undefined)
    setValue('coefficient', undefined)
    setValue('expiration-e', undefined)
    setValue('expiration-csd', undefined)
    setValue('profiling', undefined)
    fetchManagementWorkspaces(1)
  }, [setValue, fetchManagementWorkspaces])

  const handleAdvancedSearchActive = useCallback(() => {
    if (isAdvanceSearch === true) {
      handleResetForm()
      setIsAdvanceSearch(value => !value)
    } else {
      setIsAdvanceSearch(value => !value)
    }
  }, [isAdvanceSearch, handleResetForm])

  useEffect(() => {
    handleResetForm()
    setIsAdvanceSearch(false)
  }, [activeWorkspace, handleResetForm])

  return (
    <form
      className="workspaces-filters-form"
      onSubmit={methods.handleSubmit(onSubmit)}>
      <WorkspaceSimpleFilters
        isAdvancedSearchActive={isAdvanceSearch}
        handleAdvancedSearch={handleAdvancedSearchActive}
        handleResetForm={handleResetForm}
      />
      {isAdvanceSearch && (
        <WorkspacesAdvancedFilters handleResetForm={handleResetForm} />
      )}
    </form>
  )
}

export default WorkspacesFiltersForm
