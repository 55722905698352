import React from 'react'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'

import { getIndicator } from 'actions/dashboard'

import './style.scss'

import { IndicatorStatistics } from 'models/dashboard'

interface Props {
  workspaceId: number
}

const EmisorCountContainer = ({ workspaceId }: Props) => {
  const thunkDispatch = useThunkDispatch()
  const intl = useIntl()

  const { data, ...query } = useQuery<IndicatorStatistics>(
    ['get-emisor-count', workspaceId],
    () => thunkDispatch(getIndicator(workspaceId, 'rfcs'))
  )

  const formatDate = (dateString?: string) => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }

  const indicator = data?.value || 'equal'

  return (
    <DashboardCard
      className="emisor-count-container"
      title="RFC emisores"
      titleTag="h3"
      loading={query.isLoading}
      fetching={query.isFetching}
      value={data?.indicator || '- - -'}
      indicator={indicator}>
      {data?.indicator && (
        <p className="most-used">
          Más usada: <span className="value">{data?.mode}</span>
        </p>
      )}
      {data?.indicator && (
        <i className="last">Última creada {formatDate(data?.lastCreated)}</i>
      )}
    </DashboardCard>
  )
}

export default EmisorCountContainer
