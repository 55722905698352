import React, { useState, useEffect } from 'react'
import { withIO, IOInputProps } from 'react-io-forms'
import _ from 'lodash'

import MUITextField, {
  TextFieldProps as MUITextFieldProps,
} from '@material-ui/core/TextField'

import { filterFieldType } from 'lib/helpers/utilities'

import './style.scss'

//#region Types and Interfaces
type ExcludedKeys =
  | 'onChange'
  | 'autoComplete'
  | 'error'
  | 'helperText'
  | 'type'
  | 'select'
interface TextFieldProps extends Omit<MUITextFieldProps, ExcludedKeys> {
  ioProps: IOInputProps
  fieldType: string
  withCounter: boolean
  addon: any
  onChange?: (value: any) => void
  setValue: (value: any) => void
}
//#endregion

const _TextField: React.FC<TextFieldProps> = props => {
  const {
    ioProps: { name, defaultValue, invalid, message },
    className = '',
    fieldType,
    setValue,
    addon,
    withCounter,
    onChange,
    ...rest
  } = props

  const [updateValue, setUpdateValue] = useState<boolean>(false)

  useEffect(() => {
    setUpdateValue(true)
  }, [])

  useEffect(() => {
    if (defaultValue && updateValue) {
      setValue(defaultValue)
      updateValue && setUpdateValue(false)
    }
  }, [updateValue, defaultValue, setValue])

  const filterKeys = (e: any) => {
    const numbers = /[0-9]/
    const letters = /[A-Z a-z]/
    const lettersWithAccents = /[A-Za-z ÁáÉéÍíÓóÚúÑñ]/
    const specialCharacters = /[.,_\-#%$/\\]/
    const foil = /[a-zA-Z0-9 -]/

    if (fieldType === 'number' && !numbers.test(e.key)) {
      e.preventDefault()
    } else if (fieldType === 'letters' && !letters.test(e.key)) {
      e.preventDefault()
    } else if (
      fieldType === 'lettersWithAccents' &&
      !lettersWithAccents.test(e.key)
    ) {
      e.preventDefault()
    } else if (
      fieldType === 'alphanumeric' &&
      !lettersWithAccents.test(e.key) &&
      !numbers.test(e.key)
    ) {
      e.preventDefault()
    } else if (
      fieldType === 'alphanumericWithSpecialCharacters' &&
      !lettersWithAccents.test(e.key) &&
      !numbers.test(e.key) &&
      !specialCharacters.test(e.key)
    ) {
      e.preventDefault()
    } else if (fieldType === 'foil' && !foil.test(e.key)) {
      e.preventDefault()
    }
  }

  const hasAnError = invalid && !_.isEmpty(message)

  const type = filterFieldType(fieldType)
  const currentLength = String(rest.value).length || 0
  const maxLength = rest.inputProps?.maxLength

  return (
    <div
      className={`component-text-field ${className} ${addon ? 'with-addon' : ''} ${withCounter ? 'with-counter' : ''}`.trim()}>
      <MUITextField
        id={`textField-${name}`}
        autoComplete="off"
        className="text-field"
        type={type}
        helperText={message}
        error={hasAnError}
        onKeyPress={filterKeys}
        onChange={e => {
          onChange && onChange(e.target.value)
          setValue(e.target.value)
        }}
        {...rest}
      />
      {withCounter && maxLength && (
        <div className="counter">{`${currentLength}/${maxLength}`}</div>
      )}
      {addon}
    </div>
  )
}

const TextField = withIO('textfield')(_TextField)
export default TextField
