import { toast } from 'react-toastify'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import ISydDocument from 'models/shared.models'
import { downloadFileFromData } from 'lib/helpers/utilities'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import {
  IApiError,
  IDownloadWorkspaceDocumentRequest,
} from 'services/Shared/shared.models'
import { getDocumentationFileByIdService } from 'services/Shared/shared.services'

interface IDownloadWorkspaceDocumentMutationVariables
  extends IDownloadWorkspaceDocumentRequest {
  fileName: string
  errorMessage?: string
}

const useDownloadWorkspaceDocumentMutation = (): UseMutationResult<
  ISydDocument,
  IApiError,
  IDownloadWorkspaceDocumentMutationVariables
> => {
  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const mutation = useMutation<
    ISydDocument,
    IApiError,
    IDownloadWorkspaceDocumentMutationVariables
  >(
    ({ workspaceId, fileId }: IDownloadWorkspaceDocumentMutationVariables) =>
      getDocumentationFileByIdService({ workspaceId, fileId }),
    {
      onMutate: () => {
        toast.loading('Preparando descarga', {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: (fileResponse, { fileName }) => {
        toast.dismiss('loading')
        downloadFileFromData(fileName, fileResponse.file as string)
      },
      onError: (error, { errorMessage }) => {
        toast.dismiss('loading')
        toast.error(
          errorMessage ?? 'Hubo un error al descargar el archivo',
          errorToastOptions
        )
      },
    }
  )

  return mutation
}

export default useDownloadWorkspaceDocumentMutation
