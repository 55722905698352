import React from 'react'
import _ from 'lodash'

import Card, { CardProps } from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/Shared/Button/Button'

import { ROLES } from '../../../constants'

import Mail from '@material-ui/icons/Mail'
import Person from '@material-ui/icons/Person'
import Options from '@material-ui/icons/MoreVert'
import AddPerson from '@material-ui/icons/PersonAdd'
import AddWorkspaces from '@material-ui/icons/GroupAdd'
import Accounting from 'mdi-material-ui/CalculatorVariant'
import './style.scss'

import { Role, WorkspaceRole } from 'models/user'

interface NewWorkspaceCardProps {
  onClick?: () => void
}

interface WorkspaceCardProps
  extends Omit<CardProps, 'id' | 'role' | 'onClick'> {
  id: number
  name: string
  users: number
  invitations: number
  role: Role | null
  workspaceRole: WorkspaceRole
  enabled: boolean
  active?: boolean
  accountingStatus?: { step: number; name: string }
  onActive?: (id: number) => void
  onEdit?: (id: number) => void
  onClick?: (id: number) => void
  onEnabled?: (id: number) => void
  onDisabled?: (id: number) => void
  onInvitation?: (id: number) => void
}

export const NewWorkspaceCard: React.FC<NewWorkspaceCardProps> = props => (
  <div
    className="component-new-workspace-card"
    onClick={() => props.onClick && props.onClick()}>
    <AddWorkspaces className="card-icon" />
    <p className="card-text">
      Agregar espacio
      <br />
      de trabajo
    </p>
  </div>
)

const WorkspaceCard: React.FC<WorkspaceCardProps> = ({
  id,
  name,
  users,
  invitations,
  role,
  workspaceRole,
  className,
  enabled,
  active = false,
  accountingStatus,
  onActive,
  onClick,
  onEdit,
  onEnabled,
  onDisabled,
  onInvitation,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isContadorMr = role?.id === ROLES.CONTADOR_MR.id
  const isWsAdmin = workspaceRole.roleName === 'ADMIN'

  const onClickOption = (
    menu: 'active' | 'edit' | 'enabled' | 'disabled' | 'invite' | 'click'
  ) => {
    if (menu === 'active') onActive && onActive(id)
    if (menu === 'edit') onEdit && onEdit(id)
    if (menu === 'enabled') onEnabled && onEnabled(id)
    if (menu === 'disabled') onDisabled && onDisabled(id)
    if (menu === 'invite') onInvitation && onInvitation(id)
    if (menu === 'click') onClick && onClick(id)
  }

  return (
    <>
      <Card
        elevation={1}
        className={`component-workspace-card ${className || ''}`}
        {...props}>
        <div
          className={`content ${onClick ? 'clickable' : ''}`}
          onClick={() => onClickOption('click')}>
          <div className="header">
            <h3>
              <strong>{name}</strong>
            </h3>
            {isContadorMr && (
              <IconButton
                className="action-button"
                size="small"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation()
                  setAnchorEl(e.currentTarget)
                }}>
                <Options />
              </IconButton>
            )}
          </div>
          <div className="list">
            <div className="list-item">
              <Person className="list-item-icon" fontSize="inherit" />
              <p className="list-item-text">{`${users} usuarios`}</p>
            </div>
            <div className="list-item">
              <Mail className="list-item-icon" fontSize="inherit" />
              <p className="list-item-text">{`${invitations} invitaciones pendientes`}</p>
            </div>
            {!_.isNil(accountingStatus) && (
              <div className="list-item" title="Proceso de Contabilidad">
                <Accounting className="list-item-icon" fontSize="inherit" />
                <p className="list-item-text">
                  <span className="step">Paso {accountingStatus?.step}:</span>{' '}
                  {accountingStatus?.name}
                </p>
              </div>
            )}
          </div>
        </div>
        {enabled && (
          <>
            <Divider />
            <div className="invitation">
              <Button
                startIcon={<AddPerson />}
                color="secondary"
                size="large"
                onClick={() => isWsAdmin && onClickOption('invite')}
                fullWidth
                disabled={!isWsAdmin}>
                Invitar colaborador
              </Button>
            </div>
          </>
        )}
      </Card>

      <Menu
        className="menu-workspace-card"
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: -25 }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        onClose={() => setAnchorEl(null)}>
        {isContadorMr && enabled && !active && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onClickOption('disabled')
            }}>
            <p>Deshabilitar</p>
          </MenuItem>
        )}
        {isContadorMr && !enabled && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onClickOption('enabled')
            }}>
            <p>Habilitar</p>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default WorkspaceCard
