import { getConciliatedTransactions } from 'services/conciliations'
import { useConciliationReportsContext } from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import useDialog from 'hooks/Shared/useDialog'
import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

export const useGetConciliationReports = () => {
  const { workspaceInfo } = useConciliationsContainerContext()

  const { setIsLoading, setReports, reports, filter, setSelectedReport } =
    useConciliationReportsContext()
  const [search] = useSearchParams()
  const dispatch = useDispatch()
  const dialog = useDialog()

  const fetchReports = async (skipPeriod?: boolean) => {
    setIsLoading(true)
    try {
      let period
      if (search.has('year') && search.has('month') && !skipPeriod) {
        period = {
          year: Number(search.get('year')),
          month: Number(search.get('month')) + 1,
        }
      }
      if (skipPeriod && !_.isEmpty(filter)) {
        period = {
          year: filter.year,
          month: filter.month,
        }
      }
      const data = await getConciliatedTransactions(
        workspaceInfo.activeWorkspace,
        period
      )
      if (_.isEmpty(data) && skipPeriod) {
        fetchReports(true)
        return
      }
      const reports = _.map(data, (report, index) => ({
        ...report,
        id: index,
      }))
      dispatch(setReports(reports))
    } catch (error: any) {
      if (error.status === 404) return
      await dialog.dangerAsync({
        subtitle: 'No se han podido obtener los reportes.',
        title: 'generic.error.title',
        primaryButtonText: 'Continuar',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(
    () => {
      if (
        workspaceInfo &&
        workspaceInfo.activeWorkspace &&
        reports &&
        reports.length === 0
      ) {
        fetchReports()
      }
      return () => {
        dispatch(setReports([]))
        dispatch(setSelectedReport(undefined))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      workspaceInfo /*, dispatch, reports, fetchReports, setSelectedReport, setReports*/,
    ]
  )

  return {
    reports,
    fetchReports,
  }
}

export default useGetConciliationReports
