import update from 'immutability-helper';
import { 
	SET_PRODUCTS_LIST,
    SET_SELECTED_PRODUCT,
    SET_CURRENT_TAB
} from 'actions/products';

/** interfaces */
import { ProductsStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialClients: ProductsStore = {
    form: {
        internalId: 0,
        name: "",
        description: "",
        itemSat: null,
        unitSat: null,
        price: 0,
        category: null,
        tax: null
    },
    list: [],
    tab: 0,
    active: 0,
    selected: null

}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: ""
}

const products = (state = initialClients, action = defaultAction) => {
    switch (action.type) {
        case SET_PRODUCTS_LIST:
            return update(state, { list: { $set: action.value }});
        case SET_SELECTED_PRODUCT:
            return update(state, { selected: { $set: action.value }});
        case SET_CURRENT_TAB:
            return update(state, { tab: { $set: action.value }});
        default:
            return state
    }
}

export default products;