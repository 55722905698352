import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import Comment from 'components/Workspaces/Comment/Comment'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import { useAddComment, useCommentList } from 'hooks/queries/workspaces'

import { nameBuilder } from 'lib/helpers/utilities'

import Add from '@material-ui/icons/Add'
import './style.scss'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
  className?: string
}

type OrderBy = 'asc' | 'desc'

const CommentsContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  className = '',
}) => {
  const intl = useIntl()

  const [order, setOrder] = useState<OrderBy>('desc')
  const [openDialog, setOpenDialog] = useState(false)
  const [validForm, setValidForm] = useState(false)

  // #region Queries
  const listQuery = useCommentList(workspaceId, {
    refreshOn: [order],
    onError: () => onRedirect('error'),
    select: data => _.orderBy(data, ['createdAt'], [order]),
  })
  // #endregion

  // #region Mutation
  const addMutation = useAddComment(workspaceId, {
    onSuccess: () => {
      listQuery.refetch()
      setOpenDialog(false)
      setValidForm(false)
    },
  })
  // #endregion

  // #region Formatters
  const formatDate = (dateString?: string) => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const formatHour = (dateString?: string) => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      hour: '2-digit',
      minute: '2-digit',
    })
    return formatted
  }
  // #endregion

  return (
    <div className={`container-comments-list ${className}`}>
      <div className="title-container">
        <FormControl>
          <InputLabel id="select-label">Ordenar</InputLabel>
          <Select
            labelId="select-label"
            value={order}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setOrder(e.target.value as OrderBy)
            }>
            <MenuItem value="asc">Ascendente</MenuItem>
            <MenuItem value="desc">Descendente</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="warning"
          startIcon={<Add />}
          onClick={() => setOpenDialog(true)}>
          Comentario
        </Button>
      </div>
      <Grid container spacing={3} className="comments-list">
        {listQuery.isLoading && (
          <Grid item xs={12} className="loading-container">
            <CircularProgress color="primary" size={60} />
          </Grid>
        )}
        {!(listQuery.isLoading || listQuery.isError) &&
          _.map(listQuery?.data, c => (
            <Grid key={c.id} item xs={12}>
              <Comment
                name={nameBuilder(
                  c.user.name || '',
                  c.user.lastname || '',
                  c.user?.lastname2
                )}
                date={`${formatDate(c.createdAt)} - ${formatHour(c.createdAt)}hrs`}
                value={c.comment}
              />
            </Grid>
          ))}
      </Grid>

      {/** Add Comment Dialog */}
      <SydDialog
        open={openDialog}
        title="Agregar comentarios"
        primaryButtonText="Agregar"
        loadingPrimaryButton={addMutation.isLoading}
        disablePrimaryButton={!validForm}
        secondaryButtonText="Cancelar"
        disableSecondaryButton={addMutation.isLoading}
        onCancel={() => {
          setOpenDialog(false)
          setValidForm(false)
        }}
        form="commentsForm"
        sideButtons
        fullWidth>
        <Form
          id="commentsForm"
          onSubmit={async (form: { comment: string }) =>
            addMutation.mutate(form.comment)
          }
          onValidity={setValidForm}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="comment"
                placeholder="Hasta 500 caracteres"
                inputProps={{ maxLength: 500 }}
                multiline
                minRows={5}
                maxRows={5}
                withCounter
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>
    </div>
  )
}

export default CommentsContainer
