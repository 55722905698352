import update from 'immutability-helper';
import _ from 'lodash';
import { 
    SET_DOCUMENTATION_LIST,
    SET_PATH,
    RESET_PATH,
    ADD_TO_PATH,
    EDIT_ARCHIVE
} from "actions/documentation";

/** interfaces */
import { DocumentationStore } from "models/documentation"
import { ReduxAction } from "models/thunk";

const initialDocumentation: DocumentationStore = {
    currentPath: [{
        id: 0,
        label: "Documentos",
        goTo: ""
    }],
    list: [],
    uploadList: []
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: {}
}

const documentation = (state = initialDocumentation, action = defaultAction) => {
    switch (action.type) {
        case SET_DOCUMENTATION_LIST:
            return update(state, { list: { $set: action.value } });
        case ADD_TO_PATH:
            return update(state, { currentPath: { $push: [action.value] } });
        case SET_PATH:
            return update(state, { currentPath: { $set: action.value } });
        case RESET_PATH:
            return update(state, { currentPath: { $set: initialDocumentation.currentPath } });
        case EDIT_ARCHIVE: {
            const index = _.findIndex(state.list, (f) => f.idWsfile === action.value.id);
            console.log(index);
            if (index === -1) return state;
            return update(state, {
                list: {
                  [index]: {
                    [action.value.name]: { $set: action.value.value }
                  }
                }
            });
        }
        default: 
            return state;
    }
}

export default documentation;