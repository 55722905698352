import { useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { CircularProgress, IconButton } from '@material-ui/core'
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline'
import { LedgerStatement } from 'models/redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { deleteLedgerAccountByLedgerId } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

interface ILedgerAccountsDeleteButtonProps {
  account: LedgerStatement
}

const LedgerAccountsDeleteButton = ({
  account,
}: ILedgerAccountsDeleteButtonProps) => {
  const { workspaceInfo } = useConciliationsContainerContext()

  const { ledgers } = useConciliationsContainerContext()

  const { ledgerRows, setLedgerRows } = ledgers

  const dispatch = useDispatch()
  const dialog = useDialog()

  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    try {
      await deleteLedgerAccountByLedgerId(
        workspaceInfo.activeWorkspace,
        account.idLedgerAccount
      )
      dispatch(
        setLedgerRows(
          _.filter(
            ledgerRows,
            (r: any) => r.idLedgerAccount !== account.idLedgerAccount
          )
        )
      )
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      const response = await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: `No se ha podido eliminar "${account.name}", intenta de nuevo`,
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })
      if (response) {
        onClick()
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <IconButton size="small" onClick={onClick} disabled={isLoading}>
      {isLoading ? (
        <CircularProgress size={18} />
      ) : (
        <TrashCanOutline color="error" />
      )}
    </IconButton>
  )
}

export default LedgerAccountsDeleteButton
