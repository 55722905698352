import { FC, useCallback, useState } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import ErrorI from '@material-ui/icons/ErrorOutline'
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline'
import { ConciliationFile } from 'models/conciliations'
import { OCR_DRAWER_MODES } from '../../../constants'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { deleteCFDIDocument, deleteLedgerArchive } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const FileListDrawerDeleteButton: FC<{
  document: ConciliationFile
}> = ({ document }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { drawer, removeDrawerFilelistItem } =
    useConciliationsContainerContext()
  const {
    workspaceInfo,
    globalMethods: { deleteArchive },
  } = useConciliationsContainerContext()
  const dialog = useDialog()

  const onClick = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!drawer.filelist)
        throw new Error('No se ha encontrado la lista de archivos')
      const { ocrMode } = drawer.filelist

      if (ocrMode === OCR_DRAWER_MODES.statements) {
        await deleteArchive(workspaceInfo.activeWorkspace, document.idWsFile)
      }
      if (ocrMode === OCR_DRAWER_MODES.ledger) {
        await deleteLedgerArchive(
          workspaceInfo.activeWorkspace,
          document.idWsFile
        )
      }
      if (ocrMode === OCR_DRAWER_MODES.CFDIs) {
        await deleteCFDIDocument(
          workspaceInfo.activeWorkspace,
          document.idWsFile
        )
      }

      removeDrawerFilelistItem(document.idWsFile)
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido eliminar el archivo. Inténtalo mas tarde.',
        icon: ErrorI as TModalIcon,
        primaryButtonText: 'Entendido',
      })
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawer,
    document,
    deleteArchive,
    workspaceInfo.activeWorkspace,
    removeDrawerFilelistItem /*, dialog*/,
  ])

  return (
    <IconButton
      className="delete-button"
      onClick={onClick}
      disabled={isLoading}>
      {isLoading ? <CircularProgress size={24} /> : <TrashCanOutline />}
    </IconButton>
  )
}

export default FileListDrawerDeleteButton
