import { Box, Grid, LinearProgress } from '@material-ui/core'
import { FC, useEffect, useMemo, useState } from 'react'
import ReportDetailsHeader from '../ConciliationReportDetailsHeader/ConciliationReportDetailsHeader'
import ReportDetailsData from '../ConciliationReportDetailsReportData/ConciliationReportDetailsReportData'
import {
  ReportItem,
  getConciliatedTransactionsByConciliation,
} from 'services/conciliations'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { useConciliationReportsContext } from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

export const TYPES = {
  CFDIS: 'Papel de trabajo',
  CUENTAS_CONTABLES: 'Cuenta contable',
}

const Details: FC<{
  report: ReportItem
}> = ({ report }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { workspaceInfo } = useConciliationsContainerContext()
  const { selectedReport, setTransactions } = useConciliationReportsContext()
  const dialog = useDialog()

  const fetchReportTransactions = async () => {
    setIsLoading(true)
    try {
      setTransactions([])
      const data = await getConciliatedTransactionsByConciliation(
        workspaceInfo.activeWorkspace,
        report.idConciliation,
        report.type === TYPES.CFDIS ? 1 : 2
      )
      setTransactions(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se han podido obtener los estados de cuentas.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  useEffect(() => {
    fetchReportTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport /*, fetchReportTransactions*/])

  return (
    <Box>
      {isLoading && <LinearProgress variant="indeterminate" />}
      <ReportDetailsHeader report={report} />
      <ReportDetailsData report={report} />
    </Box>
  )
}

const ConciliationReportDetails: FC = () => {
  const { reports, selectedReport } = useConciliationReportsContext()

  const report = useMemo(
    () => reports.find(report => report.id === selectedReport),
    [reports, selectedReport]
  )

  if (!report) return null

  return (
    <Grid item xs={12} md={6}>
      <Details report={report} />
    </Grid>
  )
}

export default ConciliationReportDetails
