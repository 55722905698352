import { createContext, useContext, useMemo } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material'
import { isUniqueString } from 'lib/helpers/ioValidations'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import IOCustomForm from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AccountingFileDropzoneMultiple from '../AccountingFileDropzoneMultiple/AccountingFileDropzoneMultiple'
import AccountingStepFivePreviousDocuments from '../AccountingStepFivePreviousDocuments/AccountingStepFivePreviousDocuments'
import useAccountingStepFive, {
  IUseAccountingStepFive,
} from './useAccountingStepFive'
import './styles.scss'

const AccountingStepFiveContext = createContext<IUseAccountingStepFive | null>(
  null
)

const AccountingStepFive = () => {
  const { stepChangeLoading, handleStepBack } = useAccountingContext()

  const stepFiveState = useAccountingStepFive()

  const {
    clientCommentsDialog,
    validCommentsDialog,
    validRenameDialog,
    additionalDocuments,
    selectedFile,
    totalFirstCategoryFiles,
    uploadedFirstCategoryFiles,
    uploadedFourthCategoryFiles,
    listForFileNameValidation,
    firstCategoryFilesToValidate,
    uploadedAndValidatedFirstCategoryFiles,
    dynamicFileUploadColumns,
    continueButtonProps,
    updateStepFiveState,
    handleCommentsFormSubmit,
    handleRenameFormSubmit,
  } = stepFiveState

  const contextValue = useMemo(() => stepFiveState, [stepFiveState])

  return (
    <div className="step-container step-five">
      <AccountingStepFiveContext.Provider value={contextValue}>
        <div className="content">
          <p className="step-description">
            {(!totalFirstCategoryFiles.length ||
              uploadedFirstCategoryFiles.length > 0) && (
              <>
                Espera a que el contador suba el “Acuse de contribuciones de
                recibo de declaración provisional o definitiva de impuestos
                federales”
              </>
            )}

            {firstCategoryFilesToValidate.length > 0 && (
              <>
                Valida el “Acuse de contribuciones de recibo de declaración
                provisional o definitiva de impuestos federales” cargado por el
                contador y atiende sus instrucciones.
              </>
            )}

            {uploadedAndValidatedFirstCategoryFiles.length > 0 && (
              <>Atiende las instrucciones del contador.</>
            )}
          </p>

          <Grid container direction="row" spacing={2}>
            {dynamicFileUploadColumns.map(({ id, ...props }, i) => {
              return additionalDocuments ? (
                <Grid item xs={3} key={`step-five-upload-item-${id}`}>
                  <AccountingFileDropzoneMultiple
                    {...props}
                    className="with-fourth-column"
                  />
                </Grid>
              ) : (
                i !== dynamicFileUploadColumns.length - 1 && (
                  <Grid item xs={4} key={`step-five-upload-item-${id}`}>
                    <AccountingFileDropzoneMultiple {...props} />
                  </Grid>
                )
              )
            })}
            <FormControlLabel
              control={
                <Checkbox
                  name="allowAdditionalDocumentsUpload"
                  className="additional-documents-checkbox"
                  checked={additionalDocuments}
                  onChange={e =>
                    updateStepFiveState({
                      additionalDocuments: e.target.checked,
                    })
                  }
                  disabled={uploadedFourthCategoryFiles.length > 0}
                />
              }
              label="Agregar documentos adicionales (Cliente o contador)"
            />
            <Grid item xs={12} className="step-actions">
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleStepBack}
                activity={stepChangeLoading}>
                Ir a la sección anterior
              </Button>

              <Button
                variant="contained"
                color="secondary"
                {...continueButtonProps}
                activity={stepChangeLoading}>
                {continueButtonProps.text}
              </Button>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12}>
              <Divider className="step-divider" />
            </Grid>
          </Grid>

          <AccountingStepFivePreviousDocuments />
        </div>

        <SydDialog
          open={clientCommentsDialog}
          title="Agregar instrucciones"
          subtitle="Agrega Instrucciones para que el contador pueda realizar las acciones necesarias."
          primaryButtonText="Agregar"
          disablePrimaryButton={!validCommentsDialog}
          secondaryButtonText="Cancelar"
          onCancel={() => {
            updateStepFiveState({ clientCommentsDialog: false })
            updateStepFiveState({ validCommentsDialog: false })
          }}
          form="commentsForm"
          sideButtons
          fullWidth>
          <IOCustomForm
            id="commentsForm"
            onSubmit={handleCommentsFormSubmit}
            onValidity={(v: boolean) =>
              updateStepFiveState({ validCommentsDialog: v })
            }
            reset>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="comment"
                  inputProps={{ maxLength: 500 }}
                  multiline
                  minRows={5}
                  maxRows={5}
                  withCounter
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </IOCustomForm>
        </SydDialog>

        <SydDialog
          open={Boolean(selectedFile)}
          size="sm"
          title="Renombrar un archivo"
          primaryButtonText="Renombrar"
          disablePrimaryButton={!validRenameDialog}
          secondaryButtonText="Cancelar"
          onCancel={() => {
            updateStepFiveState({ selectedFile: undefined })
            updateStepFiveState({ validRenameDialog: false })
          }}
          form="renameFileForm"
          sideButtons>
          <IOCustomForm
            id="renameFileForm"
            onSubmit={handleRenameFormSubmit}
            onValidity={(v: boolean) =>
              updateStepFiveState({ validRenameDialog: v })
            }
            reset>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nombre del archivo"
                  value={selectedFile?.name}
                  validate={(v: string) =>
                    isUniqueString(v, listForFileNameValidation)
                  }
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </IOCustomForm>
        </SydDialog>
      </AccountingStepFiveContext.Provider>
    </div>
  )
}

export const useAccountingStepFiveContext = () => {
  const context = useContext(AccountingStepFiveContext)

  if (!context) {
    throw new Error(
      'useAccountingStepFiveContext must be used within an AccountingStepFiveContext'
    )
  }

  return context
}

export default AccountingStepFive
