import { Box, Button, Grid, GridSize, Typography } from '@material-ui/core'
import { FC } from 'react'
import { CUSTOM_ROW_HEIGHT } from '../ManagementWorkspacesTable/ManagementWorkspacesTable'
import ChartWheel from '../ManagementWorkspacesTableChartWheel/ManagementWorkspacesTableChartWheel'
import _ from 'lodash'
import { IWorkspaceItem } from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import Alert from '@material-ui/lab/Alert'
import { EXPIRED, WARNING } from '../../../../constants'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { daysToExpire } from 'lib/utils/Management/management.utils'

const viewportSizes: Record<string, GridSize> = {
  chartSection: 2,
  efirmaSection: 2,
  lastCommentSection: 6,
  actionsSection: 2,
}

const EFirma: FC<{
  workspace: IWorkspaceItem
}> = ({ workspace }) => {
  const elements = [
    {
      label: 'e.firma',
      value: moment(workspace.eFirmaExpirationDate, 'DD/MM/YYYY').toDate(),
      status: daysToExpire(
        moment(workspace.eFirmaExpirationDate, 'DD/MM/YYYY').toDate()
      ),
    },
    {
      label: 'CSD',
      value: moment(workspace.csdExpirationDate, 'DD/MM/YYYY').toDate(),
      status: daysToExpire(
        moment(workspace.csdExpirationDate, 'DD/MM/YYYY').toDate()
      ),
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gridGap={8}>
      {_.map(elements, (element, index) => (
        <Box key={index}>
          <Typography variant="subtitle2">{element.label}</Typography>
          <Alert
            variant="outlined"
            style={{ padding: 0, border: 'none', margin: 0 }}
            icon={false}
            severity={
              element.status === EXPIRED
                ? 'error'
                : element.status === WARNING
                  ? 'warning'
                  : 'success'
            }>
            {element.value.toLocaleDateString('en-GB')}
          </Alert>
        </Box>
      ))}
    </Box>
  )
}

const GeneralInfo: FC<{ workspace: IWorkspaceItem }> = ({ workspace }) => {
  return (
    <Box display="flex" flexDirection="column" gridGap={8} marginBottom={2}>
      <Box>
        <Typography variant="h6" style={{ fontSize: 14 }}>
          Planes contratados
        </Typography>
        <Typography style={{ textTransform: 'capitalize' }}>
          {workspace.plans.join(', ')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" style={{ fontSize: 14 }}>
          Último comentario
        </Typography>
        <Typography>
          {workspace.lastComment ? workspace.lastComment : 'Sin comentarios'}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" style={{ fontSize: 14 }}>
          XML promedio mensual:
        </Typography>
        <Typography>
          {workspace.averageXml ? workspace.averageXml : 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" style={{ fontSize: 14 }}>
          Numero de movimientos bancarios:
        </Typography>
        <Typography>
          {workspace.numTransactions ? workspace.numTransactions : 0}
        </Typography>
      </Box>
    </Box>
  )
}

const ManagementWorkspacesTableDetails: FC<{ workspace: IWorkspaceItem }> = ({
  workspace,
}) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/workspace_details?id=${workspace.idWorkspace}`)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      paddingX={4}
      paddingY={2}
      height={CUSTOM_ROW_HEIGHT}>
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={viewportSizes.chartSection}>
          <Box marginBottom={1}>Perfilamiento</Box>
          <ChartWheel
            total={100}
            current={workspace.profilingPercent}
            label="Proceso terminado"
          />
        </Grid>
        <Grid item xs={viewportSizes.efirmaSection}>
          <EFirma workspace={workspace} />
        </Grid>
        <Grid item xs={viewportSizes.lastCommentSection}>
          <GeneralInfo workspace={workspace} />
        </Grid>
        <Grid item xs={viewportSizes.actionsSection}>
          <Button>Ver detalle</Button>
        </Grid>
      </Grid>
      <Box
        borderTop="1px solid #E0E0E0"
        paddingTop={2}
        display="flex"
        justifyContent="flex-end">
        <Button disabled>Actualizar CSD</Button>
        <Button disabled>Actualizar e.firma</Button>
        <Button variant="outlined" color="secondary" onClick={handleNavigate}>
          Ir a espacio de trabajo
        </Button>
      </Box>
    </Box>
  )
}

export default ManagementWorkspacesTableDetails
