import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Button from 'components/Shared/Button/Button'

import ProductsContainer from 'containers/ProductsAndCategories/ProductsContainer/ProductsContainer'

import { setCurrentTab } from 'actions/products'
import { selectActiveWorkspace, selectCurrentTab } from 'lib/helpers/selectors'

import { ROUTES } from '../../../constants'

import Add from '@material-ui/icons/Add'
import Products from '@material-ui/icons/LibraryBooks'
import Category from '@material-ui/icons/Category'
import './style.scss'
import CategoriesTable from 'components/ProductsAndCategories/CategoriesTable/CategoriesTable'

interface Props {}

const ProductsAndCategoriesScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const workspaceId = useSelector(selectActiveWorkspace)
  const currentTab = useSelector(selectCurrentTab)

  const dispatch = useDispatch()

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'back') navigate(-1)
    if (action === 'add-cat') navigate(ROUTES.ADD_CATEGORY)
    if (action === 'add-ps') navigate(ROUTES.ADD_PRODUCT)
    if (action === 'edit-cat') navigate(`${ROUTES.CATEGORY_DETAILS}?id=${id}`)
    if (action === 'edit-ps') navigate(`${ROUTES.PRODUCT_DETAILS}?id=${id}`)
    if (action === 'error') navigate(ROUTES.HOME, { replace: true })
  }

  const onChangeTab = (_e: React.ChangeEvent<{}>, newValue: number) =>
    dispatch(setCurrentTab(newValue))

  return (
    <>
      <Grid
        container
        spacing={0}
        className="screen-categories-products-services">
        <Grid item xs={12}>
          <div className="title-container">
            <Tabs
              className="title-selector"
              value={currentTab}
              onChange={onChangeTab}>
              <Tab
                id="tab-0"
                className="tab"
                label="Productos y Servicios"
                icon={<Products />}
              />
              <Tab
                id="tab-1"
                className="tab"
                label="Categorías"
                icon={<Category />}
              />
            </Tabs>
            {currentTab === 0 && (
              <Button
                variant="outlined"
                color="warning"
                startIcon={<Add />}
                onClick={() => onRouteChange('add-ps')}>
                Nuevo producto o servicio
              </Button>
            )}
            {currentTab === 1 && (
              <Button
                variant="outlined"
                color="warning"
                startIcon={<Add />}
                onClick={() => onRouteChange('add-cat')}>
                Nueva categoría
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          {currentTab === 0 && (
            <ProductsContainer
              workspaceId={workspaceId}
              onRedirect={onRouteChange}
            />
          )}
          {currentTab === 1 && (
            <CategoriesTable
              workspaceId={workspaceId}
              onRedirect={onRouteChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ProductsAndCategoriesScreen
