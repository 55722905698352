

const FileWarningIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M16.5 20H17.5V16H16.5V20ZM17 15C17.1333 15 17.25 14.95 17.35 14.85C17.45 14.75 17.5 14.6333 17.5 14.5C17.5 14.3667 17.45 14.25 17.35 14.15C17.25 14.05 17.1333 14 17 14C16.8667 14 16.75 14.05 16.65 14.15C16.55 14.25 16.5 14.3667 16.5 14.5C16.5 14.6333 16.55 14.75 16.65 14.85C16.75 14.95 16.8667 15 17 15ZM7 14H10.675C10.8583 13.6167 11.0708 13.2583 11.3125 12.925C11.5542 12.5917 11.825 12.2833 12.125 12H7V14ZM7 18H10.075C10.025 17.6667 10 17.3333 10 17C10 16.6667 10.025 16.3333 10.075 16H7V18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V4C3 3.45 3.19583 2.97917 3.5875 2.5875C3.97917 2.19583 4.45 2 5 2H13L19 8V10.3C18.6833 10.2 18.3583 10.125 18.025 10.075C17.6917 10.025 17.35 10 17 10V9H12V4H5V20H10.675C10.8583 20.3833 11.0708 20.7417 11.3125 21.075C11.5542 21.4083 11.825 21.7167 12.125 22H5ZM17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12Z" fill="#415364"/>
    </svg>
  )
}

export default FileWarningIcon;