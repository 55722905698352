import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { TaskAlt } from 'components/Shared/CustomIcons'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import AddConceptForm from '../AddConceptCreditNoteV2Container/AddConceptCreditNoteV2Container'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from 'components/Shared/Button/Button'
import ScrollSpy from 'components/Invoices/ScrollSpy/ScrollSpy'
import { DisplayTotalDetails } from 'components/Invoices/DisplayTotal/DisplayTotal'
import { DropdownCard } from 'components/FormComponents/Dropdown/Dropdown'
import SydDialog, { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import { setLocalLoader } from 'actions/loader'
import { getProductsList } from 'actions/products'
import { getCategoriesList } from 'actions/categories'
import { resetForm, storeFormValue } from 'actions/forms'

import {
  createCreditNote,
  createCreditNoteConcept,
  getCreditNoteFiles,
  getIncomeInvoiceById,
  getTaxCatalogue,
} from 'actions/invoices'
import {
  selectSelectedInvoice,
  selectCatalogue,
  selectProductsList,
  selectCreateCreditNote,
} from 'lib/helpers/selectors'

import {
  downloadFileFromData,
  filterCatalogueRelationByType,
  validateCreditNoteComplete,
} from 'lib/helpers/utilities'

import Add from '@material-ui/icons/Add'
import Error from '@material-ui/icons/ErrorOutline'
import './style.scss'

import Product from 'models/product'
import { Concept, TaxCatalogue } from 'models/invoice'
import { CatalogueEntry } from 'models/catalogues'
import { useThunkDispatch } from 'models/thunk'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

interface Props {
  workspaceId: number
  invoiceId: number
  onRedirect: (action: string) => void
}

const AddCreditNoteV2Container: React.FC<Props> = ({
  workspaceId,
  invoiceId,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()
  const intl = useIntl()

  const form = useSelector(selectCreateCreditNote)
  const invoice = useSelector(selectSelectedInvoice)

  const products = useSelector(selectProductsList)
  const usosCfdi = useSelector(selectCatalogue('usosCfdi'))
  const monedas = useSelector(selectCatalogue('monedas'))
  const metodosPago = useSelector(selectCatalogue('metodosPago'))
  const formasPago = useSelector(selectCatalogue('formasPago'))
  const tipoRelacion = useSelector(selectCatalogue('tiposRelaciones'))
  const idInvoice = invoice?.idCfdi

  const [loading, setLoading] = useState(false)
  const [valid, setValid] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [taxes, setTaxes] = useState<TaxCatalogue>([])
  const [creditNoteSucces, setcreditNoteSuccess] = useState(false)

  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const filterTipoRelacion = useMemo(
    () => filterCatalogueRelationByType(invoice?.type.value, tipoRelacion),
    [invoice?.type.value, tipoRelacion]
  )

  const getInformation = async () => {
    dispatch(setLocalLoader(true, 'Cargando catálogos personalizados'))
    saveValueToStore('serie', invoice?.serie)
    saveValueToStore('folio', invoice?.folio)
    saveValueToStore('uuid', invoice?.uuid)
    try {
      const req = await Promise.all([
        thunkDispatch(getIncomeInvoiceById(workspaceId, invoiceId)),
        thunkDispatch(getTaxCatalogue()),
        thunkDispatch(getProductsList(workspaceId)),
        thunkDispatch(getCategoriesList(workspaceId)),
      ])
      setTaxes(req[1])
      dispatch(setLocalLoader(false))
    } catch (err) {
      console.log(err)
      const rp = await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener la información de los catálogos. Intenta mas tarde.',
        icon: Error as TModalIcon,
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })
      if (rp) {
        dispatch(setLocalLoader(false))
        getInformation()
      } else {
        dispatch(setLocalLoader(false))
        onRedirect('cancel')
      }
    }
  }

  const formatCatalogueLabel = (type?: CatalogueEntry) => {
    if (!type) return '---'
    return `${type.value} - ${type.label}`
  }
  const formatMoney = (money?: number) => {
    if (!money && money !== 0) return '---'
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatDate = (dateString?: string) => {
    if (!dateString) return ''
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  const onDownloadCreditNoteFiles = useCallback(async () => {
    if (!idInvoice) return
    try {
      showAdditionalInformationLoading()
      const files = await thunkDispatch(
        getCreditNoteFiles(workspaceId, idInvoice, form.idCreditNote)
      )
      dialog.close()
      _.forEach(files, (f: any, key: string) => {
        downloadFileFromData(`${form.uuid}.${key}`, f)
      })
      onRedirect('success')
    } catch (err) {
      setTimeout(() => {
        dialog.danger({
          title: 'generic.error.title',
          subtitle:
            'No se pudo descargar la nota de crédito. Intenta nuevamente.',
        })
      }, 600)
    }
  }, [
    form,
    idInvoice,
    dialog,
    thunkDispatch,
    workspaceId,
    onRedirect,
    showAdditionalInformationLoading,
  ])

  const onAdd = async (form: any) => {
    setLoading(true)
    try {
      let response = await thunkDispatch(
        createCreditNote(workspaceId, form, '4.0')
      )
      if (response) {
        dispatch(
          storeFormValue(
            'createCreditNote',
            'idCreditNote',
            response.idCreditNote
          )
        )
      }
      dispatch(resetForm('createPaymentComplement'))
      setcreditNoteSuccess(true)
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo crear la nota de credito. Intenta nuevamente.',
        icon: Error as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  const addConcept = (p?: Product) => {
    const concept: Concept = {
      uuid: uuid(),
      satKey: p?.claveSat || null,
      claveUnitSat: p?.claveUnitSat || null,
      objImp: p?.objImp || null,
      price: `${p?.price || ''}`,
      description: p?.description || '',
      cantidad: '1',
      descuento: '',
      impuestos: [],
      importacion: '',
      predial: '',
    }
    dispatch(createCreditNoteConcept(concept))
  }

  const saveValueToStore = useCallback(
    (name: string, value: any) => {
      dispatch(storeFormValue('createCreditNote', name, value))
    },
    [dispatch]
  )

  const onCancelCreditNote = useCallback(() => {
    dispatch(resetForm('createPaymentComplement'))
    onRedirect('success')
  }, [dispatch, onRedirect])

  const onSetExcangeRate = useCallback(
    (value: CatalogueEntry) => {
      saveValueToStore('moneda', value)
      if (value?.value === 'MXN') {
        saveValueToStore('exchangeRate', 0)
      }
    },
    [saveValueToStore]
  )

  useEffect(() => {
    getInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, invoiceId])
  //cargar por defecto ciertos valores de campos
  useEffect(() => {
    if (_.isNil(form.usoCfdi)) {
      const usoCfdi = _.filter(usosCfdi, m => m.value === 'G02')[0]
      saveValueToStore('usoCfdi', usoCfdi)
    }
    if (_.isNil(form.metodoPago)) {
      const metodoPago = _.filter(metodosPago, m => m.value === 'PUE')[0]
      saveValueToStore('metodoPago', metodoPago)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //Validar state de creditNote
  useEffect(() => {
    setFormValid(validateCreditNoteComplete(form))
  }, [form])
  return (
    <>
      <DropdownCard
        title="Factura Original"
        headerBackgroundColor="secondary"
        capitalize
        iconAlign="left"
        defaultExpanded
        elevation={3}>
        <Form onSubmit={() => true} onValidity={() => true}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="fecha"
                label="Fecha"
                value={formatDate(invoice?.createdAt)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="serie"
                label="Serie"
                value={invoice?.serie}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="folio"
                label="Folio"
                value={invoice?.folio}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="razonSocial"
                label="Razón social"
                value={invoice?.emisor?.name}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="moneda"
                label="Moneda"
                value={formatCatalogueLabel(invoice?.currency)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="total"
                label="Total"
                value={formatMoney(invoice?.total)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
          </Grid>
        </Form>
      </DropdownCard>
      <Card elevation={1} className="container-add-credit-note">
        <div className="scrollspy-container">
          <ScrollSpy
            items={[
              { id: 'section-1', label: '1', tooltip: 'CFDI' },
              { id: 'section-2', label: '2', tooltip: 'Conceptos' },
              {
                id: 'section-3',
                label: '3',
                tooltip: 'Documentos relacionados',
              },
              { id: 'section-4', label: '4', tooltip: 'Serie' },
            ]}
          />
        </div>
        <div className="form-container">
          <Form onSubmit={onAdd} onValidity={v => setValid(v)}>
            <div id="section-1" className="content">
              <h3 className="title">(1) CFDI</h3>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={7}>
                  <AutocompleteField
                    name="usoCfdi"
                    label="Uso de Comprobante"
                    catalogue={usosCfdi}
                    value={form?.usoCfdi}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('usoCfdi', v)
                    }
                    fullWidth
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    required
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="moneda"
                    label="Moneda"
                    catalogue={monedas}
                    value={form?.moneda}
                    onValue={(v: CatalogueEntry) => onSetExcangeRate(v)}
                    fullWidth
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    required
                  />
                </Grid>
                {!_.isNil(form?.moneda) && form.moneda?.value !== 'MXN' && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      fullWidth
                      value={form?.exchangeRate}
                      onValue={(v: any) =>
                        saveValueToStore(
                          'exchangeRate',
                          parseFloat(v).toFixed(2)
                        )
                      }
                      format={'money'}
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <AutocompleteField
                    name="metodoPago"
                    label="Método de pago"
                    catalogue={metodosPago}
                    value={form?.metodoPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('metodoPago', v)
                    }
                    fullWidth
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="formaPago"
                    label="Forma de pago"
                    catalogue={formasPago}
                    value={form?.formaPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('formaPago', v)
                    }
                    fullWidth
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    required
                  />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={8}>
                  <p className="form-label">
                    Condiciones de pago - Información adicional
                  </p>
                  <TextField
                    variant="outlined"
                    name="condiciones"
                    value={form?.condiciones}
                    onValue={(v: string) => saveValueToStore('condiciones', v)}
                    inputProps={{ maxLength: 500 }}
                    multiline
                    minRows={4}
                    maxRows={4}
                    withCounter
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <div id="section-2" className="content">
              <h3 className="title">(2) Conceptos</h3>
              <Divider className="separator" />
              <p className="form-label-alternative">
                Selecciona un concepto guardado anteriormente o agrega uno nuevo
              </p>
              <Grid
                className="section-add-item"
                container
                justifyContent="space-between">
                <Grid item xs={5}>
                  <AutocompleteField
                    name="conceptos"
                    placeholder="Buscar"
                    catalogue={products}
                    fullWidth
                    getOptionLabel={(o: any) => `${o.name}`}
                    onSelectValue={addConcept}
                    exclude
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<Add />}
                    onClick={() => addConcept()}>
                    Nuevo Concepto
                  </Button>
                </Grid>
              </Grid>
              {_.map(form?.conceptos, (c, i) => (
                <AddConceptForm
                  id={i}
                  key={c.uuid}
                  title={`Concepto ${i + 1}`}
                  uuid={c.uuid}
                  clave={c.satKey}
                  unidad={c.claveUnitSat}
                  precio={c.price}
                  cantidad={c.cantidad}
                  descuento={c.descuento}
                  descripcion={c.description}
                  impuestos={c.impuestos}
                  importacion={c.importacion}
                  impuestosCat={taxes}
                  predial={c.predial}
                />
              ))}
              <div className="invoice-breakdown">
                <DisplayTotalDetails concepts={form?.conceptos} />
              </div>
            </div>
            <div id="section-3" className="content">
              <h3 className="title">(3) Documentos relacionados</h3>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="uuid"
                    label="UUID"
                    value={form.uuid ? form.uuid : ''}
                    fullWidth
                    disabled
                    exclude
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="relationType"
                    label="Tipo de relación"
                    placeholder="Buscar"
                    catalogue={filterTipoRelacion}
                    fullWidth
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    onSelectValue={(val: any) =>
                      saveValueToStore('tipoRelacion', val)
                    }
                    value={form?.tipoRelacion}
                    required
                  />
                </Grid>
              </Grid>
            </div>
            <div id="section-4" className="content">
              <h3 className="title">(4) Serie</h3>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="serie"
                    label="Serie"
                    value={form?.serie}
                    disabled
                    fullWidth
                    exclude
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="folio"
                    label="Folio"
                    value={form?.folio}
                    fullWidth
                    disabled
                    exclude
                  />
                </Grid>
              </Grid>
            </div>
            <Divider className="separator" />
            <div className="actions">
              {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                <Button
                  variant="outlined"
                  color="secondary"
                  activity={loading}
                  disabled={!valid}>
                  Vista previa
                </Button>
              )}
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                activity={loading}
                disabled={!valid || !formValid}>
                Agregar
              </Button>
            </div>
          </Form>
        </div>
      </Card>

      <SydDialog
        open={creditNoteSucces}
        icon={TaskAlt}
        hidePrimaryButton
        fullWidth>
        <div className="success-dialog">
          <div className="title-dialog">
            <h1>
              {intl.formatMessage({ id: 'creditNote.succcess.diolog.title' })}
            </h1>
          </div>
          <div className="text-container">
            <h5>
              {intl.formatMessage({ id: 'creditNote.succcess.diolog.message' })}
            </h5>
          </div>
          <div className="buttons-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="lgButton"
                  onClick={() => onDownloadCreditNoteFiles()}>
                  {intl.formatMessage({ id: 'button.download' })}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="hover"
                  color="secondary"
                  className="lgButton"
                  onClick={() => onCancelCreditNote()}
                  disabled={loading}>
                  {intl.formatMessage({ id: 'button.cancel' })}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </SydDialog>
    </>
  )
}

export default AddCreditNoteV2Container
