import React, { useMemo } from 'react'

import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'

import Event from '@material-ui/icons/Event'
import GroupAdd from '@material-ui/icons/GroupAdd'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import './style.scss'

import { Notification as INotificacion, NotificationType } from 'models/general'
import Button from 'components/Shared/Button/Button'

interface NotificationProps extends INotificacion {
  onAccept?: () => void
  onReject?: () => void
}

const getNotificationIcon = (t: NotificationType) => {
  switch (t) {
    case NotificationType.VALIDITY:
      return Event
    case NotificationType.INVITATION:
      return GroupAdd
    case NotificationType.TEXT:
    default:
      return PriorityHigh
  }
}

const getNotificationTitle = (t: NotificationType) => {
  switch (t) {
    case NotificationType.VALIDITY:
      return 'Vigencia de archivos'
    case NotificationType.INVITATION:
      return 'Invitación a espacio de trabajo'
    case NotificationType.TEXT:
    default:
      return 'PriorityHigh'
  }
}

const getNotificationMessage = (
  t: NotificationType,
  message: string,
  actions?: any
) => {
  switch (t) {
    case NotificationType.INVITATION:
      return (
        <>
          Tienes una invitacion para colaborar en el espacio de trabajo{' '}
          <strong>{message || ''}</strong>
          {actions}
        </>
      )
    case NotificationType.VALIDITY:
    case NotificationType.TEXT:
    default:
      return message
  }
}

const Notification: React.FC<NotificationProps> = ({
  id,
  relatedId,
  type,
  message,
  onAccept,
  onReject,
  ...props
}) => {
  const Icon = useMemo(() => getNotificationIcon(type), [type])
  const title = useMemo(() => getNotificationTitle(type), [type])
  const msg = useMemo(() => {
    const actions = (
      <span className="list-item-actions">
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={() => onAccept && onAccept()}>
          Aceptar
        </Button>
        <Button
          size="small"
          variant="text"
          color="danger"
          onClick={() => onReject && onReject()}>
          Rechazar
        </Button>
      </span>
    )
    return getNotificationMessage(type, message, actions)
  }, [message, onAccept, onReject, type])

  return (
    <ListItem className="notification" alignItems="flex-start">
      <ListItemAvatar>
        <Avatar className={type}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<strong>{title}</strong>} secondary={msg} />
    </ListItem>
  )
}

export default Notification
