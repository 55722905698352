import React, { useCallback } from 'react'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'

import { getIndicator } from 'actions/dashboard'

import Dot from '@material-ui/icons/FiberManualRecord'
import './style.scss'

import { IndicatorStatistics } from 'models/dashboard'

interface Props {
  workspaceId: number
}

const PlanInformationContainer = ({ workspaceId }: Props) => {
  const thunkDispatch = useThunkDispatch()
  const intl = useIntl()

  const { data, ...query } = useQuery<IndicatorStatistics>(
    ['get-plan-information', workspaceId],
    () => thunkDispatch(getIndicator(workspaceId, 'suscription')),
    {
      select: useCallback(data => {
        let plan = data.data_type
        let additionalPlan: string[] = Boolean(data.subdata_type)
          ? _.split(data.subdata_type, ',')
          : []

        if (!Boolean(data.data_type)) {
          plan = additionalPlan.shift() ?? ''
        }

        return { ...data, data_type: plan, subdata_type: additionalPlan }
      }, []),
    }
  )

  // const formatMoney = (money: number) => {
  //     const formatted = intl.formatNumber(money, {
  //         style: "currency",
  //         currency: "MXN",
  //         currencyDisplay: "narrowSymbol"
  //     });
  //     return formatted;
  // }

  return (
    <DashboardCard
      className="plan-information-container"
      title={
        data?.data_type
          ? intl.formatMessage({ id: `workspace.plan.${data?.data_type}` })
          : '- - -'
      }
      titleTag="h2"
      loading={query.isLoading}
      fetching={query.isFetching}
      hideTitleDivider>
      {/* <div className="indicator-container">{formatMoney(data?.indicator)} MXN / mes</div> */}
      {(data?.subdata_type ?? []).length > 0 && (
        <ul>
          {_.map(data?.subdata_type, (p, i) => (
            <li key={i}>{intl.formatMessage({ id: `workspace.plan.${p}` })}</li>
          ))}
        </ul>
      )}
      <div className="payment-container">
        <Dot
          className={`status-icon pagado`}
          fontSize="inherit"
          color="inherit"
        />
        Pagado
      </div>
      <small className="legend">El estatus se actualiza quincenalmente</small>
    </DashboardCard>
  )
}

export default PlanInformationContainer
