import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import {
  Divider,
  LinearProgress,
  CardContent,
  CardActions,
} from '@material-ui/core'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import CreateWorkspaceStepOne from 'components/Workspaces/CreateWorkspace/CreateWorkSpaceStepOne/CreateWorkSpaceStepOne'

import useWorkspaceList, { useAddWorkspace } from 'hooks/queries/workspaces'

import {
  resetCreateForm,
  setActiveWorkspace,
  setCreateFormValue,
  setCreateStep,
} from 'actions/workspaces'
import { selectCatalogue, selectCreateWorkspace } from 'lib/helpers/selectors'

import './style.scss'
import CreateWorkspaceStepTwo from 'components/Workspaces/CreateWorkspace/CreateWorkSpaceStepTwo/CreateWorkSpaceStepTwo'
import CreateWorkspaceStepThree from 'components/Workspaces/CreateWorkspace/CreateWorkSpaceStepThree/CreateWorkSpaceStepThree'

interface Props {
  onRedirect: (action: string) => void
}

enum Step {
  EmisorInformation = 1,
  PlanSelection = 2,
  UploadFiles = 3,
}

const normalise = (value: number) => (value * 100) / 3

const AddWorkspaceFormContainer: React.FC<Props> = ({ onRedirect }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const form = useSelector(selectCreateWorkspace)
  const tiposPersonaCatalogue = useSelector(selectCatalogue('tiposPersona'))
  const taxSystemFisicaCatalogue = useSelector(
    selectCatalogue('regimenesFisica')
  )
  const taxSystemMoralCatalogue = useSelector(selectCatalogue('regimenesMoral'))

  const [validForm, setValidForm] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [form.createStep])

  const listQuery = useWorkspaceList(true, { enabled: false })
  const addMutation = useAddWorkspace({
    onSuccess: async ws => {
      await listQuery.refetch()
      dispatch(setActiveWorkspace(ws.id))
      dispatch(resetCreateForm())
      onRedirect('success')
    },
    onError: () => {},
  })

  const onCancelForm = () => {
    dispatch(resetCreateForm())
    onRedirect('cancel')
  }

  const onPrevStep = () => {
    if (form.createStep === Step.UploadFiles) {
      // saveValueToStore("plan", "");
    }
    if (form.createStep > Step.EmisorInformation) {
      dispatch(setCreateStep(form.createStep - 1))
    }
  }

  const onNextStep = async () => {
    if (form.createStep === Step.UploadFiles) {
      addMutation.mutate(form)
    } else {
      dispatch(setCreateStep(form.createStep + 1))
    }
  }

  const saveValueToStore = (name: string, value: any) => {
    dispatch(setCreateFormValue(name, value))
  }

  const getDisabledState = useCallback(() => {
    if (form.createStep === Step.PlanSelection) {
      if (form.plan === 'facturacion' || form.plan === 'contabilidad') {
        return !(validForm && form.acceptTerms && form.acceptContract)
      } else {
        return !(
          validForm &&
          form.acceptTerms &&
          form.additionalPlan.length > 0
        )
      }
    } else {
      return !validForm
    }
  }, [
    form.acceptContract,
    form.acceptTerms,
    form.createStep,
    form.plan,
    form.additionalPlan,
    validForm,
  ])

  return (
    <div className="container-create-workspace-form">
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={normalise(form.createStep)}
      />
      <Form
        id={'form1'}
        onSubmit={onNextStep}
        onValidity={v => setValidForm(v)}>
        <CardContent className="steps-container">
          {form.createStep === Step.EmisorInformation && (
            <CreateWorkspaceStepOne
              type={form.type}
              workspace={form.workspace}
              name={form.name}
              rfc={form.rfc}
              taxSystem={form.taxSystem}
              zipCode={form.zipCode}
              taxAddress={form.taxAddress || ''}
              storeValue={saveValueToStore}
              tiposPersonaCatalogue={tiposPersonaCatalogue}
              taxSystemFisicaCatalogue={taxSystemFisicaCatalogue}
              taxSystemMoralCatalogue={taxSystemMoralCatalogue}
            />
          )}
          {form.createStep === Step.PlanSelection && (
            <CreateWorkspaceStepTwo
              type={form.type}
              name={form.name}
              rfc={form.rfc}
              plan={form.plan}
              additionalPlan={form.additionalPlan}
              freeTrial={form.freeTrial}
              invoiceAmount1={form.invoiceAmount1}
              invoiceQuantity1={form.invoiceQuantity1}
              withInventory1={form.withInventory1}
              invoiceAmount2={form.invoiceAmount2}
              invoiceQuantity2={form.invoiceQuantity2}
              withInventory2={form.withInventory2}
              payroll={form.payroll}
              acceptContract={form.acceptContract}
              acceptTerms={form.acceptTerms}
              promotionalCode={form.promotionalCode}
              valid={validForm}
              storeValue={saveValueToStore}
            />
          )}
          {form.createStep === Step.UploadFiles && (
            <CreateWorkspaceStepThree
              type={form.type}
              plan={form.plan}
              fielCer={form.fielCer}
              fielKey={form.fielKey}
              passwordFiel={form.passwordFiel}
              csdCer={form.csdCer}
              csdKey={form.csdKey}
              ciec={form.ciec}
              passwordCsd={form.passwordCsd}
              storeValue={saveValueToStore}
            />
          )}
        </CardContent>
        {form.type && (
          <>
            <Divider />
            <CardActions className="actions-container" disableSpacing>
              <div className="left-container">
                <Button
                  id="wsCancelButton"
                  variant="hover"
                  color="danger"
                  onClick={onCancelForm}
                  disabled={addMutation.isLoading || listQuery.isFetching}>
                  {intl.formatMessage({ id: 'button.cancel' })}
                </Button>
              </div>
              <div className="right-container">
                {form.createStep > Step.EmisorInformation && (
                  <Button
                    id="wsPrevStepBtn"
                    variant="hover"
                    color="secondary"
                    onClick={onPrevStep}
                    disabled={addMutation.isLoading || listQuery.isFetching}>
                    {intl.formatMessage({ id: 'button.back' })}
                  </Button>
                )}
                <Button
                  id="wsNextStepBtn"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={getDisabledState()}
                  activity={addMutation.isLoading || listQuery.isFetching}>
                  {intl.formatMessage({ id: 'button.continue' })}
                </Button>
              </div>
            </CardActions>
          </>
        )}
      </Form>
    </div>
  )
}

export default AddWorkspaceFormContainer
