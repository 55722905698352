import React, { useEffect, useState, useCallback } from 'react'
import { useDidUpdate } from 'rooks'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import SydDialog, { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import RadioGroup from 'components/FormComponents/IOComponents/RadioGroup/RadioGroup'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import {
  CatalogueEntry,
  WizardCatalogue,
  WizardCatalogueEntry,
} from 'models/catalogues'
import { getWizardCatalogue, searchInCatalogue } from 'actions/catalogues'

import Error from '@material-ui/icons/ErrorOutline'
import './style.scss'

interface Props {
  open: boolean
  onSelect: (item: CatalogueEntry) => void
  onCancel: () => void
}

// interface SearchForm {
//     type: WizardCatalogueEntry
//     division: WizardCatalogueEntry
//     group: WizardCatalogueEntry
//     class?: WizardCatalogueEntry
// }

const SearchSatCodeWizardContainer: React.FC<Props> = props => {
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()

  const [types, setTypes] = useState<WizardCatalogue>([])
  const [divisions, setDivisions] = useState<WizardCatalogue>([])
  const [groups, setGroups] = useState<WizardCatalogue>([])
  const [classes, setClasses] = useState<WizardCatalogue>([])
  const [items, setItems] = useState<WizardCatalogue>([])

  const [selectedType, setSelectedType] = useState<WizardCatalogueEntry | null>(
    null
  )
  const [selectedDivision, setSelectedDivision] =
    useState<WizardCatalogueEntry | null>(null)
  const [selectedGroup, setSelectedGroup] =
    useState<WizardCatalogueEntry | null>(null)
  const [selectedClass, setSelectedClass] =
    useState<WizardCatalogueEntry | null>(null)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const [validSearchForm, setValidSearchForm] = useState(false)
  const [validSelectForm, setValidSelectForm] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)

  const getTypes = useCallback(async () => {
    try {
      const types = await thunkDispatch(getWizardCatalogue())
      setTypes(types)
    } catch (err) {
      console.log(err)
      // ToDo: Error Dialog and Retry
    }
  }, [thunkDispatch])
  const getDivisions = useCallback(async () => {
    try {
      if (selectedType) {
        const divisions = await thunkDispatch(
          getWizardCatalogue(selectedType.id)
        )
        setDivisions(divisions)
      }
    } catch (err) {
      console.log(err)
      // ToDo: Error Dialog and Retry
    }
  }, [thunkDispatch, selectedType])
  const getGroups = useCallback(async () => {
    try {
      if (selectedType && selectedDivision) {
        const groups = await thunkDispatch(
          getWizardCatalogue(selectedType.id, selectedDivision.id)
        )
        setGroups(groups)
      }
    } catch (err) {
      console.log(err)
      // ToDo: Error Dialog and Retry
    }
  }, [thunkDispatch, selectedType, selectedDivision])
  const getClasses = useCallback(async () => {
    try {
      if (selectedType && selectedDivision && selectedGroup) {
        const classes = await thunkDispatch(
          getWizardCatalogue(
            selectedType.id,
            selectedDivision.id,
            selectedGroup.id
          )
        )
        setClasses(classes)
      }
    } catch (err) {
      console.log(err)
      // ToDo: Error Dialog and Retry
    }
  }, [thunkDispatch, selectedType, selectedDivision, selectedGroup])

  useEffect(() => {
    getTypes()
  }, [getTypes])
  useDidUpdate(() => {
    getDivisions()
  }, [getDivisions])
  useDidUpdate(() => {
    getGroups()
  }, [getGroups])
  useDidUpdate(() => {
    getClasses()
  }, [getClasses])

  const resetFilters = () => {
    setSelectedType(null)
    setSelectedDivision(null)
    setDivisions([])
    setSelectedGroup(null)
    setGroups([])
    setSelectedClass(null)
    setClasses([])
    setSelectedItem(null)
    setItems([])
    setValidSelectForm(false)
  }

  const onCancel = () => {
    resetFilters()
    props.onCancel()
  }

  const onSearch = async () => {
    setLoading(true)
    try {
      let items = []
      if (selectedClass?.id)
        items = await thunkDispatch(
          getWizardCatalogue(
            selectedType?.id,
            selectedDivision?.id,
            selectedGroup?.id,
            selectedClass.id
          )
        )
      else
        items = await thunkDispatch(
          getWizardCatalogue(
            selectedType?.id,
            selectedDivision?.id,
            selectedGroup?.id
          )
        )
      setItems(items)
    } catch (err) {
      console.log(err)
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener el catálogo solicitado. Intenta nuevamente.',
        icon: Error as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  const onSelect = async () => {
    setLoadingForm(true)
    try {
      const list = await thunkDispatch(
        searchInCatalogue('items', selectedItem || '')
      )
      const item = list[0]
      props.onSelect(item)
    } catch (err) {
      console.log(err)
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener la información del producto o servicio solicitado. Intenta nuevamente',
        icon: Error as TModalIcon,
      })
    } finally {
      setLoadingForm(false)
      onCancel()
    }
  }

  const placeholder = _.map([1, 2, 3, 4], v => ({
    id: `${v}`,
    label: 'abcdefghijklmnopqrstuvwxyz',
    className: 'placeholder',
  }))
  const parsedItems = _.map(items, i => ({
    ...i,
    label: `${i.id} - ${i.label}`,
    className: i.id === selectedClass?.id ? 'class' : 'item',
  }))

  return (
    <SydDialog
      open={props.open}
      title="Descripción del SAT"
      subtitle="Consulta el catálogo de productos y servicios."
      primaryButtonText="Seleccionar"
      onConfirm={onSelect}
      disablePrimaryButton={!validSelectForm}
      loadingPrimaryButton={loadingForm}
      secondaryButtonText="Cancelar"
      onCancel={onCancel}
      sideButtons
      fullWidth
      >
      <div className="container-search-sat-code-wizard">
        <Form
          onSubmit={async () => {}}
          onValidity={(v: boolean) => setValidSearchForm(v)}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <AutocompleteField
                name="type"
                label="Tipo"
                catalogue={types}
                value={selectedType}
                onValue={(v: any) => setSelectedType(v)}
                fullWidth
                required
                disabled={types.length === 0}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteField
                name="division"
                label="Division"
                catalogue={divisions}
                value={selectedDivision}
                onValue={(v: any) => setSelectedDivision(v)}
                fullWidth
                required
                disabled={divisions.length === 0}
                disableClearable
                getOptionLabel={(o: WizardCatalogueEntry) =>
                  `${o.id.substring(0, 2)} - ${o.label}`
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteField
                name="group"
                label="Grupo"
                catalogue={groups}
                value={selectedGroup}
                onValue={(v: any) => setSelectedGroup(v)}
                fullWidth
                required
                disabled={groups.length === 0}
                disableClearable
                getOptionLabel={(o: WizardCatalogueEntry) =>
                  `${o.id.substring(0, 4)} - ${o.label}`
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteField
                name="class"
                label="Clase"
                catalogue={classes}
                value={selectedClass}
                onValue={(v: any) => setSelectedClass(v)}
                fullWidth
                include
                disabled={classes.length === 0}
                disableClearable
                getOptionLabel={(o: WizardCatalogueEntry) =>
                  `${o.id.substring(0, 6)} - ${o.label}`
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="hover"
                color="secondary"
                onClick={resetFilters}
                disabled={loading}>
                Limpiar filtros
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="warning"
                disabled={!validSearchForm}
                activity={loading}
                onClick={onSearch}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Form>
        <Form onSubmit={async () => {}} onValidity={() => {}}>
          <Divider className="form-divider" />
          <RadioGroup
            name="selectedItem"
            className="scrollbar"
            value={selectedItem}
            onValue={(v: any) => {
              setValidSelectForm(Boolean(v))
              Boolean(v) && setSelectedItem(v)
            }}
            radioOptions={items.length === 0 ? placeholder : parsedItems}
            formControlProps={{ fullWidth: true }}
            disabled={items.length === 0}
            required
          />
          <Divider className="form-divider" />
        </Form>
      </div>
    </SydDialog>
  )
}

export default SearchSatCodeWizardContainer
