import { useDispatch } from 'react-redux'
import _ from 'lodash'
import useDialog from 'hooks/Shared/useDialog'
import { getLedgerAccounts } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

export const useQueryLedgerAccounts = () => {
  const { workspaceInfo, cfdis, ledgers, updateLoadingStates } =
    useConciliationsContainerContext()

  const { cfdiActiveSection } = cfdis

  const { ledgerFilters, setLedgerRows } = ledgers

  const dispatch = useDispatch()
  const dialog = useDialog()

  const parseLedgerAccounts = (data: any) => {
    return _.flatten(
      data.map((r: any) => {
        return _.map(r.data, (d: any, key: number) => ({
          ...d,
          id: d.idLedgerAccount,
        }))
      })
    )
  }

  const queryLedgerAccounts = async (
    workspace: number,
    param: string | undefined
  ) => {
    updateLoadingStates('ledgers', true)
    dispatch(setLedgerRows([]))
    try {
      const data = await getLedgerAccounts(workspace, param)
      updateLoadingStates('ledgers', false)
      const rows = parseLedgerAccounts(data)
      if (!rows.length) return dispatch(setLedgerRows([]))
      dispatch(setLedgerRows(rows))
    } catch (error: any) {
      updateLoadingStates('ledgers', false)
      if (cfdiActiveSection !== 1) return
      if (error.status === 404) {
        if (ledgerFilters.display) {
          return await dialog.primaryAsync({
            subtitle: 'No se encontraron cuentas contables.',
            title: 'Sin resultados',
            primaryButtonText: 'Aceptar',
          })
        } else return
      }
      const retry = await dialog.dangerAsync({
        subtitle: 'No se han podido obtener las cuentas contables.',
        title: 'generic.error.title',
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })
      if (retry) {
        await queryLedgerAccounts(workspace, param)
      }
    }
  }

  const refetch = async () =>
    queryLedgerAccounts(
      workspaceInfo.activeWorkspace,
      ledgerFilters.display ? ledgerFilters.text : undefined
    )
  return {
    queryLedgerAccounts,
    refetch,
  }
}

export default useQueryLedgerAccounts
