import update from 'immutability-helper';
import { 
	SET_ACTIVE_FORM,
    RESET_ACTIVE_FORM
} from 'actions/login';

/** interfaces */
import { LoginScreenForms as Forms } from 'models/general';
import { LoginStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialLogin: LoginStore = {
	form: Forms.LOGIN
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: ""
}

const login = (state = initialLogin, action = defaultAction) => {
	switch (action.type) {
		case SET_ACTIVE_FORM:
			return update(state, { form: { $set: action.value } });
		case RESET_ACTIVE_FORM:
			return update(state, { form: { $set: Forms.LOGIN } });
		default:
			return state;
	}
}

export default login;