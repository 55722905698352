import { IconButton, Tooltip } from '@mui/material'
import { GridColDef } from '@material-ui/data-grid'
import { Payment, THistoryPaymentStatus } from 'models/payments'
import {
  HISTORY_PAYMENT_STATUS_CLASSNAME_MAP,
  HISTORY_PAYMENT_STATUS_LABEL_MAP,
} from 'lib/utils/payments.utils'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import SydTable from 'components/Shared/SydTable/SydTable'
import './styles.scss'

const PaymentsHistoryTable = () => {
  const { paymentsHistoryPaginatedData, historyPaymentDownloadActionItems } =
    usePaymentsContainerContext()

  const paymentsHistoryTableColumns: GridColDef[] = [
    {
      field: 'startPeriod',
      headerName: 'Periodo',
      minWidth: 217,
      flex: 0,
      sortable: false,
      renderCell: ({ row }) => `${row.startPeriod} - ${row.endPeriod}`,
    },
    {
      field: 'concept',
      headerName: 'Concepto',
      minWidth: 450,
      flex: 0,
      sortable: false,
      renderCell: ({ row }) => `${row.concept}`,
    },
    {
      field: 'payDay',
      headerName: 'Fecha de pago',
      minWidth: 185,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) =>
        row.status !== 'pending' ? `${row.payDay}` : '- - -',
    },
    {
      field: 'status',
      headerName: 'Estatus',
      minWidth: 160,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { status } }) => (
        <div className="payment-status">
          <span
            className={`bullet ${HISTORY_PAYMENT_STATUS_CLASSNAME_MAP[status as THistoryPaymentStatus]}`}
          />

          <span>
            {HISTORY_PAYMENT_STATUS_LABEL_MAP[status as THistoryPaymentStatus]}
          </span>
        </div>
      ),
    },
    {
      field: '',
      headerName: 'Factura PDF / XML / ZIP',
      minWidth: 180,
      flex: 0,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <div className="download-actions">
          {historyPaymentDownloadActionItems.map(
            ({ id, icon: Icon, fileType, onClick }, index) =>
              row.idInvoice ? (
                <IconButton
                  key={id}
                  disabled={!row.idInvoice}
                  onClick={() =>
                    onClick && fileType && onClick(row as Payment, fileType)
                  }>
                  <Icon />
                </IconButton>
              ) : (
                <Tooltip
                  placement="top"
                  title="No se puede descargar hasta que se registre el pago"
                  key={id}>
                  <div className="tooltip-wrapper">
                    <Icon color="#cfcfcf" />
                  </div>
                </Tooltip>
              )
          )}
        </div>
      ),
    },
  ]

  return (
    <SydTable
      className="payments-history-table"
      columns={paymentsHistoryTableColumns}
      {...paymentsHistoryPaginatedData}
      rowHeight={40}
      checkboxSelection={false}
      disableColumnMenu
      noBorder
      noDataText="No hay información de pagos para mostrar"
    />
  )
}

export default PaymentsHistoryTable
