import React from 'react'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'

import { getIndicator } from 'actions/dashboard'

import './style.scss'

import { IndicatorStatistics } from 'models/dashboard'

interface Props {
  workspaceId: number
}

const UserCountContainer = ({ workspaceId }: Props) => {
  const thunkDispatch = useThunkDispatch()

  const { data, ...query } = useQuery<IndicatorStatistics>(
    ['get-user-count', workspaceId],
    () => thunkDispatch(getIndicator(workspaceId, 'users'))
  )

  const indicator = data?.value || 'equal'

  return (
    <DashboardCard
      className="user-count-container"
      title="Número de usuarios"
      loading={query.isLoading}
      fetching={query.isFetching}
      value={data?.indicator || '- - -'}
      indicator={indicator}
    />
  )
}

export default UserCountContainer
