import {
  NPSFileRequest,
  NPSFileResponse,
  UpdateWorkspacesRequest,
  UpdateWorkspacesResponse,
} from 'models/management.models'
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import { IWorkspaceRequest } from 'services/Shared/shared.models'
import {
  IAccountantsResponse,
  IMassiveAlertResponse,
} from './management.models'
import { SCHEMA_ID, formURL, route } from './management.utils'

export const getManagementWorkspaces = async (
  queryParams: string
): Promise<any> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/workspaces/management?${queryParams}`
    const petition = await client.get(url)
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountants = async (
  workspaceId: number,
  params: {
    page: number | string
    elements: number
  }
): Promise<IAccountantsResponse> => {
  const client = HTTPClient.getClient()
  try {
    const body = { params }
    const url = formURL(workspaceId, 'score/accountants', params)
    const petition = await client.get(url, body)
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const downloadMultiWorkspaceCFDIs = async (
  workspaces: IWorkspaceRequest[]
): Promise<string> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/workspaces/multicfdi/download`
    const petition = await client.post(url, {
      request: workspaces,
    })
    return await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const generateMassiveReports = async (
  reportType: keyof typeof SCHEMA_ID,
  workspaceIds: number[]
): Promise<string> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/management/reports/${SCHEMA_ID[reportType]}?workspaces=${workspaceIds.join(',')}`
    const petition = await client.get(url)
    return await petition.text()
  } catch (err: any) {
    const error = httpErrorParser(err)
    throw error
  }
}

export const sendMassiveAlertToWorkspaces = async (
  workspaces: IWorkspaceRequest[],
  message: string
): Promise<IMassiveAlertResponse> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/workspaces/alert`
    const petition = await client.post(url, { message, request: workspaces })
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

// TODO: add service fow table data csv download

export const updateWorkspaceStatus = async (
  workspaceId: number,
  requestBody: UpdateWorkspacesRequest
): Promise<UpdateWorkspacesResponse> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.put(
      `${route}/workspaces/${workspaceId}/status`,
      requestBody
    )
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const deleteAccountantByID = async (
  workspaceId: number,
  accountantId: number
) => {
  const client = HTTPClient.getClient()
  try {
    const url = formURL(workspaceId, `score/accountants/${accountantId}`)
    await client.delete(url)
    return
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const uploadNPSFile = async (
  workspaceId: number,
  request: NPSFileRequest
): Promise<NPSFileResponse> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.post(
      `${route}/workspaces/${workspaceId}/nps`,
      request
    )
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const ocrNPSFile = async (
  workspaceId: number,
  uuid: string
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/nps/${uuid}`
    )
    await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountantsFind = async (
  workspaceId: number,
  params: {
    page: number | string
    elements: number
    param: string
  }
): Promise<IAccountantsResponse> => {
  const client = HTTPClient.getClient()
  try {
    const body = { params }
    const url = formURL(workspaceId, 'score/accountants', params)
    const petition = await client.get(url, body)
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getWorkspacesManagementCatalogs = async (): Promise<any[]> => {
  const client = HTTPClient.getClient()
  const petition = await client.get(`${route}/management/catalogs`)
  return await petition.json()
}
