import { Box, LinearProgress } from '@material-ui/core';
import { FC } from 'react';
import './styles.scss';

const TableLoadingOverlay: FC = () => {
  return (
    <Box className="table-loading-overlay">
      <LinearProgress variant="query" color="secondary" className="linear-progress" />
    </Box>
  );
};

export default TableLoadingOverlay;