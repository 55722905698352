import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { ConciliationFilterType, OCRBankStatement } from 'models/redux'
import { toPeriodString } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { getCFDISuggestions, getTransactions } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import {
  setConciliationActiveStatementRow,
  setConciliationStatementRows,
} from 'actions/conciliations'

export const useQueryStatements = () => {
  const {
    workspaceInfo,
    statements,
    conciliationPeriod,
    conciliations,
    updateLoadingStates,
    updateCFDISuggestions,
  } = useConciliationsContainerContext()

  const { selectedStatementRows, activeStatementRow, statementFilters } =
    statements

  const { month } = conciliationPeriod

  const { displayReconciled } = conciliations

  const dispatch = useDispatch()
  const dialog = useDialog()

  const responseHandler = (data: OCRBankStatement[]) => {
    dispatch(setConciliationStatementRows(data))
    const first = _.first(data) as OCRBankStatement
    if (!activeStatementRow && first) {
      dispatch(setConciliationActiveStatementRow(first.idFileBankStatement))
    }
  }

  const errorHandler = async (error: any) => {
    updateLoadingStates('statements', false)
    if (error.status === 404) {
      return await dialog.primaryAsync({
        subtitle: 'No se encontraron estados de cuenta.',
        title: 'Sin resultados',
      })
    }
    await dialog.dangerAsync({
      subtitle: 'No se han podido obtener los estados de cuentas.',
      title: 'generic.error.title',
      primaryButtonText: 'Continuar',
    })
  }

  const queryStatements = async (
    workspace: number,
    filters?: ConciliationFilterType,
    displayConcilied: boolean = false
  ) => {
    updateLoadingStates('statements', true)

    const asPeriodString = toPeriodString(
      {
        ...conciliationPeriod,
        month,
      },
      true
    )

    let enabledFilters = {
      period: asPeriodString,
      showConciliated: displayConcilied,
    } as any

    if (filters && filters.display) {
      enabledFilters = {
        ...enabledFilters,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        param: filters.text,
        type: filters.type,
      }
    }
    getTransactions(workspace, enabledFilters)
      .then(responseHandler)
      .catch(errorHandler)
      .finally(() => {
        updateLoadingStates('statements', false)
      })
  }

  const refetch = async () =>
    await queryStatements(
      workspaceInfo.activeWorkspace,
      statementFilters,
      displayReconciled
    )

  const getSuggestions = async () => {
    const movement = selectedStatementRows[0]
    try {
      const suggestions = await getCFDISuggestions(
        workspaceInfo.activeWorkspace,
        movement
      )
      updateCFDISuggestions(suggestions)
    } catch (error) {
      updateCFDISuggestions([])
    }
  }

  return {
    queryStatements,
    refetch,
    getSuggestions,
  }
}

export default useQueryStatements
