import { QueryOptions } from 'models/general'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { useMutation } from '@tanstack/react-query'
import {
  ocrNPSFile,
  updateWorkspaceStatus,
  uploadNPSFile,
} from 'services/Management/management.services'
import {
  NPSFileRequest,
  NPSFileResponse,
  UpdateWorkspacesRequest,
  UpdateWorkspacesResponse,
} from 'models/management.models'
import ErrorI from '@material-ui/icons/ErrorOutline'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

// #region NPS
export const useUploadNPSFile = (
  workspaceId: number,
  options: QueryOptions<string, NPSFileResponse, NPSFileRequest> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<NPSFileResponse, unknown, NPSFileRequest>(
    ['post-upload-nps', workspaceId, ...refreshOn],
    request => {
      onBefore && onBefore()
      return uploadNPSFile(workspaceId, request)
    },
    {
      onSuccess: data => {
        onSuccess && onSuccess(data)
      },
      onError: async (error: any, request: NPSFileRequest) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo subir el archivo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.mutate(request)
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useFetchNpsOcr = (
  workspaceId: number,
  options: QueryOptions<string, any, string> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<any, unknown, string>(
    ['get-nps-ocr', workspaceId, ...refreshOn],
    request => {
      onBefore && onBefore()
      return ocrNPSFile(workspaceId, request)
    },
    {
      onSuccess: data => {
        onSuccess && onSuccess(data)
      },
      onError: async (error: any, request: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo subir el archivo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.mutate(request)
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
// #endregion

export const useUpdateWorkspaceStatus = (
  workspaceId: number,
  options: QueryOptions<
    string,
    UpdateWorkspacesResponse,
    UpdateWorkspacesRequest
  > = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<
    UpdateWorkspacesResponse,
    unknown,
    UpdateWorkspacesRequest
  >(
    ['put-update-workspace-status', workspaceId, ...refreshOn],
    request => {
      onBefore && onBefore()
      return updateWorkspaceStatus(workspaceId, request)
    },
    {
      onSuccess: data => {
        onSuccess && onSuccess(data)
      },
      onError: async (error: any, request: UpdateWorkspacesRequest) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar el estatus del espacio de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.mutate(request)
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
