import React from 'react';
import moment from 'moment';

import './style.scss';

interface ChatMessageProps {
  messageText: string;
  messageDate:string;
  messageUser: string;
  type: 'Emisor' | 'Receptor';
}
//i18n
const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  // const intl = useIntl();
  const {type, messageText,messageDate,messageUser} = props;

  return (
    <>
      <div className="component-chat-message">
        <div className={`content -${type}`}>
          { type === 'Receptor' &&
          <div className='info-user'>
          {messageUser}
        </div>
          }
          <div className={'message-content'}>
            {messageText}
          </div>
          <div className={'message-date'}>
            {`${moment(messageDate).format("MM/DD/YYYY - HH:mm")}hrs`}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatMessage;
