import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import ErrorI from '@mui/icons-material/ErrorOutline'
import { UploadedFile } from 'models/general'
import {
  IAccountingChecklist,
  IAccountingFileType,
} from 'models/accounting.models'
import { ACCOUNTING_PROCESS_STEPS } from 'lib/enums/accounting.enums'
import {
  getAccountingFileCategoryType,
  getFileToValidate,
  getUploadedAndValidatedFile,
  getUploadedFile,
  getUploadingFile,
} from 'lib/utils/Accounting/accounting.utils'
import useDialog from 'hooks/Shared/useDialog'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import ISydDocument from 'models/shared.models'

export interface IUseAccountingStepThree {
  viewClarification: boolean
  selectedFile: ISydDocument | UploadedFile | undefined
  validRenameDialog: boolean
  validForm: boolean
  isOpenCommentHistory: boolean
  clarificationFileStatus: string | undefined
  prev: IAccountingChecklist | null
  setViewClarification: Dispatch<SetStateAction<boolean>>
  setSelectedFile: Dispatch<
    SetStateAction<ISydDocument | UploadedFile | undefined>
  >
  setValidRenameDialog: Dispatch<SetStateAction<boolean>>
  setValidForm: Dispatch<SetStateAction<boolean>>
  setIsOpenCommentHistory: Dispatch<SetStateAction<boolean>>
  setSentCommentInHistory: Dispatch<SetStateAction<boolean>>
  setLatestComment: Dispatch<SetStateAction<string | undefined>>
  setClarificationFileStatus: Dispatch<SetStateAction<string | undefined>>
  handleCompleteChecklist: (step: number) => Promise<void>
  handleIncompleteChecklist: (step: number) => void
  handleRenameFormSubmit: (form: any) => Promise<void>
  handleClarificationRequestFormSubmit: (form: any) => Promise<void>
  handleSendMessage: (message: string) => Promise<void>
  handleClarificationFormSubmit: (form: any) => Promise<void>
  handleClarificationCheckFormSubmit: (form: any) => Promise<void>
  latestComment?: string
  currentChecklist?: IAccountingChecklist
  clarificationRequestFileType?: IAccountingFileType
  clarificationFileType?: IAccountingFileType
  proxyClarificationRequestFile?: UploadedFile
  proxyClarificationFile?: UploadedFile
  uploadedClarificationRequestFile?: ISydDocument
  uploadedClarificationFile?: ISydDocument
  clarificationRequestFileToValidate?: ISydDocument
  clarificationFileToValidate?: ISydDocument
  uploadedAndValidatedClarificationFile?: ISydDocument
}

const useAccountingStepThree = (): IUseAccountingStepThree => {
  const dialog = useDialog()

  const {
    filesToUpload,
    checklist,
    filesInProcess,
    handleFileRename,
    handleFileValidation,
    handleProcessCheck,
    handleStepSubmit,
  } = useAccountingContext()

  const clarificationRequestFileType = getAccountingFileCategoryType(7)
  const clarificationFileType = getAccountingFileCategoryType(8)

  const proxyClarificationRequestFile = getUploadingFile(
    filesToUpload,
    clarificationRequestFileType
  )
  const proxyClarificationFile = filesToUpload.find(
    f => clarificationFileType?.type === f?.description
  )

  const uploadedClarificationRequestFile = getUploadedFile(
    filesInProcess,
    clarificationRequestFileType
  )
  const uploadedClarificationFile = getUploadedFile(
    filesInProcess,
    clarificationFileType
  )

  const clarificationRequestFileToValidate = getFileToValidate(
    filesInProcess,
    clarificationRequestFileType
  )
  const clarificationFileToValidate = getFileToValidate(
    filesInProcess,
    clarificationFileType
  )

  const uploadedAndValidatedClarificationFile = getUploadedAndValidatedFile(
    filesInProcess,
    clarificationFileType
  )

  const [viewClarification, setViewClarification] = useState(
    !!proxyClarificationRequestFile ||
      !!proxyClarificationFile ||
      !!uploadedClarificationRequestFile ||
      !!uploadedAndValidatedClarificationFile ||
      !!uploadedClarificationFile ||
      !!clarificationRequestFileToValidate ||
      !!clarificationFileToValidate
  )
  const [selectedFile, setSelectedFile] = useState<
    ISydDocument | UploadedFile | undefined
  >()
  const [validRenameDialog, setValidRenameDialog] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [isOpenCommentHistory, setIsOpenCommentHistory] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sentCommentInHistory, setSentCommentInHistory] = useState(false)
  const [latestComment, setLatestComment] = useState<string>()
  const [clarificationFileStatus, setClarificationFileStatus] = useState<
    string | undefined
  >()

  const prev = useMemo(() => {
    return checklist.reduceRight<IAccountingChecklist | null>(
      (lastMatch, item) => {
        if (lastMatch) return lastMatch
        if (item.status === 2) return item
        return null
      },
      null
    )
  }, [checklist])

  const currentChecklist = useMemo(
    () => checklist.find(c => c.status === 1),
    [checklist]
  )

  const handleCompleteChecklist = async (step: number) => {
    if (!currentChecklist) return

    let rp = true
    if (step === 4) {
      setViewClarification(
        !!proxyClarificationRequestFile ||
          !!uploadedClarificationRequestFile ||
          !!clarificationRequestFileToValidate ||
          !!proxyClarificationFile ||
          !!uploadedClarificationFile ||
          !!clarificationFileToValidate ||
          !!uploadedAndValidatedClarificationFile
      )
    } else if (
      step === 5 &&
      viewClarification &&
      !uploadedAndValidatedClarificationFile
    ) {
      rp = (await dialog.primaryAsync({
        title: '¿Está seguro?',
        message: 'Si continúa, este proceso de aclaración será omitido.',
        icon: ErrorI as TModalIcon,
        primaryButtonText: 'Sí, avanzar',
        secondaryButtonText: 'Cancelar',
      })) as boolean
    }

    rp && handleProcessCheck(currentChecklist, 2)
  }

  const handleIncompleteChecklist = (step: number) => {
    if (!prev) return
    if (step === 6) {
      setViewClarification(
        !!proxyClarificationRequestFile ||
          !!uploadedClarificationRequestFile ||
          !!clarificationRequestFileToValidate ||
          !!proxyClarificationFile ||
          !!uploadedClarificationFile ||
          !!clarificationFileToValidate ||
          !!uploadedAndValidatedClarificationFile
      )
    }
    handleProcessCheck(prev, 1)
  }

  const handleRenameFormSubmit = async (form: any) => {
    if (!selectedFile) return
    handleFileRename(form.name, selectedFile)
    setSelectedFile(undefined)
  }

  const handleClarificationRequestFormSubmit = async (form: any) => {
    !!uploadedClarificationRequestFile &&
      handleStepSubmit(
        ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES,
        form.comment,
        [uploadedClarificationRequestFile],
        undefined,
        1
      )
    setValidForm(false)
  }

  const handleSendMessage = async (message: string) => {
    const result = await handleStepSubmit(
      ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES,
      message,
      undefined,
      undefined,
      2
    )
    setSentCommentInHistory(true)
    return result
  }

  const handleClarificationFormSubmit = async () => {
    !!uploadedClarificationFile &&
      handleStepSubmit(
        ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES,
        '',
        [uploadedClarificationFile]
      )
    setSentCommentInHistory(false)
  }

  const handleClarificationCheckFormSubmit = async (form: any) => {
    if (!clarificationFileToValidate || !currentChecklist) return

    if (clarificationFileStatus === 'valid') {
      await handleFileValidation(
        clarificationFileToValidate,
        clarificationFileStatus
      )
      handleProcessCheck(currentChecklist, 2)
    } else {
      handleStepSubmit(
        ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES,
        form.comment,
        [],
        [clarificationFileToValidate],
        1
      )
    }

    setClarificationFileStatus(undefined)
    setValidForm(false)
    setLatestComment(undefined)
  }

  return {
    viewClarification,
    selectedFile,
    validRenameDialog,
    validForm,
    isOpenCommentHistory,
    latestComment,
    clarificationFileStatus,
    prev,
    setViewClarification,
    setSelectedFile,
    setValidRenameDialog,
    setValidForm,
    setIsOpenCommentHistory,
    setSentCommentInHistory,
    setLatestComment,
    setClarificationFileStatus,
    handleCompleteChecklist,
    handleIncompleteChecklist,
    handleRenameFormSubmit,
    handleClarificationRequestFormSubmit,
    handleSendMessage,
    handleClarificationFormSubmit,
    handleClarificationCheckFormSubmit,
    currentChecklist,
    clarificationRequestFileType,
    clarificationFileType,
    proxyClarificationRequestFile,
    proxyClarificationFile,
    uploadedClarificationRequestFile,
    uploadedClarificationFile,
    clarificationRequestFileToValidate,
    clarificationFileToValidate,
    uploadedAndValidatedClarificationFile,
  }
}

export default useAccountingStepThree
