import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';

const ManagementWorkspacesTableChartWheel: FC<{
  total: number;
  current: number;
  label: string;
}> = ({ total, current, label }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (current / total) * circumference;

  return (
    <Box>
      <Box
        position="relative"
        width={120}
        height={120}
      >
        <svg width="120" height="120">
          <circle
            stroke="#6885A0"
            fill="transparent"
            strokeWidth="16"
            r={radius}
            cx="60"
            cy="60"
          />
          <circle
            stroke="#95D357"
            fill="transparent"
            strokeWidth="16"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            r={radius}
            cx="60"
            cy="60"
          />
        </svg>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bold',
            }}
          >
            {current}%
          </Typography>
          <Typography
            variant="caption"
            style={{
              maxWidth: '70%',
              textAlign: 'center',
              lineHeight: '1',
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementWorkspacesTableChartWheel;