import Profiling, { Answer, ProfilingFormModel, Question, QuestionForm } from "models/profiling"

const findDependentsQuestions = (id: number, questions: Question []): boolean => {
  const depndents = questions.find((question) => question.isRequiredBy && parseInt(question.isRequiredBy) === id);
  if(depndents !== undefined){
    return true
  }else {
    return false
  }
}


export const buildProfilingForm = (profiling: Profiling [], answers: Answer []): ProfilingFormModel [] => {
  let response: ProfilingFormModel [] = [];
  
  profiling.forEach((profile) => {
    let questions: QuestionForm [] = []
    questions = profile.questions.map((profileQuestion) => ({
      ...profileQuestion, 
      answer: answers.find((answer) => answer.question.id === profileQuestion.id)?.answer,
      needToListen: findDependentsQuestions(profileQuestion.id, profile.questions)       
    }))

    response.push({
      id: profile.id,
      id_workspace: profile.id_workspace,
      name: profile.name,
      questions: questions
    })

  })
  return response
}
