import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import SearchSatCodeWizardContainer from 'containers/Shared/SearchSatCodeWizardContainer/SearchSatCodeWizardContainer'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from 'components/Shared/Button/Button'
import Dropdown from 'components/FormComponents/Dropdown/Dropdown'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import SearchField from 'components/FormComponents/IOComponents/SearchField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import {
  createTax,
  deleteConcept,
  deleteTax,
  setConceptValue,
} from 'actions/invoices'
import { searchInCatalogue } from 'actions/catalogues'

import { selectCatalogue } from 'lib/helpers/selectors'

import Delete from '@material-ui/icons/Delete'
import Search from '@material-ui/icons/Search'
import './style.scss'

import { CatalogueEntry } from 'models/catalogues'
import { Tax, TaxCatalogue, TaxCatalogueEntry } from 'models/invoice'
import { isNumberInRange } from 'lib/helpers/ioValidations'
import { TAXES_FACTORS } from '../../../../constants'

interface Props {
  impuestosCat: TaxCatalogue
  uuid: string
  title: string
  clave?: CatalogueEntry | null
  unidad?: CatalogueEntry | null
  objImp?: CatalogueEntry | null
  precio?: string
  cantidad?: string
  descuento?: string
  descripcion?: string
  impuestos?: Tax[]
  importacion?: string
  id?: number
}

const AddConceptV2Container = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()

  const unidadesCat = useSelector(selectCatalogue('unidades'))
  const objImpCat = useSelector(selectCatalogue('objImp'))

  const [selectedTax, setSelectedTax] = useState<TaxCatalogueEntry | null>(null)
  const [rate, setRate] = useState<string>('')
  const [fee, setFee] = useState<string>('')
  const [openWizard, setOpenWizard] = useState(false)

  const addTax = () => {
    if (!selectedTax) return
    const tax: Tax = {
      ...selectedTax,
      uuid: uuid(),
      selectedValue: selectedTax.factor === 'Tasa' ? Number(rate) : Number(fee),
    }
    dispatch(createTax(props.uuid, tax))
    setRate('')
    setFee('')
    setSelectedTax(null)
  }

  const saveValueToStore = (name: string, value: any) => {
    dispatch(setConceptValue(props.uuid, name, value))
  }

  const getTaxValue = (factor: string, value: number) => {
    switch (factor) {
      case 'Tasa': {
        if (!value && value !== 0) return ''
        const formatted = intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
        })
        return formatted
      }
      case 'Cuota': {
        if (!value && value !== 0) return ''
        const formatted = intl.formatNumber(value, {
          style: 'currency',
          currency: 'MXN',
          currencyDisplay: 'narrowSymbol',
        })
        return formatted
      }
      default:
        return ''
    }
  }
  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  const isDisableAddTaxButton = useMemo(() => {
    if (!selectedTax) return true
    const { factor } = selectedTax
    if (factor === TAXES_FACTORS.exent) return false
    if (factor === TAXES_FACTORS.rate && rate !== '') return false
    if (factor === TAXES_FACTORS.fee && fee !== '') return false
    return true
  }, [selectedTax, rate, fee])

  return (
    <Dropdown
      id={`conceptDropdownBtn_${props.id}`}
      title={props.title}
      className="add-invoice-product-form"
      capitalize
      withDivider
      defaultExpanded>
      <Grid container spacing={4} className="basic-information">
        <Grid item xs={12} className="item-actions">
          <Button
            id={`deleteConceptBtn_${props.id}`}
            startIcon={<Delete />}
            onClick={() => dispatch(deleteConcept(props.uuid))}>
            Eliminar Concepto
          </Button>
        </Grid>
        <Grid item xs={4}>
          <SearchField
            name={`claveSat_${props.id}`}
            label="Clave SAT"
            onSearch={(v: string) =>
              thunkDispatch(searchInCatalogue('items', v))
            }
            value={props.clave}
            onValue={(v: CatalogueEntry) => saveValueToStore('claveSat', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            renderOption={(o: any) => renderCatalogueOption(o)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setOpenWizard(true)} size="small">
                  <Search />
                </IconButton>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            name={`unidad_${props.id}`}
            label="Unidad o medida"
            catalogue={unidadesCat}
            value={props.unidad}
            onValue={(v: CatalogueEntry) => saveValueToStore('claveUnitSat', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            renderOption={(o: any) => renderCatalogueOption(o)}
            getOptionSelected={(
              option: CatalogueEntry,
              value: CatalogueEntry
            ) => option.idCatalogValues === value.idCatalogValues}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            name={`objImp_${props.id}`}
            label="Objeto de Impuesto"
            catalogue={objImpCat}
            value={props.objImp}
            onValue={(v: CatalogueEntry) => saveValueToStore('objImp', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            renderOption={(o: any) => renderCatalogueOption(o)}
            getOptionSelected={(
              option: CatalogueEntry,
              value: CatalogueEntry
            ) => option.idCatalogValues === value.idCatalogValues}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <NumericField
            name={`precioUnitario_${props.id}`}
            label="Precio unitario"
            value={props.precio}
            onValue={(v: string) => saveValueToStore('price', v)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            format="money"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <NumericField
            name={`cantidad_${props.id}`}
            label="Cantidad"
            validate={['isMoreThanZero']}
            value={props.cantidad}
            onValue={(v: string) => saveValueToStore('cantidad', v)}
            format="money"
            fullWidth
            required
          />
        </Grid>
        {/* <Grid item xs={4}>
          <NumericField
            name="descuento"
            label="Descuento"
            value={props.descuento}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onValue={(v: string) => saveValueToStore("descuento", v)}
            fullWidth
            required
            exclude
          />
        </Grid> */}
        <Grid item xs />
        <Grid item xs={8}>
          <p className="form-label">Descripción</p>
          <TextField
            variant="outlined"
            fieldType="alphanumericWithSpecialCharacters"
            name={`description_${props.id}`}
            value={props.descripcion}
            onValue={(v: string) => saveValueToStore('description', v)}
            inputProps={{ maxLength: 1000 }}
            multiline
            minRows={4}
            maxRows={4}
            withCounter
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Dropdown
        id={`impuestosDropdown_${props.id}`}
        title="Impuestos"
        headerBackgroundColor="gray"
        bordered>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <AutocompleteField
              name={`impuesto_${props.id}`}
              label="Impuesto"
              catalogue={props.impuestosCat}
              value={selectedTax}
              onValue={(v: any) => {
                setSelectedTax(v)
                setRate('')
                setFee('')
              }}
              getOptionLabel={(o: any) =>
                `${_.padStart(o.id, 2, '0')} - ${o.label}`
              }
              fullWidth
              exclude
            />
          </Grid>
          {(() => {
            console.log(
              selectedTax,
              _.map(selectedTax?.value, v => ({
                label: `${v * 100}%`,
                value: v,
              }))
            )
          })()}
          {!_.isEmpty(selectedTax) &&
            _.isArray(selectedTax?.value) &&
            _.get(selectedTax, 'value.length', 0) > 1 && (
              <Grid item xs={4}>
                <AutocompleteField
                  name={`tasa_${props.id}`}
                  label="Tasa (%)"
                  catalogue={_.map(selectedTax?.value, v => ({
                    label: `${v * 100}%`,
                    value: v,
                  }))}
                  value={
                    rate !== ''
                      ? { label: `${Number(rate) * 100}%`, value: rate }
                      : undefined
                  }
                  onValue={(v: any) => setRate(v ? v.value : '')}
                  getOptionLabel={(o: any) => o.label}
                  fullWidth
                  exclude
                />
              </Grid>
            )}
          {!_.isEmpty(selectedTax) && !_.isArray(selectedTax?.value) && (
            <Grid item xs={4}>
              <NumericField
                name={`cuota_${props.id}`}
                label="Cuota"
                value={fee}
                onValue={(v: string) => setFee(v)}
                validate={(v: string) => {
                  const min =
                    Number(props.cantidad) *
                    Number(props.precio) *
                    _.get(selectedTax, 'value.min', 0)
                  const max =
                    Number(props.cantidad) *
                    Number(props.precio) *
                    _.get(selectedTax, 'value.max', 0)
                  return isNumberInRange(v, min, max)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                format="money"
                fullWidth
                exclude
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              id={`conceptAddTaxBtn_${props.id}`}
              variant="outlined"
              color="warning"
              // disabled={(_.indexOf([1, 2, 4, 5, 6], selectedTax?.id) > -1)
              //   ? !(!!selectedTax && rate !== "" && Number(rate) >= 0)
              //   : !(!!selectedTax && !!fee)
              // }
              disabled={isDisableAddTaxButton}
              onClick={addTax}>
              Agregar
            </Button>
          </Grid>
          {!_.isEmpty(props.impuestos) && (
            <Grid item xs={12} className="table-taxes">
              <Table size="small">
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell
                      className="table-cell"
                      style={{ width: '150px' }}>
                      Tipo de impuesto
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{ width: '300px' }}>
                      Impuesto
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{ width: '150px' }}>
                      Tasa o cuota
                    </TableCell>
                    <TableCell className="table-cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(props.impuestos, i => (
                    <TableRow className="table-row" key={i.uuid}>
                      <TableCell className="table-cell">{i.type}</TableCell>
                      <TableCell className="table-cell">
                        {/* {`${_.padStart(i.sat.value, 3, "0")} - ${i.sat.label}`} */}
                        {`
                            ${_.padStart(_.get(i, 'sat.value', ''), 3, '0')}
                            -
                            ${_.get(i, 'sat.label', '')}
                          `}
                      </TableCell>
                      <TableCell className="table-cell">
                        {getTaxValue(i.factor, i.selectedValue) || 'N/A'}
                      </TableCell>
                      <TableCell className="table-cell table-actions">
                        <IconButton
                          id={`conceptDeleteTaxBtn_${props.id}_${i}`}
                          onClick={() =>
                            dispatch(deleteTax(props.uuid, i.uuid))
                          }
                          size="small">
                          <Delete className="delete" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </Dropdown>
      {/*
            <Dropdown title="Impuestos locales" headerBackgroundColor="gray" bordered>

            </Dropdown>
            <Dropdown title="Importación" headerBackgroundColor="gray" bordered>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TextField
                            name="pedimento"
                            label="No. de pedimento"
                            fieldType="number"
                            fullWidth
                            exclude
                        />
                    </Grid>
                </Grid>
            </Dropdown>
            <Dropdown title="Cuenta predial" headerBackgroundColor="gray" bordered>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TextField
                            name="predial"
                            label="Nùmero"
                            fieldType="number"
                            fullWidth
                            exclude
                        />
                    </Grid>
                </Grid>
            </Dropdown>
            <Dropdown title="Complementos del concepto" headerBackgroundColor="gray" bordered>

            </Dropdown>
            */}
      <SearchSatCodeWizardContainer
        open={openWizard}
        onSelect={(v: CatalogueEntry) => saveValueToStore('claveSat', v)}
        onCancel={() => setOpenWizard(false)}
      />
    </Dropdown>
  )
}

export default AddConceptV2Container
