import { toast } from 'react-toastify'
import { UseMutationResult } from '@tanstack/react-query'
import { TWorkspaceParams } from 'models/general'
import { CONTROLLED_ERROR_CODES } from 'lib/enums/shared.enums'
import { sseErrorCodeToastMessagesMapp } from 'lib/utils/shared.utils'
import { successToastOptions, errorToastOptions } from 'lib/utils/toast.utils'
import { IApiError } from 'services/Shared/shared.models'
import { getComplianceOpinionsService } from 'services/Shared/shared.services'
import useCreateCFDIComplianceDownloadMutation from './useCreateCFDIComplianceDownloadMutation'

const useDownloadComplianceOpinions = (
  selectedWorkspaceIds: number[]
): UseMutationResult<string, IApiError, TWorkspaceParams> => {
  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const downloadComplianceOpininons = useCreateCFDIComplianceDownloadMutation({
    loadingToastOptions,
    errorToastOptions,
    service: getComplianceOpinionsService,
    fileName: 'opiniones',
    loadingMessage: `Descargando ${selectedWorkspaceIds.length > 1 ? 'opiniones de cumplimiento' : 'opinión de cumplimiento'}`,
    onError: error => {
      const errorCode = Object.values(CONTROLLED_ERROR_CODES).find(code =>
        error.code.includes(code)
      )

      if (errorCode && sseErrorCodeToastMessagesMapp[errorCode]) {
        toast.error(sseErrorCodeToastMessagesMapp[errorCode], errorToastOptions)
      }

      ;(!errorCode || !sseErrorCodeToastMessagesMapp[errorCode]) &&
        toast.error(
          selectedWorkspaceIds.length > 1
            ? 'No fue posible descargar las opiniones de cumplimiento'
            : 'No fue posible descargar la opinión de cumplimiento',
          errorToastOptions
        )
    },
  })

  return downloadComplianceOpininons
}

export default useDownloadComplianceOpinions
