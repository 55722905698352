import {
  TControlledErrorCodes,
  TServerSentOperations,
} from 'models/shared.models'
import {
  STORE_EVENT_TYPES,
  CONTROLLED_ERROR_CODES,
  SERVER_SENT_OPERATIONS,
} from 'lib/enums/shared.enums'
import { TStoreEvents } from 'store/StoreEvent/storeEvent.models'

export const months: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
}

export const sseErrorCodeToastMessagesMapp: Readonly<
  Partial<Record<TControlledErrorCodes, string>>
> = {
  [CONTROLLED_ERROR_CODES.NET_002]:
    'El servicio está temporalmente indisponible, por favor intenta de nuevo.',
  [CONTROLLED_ERROR_CODES.DB_003]:
    'Necesita sincronizar antes de poder descargar opiniones de cumplimiento.',
} as const

export const operationIdGlobalEventTypeMap: Readonly<
  Partial<Record<TServerSentOperations, TStoreEvents>>
> = {
  [SERVER_SENT_OPERATIONS.COMPLIANCE]:
    STORE_EVENT_TYPES.FETCH_MANAGEMENT_WORKSPACES,
} as const
