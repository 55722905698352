import { orange, red } from '@material-ui/core/colors'
import { FC, Fragment, useMemo, useState } from 'react'
import BankIcon from '@material-ui/icons/AccountBalance'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ReportItem } from 'services/conciliations'
import {
  Transaction,
  useConciliationReportsContext,
} from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import { toCurrency } from 'lib/helpers/utilities'
import { TYPES } from '../ConciliationReportDetails/ConciliationReportDetails'
import _ from 'lodash'
import Deconciliate from 'components/Conciliations/Deconciliate/Deconciliate'
import { useDispatch } from 'react-redux'
import useGetConciliationReports from 'hooks/useGetConciliationReports'

const iconProps: any = {
  fontSize: 'small',
  style: {
    color: orange[500],
  },
}

const parentIconProps: any = {
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const Item: FC<{
  item: TransactionType
  report: ReportItem
}> = ({ item, report }) => {
  const details = useMemo(
    () => [
      {
        icon: <LocalAtmIcon {...iconProps} />,
        value: `Código: ${item.code}`,
      },
      {
        icon: null,
        value: item.name,
      },
    ],
    [item]
  )
  return (
    <Box display="flex" flexDirection="column" gridGap={10} padding="15px 35px">
      {details.map((detail, index) => (
        <Box key={index} display="flex" alignItems="center" gridGap={8}>
          <Box {...parentIconProps}>{detail.icon}</Box>
          <Typography
            variant="body2"
            style={{
              textTransform: 'capitalize',
            }}>
            {detail.value}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

const CFDI_STATUS = {
  vigente: 'Vigente',
  cancelado: 'Cancelado',
}

const CFDIList: FC<{ list: TransactionType[] }> = ({ list }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={10}
      padding="15px 35px"
      paddingRight={0}>
      {list.map((item, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          gridGap={8}
          color={item.status === CFDI_STATUS.cancelado ? red[500] : ''}>
          <Box {...parentIconProps}>
            <InsertDriveFile {...iconProps} />
          </Box>
          <Box flex={1}>UUID: {item.uuid}</Box>
          <Box>{item.status}</Box>
          <Box flex={1} textAlign="right">
            {toCurrency(item.amount, 'MXN')}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const Concept: FC<{ transaction: Transaction }> = ({ transaction }) => {
  return (
    <Box padding="15px 35px" paddingBottom={0}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <Box
          width={16}
          height={16}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <BankIcon {...iconProps} />
        </Box>
        <Box flex={1}>{transaction.concept}</Box>
      </Box>
    </Box>
  )
}

const CollapseData: FC<{
  open: boolean
  report: ReportItem
  items: TransactionType[]
  transaction: Transaction
}> = ({ open, report, items, transaction }) => {
  const { fetchReports } = useGetConciliationReports()
  const { setSelectedReport } = useConciliationReportsContext()
  const dispatch = useDispatch()

  const onDeconciliate = async () => {
    try {
      dispatch(setSelectedReport(null))
      await fetchReports(false)
    } catch (error: any) {
      console.log(error)
    }
  }

  return (
    <TableRow>
      <TableCell
        colSpan={4}
        style={{
          padding: 0,
          border: 'none',
        }}>
        <Collapse in={open} unmountOnExit>
          <Concept transaction={transaction} />
          {report.type === TYPES.CFDIS && <CFDIList list={items} />}
          {report.type === TYPES.CUENTAS_CONTABLES &&
            items.map((item, index) => (
              <Item key={index} item={item} report={report} />
            ))}
          <Box display="flex" justifyContent="flex-end">
            <Deconciliate
              movementId={transaction.idTransaction}
              onComplete={onDeconciliate}
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export type TransactionType = {
  amount: number
  idTransactionConciliated: number
  status?: string
  uuid?: string
  name?: string
  code?: string
}

const ConciliationReportDetailsReportDataRow: FC<{
  report: ReportItem
  transaction: Transaction
}> = ({ report, transaction }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  const tableCellProps: any = {
    padding: 'none',
    style: {
      paddingBlock: 5,
    },
  }

  const formattedAmount = toCurrency(transaction.amount, 'MXN')
  const cfdisLength = transaction.cfdis?.length || 0

  const hasCancelledCFDIS = useMemo(() => {
    if (transaction.cfdis) {
      if (_.every(transaction.cfdis, { status: CFDI_STATUS.vigente })) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [transaction])

  return (
    <Fragment>
      <TableRow
        style={{
          backgroundColor: hasCancelledCFDIS ? red[50] : '',
        }}>
        <TableCell {...tableCellProps}>
          <IconButton
            size="small"
            onClick={toggleOpen}
            style={{
              marginRight: 10,
            }}>
            <ChevronDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
              }}
            />
          </IconButton>
          1 movimiento
        </TableCell>
        <TableCell {...tableCellProps}>
          {report.type === TYPES.CUENTAS_CONTABLES && 'Cuenta contable'}
          {report.type === TYPES.CFDIS &&
            `${cfdisLength} CFDI${cfdisLength > 1 ? 's' : ''}`}
        </TableCell>
        {report.type === TYPES.CFDIS && (
          <TableCell {...tableCellProps}></TableCell>
        )}
        <TableCell {...tableCellProps} align="right">
          {formattedAmount}
        </TableCell>
      </TableRow>
      <CollapseData
        open={open}
        report={report}
        transaction={transaction}
        items={_.get(
          transaction,
          report.type === TYPES.CFDIS ? 'cfdis' : 'ledgerAccounts',
          []
        )}
      />
    </Fragment>
  )
}

export default ConciliationReportDetailsReportDataRow
