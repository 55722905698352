import React from 'react'

import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import './style.scss'

import { ContextColors } from 'models/general'

interface Props extends BackdropProps {
  color: ContextColors
  text?: string
  direction?: 'row' | 'column'
}

const Loader: React.FC<Props> = props => {
  const { color, text = '', direction = 'row', ...rest } = props
  return (
    <Backdrop className={`component-backdrop ${color} ${direction}`} {...rest}>
      <CircularProgress color="inherit" size={45} thickness={4} />
      {Boolean(text) && <h1>{text}</h1>}
    </Backdrop>
  )
}

export default Loader
