import React, { useState, useRef } from 'react'
import { useDidMount, useDidUpdate } from 'rooks'
import { useIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import Button from 'components/Shared/Button/Button'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import Checkbox from 'components/FormComponents/IOComponents/Checkbox/Checkbox'

import { getPricing } from 'lib/helpers/utilities'

import TicketI from '@material-ui/icons/ConfirmationNumber'
import './style.scss'

import { Plan } from 'models/general'
import { CatalogueEntry } from 'models/catalogues'
import _ from 'lodash'
import PlanCard from 'components/Workspaces/PlanCardAlt'
interface Props {
  type: CatalogueEntry | null
  name: string
  rfc: string
  plan: Plan
  additionalPlan: Plan[]
  freeTrial?: boolean
  invoiceAmount1?: number
  invoiceQuantity1?: number
  withInventory1?: boolean
  invoiceAmount2?: number
  invoiceQuantity2?: number
  withInventory2?: boolean
  payroll?: number
  acceptContract: boolean
  acceptTerms: boolean
  promotionalCode?: string
  valid?: boolean
  storeValue?: (name: string, value: any) => void
  openDialog?: () => void
  formRef?: any
}

const CreateWorkspaceStepTwo: React.FC<Props> = ({ storeValue, ...props }) => {
  const intl = useIntl()
  const scrollRef = useRef<null | HTMLInputElement>(null)

  const [open, setOpen] = useState(false)

  useDidMount(() => {
    if (props.freeTrial)
      scrollRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  })

  useDidUpdate(() => {
    if (props.plan !== 'facturacion')
      storeValue && storeValue('invoiceQuantity1', '')
    storeValue && storeValue('freeTrial', false)
    if (props.plan === '') storeValue && storeValue('acceptContract', false)
  }, [props.plan])

  useDidUpdate(() => {
    if (props.freeTrial && storeValue) {
      storeValue('additionalPlan', 'reset')
      storeValue('invoiceQuantity1', 5)
      scrollRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    } else if (!props.freeTrial && storeValue) {
      storeValue('invoiceQuantity1', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.freeTrial])

  return (
    <div className="create-workspace-step-two-container">
      <h3 className="title">
        {intl.formatMessage({ id: 'workspace.create.step.2.title' })}
      </h3>
      <p className="about-you">
        {intl.formatMessage({ id: 'workspace.create.step.2.subtitle' })}
      </p>
      <Divider className="divider" />
      <div className="form-container">
        <PlanCard
          plan="facturacion"
          price={
            !!props?.invoiceQuantity1
              ? getPricing('facturacion', props?.invoiceQuantity1, 5000)
              : undefined
          }
          endPriceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.iva_monthly.label',
          })}
          selected={props.plan === 'facturacion'}
          onSelect={(p, s) => storeValue && storeValue('plan', s ? p : '')}>
          <NumericField
            format="quantity"
            name="invoiceQuantity1"
            label={intl.formatMessage({
              id: 'workspace.create.step.2.form.plan.invoices_quantity.label',
            })}
            placeholder={intl.formatMessage({
              id: 'workspace.create.step.2.form.plan.invoices_quantity.placeholder',
            })}
            value={props?.invoiceQuantity1 || ''}
            onValue={(v: number) =>
              storeValue && storeValue('invoiceQuantity1', v)
            }
            onClick={(e: any) => e.stopPropagation()}
            validate={(v: number) => ({
              valid: props.freeTrial ? v >= 1 && v < 6 : v > 5 && v <= 159998,
              message: 'Esta cantidad esta fuera de rango',
            })}
            disabled={props.plan !== 'facturacion'}
            required={props.plan === 'facturacion'}
            fullWidth
          />
          <Checkbox
            size="small"
            name="freeTrial"
            label="Quiero probarlo antes"
            onClick={(e: any) => e.stopPropagation()}
            value={props.plan === 'facturacion' && props.freeTrial}
            onValue={(v: boolean) => storeValue && storeValue('freeTrial', v)}
            disabled={props.plan !== 'facturacion'}
          />
        </PlanCard>
        <br />
        <PlanCard
          plan="contabilidad"
          price={839}
          priceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.per_month.label',
          })}
          startPriceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.from.label',
          })}
          selected={props.plan === 'contabilidad'}
          onSelect={(p, s) => storeValue && storeValue('plan', s ? p : '')}
          disabled={props.freeTrial}
          title={
            props.freeTrial ? `No disponible para prueba gratuita` : undefined
          }>
          <p className="addon">
            {intl.formatMessage({
              id: 'workspace.create.step.2.form.plan.includes_bill.label',
            })}
          </p>
        </PlanCard>
        <br />
        {/* <div className="icon-container">
                    <AddI className="plus-icon" color="inherit" />
                </div>
                <br /> */}
        <PlanCard
          plan="nomina"
          price={180}
          priceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.per_employee.label',
          })}
          selected={_.indexOf(props.additionalPlan, 'nomina') > -1}
          onSelect={(p, s) => storeValue && storeValue('additionalPlan', p)}
          disabled={props.freeTrial}
          title={
            props.freeTrial ? `No disponible para prueba gratuita` : undefined
          }
        />
        <br />
        <PlanCard
          plan="repse"
          selected={_.indexOf(props.additionalPlan, 'repse') > -1}
          onSelect={(p, s) => storeValue && storeValue('additionalPlan', p)}
          disabled={props.freeTrial}
          title={
            props.freeTrial ? `No disponible para prueba gratuita` : undefined
          }
        />
        <br />
        <PlanCard
          plan="declaracion"
          price={500}
          priceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.per_declaration.label',
          })}
          startPriceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.from.label',
          })}
          endPriceAdornment={intl.formatMessage({
            id: 'workspace.create.step.2.form.plan.refund_management_price.label',
          })}
          selected={_.indexOf(props.additionalPlan, 'declaracion') > -1}
          onSelect={(p, s) => storeValue && storeValue('additionalPlan', p)}
          disabled={props.freeTrial}
          title={
            props.freeTrial ? `No disponible para prueba gratuita` : undefined
          }>
          <p className="addon">
            {intl.formatMessage({
              id: 'workspace.create.step.2.form.plan.refund_management.label',
            })}
          </p>
        </PlanCard>
        <br />
        <PlanCard
          plan="regularizacion"
          selected={_.indexOf(props.additionalPlan, 'regularizacion') > -1}
          onSelect={(p, s) => storeValue && storeValue('additionalPlan', p)}
          disabled={props.freeTrial}
          title={
            props.freeTrial ? `No disponible para prueba gratuita` : undefined
          }
        />
        <br />
        <Checkbox
          name="acceptTerms"
          inputRef={scrollRef}
          label={intl.formatMessage(
            { id: 'workspace.create.step.1.form.terms.label' },
            {
              link: (...chunks) => (
                <a
                  href="https://www.simmple.mx/terminos-y-condiciones"
                  target="_blank"
                  rel="noreferrer">
                  {chunks}
                </a>
              ),
            }
          )}
          value={props.acceptTerms}
          onValue={(v: boolean) => storeValue && storeValue('acceptTerms', v)}
        />
        <Checkbox
          name="acceptContract"
          label={intl.formatMessage(
            { id: 'workspace.create.step.1.form.contract.label' },
            {
              link: (...chunks) => (
                <a
                  className={
                    props.plan !== 'facturacion' &&
                    props.plan !== 'contabilidad'
                      ? 'disabled'
                      : undefined
                  }
                  href="/contrato-persona-moral"
                  onClick={e => {
                    e.preventDefault()
                    setOpen(true)
                  }}>
                  {chunks}
                </a>
              ),
            }
          )}
          value={props.acceptContract}
          disabled={
            !(props.plan === 'facturacion' || props.plan === 'contabilidad')
          }
          onValue={(v: boolean) =>
            storeValue && storeValue('acceptContract', v)
          }
        />
      </div>
      <div className="promotions-container">
        <p className="promotion-title">
          {intl.formatMessage({
            id: `workspace.create.step.2.form.promotion.code`,
          })}
        </p>
        <TextField
          fieldType="alphanumeric"
          variant="outlined"
          className="promotion-field code"
          name="code"
          //disabled={props.plan === "declaracionAnual"}
          value={props.promotionalCode}
          onValue={(v: string) =>
            storeValue && storeValue('promotionalCode', v)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TicketI color="inherit" />
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 10, style: { textTransform: 'uppercase' } }}
          fullWidth
          addon={
            <div className="helper-message">
              {intl.formatMessage({
                id: 'workspace.create.step.2.form.promotion.code.addon',
              })}
            </div>
          }
        />
      </div>
      <div className="conditions-container">
        <p className="condition last">
          {intl.formatMessage({ id: 'workspace.create.step.2.conditions.1' })}
        </p>
        {/* <p className="condition last">{intl.formatMessage({ id: "workspace.create.step.2.conditions.2" })}</p> */}
      </div>
      <Button
        className="advisor-button"
        variant="outlined"
        color="secondary"
        onClick={props.openDialog}
        fullWidth>
        {intl.formatMessage({ id: 'workspace.create.step.2.advisor' })}
      </Button>
      <br />

      <SydDialog
        open={open}
        title="Contrato de Timbrado de Facturas"
        primaryButtonText={intl.formatMessage({ id: 'button.close' })}
        onConfirm={() => setOpen(false)}>
        <span className="contract-sefactura">
          {props.type?.idCatalogValues === 2
            ? intl.formatMessage(
                { id: 'contract.sefactura.moral' },
                {
                  name: props.name.toUpperCase(),
                  rfc: props.rfc.toUpperCase(),
                  date: intl.formatDate(new Date(), { dateStyle: 'long' }),
                  b: (...chunks) => <strong>{chunks}</strong>,
                }
              )
            : intl.formatMessage(
                { id: 'contract.sefactura.fisica' },
                {
                  name: props.name.toUpperCase(),
                  rfc: props.rfc.toUpperCase(),
                  date: intl.formatDate(new Date(), { dateStyle: 'long' }),
                  b: (...chunks) => <strong>{chunks}</strong>,
                }
              )}
        </span>
        <span className="contract-sefactura-anexo">
          <br />
          <h3>Anexo A</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>PAC X</TableCell>
                <TableCell>No. de Oficio</TableCell>
                <TableCell>Sitio Web</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Grupo Yacord Servicio Contables SA DE CV (Iofacturo)
                </TableCell>
                <TableCell>58080</TableCell>
                <TableCell>
                  <a
                    href="https://iofacturo.mx/"
                    target="_blank"
                    rel="noreferrer">
                    iofacturo.mx
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Lunasoft SA DE CV (Smartweb)</TableCell>
                <TableCell>16543</TableCell>
                <TableCell>
                  <a href="https://sw.com.mx/" target="_blank" rel="noreferrer">
                    sw.com.mx
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Solución Integral de Facturación Electrónica SIFEI SA DE CV
                </TableCell>
                <TableCell>58355</TableCell>
                <TableCell>
                  <a
                    href="http://www.sifei.com.mx/"
                    target="_blank"
                    rel="noreferrer">
                    www.sifei.com.mx
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Sefactura S.A. de C.V.</TableCell>
                <TableCell>55503</TableCell>
                <TableCell>
                  <a
                    href="http://www.sefactura.com.mx/"
                    target="_blank"
                    rel="noreferrer">
                    www.sefactura.com.mx
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </span>
      </SydDialog>
    </div>
  )
}

export default CreateWorkspaceStepTwo
