import { Box, Grid } from '@material-ui/core'
import ConciliationsDrawer from 'components/Conciliations/ConciliationsDrawer/ConciliationsDrawer'
import ConciliationInfo from 'components/Conciliations/ConciliationInfo/ConciliationInfo'
import DateRangeSelector from 'components/Conciliations/DateRangeSelector/DateRangeSelector'
import GenerateReportButton from 'components/Conciliations/GenerateReportButton/GenerateReportButton'
import AccountMovements from 'components/Conciliations/AccountMovements/AccountMovements'
import CFDIMovements from 'components/Conciliations/CFDIMovements/CFDIMovements'
import { useEffect } from 'react'
import { useConciliationsContainerContext } from '../ConciliationsContainer/ConciliationsContainer'
import useGetCFDIs from 'hooks/useGetCFDIs'
import useQueryStatements from 'hooks/useQueryStatements'
import useQueryLedgerAccounts from 'hooks/useQueryLedgerAccounts'
import useQueryConciliations from 'hooks/useQueryConciliations'
import _ from 'lodash'

const ConciliationsTablesContainer = () => {
  const {
    workspaceInfo,
    statements,
    cfdis,
    ledgers,
    conciliationPeriod,
    conciliations,
    updateCFDISuggestions,
    updateLoadingStates,
  } = useConciliationsContainerContext()

  const { selectedStatementRows, statementFilters } = statements

  const { cfdiFilters } = cfdis

  const { ledgerFilters } = ledgers

  const { month, year } = conciliationPeriod

  const { displayReconciled } = conciliations

  const { getCFDIs } = useGetCFDIs()
  const { queryStatements, getSuggestions } = useQueryStatements()
  const { queryLedgerAccounts } = useQueryLedgerAccounts()
  const { queryConciliations } = useQueryConciliations()

  useEffect(() => {
    if (!_.isEmpty(cfdiFilters) && workspaceInfo) {
      getCFDIs(workspaceInfo.activeWorkspace, cfdiFilters, displayReconciled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*workspaceInfo,*/ cfdiFilters, displayReconciled])

  useEffect(() => {
    if (workspaceInfo.activeWorkspace) {
      getCFDIs(workspaceInfo.activeWorkspace)
    }
    return () => {
      updateLoadingStates('cfdis', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceInfo.activeWorkspace /*, getCFDIs*/])

  useEffect(() => {
    if (selectedStatementRows.length === 1) {
      getSuggestions()
    } else {
      updateCFDISuggestions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceInfo.activeWorkspace,
    selectedStatementRows /*, getCFDISuggestions, setCfdiSuggestions*/,
  ])

  useEffect(() => {
    if (workspaceInfo.activeWorkspace) {
      queryStatements(
        workspaceInfo.activeWorkspace,
        statementFilters,
        displayReconciled
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceInfo.activeWorkspace,
    statementFilters.fromDate,
    statementFilters.toDate,
    statementFilters.text,
    statementFilters.type,
    // conciliationPeriod,
    month,
    year,
    displayReconciled,
    //statementFilters
    // queryStatements,
  ])

  useEffect(() => {
    if (workspaceInfo && workspaceInfo.activeWorkspace) {
      queryLedgerAccounts(
        workspaceInfo.activeWorkspace,
        ledgerFilters.display ? ledgerFilters.text : undefined
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceInfo.activeWorkspace,
    /*workspace,*/ ledgerFilters /*, queryLedgerAccounts*/,
  ])

  useEffect(() => {
    if (workspaceInfo.activeWorkspace) {
      queryConciliations(workspaceInfo.activeWorkspace, conciliationPeriod)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceInfo.activeWorkspace,
    /* conciliationPeriod, */ year,
    month /*queryConciliations*/,
  ])

  return (
    <div className="conciliations-tables-container">
      <Grid item xs={12} className="date-selector-container">
        <Box display="flex" width="100%" alignItems="center">
          <DateRangeSelector />
          <Box flex={1} />
          <GenerateReportButton />
        </Box>
        <ConciliationInfo />
      </Grid>
      <Grid className="conciliations-container" container spacing={4}>
        <AccountMovements />
        <CFDIMovements />
      </Grid>
      <ConciliationsDrawer />
    </div>
  )
}

export default ConciliationsTablesContainer
