import useGetAccountants from 'hooks/Management/Accountants/useGetAccountants'
import AccountantsTable from '../AccountantsTable/AccountantsTable'
import NPSSection from '../NPSSection/NPSSection'

const Accountants = () => {
  useGetAccountants()

  return (
    <>
      <NPSSection />
      <AccountantsTable />
    </>
  )
}

export default Accountants
