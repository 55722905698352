import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  withStyles,
} from '@material-ui/core'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './style.scss'

const CustomSwitch = withStyles(theme => ({
  root: {
    width: 22,
    height: 12,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(10px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 6,
    height: 6,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch)

const ConciliedTrigger = () => {
  const { conciliations } = useConciliationsContainerContext()

  const { displayReconciled, setDisplayReconciled } = conciliations

  const [value, setValue] = useState(displayReconciled)
  const dispatch = useDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
    dispatch(setDisplayReconciled(event.target.checked))
  }

  return (
    <FormControl className="conciliation-toggle">
      <CustomSwitch
        id="conciliated-movements"
        checked={value}
        onChange={handleChange}
      />
      <FormLabel className="label" htmlFor="conciliated-movements">
        Mostrar movimientos conciliados
      </FormLabel>
    </FormControl>
  )
}

const LoadingOverlay = () => (
  <Box className="conciliation-info-component animate-pulse">
    <Box flex={1} className="counter-parent" style={{ opacity: 0 }}>
      <Box className="counter">...</Box>
      <Box className="detail-view-trigger">...</Box>
    </Box>
  </Box>
)

const ConciliationInfo = () => {
  const { isLoadingConciliations, conciliations, setDrawerConciliation } =
    useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  const handleClick = () => {
    setDrawerConciliation(conciliationRows)
  }

  const count = conciliationRows.length

  if (count === 0) {
    return <Fragment />
  }

  if (isLoadingConciliations) return <LoadingOverlay />
  return (
    <Box className="conciliation-info-component">
      <Box flex={1} className="counter-parent">
        <Box className="counter">{count}</Box>
        <Box className="detail-view-trigger" onClick={handleClick}>
          Movimiento{count > 1 ? 's' : ''} conciliado{count > 1 ? 's' : ''}
        </Box>
      </Box>
      <ConciliedTrigger />
    </Box>
  )
}

export default ConciliationInfo
