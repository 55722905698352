import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import SendI from '@material-ui/icons/Send';
import './style.scss';

interface ChatInputTextProps {
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  rows?:number;
  onSendMessage:(value: string) => void;
}

const ChatInputText: React.FC<ChatInputTextProps> = (props) => {
  const { name, rows, onChange, onSendMessage: sendMessage, placeholder } = props;
  const [message, setMessage] = useState("");

  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
    setMessage(e.target.value);
  };

  const onSend = useCallback(() => {
    if (!message) return;
    sendMessage(message);
    setMessage("");
  }, [message, sendMessage]);

  return (
    <>
      <div className={'component-chat-input-text'}>
        <TextField
          autoComplete="off"
          className={`input-text`}
          name={name}
          onChange={handleChange}
          value={message}
          placeholder={placeholder || ""}
          InputProps={{ disableUnderline: true }}
          multiline
          rows={rows || 5}
          fullWidth
        />  
        <div className="actions">
          <div className='emojisSection'></div>
          <IconButton disabled={!message} color="inherit" size="small" onClick={onSend}>
            <SendI/>
          </IconButton>
        </div>
      </div>
    </>
  )
}

export default ChatInputText;
