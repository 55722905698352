import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from '@tanstack/react-query'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { List } from 'models/general'
import { DEFAULT_TABLE_ITEMS_PER_PAGE } from '../../constants'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import useDialog from 'hooks/Shared/useDialog'
import { IApiError } from 'services/Shared/shared.models'

export interface IUseDataGridServerPagination<T> {
  ref: MutableRefObject<HTMLDivElement | null>
  isLoading: boolean
  rows: T[]
  rowCount: number
  paginationMode: 'server'
  itemsPerPage?: number
  pagination?: true
  page?: number
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<List<T[]>, IApiError>>
  onPageChange: (newPage: number) => void
}

const useDataGridServerPagination = <T,>(
  activeWorkspace: number,
  fetchFunction: (
    activeWorkspace: number,
    page: number,
    pageSize: number
  ) => Promise<List<T[]>>,
  errorMessage: string,
  isInfiniteScroll?: boolean,
  pageSize?: number
): IUseDataGridServerPagination<T> => {
  const dialog = useDialog()

  const [page, setPage] = useState(0)
  const [tableData, setTableData] = useState<T[]>([])

  const dynamicPageSize = pageSize ?? DEFAULT_TABLE_ITEMS_PER_PAGE

  const tableRef = useRef<HTMLDivElement | null>(null)

  const { data, isLoading, isFetching, refetch } = useQuery<
    List<T[]>,
    IApiError
  >(
    ['dataGridServerPagination', activeWorkspace, page],
    () => fetchFunction(activeWorkspace, page, dynamicPageSize),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        isInfiniteScroll
          ? setTableData(prevData => [...prevData, ...data.list])
          : setTableData(data.list)
      },
      onError: async (error: IApiError) => {
        const predefinedErrorModalConfig = selectErrorDialog(error)

        if (predefinedErrorModalConfig && predefinedErrorModalConfig.variant) {
          await dialog[predefinedErrorModalConfig.variant](
            predefinedErrorModalConfig
          )
          return
        }

        if (error.status !== 404) {
          const dangerDialog = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: errorMessage,
            icon: ErrorOutlineIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (dangerDialog) {
            refetch()
            return
          }
        }
      },
    }
  )

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])

  useEffect(() => {
    const element =
      tableRef.current?.getElementsByClassName('MuiDataGrid-window')[0]

    const onScroll = () => {
      if (isInfiniteScroll && element) {
        const hasVerticalScroll = element.scrollHeight > element.clientHeight
        const isScrolledToBottom =
          hasVerticalScroll &&
          element.scrollHeight - element.scrollTop === element.clientHeight

        if (isScrolledToBottom) {
          data?.totalPages &&
            page + 1 < data.totalPages &&
            handlePageChange(page + 1)
        }
      }
    }

    isInfiniteScroll && element?.addEventListener('scroll', onScroll)

    return () => {
      element?.removeEventListener('scroll', onScroll)
    }
    /* I'm disabling the rule ONLY to add the element ref to the dependencies without causing a warning,
    since without it the event listener stops working upon unmounting/mounting the component */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.totalPages,
    handlePageChange,
    isInfiniteScroll,
    page,
    tableRef.current,
  ])

  return {
    ref: tableRef,
    isLoading: isLoading || isFetching,
    rows: tableData,
    rowCount: data?.total ?? 0,
    page: !isInfiniteScroll ? page : undefined,
    itemsPerPage: isInfiniteScroll ? tableData.length : dynamicPageSize,
    pagination: !isInfiniteScroll ? true : undefined,
    paginationMode: 'server',
    onPageChange: handlePageChange,
    refetch,
  }
}

export default useDataGridServerPagination
