import { API_RESPONSE_ERROR_CODE_KEYS } from 'lib/enums/shared.enums'
import { safeJsonParse } from 'lib/helpers/utilities'
import { predefinedErrorModalOptionsMap } from 'lib/utils/errors.utils'
import { TApiResponseErrorCodeKeys } from 'models/shared.models'
import { IDialogProviderState } from 'providers/DialogProvider/DialogProvider'
import { IApiError } from 'services/Shared/shared.models'

export const evaluateApiErrorToErrorModalOptionsKey = (
  status: number,
  err: any,
  customError: any
): TApiResponseErrorCodeKeys | undefined => {
  const errorCodeKey =
    API_RESPONSE_ERROR_CODE_KEYS[
      status as keyof typeof API_RESPONSE_ERROR_CODE_KEYS
    ]

  const customErrorCodeKey =
    API_RESPONSE_ERROR_CODE_KEYS[
      customError?.code as keyof typeof API_RESPONSE_ERROR_CODE_KEYS
    ]

  if (!err && Number(customError?.code) >= 9000) {
    return API_RESPONSE_ERROR_CODE_KEYS[9000]
  }

  return errorCodeKey ?? customErrorCodeKey
}

export const selectErrorDialog = (
  error: IApiError
): Partial<IDialogProviderState> | undefined => {
  const { status, message } = error

  const [err, customError] = safeJsonParse(message)

  const errorCodeKey = evaluateApiErrorToErrorModalOptionsKey(
    status,
    err,
    customError
  )

  if (!errorCodeKey) return

  return predefinedErrorModalOptionsMap[errorCodeKey](customError)
}
