import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { useCountdown, useDidMount, useDidUpdate } from 'rooks'

import {
  selectCreatePaymentComplement,
  selectInvoicesSync,
} from 'lib/helpers/selectors'
import {
  setInvoicesList,
  setPaginationValue,
  setSelectedInvoice,
  setSyncValue,
} from 'actions/invoices'
import {
  getCfdiList,
  getCreditNoteList,
  getPaymentComplementList,
  getCfdiCancelNotice,
  getPaymentComplementFiles,
  getCreditNoteFiles,
  sendCfdiByEmail,
  sendPaymentComplementByEmail,
  sendCreditNoteByEmail,
  changeCfdiPaymentStatus,
  cancelCfdi,
  getCfdiById,
  createPaymentComplement,
  syncWithSat,
  getCfdiAmountPaid,
  getCfdiListFilesInZip,
} from 'services/invoices'

import { downloadFileFromData } from 'lib/helpers/utilities'

import ErrorI from '@material-ui/icons/ErrorOutline'

import Invoice, {
  PaymentComplement,
  CreditNote,
  PaymentComplementFiles,
  CreditNoteFiles,
  SyncForm,
} from 'models/invoice'
import RazonSocial from 'models/razonSocial'
//import { CatalogueEntry } from 'models/catalogues';
import { QueryOptions, MutationOptions, List } from 'models/general'
import { Money } from 'ts-money'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

// #region Get List Hooks
const useCfdiList = (
  workspaceId: number,
  page: number,
  size: number,
  rfc?: string,
  search?: string,
  advance?: boolean,
  type?: string,
  status?: number,
  startDate?: string | null,
  endDate?: string | null,
  receptor?: string,
  options: QueryOptions<List<Invoice[]>> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<List<Invoice[]>, any>(
    ['get-cfdi-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCfdiList(
        workspaceId,
        page,
        size,
        rfc,
        search,
        advance,
        type,
        status,
        startDate || undefined,
        endDate || undefined,
        receptor
      )
    },
    {
      ...rest,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: data => {
        !noSave && dispatch(setInvoicesList(data.list))
        !noSave && dispatch(setPaginationValue('total', data.total))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de CFDIs.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

// #region Get List Hooks
export const useAllCfdiList = (
  workspaceId: number,
  page: number,
  size: number,
  rfc?: string,
  search?: string,
  advance?: boolean,
  type?: string,
  status?: number,
  startDate?: string | null,
  endDate?: string | null,
  receptor?: string,
  options: QueryOptions<List<Invoice[]>> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<List<Invoice[]>, any>(
    ['get-cfdi-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCfdiList(
        workspaceId,
        page,
        size,
        rfc,
        search,
        advance,
        type,
        status,
        startDate || undefined,
        endDate || undefined,
        receptor
      )
    },
    {
      onSuccess: data => {
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de CFDIs.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.mutate()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const usePaymentComplementList = (
  workspaceId: number,
  invoice?: Invoice,
  options: QueryOptions<PaymentComplement[]> = {}
) => {
  // const dispatch = useDispatch();
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<PaymentComplement[], any>(
    ['get-payment-complement-list', workspaceId, invoice, ...refreshOn],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      onBefore && onBefore()
      return getPaymentComplementList(workspaceId, invoice.idCfdi)
    },
    {
      ...rest,
      onSuccess: data => {
        //!noSave && dispatch(setInvoicesList(data));
        onSuccess && onSuccess(data)
      },
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la lista de los complementos de pago para el CFDI seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useCreditNoteList = (
  workspaceId: number,
  invoice?: Invoice,
  options: QueryOptions<CreditNote[]> = {}
) => {
  // const dispatch = useDispatch();
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<CreditNote[], any>(
    ['get-credit-note-list', workspaceId, invoice, ...refreshOn],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      onBefore && onBefore()
      return getCreditNoteList(workspaceId, invoice.idCfdi)
    },
    {
      ...rest,
      onSuccess: data => {
        //!noSave && dispatch(setInvoicesList(data));
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la lista de las notas de crédito para el CFDI seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
// #endregion

// #region Get By Param Hooks
export const useCfdi = (
  workspaceId: number,
  id: number,
  options: QueryOptions<Invoice> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Invoice, any>(
    ['get-cfdi', workspaceId, id, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCfdiById(workspaceId, id)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setSelectedInvoice(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del CFDI.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useCfdiPaidAmount = (
  workspaceId: number,
  invoice?: Invoice,
  options: QueryOptions<Money> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Money, any>(
    ['get-cfdi-paid-amount', workspaceId, invoice, ...refreshOn],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      onBefore && onBefore()
      return getCfdiAmountPaid(workspaceId, invoice)
    },
    {
      ...rest,
      onSuccess: data => {
        noSave && console.log(data)
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la información de los pagos del CFDI.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
// #endregion

// #region Download Files Hooks

export const useDownloadInvoicesFilesInZip = (
  workspaceId: number,
  page: number,
  size: number,
  rfc?: string,
  search?: string,
  advance?: boolean,
  type?: string,
  status?: number,
  startDate?: string | null,
  endDate?: string | null,
  receptor?: string,
  options: QueryOptions<string> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<string, any>(
    ['get-cfdi-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCfdiListFilesInZip(
        workspaceId,
        page,
        size,
        rfc,
        search,
        advance,
        type,
        status,
        startDate || undefined,
        endDate || undefined,
        receptor
      )
    },
    {
      ...rest,
      onSuccess: file => {
        onSuccess && onSuccess(file)
        downloadFileFromData(
          'Facturas.zip',
          `data:application/zip;base64,${file}`
        )
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudieron descargar los CFDIs seleccionados.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useDownloadCancelNoticeFile = (
  workspaceId: number,
  invoice?: Invoice,
  options: QueryOptions<string> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const query = useQuery<string>(
    ['download-cancel-notice-file-in-base64', workspaceId, invoice],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      showAdditionalInformationLoading()
      return getCfdiCancelNotice(workspaceId, invoice)
    },
    {
      enabled: false,
      onSuccess: file => {
        dialog.close()
        onSuccess && onSuccess(file)
        downloadFileFromData(`Acuse de Cancelación.pdf`, file)
      },
      onError: async (error: any) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo descargar el Acuse de Cancelación.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              query.refetch()
              return
            }
          }

          onError && onError(error)
        }, 600)
      },
    }
  )

  return query
}

export const useDownloadPaymentComplementFiles = (
  workspaceId: number,
  invoice?: Invoice,
  paymentComplement?: any,
  options: QueryOptions<PaymentComplementFiles> = {}
) => {
  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()
  const { onSuccess, onError, noDialog } = options

  const query = useQuery<PaymentComplementFiles>(
    [
      'get-payment-complement-files-in-base64',
      workspaceId,
      invoice,
      paymentComplement,
    ],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      if (!paymentComplement)
        throw new Error('Complemento de pago cargado incorrectamente')
      showAdditionalInformationLoading()
      return getPaymentComplementFiles(
        workspaceId,
        invoice.idCfdi,
        paymentComplement.idPayment
      )
    },
    {
      enabled: false,
      onSuccess: files => {
        dialog.close()
        onSuccess && onSuccess(files)
        downloadFileFromData(
          `${paymentComplement?.uuid || 'PDF'}.pdf`,
          files.pdf
        )
        downloadFileFromData(
          `${paymentComplement?.uuid || 'XML'}.xml`,
          files.xml
        )
      },
      onError: async (error: any) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo descargar el complemento de pago seleccionado.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              query.refetch()
              return
            }
          }

          onError && onError(error)
        }, 600)
      },
    }
  )

  return query
}
export const useDownloadCreditNoteFiles = (
  workspaceId: number,
  invoice?: Invoice,
  creditNote?: CreditNote,
  options: QueryOptions<CreditNoteFiles> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalLoading = useLoadingAdditionalInformation()

  const query = useQuery<CreditNoteFiles>(
    [
      'get-payment-complement-files-in-base64',
      workspaceId,
      invoice,
      creditNote,
    ],
    () => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      if (!creditNote)
        throw new Error('Nota de crédito cargada incorrectamente')
      showAdditionalLoading()
      return getCreditNoteFiles(
        workspaceId,
        invoice.idCfdi,
        creditNote.idCreditNote
      )
    },
    {
      enabled: false,
      onSuccess: files => {
        dialog.close()
        onSuccess && onSuccess(files)
        downloadFileFromData(`${creditNote?.uuid || 'PDF'}.pdf`, files.pdf)
        downloadFileFromData(`${creditNote?.uuid || 'XML'}.xml`, files.xml)
      },
      onError: async (error: any) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)

          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo descargar la nota de credito seleccionada.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              query.refetch()
              return
            }
          }

          onError && onError(error)
        }, 600)
      },
    }
  )

  return query
}
// #endregion

// #region Send Email Hooks
export const useSendInvoiceByEmail = (
  workspaceId: number,
  options: MutationOptions<void, Invoice> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<void, any, Invoice>(
    invoice => {
      return sendCfdiByEmail(workspaceId, invoice.idCfdi)
    },
    {
      onMutate: () => {
        showAdditionalInformationLoading()
      },
      onSuccess: (data, invoice, ctx) => {
        dialog.close()
        onSuccess && onSuccess(data, invoice, ctx)
      },
      onError: async (error, invoice, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)

          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo enviar el CFDI seleccionado. Intenta nuevamente.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(invoice)
              return
            }
          }

          onError && onError(error, invoice, ctx)
        }, 600)
      },
    }
  )

  return mutation
}
export const useSendPayComplementByEmail = (
  workspaceId: number,
  options: MutationOptions<void, { payComplement: any; invoice: Invoice }> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<
    void,
    any,
    { payComplement: any; invoice: Invoice }
  >(
    ({ payComplement, invoice }) => {
      onBefore && onBefore({ payComplement, invoice })
      return sendPaymentComplementByEmail(
        workspaceId,
        invoice.idCfdi,
        payComplement.idPayment
      )
    },
    {
      ...rest,
      onError: async (error, variables, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo eliminar la categoría seleccionada. Intenta nuevamente.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(variables)
            return
          }
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
export const useSendCreditNoteByEmail = (
  workspaceId: number,
  options: MutationOptions<void, { creditNote: any; invoice: Invoice }> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<
    void,
    any,
    { creditNote: any; invoice: Invoice }
  >(
    ({ creditNote, invoice }) => {
      return sendCreditNoteByEmail(
        workspaceId,
        invoice.idCfdi,
        creditNote.idCreditNote
      )
    },
    {
      onMutate: () => {
        showAdditionalInformationLoading()
      },
      onSuccess: (data, variables, ctx) => {
        dialog.close()
        onSuccess && onSuccess(data, variables, ctx)
      },
      onError: async (error, variables, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }
          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo eliminar la categoría seleccionada. Intenta nuevamente.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(variables)
              return
            }
          }

          onError && onError(error, variables, ctx)
        }, 600)
      },
    }
  )

  return mutation
}
// #endregion

// #region CFDI Mutations Hooks
export const useChangeCfdiPayStatus = (
  workspaceId: number,
  options: MutationOptions<void, { invoice: Invoice; status: string }> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, { invoice: Invoice; status: string }>(
    ({ invoice, status }) => {
      onBefore && onBefore({ invoice, status })
      return changeCfdiPaymentStatus(workspaceId, invoice, status)
    },
    {
      ...rest,
      onError: async (error, variables, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo cambiar el estatus del CFDI seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(variables)
            return
          }
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
export const useCancelCfdi = (
  workspaceId: number,
  options: MutationOptions<
    void,
    { invoice: Invoice; reason: number; substitute?: string }
  > = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<
    void,
    any,
    { invoice: Invoice; reason: number; substitute?: string }
  >(
    ({ invoice, reason, substitute }) => {
      onBefore && onBefore({ invoice, reason, substitute })
      return cancelCfdi(workspaceId, invoice, reason, substitute)
    },
    {
      ...rest,
      onError: async (error, variables, ctx) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo cancelar el CFDI seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(variables)
            return
          }
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
export const useSyncWithSat = (
  workspaceId: number,
  emisor?: RazonSocial,
  options: MutationOptions<any, SyncForm> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()
  const { onBefore, onError, onSuccess, noDialog, ...rest } = options

  const mutation = useMutation<any, any, SyncForm>(
    form => {
      if (!emisor) throw new Error('Emisor cargado incorrectamente')
      onBefore && onBefore(form)
      return syncWithSat(workspaceId, emisor, form.year, form.month, form.type)
    },
    {
      ...rest,
      onSuccess: (data, form, ctx) => {
        const now = new Date()
        const next = new Date(now.getTime() + 3 * 60000)

        if (data.status === -1) {
          throw new Error('sync-problem')
        } else if (data.status === 0) {
          dispatch(setSyncValue('createdAt', now))
          dispatch(setSyncValue('nextAt', next))
          dispatch(setSyncValue('active', true))
        } else if (data.status === 1) {
          dispatch(setSyncValue('active', false))
        } else if (data.status > 1) {
          dispatch(setSyncValue('active', false))
          throw new Error('sync-problem')
        }

        onSuccess && onSuccess(data, form, ctx)
      },
      onError: async (error, variables, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la lista de CFDIs con el servicio del SAT. Inténtalo mas tarde.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Entendido',
          })
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region Payment Complement Mutation Hooks
export const useAddPaymentComplement = (
  workspaceId: number,
  invoice?: Invoice,
  options: MutationOptions<void, string | undefined> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const form = useSelector(selectCreatePaymentComplement)

  const mutation = useMutation<void, any, string | undefined>(
    version => {
      if (!invoice) throw new Error('CFDI cargado incorrectamente')
      onBefore && onBefore(version)
      return createPaymentComplement(workspaceId, invoice, form, version)
    },
    {
      ...rest,
      onError: async (error, variables, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar la información de la categoría seleccionada.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(variables)
            return
          }
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
// #endregion

export const useCountToSync = (
  workspaceId: number,
  onEnd?: (form: SyncForm) => void
) => {
  const dispatch = useDispatch()
  const syncData = useSelector(selectInvoicesSync)

  useDidMount(() => {
    const now = new Date()
    const nextAt = new Date(syncData?.nextAt || '')
    if (now.getTime() > nextAt.getTime()) {
      dispatch(setSyncValue('active', false))
    }
  })

  useDidUpdate(() => {
    dispatch(setSyncValue('active', false))
  }, [workspaceId])

  const count = useCountdown(new Date(syncData?.nextAt || ''), {
    interval: 1000,
    onEnd: () => {
      dispatch(setSyncValue('active', false))
      onEnd && onEnd(syncData.form)
    },
  })

  return {
    count,
    displayCount: new Date(count * 1000).toISOString().substring(14, 19),
    ...syncData,
  }
}

export default useCfdiList
