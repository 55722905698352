import { useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import Conekta from 'assets/conekta.svg'
import { PAYMENT_METHODS } from 'lib/enums/payments.enums'
import { paymentMethodTabs } from 'lib/utils/payments.utils'
import withTextInputController from 'hocs/withTextInputController'
import { selectIsRecurringPayment } from 'store/Payments/payments.selectors'
import Button from 'components/Shared/Button/Button'
import PaymentMethodTab from '../PaymentMethodTab/PaymentMethodTab'
import useConektaForm from './useConektaForm'
import './styles.scss'

const ControlledTextInput = withTextInputController(TextField)

const ConektaForm = () => {
  const isRecurringPayment = useSelector(selectIsRecurringPayment)

  const {
    selectedPaymentMethod,
    isPaymentLoading,
    methods,
    handleSelectPaymentMethod,
    handleUpdateCardNumber,
    handleUpdateCardExpiration,
    handleUpdateCardCvc,
    onSubmit,
  } = useConektaForm()

  const { control, formState, handleSubmit } = methods

  return (
    <div className="conekta-form-component">
      <div className="header">
        <span>PAGOS SEGUROS CON</span>
        <img src={Conekta} alt="logo de conekta" />
      </div>
      <hr />
      <span>SELECCIONA UN MÉTODO DE PAGO</span>
      <article className="payment-methods">
        {paymentMethodTabs.map(tab => (
          <PaymentMethodTab
            key={tab.id}
            tab={tab}
            handleSelectPaymentMethod={handleSelectPaymentMethod}
          />
        ))}
      </article>
      {selectedPaymentMethod === PAYMENT_METHODS.TARJETA && (
        <form className="conekta-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="text-input">
              <label htmlFor="nameCard"> Nombre en la tarjeta </label>
              <ControlledTextInput
                control={control}
                name="name"
                placeholder="Nombre como aparece en la tarjeta"
                variant="outlined"
              />
            </div>
            <div className="text-input">
              <label htmlFor="numberCard"> Número de tarjeta </label>
              <ControlledTextInput
                control={control}
                name="number"
                placeholder="0000 0000 0000 0000"
                onKeyDown={handleUpdateCardNumber}
                variant="outlined"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="text-input">
              <label htmlFor="expiration"> Fecha de expiración </label>
              <ControlledTextInput
                control={control}
                name="expiration"
                placeholder="MM/YY"
                onKeyDown={handleUpdateCardExpiration}
                variant="outlined"
              />
            </div>

            <div className="text-input">
              <label htmlFor="securityCode"> Código de seguridad </label>
              <ControlledTextInput
                control={control}
                name="cvc"
                placeholder="CVV"
                onKeyDown={handleUpdateCardCvc}
                variant="outlined"
              />
            </div>
          </div>

          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={!formState.isValid || isPaymentLoading}
            activity={isPaymentLoading}>
            {isRecurringPayment ? 'Continuar' : `Pagar`}
          </Button>
        </form>
      )}
      {selectedPaymentMethod === PAYMENT_METHODS.EFECTIVO && <>EFECTIVO</>}
      {selectedPaymentMethod === PAYMENT_METHODS.TRANSFERENCIA && (
        <>TRANSFERENCIA</>
      )}
    </div>
  )
}

export default ConektaForm
