import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AccountStatementsSection,
  CFDISection,
  ConciliationPeriodSection,
  ConciliationSection,
  DrawerConciliationPayload,
  DrawerControlInterface,
  DrawerModeType,
  IConciliationGlobalMethods,
  IConciliationHistorySections,
  IConciliationOCRSection,
  IConciliationScreenSection,
  LedgerSection,
  TConciliationTabs,
  TConciliationTabsWildcardsKey,
  TConciliationWorkspaceInfo,
} from 'models/conciliations'
import {
  CFDIRow,
  OCRBankStatement,
  OCRBankStatementMovement,
} from 'models/redux'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import {
  selectAccountStatementsHistory,
  selectActiveWorkspace,
  selectCFDISHistory,
  selectConciliationActiveStatementRow,
  selectConciliationCFDIFilter,
  selectConciliationCFDISActiveSection,
  selectConciliationCFDISRows,
  selectConciliationDisplayReconciled,
  selectConciliationRows,
  selectConciliationStatementFilter,
  selectConciliationStatementRows,
  selectConciliationStatementRowsSearch,
  selectConciliationsTab,
  selectLedgerFilter,
  selectLedgerRows,
  selectOCRMovements,
  selectOCRStatements,
  selectSelectedConciliationCFDISRows,
  selectSelectedConciliationStatementRows,
  selectSelectedLedgerRows,
  selectSelectedWorkspace,
  selectWorkspacesList,
  selectconciliationPeriod,
} from 'lib/helpers/selectors'
import {
  CONCILIATION_TABS_WILDCARDS,
  loadingStatesTargetMap,
} from 'lib/utils/Conciliations/conciliations.utils'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import {
  setConciliationStatementRows,
  setSelectedConciliationStatementRows,
  setConciliationActiveStatementRow,
  setConciliationStatementFilters,
  setConciliationCfdiRows,
  setConciliationSelectedCfdiRows,
  setConciliationCfdiActiveSection,
  setConciliationCfdiFilters,
  setLedgerRows,
  setLedgerSelectedRows,
  setLedgerFilters,
  setConciliationPeriodMonth,
  setConciliationPeriodYear,
  setConciliationRows,
  setDisplayReconciled,
  setOCRStatements,
  setOCRMovements,
  setAccountStatementsHistory,
  setCFDISHistory,
} from 'actions/conciliations'
import { setActiveTab } from 'actions/documentRequest'
import {
  deleteArchive,
  processOCR,
  replaceArchive,
  uploadArchiveToPeriod,
} from 'services/conciliations'
import { resetAppState } from 'store/shared.actions'

interface IConciliationsContainerState {
  isLoadingStatements: boolean
  isLoadingCFDIs: boolean
  isLoadingLedgerAccounts: boolean
  isLoadingConciliations: boolean
  totalElements: number
  lastSync: string
  isSyncCFDIOpen: boolean
  cfdiSuggestions: CFDIRow[]
  showStatementFilters: boolean
  drawer: DrawerControlInterface
  accountMovementsIsLoading: boolean
  accountMovementsModalIsOpen: boolean
}

export interface IUseConciliationsContainer
  extends IConciliationsContainerState {
  isScreenLoading: boolean
  workspaceInfo: TConciliationWorkspaceInfo
  screen: IConciliationScreenSection
  OCR: IConciliationOCRSection
  conciliationHistorySections: IConciliationHistorySections
  statements: AccountStatementsSection
  cfdis: CFDISection
  ledgers: LedgerSection
  conciliationPeriod: ConciliationPeriodSection
  conciliations: ConciliationSection
  globalMethods: IConciliationGlobalMethods
  updateScreenLoading: (isLoading: boolean) => void
  updateLoadingStates: (
    target:
      | 'statements'
      | 'cfdis'
      | 'ledgers'
      | 'conciliations'
      | 'accountMovements',
    value: boolean
  ) => void
  updateTotalElements: (value: number) => void
  updateLastSync: (value: string) => void
  updateIsSyncCFDIOpen: (value: boolean) => void
  updateCFDISuggestions: (suggestions: CFDIRow[]) => void
  openDrawer: (mode: DrawerModeType) => void
  closeDrawer: () => void
  setDrawerMovement: (movement: DrawerControlInterface['movement']) => void
  setDrawerCFDI: (cfdi: DrawerControlInterface['cfdi']) => void
  setDrawerConciliation: (conciliation: DrawerConciliationPayload) => void
  setDrawerFilelist: (filelist: DrawerControlInterface['filelist']) => void
  removeDrawerFilelistItem: (idWsFile: number) => void
  toggleAccountMovementsModal: () => void
}

const useConciliationsContainer = (
  isScreenLoading: boolean,
  updateScreenLoading: (isLoading: boolean) => void
): IUseConciliationsContainer => {
  const dispatch = useDispatch()

  const workspacesList = useSelector(selectWorkspacesList)
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const selectedWorkspace = useSelector(selectSelectedWorkspace)

  const accountStatementsHistory = useSelector(selectAccountStatementsHistory)
  const CFDIsHistory = useSelector(selectCFDISHistory)

  const activeTab = useSelector(selectConciliationsTab)

  const OCRStatements = useSelector(selectOCRStatements)
  const OCRMovements = useSelector(selectOCRMovements)

  const statementRows = useSelector(selectConciliationStatementRows)
  const activeStatementRow = useSelector(selectConciliationActiveStatementRow)
  const selectedStatementRows = useSelector(
    selectSelectedConciliationStatementRows
  )
  const statementSearch = useSelector(selectConciliationStatementRowsSearch)
  const statementFilters = useSelector(selectConciliationStatementFilter)

  const cfdiRows = useSelector(selectConciliationCFDISRows)
  const selectedCfdiRows = useSelector(selectSelectedConciliationCFDISRows)
  const cfdiActiveSection = useSelector(selectConciliationCFDISActiveSection)
  const cfdiSearch = useSelector(selectConciliationStatementRowsSearch)
  const cfdiFilters = useSelector(selectConciliationCFDIFilter)

  const ledgerRows = useSelector(selectLedgerRows)
  const selectedLedgerRows = useSelector(selectSelectedLedgerRows)
  const ledgerFilters = useSelector(selectLedgerFilter)

  const conciliationsPeriod = useSelector(selectconciliationPeriod)

  const conciliationRows = useSelector(selectConciliationRows)
  const displayReconciled = useSelector(selectConciliationDisplayReconciled)

  const emptyDrawer = useMemo(
    (): DrawerControlInterface => ({
      open: false,
      mode: null,
      movement: null,
      cfdi: null,
      filelist: {
        ocrMode: 'statements',
        documents: null,
        conciliation: null,
        onConfirm: () => {},
      },
    }),
    []
  )

  const [conciliationsContainerState, setConciliationsContainerState] =
    useState<IConciliationsContainerState>({
      isLoadingStatements: false,
      isLoadingCFDIs: false,
      isLoadingLedgerAccounts: false,
      isLoadingConciliations: false,
      totalElements: 0,
      lastSync: '',
      isSyncCFDIOpen: false,
      cfdiSuggestions: [],
      showStatementFilters: false,
      drawer: emptyDrawer,
      accountMovementsIsLoading: false,
      accountMovementsModalIsOpen: false,
    })

  const { drawer } = conciliationsContainerState

  const updateConciliationsContainerState =
    useUpdateStateFunction<IConciliationsContainerState>(
      setConciliationsContainerState
    )

  const workspaceInfo = useMemo(
    (): TConciliationWorkspaceInfo => ({
      workspacesList,
      activeWorkspace,
      selectedWorkspace,
    }),
    [workspacesList, activeWorkspace, selectedWorkspace]
  )

  const conciliationHistorySections = useMemo(
    (): IConciliationHistorySections => ({
      accountStatementsHistory,
      CFDIsHistory,
      setAccountStatementsHistory,
      setCFDISHistory,
    }),
    [accountStatementsHistory, CFDIsHistory]
  )

  const statements = useMemo(
    (): AccountStatementsSection => ({
      statementRows,
      selectedStatementRows,
      statementsAccount: null,
      activeStatementRow,
      statementSearch,
      statementFilters,
      setConciliationStatementRows,
      setSelectedConciliationStatementRows,
      setConciliationActiveStatementRow,
      setConciliationStatementFilters,
    }),
    [
      statementRows,
      selectedStatementRows,
      activeStatementRow,
      statementSearch,
      statementFilters,
    ]
  )

  const cfdis = useMemo(
    (): CFDISection => ({
      cfdiRows,
      selectedCfdiRows,
      cfdiActiveSection,
      cfdiSearch,
      cfdiFilters,
      setConciliationCfdiRows,
      setConciliationSelectedCfdiRows,
      setConciliationCfdiActiveSection,
      setConciliationCfdiFilters,
    }),
    [cfdiRows, selectedCfdiRows, cfdiActiveSection, cfdiSearch, cfdiFilters]
  )

  const ledgers = useMemo(
    (): LedgerSection => ({
      ledgerRows,
      selectedLedgerRows,
      ledgerFilters,
      setLedgerRows,
      setLedgerSelectedRows,
      setLedgerFilters,
    }),
    [ledgerRows, selectedLedgerRows, ledgerFilters]
  )

  const conciliationPeriod = useMemo(
    (): ConciliationPeriodSection => ({
      ...conciliationsPeriod,
      setConciliationPeriodMonth,
      setConciliationPeriodYear,
    }),
    [conciliationsPeriod]
  )

  const conciliations = useMemo(
    (): ConciliationSection => ({
      conciliationRows,
      displayReconciled,
      setConciliationRows,
      setDisplayReconciled,
    }),
    [conciliationRows, displayReconciled]
  )

  const globalMethods = useMemo(
    (): IConciliationGlobalMethods => ({
      uploadArchiveToPeriod,
      deleteArchive,
      replaceArchive,
    }),
    []
  )

  const updateOCRStatement = useCallback(
    (statement: OCRBankStatement) => {
      const newStatements = OCRStatements.map(s => {
        if (s.idFileBankStatement === statement.idFileBankStatement) {
          return statement
        }
        return s
      })
      dispatch(setOCRStatements(newStatements))
    },
    [dispatch, OCRStatements]
  )

  const updateOCRMovement = useCallback(
    (movement: OCRBankStatementMovement) => {
      const newMovements = OCRMovements.map(m => {
        if (m.idTransaction === movement.idTransaction) {
          return movement
        }
        return m
      })
      dispatch(setOCRMovements(newMovements))
    },
    [dispatch, OCRMovements]
  )

  const handleSetTab = useCallback(
    (tab: TConciliationTabs) => {
      document.location.hash =
        CONCILIATION_TABS_WILDCARDS[tab as TConciliationTabsWildcardsKey]
      dispatch(setActiveTab(tab))
    },
    [dispatch]
  )

  const screen = useMemo(
    (): IConciliationScreenSection => ({
      activeTab,
      handleSetTab,
    }),
    [activeTab, handleSetTab]
  )
  const OCR = useMemo(
    (): IConciliationOCRSection => ({
      OCRStatements,
      setOCRStatements,
      OCRMovements,
      setOCRMovements,
      updateOCRMovement,
      updateOCRStatement,
      processOCR,
    }),
    [OCRStatements, OCRMovements, updateOCRMovement, updateOCRStatement]
  )

  const updateLoadingStates = useCallback(
    (
      target:
        | 'statements'
        | 'cfdis'
        | 'ledgers'
        | 'conciliations'
        | 'accountMovements',
      value: boolean
    ) => {
      const targetKey = loadingStatesTargetMap[target]
      updateConciliationsContainerState({
        [targetKey]: value,
      })
    },
    [updateConciliationsContainerState]
  )

  const updateTotalElements = useCallback(
    (value: number) =>
      updateConciliationsContainerState({ totalElements: value }),
    [updateConciliationsContainerState]
  )

  const updateLastSync = useCallback(
    (value: string) => updateConciliationsContainerState({ lastSync: value }),
    [updateConciliationsContainerState]
  )

  const updateIsSyncCFDIOpen = useCallback(
    (value: boolean) =>
      updateConciliationsContainerState({ isSyncCFDIOpen: value }),
    [updateConciliationsContainerState]
  )

  const updateCFDISuggestions = useCallback(
    (suggestions: CFDIRow[]) =>
      updateConciliationsContainerState({ cfdiSuggestions: suggestions }),
    [updateConciliationsContainerState]
  )

  const openDrawer = useCallback(
    (mode: DrawerModeType) =>
      updateConciliationsContainerState({
        drawer: { ...emptyDrawer, open: true },
      }),
    [emptyDrawer, updateConciliationsContainerState]
  )

  const closeDrawer = useCallback(
    () => updateConciliationsContainerState({ drawer: emptyDrawer }),
    [emptyDrawer, updateConciliationsContainerState]
  )

  const setDrawerMovement = useCallback(
    (movement: DrawerControlInterface['movement']) =>
      updateConciliationsContainerState(prevState => ({
        ...prevState,
        drawer: {
          ...prevState.drawer,
          open: true,
          mode: 'account',
          movement,
        },
      })),
    [updateConciliationsContainerState]
  )

  const setDrawerCFDI = useCallback(
    (cfdi: DrawerControlInterface['cfdi']) =>
      updateConciliationsContainerState(prevState => ({
        ...prevState,
        drawer: {
          ...prevState.drawer,
          open: true,
          mode: 'cfdi',
          cfdi,
        },
      })),
    [updateConciliationsContainerState]
  )

  const setDrawerConciliation = useCallback(
    (conciliation: DrawerConciliationPayload) =>
      updateConciliationsContainerState(prevState => ({
        ...prevState,
        drawer: {
          ...prevState.drawer,
          open: true,
          mode: 'conciliation',
          conciliation: conciliation,
        },
      })),
    [updateConciliationsContainerState]
  )

  const setDrawerFilelist = useCallback(
    (filelist: DrawerControlInterface['filelist']) =>
      updateConciliationsContainerState(prevState => ({
        ...prevState,
        drawer: {
          ...prevState.drawer,
          open: true,
          mode: 'filelist',
          filelist,
        },
      })),
    [updateConciliationsContainerState]
  )

  const removeDrawerFilelistItem = useCallback(
    (idWsFile: number) => {
      if (!drawer.filelist?.documents) return
      const documents = drawer.filelist.documents.filter(
        doc => doc.idWsFile !== idWsFile
      )
      if (documents.length === 0) {
        closeDrawer()
        return
      }
      setDrawerFilelist({
        ...drawer.filelist,
        documents,
      })
    },
    [drawer.filelist, closeDrawer, setDrawerFilelist]
  )

  const toggleAccountMovementsModal = useCallback(
    () =>
      updateConciliationsContainerState(prevState => ({
        ...prevState,
        accountMovementsModalIsOpen: !prevState.accountMovementsModalIsOpen,
      })),
    [updateConciliationsContainerState]
  )

  useEffect(
    () => {
      const routeHash = document.location.hash.replace('#', '')
      const tab = Object.keys(CONCILIATION_TABS_WILDCARDS).find(
        (key: string) =>
          CONCILIATION_TABS_WILDCARDS[
            key as unknown as TConciliationTabsWildcardsKey
          ] === routeHash
      )
      if (tab) {
        dispatch(setActiveTab(parseInt(tab, 10)))
      } else {
        dispatch(setActiveTab(CONCILIATION_TABS.CONCILIATIONS))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*dispatch*/
    ]
  )

  useEffect(
    () => () => {
      dispatch(resetAppState())
    },
    [dispatch]
  )

  return {
    ...conciliationsContainerState,
    isScreenLoading,
    workspaceInfo,
    screen,
    OCR,
    conciliationHistorySections,
    statements,
    cfdis,
    ledgers,
    conciliationPeriod,
    conciliations,
    globalMethods,
    updateScreenLoading,
    updateLoadingStates,
    updateTotalElements,
    updateLastSync,
    updateIsSyncCFDIOpen,
    updateCFDISuggestions,
    openDrawer,
    closeDrawer,
    setDrawerMovement,
    setDrawerCFDI,
    setDrawerFilelist,
    setDrawerConciliation,
    removeDrawerFilelistItem,
    toggleAccountMovementsModal,
  }
}

export default useConciliationsContainer
