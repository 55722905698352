import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'accounting',
    'catalogues',
    'employees',
    'documentation',
    'documentRequest',
    'invoices',
    'loader',
    'payments',
    'sse',
    'user',
    'workspace',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

export { store, persistor }
