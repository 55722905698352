import React from 'react';

import Grid from '@material-ui/core/Grid';
import SydDialog from 'components/Shared/SydDialog/SydDialog';

import Around from 'assets/around.png';
import EpicLab from 'assets/epic_lab.png';
import PassWork from 'assets/passwork.png';
import StartupMexico from 'assets/startupmexico.png';
import TribalCredit from 'assets/tribalcredit.png';
import './style.scss';

interface OtherServicesDialogProps {
    open: boolean
    onClose: () => void
}

const OtherServicesDialog: React.FC<OtherServicesDialogProps> = (props) => {
    return (
        <SydDialog 
            open={props.open}
            title="Convenios y colaboraciones"
            className="dialog-other-services"
            hidePrimaryButton
            secondaryButtonText="Cerrar"
            onCancel={props.onClose}
        >
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={7}>
                    <a href='https://www.simmple.mx/simmple-y-around' target="_blank" rel="noreferrer">
                        <img className="service-logo" src={Around} alt="Around" />
                    </a>
                </Grid>
                <Grid item xs={6}>
                    <a href='https://www.simmple.mx/landing-simmple-y-tribal' target="_blank" rel="noreferrer">
                        <img className="service-logo" src={TribalCredit} alt="Tribal Credit" />
                    </a>
                </Grid>
                <Grid item xs={6}>
                    <a href='https://www.simmple.mx/simmple-y-passwork' target="_blank" rel="noreferrer">
                        <img className="service-logo" src={PassWork} alt="PassWork" />
                    </a>
                </Grid>
                <Grid item xs={6}>
                    <a href='https://www.startupmexico.com/' target="_blank" rel="noreferrer">
                        <img className="service-logo" src={StartupMexico} alt="Startup Mexico" />
                    </a>
                </Grid>
                <Grid item xs={6}>
                    <a href='https://www.epiclab.itam.mx/' target="_blank" rel="noreferrer">
                        <img className="service-logo" src={EpicLab} alt="Epic Lab" />
                    </a>
                </Grid>
            </Grid>
        </SydDialog>
    )
}

export default OtherServicesDialog;