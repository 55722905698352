import HTTPClient from 'lib/helpers/HTTPClient';
//import _ from 'lodash';

import { httpErrorParser } from 'lib/helpers/utilities';

import Category, { CategoryForm } from 'models/category';

export const SET_CATEGORIES_LIST = '@Products/SET_CATEGORIES_LIST';
export const SET_SELECTED_CATEGORY = '@Products/SET_SELECTED_CATEGORY';

const api = process.env.REACT_APP_APIURL || "/api/";

export const setCategoriesList = (value: Category[]): object => ({
    type: SET_CATEGORIES_LIST,
    value
})

export const setSelectedCategory = (value: Category | null = null): object => ({
    type: SET_SELECTED_CATEGORY,
    value
})

export const getCategoryById = (workspaceId: number, categoryId: number, noSave: boolean = false) => async (dispatch: any): Promise<Category> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/categories/${categoryId}`);
        const category: Category = await petition.json();
        !noSave && dispatch(setSelectedCategory(category));
        return category;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCategoriesList = (workspaceId: number, noSave: boolean = false) => async (dispatch: any): Promise<Category[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/categories`);
        const list: Category[] = await petition.json();
        !noSave && dispatch(setCategoriesList(list));
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const createCategory = (workspaceId: number, form: CategoryForm) => async (): Promise<Category> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            name: form.name,
            description: form.description
        }
        const petition = await client.post(`${api}/workspaces/${workspaceId}/categories`, body);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const updateCategory = (workspaceId: number, categoryId: number, form: CategoryForm) => async (): Promise<Category> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            name: form.name,
            description: form.description
        }
        const petition = await client.put(`${api}/workspaces/${workspaceId}/categories/${categoryId}`, body);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const deleteCategory = (workspaceId: number, categoryId: number) => async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${api}/workspaces/${workspaceId}/categories/${categoryId}`);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}