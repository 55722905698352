import AutocompleteField from "components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField";
import Button from "components/Shared/Button/Button";
import { CatalogueEntry } from "models/catalogues";
import { useFormContext } from "react-hook-form";
import CloseIcon from '@material-ui/icons/Close';
import './styles.scss';
import { 
  useManagementWorkspacesContext 
} from "components/Management/ManagementWorkspaces/ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider";

//const TextInput = withTextInputController(TextField)
export interface WorkspacesAdvancedFiltersProps {
  handleResetForm: () => void
}

const WorkspacesAdvancedFilters = (props: WorkspacesAdvancedFiltersProps) => {
  const { handleResetForm } = props;
  const { register, setValue, watch } = useFormContext();
  const { 
    plans: plansCatalog,
    profilingStatus: profilingCatalog,
    certLife: certLifeCatalog
  } = useManagementWorkspacesContext();
  const plan = watch("plan");
  //const coefficent = watch("coefficient");
  const expirationE = watch("expiration-e")
  const expirationCsd = watch("expiration-csd")
  const profiling = watch("profiling")

  return(
    <div className='workspaces-advanced-filters'>
      <div className="filters">
        {/* <div className='filter-290'>
          <TextInput
            fullWidth
            control={control}
            name={"coefficient"}
            label="Complejidad"
            placeholder="Buscar por coeficiente de complejidad"
            InputLabelProps={{ shrink: true }}
          />
        </div> */}

        <div className='filter-147'>
          <AutocompleteField
            label={'Plan contratado'}
            catalogue={plansCatalog.map(
              mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
            )}
            getOptionLabel={(option: CatalogueEntry) => option.label}
            onSelectValue={(v: CatalogueEntry) => setValue("plan", v.value)}
            //onClear={(v: CatalogueEntry) => setValue("plan", v)}
            value={plansCatalog.find(m => m.value === plan)}
            fullWidth
            required
            disableClearable
            {...register("plan")}
          />
        </div>
        
        <div className='filter-147'>
          <AutocompleteField
            label={'Vigencia e-firma'}
            catalogue={certLifeCatalog.map(
              mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
            )}
            getOptionLabel={(option: CatalogueEntry) => option.label}
            onSelectValue={(v: CatalogueEntry) => setValue("expiration-e", v.value)}
            onClear={(v: CatalogueEntry) => setValue("expiration-e", v)}
            value={certLifeCatalog.find(m => m.value === expirationE)}
            fullWidth
            required
            disableClearable
            {...register("expiration-e")}
          />
        </div>

        <div className='filter-147'>
          <AutocompleteField
            label={'Vigencia CSD'}
            catalogue={certLifeCatalog.map(
              mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
            )}
            getOptionLabel={(option: CatalogueEntry) => option.label}
            onSelectValue={(v: CatalogueEntry) => setValue("expiration-csd", v.value)}
            onClear={(v: CatalogueEntry) => setValue("expiration-csd", v)}
            value={certLifeCatalog.find(m => m.value === expirationCsd)}
            fullWidth
            required
            disableClearable
            {...register("expiration-csd")}
          />
        </div>

        <div className='filter-147'>
          <AutocompleteField
            label={'Perfilamiento'}
            catalogue={profilingCatalog.map(
              mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
            )}
            getOptionLabel={(option: CatalogueEntry) => option.label}
            onSelectValue={(v: CatalogueEntry) => setValue("profiling", v.value)}
            onClear={(v: CatalogueEntry) => setValue("profiling", v)}
            value={profilingCatalog.find(m => m.value === profiling)}
            fullWidth
            required
            disableClearable
            {...register("profiling")}
          />
        </div>
      </div>

      <div className="actions">
        <Button 
          variant="contained" 
          color="secondary" 
          type="submit"
        >
          Buscar
        </Button>
      
        <Button
          variant="text"
          color="primary"
          type="button"
          startIcon={<CloseIcon />}
          onClick={handleResetForm}>
          Borrar filtros
        </Button>
      </div>
    </div>
  )
}

export default WorkspacesAdvancedFilters;