import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'

import NewPasswordFormContainer from 'containers/NewPasswordForm/NewPasswordFormContainer'

import { ROUTES } from '../../constants'

import './style.scss'

import { LoginScreenForms as Forms } from 'models/general'

interface Props {}

const RecoveryScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const token = params.get('token') || ''

  const onRouteChange = (action: string) => {
    if (action === 'back') navigate(-1)
    if (action === 'success') navigate(`${ROUTES.LOGIN}?form=${Forms.LOGIN}`)
  }

  if (!token) {
    return <Navigate to={ROUTES.ROOT} replace />
  }

  return (
    <Card className="screen-recovery">
      <Grid className="screen-container" container spacing={0}>
        <Grid item xs={12}>
          <NewPasswordFormContainer token={token} onRedirect={onRouteChange} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default RecoveryScreen
