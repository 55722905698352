import { TServerSentOperations } from 'models/shared.models'
import { SERVER_SENT_OPERATIONS } from 'lib/enums/shared.enums'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { useAppSelector } from 'store/hooks'
import { ISSEEventData } from 'services/Shared/shared.models'
import useDownloadWorkspaceDocumentMutation from '../useDownloadWorkspaceDocumentMutation'
import useFireStoreEventFromSSE from './useFireStoreEventFromSSE'
import useSynchronizationNotification from './useSynchronizationSSENotification'

const useHandleSSE = (): ((typedEventData: ISSEEventData) => void) => {
  const activeWorkspace = useAppSelector(selectActiveWorkspace)

  const downloadWorkspaceDocumentMutation =
    useDownloadWorkspaceDocumentMutation()

  const notifySynchronization = useSynchronizationNotification()
  const fireStoreEventFromSSE = useFireStoreEventFromSSE()

  const operationIdEventHandlerMap: Record<
    TServerSentOperations,
    (typedEventData: ISSEEventData) => void
  > = {
    [SERVER_SENT_OPERATIONS.CIF]: (typedEventData: ISSEEventData) => {
      const { idWsfile } = typedEventData.data
      downloadWorkspaceDocumentMutation.mutate({
        workspaceId: activeWorkspace,
        fileId: idWsfile,
        fileName: 'CIF.pdf',
        errorMessage: 'Hubo un error al descargar el CIF',
      })
    },
    [SERVER_SENT_OPERATIONS.COMPLIANCE]: notifySynchronization,
    [SERVER_SENT_OPERATIONS.CFDIS]: notifySynchronization,
  }

  const handleSSE = (typedEventData: ISSEEventData) => {
    const relevantHandler =
      operationIdEventHandlerMap[typedEventData.idOperation]
    relevantHandler && relevantHandler(typedEventData)
    fireStoreEventFromSSE(typedEventData)
  }

  return handleSSE
}

export default useHandleSSE
