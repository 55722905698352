import { useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextareaAutosize,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { TWorkspaceParams } from 'models/general'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import { sendMassiveAlertToWorkspaces } from 'services/Management/management.services'
import { useManagementWorkspacesContext } from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import './styles.scss'

const MAX_LENGTH = 500
const MIN_LENGTH = 1

interface IManagementWorkspacesMassiveAlertDialogProps {
  isOpen: boolean
  onClose: () => void
}

const ManagementWorkspacesMassiveAlertDialog = ({
  isOpen,
  onClose,
}: IManagementWorkspacesMassiveAlertDialogProps) => {
  const { selectedWorkspaceIds } = useManagementWorkspacesContext()

  const [value, setValue] = useState('')

  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const typedWorkspaces = selectedWorkspaceIds.map(idWorkspace => ({
    idWorkspace,
  })) as TWorkspaceParams

  const handleClose = () => {
    setValue('')
    onClose()
  }

  const handleOnChange = (e: any) => {
    setValue(e.target.value)
  }

  const mutation = useMutation(
    () => sendMassiveAlertToWorkspaces(typedWorkspaces, value),
    {
      onMutate: () => {
        toast.loading(`Generando alerta`, {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: () => {
        toast.dismiss('loading')
        toast.success('La alerta ha sido creada', successToastOptions)
        handleClose()
      },
      onError: () => {
        toast.dismiss('loading')
        toast.error('No fue posible crear la alerta', errorToastOptions)
      },
    }
  )

  const { isLoading } = mutation

  const handleGenerate = () => {
    mutation.mutate()
  }

  return (
    <>
      <Dialog onClose={handleClose} open={isOpen} className="dialog">
        <DialogContent className="content">
          <Box className="close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="labels">
            <Box className="title">Generar alerta</Box>
            <Box className="subtitle">
              Ingresa el mensaje que quieres enviar a través de la alerta para
              los usuarios que seleccionaste ({typedWorkspaces.length}).
            </Box>
          </Box>
          <TextareaAutosize
            className="textarea"
            placeholder="Escribe aquí tu mensaje"
            value={value}
            onChange={handleOnChange}
            maxLength={MAX_LENGTH}
            minLength={MIN_LENGTH}
          />
          <Box textAlign="right">
            {value.length}/{MAX_LENGTH}
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          <Button
            className="button cancel"
            onClick={handleClose}
            disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            className="button primary"
            onClick={handleGenerate}
            disabled={
              value.length < MIN_LENGTH ||
              value.length > MAX_LENGTH ||
              isLoading
            }>
            Enviar alerta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManagementWorkspacesMassiveAlertDialog
