
import { buildProfilingForm } from "helpers/workspaces/buildProfilingForm";
import Profiling, { Answer, ProfilingFormModel } from "models/profiling";
import { useCallback, useMemo, useState } from "react"
import { getAnswersByWorkspaceId, getFormsByWorkspaceId } from "services/profiling";


export interface GetProfilingDataResponse {
  isError: boolean
  isLoading: boolean
  response: ProfilingFormModel []
  handleProfilingData: (worksPaceId:number) => void
}

const useGetProfilingData = (): GetProfilingDataResponse => {
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ProfilingFormModel []>([]);
  
  const promisesArray = useMemo(() => {
    return [getFormsByWorkspaceId, getAnswersByWorkspaceId]
  }, [])

  const getProfilingData = useCallback(async (workSpaceId: number) => {
    setIsLoading(true)
    setIsError(false)
    await Promise.all(promisesArray.map((promise) => promise(workSpaceId)))
      .then((values: any [] ) => {          
        const questionResponse: ProfilingFormModel [] = buildProfilingForm(
          values[0] as Profiling [], 
          values[1] as Answer []
        )
        setResponse(questionResponse) 

      })
      .catch(async () => {
        setIsError(true)
      })
    setIsLoading(false)
  }, [promisesArray])

  return {
    isError: isError,
    isLoading: isLoading,
    handleProfilingData: getProfilingData,
    response: response,
  }

}

export default useGetProfilingData;
