import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import { IApiError } from 'services/Shared/shared.models'
import { ICancelRecurrentPaymentRequest } from 'services/Payments/payments.models'
import { deleteRecurrentPayment } from 'services/Payments/payments.services'
import { IUsePaymentsContainer } from 'containers/Account/Payments/PaymentsContainer/usePaymentsContainer'

type TUseCancelRecurrentPaymentProps = Pick<
  IUsePaymentsContainer,
  | 'refetchBillingInformation'
  | 'goBackToPaymentsOverview'
  | 'onCloseRecurrentPlanModalHandler'
>

const useCancelRecurrentPayment = ({
  goBackToPaymentsOverview,
  onCloseRecurrentPlanModalHandler,
  refetchBillingInformation,
}: TUseCancelRecurrentPaymentProps): UseMutationResult<
  void,
  IApiError,
  ICancelRecurrentPaymentRequest
> => {
  const mutation = useMutation<void, IApiError, ICancelRecurrentPaymentRequest>(
    (variables: ICancelRecurrentPaymentRequest) =>
      deleteRecurrentPayment(variables),
    {
      onSuccess: () => {
        refetchBillingInformation()
        onCloseRecurrentPlanModalHandler()
        goBackToPaymentsOverview()
        toast.success(
          'La domiciliación ha sido cancelada',
          successToastOptions
        )
      },
      onError: () => {
        onCloseRecurrentPlanModalHandler()
        toast.error(
          'No fue posible cancelar la domiciliación. Por favor intenta de nuevo',
          errorToastOptions
        )
      },
    }
  )

  return mutation
}

export default useCancelRecurrentPayment
