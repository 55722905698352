import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import FileField from 'components/FormComponents/IOComponents/FileField/FileField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import { useAddEmisor } from 'hooks/queries/emisores'

import { selectCatalogue } from 'lib/helpers/selectors'

import { getPersonType } from 'lib/helpers/utilities'

import './style.scss'

import { Archive, Persona } from 'models/general'
import { RazonSocialForm } from 'models/razonSocial'
import { CatalogueEntry } from 'models/catalogues'

interface Props {
  workspaceId: number
  onRedirect: (action: string) => void
}

const AddRazonSocialFormContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const intl = useIntl()

  const tiposPersona = useSelector(selectCatalogue('tiposPersona'))
  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))

  const [valid, setValid] = useState(false)
  const [personType, setPersonType] = useState<Persona | undefined>(
    Persona.fisica
  )
  const [csdCer, setCsdCer] = useState<Archive | null>()
  const [csdKey, setCsdKey] = useState<Archive | null>()
  const [csdPass, setCsdPass] = useState('')

  const addMutation = useAddEmisor(workspaceId, {
    onSuccess: () => onRedirect('success'),
  })

  return (
    <>
      <Card elevation={1} className="container-add-razon-social">
        <Form
          onSubmit={async (form: RazonSocialForm) => addMutation.mutate(form)}
          // onSubmit={async (form: RazonSocialForm) => console.log(form)}
          onValidity={(v: boolean) => setValid(v)}>
          <div className="content">
            <p>
              Completa la siguiente información para agregar un nuevo emisor al
              catálogo
            </p>
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <p className="section-header">Información general</p>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <AutocompleteField
                      name="type"
                      label={intl.formatMessage({
                        id: `emisor.create.form.type`,
                      })}
                      catalogue={tiposPersona}
                      value={
                        _.filter(tiposPersona, i => i.idCatalogValues === 1)[0]
                      }
                      onValue={(v: CatalogueEntry) =>
                        setPersonType(getPersonType(v.idCatalogValues))
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="name"
                      label={intl.formatMessage({
                        id: `emisor.create.form.${personType}.name`,
                      })}
                      fieldType="lettersWithAccents"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="rfc"
                      label={intl.formatMessage({
                        id: `emisor.create.form.rfc`,
                      })}
                      validate={
                        personType === Persona.moral
                          ? ['isCompanyRFC']
                          : ['isPersonRFC']
                      }
                      inputProps={{
                        maxLength: personType === Persona.moral ? 12 : 13,
                        style: { textTransform: 'uppercase' },
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <AutocompleteField
                      name="taxSystem"
                      label={intl.formatMessage({
                        id: `emisor.create.form.tax_system`,
                      })}
                      catalogue={
                        personType === Persona.moral
                          ? taxSystemMoral
                          : taxSystemFisica
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fieldType="number"
                      name="zipCode"
                      label={intl.formatMessage({ id: `form.zip_code` })}
                      validate={['isZipCode']}
                      inputProps={{ maxLength: 5 }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="taxAddress"
                      label={`${intl.formatMessage({ id: `form.tax_address` })} (${intl.formatMessage({ id: `form.optional` })})`}
                      inputProps={{ maxLength: 150 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <p className="section-header">
                  Información de la e.Firma (FIEL)
                </p>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FileField
                      name="fielCer"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.CER', more: '' }
                      )}
                      accept=".cer"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileField
                      name="fielKey"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.KEY', more: '' }
                      )}
                      accept=".key"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="passwordFiel"
                      validate={['isAtLeastEightCharacters']}
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.fiel`,
                      })}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <p className="section-header">
                  {!!csdCer || csdKey || !!csdPass
                    ? 'Información de la CSD'
                    : 'Información de la CSD (opcional)'}
                </p>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FileField
                      name="csdCer"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.CER', more: '' }
                      )}
                      accept=".cer"
                      onValue={(f: any) => setCsdCer(f)}
                      required={Boolean(csdKey) || !_.isNil(csdPass)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileField
                      name="csdKey"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.KEY', more: '' }
                      )}
                      accept=".key"
                      onValue={(f: any) => setCsdKey(f)}
                      required={!_.isNil(csdCer) || !_.isNil(csdPass)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="passwordCsd"
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.csd`,
                      })}
                      fullWidth
                      onValue={(f: string) => setCsdPass(f)}
                      required={!_.isNil(csdCer) || Boolean(csdKey)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="ciec"
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.ciec`,
                      })}
                      fullWidth
                      required={
                        !_.isNil(csdCer) || Boolean(csdKey) || !_.isNil(csdPass)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="addBusinessNameBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={addMutation.isLoading}
              disabled={!valid}>
              Agregar
            </Button>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default AddRazonSocialFormContainer
