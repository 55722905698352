import React from 'react'
import MUIButton, {
  ButtonProps as MUIButtonProps,
} from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import './style.scss'

import { filterColorPalette, filterVariant } from 'lib/helpers/utilities'

//#region Types and Interfaces
import { ExtendedButtonVariant, ExtendedColorPalette } from 'models/general'

export interface ButtonProps extends Omit<MUIButtonProps, 'color' | 'variant'> {
  variant?: ExtendedButtonVariant
  color?: ExtendedColorPalette
  activity?: boolean
}
//#endregion

/**
 * Base Button based on MUI v4.11, integrates a symbolic palette like Bootstrap.
 */
const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  variant = 'text',
  color = 'default',
  activity,
  disabled,
  ...props
}) => {
  return (
    <MUIButton
      {...props}
      variant={filterVariant(variant)}
      disabled={activity || disabled}
      color={filterColorPalette(color)}
      className={`component-button ${variant} ${color} ${className}`.trim()}
      disableRipple={variant === 'link'}>
      {children}
      {activity && (
        <CircularProgress size={24} className={`progress ${color}`} />
      )}
    </MUIButton>
  )
}

export default Button
