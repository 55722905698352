import HTTPClient from 'lib/helpers/HTTPClient'
import _ from 'lodash'

import { resetActiveForm } from 'actions/login'
import { getRole } from 'actions/account'
import { getWorkspaceById, getWorkspaceList } from 'actions/workspaces'
import { setLocalLoader } from 'actions/loader'

import { httpErrorParser, sleep } from 'lib/helpers/utilities'

import ReduxState from 'models/redux'
import { Notification, NotificationType } from 'models/general'
import { ReduxAction } from 'models/thunk'
import { Role } from 'models/user'

/** Action type to set the user's authorization value */
export const SET_AUTHORIZATION = '@User/SET_AUTHORIZATION'
/** Action type to set the user's data/information value */
export const SET_USER_DATA = '@User/SET_USER_DATA'
/** Action type to set the user's notifications */
export const SET_NOTIFICATIONS = '@User/SET_NOTIFICATIONS'
/** Action type to add the user's notifications */
export const ADD_NOTIFICATIONS = '@User/ADD_NOTIFICATIONS'
/** Action type to delete the user's notifications */
export const DELETE_NOTIFICATION = '@User/DELETE_NOTIFICATION'
/** Action type to set the user's pending invitations */
export const SET_INVITATIONS = '@User/SET_INVITATIONS'
/** Action type to add the user's pending invitations */
export const ADD_INVITATIONS = '@User/ADD_INVITATIONS'
/** Action type to change the user's pending invitations */
export const DELETE_INVITATION = '@User/DELETE_INVITATION'
/** Action type to set the user's role */
export const SET_USER_ROLE = '@User/SET_USER_ROLE'
/** Acción para establecer las llaves de sesión */
export const SET_SESSION_KEYS = '@User/SET_SESSION_KEYS'
/** Acción para establcer la bandera de logout manual */
export const SET_MANUAL_LOGOUT = '@User/MANUAL_LOGOUT_FLAG'
/** Acción para establcer la bandera de logout manual */
export const SET_DEVICE = '@User/SET_DEVICE'

const api = process.env.REACT_APP_APIURL || '/api'

/**
 * Sets the User's authorization state value.
 * @param {Boolean} value The authorization state value
 * @returns {Object} The action object to be consumed by the User Reducer
 */
export const setAuthorization = (value: boolean): object => ({
  type: SET_AUTHORIZATION,
  value,
})

/**
 * Sets the User's data state value.
 * @param {Object} value The user entity value
 * @returns {Object} The action object to be consumed by the User Reducer
 */
export const setUserData = (value: any): object => ({
  type: SET_USER_DATA,
  value,
})

export const setNotifications = (
  value: Notification[]
): ReduxAction<Notification[]> => ({
  type: SET_NOTIFICATIONS,
  value,
})

export const addNotifications = (
  value: Notification[]
): ReduxAction<Notification[]> => ({
  type: ADD_NOTIFICATIONS,
  value,
})

export const deleteNotification = (
  value: Notification
): ReduxAction<Notification> => ({
  type: DELETE_NOTIFICATION,
  value,
})

export const setInvitations = (
  value: Notification[]
): ReduxAction<Notification[]> => ({
  type: SET_INVITATIONS,
  value,
})

export const addInvitations = (
  value: Notification[]
): ReduxAction<Notification[]> => ({
  type: ADD_INVITATIONS,
  value,
})

export const deleteInvitation = (
  value: Notification
): ReduxAction<Notification> => ({
  type: DELETE_INVITATION,
  value,
})

/**
 * Sets the session keys delivered by the OAUTH service in the Redux store.
 * @param {object} value Objeto que contiene los tokens de autorización
 */
export const setSessionKeys = (value: any): object => ({
  type: SET_SESSION_KEYS,
  value,
})

/**
 * Establece una bandera para determinar si el logout fue manual o no.
 */
export const setManualLogout = (value: boolean): object => ({
  type: SET_MANUAL_LOGOUT,
  value,
})

/**
 *
 */
export const setDevice = (value: string): object => ({
  type: SET_DEVICE,
  value,
})

/**
 *
 */
export const setUserRole = (value: Role | null): object => ({
  type: SET_USER_ROLE,
  value,
})

/**
 * Ejecuta las acciones para terminar la sesión actual
 * @returns {Object} La acción a ejecutar por el reductor
 */
export const logout =
  (manual: boolean = false) =>
  async (dispatch: any) => {
    const client = HTTPClient.getClient()
    dispatch(resetActiveForm())
    dispatch(setManualLogout(manual))
    dispatch(setUserData(null))
    dispatch(setSessionKeys(null))
    dispatch(setAuthorization(false))
    dispatch(setUserRole(null))
    dispatch(setNotifications([]))
    dispatch(setInvitations([]))
    try {
      manual && (await client.delete(`${api}/log_out`))
    } catch (error: any) {
      const err = await error.json()
      console.log(err)
    } finally {
      dispatch(setLocalLoader(false))
    }
  }

/**
 * Ejecuta un intento de inicio de sesión contra el servicio de OAuth a traves del middleware/dispatcher
 * @param {UserCredentials} credentials Credenciales definidas por un usuario y una contraseña
 * @return {Promise} Devuelve una promesa con el resultado
 */
export const login =
  (email: string, password: string) =>
  async (dispatch: any, getStore: () => ReduxState) => {
    const client = HTTPClient.getClient()
    const store = getStore()
    try {
      const body = {
        users: { email, password },
        devices: { deviceName: store.user.device },
      }

      const petition = await client.post(`${api}/login`, body)
      const data = await petition.json()
      const invitations = _.map(
        data['Invitations'],
        (i): Notification => ({
          id: i.idInvitation,
          type: NotificationType.INVITATION,
          message: i.workspaces.name,
          relatedId: i.workspaces.idWorkspace,
          read: false,
        })
      )

      client.setAuthorization(data.token, '')
      dispatch(setSessionKeys({ access_token: data.token, refresh_token: '' }))
      dispatch(setInvitations(invitations))

      return data.token
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const loginDummy =
  (email: string, _password: string) =>
  async (dispatch: any, _getStore: () => ReduxState) => {
    const client = HTTPClient.getClient()
    const token = '50yunt0k3nb13nch1d0'
    try {
      await sleep(2000)
      client.setAuthorization(token, '')
      dispatch(
        setSessionKeys({
          access_token: token,
          refresh_token: '',
        })
      )
      dispatch(setUserData({ email }))
      dispatch(setAuthorization(true))
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

/**
 * Ejecuta un intento de renegociación de credenciales
 * @param {string} refreshToken Token para refrescar la sesión
 * @return {Promise} Devuelve una promesa con el resultado
 */
export const refreshSession = (refreshToken: string) => (_dispatch: any) => {
  console.log('refreshing session...', refreshToken)
  // const client = HTTPClient.getClient();
  // return client.request('POST', 'authentication/refresh', { refresh_token: refreshToken }, null, false).then(async response => {
  //     const keys = await response.json()
  //     client.setAuthorization(keys.access_token, keys.refresh_token)
  //     dispatch(setSessionKeys(keys))

  //     return response
  // })
}

export const register =
  (
    email: string,
    password: string,
    name?: string,
    lastname?: string,
    phone?: string,
    promoCode?: string
  ) =>
  async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      await client.post(`${api}/registration`, {
        name,
        lastname,
        phone,
        email,
        password,
        promoCode,
      })
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const setNewPassword =
  (password: string, token: string) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      await client.post(`${api}/new_password`, {
        tokenValid: token,
        password,
      })
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const recover = (email: string) => async (_dispatch: any) => {
  const client = HTTPClient.getClient()
  try {
    await client.post(`${api}/recover_account`, { email })
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getUser = () => (dispatch: any, getState: () => ReduxState) => {
  const client = HTTPClient.getClient()
  const {
    workspace: { activeWorkspace = 0 },
  } = getState()
  return client.get(`${api}/profile`).then(async (response: any) => {
    const user = await response.json()
    dispatch(setUserData(user))
    await dispatch(getRole())
    await dispatch(getWorkspaceList())
    Boolean(activeWorkspace) &&
      (await dispatch(getWorkspaceById(activeWorkspace)))
    dispatch(setAuthorization(true))
    return user
  })
}
