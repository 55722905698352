import { Grid } from "@material-ui/core";
import { FC, createContext, useMemo, useState } from "react";
import AccountStatementHistoryToolbar from "../AccountStatementHistoryToolbar/AccountStatementHistoryToolbar";
import StatementHistoryTable from "../AccountStatementHistoryTable/AccountStatementHistoryTable";

interface FiltersProperties {
  account: string;
  month: number;
  year: number;
}
interface StatementHistorySection {
  rows: any[],
  setRows: (rows: any[]) => void,
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  filters: FiltersProperties;
  setFilters: (filters: FiltersProperties) => void;
}

export const AccountStatementHistoryContext = createContext<StatementHistorySection>({} as StatementHistorySection);

const AccountStatementHistory: FC = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<StatementHistorySection['filters']>({
    account: '',
    month: 0,
    year: 0,
  });

  const valueToProvide: StatementHistorySection = useMemo(
    () => ({
      rows,
      setRows,
      isLoading,
      setIsLoading,
      filters,
      setFilters,
    }),
    [rows, isLoading, filters]
  );

  return (
    <AccountStatementHistoryContext.Provider value={valueToProvide}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <AccountStatementHistoryToolbar />
          <StatementHistoryTable />
        </Grid>
      </Grid>
    </AccountStatementHistoryContext.Provider>
  );
};

export default AccountStatementHistory;