import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import { API_RESPONSE_ERROR_CODE_KEYS } from 'lib/enums/shared.enums'
import { TApiResponseErrorCodeKeys } from 'models/shared.models'
import {
  IDialogProviderState,
  TDialogVariant,
} from 'providers/DialogProvider/DialogProvider'

export const predefinedErrorModalOptionsMap: Readonly<
  Record<
    TApiResponseErrorCodeKeys,
    (customError: any) => Partial<IDialogProviderState>
  >
> = {
  [API_RESPONSE_ERROR_CODE_KEYS[401]]: () => ({
    variant: 'danger' as TDialogVariant,
    title: '¿Sigue ahí?',
    subtitle: 'Hemos cerrado su sesión por inactividad.',
    primaryButtonText: 'Cerrar',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[1001]]: (customError?: any) => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle: 'Detectamos algunos errores )al generar el CFDI.',
    modalContent: (
      <>
        <p>Esta es una lista que te puede ayudar a identificarlos:</p>
        {customError?.errors && (
          <ul className="error-list">
            {customError?.errors.map((ce: string, i: number) => (
              <li key={i}>{ce.replace('on element', 'en el nodo')}</li>
            ))}
          </ul>
        )}
      </>
    ),
    primaryButtonText: 'Entendido',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[1002]]: () => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle:
      'La serie y folio usados estan repetidos. Realiza el cambio e intenta nuevamente.',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[1003]]: () => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle:
      'Hay un problema con el servicio de timbrado. Inténtalo de nuevo más tarde.',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[2001]]: () => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle: 'Los archivos cargados son inválidos o la clave es incorrecta.',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[3002]]: () => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle: 'Este RFC ya existe en el registro. Revisa e inténtalo de nuevo.',
  }),
  [API_RESPONSE_ERROR_CODE_KEYS[9000]]: (customError?: any) => ({
    variant: 'dangerAsync' as TDialogVariant,
    icon: ErrorOutlineIcon as TModalIcon,
    title: 'generic.error.title',
    subtitle: `${customError?.message || customError?.description}`,
  }),
} as const
