import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectClassKey,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './styles.scss'

export const useCustomFieldStyles = makeStyles<SelectClassKey>(() => ({
  root: {
    fontWeight: 'bold',
    fontSize: 17,
    paddingBottom: 3,
  },
}))

const AccountSelector = () => {
  const { isLoadingStatements, statements } = useConciliationsContainerContext()

  const {
    statementRows,
    activeStatementRow,
    setConciliationActiveStatementRow,
  } = statements

  const [value, setValue] = useState<number | null>(0)

  const dispatch = useDispatch()

  const classes = useCustomFieldStyles()

  useEffect(
    () => {
      if (!value) {
        setValue(activeStatementRow)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*value, activeStatementRow*/
    ]
  )

  const options = useMemo(
    () =>
      _.map(statementRows, row => ({
        value: row.idFileBankStatement,
        label: `${row.bank} ${row.account.slice(-4)}`,
      })),
    [statementRows]
  )

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setConciliationActiveStatementRow(Number(event.target.value)))
    setValue(Number(event.target.value))
  }

  return (
    <Fragment>
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        flexDirection="row"
        gridGap={10}
        className="account-selector-component">
        <Typography className="title">Estados de cuenta</Typography>
        {isLoadingStatements ? (
          <Box display="flex" alignItems="center">
            <CircularProgress variant="indeterminate" size={18} />
          </Box>
        ) : (
          <Select
            classes={classes}
            disableUnderline
            value={activeStatementRow ?? ''}
            onChange={handleChange}
            className="menu">
            <MenuItem value={0} disabled>
              Seleccionar
            </MenuItem>
            {_.map(options, (option, key) => (
              <MenuItem value={option.value} key={key}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    </Fragment>
  )
}

export default AccountSelector
