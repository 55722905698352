import { Box } from '@material-ui/core'
import { ROUTES } from '../../../constants'
import Invoice from 'models/invoice'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import CFDIFinder from 'components/NewCFDI/CFDIFinder/CFDIFinder'

const NewPaymentComplianceScreen: FC = () => {
  const navigate = useNavigate()
  const handleOnSelect = (invoice: Invoice) => {
    const version = invoice.version === 4 ? 2 : undefined
    const { idCfdi } = invoice
    let route = _.replace(
      ROUTES.ADD_PAYMENT_COMPLEMENT,
      ':invoice',
      `${idCfdi}`
    )
    if (version) route = `${route}?version=${version}`
    navigate(route)
  }
  return (
    <Box width="100%" paddingX={8}>
      <CFDIFinder
        onSelect={handleOnSelect}
        title="Nuevo complemento de pago"
        subtitle="Selecciona la factura a la cual se le aplicará el complemento de pago:"
      />
    </Box>
  )
}

export default NewPaymentComplianceScreen
