import React, { useState } from 'react'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import moment from 'moment'
import _ from 'lodash'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'
import Table from 'components/Shared/DynamicTable/DynamicTable'

import { getReport } from 'actions/dashboard'

import './style.scss'

import { ReportObject } from 'models/dashboard'
// import { CatalogueEntry } from 'models/catalogues';

interface Props {
  workspaceId: number
}

const InvoiceListContainer = ({ workspaceId }: Props) => {
  const thunkDispatch = useThunkDispatch()
  const intl = useIntl()

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  const { data: list, ...query } = useQuery<ReportObject[]>(
    ['get-invoice-list', workspaceId],
    () => {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1
      return thunkDispatch(
        getReport(workspaceId, 'tableCfdis', { year, month })
      )
    }
  )

  const formatMoney = (money: number) => {
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatStatus = (status: any) => {
    let label = ''
    switch (status?.value) {
      case 'pending':
        label = 'Pendiente'
        break
      case 'charged':
        label = 'Cobrada'
        break
      case 'part-charged':
        label = 'Cobrada parcialmente'
        break
      case 'paid':
        label = 'Pagada'
        break
      case 'part-paid':
        label = 'Pagada parcialmente'
        break
      case 'canceled':
        label = 'Cancelada'
        break
      case 'pending-canceled':
        label = 'Pendiente de cancelar'
        break
      default:
        label = '- - -'
        break
    }
    return label
  }
  const formatDate = (dateString: string) => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }

  const invoiceHeaders = [
    { label: 'Tipo', accesor: 'type' },
    { label: 'Nombre o razón social', accesor: 'name' },
    { label: 'RFC', accesor: 'rfc' },
    { label: 'Total', accesor: 'total', renderer: formatMoney },
    { label: 'Estatus', accesor: 'statusPayment', renderer: formatStatus },
    { label: 'Fecha', accesor: 'created_at', renderer: formatDate },
  ]

  return (
    <DashboardCard
      className="invoice-list-container"
      title="CFDIs del Mes"
      titleTag="h3"
      fetching={query.isFetching}>
      <Table
        headers={invoiceHeaders}
        rows={(list || []).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )}
        loading={query.isLoading}
        pagination={{
          rowsPerPageOptions: [],
          count: list?.length || 0,
          rowsPerPage: rowsPerPage,
          page: page,
          onPageChange: (_e, page) => setPage(page),
          onRowsPerPageChange: e => setRowsPerPage(Number(e.target.value)),
          labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de ${count}`,
        }}
      />
    </DashboardCard>
  )
}

export default InvoiceListContainer
