import { useDidUpdate } from 'rooks'
import _ from 'lodash'

import Button from 'components/Shared/Button/Button'
import ChatMessageC from 'components/Accounting/ChatMessage/ChatMessage'
import CircularProgress from '@material-ui/core/CircularProgress'

import ErrorI from '@material-ui/icons/ErrorOutline'
import NoMessagesI from '@material-ui/icons/SpeakerNotesOff'
import './style.scss'

import { ChatMessage } from 'models/accounting.models'

interface MessagesContainerProps {
  messages: ChatMessage[]
  onRetry: () => void
  loading?: boolean
  error?: boolean
}

const MessagesContainer = ({
  messages,
  loading,
  error,
  onRetry,
}: MessagesContainerProps) => {
  const withStatus = loading || error || messages.length === 0

  useDidUpdate(() => {
    if (loading) return
    const div = document.getElementById('last-chat-message')
    div?.scrollIntoView()
  }, [loading])

  return (
    <div className={`messages-container ${withStatus ? 'with-status' : ''}`}>
      {loading && (
        <div className="status-container">
          <CircularProgress color="primary" size={80} />
          <p>Cargando mensajes...</p>
        </div>
      )}
      {!loading && error && (
        <div className="status-container error">
          <ErrorI color="inherit" fontSize="inherit" />
          <p>Hubo un error al cargar los mensajes.</p>
          <Button onClick={onRetry} color="primary">
            Reintentar
          </Button>
        </div>
      )}
      {!loading && !error && messages.length === 0 && (
        <div className="status-container no-messages">
          <NoMessagesI color="inherit" fontSize="inherit" />
          <p>No hay mensajes que mostrar.</p>
        </div>
      )}
      {!loading &&
        !error &&
        _.map(messages, (message, i) => (
          <div
            id={messages.length - 1 === i ? 'last-chat-message' : undefined}
            key={message.date}
            className={'message-item'}>
            <ChatMessageC
              messageUser={message.emailUser || ''}
              messageText={message.text || ''}
              messageDate={message.date}
              type={message.sentBy}
            />
          </div>
        ))}
    </div>
  )
}

export default MessagesContainer
