export const LOGOUT = 'LOGOUT'
export const RESET_APP_STATE = 'RESET_APP_STATE'

export const logout = () => ({
  type: LOGOUT,
})

export const resetAppState = () => ({
  type: RESET_APP_STATE,
})
