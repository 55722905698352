import { toPeriodString } from 'lib/helpers/utilities'
import { ConciliationsStore } from 'models/redux'
import _ from 'lodash'
import { useMemo } from 'react'
import { CFDISection } from 'models/conciliations'

export const getActualSelectedCFDIs = (cfdis: CFDISection) => {
  const { cfdiRows, selectedCfdiRows } = cfdis

  const selected = _.filter(cfdiRows, cfdi =>
    selectedCfdiRows.includes(cfdi.id)
  )
  return _.map(
    _.filter(selected, cfdi => cfdi.conciliated === false),
    cfdi => cfdi.id
  )
}

export const getActualSelectedStatements = (
  statements: ConciliationsStore['statements']
) => {
  const { statementRows, selectedStatementRows, activeStatementRow } =
    statements

  const bankStatement = _.find(
    statementRows,
    statement => statement.idFileBankStatement === activeStatementRow
  )
  const transactions = _.get(bankStatement, 'transactions', [])
  const selected = _.filter(transactions, transaction =>
    selectedStatementRows.includes(transaction.idTransaction)
  )
  return _.map(
    _.filter(selected, transaction => transaction.conciliated === false),
    transaction => transaction.idTransaction
  )
}

const checkCanConciliate = (
  cfdis: ConciliationsStore['cfdis'],
  ledgers: ConciliationsStore['ledgers'],
  statements: ConciliationsStore['statements']
) => {
  const { cfdiActiveSection } = cfdis
  const { selectedLedgerRows } = ledgers

  const actualSelectedStatements = getActualSelectedStatements(statements)
  const actualSelectedCFDIs = getActualSelectedCFDIs(cfdis as CFDISection)

  if (
    actualSelectedStatements.length > 0 &&
    actualSelectedCFDIs.length > 0 &&
    cfdiActiveSection === 0
  ) {
    if (
      actualSelectedStatements.length === 1 &&
      actualSelectedCFDIs.length >= 1
    )
      return true
    if (
      actualSelectedStatements.length >= 1 &&
      actualSelectedCFDIs.length === 1
    )
      return true
    return false
  }

  return (
    actualSelectedStatements.length > 0 &&
    selectedLedgerRows.length > 0 &&
    selectedLedgerRows.length <= 1 &&
    cfdiActiveSection === 1
  )
}

const getConciliationMode = (
  cfdis: ConciliationsStore['cfdis'],
  ledgers: ConciliationsStore['ledgers'],
  statements: ConciliationsStore['statements']
) => {
  const { cfdiActiveSection } = cfdis
  const { selectedLedgerRows } = ledgers

  const actualSelectedStatements = getActualSelectedStatements(statements)
  const actualSelectedCFDIs = getActualSelectedCFDIs(cfdis as CFDISection)
  if (actualSelectedStatements.length > 0 && selectedLedgerRows.length === 1)
    return CONCILIATION_MODES.LEDGER as ConciliationType
  if (
    actualSelectedStatements.length > 0 &&
    actualSelectedCFDIs.length === 1 &&
    cfdiActiveSection === 0
  )
    return CONCILIATION_MODES.CFDI as ConciliationType
  if (
    actualSelectedStatements.length === 1 &&
    actualSelectedCFDIs.length > 0 &&
    cfdiActiveSection === 0
  )
    return CONCILIATION_MODES.CFDIS as ConciliationType
}

export const CONCILIATION_MODES = {
  LEDGER: 'statements-ledger',
  CFDI: 'statements-cfdi',
  CFDIS: 'statement-cfdis',
}

type ConciliationType =
  (typeof CONCILIATION_MODES)[keyof typeof CONCILIATION_MODES]

const getLedgerAccount = (ledgers: ConciliationsStore['ledgers']) => {
  const { ledgerRows, selectedLedgerRows } = ledgers

  const account = _.find(
    ledgerRows,
    row => row.idLedgerAccount === selectedLedgerRows[0]
  )
  if (account) return account.idLedgerAccount
  return null
}

const getSelectedTransactions = (
  statements: ConciliationsStore['statements']
) => {
  const { statementRows, selectedStatementRows, activeStatementRow } =
    statements

  const bankStatement = _.find(
    statementRows,
    statement => statement.idFileBankStatement === activeStatementRow
  )
  const transactions = _.get(bankStatement, 'transactions', [])
  const selected = _.map(
    _.filter(transactions, transaction =>
      selectedStatementRows.includes(transaction.idTransaction)
    ),
    transaction => _.pick(transaction, ['idTransaction'])
  )
  return selected
}

const getSelectedCFDIs = (cfdis: ConciliationsStore['cfdis']) => {
  const { cfdiRows } = cfdis

  const selected = getActualSelectedCFDIs(cfdis as CFDISection)
  return _.map(
    _.filter(cfdiRows, cfdi => selected.includes(cfdi.id)),
    cfdi => _.pick(cfdi, ['uuid'])
  )
}

const useConciliate = (
  statements: ConciliationsStore['statements'],
  ledgers: ConciliationsStore['ledgers'],
  cfdis: ConciliationsStore['cfdis']
) => {
  const canConciliate = useMemo(() => {
    return checkCanConciliate(cfdis, ledgers, statements)
  }, [statements, ledgers, cfdis])

  const conciliationMode = useMemo(() => {
    return getConciliationMode(cfdis, ledgers, statements)
  }, [statements, ledgers, cfdis])

  const idLedgerAccount = useMemo(() => {
    return getLedgerAccount(ledgers)
  }, [ledgers])

  const transactions = useMemo(() => {
    return getSelectedTransactions(statements)
  }, [statements])

  const selectedCfdis = useMemo(() => {
    return getSelectedCFDIs(cfdis)
  }, [cfdis])

  const getBody = (period: { month: number; year: number }) => {
    const currentPeriod = toPeriodString(period, true)
    if (conciliationMode === CONCILIATION_MODES.LEDGER) {
      return {
        period: currentPeriod,
        transactions,
        idLedgerAccount,
      }
    }
    if (conciliationMode === CONCILIATION_MODES.CFDI) {
      return {
        period: currentPeriod,
        transactions,
        cfdis: selectedCfdis,
      }
    }
    if (conciliationMode === CONCILIATION_MODES.CFDIS) {
      return {
        period: currentPeriod,
        transactions,
        cfdis: selectedCfdis,
      }
    }
  }

  return {
    canConciliate,
    conciliationMode,
    idLedgerAccount,
    transactions,
    selectedCfdis,
    getBody,
  }
}

export default useConciliate
