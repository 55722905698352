import { TextField } from '@material-ui/core'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import { CatalogueEntry } from 'models/catalogues'
import { useFormContext } from 'react-hook-form'
import CloseIcon from '@material-ui/icons/Close'
import FilterListIcon from '@material-ui/icons/FilterList'
import withTextInputController from 'hocs/withTextInputController'
import { useManagementWorkspacesContext } from 'components/Management/ManagementWorkspaces/ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import './styles.scss'
import { useEffect, useMemo } from 'react'
import Button from 'components/Shared/Button/Button'

const TextInput = withTextInputController(TextField)

export interface WorkspaceSimpleFiltersProps {
  isAdvancedSearchActive: boolean
  handleAdvancedSearch: () => void
  handleResetForm: () => void
}

const WorkspaceSimpleFilters = (props: WorkspaceSimpleFiltersProps) => {
  const { isAdvancedSearchActive, handleAdvancedSearch, handleResetForm } =
    props
  const {
    teams: teamsCatalog,
    closingStatus: closingStatusCatalog,
    opinion: opinionCatalog,
    workspaceStatus: userStatusCatalog,
  } = useManagementWorkspacesContext()
  const { register, setValue, watch, control } = useFormContext()
  const workspaceStatus = watch('workspace-status')
  const monthlyClosure = watch('closing-status')
  const opinion = watch('opinion')
  const team = watch('team')
  const param = watch('param')

  const isCleanFiltersActive = useMemo(() => {
    if (
      (param !== '' && param !== undefined) ||
      monthlyClosure !== undefined ||
      team !== undefined ||
      workspaceStatus !== undefined ||
      opinion !== undefined
    ) {
      return true
    } else {
      return false
    }
  }, [param, monthlyClosure, team, workspaceStatus, opinion])

  useEffect(() => {
    if (param === '') {
      handleResetForm()
    }
  }, [param, handleResetForm])

  return (
    <div className="workspace-simple-filters">
      <div className="filter-280">
        <TextInput
          fullWidth
          control={control}
          name={'param'}
          label="Buscar"
          placeholder="Buscar por ID, Nombre, contador o RFC"
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="filter-197">
        <AutocompleteField
          label={'Estatus de cierre mensual'}
          catalogue={closingStatusCatalog.map(
            mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
          )}
          getOptionLabel={(option: CatalogueEntry) => option.label}
          onSelectValue={(v: CatalogueEntry) =>
            setValue('closing-status', v.value)
          }
          onClear={(v: CatalogueEntry) => setValue('closing-status', v)}
          value={closingStatusCatalog.find(m => m.value === monthlyClosure)}
          fullWidth
          required
          disableClearable
          {...register('closing-status')}
        />
      </div>

      <div className="filter-147">
        <AutocompleteField
          label={'Equipo'}
          catalogue={teamsCatalog.map(
            mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
          )}
          getOptionLabel={(option: CatalogueEntry) => option.label}
          onSelectValue={(v: CatalogueEntry) => setValue('team', v.value)}
          onClear={(v: CatalogueEntry) => setValue('team', v)}
          value={teamsCatalog.find(m => m.value === team)}
          fullWidth
          required
          disableClearable
          {...register('team')}
        />
      </div>

      <div className="filter-147">
        <AutocompleteField
          label={'Estatus de usuario'}
          catalogue={userStatusCatalog.map(
            status =>
              ({ label: status.label, value: status.value }) as CatalogueEntry
          )}
          getOptionLabel={(option: CatalogueEntry) => option.label}
          onSelectValue={(v: CatalogueEntry) =>
            setValue('workspace-status', v.value)
          }
          onClear={(v: CatalogueEntry) => setValue('workspace-status', v)}
          value={userStatusCatalog.find(m => m.value === workspaceStatus)}
          fullWidth
          required
          disableClearable
          {...register('workspace-status')}
        />
      </div>

      <div className="filter-147">
        <AutocompleteField
          label={'Opinión'}
          catalogue={opinionCatalog.map(
            mc => ({ label: mc.label, value: mc.value }) as CatalogueEntry
          )}
          getOptionLabel={(option: CatalogueEntry) => option.label}
          onSelectValue={(v: CatalogueEntry) => setValue('opinion', v.value)}
          onClear={(v: CatalogueEntry) => setValue('opinion', v)}
          value={opinionCatalog.find(m => m.value === opinion)}
          fullWidth
          required
          disableClearable
          {...register('opinion')}
        />
      </div>

      <div className="actions">
        <div className="top">
          {isAdvancedSearchActive === false && (
            <>
              <Button variant="contained" color="secondary" type="submit">
                Buscar
              </Button>
            </>
          )}
          <Button
            type="button"
            startIcon={<FilterListIcon />}
            color="primary"
            onClick={handleAdvancedSearch}>
            {isAdvancedSearchActive === true ? (
              <>Ocultar búsqueda avanzada </>
            ) : (
              <>Búsqueda avanzada</>
            )}
          </Button>
        </div>

        {isCleanFiltersActive && isAdvancedSearchActive === false && (
          <div className="button">
            <Button
              variant="text"
              color="primary"
              type="button"
              startIcon={<CloseIcon />}
              onClick={handleResetForm}>
              Borrar filtros
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default WorkspaceSimpleFilters
