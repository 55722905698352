import React from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { SelectProps as MUISelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import './style.scss';

type ExcludedKeys = "onChange" | "defaultValue" | "labelId" | "labelWidth" | "native";
interface SelectProps extends Omit<MUISelectProps, ExcludedKeys> {
    ioProps: IOInputProps
    value: any
    addon: any
    setValue: (value: any) => void
}

const _SelectField: React.FC<SelectProps> = (props) => {
    const { 
        ioProps: { name, options, message, invalid },
        className = "",
        label,
        value,
        setValue,
        variant,
        fullWidth,
        addon,
        //placeholder
    } = props;
    
    return (
        <FormControl
            className={`component-select-field ${className} ${addon ? "with-addon" : ""}`.trim()}
            variant={variant}
            error={invalid}
            fullWidth={fullWidth}
        >
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
                id={`selectField-${name}`}
                labelId={`${name}-label`}
                value={value.value || ""}
            >
                {_.map(options, (op, i) => 
                    <MenuItem key={i} value={`${op.value}`} onClick={() => setValue(op)}>
                        {op.label}
                    </MenuItem>
                )}
            </Select>
            {invalid && <FormHelperText>{message}</FormHelperText>}
      </FormControl>
    )
}

const SelectField = withIO('selectfield')(_SelectField);
export default SelectField;
