import React, { useEffect, useState } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
//import _ from 'lodash';

import MUIAutocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import MUITextField from '@material-ui/core/TextField';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab/useAutocomplete';

import './style.scss';

//#region Types and Interfaces
interface CatalogueEntry {
  [key: string]: any
}

interface Props {
  ioProps: IOInputProps
  catalogue: CatalogueEntry[]
  style: React.CSSProperties
  setValue: (value: CatalogueEntry | null) => void
  value: CatalogueEntry | null
  onNewValue?: (value?: CatalogueEntry) => void
  onSelectValue?: (v: CatalogueEntry) => void
  onClear?: () => void
  noOptionsText?: string
  className?: string
  label?: string
  placeholder?: string
  variant?: "filled" | "outlined" | "standard"
  size?: "medium" | "small"
  fullWidth?: boolean
  disabled?: boolean
  disableCloseOnSelect?: boolean
  disableClearable?: boolean
  required?: boolean
  getOptionLabel?: (option: CatalogueEntry) => string
  renderOption?: (option: CatalogueEntry, state: object) => React.ReactNode
  customFilter?: (o: CatalogueEntry[], p: string) => CatalogueEntry[]
  inputProps: object
  InputProps: {
    startAdornment?: React.ReactNode,
    endAdornment?: React.ReactNode,
  }
  addon?: any
  disableCleareable?: boolean
}
//#endregion

const filter = createFilterOptions<CatalogueEntry>();

const _AutocompleteField: React.FC<Props> = (props) => {
  const {
    ioProps: { name, defaultValue },
    catalogue,
    className = "",
    variant,
    label,
    value,
    setValue,
    placeholder,
    onNewValue,
    onSelectValue,
    onClear,
    addon,
    customFilter,
    InputProps = {
      startAdornment: null,
      endAdornment: null
    },
    disableCleareable,
    ...rest
  } = props;

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  const onChange = (_e: any, v: CatalogueEntry | null) => {
    if (v && v.flag === "newValue" && onNewValue) {
      setSearch("");
      onNewValue(v);
      setValue(null);
    } else if (v && onSelectValue) {
      setValue(null);
      setSearch("");
      onSelectValue(v);
    } else {
      setValue(v);
    }
  }

  const onInputChange = (_e: any, v: string, r: string) => {
    if (r === "clear") onClear && onClear();
    setSearch(v);
  }

  const filterOptions = (o: CatalogueEntry[], p: FilterOptionsState<CatalogueEntry>) => {
    const filtered = customFilter ? customFilter(o, p.inputValue) : filter(o, p);
    if (p.inputValue !== "" && onNewValue) {
      filtered.push({
        flag: "newValue",
        label: "Add new value",
        value: p.inputValue
      });
    }
    return filtered;
  }

  const obtainDefaultOptionLabel = (o: CatalogueEntry) => (o?.label || "");

  const renderInput = ({ InputProps: InputPropsC, ...params }: AutocompleteRenderInputParams) => {
    let endAdornment = InputPropsC.endAdornment as any;
    if (InputProps.endAdornment) {
      const _endAdornment = React.createElement(
        endAdornment.type,
        { ...endAdornment.props, children: [...endAdornment.props.children, InputProps.endAdornment] }
      );
      endAdornment = _endAdornment;
    }

    return (
      <>
        <MUITextField
          {...params}
          id={`searchField-${name}`}
          className="text-field"
          variant={variant}
          placeholder={placeholder}
          label={label}
          InputProps={{
            ...InputPropsC,
            startAdornment: InputProps.startAdornment ? <>{InputProps.startAdornment}{InputPropsC.startAdornment}</> : InputPropsC.startAdornment,
            endAdornment: InputProps.endAdornment ? <>{InputPropsC.endAdornment}{InputProps.endAdornment}</> : InputPropsC.endAdornment,
          }}
        />
        {addon}
      </>
    )
  }


  return (
    <div className={`component-autocomplete-field ${className} ${addon ? "with-addon" : ""}`.trim()}>
      <MUIAutocomplete
        disableClearable={disableCleareable}
        options={catalogue}
        value={value || null}
        inputValue={search}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={obtainDefaultOptionLabel}
        filterOptions={filterOptions}
        handleHomeEndKeys={false}
        renderInput={renderInput}
        clearText="Limpiar"
        noOptionsText="Sin opciones"
        id={`autocomplete-${name}`}
        {...rest}
      />
    </div>
  )
}

const AutocompleteField = withIO("autocomplete")(_AutocompleteField);
export default AutocompleteField;

