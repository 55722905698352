import DoneIcon from '@material-ui/icons/Done';
import Button from 'components/Shared/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import "./styles.scss";

export interface AccountingRemiderSuccessProps {
  email: string
  handleClose: () => void
}

const AccountingRemiderSuccess = (props: AccountingRemiderSuccessProps) => {
  const { email, handleClose } = props;

  return(
    <div className="accounting-reminder-success">
      <div className='reminder-success-close-icon'>
        <div className='close-icon' onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
      <div className='icon'>
        <DoneIcon />
      </div>
      <span className='title'>
        Recordatorio enviado
      </span>
      <span className='reminder-success-text'>
        El recordatorio de solicitud de documentación se envió con éxito al correo {email}
      </span>
      <Button
        variant="contained"
        color="secondary"
        type='button'
        onClick={handleClose}
      >
        Aceptar
      </Button>
    </div>
  )
}

export default AccountingRemiderSuccess;