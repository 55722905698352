import Button from 'components/Shared/Button/Button'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { IconButton, Popover } from '@mui/material'
import { useState } from 'react'
import Down from '@material-ui/icons/KeyboardArrowDown'
import { IGetAccountingRemindersResponseService } from 'models/accounting.models'
import './style.scss'

export interface AccountingStepOneNotificationProps {
  data?: IGetAccountingRemindersResponseService
  isErrorGettingHistory: boolean
  isLoadingGettingHistory: boolean
  isButtonDisabled: boolean
  handleSendReminder: () => void
}

const AccountingStepOneNotification = (
  props: AccountingStepOneNotificationProps
) => {
  const { data, isButtonDisabled, handleSendReminder, isErrorGettingHistory, isLoadingGettingHistory } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGElement>(
    null
  )
  const [isRemindersOpen, setIsRemindersOpen] = useState<boolean>(true)

  return (
    <div className="accounting-step-one-notification">
      {isLoadingGettingHistory === false &&
        <>
          <Button
            className="accounting-step-one-button"
            variant="outlined"
            color="secondary"
            startIcon={<NotificationsIcon />}
            onClick={handleSendReminder}
            disabled={isButtonDisabled}>
            Enviar recordatorio
          </Button>

          {(data && data.remindersHistory.length > 0 && isErrorGettingHistory === false) && (
            <div className="accounting-set-one-info">
              <IconButton
                id="sbWorkspaceSelectorBtn"
                size="small"
                onClick={e => {
                  setAnchorEl(e.currentTarget)
                  setIsRemindersOpen(true)
                }}>
                <Down />
              </IconButton>
              <span>
                {data.remindersHistory.length} recordatorios enviados, último el {data.lastReminderSent}
              </span>
            </div>
          )}
        </>
      }      

      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && isRemindersOpen}
        onClose={() => {
          setAnchorEl(null)
          setIsRemindersOpen(false)
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className="accounting-set-one-drop-pop">
        <span className="accounting-set-one-drop-pop-title">
          Recordatorios enviados:
        </span>
        <div className="accounting-set-one-drop-pop-list">
          {data && data.remindersHistory.map((value, i) => (
            <li key={`reminder-${i}`}>{value}</li>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default AccountingStepOneNotification
