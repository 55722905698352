import { Box, Button, Grid, TextField } from '@material-ui/core';
import useGetAccountants from 'hooks/Management/Accountants/useGetAccountants';
import { selectActiveWorkspace } from 'lib/helpers/selectors';
import { FC, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import './styles.scss';

const ParamField: FC = () => {
  const { register } = useFormContext();
  return (
    <Grid item xs={9}>
      <TextField
      className='accountants-search-bar'
        fullWidth
        {...register('param')}
        label="Buscar"
        placeholder="Buscar por Nombre, ID,  Equipo asignado o correo"
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
  );
};

const Actions: FC = () => {
  return (
    <Grid item xs={3}>
      <Box display="flex" gridGap="2rem">
        <Button variant="outlined" color="secondary" type="submit">Buscar</Button>
      </Box>
    </Grid>
  );
};

interface SearchProps {
  onSubmit: (data: any) => void
}

const SearchAccountants: FC<SearchProps> = (props: SearchProps) => {
  const { onSubmit } = props
  const { fetchAccountants } = useGetAccountants()

  const methods = useForm();

  const activeWorkspace = useSelector(selectActiveWorkspace)

  useEffect(() => {
    methods.reset()
    fetchAccountants(0, 10, '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, activeWorkspace])

  return (
    <FormProvider {...methods}>
      <Box component="form" marginTop={4} onSubmit={methods.handleSubmit(onSubmit)} className='search-accountants-component'>
        <Grid container spacing={2} alignItems="flex-end">
          <ParamField />
          <Actions />
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default SearchAccountants;