import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Accountant } from 'models/management.models'
import { selectManagementAccountants } from 'lib/helpers/selectors'
import { setManagementAccountants } from 'actions/conciliations'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'

interface IAccountantsContextProviderProps {
  children: React.ReactNode
}

interface IAccountantsContextState {
  page: number
  elements: number
  isLoading: boolean
  param: string
}

interface IAccountantsContext extends IAccountantsContextState {
  updateAccountantsState: (
    update:
      | Partial<IAccountantsContextState>
      | ((prevState: IAccountantsContextState) => IAccountantsContextState)
  ) => void
  accountants: Accountant[]
  setManagementAccountants: typeof setManagementAccountants
}

export const AccountantsContext = createContext<IAccountantsContext | null>(
  null
)

const AccountantsContextProvider = ({
  children,
}: PropsWithChildren<IAccountantsContextProviderProps>) => {
  const accountants = useSelector(selectManagementAccountants)

  const [contextState, setContextState] = useState<IAccountantsContextState>({
    page: 0,
    elements: 10,
    isLoading: false,
    param: '',
  })

  const { page, elements, isLoading, param } = contextState

  const updateAccountantsState =
    useUpdateStateFunction<IAccountantsContextState>(setContextState)

  const contextValue = useMemo<IAccountantsContext>(
    () => ({
      accountants,
      setManagementAccountants,
      page,
      elements,
      isLoading,
      param,
      updateAccountantsState,
    }),
    [accountants, page, elements, isLoading, param, updateAccountantsState]
  )

  return (
    <AccountantsContext.Provider value={contextValue}>
      {children}
    </AccountantsContext.Provider>
  )
}

export const useAccountantsContext = () => {
  const context = useContext(AccountantsContext)

  if (!context) {
    throw new Error(
      'useAccountantsContext must be used within a AccountantsContext'
    )
  }

  return context
}

export default AccountantsContextProvider
