// import _ from 'lodash';
import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";

import Product, { ProductForm } from "models/product";

const route = process.env.REACT_APP_APIURL || "/api/";

export const getProductsList = async (workspaceId: number): Promise<Product[]> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${route}/workspaces/${workspaceId}/items`);
    const list: Product[] = await petition.json();
    return list;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getProductById = async (workspaceId: number, productId: number): Promise<Product> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${route}/workspaces/${workspaceId}/items/${productId}`);
    const product: Product = await petition.json();
    return product;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const createProduct = async (workspaceId: number, form: ProductForm): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const body = {
      internalId: form.internalId,
      name: form.name,
      description: form.description,
      itemsatcode: `${form.itemSat?.value}`,
      unitsatcode: `${form.unitSat?.value}`,
      idClaveSat: form.itemSat?.idCatalogValues,
      idClaveUnitSat: form.unitSat?.idCatalogValues,
      price: form.price,
      categoryId: form.category?.idCategory,
      idObjImp: form.tax?.idCatalogValues
    }
    const petition = await client.post(`${route}/workspaces/${workspaceId}/items`, body);
    await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const updateProduct = async (workspaceId: number, productId: number, form: ProductForm): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const body = {
      internalId: form.internalId,
      name: form.name,
      description: form.description,
      itemsatcode: `${form.itemSat?.value}`,
      unitsatcode: `${form.unitSat?.value}`,
      idClaveSat: form.itemSat?.idCatalogValues,
      idClaveUnitSat: form.unitSat?.idCatalogValues,
      price: form.price,
      categoryId: form.category?.idCategory,
      idObjImp: form.tax?.idCatalogValues
    }
    const petition = await client.put(`${route}/workspaces/${workspaceId}/items/${productId}`, body);
    await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const deleteProduct = async (workspaceId: number, productId: number): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.delete(`${route}/workspaces/${workspaceId}/items/${productId}`);
    await petition.text();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}