import { useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Analytics from 'react-ga4'
import TagManager from 'react-gtm-module'
import { useDidMount, useDidUpdate } from 'rooks'
import _ from 'lodash'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useThunkDispatch } from 'models/thunk'
import { ROLES, ROUTES } from './constants'
import APIClient from 'lib/helpers/HTTPClient'
import { selectCreateWorkspaceFlag, selectUser } from 'lib/helpers/selectors'
import useDialog from 'hooks/Shared/useDialog'
import useGlobalEvents from 'hooks/Shared/useGlobalEvents'
import useEventSource from 'hooks/Shared/SSE/useEventSource'
import { getCatalogues } from 'actions/catalogues'
import { setGlobalLoader } from 'actions/loader'
import { getUser, logout, refreshSession, setDevice } from 'actions/user'
import { AsyncHubFC } from 'hubs/AsyncHub'
import LoaderHub from 'hubs/LoaderHub'
import PrivateHub from 'hubs/PrivateHub'
import RoleHub from 'hubs/RoleHub'
import RouteChangeTracker from 'hubs/RouteTrackerHub'
import AccountScreen from 'screens/Account/AccountScreen'
import AccountingScreen from 'screens/Accounting/AccountingScreen'
import AddCategoryScreen from 'screens/ProductsAndCategories/AddCategoryScreen/AddCategoryScreen'
import AddClientScreen from 'screens/Clients/AddClientScreen/AddClientScreen'
import AddComplementScreen from 'screens/PaymentComplements/AddComplementScreen/AddComplementScreen'
import AddCreditNoteScreen from 'screens/CreditNotes/AddCreditNoteScreen/AddCreditNoteScreen'
import AddInvoiceScreen from 'screens/Invoices/AddInvoiceScreen/AddInvoiceScreen'
import AddProductScreen from 'screens/ProductsAndCategories/AddProductScreen/AddProductScreen'
import AddRazonSocialScreen from 'screens/Emisores/AddRazonSocialScreen/AddRazonSocialScreen'
import AddWorkspaceScreen from 'screens/Workspaces/AddWorkspaceScreen/AddWorkspaceScreen'
import ClientsScreen from 'screens/Clients/ClientsScreen/ClientsScreen'
import ConciliationsScreen from 'screens/Conciliations/ConciliationsScreen'
import DashboardScreen from 'screens/Dashboard/DashboardScreen'
import DocumentRequestScreen from 'screens/DocumentRequest/DocumentRequestScreen'
import DocumentsScreen from 'screens/Documents/DocumentsScreen'
import EditCategoryScreen from 'screens/ProductsAndCategories/EditCategoryScreen/EditCategoryScreen'
import EditClientScreen from 'screens/Clients/EditClientScreen/EditClientScreen'
import EditEmployeeScreen from 'screens/Employees/EditEmployeeScreen/EditEmployeeScreen'
import EditProductScreen from 'screens/ProductsAndCategories/EditProductScreen/EditProductScreen'
import EditRazonSocialScreen from 'screens/Emisores/EditRazonSocialScreen/EditRazonSocialScreen'
import EditWorkspaceScreen from 'screens/Workspaces/EditWorkspaceScreen/EditWorkspaceScreen'
import EmployeesScreen from 'screens/Employees/EmployeesScreen/EmployeesScreen'
import InvoicesScreen from 'screens/Invoices/InvoicesScreen/InvoicesScreen'
import LoginScreen from 'screens/Login/LoginScreen'
import ManagementScreen from 'screens/Management/ManagementScreen'
import NewCreditNoteScreen from 'screens/NewCFDI/NewCreditNoteScreen/NewCreditNoteScreen'
import NewPaymentComplianceScreen from 'screens/NewCFDI/NewPaymentComplianceScreen/NewPaymentComplianceScreen'
import ProductsAndCategoriesScreen from 'screens/ProductsAndCategories/ProductsAndCategoriesScreen/ProductsAndCategoriesScreen'
import RecoveryScreen from 'screens/Recovery/RecoveryScreen'
import WorkspacesScreen from 'screens/Workspaces/WorkspacesScreen/WorkspacesScreen'
import Sidebar from 'containers/Sidebar/SidebarContainer'
import Activity from 'components/Shared/Activity/Activity'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import 'theme/style.scss'

const fpPromise = FingerprintJS.load()

const App = () => {
  const intl = useIntl()
  const dialog = useDialog()
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()

  const user = useSelector(selectUser)
  const isCreatingWorkspace = useSelector(selectCreateWorkspaceFlag)

  const [verification, setVerification] = useState<any>(null)

  const configureHTTPClient = async () => {
    dispatch(setGlobalLoader(true, 'Cargando información inicial'))
    // Toma el token almacenado en el store de Redux
    const token = _.get(user, 'session.access_token')
    const refresh = _.get(user, 'session.refresh_token')
    // Configuración inicial del cliente HTTP
    const host =
      process.env.REACT_APP_MIDDLEWARE_HOST || 'http://localhost:3000'
    const client = APIClient.getClient(host, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
      timeout: 300000,
    })

    const fp = await fpPromise
    const fingerprint = await fp.get()
    const visitorId = fingerprint.visitorId

    dispatch(setDevice(visitorId))

    client.setDevice(visitorId)
    client.setAuthorization(token, refresh)
    client.renegotiateOn([401], (refreshToken: any) => {
      return dispatch(refreshSession(refreshToken))
    })
    client.on([401], async () => {
      return thunkDispatch(logout())
    })
    client.on([504], async (response: any) => {
      const data = await response.json()
      console.error(data)
    })

    verify()
  }

  const getInitialInformation = async () => {
    try {
      const catalogues = await thunkDispatch(getCatalogues())
      setVerification(catalogues)
    } catch (err) {
      console.log(err)
    }
  }
  const verify = async () => {
    try {
      const sesion = dispatch(getUser())
      setVerification(sesion)
    } catch (err) {
      console.log(err)
    }
  }

  const getAdditionalClasses = () => {
    if (user.authorized && !isCreatingWorkspace) return 'logged'
    else if (user.authorized && isCreatingWorkspace) return 'creating-workspace'
    else return ''
  }

  const url = `${process.env.PUBLIC_URL}`
  const base = _.replace(url, '\r', '')

  useDidMount(() => {
    process.env.REACT_APP_ENVIRONMENT === 'development' &&
      Boolean(process.env.REACT_APP_GA_KEY) &&
      Analytics.initialize(`${process.env.REACT_APP_GA_KEY?.replace('\r', '')}`)
    Boolean(process.env.REACT_APP_GTM_KEY) &&
      TagManager.initialize({
        gtmId: `${process.env.REACT_APP_GTM_KEY?.replace('\r', '')}`,
      })
    configureHTTPClient()
  })

  useDidUpdate(() => {
    if (!user.authorized && !user.manualLogout) {
      dialog.danger({
        subtitle: 'Hemos cerrado su sesión por inactividad.',
        title: '¿Sigue ahí?',
        primaryButtonText: 'Cerrar',
      })
    } else if (user.authorized) {
      getInitialInformation()
    }
  }, [user.authorized])

  useGlobalEvents()
  useEventSource()

  return (
    <AsyncHubFC
      promise={verification}
      pending={
        <Activity size={60} color="primary" message="Validando sesión" />
      }>
      <ToastContainer />
      <LoaderHub />
      <div key={intl.locale}>
        <BrowserRouter basename={base}>
          <RouteChangeTracker />
          <div className="app">
            <Header />
            <Sidebar />
            <div className={`screen-wrapper ${getAdditionalClasses()}`}>
              <Routes>
                <Route
                  path={ROUTES.ROOT}
                  element={<Navigate to={ROUTES.LOGIN} replace />}
                />
                <Route
                  path={ROUTES.LOGIN}
                  element={
                    <PrivateHub inverse>
                      <LoginScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.REGISTER}
                  element={
                    <PrivateHub inverse>
                      <LoginScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.RECOVERY}
                  element={
                    <PrivateHub inverse>
                      <RecoveryScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.HOME}
                  element={
                    <PrivateHub>
                      <DashboardScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ACCOUNT}
                  element={
                    <PrivateHub>
                      <AccountScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.WORKSPACES}
                  element={
                    <PrivateHub>
                      <WorkspacesScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_WORKSPACE}
                  element={
                    <PrivateHub>
                      <AddWorkspaceScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.WORKSPACE_DETAILS}
                  element={
                    <PrivateHub>
                      <EditWorkspaceScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.CLIENTS}
                  element={
                    <PrivateHub>
                      <ClientsScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_CLIENT}
                  element={
                    <PrivateHub>
                      <AddClientScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.CLIENT_DETAILS}
                  element={
                    <PrivateHub>
                      <EditClientScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.CATEGORIES_PRODUCTS}
                  element={
                    <PrivateHub>
                      <ProductsAndCategoriesScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_PRODUCT}
                  element={
                    <PrivateHub>
                      <AddProductScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.PRODUCT_DETAILS}
                  element={
                    <PrivateHub>
                      <EditProductScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_CATEGORY}
                  element={
                    <PrivateHub>
                      <AddCategoryScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.CATEGORY_DETAILS}
                  element={
                    <PrivateHub>
                      <EditCategoryScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_RAZON_SOCIAL}
                  element={
                    <PrivateHub>
                      <AddRazonSocialScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.RAZON_SOCIAL_DETAILS}
                  element={
                    <PrivateHub>
                      <EditRazonSocialScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.INVOICES}
                  element={
                    <PrivateHub>
                      <InvoicesScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_INVOICE}
                  element={
                    <PrivateHub>
                      <AddInvoiceScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_PAYMENT_COMPLEMENT}
                  element={
                    <PrivateHub>
                      <AddComplementScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_CREDIT_NOTE}
                  element={
                    <PrivateHub>
                      <AddCreditNoteScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.DOCUMENTATION}
                  element={
                    <PrivateHub>
                      <DocumentsScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.ADD_ACCOUNTING}
                  element={
                    <PrivateHub>
                      <AccountingScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path={ROUTES.DOCUMENT_REQUEST}
                  element={
                    <PrivateHub>
                      <DocumentRequestScreen />
                    </PrivateHub>
                  }
                />
                {/** Admin Routes */}
                <Route
                  path={ROUTES.CONCILIATIONS}
                  element={
                    <RoleHub
                      roles={[
                        ROLES.ADMINISTRADOR,
                        ROLES.CONTADOR_MR,
                        ROLES.CONTADOR_JR,
                        ROLES.CONTADOR_SR,
                      ]}>
                      <ConciliationsScreen />
                    </RoleHub>
                  }
                />
                <Route
                  path={ROUTES.EMPLOYEES}
                  element={
                    <RoleHub roles={[ROLES.ADMINISTRADOR, ROLES.CONTADOR_MR]}>
                      <EmployeesScreen />
                    </RoleHub>
                  }
                />
                <Route
                  path={ROUTES.MANAGEMENT}
                  element={
                    <RoleHub roles={[ROLES.ADMINISTRADOR, ROLES.CONTADOR_MR]}>
                      <ManagementScreen />
                    </RoleHub>
                  }
                />
                <Route
                  path={ROUTES.EMPLOYEE_DETAILS}
                  element={
                    <RoleHub roles={[ROLES.ADMINISTRADOR, ROLES.CONTADOR_MR]}>
                      <EditEmployeeScreen />
                    </RoleHub>
                  }
                />
                {/** Redirect to default */}
                {/* Credit Note from CFDI */}
                <Route
                  path={ROUTES.NEW_CREDIT_NOTE_FROM_INVOICE}
                  element={
                    <PrivateHub>
                      <NewCreditNoteScreen />
                    </PrivateHub>
                  }
                />
                {/* Payment Compliance from CFDI */}
                <Route
                  path={ROUTES.NEW_PAYMENT_COMPLEMENT_FROM_INVOICE}
                  element={
                    <PrivateHub>
                      <NewPaymentComplianceScreen />
                    </PrivateHub>
                  }
                />
                <Route
                  path="*"
                  element={<Navigate to={ROUTES.ROOT} replace />}
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </AsyncHubFC>
  )
}

export default App
