import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Activity from 'components/Shared/Activity/Activity'
import Button from 'components/Shared/Button/Button'
import Dropdown from 'components/FormComponents/Dropdown/Dropdown'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import Checkbox from 'components/FormComponents/IOComponents/Checkbox/Checkbox'
// import SelectField from 'components/IOComponents/SelectField';

import { useClient, useUpdateClient } from 'hooks/queries/clients'

import { setSelectedClient } from 'actions/clients'
import { selectCatalogue, selectSelectedClient } from 'lib/helpers/selectors'

import './style.scss'

import { ClientForm } from 'models/client'
import { CatalogueEntry } from 'models/catalogues'

interface Props {
  id: number
  workspaceId: number
  onRedirect: (action: string) => void
}

const EditClientContainer: React.FC<Props> = ({
  id,
  workspaceId,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()

  const tiposPersona = useSelector(selectCatalogue('tiposPersona'))
  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))

  const client = useSelector(selectSelectedClient) || undefined

  const [personType, setPersonType] = useState<CatalogueEntry>()
  const [isForeign, setIsForeign] = useState<boolean>()
  const [valid, setValid] = useState(false)

  const clientQuery = useClient(workspaceId, id, {
    onSuccess: c => {
      setPersonType(c.type)
      setIsForeign(c.foreign)
    },
    onError: () => onRedirect('error'),
  })

  const updateMutation = useUpdateClient(workspaceId, client, {
    onSuccess: () => onRedirect('success'),
  })

  useEffect(() => {
    clientQuery.isLoading
      ? loader.primary('Cargando información del cliente')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQuery.isLoading])
  useEffect(
    () => () => {
      dispatch(setSelectedClient())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Card elevation={1} className="container-edit-client">
        <Form
          onSubmit={async (form: ClientForm) => updateMutation.mutate(form)}
          onValidity={(v: boolean) => setValid(v)}>
          <div className="description">
            <p>
              Puedes editar la siguiente información para actualizar este
              cliente
            </p>
            {clientQuery.isFetching && <Activity size={22} local />}
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <AutocompleteField
                  name="type"
                  label={'Tipo de persona'}
                  catalogue={tiposPersona}
                  value={client?.type}
                  onValue={(v: CatalogueEntry) => setPersonType(v)}
                  onClear={() => setPersonType(undefined)}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={4} alignItems="center">
                <Checkbox
                  name="foreign"
                  label="Es extranjero"
                  onValue={(value: any) => setIsForeign(value)}
                  value={isForeign}
                />
              </Grid>
              <Grid item xs={4}>
                {isForeign && (
                  <TextField
                    name="taxPayerId"
                    label={'Tax Payer Identification Number'}
                    value={client?.taxPayerId}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    required
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="alias"
                  label={'Alias (opcional)'}
                  value={client?.alias}
                  fullWidth
                  disabled={_.isNil(personType)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  label={'Nombre o razón social'}
                  value={client?.name}
                  fullWidth
                  required
                  disabled={_.isNil(personType)}
                />
              </Grid>
              {personType?.idCatalogValues === 2 && (
                <Grid item xs={4}>
                  <TextField
                    name="rfc"
                    label={'RFC'}
                    validate={['isGenericOrCompanyRFC']}
                    defaultValue={client?.rfc}
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                    required
                    disabled={_.isNil(personType)}
                  />
                </Grid>
              )}
              {personType?.idCatalogValues === 1 && (
                <Grid item xs={4}>
                  <TextField
                    name="rfc"
                    label={'RFC'}
                    validate={['isGenericOrPersonRFC']}
                    defaultValue={client?.rfc}
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                    required
                    disabled={_.isNil(personType)}
                  />
                </Grid>
              )}
              {personType?.idCatalogValues === 2 && (
                <Grid item xs={8}>
                  <AutocompleteField
                    name="taxResidence"
                    label={'Régimen Fiscal'}
                    catalogue={taxSystemMoral}
                    value={client?.taxRegime}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    fullWidth
                    required
                    disabled={_.isNil(personType)}
                  />
                </Grid>
              )}
              {personType?.idCatalogValues !== 2 && (
                <Grid item xs={8}>
                  <AutocompleteField
                    name="taxResidence"
                    label={'Régimen Fiscal'}
                    catalogue={taxSystemFisica}
                    value={client?.taxRegime}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    fullWidth
                    required
                    disabled={_.isNil(personType)}
                  />
                </Grid>
              )}
              <Grid item xs={4} />
              <Grid item xs={4}>
                <TextField
                  name="email"
                  label={'Correo electrónico'}
                  value={client?.email}
                  validate={['isEmail', 'isEmailFormat']}
                  fullWidth
                  required
                  disabled={_.isNil(personType)}
                />
              </Grid>
              <Grid item xs={4}>
                <NumericField
                  name="phone"
                  label={'Teléfono (opcional)'}
                  value={client?.phone}
                  format="phone"
                  fullWidth
                  disabled={_.isNil(personType)}
                />
              </Grid>
            </Grid>
            <p className="form-header">Dirección</p>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  fieldType="number"
                  name="zipCode"
                  label={'Código postal'}
                  value={_.padStart(client?.zipCode, 5, '0')}
                  validate={['isZipCode']}
                  inputProps={{ maxLength: 5 }}
                  fullWidth
                  required
                  disabled={_.isNil(personType)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="branch"
                  label={'Nombre sucursal (opcional)'}
                  value={client?.branch}
                  fullWidth
                  disabled={_.isNil(personType)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="contact"
                  label={'Contacto (opcional)'}
                  value={client?.contact}
                  fullWidth
                  disabled={_.isNil(personType)}
                />
              </Grid>
              {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                <Grid item xs={12}>
                  <Dropdown
                    title={'Dirección completa (opcional)'}
                    bordered
                    disabled>
                    <></>
                  </Dropdown>
                </Grid>
              )}
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="updateClientBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={updateMutation.isLoading}
              disabled={!valid}>
              Actualizar
            </Button>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default EditClientContainer
