import React from 'react'
import { useDidUpdate } from 'rooks'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import { resetForm } from 'actions/forms'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'
import AddIncomeContainer from 'containers/Invoices/3.3/AddIncomeContainer/AddIncomeContainer'
import AddIncomeV2Container from 'containers/Invoices/4.0/AddIncomeV2Container/AddIncomeV2Container'

interface Props {}

const AddInvoiceScreen: React.FC<Props> = props => {
  const workspaceId = useSelector(selectActiveWorkspace)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { type } = useParams()

  const version = params.get('version') || '3.3'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('cancel')
  }, [workspaceId])

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error') navigate(ROUTES.INVOICES, { replace: true })
    if (action === 'cancel' || action === 'success')
      dispatch(resetForm('createInvoice'))
    navigate(ROUTES.INVOICES)
    if (action === 'newClient') navigate(ROUTES.ADD_CLIENT)
  }

  if (!type || ['income', 'expense', '3', '4', '5', '6'].indexOf(type) === -1) {
    return <Navigate to={ROUTES.INVOICES} replace />
  }

  if (!workspaceId) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-add-invoice">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Nuevo Comprobante de Ingresos {version}</h1>
            <Button
              id="cancelNewCfdiBtn"
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          {type === 'income' && version === '3.3' && (
            <AddIncomeContainer
              workspaceId={workspaceId}
              onRedirect={onRouteChange}
            />
          )}
          {type === 'income' && version === '4.0' && (
            <AddIncomeV2Container
              workspaceId={workspaceId}
              onRedirect={onRouteChange}
            />
          )}
          {type === 'expense' && <></>}
          {type === '3' && <></>}
          {type === '4' && <></>}
          {type === '5' && <></>}
          {type === '6' && <></>}
        </Grid>
      </Grid>
    </>
  )
}

export default AddInvoiceScreen
