import { Fragment, useMemo, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  downloadFileFromData,
  formatBase64DataAsDataURL,
  toCurrency,
  toPeriodStringV2,
} from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { generateConciliationReport } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './styles.scss'
import ISydDocument from 'models/shared.models'

const Conciliated = () => {
  const { conciliations } = useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  return (
    <Typography className="item">
      {conciliationRows.length} movimientos conciliados
    </Typography>
  )
}

const TotalConciliated = () => {
  const { conciliations } = useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  const total = useMemo(
    () =>
      conciliationRows.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
    [conciliationRows]
  )
  return (
    <Typography className="item">
      {toCurrency(total)} conciliado - total
    </Typography>
  )
}

const TotalConciliatedDeposits = () => {
  const { conciliations } = useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  const total = useMemo(
    () =>
      conciliationRows
        .filter(conciliation => conciliation.amount > 0)
        .reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
    [conciliationRows]
  )
  return (
    <Typography className="item">
      {toCurrency(total)} conciliado - depósitos
    </Typography>
  )
}

const TotalConciliatedWithdrawals = () => {
  const { conciliations } = useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  const total = useMemo(
    () =>
      conciliationRows
        .filter(conciliation => conciliation.amount < 0)
        .reduce((acc, curr) => acc + curr.amount, 0),
    [conciliationRows]
  )
  return (
    <Typography className="item">
      {toCurrency(total)} conciliado - retiros
    </Typography>
  )
}

const TotalUnconciliated = () => {
  const { statements } = useConciliationsContainerContext()

  const { statementRows, activeStatementRow } = statements

  const total = useMemo(() => {
    const selectedStatement = statementRows.find(
      statement => statement.idFileBankStatement === activeStatementRow
    )
    if (!selectedStatement) return 0
    return selectedStatement.transactions.reduce((acc, curr) => {
      if (curr.conciliated) return acc
      if (curr.deposit) return acc + curr.deposit
      return acc + curr.withdrawal
    }, 0)
  }, [activeStatementRow, statementRows])

  return (
    <Typography className="item">{toCurrency(total)} sin conciliar</Typography>
  )
}

const GenerateReportButton = () => {
  const { workspaceInfo } = useConciliationsContainerContext()

  const { conciliationPeriod, conciliations } =
    useConciliationsContainerContext()

  const { conciliationRows } = conciliations

  const count = conciliationRows.length

  const dialog = useDialog()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => setIsOpen(!isOpen)

  if (count === 0) {
    return <Fragment />
  }

  const handleGenerate = async () => {
    setIsLoading(true)
    try {
      const data = await generateConciliationReport(
        workspaceInfo.activeWorkspace,
        toPeriodStringV2(conciliationPeriod, 'YYYY/MM/DD')
      )
      const file = JSON.parse(await data.text()) as ISydDocument
      file.file &&
        downloadFileFromData(
          `${file.name}.${file.extension}`,
          formatBase64DataAsDataURL(file.file)
        )
      setIsLoading(false)
      await dialog.primaryAsync({
        title: 'Reporte generado',
        subtitle: 'Se ha generado el reporte',
        primaryButtonText: 'Continuar',
      })
      toggleIsOpen()
    } catch (error) {
      setIsLoading(false)
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido generar el reporte',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const handleClose = (e: any, reason: string) => {
    if (reason === 'backdropClick' && isLoading) return
    toggleIsOpen()
  }

  return (
    <Fragment>
      <Button
        disableElevation
        className="generate-report-button"
        onClick={toggleIsOpen}>
        Generar reporte
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="generate-report-dialog">
        <DialogTitle className="title">
          <Typography variant="h5">Generar reportes</Typography>
        </DialogTitle>
        <DialogContent className="content">
          <DialogContentText className="text">
            <Typography className="item">Resumen</Typography>
            <Conciliated />
            <TotalConciliated />
            <TotalConciliatedDeposits />
            <TotalConciliatedWithdrawals />
            <TotalUnconciliated />
            <Typography className="item">
              ¿Deseas continuar a generar el reporte?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <Button
            className="cancel"
            variant="outlined"
            onClick={toggleIsOpen}
            disabled={isLoading}>
            Volver
          </Button>
          <Button
            className="confirm"
            variant="contained"
            disableElevation
            onClick={handleGenerate}
            disabled={isLoading}>
            Generar reportes
            {isLoading && <CircularProgress size={14} className="spinner" />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default GenerateReportButton
