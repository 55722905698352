import { Grid } from '@material-ui/core';
import { FC, createContext, useEffect, useMemo, useState } from 'react';
import CFDIHistoryToolbar from '../CFDIHistoryToolbar/CFDIHistoryToolbar';
import CFDIHistoryTable from '../CFDIHistoryTable/CFDIHistoryTable';

type PeriodType = {
  year: number;
  month: number;
}

type FileType = {
  id: number,
  idWsfile: number;
  name: string;
  period: string;
};

export interface CFDIHistoryContextProps {
  period: PeriodType,
  setPeriod: (period: PeriodType) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  files: FileType[];
  setFiles: (files: FileType[]) => void;
}

export const CFDIHistoryContext = createContext<CFDIHistoryContextProps>({} as CFDIHistoryContextProps);

const CFDIHistory: FC = () => {
  const [period, setPeriod] = useState<PeriodType>({ year: -1, month: -1 });
  const [files, setFiles] = useState<FileType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(
    () => {
      const date = new Date();
      const currentPeriod = {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      };
      setPeriod(currentPeriod);
    },
    []
  );

  const valueToProvide = useMemo(
    () => ({
      period,
      setPeriod,
      isLoading,
      setIsLoading,
      files,
      setFiles
    }),
    [period, isLoading, files]
  );

  return (
    <CFDIHistoryContext.Provider value={valueToProvide}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <CFDIHistoryToolbar />
          <CFDIHistoryTable />
        </Grid>
      </Grid>
    </CFDIHistoryContext.Provider>
  );
};

export default CFDIHistory;