import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import ConciliationTableFilter from '../ConciliationTableFilter/ConciliationTableFilter'

const CFDIFilters = () => {
  const { cfdis } = useConciliationsContainerContext()

  const { cfdiFilters, setConciliationCfdiFilters } = cfdis

  const dispatch = useDispatch()

  const handleFilterChange = (values: any) => {
    dispatch(
      setConciliationCfdiFilters({
        ...cfdiFilters,
        text: values.name,
        fromDate: values.fromDate,
        toDate: values.toDate,
        status: values.status,
        rfc: values.rfc,
      })
    )
  }

  const fields = useMemo(
    () => ({
      name: {
        name: 'name',
        placeholder: 'Buscar por RFC, por UUID o monto',
        enabled: true,
        defaultValue: cfdiFilters.text,
      },
      fromDate: {
        name: 'fromDate',
        enabled: true,
        defaultValue: cfdiFilters.fromDate,
      },
      toDate: {
        name: 'toDate',
        enabled: true,
        defaultValue: cfdiFilters.toDate,
      },
      status: {
        name: 'status',
        placeholder: 'Estatus CFDI',
        enabled: true,
        defaultValue: cfdiFilters.status || '',
        options: [
          { label: 'Todos', value: '' },
          { label: 'Pendiente', value: 'pending' },
          { label: 'Cobrada', value: 'charged' },
          { label: 'Cobrada parcialmente', value: 'part-charged' },
          { label: 'Pagada', value: 'paid' },
          { label: 'Pagada parcialmente', value: 'part-paid' },
          { label: 'Cancelada', value: 'canceled' },
          { label: 'Pendiente de cancelar', value: 'pending-canceled' },
        ],
      },
    }),
    [cfdiFilters]
  )
  return (
    <Box marginTop={2}>
      <ConciliationTableFilter onChange={handleFilterChange} fields={fields} />
    </Box>
  )
}

export default CFDIFilters
