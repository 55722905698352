import messagesEN from './reactIntlMessages-en-US.json';
import messagesES from './reactIntlMessages-es-MX.json';

export const AvailableLanguages = {
	EN: ['en', 'en-us'],
	ES: ['es', 'es-mx']
};

export const getMessages = (code: string = '') => {
	let messages = null;
	switch (code.toLowerCase()) {
		case 'en' :
		case 'en-us' :
			messages = messagesEN;
			break;
		case 'es':
        case 'es-419':
		case 'es-mx':
		default :
			messages = messagesES;
			break;
	}
	return messages;
};