import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import {
  createCategory,
  deleteCategory,
  getCategoriesList,
  getCategoryById,
  updateCategory,
} from 'services/categories'
import { setCategoriesList, setSelectedCategory } from 'actions/categories'

import Help from '@material-ui/icons/Help'
import ErrorI from '@material-ui/icons/ErrorOutline'

import Category, { CategoryForm } from 'models/category'
import { QueryOptions, MutationOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

const useCategoriesList = (
  workspaceId: number,
  options: QueryOptions<Category[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Category[], any>(
    ['get-categories-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCategoriesList(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setCategoriesList(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de Categorías.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useCategory = (
  workspaceId: number,
  categoryId: number,
  options: QueryOptions<Category> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Category, any>(
    ['get-category', categoryId, workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCategoryById(workspaceId, categoryId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setSelectedCategory(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información de la categoría.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useAddCategory = (
  workspaceId: number,
  options: MutationOptions<void, CategoryForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, CategoryForm>(
    form => {
      onBefore && onBefore(form)
      return createCategory(workspaceId, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo agregar esta categoría.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useUpdateCategory = (
  workspaceId: number,
  category?: Category,
  options: MutationOptions<void, CategoryForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, CategoryForm>(
    form => {
      if (!category) throw new Error('Categoría cargada incorrectamente')
      onBefore && onBefore(form)
      return updateCategory(workspaceId, category.idCategory, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'Categoría cargada incorrectamente') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar la información de la categoría seleccionada.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useDeleteCategory = (
  workspaceId: number,
  options: MutationOptions<void, Category> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<void, any, Category>(
    async category => {
      const resp = await dialog.dangerAsync({
        title: '¿Estás seguro?',
        subtitle: 'Estás a punto de eliminar esta categoría.',
        icon: Help as TModalIcon,
        primaryButtonText: 'Sí, eliminar',
        secondaryButtonText: 'Cancelar',
      })
      if (resp) {
        showAdditionalInformationLoading()
        return await deleteCategory(workspaceId, category.idCategory)
      }
      if (!resp) throw new Error('mutation-cancelled')
    },
    {
      onSuccess: (data, variables, context) => {
        dialog.close()
        onSuccess && onSuccess(data, variables, context)
      },
      onError: async (error, category, ctx) => {
        if (error.message === 'mutation-cancelled') return

        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }
          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudo eliminar la categoría seleccionada.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(category)
              return
            }
          }

          onError && onError(error, category, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export default useCategoriesList
