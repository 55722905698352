import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
//import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from 'components/Shared/Button/Button'
import Dropdown from 'components/FormComponents/Dropdown/Dropdown'
import ScrollSpy from 'components/Invoices/ScrollSpy/ScrollSpy'
import { DisplayTotalDetails } from 'components/Invoices/DisplayTotal/DisplayTotal'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import AddConceptContainer from '../AddConceptContainer/AddConceptContainer'

import { getClientsList } from 'actions/clients'
import { getProductsList } from 'actions/products'
import { getCategoriesList } from 'actions/categories'
import { setLocalLoader } from 'actions/loader'
import { getRazonesSocialesList } from 'actions/razonesSociales'
import {
  createConcept,
  createIncomeInvoice,
  getSerieFolioHistorial,
  getTaxCatalogue,
} from 'actions/invoices'
import { storeFormValue } from 'actions/forms'

import {
  selectCatalogue,
  selectClientsList,
  selectCreateInvoice,
  selectProductsList,
  selectRazonesSocialesList,
} from 'lib/helpers/selectors'
// import { getPersonType } from 'lib/helpers/utilities';

import Add from '@material-ui/icons/Add'
import Error from '@material-ui/icons/ErrorOutline'
import './style.scss'

// import { Persona } from 'models/general';
import Client, { ClientForm } from 'models/client'
import RazonSocial from 'models/razonSocial'
import Product from 'models/product'
import { Concept, TaxCatalogue } from 'models/invoice'
import { CatalogueEntry } from 'models/catalogues'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

interface Props {
  workspaceId: number
  onRedirect: (action: string) => void
}

const AddIncomeContainer: React.FC<Props> = ({ workspaceId, ...props }) => {
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()

  const clients = useSelector(selectClientsList)
  const emisores = useSelector(selectRazonesSocialesList)
  const products = useSelector(selectProductsList)
  const form = useSelector(selectCreateInvoice)
  const tiposComprobantes = useSelector(selectCatalogue('tiposComprobantes'))
  // const taxSystemFisica = useSelector(selectCatalogue("regimenesFisica"));
  // const taxSystemMoral = useSelector(selectCatalogue("regimenesMoral"));
  const usosCfdi = useSelector(selectCatalogue('usosCfdi'))
  const monedas = useSelector(selectCatalogue('monedas'))
  const metodosPago = useSelector(selectCatalogue('metodosPago'))
  const formasPago = useSelector(selectCatalogue('formasPago'))
  // const unidades = useSelector(selectCatalogue("unidades"));

  const [loading, setLoading] = useState(false)
  const [valid, setValid] = useState(false)
  const [taxes, setTaxes] = useState<TaxCatalogue>([])
  const [lastFolio, setLastFolio] = useState('')
  const [serieCatalog, setSerieCatalog] = useState<{}[]>([])

  const getInformation = async () => {
    dispatch(setLocalLoader(true, 'Cargando catálogos personalizados'))
    try {
      const req = await Promise.all([
        thunkDispatch(getTaxCatalogue()),
        thunkDispatch(getClientsList(workspaceId)),
        thunkDispatch(getRazonesSocialesList(workspaceId)),
        thunkDispatch(getProductsList(workspaceId)),
        thunkDispatch(getCategoriesList(workspaceId)),
      ])
      setTaxes(req[0])
      dispatch(setLocalLoader(false))
      if (form.emisor?.idRfc) {
        getFolios(form.emisor)
      }
    } catch (err) {
      console.log(err)
      const rp = await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener la información de los catálogos. Intenta mas tarde.',
        icon: Error as TModalIcon,
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })
      if (rp) {
        dispatch(setLocalLoader(false))
        getInformation()
      } else {
        dispatch(setLocalLoader(false))
        props.onRedirect('cancel')
      }
    }
  }

  const saveValueToStore = useCallback(
    (name: string, value: any) => {
      dispatch(storeFormValue('createInvoice', name, value))
    },
    [dispatch]
  )

  const getFolios = useCallback(
    async emisor => {
      try {
        const req = await thunkDispatch(
          getSerieFolioHistorial(workspaceId, emisor.idRfc)
        )
        const serieCatalog = req.map((element: any) => {
          return {
            value: element.serie,
            label: element.serie,
            folio: element.folio,
          }
        })
        setSerieCatalog(serieCatalog)
      } catch (err) {
        console.log(err)
      }
    },
    [thunkDispatch, workspaceId]
  )

  const handleNewSerie = useCallback(
    (serie: any) => {
      if (!_.isNil(serie)) {
        const newSerie = { value: serie.value, label: serie.value, folio: '' }
        saveValueToStore('serie', newSerie)
        setSerieCatalog([...serieCatalog, newSerie])
        setLastFolio('')
      } else {
        saveValueToStore('serie', null)
        setLastFolio('')
      }
    },
    [saveValueToStore, serieCatalog]
  )

  const suggestFolio = useCallback((folio: string) => {
    const onlyNumber = /\D/
    if (!onlyNumber.test(folio)) {
      const length = folio.toString().length
      const nextNumber = parseFloat(folio) + 1
      const suggest = nextNumber.toString().padStart(length, '0')
      return suggest
    } else {
      return null
    }
  }, [])

  const setSerie = useCallback(
    (actualSerie: any) => {
      const actual: any = serieCatalog.find(
        (serie: any) => serie.value === actualSerie.value
      )
      if (!_.isNil(actualSerie) && !_.isNil(actual)) {
        saveValueToStore('serie', actualSerie)
        const suggest = suggestFolio(actualSerie.folio)
        if (!_.isNil(suggest)) {
          saveValueToStore('folio', suggest)
          setLastFolio(actualSerie.folio)
        } else {
          saveValueToStore('folio', '')
          setLastFolio(actualSerie.folio)
        }
      }
    },
    [saveValueToStore, serieCatalog, suggestFolio]
  )

  const handleCleanSerie = useCallback(
    (value: any) => {
      if (_.isNil(value)) {
        saveValueToStore('serie', null)
        saveValueToStore('folio', '')
        setLastFolio('')
      }
    },
    [saveValueToStore]
  )

  const onAdd = async (form: ClientForm) => {
    setLoading(true)
    try {
      await thunkDispatch(createIncomeInvoice(workspaceId))
      props.onRedirect('success')
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo crear el CFDI. Intenta nuevamente.',
        icon: Error as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  const clientFilter = (clients: Client[], value: string) => {
    if (!value) return clients
    const search = value.toLowerCase()
    const filtered = _.filter(clients, c => {
      const likeAlias = c.alias?.toLowerCase().indexOf(search) > -1
      const likeName = c.name?.toLowerCase().indexOf(search) > -1
      const likeRFC = c.rfc?.toLowerCase().indexOf(search) > -1
      return likeAlias || likeName || likeRFC
    })
    return filtered
  }

  const renderClientOption = (o: Client) => (
    <div className="client-option">
      <div className="alias">{o.alias}</div>
      <div className="rfc">{_.toUpper(o.rfc)}</div>
    </div>
  )

  const renderEmisorOption = (o: any) => {
    const withCsd = Boolean(o.passwordCsd)
    return (
      <>
        <div className={`client-option ${!withCsd ? 'disabled' : ''}`.trim()}>
          <div className="alias">{o.name}</div>
          <div className="rfc">{_.toUpper(o.rfc)}</div>
          {!withCsd && (
            <>
              <div className="break" />
              <div className="advice">Este emisor no tiene CSD</div>
            </>
          )}
        </div>
      </>
    )
  }

  const renderSerieOption = useCallback((o: any) => {
    return (
      <>
        <div className={`serie-option`.trim()}>
          <div className="name">{o.value}</div>
        </div>
      </>
    )
  }, [])

  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  const renderNewOption = useCallback(
    (o: any, text: string) => (
      <div className="new-option">
        <Add />
        <div className="new-text">{text}</div>
      </div>
    ),
    []
  )

  const addConcept = (p?: Product) => {
    const concept: Concept = {
      uuid: uuid(),
      satKey: p?.claveSat || null,
      claveSat: p?.claveSat || null,
      claveUnitSat: p?.claveUnitSat || null,
      price: `${p?.price || ''}`,
      description: p?.description || '',
      cantidad: '1',
      descuento: '',
      impuestos: [],
      importacion: '',
      predial: '',
    }
    dispatch(createConcept(concept))
  }

  const setEmisor = useCallback(
    (value: RazonSocial) => {
      saveValueToStore('emisor', value)
      if (!_.isNil(value)) {
        getFolios(value)
      }
    },
    [getFolios, saveValueToStore]
  )

  useEffect(() => {
    getInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Se encarga de poner los valores por defecto a ciertos campos.
  useEffect(() => {
    if (_.isNil(form.tipo)) {
      const income = _.filter(tiposComprobantes, m => m.value === 'I')[0]
      saveValueToStore('tipo', income)
    }
    if (_.isNil(form.moneda)) {
      const mxn = _.filter(monedas, m => m.value === 'MXN')[0]
      saveValueToStore('moneda', mxn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card elevation={1} className="container-add-invoice">
      <div className="scrollspy-container">
        <ScrollSpy
          items={[
            { id: 'section-1', label: '1', tooltip: 'Información del emisor' },
            {
              id: 'section-2',
              label: '2',
              tooltip: 'Información del receptor',
            },
            { id: 'section-3', label: '3', tooltip: 'CFDI' },
            { id: 'section-4', label: '4', tooltip: 'Conceptos' },
            { id: 'section-5', label: '5', tooltip: 'Serie' },
          ]}
        />
      </div>
      <div className="form-container">
        <Form onSubmit={onAdd} onValidity={(v: boolean) => setValid(v)}>
          {/* Emisor */}
          <div id="section-1" className="content">
            <h3>(1) Información del emisor</h3>
            <Divider className="separator" />
            <p className="form-label-alternative">Selecciona el emisor</p>
            <Grid container>
              <Grid item xs={5}>
                <AutocompleteField
                  name="emisor"
                  placeholder="Busca por alias, nombre, razón social o RFC"
                  catalogue={emisores}
                  value={form?.emisor}
                  onValue={(v: RazonSocial) => setEmisor(v)}
                  getOptionLabel={(o: any) => o.alias || o.name}
                  getOptionDisabled={(o: RazonSocial) =>
                    !Boolean(o.passwordCsd)
                  }
                  renderOption={(o: any) => renderEmisorOption(o)}
                  getOptionSelected={(
                    option: RazonSocial,
                    value: RazonSocial
                  ) => option.idRfc === value.idRfc}
                  fullWidth
                />
              </Grid>
              {!_.isEmpty(form.emisor) && (
                <Grid item xs={12}>
                  <div className="emisor-info">
                    <Grid container spacing={4}>
                      <Grid item xs={3}>
                        <TextField
                          name="emisor_rfc"
                          label="RFC"
                          value={form.emisor?.rfc}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{
                            readOnly: true,
                            style: { textTransform: 'uppercase' },
                          }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="emisor_name"
                          label="Nombre o razón social"
                          value={form.emisor?.name}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="emisor_taxResidence"
                          label="Régimen fiscal"
                          value={form?.emisor?.taxResidence?.label}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          exclude
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          {/* Receptor */}
          <div id="section-2" className="content">
            <h3>(2) Información del receptor</h3>
            <Divider className="separator" />
            <p className="form-label-alternative">
              Seleccionalo desde tu catálogo de clientes
            </p>
            <Grid container>
              <Grid item xs={5}>
                <AutocompleteField
                  name="receptor"
                  placeholder="Busca por alias, nombre, razón social o RFC"
                  catalogue={clients}
                  value={form?.receptor}
                  onValue={(v: Client) => saveValueToStore('receptor', v)}
                  onNewValue={() => props.onRedirect('newClient')}
                  getOptionLabel={(o: any) => o.alias || o.name}
                  customFilter={clientFilter}
                  renderOption={(o: any) =>
                    o.flag === 'newValue'
                      ? renderNewOption(o, 'AGREGAR NUEVO CLIENTE')
                      : renderClientOption(o)
                  }
                  getOptionSelected={(option: Client, value: Client) =>
                    option.id === value.id
                  }
                  fullWidth
                />
              </Grid>
              {!_.isEmpty(form.receptor) && (
                <Grid item xs={12}>
                  <div className="receptor-info">
                    <Grid container spacing={4}>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_rfc"
                          label="RFC"
                          value={form.receptor?.rfc}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{
                            readOnly: true,
                            style: { textTransform: 'uppercase' },
                          }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_name"
                          label="Nombre o razón social"
                          value={form.receptor?.name}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_email"
                          label="Correo electrónico"
                          value={form.receptor?.email}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_phone"
                          label="Teléfono"
                          value={form.receptor?.phone}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <p className="form-header">Dirección</p>
                    <Grid container spacing={4}>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_zipCode"
                          label="Código postal"
                          value={form.receptor?.zipCode || '---'}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_branch"
                          label="Nombre sucursal"
                          value={form.receptor?.branch || '---'}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="receptor_contant"
                          label="Contacto"
                          value={form.receptor?.contact || '---'}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ readOnly: true }}
                          exclude
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Dropdown
                          title={'Dirección completa (opcional)'}
                          bordered
                          disabled>
                          <></>
                        </Dropdown>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          {/* CFDI */}
          <div id="section-3" className="content">
            <h3>(3) CFDI</h3>
            <Divider className="separator" />
            <Grid container spacing={4}>
              {/* <Grid item xs={4}>
                                <AutocompleteField
                                    name="expedicion"
                                    label="Lugar de expedición (matriz o sucursal)"
                                    catalogue={[]}
                                    fullWidth
                                />
                            </Grid> */}
              <Grid item xs={4}>
                <AutocompleteField
                  name="moneda"
                  label="Moneda"
                  catalogue={monedas}
                  value={form?.moneda}
                  onValue={(v: CatalogueEntry) => saveValueToStore('moneda', v)}
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                  required
                />
              </Grid>
              {!_.isNil(form.moneda) &&
                ['MXN', 'XXX'].indexOf(form.moneda?.value) === -1 && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      value={form?.tipoCambio}
                      onValue={(v: string) => saveValueToStore('tipoCambio', v)}
                      format="money"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
              {/* <Grid item xs={4}>
                                <TextField
                                    name="confirmacion"
                                    label="Confirmación"
                                    fullWidth
                                />
                            </Grid> */}
              <Grid item xs={4}>
                <AutocompleteField
                  name="metodoPago"
                  label="Método de pago"
                  catalogue={metodosPago}
                  value={form?.metodoPago}
                  onValue={(v: CatalogueEntry) =>
                    saveValueToStore('metodoPago', v)
                  }
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name="formaPago"
                  label="Forma de pago"
                  catalogue={formasPago}
                  value={form?.formaPago}
                  onValue={(v: CatalogueEntry) =>
                    saveValueToStore('formaPago', v)
                  }
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name="usoCfdi"
                  label="Uso de Comprobante"
                  catalogue={usosCfdi}
                  value={form?.usoCfdi}
                  onValue={(v: CatalogueEntry) =>
                    saveValueToStore('usoCfdi', v)
                  }
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                  required
                />
              </Grid>
              {/* <Grid item xs={4}>
                                <NumericField
                                    name="subtotal"
                                    label="Subtotal"
                                    fullWidth
                                />
                            </Grid> */}
              <Grid item xs={12} />
              <Grid item xs={8}>
                <p className="form-label">
                  Condiciones de pago - Información adicional
                </p>
                <TextField
                  variant="outlined"
                  fieldType="alphanumericWithSpecialCharacters"
                  name="condiciones"
                  value={form?.condiciones}
                  onValue={(v: string) => saveValueToStore('condiciones', v)}
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  withCounter
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          {/* Conceptos */}
          <div id="section-4" className="content">
            <h3>(4) Conceptos</h3>
            <Divider className="separator" />
            <p className="form-label-alternative">
              Selecciona un concepto guardado anteriormente o agrega uno nuevo
            </p>
            <Grid
              className="section-add-item"
              container
              justifyContent="space-between">
              <Grid item xs={5}>
                <AutocompleteField
                  name="conceptos"
                  placeholder="Buscar"
                  catalogue={products}
                  fullWidth
                  getOptionLabel={(o: any) => `${o.name}`}
                  onSelectValue={addConcept}
                  exclude
                />
              </Grid>
              <Grid item>
                <Button
                  id="newConceptBtn"
                  variant="outlined"
                  color="warning"
                  startIcon={<Add />}
                  onClick={() => addConcept()}>
                  Nuevo Concepto
                </Button>
              </Grid>
            </Grid>
            {_.map(form?.conceptos, (c, i) => (
              <AddConceptContainer
                id={i}
                key={c.uuid}
                title={`Concepto ${i + 1}`}
                uuid={c.uuid}
                clave={c.satKey || c.claveSat}
                unidad={c.claveUnitSat}
                precio={c.price}
                cantidad={c.cantidad}
                descuento={c.descuento}
                descripcion={c.description}
                impuestos={c.impuestos}
                importacion={c.importacion}
                impuestosCat={taxes}
              />
            ))}
            <div className="invoice-breakdown">
              <DisplayTotalDetails concepts={form?.conceptos} />
            </div>
          </div>
          {/* Serie y Folio */}
          <div id="section-5" className="content">
            <h3>(5) Serie</h3>
            <Divider className="separator" />
            <Grid container spacing={4}>
              <Grid item xs={4}>
                {/* <TextField
                  name="serie"
                  label="Serie"
                  value={form?.serie}
                  onValue={(v: string) => saveValueToStore("serie", v)}
                  fullWidth
                  required
                /> */}
                <p className="form-label-alternative">Serie</p>
                <AutocompleteField
                  name="serie"
                  placeholder="Busca por serie"
                  catalogue={serieCatalog}
                  value={form.serie ? form.serie : null}
                  onSelectValue={(v: any) => setSerie(v)}
                  onValue={(v: any) => handleCleanSerie(v)}
                  onNewValue={(value: string) => handleNewSerie(value)}
                  getOptionLabel={(o: any) => o.label}
                  renderOption={(o: any) =>
                    o.flag === 'newValue'
                      ? renderNewOption(o, 'AGREGAR NUEVA SERIE')
                      : renderSerieOption(o)
                  }
                  getOptionSelected={(option: any, value: any) =>
                    option.value === value.value
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <p className="form-label-alternative">Folio</p>
                <TextField
                  name="folio"
                  value={form?.folio}
                  onValue={(v: string) => saveValueToStore('folio', v)}
                  fullWidth
                  required
                />
                {lastFolio && (
                  <label className="form-label-alternative">{`Ultimo Folio: ${lastFolio}`}</label>
                )}
              </Grid>
            </Grid>
          </div>
          <Divider className="separator" />
          <div className="actions">
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <Button
                variant="outlined"
                color="secondary"
                activity={loading}
                disabled={!valid}>
                Vista previa
              </Button>
            )}
            <Button
              id="addCfdiBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={loading}
              disabled={
                !form.emisor ||
                !form.receptor ||
                form.conceptos.length === 0 ||
                !valid
              }>
              Timbrar
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  )
}

export default AddIncomeContainer
