export enum PaymentStep {
  BILLING_INFORMATION,
  SELECTED_PLAN_PAYMENT,
  RECURRENT_PLAN_INFORMATION,
}

export const PLAN_PAYMENT_STATUS = {
  PAGADO: 1,
  POR_PAGAR: 2,
  VENCIDO: 3,
} as const

export const HISTORY_PAYMENT_STATUS = {
  PAGADO: 'pagado',
  POR_PAGAR: 'pendiente',
  NO_DISPONIBLE: 'No disponible',
} as const

export const PAYMENT_METHODS = {
  TARJETA: 0,
  EFECTIVO: 1,
  TRANSFERENCIA: 2,
} as const
