import { Box } from '@material-ui/core'
import { OCRBankStatement, OCRBankStatementMovement } from 'models/redux'
import { createContext, useCallback, useMemo, useState } from 'react'
import './styles.scss'
import OCRDrawer from 'components/Conciliations/OCRDrawer/OCRDrawer'
import OCRStatements from 'components/Conciliations/OCRStatements/OCRStatements'

interface DrawerInterface {
  open: boolean
  type:
    | {
        is: 'statement'
        value: OCRBankStatement
      }
    | {
        is: 'transaction'
        value: OCRBankStatementMovement
      }
    | {
        is: 'transaction--create'
        value: any
      }
}

interface OCRContextProps {
  drawer: DrawerInterface
  setDrawerStatement: (statement: OCRBankStatement) => void
  setDrawerTransaction: (transaction: OCRBankStatementMovement) => void
  setDrawerCreateTransaction: (statement: OCRBankStatement) => void
  closeDrawer: () => void
}

export const OCRContext = createContext<OCRContextProps>({} as OCRContextProps)

const emptyDrawer = {
  open: false,
  type: {
    is: 'statement',
    value: {} as OCRBankStatement,
  },
} as DrawerInterface

const OCRProcessContainer = () => {
  const [drawer, setDrawer] = useState<DrawerInterface>(emptyDrawer)

  const closeDrawer = useCallback(() => setDrawer(emptyDrawer), [])

  const setDrawerStatement = useCallback((statement: OCRBankStatement) => {
    setDrawer({
      open: true,
      type: {
        is: 'statement',
        value: statement,
      },
    })
  }, [])

  const setDrawerTransaction = useCallback(
    (transaction: OCRBankStatementMovement) => {
      setDrawer({
        open: true,
        type: {
          is: 'transaction',
          value: transaction,
        },
      })
    },
    []
  )

  const setDrawerCreateTransaction = useCallback(
    (statement: OCRBankStatement) => {
      setDrawer({
        open: true,
        type: {
          is: 'transaction--create',
          value: statement,
        },
      })
    },
    []
  )

  const valueToProvide = useMemo(() => {
    return {
      drawer,
      setDrawerStatement,
      setDrawerTransaction,
      setDrawerCreateTransaction,
      closeDrawer,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawer /* closeDrawer, setDrawerCreateTransaction, setDrawerStatement, setDrawerTransaction*/,
  ])

  return (
    <OCRContext.Provider value={valueToProvide}>
      <OCRDrawer />
      <Box className="ocr-process-container">
        <Box fontSize={24} fontWeight="bold" marginBottom={2}>
          Revisa la información obtenida
        </Box>
        <Box fontSize={16}>
          Revisa y confirma la información de tus estados de cuentas cargados.
          Puedes editarla o agregar nuevos movimientos.
        </Box>
        <OCRStatements />
      </Box>
    </OCRContext.Provider>
  )
}

export default OCRProcessContainer
