import { useMemo } from 'react'
import { Box, Grid, Toolbar } from '@material-ui/core'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import AccountMovementsFilter from '../AccountMovementsFilters/AccountMovementsFilters'
import AccountMovementsTable from '../AccountMovementsTable/AccountMovementsTable'
import AccountMovementsDrawer from '../AccountMovementsDrawer/AccountMovementsDrawer'
import AccountSelector from '../AccountSelector/AccountSelector'
import Options from '../Options/Options'
import './styles.scss'

const AccountMovementsToolbar = () => {
  const { statements, lastSync } = useConciliationsContainerContext()

  const { statementFilters } = statements

  const extraGap = useMemo(() => {
    if (lastSync !== undefined || lastSync !== null) {
      return true
    }
    return false
  }, [lastSync])

  return (
    <Box marginBottom={statementFilters.display ? 0 : 1}>
      <Toolbar
        disableGutters
        className={`account-movements-toolbar-component ${extraGap ? 'extra' : ''}`}>
        <AccountSelector />
        <Options />
      </Toolbar>
      {statementFilters.display && <AccountMovementsFilter />}
    </Box>
  )
}

const AccountMovements = () => {
  return (
    <Grid item xs={12} md={6}>
      <AccountMovementsToolbar />
      <AccountMovementsTable />
      <AccountMovementsDrawer />
    </Grid>
  )
}

export default AccountMovements
