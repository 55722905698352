/* eslint-disable react-hooks/exhaustive-deps */
import { getAccountantsFind } from 'services/Management/management.services'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import useDialog from 'hooks/Shared/useDialog'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accountant, RawAccountant } from 'models/management.models'
import _ from 'lodash'
import { useAccountantsContext } from 'components/Management/Accountants/AccountantsContextProvider/AccountantsContextProvider'
import { IAccountantsResponse } from 'services/Management/management.models'

const normalizeAccountants = (accountants: RawAccountant[]): Accountant[] => {
  return _.map(accountants, accountant => ({
    ...accountant,
    id: accountant.idUser,
  }))
}

export const useGetAccountants = () => {
  const {
    setManagementAccountants,
    page,
    elements,
    param,
    updateAccountantsState,
  } = useAccountantsContext()
  const dispatch = useDispatch()
  const workspace = useSelector(selectActiveWorkspace)
  const dialog = useDialog()

  const handleSuccess = async (data: IAccountantsResponse) => {
    updateAccountantsState({
      isLoading: false,
    })
    dispatch(setManagementAccountants(normalizeAccountants(data.accountants)))
  }

  const handleError = async () => {
    updateAccountantsState({
      isLoading: false,
    })
    await dialog.dangerAsync({
      title: 'generic.error.title',
      subtitle: 'No se ha podido obtener la lista de contadores',
      primaryButtonText: 'Continuar',
    })
  }

  const fetchAccountants = async (page = 0, elements = 10, param: string) => {
    updateAccountantsState({
      isLoading: true,
    })
    getAccountantsFind(workspace, { page, elements, param })
      .then(handleSuccess)
      .catch(handleError)
  }

  const refetch = () => fetchAccountants(page, elements, param)

  useEffect(() => {
    fetchAccountants(page, elements, param)
  }, [page, elements])

  return {
    fetchAccountants,
    refetch,
  }
}

export default useGetAccountants
