import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { ParsedArchive } from 'models/general'
import {
  parseFileListV2,
  parseUploadedFiles,
  toPeriodString,
} from 'lib/helpers/utilities'
import { useUploadAccountMovementsFiles } from 'hooks/queries/conciliations'
import { UploadArchiveToPeriodResponse } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import FileInputWrapper from '../FileInputWrapper/FileInputWrapper'
import './styles.scss'
import Button from 'components/Shared/Button/Button'

const DocumentInputs: FC<{
  handleDelete: (uuid: string) => void
  sectionNumber: number
  uuid: string
}> = ({ handleDelete, sectionNumber, uuid }) => {
  const [inputsNames, setInputNames] = useState<string[]>([])
  const { unregister } = useFormContext()

  const handleInputName = useCallback((name: string) => {
    setInputNames(inputsNames => [...inputsNames, name])
  }, [])

  const onClick = (id: string) => {
    handleDelete(id)
    inputsNames.forEach((name: string) => unregister(name))
  }

  return (
    <div className="document_inputs">
      <header>
        <p>Estado de cuenta #{sectionNumber + 1}</p>
        {sectionNumber > 0 && (
          <IconButton onClick={() => onClick(uuid)}>
            <DeleteForeverIcon fontSize="medium" />
          </IconButton>
        )}
      </header>
      <FileInputWrapper
        ext="XLSX"
        handleInputName={handleInputName}
        uuid={uuid}
      />
      <FileInputWrapper
        ext="PDF"
        handleInputName={handleInputName}
        uuid={uuid}
      />
    </div>
  )
}

const DocumentForm = () => {
  const {
    workspaceInfo,
    conciliationPeriod,
    updateLoadingStates,
    setDrawerFilelist,
    toggleAccountMovementsModal,
  } = useConciliationsContainerContext()

  const [bankStatements, setBankStatements] = useState<string[]>([
    crypto.randomUUID(),
  ])
  const divRef = useRef<HTMLDivElement | null>(null)
  const methods = useForm({
    mode: 'onChange',
  })
  const { handleSubmit, formState } = methods
  const { isValid } = formState

  const { isLoading, mutate } = useUploadAccountMovementsFiles(
    workspaceInfo.activeWorkspace,
    toPeriodString(conciliationPeriod),
    {
      onBefore: () => {
        updateLoadingStates('accountMovements', true)
      },
      onSuccess: (data: UploadArchiveToPeriodResponse) => {
        updateLoadingStates('accountMovements', false)
        toggleAccountMovementsModal()

        const documents = parseUploadedFiles(data.documents)
        setDrawerFilelist({
          ocrMode: 'statements',
          documents,
          conciliation: data.idConciliation,
          onConfirm: () => {},
        })
      },
      onError: () => {
        updateLoadingStates('accountMovements', false)
        toggleAccountMovementsModal()
      },
    }
  )

  const handleDelete = (uuid: string) => {
    setBankStatements(bankStatements =>
      bankStatements.filter(bs => bs !== uuid)
    )
  }

  const handleAddMoreFiles = () => {
    const UUID = crypto.randomUUID()
    setBankStatements(bankStatements => [...bankStatements, UUID])
  }

  const onSubmit = handleSubmit(async (data: any) => {
    const fileList = Object.entries(data).reduce((acc, [key, value]) => {
      Object.entries(value as Object).forEach(([, file]) => {
        if (file !== undefined) {
          console.log(file)
          acc.push({
            originalName: file.name,
            file: file,
            idParentFile: key,
          })
        }
      })
      return acc
    }, [] as any[])

    const files: File[] = fileList.map((file: any) => file.file as File)

    const fileAsBase64 = await parseFileListV2(files)

    const parsedArchiveArray: ParsedArchive[] = fileList.map(
      (file: any, index: number) =>
        ({
          file: fileAsBase64[index],
          originalName: file.originalName,
          idParentFile: file.idParentFile,
        }) as ParsedArchive
    )
    mutate(parsedArchiveArray)
  })

  useEffect(() => {
    const current = divRef.current
    current && current.scrollTo(0, current.scrollHeight ?? 0)
  }, [bankStatements])

  return (
    <FormProvider {...methods}>
      <form className="document_form" onSubmit={onSubmit}>
        <div className="inputs_container" ref={divRef}>
          {bankStatements.map((uuid: string, index: number) => (
            <DocumentInputs
              handleDelete={handleDelete}
              key={uuid}
              sectionNumber={index}
              uuid={uuid}
            />
          ))}
          <Button
            type="button"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleAddMoreFiles}
            disabled={!isValid || isLoading}>
            <FileUploadIcon />
            Cargar otro estado de cuenta
          </Button>
        </div>
        <div className="buttons_container">
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={toggleAccountMovementsModal}
            disabled={isLoading}>
            CANCELAR
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={!isValid || isLoading}>
            {isLoading ? 'SUBIENDO...' : 'CONTINUAR'}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default DocumentForm
