import { UploadArchiveToPeriodResponse } from 'services/conciliations'
import Dropzone, {
  IDropzoneProps,
} from 'components/Conciliations/Dropzone/Dropzone'
import { parseFileList } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { ParsedArchive } from 'models/general'
import { FC, Fragment } from 'react'
import MenuItemFileInput from '../MenuItemFileInput/MenuItemFileInput'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

type DropzoneWrapperProps = Omit<IDropzoneProps, 'onChange'>
type WrapperMode = 'dropzone' | 'option'

interface UploadProps {
  activeWorkspace: number
  activePeriod: string
}

interface ConciliationFileHandlerProps {
  dropzoneProps?: DropzoneWrapperProps
  uploadProps: UploadProps
  onUpload?: (response: UploadArchiveToPeriodResponse) => void
  mode?: WrapperMode
  onContinuousClick?: () => void
}

const ConciliationFileWrapper: FC<ConciliationFileHandlerProps> = ({
  dropzoneProps,
  uploadProps,
  mode = 'dropzone',
  onUpload = data => {},
  onContinuousClick,
}) => {
  const {
    globalMethods: { uploadArchiveToPeriod },
    updateScreenLoading,
  } = useConciliationsContainerContext()
  const dialog = useDialog()

  const handleFileChange = async (files: FileList) => {
    updateScreenLoading(true)
    try {
      const archives = (await parseFileList(files)) as ParsedArchive[]
      const { activePeriod, activeWorkspace } = uploadProps
      const data = await uploadArchiveToPeriod(
        activeWorkspace,
        archives,
        activePeriod
      )
      updateScreenLoading(false)
      onUpload(data)
    } catch (error: any) {
      updateScreenLoading(false)
      if (error.status === 400) {
        return await dialog.primaryAsync({
          title: 'Archivo repetido',
          subtitle:
            'Algunos archivos están repetidos y no se han podido subir.',
          primaryButtonText: 'Continuar',
        })
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo cargar el archivo. Inténtalo mas tarde.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  if (mode === 'option') {
    return (
      <Fragment>
        <MenuItemFileInput
          onChange={handleFileChange}
          onContinuousClick={onContinuousClick}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Dropzone
        {...dropzoneProps}
        onChange={handleFileChange}
        isAccountMovement
      />
    </Fragment>
  )
}

export default ConciliationFileWrapper
