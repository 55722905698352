import {
  Button,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Select,
} from '@material-ui/core'
import { months } from '../../../constants'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { getConciliatedTransactions } from 'services/conciliations'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { useConciliationReportsContext } from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const MonthSelector: FC = () => {
  const {
    filter: { month, setMonth },
    isLoading,
    reports,
  } = useConciliationReportsContext()
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(month + 1)
  const [search] = useSearchParams()

  useEffect(
    () => {
      if (search.has('month')) {
        const month = Number(search.get('month'))
        dispatch(setMonth(month + 1))
        setValue(month + 1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*search, dispatch, setMonth*/
    ]
  )

  const getMonth = (month: string): number => {
    const monthIndex = Object.values(months).indexOf(_.capitalize(month))
    const monthKey = Object.keys(months)[monthIndex]
    return Number(monthKey)
  }

  useEffect(
    () => {
      const first = _.first(reports)
      if (first) {
        const [m] = first.period.split(' ')
        const _month = getMonth(m)
        if (month !== _month) {
          dispatch(setMonth(_month))
          setValue(_month)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reports /*, month, dispatch, setMonth*/]
  )

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
    dispatch(setMonth(value))
  }

  const parsedMonths = Object.keys(months).map((key: string) => ({
    label: months[Number(key)],
    value: Number(key),
  }))

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        disabled={isLoading}
        input={
          <Input
            disableUnderline
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Mes
        </MenuItem>
        {parsedMonths.map(month => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const YearSelector: FC = () => {
  const {
    filter: { year, setYear },
    isLoading,
  } = useConciliationReportsContext()
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(year)
  const [search] = useSearchParams()

  useEffect(
    () => {
      if (search.has('year')) {
        const year = Number(search.get('year'))
        dispatch(setYear(year))
        setValue(year)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*search, dispatch, setYear*/
    ]
  )

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    setValue(value)
    dispatch(setYear(value))
  }

  useEffect(() => {
    if (year !== -1 && value === -1) {
      setValue(year)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year /*, value*/])

  const years = [2020, 2021, 2022, 2023, 2024, 2025]

  return (
    <Grid item xs={12} md={3}>
      <Select
        value={value}
        onChange={onChange}
        fullWidth
        disabled={isLoading}
        input={
          <Input
            disableUnderline
            style={{
              border: '2px solid #999',
              padding: '3px 14px',
              borderRadius: 4,
            }}
          />
        }>
        <MenuItem value={-1} selected disabled>
          Año
        </MenuItem>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

const ActionButton: FC = () => {
  const {
    filter: { month, year },
    setIsLoading,
    setReports,
    setSelectedReport,
    isLoading,
  } = useConciliationReportsContext()
  const { workspaceInfo } = useConciliationsContainerContext()
  const dispatch = useDispatch()
  const dialog = useDialog()

  const fetchReports = async () => {
    setIsLoading(true)
    dispatch(setSelectedReport(undefined))
    try {
      const data = await getConciliatedTransactions(
        workspaceInfo.activeWorkspace,
        {
          month,
          year,
        }
      )
      dispatch(
        setReports(
          _.map(data, (report, index) => ({
            ...report,
            id: index,
          }))
        )
      )
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se han podido obtener los estados de cuentas.',
        primaryButtonText: 'Continuar',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid item xs={12} md={6}>
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        onClick={fetchReports}
        disabled={isLoading}
        startIcon={
          isLoading ? (
            <CircularProgress size={20} style={{ color: '#fff' }} />
          ) : (
            <SearchIcon />
          )
        }
        style={{
          color: '#fff',
        }}>
        Buscar
      </Button>
    </Grid>
  )
}

const ConciliationReportsDateSelector: FC = () => {
  return (
    <Grid container spacing={2} alignItems="center">
      <MonthSelector />
      <YearSelector />
      <ActionButton />
    </Grid>
  )
}

export default ConciliationReportsDateSelector
