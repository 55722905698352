import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import StyledIconButton from 'components/Shared/StyledIconButton/StyledIconButton'
import StyledMenu from 'components/Shared/StyledMenu/StyledMenu'
import LedgerAccountsFileWrapper from 'components/Conciliations/LedgerAccountsFileWrapper/LedgerAccountsFileWrapper'

interface ILoadFileButtonProps {
  handleClose: () => void
}

const LoadFileButton = ({ handleClose }: ILoadFileButtonProps) => {
  return (
    <LedgerAccountsFileWrapper mode="option" onContinuousClick={handleClose} />
  )
}

const MenuButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <Fragment>
      <StyledIconButton
        aria-controls="ledger-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </StyledIconButton>
      <StyledMenu
        id="ledger-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <LoadFileButton handleClose={handleClose} />
      </StyledMenu>
    </Fragment>
  )
}

const FiltersButton = () => {
  const { ledgers } = useConciliationsContainerContext()

  const { ledgerFilters, setLedgerFilters } = ledgers

  const { display } = ledgerFilters

  const dispatch = useDispatch()

  const toggle = () =>
    dispatch(
      setLedgerFilters({
        ...ledgerFilters,
        display: !display,
      })
    )

  return (
    <StyledIconButton
      onClick={toggle}
      className={display ? 'toolbar-button active' : 'toolbar-button '}>
      {display ? <CloseIcon /> : <SearchIcon />}
    </StyledIconButton>
  )
}

const LedgerAccountActions = () => {
  return (
    <Box className="toolbar-actions">
      <FiltersButton />
      <MenuButton />
    </Box>
  )
}

export default LedgerAccountActions
