import { useMutation, useQuery } from '@tanstack/react-query'
// import { useDispatch } from 'react-redux';
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import {
  getAnswersByWorkspaceId,
  getFormsByWorkspaceId,
  saveAnswers,
} from 'services/profiling'

import Error from '@material-ui/icons/Error'
import CheckCircle from '@material-ui/icons/CheckCircle'

import Profiling, { Answer } from 'models/profiling'
import { QueryOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

export const useGetForms = (
  workspaceId: number,
  options: QueryOptions<Profiling[]> = {}
) => {
  // const dispatch = useDispatch();
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    // noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Profiling[], any>(
    ['get-questions', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getFormsByWorkspaceId(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        // !noSave && dispatch(setProductsList(data));
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener las preguntas para el espacio de trabajo seleccionado.',
            icon: Error as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useGetAnswers = (
  workspaceId: number,
  options: QueryOptions<Answer[]> = {}
) => {
  // const dispatch = useDispatch();
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    // noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Answer[], any>(
    ['get-answers', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getAnswersByWorkspaceId(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        // !noSave && dispatch(setProductsList(data));
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener las preguntas para el espacio de trabajo seleccionado.',
            icon: Error as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useSaveAnswers = (
  workspaceId: number,
  options: QueryOptions<string, unknown, Answer[]> = {}
) => {
  // const dispatch = useDispatch();
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    // noSave = false,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<string, any, Answer[]>(
    ['get-answers', workspaceId, ...refreshOn],
    answers => {
      onBefore && onBefore()
      return saveAnswers(workspaceId, answers)
    },
    {
      onSuccess: data => {
        // !noSave && dispatch(setProductsList(data));
        dialog.primary({
          title: 'Respuestas guardadas',
          subtitle: 'Se guardaron de manera correcta',
          icon: CheckCircle as TModalIcon,
          primaryButtonText: 'Continuar',
        })
        onSuccess && onSuccess(data)
      },
      onError: async (error, answers) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo guardar las respuestas.',
            icon: Error as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.mutate(answers)
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
