import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useDebounce from 'hooks/useDebounce'
import useLoader from 'hooks/Shared/useLoader'
// import { useIntl } from 'react-intl';
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Activity from 'components/Shared/Activity/Activity'

import useWorkspaceList, {
  useUpdateWorkspace,
  useWorkspaceSelection,
} from 'hooks/queries/workspaces'

import { setActiveTab } from 'actions/workspaces'
import {
  selectActiveWorkspace,
  selectUserRole,
  selectWorkspacesList,
} from 'lib/helpers/selectors'

import { searchInCollection } from 'lib/helpers/utilities'

import Close from '@material-ui/icons/Clear'
import './style.scss'

import Workspace from 'models/workspace'
import WorkspaceCard, {
  NewWorkspaceCard,
} from 'components/Workspaces/WorkspaceCard/WorkspaceCard;'
import { WorkspaceSections } from 'lib/enums/shared.enums'

interface Props {
  onRedirect: (action: string, id?: number) => void
  onFetching: (active: boolean) => void
}

const mapSelectToStatus = (status: number) => {
  switch (status) {
    case 1:
      return true
    case 2:
      return false
    default:
      return undefined
  }
}

const WorkspacesContainer: React.FC<Props> = ({ onRedirect, onFetching }) => {
  const dispatch = useDispatch()
  const loader = useLoader()
  // const intl = useIntl();

  const [status, setStatus] = useState(1)
  const [step, setStep] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500)

  const userRole = useSelector(selectUserRole)
  const active = useSelector(selectActiveWorkspace)
  const list: Workspace[] = useSelector(selectWorkspacesList)

  const filteredList = useMemo(() => {
    const _status = mapSelectToStatus(status)
    const filteredBySearch = searchInCollection(
      list,
      debouncedSearchTerm,
      ['id', 'name'],
      'id'
    )
    const filteredByAccountingStatus = _.filter(filteredBySearch, w =>
      step === 0 ? true : w.accountingStatus?.step === step
    )
    const filteredByStatus = _.filter(filteredByAccountingStatus, w =>
      _.isNil(_status) ? true : w.enabled === _status
    )
    return filteredByStatus
  }, [status, list, debouncedSearchTerm, step])

  const listQuery = useWorkspaceList(undefined, {
    onError: () => onRedirect('error'),
  })

  const updateMutation = useUpdateWorkspace({
    onSuccess: () => listQuery.refetch(),
  })

  const onSelectWorkspace = useWorkspaceSelection()

  useEffect(() => {
    listQuery.isLoading
      ? loader.primary('Cargando información de los espacios de trabajo')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQuery.isLoading])
  useEffect(() => {
    onFetching(listQuery.isFetching)
  }, [listQuery.isFetching, onFetching])

  useEffect(
    () => () => {
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Grid container spacing={3} className="container-workspaces-list">
        {listQuery.isLoading && (
          <Grid item xs={12}>
            <Activity message="Cargando espacios de trabajo" fullSize />
          </Grid>
        )}
        {!listQuery.isLoading && (
          <>
            <Grid item xs={5}>
              <TextField
                id="wsSearchTextField"
                autoComplete="off"
                label="Busca tu espacio de trabajo"
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        id="clearSearchBtn"
                        onClick={() => setSearchTerm('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>Estatus de cierre mensual</InputLabel>
                <Select
                  value={step}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setStep(e.target.value as number)
                  }>
                  <MenuItem value={0}>Todo</MenuItem>
                  <MenuItem value={1}>Pendiente</MenuItem>
                  <MenuItem value={2}>Documentos solicitados</MenuItem>
                  <MenuItem value={3}>Documentos en revisión</MenuItem>
                  <MenuItem value={4}>Servicio en proceso</MenuItem>
                  <MenuItem value={5}>Revisión del cliente</MenuItem>
                  <MenuItem value={6}>Impuestos pagados</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>Estatus del workspace</InputLabel>
                <Select
                  value={status}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setStatus(e.target.value as number)
                  }>
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={1}>Activos</MenuItem>
                  <MenuItem value={2}>Inactivos</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        {!listQuery.isLoading && (
          <>
            {_.map(filteredList, (ws, i) => (
              <Grid key={i} item md={3}>
                <WorkspaceCard
                  elevation={1}
                  id={ws.id}
                  name={ws.name}
                  users={ws.users}
                  invitations={ws.pendingInvitations}
                  role={userRole}
                  workspaceRole={ws.currentRole}
                  enabled={ws.enabled}
                  active={active === ws.id}
                  accountingStatus={ws.accountingStatus}
                  onActive={id => onSelectWorkspace(id)}
                  onClick={
                    !ws.enabled
                      ? undefined
                      : id => {
                          dispatch(setActiveTab(WorkspaceSections.INFORMATION))
                          onRedirect('edit', id)
                        }
                  }
                  onEnabled={() =>
                    updateMutation.mutate({ id: ws.id, enabled: true })
                  }
                  onDisabled={() =>
                    updateMutation.mutate({ id: ws.id, enabled: false })
                  }
                  onInvitation={id => {
                    dispatch(setActiveTab(WorkspaceSections.COLLABORATORS))
                    onRedirect('edit', id)
                  }}
                />
              </Grid>
            ))}
            <Grid item md={3}>
              <NewWorkspaceCard onClick={() => onRedirect('add')} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default WorkspacesContainer
