import _ from 'lodash';
import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";

import RazonSocial, { RazonSocialForm } from "models/razonSocial";
import { File } from 'models/general';

const route = process.env.REACT_APP_APIURL || "/api/";

export const getEmisoresList = async (workspaceId: number): Promise<RazonSocial[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/rfcs`);
        const data = await petition.json();
        const list: RazonSocial[] = data["Rfcs:"];
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getEmisorById = async (workspaceId: number, razonSocialId: number): Promise<RazonSocial> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/rfcs/${razonSocialId}`);
        const razonSocial: RazonSocial = await petition.json();
        return razonSocial;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getEmisorFiles = async (workspaceId: number, razonSocialId: number): Promise<File[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/rfcs/${razonSocialId}/files`);
        const data = await petition.json();
        const filesPromises = _.map(data, async (f) => {
            const p = await client.get(`${route}/workspaces/${workspaceId}/rfcs/${razonSocialId}/files/${f.idFile}`);
            const file = await p.text();
            return ({ ...f, base64: file });
        });
        const files = await Promise.all(filesPromises);
        return files;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const createEmisor = async (workspaceId: number, form: RazonSocialForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        let files: any = [];
        files.push({ ...form.fielCer, type: "cerFiel" });
        files.push({ ...form.fielKey, type: "keyFiel" });
        !!form.csdCer && files.push({ ...form.csdCer, type: "cerCsd" });
        !!form.csdKey && files.push({ ...form.csdKey, type: "keyCsd" });

        const body = {
            idType: form.type?.idCatalogValues,
            name: form.name,
            rfc: _.toUpper(form.rfc),
            taxSystem: "123456789",
            zipCode: form.zipCode,
            idTaxResidence: form.taxSystem?.idCatalogValues,
            files: files,
            passwordFiel: form.passwordFiel,
            ciec: form.ciec,
            passwordCsd: form.passwordCsd,
        }
        const petition = await client.post(`${route}/workspaces/${workspaceId}/rfcs`, body);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const updateEmisor = async (workspaceId: number, razonSocialId: number, form: RazonSocialForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        let files: any = [];
        files.push({ ...form.fielCer, type: "cerFiel" });
        files.push({ ...form.fielKey, type: "keyFiel" });
        !!form.csdCer && files.push({ ...form.csdCer, type: "cerCsd" });
        !!form.csdKey && files.push({ ...form.csdKey, type: "keyCsd" });

        const body = {
            idRfc: razonSocialId,
            name: form.name,
            zipCode: form.zipCode,
            idTaxResidence: form.taxSystem?.idCatalogValues,
            files: files,
            passwordFiel: form.passwordFiel,
            ciec: form.ciec,
            passwordCsd: form.passwordCsd,
        }
        const petition = await client.put(`${route}/workspaces/${workspaceId}/rfcs/${razonSocialId}`, body);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const deleteEmisor = async (workspaceId: number, razonSocialId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${route}/workspaces/${workspaceId}/rfcs/${razonSocialId}`);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}