import update from 'immutability-helper';
import { RESET_CATALOGUES, SET_CATALOGUE } from 'actions/catalogues';

/** interfaces */
import { CataloguesStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialCatalogues: CataloguesStore = {

}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: {
        name: "",
        entries: []
    }
}

const catalogues = (state = initialCatalogues, action = defaultAction) => {
    switch (action.type) {
        case SET_CATALOGUE:
            return update(state, { [action.value.name]: { $set: action.value.entries }});
        case RESET_CATALOGUES:
            return initialCatalogues;
        default:
            return state
    }
}

export default catalogues;