import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, LinearProgress } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
} from '@material-ui/data-grid'
import Clipboard from '@material-ui/icons/Assignment'
import Check from '@material-ui/icons/Check'
import SwapVertical from '@material-ui/icons/SwapVert'
import { LedgerStatement } from 'models/redux'
import { useCustomDataGridStyle } from 'lib/helpers/utilities'
import { getActualSelectedStatements } from 'hooks/useConciliate'
import useDialog from 'hooks/Shared/useDialog'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { OrangeCheckbox } from 'components/Conciliations/AccountMovementsTable/AccountMovementsTable'
import LedgerAccountsFileWrapper from 'components/Conciliations/LedgerAccountsFileWrapper/LedgerAccountsFileWrapper'
import LedgerAccountDeleteButton from '../LedgerAccountsDeleteButton/LedgerAccountsDeletebutton'
import LedgerFilter from '../LedgerAccountsFilters/LedgerAccountsFilters'

export const rows = [
  { name: 'file_3383.pdf', code: '8622315299', id: 1 },
  { name: 'file_2542.pdf', code: '5258657660', id: 2 },
  { name: 'file_5491.pdf', code: '3273442224', id: 3 },
  { name: 'file_9124.pdf', code: '4495881005', id: 4 },
  { name: 'file_8863.pdf', code: '7348227200', id: 5 },
  { name: 'file_4011.pdf', code: '9447704565', id: 6 },
  { name: 'file_8342.pdf', code: '3096329193', id: 7 },
  { name: 'file_2197.pdf', code: '8377907203', id: 8 },
  { name: 'file_8627.pdf', code: '8716309144', id: 9 },
]

const SelectFile = () => {
  return (
    <Box height={300}>
      <LedgerAccountsFileWrapper mode="dropzone" />
    </Box>
  )
}

interface ICopyToClipboardProps {
  label: string
}

const CopyToClipboard = ({ label }: ICopyToClipboardProps) => {
  const [copied, setCopied] = useState(false)
  const [hover, setHover] = useState(false)

  const handleCopyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(label)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Button
      onClick={handleCopyToClipboard}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        fontWeight: 400,
        fontSize: 'inherit',
        color: 'inherit',
        padding: 0,
        borderRadius: 0,
      }}>
      {label}
      {hover && (
        <>
          {copied ? (
            <Check fontSize="small" style={{ marginLeft: 4 }} />
          ) : (
            <Clipboard fontSize="small" style={{ marginLeft: 4 }} />
          )}
        </>
      )}
    </Button>
  )
}

const LedgerAccounts = () => {
  const { isLoadingLedgerAccounts, statements, ledgers } =
    useConciliationsContainerContext()

  const {
    ledgerRows,
    selectedLedgerRows,
    ledgerFilters,
    setLedgerSelectedRows,
  } = ledgers

  const classes = useCustomDataGridStyle()
  const dispatch = useDispatch()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Código',
      flex: 1,
      renderCell(params) {
        return (
          <Box className="cell" display="flex" alignItems="center" flex={1}>
            <Box flex={1}>
              <CopyToClipboard label={params.value as string} />
            </Box>
            <LedgerAccountDeleteButton
              account={params.row as LedgerStatement}
            />
          </Box>
        )
      },
    },
  ]
  const dialog = useDialog()

  const handleSelectionModelChange = async (params: GridSelectionModel) => {
    const selectedStatements = getActualSelectedStatements(statements)
    if (selectedStatements.length >= 1 && params.length > 1) {
      return await dialog.primaryAsync({
        title: 'Acción no permitida',
        subtitle: 'Sólo puedes conciliar con una cuenta contable a la vez',
        primaryButtonText: 'Aceptar',
      })
    }
    dispatch(setLedgerSelectedRows(params))
  }
  return (
    <>
      {ledgerFilters.display && <LedgerFilter />}
      <Box maxHeight={450} overflow="auto">
        <DataGrid
          classes={classes}
          rows={ledgerRows}
          columns={columns}
          autoHeight
          density="compact"
          hideFooter
          disableColumnMenu
          checkboxSelection
          disableSelectionOnClick
          headerHeight={70}
          loading={isLoadingLedgerAccounts}
          selectionModel={selectedLedgerRows}
          onSelectionModelChange={handleSelectionModelChange}
          style={{
            border: 'none',
            fontSize: 14,
          }}
          components={{
            ColumnSortedAscendingIcon: SwapVertical,
            ColumnSortedDescendingIcon: SwapVertical,
            ColumnResizeIcon: () => null,
            NoRowsOverlay: SelectFile,
            LoadingOverlay: () => <LinearProgress variant="indeterminate" />,
            Checkbox: OrangeCheckbox,
          }}
        />
      </Box>
    </>
  )
}

export default LedgerAccounts
