import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SimpleCatalogModel } from 'models/catalogues'
import { STORE_EVENT_TYPES } from 'lib/enums/shared.enums'
import { WorkspacesFiltersFormModel } from 'lib/forms/WorkspacesFiltersFormUtils'
import { toQueryParamsV2 } from 'lib/helpers/utilities'
import useStoreEvent from 'hooks/Shared/useStoreEvent'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import {
  getManagementWorkspaces,
  getWorkspacesManagementCatalogs,
} from 'services/Management/management.services'
import { FormProvider, useForm } from 'react-hook-form'

export interface IWorkspaceItem {
  idWorkspace: number
  name: string
  rfc: string
  team: string
  emailAccountant: string
  monthlyClosure: number
  complianceOpinion: string
  coefficient: number
  userStatus: string
  profilingPercent: number
  eFirmaExpirationDate: string
  csdExpirationDate: string
  plans: string[]
  lastComment: string
  averageXml: number
  numTransactions: number
}

export interface IManagementWorkspacesContextState {
  totalPages: number
  totalResults: number
  page: number
  size: number
  list: IWorkspaceItem[]
  selectedWorkspaceIds: number[]
  loading: boolean
}

export interface IManagementWorspacesCatalogs {
  certLife: SimpleCatalogModel[]
  closingStatus: SimpleCatalogModel[]
  opinion: SimpleCatalogModel[]
  plans: SimpleCatalogModel[]
  profilingStatus: SimpleCatalogModel[]
  teams: SimpleCatalogModel[]
  workspaceStatus: SimpleCatalogModel[]
}

export interface IManagementWorkspacesContext
  extends IManagementWorkspacesContextState,
    IManagementWorspacesCatalogs {
  managementWorkspacesLoading: boolean
  managementWorkspacesError: boolean
  updateManagementWorkspacesState: (
    update:
      | Partial<IManagementWorkspacesContextState>
      | ((
          prevState: IManagementWorkspacesContextState
        ) => IManagementWorkspacesContextState)
  ) => void
  fetchManagementWorkspaces: (pageNumber?: number) => void
}

export const ManagementWorkspacesContext =
  createContext<IManagementWorkspacesContext | null>(null)

const ManagementWorkspacesContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [managementWorkspacesLoading, setIsLoading] = useState<boolean>(false)
  const [managementWorkspacesError, setIsError] = useState<boolean>(false)

  const [contextState, setContextState] =
    useState<IManagementWorkspacesContextState>({
      totalPages: 0,
      totalResults: 0,
      page: 1,
      size: 15,
      list: [],
      selectedWorkspaceIds: [],
      loading: false,
    })

  const filtersFormMethods = useForm<WorkspacesFiltersFormModel>()

  const { watch } = filtersFormMethods

  const [managementCatalogs, setManagementCatalogs] =
    useState<IManagementWorspacesCatalogs>({
      certLife: [],
      closingStatus: [],
      opinion: [],
      plans: [],
      profilingStatus: [],
      teams: [],
      workspaceStatus: [],
    })

  const { size } = contextState

  const {
    certLife,
    closingStatus,
    opinion,
    plans,
    profilingStatus,
    teams,
    workspaceStatus,
  } = managementCatalogs

  const updateManagementWorkspacesState =
    useUpdateStateFunction<IManagementWorkspacesContextState>(setContextState)

  const fetchManagementWorkspaces = useCallback(
    async (pageNumber?: number) => {
      setIsLoading(true)
      setIsError(false)
      try {
        const params = toQueryParamsV2({
          page: pageNumber ?? 1,
          size: size,
          ...watch(),
        })
        const response = await getManagementWorkspaces(params.toString())
        const { totalElements, totalPages, workspaces } = response
        if (pageNumber) {
          setContextState(state => ({
            ...state,
            list: workspaces,
            totalPages: totalPages,
            totalResults: totalElements,
            page: pageNumber,
          }))
        } else {
          setContextState(state => ({
            ...state,
            list: workspaces,
            totalPages: totalPages,
            totalResults: totalElements,
          }))
        }
      } catch (error) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [size, watch]
  )

  const initializeData = useCallback(async () => {
    const response: any = await getWorkspacesManagementCatalogs()
    setManagementCatalogs(value => ({
      ...value,
      certLife: response.certLife ?? [],
      closingStatus: response.closingStatus ?? [],
      opinion: response.opinion ?? [],
      plans: response.plans ?? [],
      profilingStatus: response.profilingStatus ?? [],
      teams: response.teams ?? [],
      workspaceStatus: response.workspaceStatus ?? [],
    }))
    await fetchManagementWorkspaces(1)
  }, [fetchManagementWorkspaces])

  const contextValue = useMemo<IManagementWorkspacesContext>(
    () => ({
      ...contextState,
      managementWorkspacesLoading,
      managementWorkspacesError,
      managementCatalogs,
      certLife,
      closingStatus,
      opinion,
      plans,
      profilingStatus,
      teams,
      workspaceStatus,
      updateManagementWorkspacesState,
      fetchManagementWorkspaces,
    }),
    [
      contextState,
      managementWorkspacesLoading,
      managementWorkspacesError,
      certLife,
      closingStatus,
      opinion,
      plans,
      profilingStatus,
      teams,
      workspaceStatus,
      managementCatalogs,
      updateManagementWorkspacesState,
      fetchManagementWorkspaces,
    ]
  )

  useEffect(() => {
    if (
      certLife.length === 0 &&
      closingStatus.length === 0 &&
      opinion.length === 0 &&
      plans.length === 0 &&
      profilingStatus.length === 0 &&
      teams.length === 0 &&
      workspaceStatus.length === 0
    )
      initializeData()
  }, [
    initializeData,
    certLife,
    closingStatus,
    opinion,
    plans,
    profilingStatus,
    teams,
    workspaceStatus,
  ])

  useStoreEvent(
    STORE_EVENT_TYPES.FETCH_MANAGEMENT_WORKSPACES,
    fetchManagementWorkspaces
  )

  return (
    <ManagementWorkspacesContext.Provider value={contextValue}>
      <FormProvider {...filtersFormMethods}>{children}</FormProvider>
    </ManagementWorkspacesContext.Provider>
  )
}

export const useManagementWorkspacesContext = () => {
  const context = useContext(ManagementWorkspacesContext)

  if (!context) {
    throw new Error(
      'useManagementWorkspacesContext must be used within a ManagementWorkspacesContext'
    )
  }

  return context
}

export default ManagementWorkspacesContextProvider
