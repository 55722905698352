import {
  IContinueButtonProps,
  TAccountStepFiveValidationSteps,
  TAccountingStepFiveStepConditionPair,
} from 'models/accounting.models'
import { ACCOUNTING_STEP_FIVE_VALIDATION_STEPS } from 'lib/enums/accounting.enums'
import {
  checkforInvalidUploadContinue,
  getValidationConditionsAndSteps,
} from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import { IUseAccountingStepFive } from './useAccountingStepFive'
import ISydDocument from 'models/shared.models'

interface IUseAccountingStepFiveValidationStepsProps
  extends Pick<
    IUseAccountingStepFive,
    | 'additionalDocuments'
    | 'firstCategoryFileStatuses'
    | 'thirdCategoryFileStatuses'
    | 'firstCategoryFilesToValidate'
    | 'uploadedFirstCategoryFiles'
    | 'uploadedFourthCategoryFiles'
    | 'uploadedAndValidatedFirstCategoryFiles'
  > {
  totalThirdCategoryFiles: ISydDocument[]
  uploadedThirdCategoryFiles: ISydDocument[]
  thirdCategoryFilesToValidate: ISydDocument[]
  firstCategoryValidFiles: ISydDocument[]
  thirdCategoryValidFiles: ISydDocument[]
  firstCategoryInvalidFiles: ISydDocument[]
  thirdCategoryInvalidFiles: ISydDocument[]
  uploadedAndValidatedThirdCategoryFiles: ISydDocument[]
  runValidationServiceOnSelectedFiles: (
    validFiles: ISydDocument[],
    invalidFiles: ISydDocument[],
    withStepSubmit?: boolean
  ) => void
}

interface IUseAccountingStepFiveValidationSteps {
  continueButtonProps: IContinueButtonProps
}

const useAccountingStepFiveValidationSteps = ({
  totalThirdCategoryFiles,
  additionalDocuments,
  firstCategoryFileStatuses,
  thirdCategoryFileStatuses,
  uploadedFirstCategoryFiles,
  uploadedThirdCategoryFiles,
  uploadedFourthCategoryFiles,
  firstCategoryFilesToValidate,
  thirdCategoryFilesToValidate,
  firstCategoryValidFiles,
  thirdCategoryValidFiles,
  firstCategoryInvalidFiles,
  thirdCategoryInvalidFiles,
  uploadedAndValidatedFirstCategoryFiles,
  uploadedAndValidatedThirdCategoryFiles,
  runValidationServiceOnSelectedFiles,
}: IUseAccountingStepFiveValidationStepsProps): IUseAccountingStepFiveValidationSteps => {
  const { isAllAccountingTasksComplete, handleStepSubmit } =
    useAccountingContext()

  const handleFirstCategoryFilesSubmit = (_e: any) => {
    runValidationServiceOnSelectedFiles(
      firstCategoryValidFiles,
      firstCategoryInvalidFiles
    )
  }

  const handleThirdCategoryFilesSubmit = (_e: any) => {
    runValidationServiceOnSelectedFiles(
      thirdCategoryValidFiles,
      thirdCategoryInvalidFiles,
      true
    )
  }

  const validationConditionsAndSteps: TAccountingStepFiveStepConditionPair[] =
    getValidationConditionsAndSteps(
      totalThirdCategoryFiles,
      uploadedFirstCategoryFiles,
      uploadedThirdCategoryFiles,
      firstCategoryFilesToValidate,
      thirdCategoryFilesToValidate,
      firstCategoryFileStatuses,
      uploadedAndValidatedFirstCategoryFiles,
      uploadedAndValidatedThirdCategoryFiles
    )

  const getValidationStep = (): TAccountStepFiveValidationSteps => {
    for (const [condition, step] of validationConditionsAndSteps) {
      if (condition()) {
        return step
      }
    }
    return ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.DEFAULT
  }

  const continueButtonPropsMap = {
    [ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.FIRST_CATEGORY_FILES_UPLOADED]: {
      onClick: () => handleStepSubmit(6, '', uploadedFirstCategoryFiles),
      disabled: false,
      text: 'Continuar',
    },
    [ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.FIRST_CATEGORY_FILES_VALIDATION]: {
      onClick: handleFirstCategoryFilesSubmit,
      disabled: checkforInvalidUploadContinue(firstCategoryFileStatuses),
      text: 'Continuar',
    },
    [ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.THIRD_CATEGORY_FILES_UPLOADED]: {
      onClick: () => handleStepSubmit(6, '', uploadedThirdCategoryFiles),
      disabled: false,
      text: 'Continuar',
    },
    [ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.THIRD_CATEGORY_FILES_VALIDATION]: {
      onClick: handleThirdCategoryFilesSubmit,
      disabled:
        checkforInvalidUploadContinue(thirdCategoryFileStatuses) ||
        (additionalDocuments && !uploadedFourthCategoryFiles.length) ||
        !isAllAccountingTasksComplete,
      text:
        !checkforInvalidUploadContinue(thirdCategoryFileStatuses) &&
        !thirdCategoryFileStatuses.some(({ status }) => status === 'invalid')
          ? 'Finalizar servicio'
          : 'Continuar',
    },
    [ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.DEFAULT]: {
      disabled: true,
      text: 'Continuar',
    },
  }

  const step = getValidationStep()

  const continueButtonProps: IContinueButtonProps = continueButtonPropsMap[step]

  return {
    continueButtonProps,
  }
}

export default useAccountingStepFiveValidationSteps
