import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { FC, useContext, useState } from 'react'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SwapIcon from '@material-ui/icons/SwapVert'
import { orange } from '@material-ui/core/colors'
import {
  downloadFileFromData,
  getFormattedDate,
  useCustomDataGridStyle,
} from 'lib/helpers/utilities'
import { AccountStatementHistoryContext } from '../AccountStatementHistory/AccountStatementHistory'
import { useThunkDispatch } from 'models/thunk'
import { getDocumentationFileById } from 'actions/documentation'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { OCRBankStatement } from 'models/redux'

import './style.scss'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

type BankStatement = Omit<OCRBankStatement, 'transactions'>

export interface StatementHistoryFile extends BankStatement {
  // id: string;
  period: string
  account: string
}

export const parseRows = (rows: StatementHistoryFile[]) => {
  return rows.map(row => ({
    ...row,
    id: row.idWsFile,
    period: row.cutoffDate,
    account: row.account,
  }))
}

const NoRowsOverlay: FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={1}
      height={250}>
      <Typography variant="h6" color="textSecondary">
        No hay datos
      </Typography>
    </Box>
  )
}

export const DownloadButton: FC<{ statement: StatementHistoryFile }> = ({
  statement,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    workspaceInfo: { activeWorkspace },
  } = useConciliationsContainerContext()
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()

  const handleFileDownload = async () => {
    try {
      setIsLoading(true)
      const file = await thunkDispatch(
        getDocumentationFileById({
          workspaceId: activeWorkspace,
          fileId: statement.idWsFile,
        })
      )
      let filename
      if (file.originalName.endsWith(file.extension)) {
        filename = file.originalName
      } else {
        filename = `${file.originalName}.${file.extension}`
      }
      downloadFileFromData(filename, file?.file ?? '')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo descargar el archivo seleccionado. Intenta nuevamente.',
      })
    }
  }

  const onClick = async () => handleFileDownload()

  return (
    <IconButton
      size="small"
      style={{
        color: orange[500],
      }}
      onClick={onClick}>
      {isLoading ? (
        <CircularProgress
          variant="indeterminate"
          size={18}
          style={{ color: orange[500] }}
        />
      ) : (
        <SaveAltIcon />
      )}
    </IconButton>
  )
}

const AccountStatementHistoryTable: FC = () => {
  const { rows, isLoading } = useContext(AccountStatementHistoryContext)
  const classes = useCustomDataGridStyle()
  const columns: GridColDef[] = [
    {
      field: 'period',
      headerName: 'Periodo',
      width: 160,
      valueFormatter: ({ value }) => {
        return getFormattedDate(value as string);
      },
    },
    {
      field: 'account',
      headerName: 'Cuenta',
      flex: 1,
      valueFormatter: ({ value, row }) => {
        return `${String(row.bank)} ${String(value).slice(-4)}`
      },
      renderCell({ formattedValue, row }) {
        return (
          <Box display="flex" alignItems="center" width={1}>
            <Typography>{formattedValue}</Typography>
            <Box flex={1} />
            {row.uploadedFiles?.includes('xlsx') && (
              <Box>
                {' '}
                <small className="fileTypeBox">XLSX</small>
              </Box>
            )}
            {row.uploadedFiles?.includes('pdf') && (
              <Box>
                <small className="fileTypeBox">PDF</small>
              </Box>
            )}
            <DownloadButton statement={row as StatementHistoryFile} />
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      <DataGrid
        classes={classes}
        density="compact"
        rows={rows}
        columns={columns}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        autoPageSize
        pageSize={10}
        hideFooterSelectedRowCount
        headerHeight={80}
        loading={isLoading}
        components={{
          ColumnResizeIcon: () => null,
          ColumnSortedAscendingIcon: SwapIcon,
          ColumnSortedDescendingIcon: SwapIcon,
          LoadingOverlay: () => <LinearProgress variant="indeterminate" />,
          NoRowsOverlay,
        }}
        style={{
          border: 'none',
          fontSize: 14,
        }}
      />
    </Box>
  )
}

export default AccountStatementHistoryTable
