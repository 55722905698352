import { useState } from 'react'
import { toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { boolean, object } from 'yup'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  LinearProgress,
} from '@material-ui/core'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import { generateMassiveReports } from 'services/Management/management.services'
import { useManagementWorkspacesContext } from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'

const REPORTS_LABELS: Record<string, string> = {
  MonthlyClosing: 'Estatus de cierre mensual',
  NPS: 'NPS',
  WorkLoad: 'Carga de trabajo',
}

const schema = object().shape({
  MonthlyClosing: boolean(),
  NPS: boolean(),
  WorkLoad: boolean(),
})

interface IManagementWorkspacesReportDialogProps {
  open: boolean
  onClose: () => void
}

const ManagementWorkspacesReportDialog = ({
  open,
  onClose,
}: IManagementWorkspacesReportDialogProps) => {
  const { selectedWorkspaceIds } = useManagementWorkspacesContext()
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm({
    defaultValues: {
      MonthlyClosing: false,
      NPS: false,
      WorkLoad: false,
    },
    resolver: yupResolver(schema),
  })

  const monthlyClosing = methods.watch('MonthlyClosing')
  const nps = methods.watch('NPS')
  const workLoad = methods.watch('WorkLoad')

  const handleClose = () => {
    methods.reset()
    onClose()
  }

  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const onSubmit = async (data: any) => {
    const selectedReports = Object.keys(data).filter(key => data[key])

    setIsLoading(true)

    toast.loading(
      `Generando ${selectedReports.length > 1 ? 'reportes' : 'reporte'}`,
      {
        ...loadingToastOptions,
        toastId: 'loading',
      }
    )

    const promises = selectedReports.map(report =>
      generateMassiveReports(report, selectedWorkspaceIds).then(file => {
        return new Promise<void>((resolve, reject) => {
          try {
            const str = `data:application/octet-stream;base64,${file}`
            const link = document.createElement('a')
            link.href = str
            link.download = `${REPORTS_LABELS[report]} - ${new Date().toISOString().slice(0, 10)}.xlsx`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            resolve()
          } catch (error) {
            reject(error)
          }
        })
      })
    )

    try {
      await Promise.all(promises)
    } catch (error) {
      const errorMessage =
        selectedReports.length > 1
          ? 'No fue posible descargar los reportes'
          : `No fue posible descargar el reporte`
      toast.error(errorMessage, errorToastOptions)
    } finally {
      toast.dismiss('loading')
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Dialog onClose={handleClose} open={open} className="dialog">
          <DialogContent className="content">
            <Box className="labels">
              <Box className="title">Generar reporte</Box>
              <Box className="subtitle">
                Selecciona los reportes que quieres generar.
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mx={4} mt={2}>
              {isLoading && <LinearProgress variant="query" />}
              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register('MonthlyClosing')}
                    color="primary"
                  />
                }
                label="Estatus de cierre mensual"
                disabled={isLoading}
              />
              <FormControlLabel
                control={
                  <Checkbox {...methods.register('NPS')} color="primary" />
                }
                label="NPS"
                disabled={isLoading}
              />
              <FormControlLabel
                control={
                  <Checkbox {...methods.register('WorkLoad')} color="primary" />
                }
                label="Carga de trabajo"
                disabled={isLoading}
              />
            </Box>
          </DialogContent>
          <DialogActions className="actions">
            <Button
              className="button cancel"
              onClick={handleClose}
              disabled={isLoading}>
              Cancelar
            </Button>
            <Button
              className="button primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading || (!monthlyClosing && !nps && !workLoad)}>
              Generar
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

export default ManagementWorkspacesReportDialog
