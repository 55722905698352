import { setActiveTab } from 'actions/documentRequest'
import CFDIHistory from 'components/Conciliations/CFDIHistory/CFDIHistory'
import { createContext, useMemo } from 'react'

interface AccountStatementHistoryContextInterface {
  setParentTab: typeof setActiveTab
}

export const CFDIHistoryContext =
  createContext<AccountStatementHistoryContextInterface>(
    {} as AccountStatementHistoryContextInterface
  )

const CFDIHistoryContainer = () => {
  const setParentTab = setActiveTab

  const valueToProvide: AccountStatementHistoryContextInterface = useMemo(
    () => ({ setParentTab }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*setParentTab*/
    ]
  )

  return (
    <CFDIHistoryContext.Provider value={valueToProvide}>
      <CFDIHistory />
    </CFDIHistoryContext.Provider>
  )
}

export default CFDIHistoryContainer
