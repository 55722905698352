import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import { setNotifications, setUserData } from 'actions/user'
import {
  changeInformation,
  changePassword,
  getNotifications,
  getProfile,
} from 'services/account'

import ErrorI from '@material-ui/icons/ErrorOutline'

import { Profile } from 'models/user'
import { InformationForm, PasswordForm } from 'models/account'
import { Notification, QueryOptions, MutationOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useProfile = (options: QueryOptions<Profile> = {}) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Profile, any>(
    ['get-profile', ...refreshOn],
    () => {
      onBefore && onBefore()
      return getProfile()
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setUserData(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del perfil.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export const useUpdateInformation = (
  options: MutationOptions<void, InformationForm> = {}
) => {
  const dialog = useDialog()
  const { onSuccess, noDialog } = options

  const mutation = useMutation<void, any, InformationForm>(
    form => {
      return changeInformation(form)
    },
    {
      onSuccess: (data, form, ctx) => {
        onSuccess && onSuccess(data, form, ctx)
      },
      onError: async (error, form, ctx) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo actualizar la información de tu perfil.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }
      },
    }
  )

  return mutation
}

export const useUpdatePassword = (
  options: MutationOptions<void, PasswordForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, PasswordForm>(
    form => {
      onBefore && onBefore(form)
      return changePassword(form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo actualizar la contraseña de tu perfil.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}

export const useNotificationList = (
  workspaceId: number,
  options: QueryOptions<Notification[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Notification[], any>(
    ['get-notifications-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getNotifications(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setNotifications(data))
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de notificaciones.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}

export default useProfile
