import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Grid } from '@material-ui/core'
import { TaskAlt } from 'components/Shared/CustomIcons'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { validations } from 'lib/helpers/ioValidations'

import './style.scss'

interface Props {
  password: string
  onValidity?: (v: boolean) => void
  variant?: 'small' | 'large'
}

interface PasswordRulesProps extends Omit<Props, 'variant'> {}

const PasswordRulesLarge: React.FC<PasswordRulesProps> = ({
  password,
  onValidity,
}) => {
  const intl = useIntl()

  const rule1 = validations.isPasswordLength(password)
  const rule2 = validations.isPasswordWithNumber(password)
  const rule3 = validations.isPasswordWithCapitalLetter(password)
  const rule4 = validations.isPasswordWithSpecialCharacter(password)

  useEffect(() => {
    onValidity && onValidity(rule1 && rule2 && rule3 && rule4)
  }, [onValidity, rule1, rule2, rule3, rule4])

  return (
    <div className="component-password-rules-large">
      <p>{intl.formatMessage({ id: 'password.rules.title' })}</p>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12}>
          <div className="rule-container">
            <TaskAlt color={rule1 ? 'success' : 'disabled'} />
            <p>{intl.formatMessage({ id: 'password.rules.rule.1' })}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="rule-container">
            <TaskAlt color={rule2 ? 'success' : 'disabled'} />
            <p>{intl.formatMessage({ id: 'password.rules.rule.2' })}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="rule-container">
            <TaskAlt color={rule3 ? 'success' : 'disabled'} />
            <p>{intl.formatMessage({ id: 'password.rules.rule.3' })}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="rule-container">
            <TaskAlt color={rule4 ? 'success' : 'disabled'} />
            <p>{intl.formatMessage({ id: 'password.rules.rule.4' })}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const PasswordRulesSmall: React.FC<PasswordRulesProps> = ({
  password,
  onValidity,
}) => {
  const intl = useIntl()
  const initialState = 'initial'
  const [rule1, setRule1] = useState(initialState)
  const [rule2, setRule2] = useState(initialState)
  const [rule3, setRule3] = useState(initialState)
  const [rule4, setRule4] = useState(initialState)

  useEffect(() => {
    if (password) {
      setRule1(validations.isPasswordLength(password) ? 'success' : 'error')
      setRule2(validations.isPasswordWithNumber(password) ? 'success' : 'error')
      setRule3(
        validations.isPasswordWithCapitalLetter(password) ? 'success' : 'error'
      )
      setRule4(
        validations.isPasswordWithSpecialCharacter(password)
          ? 'success'
          : 'error'
      )
    } else {
      setRule1(initialState)
      setRule2(initialState)
      setRule3(initialState)
      setRule4(initialState)
    }
  }, [password, initialState])

  useEffect(() => {
    if (onValidity) {
      const rules = [rule1, rule2, rule3, rule4]
      onValidity(rules.every(rule => rule === 'success'))
    }
  }, [onValidity, rule1, rule2, rule3, rule4])

  return (
    <div className="component-password-rules-small">
      <p>{intl.formatMessage({ id: 'password.rules.title' })}</p>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={6}>
          <div className="rule-container">
            {rule1 === 'error' ? (
              <HighlightOffIcon color={'error'} />
            ) : (
              <TaskAlt color={rule1 !== 'initial' ? 'success' : 'disabled'} />
            )}
            <p>{intl.formatMessage({ id: 'password.rules.rule.1' })}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="rule-container">
            {rule2 === 'error' ? (
              <HighlightOffIcon color={'error'} />
            ) : (
              <TaskAlt color={rule2 !== 'initial' ? 'success' : 'disabled'} />
            )}
            <p>{intl.formatMessage({ id: 'password.rules.rule.2' })}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="rule-container">
            {rule3 === 'error' ? (
              <HighlightOffIcon color={'error'} />
            ) : (
              <TaskAlt color={rule3 !== 'initial' ? 'success' : 'disabled'} />
            )}
            <p>{intl.formatMessage({ id: 'password.rules.rule.3' })}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="rule-container">
            {rule4 === 'error' ? (
              <HighlightOffIcon color={'error'} />
            ) : (
              <TaskAlt color={rule4 !== 'initial' ? 'success' : 'disabled'} />
            )}
            <p>{intl.formatMessage({ id: 'password.rules.rule.4' })}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const PasswordRules: React.FC<Props> = ({ variant = 'small', ...props }) => {
  return (
    <>
      {variant === 'small' ? (
        <PasswordRulesSmall {...props} />
      ) : (
        <PasswordRulesLarge {...props} />
      )}
    </>
  )
}

export default PasswordRules
