import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import _ from 'lodash'

import { parseClient } from 'lib/helpers/utilities'

import Client, { ClientForm } from 'models/client'
import { formURL } from './Management/management.utils'

const route = process.env.REACT_APP_APIURL || '/api'

export const getClientById = async (
  workspaceId: number,
  clientId: number
): Promise<Client> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/clients/${clientId}`
    )
    const data = await petition.json()
    const c = parseClient(data)
    return c
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getClientsList = async (
  workspaceId: number,
  search?: string,
  filter2?: string,
  filter3?: string
): Promise<Client[]> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/clients`
    )
    const data = await petition.json()
    const list = _.map(data, parseClient)
    return list
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const createClient = async (
  workspaceId: number,
  form: ClientForm
): Promise<Client> => {
  const client = HTTPClient.getClient()
  try {
    const body = {
      name: form.alias,
      bussinessName: form.name,
      rfc: _.toUpper(form.rfc),
      email: form.email,
      phone: form.phone,
      zipcode: form.zipCode,
      address: null,
      subsidiary: form.branch,
      contact: form.contact,
      idTypePerson: form.type?.idCatalogValues,
      taxPayerId: form.taxPayerId,
      foreing: form.foreign,
      idTaxRegime: form.taxResidence?.idCatalogValues,
    }
    const petition = await client.post(
      `${route}/workspaces/${workspaceId}/clients`,
      body
    )
    const data = await petition.json()
    return parseClient(data)
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const updateClient = async (
  workspaceId: number,
  clientId: number,
  form: ClientForm
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const body = {
      name: form.alias,
      bussinessName: form.name,
      rfc: _.toUpper(form.rfc),
      email: form.email,
      phone: form.phone,
      zipcode: form.zipCode,
      address: null,
      subsidiary: form.branch,
      contact: form.contact,
      idTypePerson: form.type?.idCatalogValues,
      taxPayerId: form.taxPayerId,
      foreing: form.foreign,
      idTaxRegime: form.taxResidence?.idCatalogValues,
    }
    const petition = await client.put(
      `${route}/workspaces/${workspaceId}/clients/${clientId}`,
      body
    )
    await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const deleteClient = async (
  workspaceId: number,
  clientId: number
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    return await client.delete(
      `${route}/workspaces/${workspaceId}/clients/${clientId}`
    )
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

interface CIFFile {
  file: string
  name: string
}

export const uploadCIF = async (
  workspaceId: number,
  file: CIFFile
): Promise<{ uuid: string }> => {
  const client = HTTPClient.getClient()
  try {
    const url = formURL(workspaceId, 'CIF')
    const petition = await client.post(url, file)
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export interface AutocompleteResponse {
  cp: number
  name: string
  regimen: {
    enabled: boolean
    idCatalogValues: number
    label: string
    value: string
  }
  rfc: string
  typePerson: string
}

export const processCIFOCR = async (
  workspaceId: number,
  uuid: string
): Promise<AutocompleteResponse> => {
  const client = HTTPClient.getClient()
  try {
    const url = formURL(workspaceId, `CIF/${uuid}`)
    const petition = await client.get(url)
    return await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAutocompleteData = async (
  workspaceId: number,
  file: CIFFile
): Promise<AutocompleteResponse> => {
  try {
    const uploadedFile = await uploadCIF(workspaceId, file)
    const uuid = uploadedFile.uuid
    const processedFile = processCIFOCR(workspaceId, uuid)
    return processedFile
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}
