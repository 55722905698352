import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'

import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import Button from 'components/Shared/Button/Button'
import PasswordRules from 'components/Shared/PasswordRules/PasswordRules'

import { setNewPassword } from 'actions/user'

import Logo from 'assets/logo.svg'
import Error from '@material-ui/icons/Error'
import { TaskAlt } from 'components/Shared/CustomIcons'
import './style.scss'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

type NewPasswordValues = {
  password: string
  cpassword: string
}

interface NewPasswordFormProps {
  token: string
  onRedirect: (action: string) => void
}

const NewPasswordFormContainer: React.FC<NewPasswordFormProps> = ({
  token,
  onRedirect,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [validForm, setValidForm] = useState<boolean>(false)
  const [validRules, setValidRules] = useState<boolean>(false)
  const dispatch = useThunkDispatch()
  const dialog = useDialog()
  const intl = useIntl()

  const formId = 'newPasswordForm'

  const isConfirmedPasswordEqual = password === confirmPassword

  const onSubmit = async (v: NewPasswordValues) => {
    setLoading(true)
    try {
      await dispatch(setNewPassword(v.password, token))
      await dialog.primaryAsync({
        title: 'new_password.success.title',
        subtitle: 'new_password.success.message',
        icon: TaskAlt,
      })
      onRedirect('success')
    } catch (err) {
      console.log(err)
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'new_password.error.message',
        icon: Error as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container-new-password-form">
      <div className="section logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="section form">
        <h3>{intl.formatMessage({ id: 'new_password.title' })}</h3>
        <p>{intl.formatMessage({ id: 'new_password.text' })}</p>
        <Form
          id={formId}
          lock={loading}
          onSubmit={onSubmit}
          onValidity={(v: boolean) => setValidForm(v && validRules)}>
          <TextField
            name="password"
            onValue={(v: string) => setPassword(v)}
            label={intl.formatMessage({ id: 'form.password' })}
            validate={['isValidPassword']}
            fieldType="password"
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <TextField
            name="cpassword"
            onChange={(v: string) => setConfirmPassword(v)}
            validate={(v: string) => ({
              valid: isConfirmedPasswordEqual || v === password,
              message: 'Las contraseñas no coinciden',
            })}
            label={intl.formatMessage({ id: 'form.confirm_password' })}
            fieldType="password"
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <PasswordRules
            password={password}
            onValidity={(v: boolean) => setValidRules(v)}
          />
        </Form>
      </div>
      <div className="section actions">
        <Button
          id={`newPasswordBtn`}
          form={formId}
          color="secondary"
          type="submit"
          variant="contained"
          fullWidth
          disabled={!validForm}
          activity={loading}>
          {intl.formatMessage({ id: 'button.send' })}
        </Button>
      </div>
    </div>
  )
}

export default NewPasswordFormContainer
