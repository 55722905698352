import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { Grid } from '@mui/material'
import CheckI from '@mui/icons-material/Check'
import DocumentsIcon from '@mui/icons-material/LibraryBooks'
import { UploadedFile } from 'models/general'
import { TAccountingFileTypes } from 'models/accounting.models'
import { ACCOUNTING_PROCESS_STEPS } from 'lib/enums/accounting.enums'
import { isUniqueString } from 'lib/helpers/ioValidations'
import { ACCOUNTING_FILE_TYPE_CATALOGUE } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import FileCard from 'components/Shared/FileCard/FileCard'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AccountingRemiderSuccess from '../AccountingReminderSuccess/AccountingReminderSuccess'
import AccountingStepOneNotification from '../AccountingStepOneNotifications/AccountingStepOneNotifications'
import './styles.scss'
import ISydDocument from 'models/shared.models'

const AccountingStepOne = () => {
  const intl = useIntl()

  const {
    stepChangeLoading,
    filesToUpload,
    filesInProcess,
    currentAccountingProcess,
    accountingReminderEmailSent,
    showAccountingReminderSuccess,
    accountingHistoryReminders,
    goToStep,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDelete,
    handleFileRename,
    handleEditRequiredFileList,
    handleSendAccountingReminders,
    handleShowAccountingReminders,
  } = useAccountingContext()

  const {
    isLoading: remindersLoading,
    isError: reminderError,
    data: remindersData,
  } = accountingHistoryReminders

  const [selectedFile, setSelectedFile] = useState<
    UploadedFile | ISydDocument
  >()
  const [validDialog, setValidDialog] = useState(false)

  const requiredFilesList =
    currentAccountingProcess?.requiredDocuments.map(rd => rd.name) ?? []

  const clarificationRequestFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, [
    'id',
    7,
  ])
  const clarificationFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, [
    'id',
    8,
  ])

  const filteredUploadedFiles = _.filter(
    filesInProcess,
    uf =>
      [clarificationRequestFileType?.type, clarificationFileType?.type].indexOf(
        uf.description as TAccountingFileTypes
      ) === -1
  )

  const listForFileNameValidation = useMemo(
    () => _.map(filesToUpload, d => d.name),
    [filesToUpload]
  )

  const formatName = (file: UploadedFile | ISydDocument) =>
    `${file.name}.${file.extension.toUpperCase()}`
  const formatDate = (dateString?: string) => {
    const date = dateString ? new Date(_.split(dateString, '.')[0]) : new Date()
    const formatted = intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const getFileCardActions = (f: UploadedFile | ISydDocument) => {
    let actions = null
    if (_.get(f, 'uuid') && _.get(f, 'error')) {
      const file = f as UploadedFile
      actions = [
        { label: 'Reintentar', action: () => handleFileRetryUpload(file) },
        { label: 'Renombrar archivo', action: () => setSelectedFile(file) },
        { label: 'Eliminar archivo', action: () => handleFileDelete(file) },
      ]
    } else {
      const file = f as ISydDocument
      actions = [
        { label: 'Renombrar archivo', action: () => setSelectedFile(file) },
        { label: 'Eliminar archivo', action: () => handleFileDelete(file) },
      ]
    }
    return actions
  }

  const handleFormRenameSubmit = async (form: any) => {
    if (!selectedFile) return
    handleFileRename(form.name, selectedFile)
    setSelectedFile(undefined)
  }

  return (
    <div className="step-container step-one">
      <div className="content">
        <div className="step-one-description">
          <p className="step-description">
            Selecciona o arrastra los archivos para subir los documentos
            solicitados. Puedes cargar más de un archivo por documento.
          </p>
          <AccountingStepOneNotification
            data={remindersData}
            isLoadingGettingHistory={remindersLoading}
            isErrorGettingHistory={reminderError}
            isButtonDisabled={
              remindersData && remindersData.reminderAvailable === true
                ? false
                : true
            }
            handleSendReminder={handleSendAccountingReminders}
          />
        </div>

        <Grid container direction="row" alignItems="stretch" spacing={2}>
          <Grid item xs={3}>
            <div className="step-documentation-list">
              <div className="title-documentation-list">
                <DocumentsIcon color="primary" />
                <p>Archivos por subir:</p>
              </div>

              <div className="list">
                {requiredFilesList.length > 0 ? (
                  _.map(requiredFilesList, (d, i) => <p key={i}>{`- ${d}`}</p>)
                ) : (
                  <p>
                    No hay una lista de documentos requeridos para esta
                    solicitud.
                  </p>
                )}
              </div>
              <Button
                id="editListBtn"
                color="warning"
                onClick={handleEditRequiredFileList}>
                Editar lista
              </Button>
            </div>
          </Grid>
          <Grid item xs={9} className="documents-container">
            <div className="uploaded-documentation-list">
              {filesToUpload.length === 0 &&
                filteredUploadedFiles.length === 0 && (
                  <FileUploader
                    className="drop-zone"
                    color="secondary"
                    label="Subir archivos"
                    onValue={handleFileUpload}
                    disabled={requiredFilesList.length === 0}
                  />
                )}
              {(filesToUpload.length > 0 ||
                filteredUploadedFiles.length > 0) && (
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    spacing={2}>
                    <Grid item xs={4}>
                      <FileUploader
                        className="drop-zone"
                        color="secondary"
                        label="Subir archivos"
                        onValue={handleFileUpload}
                      />
                    </Grid>
                    {_.map(filteredUploadedFiles, (file, i) => (
                      <Grid item xs={4} key={i}>
                        <FileCard
                          title={formatName(file)}
                          subtitle={formatDate(file.createdAt)}
                          type="File"
                          actions={getFileCardActions(file)}
                          error={file.error}
                          loading={file.activity}
                        />
                      </Grid>
                    ))}
                    {_.map(filesToUpload, (file, i) => (
                      <Grid item xs={4} key={i}>
                        <FileCard
                          title={formatName(file)}
                          subtitle={formatDate()}
                          type="File"
                          actions={getFileCardActions(file)}
                          error={file.error}
                          loading={file.activity}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="screen-actions align-end">
        {(filesToUpload.length > 0 || filteredUploadedFiles.length > 0) && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CheckI />}
            onClick={() =>
              goToStep(ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION)
            }
            activity={stepChangeLoading}>
            Finalizar carga
          </Button>
        )}
      </div>

      <SydDialog
        open={showAccountingReminderSuccess}
        hidePrimaryButton
        className="dialog-reminder-success">
        <AccountingRemiderSuccess
          email={accountingReminderEmailSent}
          handleClose={() => handleShowAccountingReminders(false)}
        />
      </SydDialog>

      <SydDialog
        open={Boolean(selectedFile)}
        size="sm"
        title="Renombrar un archivo"
        primaryButtonText="Renombrar"
        disablePrimaryButton={!validDialog}
        secondaryButtonText="Cancelar"
        onCancel={() => {
          setSelectedFile(undefined)
          setValidDialog(false)
        }}
        form="renameFileForm"
        sideButtons>
        <Form
          id="renameFileForm"
          onSubmit={handleFormRenameSubmit}
          onValidity={(v: boolean) => setValidDialog(v)}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nombre del archivo"
                value={selectedFile?.name}
                validate={(v: string) =>
                  isUniqueString(v, listForFileNameValidation)
                }
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>
    </div>
  )
}

export default AccountingStepOne
