import _ from 'lodash'
import { List } from 'models/general'
import {
  Payment,
  PaymentFiles,
  IPaymentOrderRequest,
  IBillingInformation,
} from 'models/payments'
import { formatDatetoSpanish } from 'lib/helpers/utilities'
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import {
  ICancelRecurrentPaymentRequest,
  IGetPaymentsHistoryResponse,
  TPaymentInvoiceFileTypeRequest,
} from './payments.models'
import { fileTypeParamsMap } from './payments.utils'

const route = process.env.REACT_APP_APIURL || '/api/'

export const getBillingInformation = async (
  activeWorkspace: number
): Promise<IBillingInformation> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${activeWorkspace}/payment`
    )
    const data: IBillingInformation = await petition.json()
    return {
      ...data,
      startPeriod: formatDatetoSpanish(data.startPeriod, 'DD/MMM YYYY'),
      endPeriod: formatDatetoSpanish(data.endPeriod, 'DD/MMM YYYY'),
      plans: data.plans.map(plan => ({
        ...plan,
        id: plan.idPlan,
        dateLimit: formatDatetoSpanish(plan.dateLimit, 'DD/MMM YYYY'),
      })),
    }
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const requestPlanUpdate = async (message: string): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    await client.post(`${route}/plans/change/notification`, {
      message,
    })
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const deleteRecurrentPayment = async ({
  selectedWorkspaceId,
  planId,
}: ICancelRecurrentPaymentRequest): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    await client.delete(
      `${route}/workspaces/${selectedWorkspaceId}/payment/plan/${planId}/recurrent`
    )
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const createPaymentOrder = async (
  activeWorkspace: number,
  requestBody: IPaymentOrderRequest
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    await client.post(
      `${route}/workspaces/${activeWorkspace}/payment`,
      requestBody
    )
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getPaymentsHistory = async (
  activeWorkspace: number,
  page: number,
  size: number
): Promise<List<Payment[]>> => {
  const client = HTTPClient.getClient()
  try {
    let url = `${route}/workspaces/${activeWorkspace}/payment/record?page=${page + 1}&size=${size}`

    const petition = await client.get(url)
    const data: IGetPaymentsHistoryResponse = await petition.json()
    const paymentHistory = data.record

    const list: Payment[] = _.orderBy(
      paymentHistory,
      ['createdAt'],
      ['desc']
    ).map(payment => ({
      ...payment,
      id: payment.idOrder,
      payDay: formatDatetoSpanish(payment.payDay as string, 'DD/MM/YYYY'),
    }))

    return {
      list,
      page: data.pageNumber,
      totalPages: data.pages,
      total: data.totalElements,
    }
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getPaymentInvoice = async (
  activeWorkspace: number,
  payment: Payment,
  fileType: TPaymentInvoiceFileTypeRequest
): Promise<PaymentFiles> => {
  const client = HTTPClient.getClient()
  try {
    const queryResponse: PaymentFiles = {
      pdf: '',
      xml: '',
      zip: '',
    }

    if (payment.idInvoice) {
      const fileResponse = await client.get(
        `${route}/workspaces/${activeWorkspace}/cfdis/${payment.idInvoice}/download/${fileTypeParamsMap[fileType]}`
      )
      const fileData = await fileResponse.text()
      queryResponse[fileType] =
        `data:application/${fileType};base64,${fileData}`
    }

    return queryResponse
  } catch (err) {
    const error = await httpErrorParser(err)
    throw error
  }
}
