import { TextField } from '@material-ui/core'
import withTextInputController from 'hocs/withTextInputController'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import './styles.scss'

const ControlledTextField = withTextInputController(TextField)

const PlanUpdateModal = () => {
  const {
    isPlanUpdateDialogOpen,
    methods,
    isLoadingPlanUpdateRequest,
    onClosePlanUpdateModalHandler,
    submitPlanUpdateRequest,
  } = usePaymentsContainerContext()

  const {
    control,
    watch,
    formState: { isValid },
  } = methods

  const maxWords = 500

  return (
    <SydDialog
      className="update-plan"
      open={isPlanUpdateDialogOpen}
      title="Quiero cambiar mi plan"
      subtitle="Cuéntanos qué te gustaría cambiar sobre tus planes contratados y nos
          pondremos en contacto contigo."
      onClose={onClosePlanUpdateModalHandler}
      primaryButtonText="Agregar"
      form="updatePlan"
      disablePrimaryButton={!isValid}
      loadingPrimaryButton={isLoadingPlanUpdateRequest}
      secondaryButtonText="Cancelar"
      onCancel={onClosePlanUpdateModalHandler}
      sideButtons>
      <form
        id="updatePlan"
        onSubmit={methods.handleSubmit(submitPlanUpdateRequest)}>
        <ControlledTextField
          control={control}
          name="message"
          helperText={`${watch('message').length}/${maxWords}`}
          inputProps={{ maxLength: maxWords }}
        />
      </form>
    </SydDialog>
  )
}

export default PlanUpdateModal
