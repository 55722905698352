import { useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Checkbox, Grid, FormControlLabel, FormGroup } from '@mui/material'
import { UploadedFile } from 'models/general'
import { TAccountingFileTypes } from 'models/accounting.models'
import RequestedDocument from 'models/documentRequest'
import { ACCOUNTING_PROCESS_STEPS } from 'lib/enums/accounting.enums'
import { isUniqueString } from 'lib/helpers/ioValidations'
import { ACCOUNTING_FILE_TYPE_CATALOGUE } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import { TaskAlt } from 'components/Shared/CustomIcons'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import FileCard from 'components/Shared/FileCard/FileCard'

import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import FileValidationCard from '../FileValidationCard/FileValidationCard'
import './styles.scss'
import ISydDocument from 'models/shared.models'

const AccountingStepTwo = () => {
  const intl = useIntl()

  const {
    stepChangeLoading,
    filesToUpload,
    filesInProcess,
    requiredFileList,
    currentAccountingProcess,
    handleStepBack,
    handleStepSubmit,
    handleFileUpload,
    handleFileDelete,
    handleFileRename,
    handleFileValidation,
    handleFileDownload,
  } = useAccountingContext()

  const [commentsDialog, setCommentsDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState<ISydDocument>()
  const [validCommentsDialog, setValidCommentsDialog] = useState(false)
  const [validRenameDialog, setValidRenameDialog] = useState(false)
  const [missingDocuments, setMissingDocuments] = useState<RequestedDocument[]>(
    []
  )

  const comment = currentAccountingProcess?.comments

  const formatName = (file: UploadedFile | ISydDocument) =>
    `${file.name}.${file.extension.toUpperCase()}`
  const formatDate = (dateString?: string) => {
    const date = dateString ? new Date(_.split(dateString, '.')[0]) : new Date()
    const formatted = intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }

  const clarificationRequestFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, [
    'id',
    7,
  ])
  const clarificationFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, [
    'id',
    8,
  ])

  const filteredFiles = _.filter(
    filesInProcess,
    uf =>
      [clarificationRequestFileType?.type, clarificationFileType?.type].indexOf(
        uf.description as TAccountingFileTypes
      ) === -1
  )

  const filesToValid = comment
    ? _.filter(filteredFiles, f => !f.validated && f.statusText !== 'false') ||
      []
    : filteredFiles
  const uploadedFiles = comment
    ? _.filter(filteredFiles, f => !f.validated && f.statusText === 'false') ||
      []
    : []
  const validFiles = comment
    ? _.filter(filteredFiles, f => f.validated && f.statusText === 'valid') ||
      []
    : []
  const invalidFiles = comment
    ? _.filter(filteredFiles, f => f.validated && f.statusText === 'invalid') ||
      []
    : []

  const listForFileNameValidation = _.map(
    [...uploadedFiles, ...validFiles],
    d => d.name
  )

  const allFilesChecked = _.reduce(
    filesToValid,
    (acc, f) => acc && !_.isUndefined(f.statusText) && f.statusText !== 'false',
    filesToValid.length > 0
  )
  const allFilesValid = _.reduce(
    filesToValid,
    (acc, f) => acc && f.statusText === 'valid',
    filesToValid.length > 0
  )
  const withComment = Boolean(comment)

  const handleCommentsFormSubmit = async (form: any) => {
    const docs = _.join(
      _.map(missingDocuments, md => `- ${md.label} (${md.format})`),
      '\n'
    )
    const comment =
      missingDocuments.length === 0
        ? form.comment
        : `${form.comment}\n\nDocumentos faltantes:\n${docs}`

    handleStepSubmit(ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO, comment)
    setCommentsDialog(false)
    setValidCommentsDialog(false)
    setMissingDocuments([])
  }

  const handleRenameFormSubmit = async (form: any) => {
    if (!selectedFile) return
    handleFileRename(form.name, selectedFile)
    setSelectedFile(undefined)
  }

  return (
    <div className="step-container step-two">
      <div className="content">
        <div className="step-two-description">
          <p>Espera a que el contador valide los documentos.</p>
          <Button
            color="danger"
            variant="contained"
            disabled={allFilesValid ? true : false}
            onClick={() => setCommentsDialog(true)}>
            Solicitar documentos faltantes
          </Button>
        </div>

        <Grid container direction="row" spacing={2}>
          {withComment && (
            <Grid item xs={3}>
              <div className="step-comments">
                <div className="title-comments">
                  <p>Comentarios:</p>
                </div>
                <p className="comments">{comment}</p>
              </div>
            </Grid>
          )}
          <Grid item xs={withComment ? 9 : 12}>
            {filesToValid.length > 0 && (
              <Grid
                container
                spacing={2}
                className="documentation-to-valid-list">
                {_.map(filesToValid, (f, i) => (
                  <Grid item xs={withComment ? 4 : 3} key={i}>
                    <FileValidationCard
                      variant="outlined"
                      title={formatName(f)}
                      valid={f?.statusText === 'valid'}
                      invalid={f?.statusText === 'invalid'}
                      loading={f?.activity}
                      onValidate={v => handleFileValidation(f, v)}
                      onDownload={() => handleFileDownload(f)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {(invalidFiles.length > 0 ||
              (filesToValid.length === 0 &&
                invalidFiles.length === 0 &&
                withComment)) && (
              <Grid
                container
                spacing={2}
                className="uploaded-documentation-list">
                <Grid item xs={withComment ? 4 : 3}>
                  <FileUploader
                    className="drop-zone"
                    color="secondary"
                    label="Subir archivos"
                    onValue={handleFileUpload}
                  />
                </Grid>
                {_.map(filesToUpload, (f, i) => (
                  <Grid item xs={withComment ? 4 : 3} key={i}>
                    <FileCard
                      title={formatName(f)}
                      subtitle={formatDate()}
                      type={'File'}
                      error={f.error}
                      loading={f.activity}
                    />
                  </Grid>
                ))}
                {_.map(uploadedFiles, (f, i) => (
                  <Grid item xs={withComment ? 4 : 3} key={i}>
                    <FileCard
                      title={formatName(f)}
                      subtitle={formatDate(f.createdAt)}
                      type={'File'}
                      actions={[
                        {
                          label: 'Descargar archivo',
                          action: () => handleFileDownload(f),
                        },
                        {
                          label: 'Renombrar archivo',
                          action: () => setSelectedFile(f),
                        },
                        {
                          label: 'Eliminar archivo',
                          action: () => handleFileDelete(f),
                        },
                      ]}
                      error={f.error}
                      loading={f.activity}
                    />
                  </Grid>
                ))}
                {_.map(invalidFiles, (f, i) => (
                  <Grid item xs={withComment ? 4 : 3} key={i}>
                    <FileCard
                      title={formatName(f)}
                      subtitle={formatDate(f.createdAt)}
                      type={'File'}
                      actions={[
                        {
                          label: 'Descargar archivo',
                          action: () => handleFileDownload(f),
                        },
                      ]}
                      error
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {validFiles.length > 0 && (
              <Grid container spacing={2} className="valid-documentation-list">
                <Grid item xs={12}>
                  <div className="valid-documentation-title-container">
                    <TaskAlt color="secondary" fontSize="large" />
                    <h4>Documentos cargados</h4>
                  </div>
                </Grid>
                {_.map(validFiles, (f, i) => (
                  <Grid item xs={withComment ? 4 : 3} key={i}>
                    <FileCard
                      title={formatName(f)}
                      subtitle={formatDate(f.createdAt)}
                      type={'File'}
                      actions={[
                        {
                          label: 'Descargar archivo',
                          action: () => handleFileDownload(f),
                        },
                      ]}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="screen-actions">
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleStepBack}
          activity={stepChangeLoading}>
          Ir a la sección anterior
        </Button>

        {filesToValid.length > 0 && allFilesChecked && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              allFilesValid
                ? handleStepSubmit(ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO)
                : setCommentsDialog(true)
            }
            activity={stepChangeLoading}>
            Finalizar validación
          </Button>
        )}
        {uploadedFiles.length > 0 && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              handleStepSubmit(
                ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO,
                undefined,
                uploadedFiles,
                invalidFiles
              )
            }
            activity={stepChangeLoading}>
            Finalizar carga
          </Button>
        )}
      </div>

      <SydDialog
        open={commentsDialog}
        title="Agregar comentarios"
        subtitle="Antes de terminar la revisión puedes agregar comentarios y seleccionar los documentos faltantes para ayudar al cliente a completar la documentación."
        primaryButtonText="Agregar"
        disablePrimaryButton={!validCommentsDialog}
        secondaryButtonText="Cancelar"
        onCancel={() => {
          setCommentsDialog(false)
          setValidCommentsDialog(false)
        }}
        form="commentsForm"
        sideButtons
        fullWidth>
        <Form
          id="commentsForm"
          onSubmit={handleCommentsFormSubmit}
          onValidity={(v: boolean) => setValidCommentsDialog(v)}
          reset>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="comment"
                inputProps={{ maxLength: 500 }}
                multiline
                minRows={5}
                maxRows={5}
                withCounter
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                {_.map(requiredFileList, (rf, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        name={`${rf.id}`}
                        color="secondary"
                        onChange={e => {
                          const checked = e.target.checked
                          if (checked) {
                            setMissingDocuments(ps => [...ps, rf])
                          } else {
                            setMissingDocuments(ps => {
                              const na = _.filter(ps, d => d.id !== rf.id)
                              return na
                            })
                          }
                        }}
                      />
                    }
                    label={
                      <>
                        {rf.label} - <i>{rf.format}</i>
                      </>
                    }
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Form>
      </SydDialog>

      <SydDialog
        open={Boolean(selectedFile)}
        size="sm"
        title="Renombrar un archivo"
        primaryButtonText="Renombrar"
        disablePrimaryButton={!validRenameDialog}
        secondaryButtonText="Cancelar"
        onCancel={() => {
          setSelectedFile(undefined)
          setValidRenameDialog(false)
        }}
        form="renameFileForm"
        sideButtons>
        <Form
          id="renameFileForm"
          onSubmit={handleRenameFormSubmit}
          onValidity={(v: boolean) => setValidRenameDialog(v)}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nombre del archivo"
                value={selectedFile?.name}
                validate={(v: string) =>
                  isUniqueString(v, listForFileNameValidation)
                }
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>
    </div>
  )
}

export default AccountingStepTwo
