export const ACCOUNTING_PROCESS_STEPS = {
  DOCUMENTOS_SOLICITADOS: 1,
  DOCUMENTOS_EN_REVISION: 2,
  SERVICIO_EN_PROCESO: 3,
  REVISION_DEL_CLIENTE: 4,
  SUBIDA_DOCUMENTOS_MULTIPLES: 5,
  IMPUESTOS_PAGADOS: 6,
} as const

export const ACCOUNTING_STATUS_CATALOGUE = {
  DOCUMENTOS_SOLICITADOS: 1,
  DOCUMENTOS_EN_REVISION: 4,
  SERVICIO_EN_PROCESO: 8,
  REVISION_DEL_CLIENTE: 7,
  SUBIDA_DOCUMENTOS_MULTIPLES: 5,
  IMPUESTOS_PAGADOS: 6,
} as const

export const ACCOUNTING_STEP_FIVE_VALIDATION_STEPS = {
  FIRST_CATEGORY_FILES_UPLOADED: 'FIRST_CATEGORY_FILES_UPLOADED',
  FIRST_CATEGORY_FILES_VALIDATION: 'FIRST_CATEGORY_FILES_VALIDATION',
  THIRD_CATEGORY_FILES_UPLOADED: 'THIRD_CATEGORY_FILES_UPLOADED',
  THIRD_CATEGORY_FILES_VALIDATION: 'THIRD_CATEGORY_FILES_VALIDATION',
  DEFAULT: 'DEFAULT',
} as const
