import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Input,
  MenuItem,
  Modal,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import ListBox from '@material-ui/icons/ListAlt'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { months } from '../../../constants'
import Alert from '@material-ui/lab/Alert'
import useDialog from 'hooks/Shared/useDialog'
import { generateChangeReport } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import {
  downloadFileFromData,
  formatBase64DataAsDataURL,
} from 'lib/helpers/utilities'
import ISydDocument from 'models/shared.models'

interface FormFields {
  startPeriod: string
  endPeriod: string
  singleton: boolean
}

type SynteticPeriodType = {
  month: number
  year: number
}

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // Add 1 to get 1-based month value
  const day = date.getDate()
  const formattedMonth = month.toString().padStart(2, '0')
  const formattedDay = day.toString().padStart(2, '0')
  return `${year}/${formattedMonth}/${formattedDay}`
}

const buttonStyles = {
  borderBottom: '1px solid #000',
  borderRadius: 0,
  paddingBlock: 0,
  color: '#444',
  fontWeight: 400,
}

const modalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const cardStyle = {
  width: 450,
  padding: 26,
  paddingTop: 0,
}

const selectInputStyle = {
  border: '2px solid #999',
  padding: '3px 14px',
  borderRadius: 4,
}

const Title: FC = () => (
  <Toolbar
    style={{
      borderBottom: '1px solid #ddd',
    }}>
    <Box flex={1} />
    <Typography variant="h6">Generar reporte de cambios</Typography>
    <Box flex={1} />
  </Toolbar>
)

const PeriodSelector: FC<{
  onChange?: (period: SynteticPeriodType) => void
}> = ({ onChange }) => {
  const [month, setMonth] = useState<number>(-1)
  const [year, setYear] = useState<number>(-1)

  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    setMonth(event.target.value as number)
  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    setYear(event.target.value as number)

  useEffect(() => {
    if (month === -1) return
    if (year === -1) return
    if (onChange) onChange({ month, year })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year /*, onChange*/])

  const parsedMonths = Object.keys(months).map((key: string) => ({
    label: months[Number(key)],
    value: Number(key) - 1,
  }))

  const years = [2020, 2021, 2022, 2023, 2024, 2025]

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Select
          value={month}
          onChange={handleMonthChange}
          fullWidth
          input={<Input disableUnderline style={selectInputStyle} />}>
          <MenuItem value={-1} selected disabled>
            Mes
          </MenuItem>
          {parsedMonths.map(month => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select
          value={year}
          onChange={handleYearChange}
          fullWidth
          input={<Input disableUnderline style={selectInputStyle} />}>
          <MenuItem value={-1} selected disabled>
            Año
          </MenuItem>
          {years.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

const SingletonField: FC = () => {
  const { register } = useFormContext<FormFields>()

  return (
    <Box marginBottom={2}>
      <FormControlLabel
        control={<Checkbox {...register('singleton')} />}
        label="Sólo este mes"
      />
    </Box>
  )
}

const PeriodsWrapper: FC = () => {
  const [start, setStart] = useState<Date | undefined>()
  const [end, setEnd] = useState<Date | undefined>()
  const { setValue, register } = useFormContext<FormFields>()

  const handleStartPeriodChange = useCallback((period: SynteticPeriodType) => {
    if (period.month === -1) return
    if (period.year === -1) return
    let date = new Date()
    date.setFullYear(period.year)
    date.setMonth(period.month)
    date.setDate(1)
    setStart(date)
  }, [])

  const handleEndPeriodChange = useCallback((period: SynteticPeriodType) => {
    if (period.month === -1) return
    if (period.year === -1) return
    let date = new Date()
    date.setFullYear(period.year)
    date.setMonth(period.month)
    date.setDate(1)
    setEnd(date)
  }, [])

  useEffect(() => {
    if (start) {
      setValue('startPeriod', formatDate(start))
    }
    if (end) {
      setValue('endPeriod', formatDate(end))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end /*, setValue*/])

  return (
    <Box marginY={2}>
      <input type="hidden" {...register('startPeriod')} />
      <input type="hidden" {...register('endPeriod')} />
      <Typography variant="body2">Desde</Typography>
      <PeriodSelector onChange={handleStartPeriodChange} />
      <SingletonField />
      <Typography variant="body2">Hasta</Typography>
      <PeriodSelector onChange={handleEndPeriodChange} />
    </Box>
  )
}

const outlinedButtonStyles = {
  border: '2px solid #444',
  color: '#444',
}

const SubmitButton: FC<{
  setOpen: (open: boolean) => void
  isLoading: boolean
  disabled?: boolean
}> = ({ setOpen, disabled, isLoading }) => {
  const handleCancel = () => setOpen(false)

  return (
    <Box display="flex" gridGap={18} marginTop={6}>
      <Box flex={1} />
      <Button
        variant="outlined"
        onClick={handleCancel}
        disabled={disabled}
        style={outlinedButtonStyles}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="contained"
        disableElevation
        color="secondary"
        style={{ background: disabled ? '#ccc' : '#AED357', color: '#fff' }}
        disabled={disabled}
        startIcon={
          isLoading ? (
            <CircularProgress size={16} style={{ color: 'white' }} />
          ) : null
        }>
        Generar reporte
      </Button>
    </Box>
  )
}

const DialogContent: FC<{
  setOpen: (open: boolean) => void
}> = ({ setOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const methods = useForm<FormFields>()
  const dialog = useDialog()
  const { workspaceInfo } = useConciliationsContainerContext()

  const handleSetError = (message: string) => {
    setError(message)
    setTimeout(() => setError(null), 3000)
  }

  const fetchReport = async (start: string, end?: string) => {
    try {
      setIsLoading(true)
      const data = await generateChangeReport(
        workspaceInfo.activeWorkspace,
        start,
        end
      )
      const file = JSON.parse(await data.text()) as ISydDocument
      file.file &&
        downloadFileFromData(
          `${file.name}.${file.extension}`,
          formatBase64DataAsDataURL(file.file)
        )
      setIsLoading(false)
      setOpen(false)
    } catch (error: any) {
      setIsLoading(false)
      if (error.status === 404) {
        await dialog.dangerAsync({
          title: 'No se ha podido generar el reporte',
          subtitle: 'No existe conciliación para el periodo seleccionado',
          primaryButtonText: 'Continuar',
        })
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido generar el reporte',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const handleSubmit = methods.handleSubmit(data => {
    console.log(data)
    if (error) return
    if (!data.startPeriod)
      return handleSetError('Debe seleccionar un periodo de inicio')
    if (!data.endPeriod && !data.singleton)
      return handleSetError('Debe seleccionar un periodo de fin')
    if (!data.singleton && data.startPeriod > data.endPeriod)
      return handleSetError(
        'El periodo de inicio debe ser menor al periodo de fin'
      )
    if (data.singleton) return fetchReport(data.startPeriod)
    fetchReport(data.startPeriod, data.endPeriod)
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card style={cardStyle}>
          <Title />
          <PeriodsWrapper />
          {error && (
            <Box my={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <SubmitButton
            setOpen={setOpen}
            disabled={isLoading || !!error}
            isLoading={isLoading}
          />
        </Card>
      </form>
    </FormProvider>
  )
}

const ConciliationReportsGenerateReportButton: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Fragment>
      <Button
        startIcon={<ListBox />}
        onClick={() => setIsOpen(true)}
        style={buttonStyles}>
        Generar reporte de cambios
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} style={modalStyles}>
        <DialogContent setOpen={setIsOpen} />
      </Modal>
    </Fragment>
  )
}

export default ConciliationReportsGenerateReportButton
