/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import SearchSatCodeWizardContainer from 'containers/Shared/SearchSatCodeWizardContainer/SearchSatCodeWizardContainer'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from 'components/Shared/Button/Button'
import Dropdown from 'components/FormComponents/Dropdown/Dropdown'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import SearchField from 'components/FormComponents/IOComponents/SearchField'

import {
  createCreditNoteTax,
  deleteCreditNoteConcept,
  deleteCreditNoteTax,
  setCreditNoteConceptValue,
} from 'actions/invoices'
import { searchInCatalogue } from 'actions/catalogues'
import { selectCatalogue, selectSelectedInvoice } from 'lib/helpers/selectors'

import { isNumberInRange } from 'lib/helpers/ioValidations'

import Delete from '@material-ui/icons/Delete'
import Search from '@material-ui/icons/Search'
import './style.scss'

import { CatalogueEntry } from 'models/catalogues'
import { Tax, TaxCatalogue, TaxCatalogueEntry } from 'models/invoice'
import { TAXES_FACTORS } from '../../../../constants'

interface Props {
  impuestosCat: TaxCatalogue
  uuid: string
  title: string
  clave?: CatalogueEntry | null
  unidad?: CatalogueEntry | null
  precio?: string
  cantidad?: string
  descuento?: string
  descripcion?: string
  impuestos?: Tax[]
  importacion?: string
  predial?: string
  objImp?: CatalogueEntry | null
  id: number
}

const AddConceptCreditNoteV2Container = (props: Props) => {
  // const { id, importacion, predial } = props;
  const intl = useIntl()
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()

  const unidadesCat = useSelector(selectCatalogue('unidades'))
  const objImpCat = useSelector(selectCatalogue('objImp'))
  const selectedInvoice = useSelector(selectSelectedInvoice)
  const invoiceConcepts = selectedInvoice?.conceptos

  const [selectedTax, setSelectedTax] = useState<TaxCatalogueEntry | null>(null)
  const [rate, setRate] = useState<string>('')
  const [fee, setFee] = useState<string>('')

  const [openWizard, setOpenWizard] = useState(false)

  const addTax = () => {
    if (!selectedTax) return
    const tax: Tax = {
      ...selectedTax,
      uuid: uuid(),
      selectedValue:
        selectedTax.factor === 'Tasa' ? parseFloat(rate) : parseFloat(fee),
    }
    dispatch(createCreditNoteTax(props.uuid, tax))
    setRate('')
    setFee('')
    setSelectedTax(null)
  }

  const saveValueToStore = useCallback(
    (name: string, value: any) => {
      dispatch(setCreditNoteConceptValue(props.uuid, name, value))
    },
    [props.uuid, dispatch]
  )

  const getTaxValue = (factor: string, value: number) => {
    switch (factor) {
      case 'Tasa': {
        if (!value) return ''
        const formatted = intl.formatNumber(value, {
          style: 'percent',
          minimumFractionDigits: 2,
        })
        return formatted
      }
      case 'Cuota': {
        if (!value) return ''
        const formatted = intl.formatNumber(value, {
          style: 'currency',
          currency: 'MXN',
          currencyDisplay: 'narrowSymbol',
        })
        return formatted
      }
      default:
        return ''
    }
  }

  const handleClaveSatItems = useCallback(
    async value => {
      const data = await thunkDispatch(searchInCatalogue('items', value))
      let originConcepts = invoiceConcepts?.map(
        (concept: any) => concept.satkey.value
      )
      originConcepts?.push('84111506')
      let response = data.map(option => {
        const suggest = originConcepts?.some(
          (concept: any) => option.value === concept
        )
        return {
          suggest: suggest ? 'claves sugeridas' : '',
          ...option,
        }
      })
      response.sort((a, b) => b.suggest.localeCompare(a.suggest))
      return response
    },
    [thunkDispatch, invoiceConcepts]
  )

  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  //cargar por defecto ciertos valores de campos
  useEffect(() => {
    if (_.isNil(props.clave)) {
      handleClaveSatItems('84111506')
      saveValueToStore('satKey', {
        idCatalogValues: 53988,
        label: 'Servicios de facturación',
        value: '84111506',
        enabled: true,
      })
    }
    if (_.isNil(props.unidad)) {
      const unidad = _.filter(unidadesCat, m => m.value === 'ACT')[0]
      saveValueToStore('claveUnitSat', unidad)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTaxSelect = (v: any) => {
    setSelectedTax(v)
    setRate('')
    setFee('')
  }

  const isDisabledAddTaxButton = useMemo(() => {
    if (!selectedTax) return true
    const { factor } = selectedTax
    if (factor === TAXES_FACTORS.exent) return false
    if (factor === TAXES_FACTORS.rate && rate !== '') return false
    if (factor === TAXES_FACTORS.fee && fee !== '') return false
    return true
  }, [selectedTax, rate, fee])

  return (
    <Dropdown
      id={`conceptDropdownBtn_${props.id}`}
      title={props.title}
      className="add-invoice-product-form"
      capitalize
      withDivider
      defaultExpanded>
      <Grid container spacing={4} className="basic-information">
        <Grid item xs={12} className="item-actions">
          <Button
            id={`deleteConceptBtn_${props.id}`}
            startIcon={<Delete />}
            onClick={() => dispatch(deleteCreditNoteConcept(props.uuid))}>
            Eliminar Concepto
          </Button>
        </Grid>
        <Grid item xs={4}>
          <SearchField
            name={`claveSat_${props.id}`}
            label="Clave SAT"
            onSearch={(v: string) => handleClaveSatItems(v)}
            groupBy={(option: any) => option.suggest}
            value={props.clave}
            onValue={(v: CatalogueEntry) => saveValueToStore('satKey', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            getOptionSelected={(
              option: CatalogueEntry,
              value: CatalogueEntry
            ) => option.idCatalogValues === value.idCatalogValues}
            renderOption={(o: any) => renderCatalogueOption(o)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setOpenWizard(true)} size="small">
                  <Search />
                </IconButton>
              ),
            }}
            fullWidth
            required
            exclude
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            name={`unidad_${props.id}`}
            label="Unidad o medida"
            catalogue={unidadesCat}
            value={props.unidad}
            onValue={(v: CatalogueEntry) => saveValueToStore('claveUnitSat', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            renderOption={(o: any) => renderCatalogueOption(o)}
            getOptionSelected={(
              option: CatalogueEntry,
              value: CatalogueEntry
            ) => option.idCatalogValues === value.idCatalogValues}
            fullWidth
            required
            exclude
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            name={`objImp_${props.id}`}
            label="Objeto de Impuesto"
            catalogue={objImpCat}
            value={props.objImp}
            onValue={(v: CatalogueEntry) => saveValueToStore('objImp', v)}
            getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
            renderOption={(o: any) => renderCatalogueOption(o)}
            getOptionSelected={(
              option: CatalogueEntry,
              value: CatalogueEntry
            ) => option.idCatalogValues === value.idCatalogValues}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <NumericField
            name={`precioUnitario_${props.id}`}
            label="Precio unitario"
            value={props.precio}
            onValue={(v: string) => saveValueToStore('price', v)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            required
            exclude
          />
        </Grid>
        <Grid item xs={4}>
          <NumericField
            name={`cantidad_${props.id}`}
            label="Cantidad"
            value={props.cantidad}
            onValue={(v: string) => saveValueToStore('cantidad', v)}
            fullWidth
            required
            exclude
          />
        </Grid>
        <Grid item xs={4}>
          <NumericField
            name={`descuento_${props.id}`}
            label="Descuento"
            value={props.descuento}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onValue={(v: string) => saveValueToStore('descuento', v)}
            fullWidth
            exclude
          />
        </Grid>
        <Grid item xs={8}>
          <p className="form-label">Descripción</p>
          <TextField
            variant="outlined"
            name={`description_${props.id}`}
            value={props.descripcion}
            onValue={(v: string) => saveValueToStore('description', v)}
            inputProps={{ maxLength: 500 }}
            multiline
            minRows={4}
            maxRows={4}
            withCounter
            fullWidth
            required
            exclude
          />
        </Grid>
      </Grid>
      <Dropdown
        id={`impuestosDropdown_${props.id}`}
        title="Impuestos"
        headerBackgroundColor="gray"
        bordered>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <AutocompleteField
              name={`impuesto_${props.id}`}
              label="Impuesto"
              catalogue={props.impuestosCat}
              value={selectedTax}
              onValue={handleTaxSelect}
              getOptionLabel={(o: any) =>
                `${_.padStart(o.id, 2, '0')} - ${o.label}`
              }
              fullWidth
              exclude
            />
          </Grid>
          {!_.isEmpty(selectedTax) &&
            _.isArray(
              selectedTax?.value
            ) /*&& (_.get(selectedTax, "value.length", 0) === 1)*/ && (
              <Grid item xs={4}>
                <AutocompleteField
                  name={`tasa_${props.id}`}
                  label="Tasa (%)"
                  catalogue={_.map(selectedTax?.value, v => ({
                    label: `${v * 100}%`,
                    value: v,
                  }))}
                  value={{ label: `${Number(rate) * 100}%`, value: rate }}
                  onValue={(v: any) => setRate(v.value)}
                  getOptionLabel={(o: any) => o.label}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  exclude
                />
              </Grid>
            )}
          {!_.isEmpty(selectedTax) &&
            _.isArray(selectedTax?.value) &&
            _.get(selectedTax, 'value.length', 0) > 1 && (
              <Grid item xs={4}>
                <AutocompleteField
                  name={`tasa_${props.id}`}
                  label="Tasa (%)"
                  catalogue={_.map(selectedTax?.value, v => ({
                    label: `${v * 100}%`,
                    value: v,
                  }))}
                  value={{ label: `${Number(rate) * 100}%`, value: rate }}
                  onValue={(v: any) => setRate(v.value)}
                  getOptionLabel={(o: any) => o.label}
                  fullWidth
                  exclude
                />
              </Grid>
            )}
          {!_.isEmpty(selectedTax) && !_.isArray(selectedTax?.value) && (
            <Grid item xs={4}>
              <NumericField
                name={`cuota_${props.id}`}
                label="Cuota ($)"
                value={fee}
                onValue={(v: string) => setFee(v)}
                validate={(v: string) => {
                  const min =
                    Number(props.cantidad) *
                    Number(props.precio) *
                    _.get(selectedTax, 'value.min', 0)
                  const max =
                    Number(props.cantidad) *
                    Number(props.precio) *
                    _.get(selectedTax, 'value.max', 0)
                  return isNumberInRange(v, min, max)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
                exclude
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              id={`addTaxBtn_${props.id}`}
              variant="outlined"
              color="warning"
              disabled={
                isDisabledAddTaxButton
                // (_.indexOf([1, 2, 4, 5, 6], selectedTax?.id) > -1)
                // ? !(!!selectedTax && !!rate)
                // : !(!!selectedTax && !!fee)
              }
              onClick={addTax}>
              Agregar
            </Button>
          </Grid>
          {!_.isEmpty(props.impuestos) && (
            <Grid item xs={12} className="table-taxes">
              <Table size="small">
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell
                      className="table-cell"
                      style={{ width: '150px' }}>
                      Tipo de impuesto
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{ width: '300px' }}>
                      Impuesto
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{ width: '150px' }}>
                      Tasa o cuota
                    </TableCell>
                    <TableCell className="table-cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(props.impuestos, (tax, i) => (
                    <TableRow className="table-row" key={tax.uuid}>
                      <TableCell className="table-cell">{tax.type}</TableCell>
                      <TableCell className="table-cell">
                        {`${_.padStart(_.get(tax.sat, 'value', ''), 3, '0')}
                            -
                            ${_.get(tax.sat, 'label', '')}`}
                      </TableCell>
                      <TableCell className="table-cell">
                        {getTaxValue(tax.factor, tax.selectedValue)}
                      </TableCell>
                      <TableCell className="table-cell table-actions">
                        <IconButton
                          id={`deleteTaxBtn_${props.id}_${i}`}
                          onClick={() =>
                            dispatch(deleteCreditNoteTax(props.uuid, tax.uuid))
                          }
                          size="small">
                          <Delete className="delete" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </Dropdown>
      {/* <Dropdown title="Importacion" headerBackgroundColor="gray" bordered>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <NumericField
              name="pedimento"
              label="No. de pedimento"
              value={importacion}
              onValue={(v: string) => saveValueToStore('importacion', v)}
              fullWidth
              exclude
            />
          </Grid>
        </Grid>
      </Dropdown>
      <Dropdown title="Cuenta predial" headerBackgroundColor="gray" bordered>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <NumericField
              name="predial"
              label="No. de identificación"
              value={predial}
              onValue={(v: string) => saveValueToStore('predial', v)}
              fullWidth
              exclude
            />
          </Grid>
        </Grid>
      </Dropdown> */}
      <SearchSatCodeWizardContainer
        open={openWizard}
        onSelect={(v: CatalogueEntry) => saveValueToStore('claveSat', v)}
        onCancel={() => setOpenWizard(false)}
      />
    </Dropdown>
  )
}

export default AddConceptCreditNoteV2Container
