import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { FC, useEffect, useState } from 'react'
import {
  useCustomFieldStyles,
  useCustomUnderlineFieldStyle,
} from 'lib/helpers/utilities'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import './styles.scss'
import { useSelector } from 'react-redux'
import { selectActiveWorkspace } from 'lib/helpers/selectors'

interface OptionType {
  label: string
  value: string
}

interface FieldType {
  name: string
  placeholder?: string
  enabled: boolean
  defaultValue: string
  options?: OptionType[]
}

interface EnabledFields {
  name: FieldType
  fromDate: FieldType
  toDate: FieldType
  status?: FieldType
  type?: FieldType
}

interface FilterInterface {
  fields: EnabledFields
  onChange?: (values: FormValues) => void
  singleton?: boolean
}

type FormValues = {
  name: string
  fromDate: string
  toDate: string
  status?: string
  type?: string
}

const NameField: FC<{
  field: FieldType
}> = ({ field }) => {
  const classes = useCustomFieldStyles()
  const { register } = useFormContext()
  return (
    <Input
      {...register(field.name)}
      fullWidth
      classes={classes}
      placeholder={field.placeholder}
      startAdornment={
        <Box marginX={1} display="flex" alignItems="center" color="#999">
          <SearchIcon />
        </Box>
      }
      disableUnderline
    />
  )
}

const DateField: FC<{
  field: FieldType
}> = ({ field }) => {
  const { register, setValue } = useFormContext()
  const [date, setDate] = useState<Date | null>(null)
  const classes = useCustomUnderlineFieldStyle()

  useEffect(
    () => {
      if (!date && field.defaultValue) {
        setDate(new Date(field.defaultValue))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*date, field.defaultValue*/
    ]
  )

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date && date.isValid()) {
      setDate(date.toDate())
      setValue(field.name, date.format('DD/MM/yyyy'))
    }
  }

  // max date to a year from now
  const maxDate = new Date().setFullYear(new Date().getFullYear() + 1)
  // min date to 5 years ago
  const minDate = new Date().setFullYear(new Date().getFullYear() - 5)

  return (
    <Box position="relative">
      <KeyboardDatePicker
        value={date}
        format="DD/MM/yyyy"
        onChange={handleDateChange}
        views={['year', 'month', 'date']}
        autoOk
        fullWidth
        helperText={null}
        maxDate={maxDate}
        minDate={minDate}
        inputProps={{
          ...register(field.name, {
            pattern: {
              value: /^\d{2}\/\d{2}\/\d{4}$/,
              message: 'Formato de fecha inválido',
            },
          }),
          placeholder: 'DD / MM / AA',
          classes,
        }}
        style={{
          borderBottom: '1px solid #444',
          marginBottom: -2,
        }}
      />
    </Box>
  )
}

const SelectField: FC<{
  field: FieldType
  type?: string
}> = ({ field, type }) => {
  const [value, setValue] = useState<string>('')
  const { register, setValue: setFieldValue } = useFormContext()
  const classes = useCustomUnderlineFieldStyle()

  useEffect(
    () => {
      if (value === '' && field.defaultValue) {
        setValue(field.defaultValue)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*field.defaultValue, value*/
    ]
  )

  useEffect(() => {
    setFieldValue(field.name, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value /*, field.name, setFieldValue*/])

  const labelId = `select-${field.name}-label`

  return (
    <>
      <input type="hidden" {...register(field.name)} value={value} />
      <FormControl
        fullWidth
        style={{
          marginTop: -15,
        }}>
        {field.placeholder && (
          <InputLabel id={labelId}>{field.placeholder}</InputLabel>
        )}

        <Select
          labelId={labelId}
          fullWidth
          classes={classes}
          label="Tipo"
          value={value}
          onChange={event => {
            setValue(event.target.value as string)
          }}>
          {field.options?.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

const ConciliationTableFilter: FC<FilterInterface> = ({
  fields,
  onChange,
  singleton,
}) => {
  const activeWorkspace = useSelector(selectActiveWorkspace)

  const methods = useForm<FormValues>({
    defaultValues: {
      name: fields.name.defaultValue,
      fromDate: fields.fromDate.defaultValue,
      toDate: fields.fromDate.defaultValue,
      status: fields.status?.defaultValue,
      type: fields.type?.defaultValue,
    },
  })

  const handleSubmit = (data: any) => {
    if (onChange) {
      onChange(data)
    }
  }

  useEffect(() => {
    methods.reset()
  }, [activeWorkspace, methods])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        className="conciliation-table-filter-component">
        <div className="first_inputs">
          {fields.name.enabled && <NameField field={fields.name} />}
          {fields.type && <SelectField type={'type'} field={fields.type} />}
        </div>
        <div className="second_inputs">
          <div className="inputs">
            {fields.fromDate.enabled && <DateField field={fields.fromDate} />}
            {fields.fromDate.enabled && fields.toDate.enabled && <>A</>}
            {fields.toDate.enabled && <DateField field={fields.toDate} />}
            {fields.status && <SelectField field={fields.status} />}
          </div>
          <Box className="submit-button-wrapper">
            <Button
              type="submit"
              className="submit-button"
              disableElevation
              variant="outlined">
              Buscar
            </Button>
          </Box>
        </div>
      </form>
    </FormProvider>
  )
}

export default ConciliationTableFilter
