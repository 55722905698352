import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import { FC, Fragment, useCallback, useMemo } from 'react'
import './styles.scss'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import { UploadedFile } from 'models/general'
import { NPS_ACCEPTED_FILES } from '../../../../constants'
import CloseIcon from '@material-ui/icons/Close'
import PostAddIcon from '@material-ui/icons/PostAdd'

const NPSButton: FC<{
  handleSumbit: (file: UploadedFile) => void
  isLoading: boolean
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  file: UploadedFile | undefined
  setFile: (file: UploadedFile | undefined) => void
}> = ({ handleSumbit, isLoading, isOpen, setIsOpen, file, setFile }) => {
  const handleFile = (files: UploadedFile[]) => {
    const file = files[0]
    setFile(file)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOpen = () => {
    handleToggle()
  }

  const handleRemove = () => setFile(undefined)

  const handleFileUpload = useCallback(() => {
    file !== undefined && handleSumbit(file)
  }, [handleSumbit, file])

  const dropzoneLabel = useMemo(() => {
    if (file && !isLoading) return `${file.name}.${file.extension}`
    if (file && isLoading) return `Cargando ${file.name}.${file.extension}`
    return 'Subir archivo NPS.xlsx'
  }, [file, isLoading])

  return (
    <Fragment>
      <Button className="button" size="large" onClick={handleOpen}>
        <PostAddIcon fontSize="large" />
        <Typography className="text">
          Actualizar información del contador
        </Typography>
      </Button>
      <Dialog
        open={isOpen}
        className="dialog"
        fullWidth
        maxWidth="sm"
        keepMounted
        PaperComponent={({ children }) => (
          <Box className="dialog-paper">{children}</Box>
        )}>
        <Box className="close">
          <IconButton
            onClick={() => {
              handleRemove()
              handleToggle()
            }}
            disabled={isLoading}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent className="dialog-content">
          <Box className="title">Sube el NPS</Box>
          <Box className="subtitle">
            Sube tu archivo NPS en formato XLSX para que se autocomplete la
            información de manera automática.
          </Box>
          <Box className="dropzone-parent">
            <FileUploader
              label={dropzoneLabel}
              onValue={handleFile}
              accept={NPS_ACCEPTED_FILES}
              limit={1}
              color="secondary"
              className="dropzone"
              disabled={file !== undefined}
              loading={isLoading}
            />
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          {file !== undefined ? (
            <Button
              className="button cancel"
              onClick={handleRemove}
              disabled={isLoading}>
              Remover
            </Button>
          ) : (
            <Button
              className="button cancel"
              onClick={handleToggle}
              disabled={isLoading}>
              Cancelar
            </Button>
          )}
          <Button
            className="button primary"
            disabled={file === undefined || isLoading}
            onClick={handleFileUpload}>
            Subir
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default NPSButton
