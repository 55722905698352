import { createContext, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Check from '@mui/icons-material/Check'
import { Collapse, Divider, Drawer, Grid } from '@mui/material'
import { ACCOUNTING_PROCESS_STEPS } from 'lib/enums/accounting.enums'
import { formatDate, formatName } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import Checklist from 'components/Accounting/Checklist/CheckList'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AccountingCommentHistory from '../AccountingCommentHistory/AccountingCommentHistory'
import FileValidationCard from '../FileValidationCard/FileValidationCard'
import useAccountingStepThree, {
  IUseAccountingStepThree,
} from './useAccountingStepThree'
import './styles.scss'
import Activity from 'components/Shared/Activity/Activity'

const AccountingStepThreeContext =
  createContext<IUseAccountingStepThree | null>(null)

const AccountingStepThree = () => {
  const intl = useIntl()

  const {
    updateAccountingProcessLoading,
    stepChangeLoading,
    checklist,
    stepThreeComments,
    handleStepBack,
    handleStepSubmit,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDelete,
    handleFileDownload,
  } = useAccountingContext()

  const stepThreeState = useAccountingStepThree()
  const contextValue = useMemo(() => stepThreeState, [stepThreeState])

  const {
    viewClarification,
    selectedFile,
    validRenameDialog,
    validForm,
    isOpenCommentHistory,
    latestComment,
    clarificationFileStatus,
    prev,
    currentChecklist,
    clarificationRequestFileType,
    clarificationFileType,
    proxyClarificationRequestFile,
    proxyClarificationFile,
    uploadedClarificationRequestFile,
    clarificationRequestFileToValidate,
    clarificationFileToValidate,
    uploadedClarificationFile,
    uploadedAndValidatedClarificationFile,
    setViewClarification,
    setSelectedFile,
    setValidRenameDialog,
    setValidForm,
    setIsOpenCommentHistory,
    setClarificationFileStatus,
    handleCompleteChecklist,
    handleIncompleteChecklist,
    handleRenameFormSubmit,
    handleClarificationRequestFormSubmit,
    handleClarificationFormSubmit,
    handleClarificationCheckFormSubmit,
  } = stepThreeState

  return (
    <div className="step-container step-three">
      <AccountingStepThreeContext.Provider value={contextValue}>
        <div className="content">
          {checklist.map(({ idCheckList, status, lastUpdate }, i) => (
            <div key={i} className="element-container">
              <Checklist
                step={idCheckList}
                text={intl.formatMessage({
                  id: `accounting.checklist.${idCheckList}`,
                })}
                status={status}
                loading={updateAccountingProcessLoading}
                disabled={
                  idCheckList === 5 &&
                  viewClarification &&
                  !uploadedAndValidatedClarificationFile
                }
                date={status === 2 ? lastUpdate : ''}
                previous={prev?.idCheckList === idCheckList}
                current={currentChecklist?.idCheckList === idCheckList}
                onComplete={handleCompleteChecklist}
                onIncomplete={handleIncompleteChecklist}
                onClarification={
                  currentChecklist?.idCheckList === idCheckList &&
                  idCheckList === 5
                    ? () => setViewClarification(!viewClarification)
                    : undefined
                }
              />
              {currentChecklist?.idCheckList === idCheckList &&
                idCheckList === 5 &&
                !updateAccountingProcessLoading && (
                  <Collapse in={viewClarification}>
                    {!clarificationRequestFileToValidate && (
                      <Form
                        onSubmit={handleClarificationRequestFormSubmit}
                        onValidity={setValidForm}
                        reset>
                        <Grid
                          container
                          spacing={2}
                          className="step-addon clarification-request-form">
                          <Grid item xs={4}>
                            <p className="section">
                              a) Adjunta la solicitud de aclaraciones
                            </p>
                            {!proxyClarificationRequestFile &&
                              !uploadedClarificationRequestFile && (
                                <FileUploader
                                  className="file-zone"
                                  color="secondary"
                                  label="Subir archivo"
                                  onValue={handleFileUpload}
                                  type={clarificationRequestFileType?.type}
                                  documentType={1}
                                />
                              )}
                            {!!proxyClarificationRequestFile &&
                              !uploadedClarificationRequestFile && (
                                <FileCardAlt
                                  title={formatName(
                                    proxyClarificationRequestFile
                                  )}
                                  subtitle={formatDate()}
                                  type={'File'}
                                  actions={[
                                    {
                                      label: 'Reintentar',
                                      action: () =>
                                        handleFileRetryUpload(
                                          proxyClarificationRequestFile
                                        ),
                                    },
                                    {
                                      label: 'Renombrar archivo',
                                      action: () =>
                                        setSelectedFile(
                                          proxyClarificationRequestFile
                                        ),
                                    },
                                    {
                                      label: 'Eliminar archivo',
                                      action: () =>
                                        handleFileDelete(
                                          proxyClarificationRequestFile
                                        ),
                                    },
                                  ]}
                                  error={proxyClarificationRequestFile.error}
                                  loading={
                                    proxyClarificationRequestFile.activity
                                  }
                                  className="file-zone"
                                />
                              )}
                            {!proxyClarificationRequestFile &&
                              !!uploadedClarificationRequestFile && (
                                <FileCardAlt
                                  title={formatName(
                                    uploadedClarificationRequestFile
                                  )}
                                  subtitle={formatDate()}
                                  type={'File'}
                                  onFileClick={() =>
                                    handleFileDownload(
                                      uploadedClarificationRequestFile
                                    )
                                  }
                                  actions={[
                                    {
                                      label: 'Renombrar archivo',
                                      action: () =>
                                        setSelectedFile(
                                          uploadedClarificationRequestFile
                                        ),
                                    },
                                    {
                                      label: 'Eliminar archivo',
                                      action: () =>
                                        handleFileDelete(
                                          uploadedClarificationRequestFile
                                        ),
                                    },
                                  ]}
                                  error={uploadedClarificationRequestFile.error}
                                  loading={
                                    uploadedClarificationRequestFile.activity
                                  }
                                  className="file-zone"
                                />
                              )}
                          </Grid>
                          <Grid item xs={8}>
                            <p className="section">b) Agrega un comentario</p>
                            <TextField
                              variant="outlined"
                              name="comment"
                              inputProps={{ maxLength: 500 }}
                              multiline
                              minRows={5}
                              maxRows={5}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} className="actions">
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              startIcon={<Check />}
                              disabled={
                                !uploadedClarificationRequestFile || !validForm
                              }>
                              Finalizar Carga
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                    {!!clarificationRequestFileToValidate &&
                      !clarificationFileToValidate &&
                      !uploadedAndValidatedClarificationFile && (
                        <Form onSubmit={handleClarificationFormSubmit} reset>
                          <Grid
                            container
                            spacing={2}
                            className="step-addon clarification-form">
                            <Grid item xs={12}>
                              <p>
                                Estimado cliente, es necesario que realices una
                                aclaración sobre los movimientos bancarios
                                detallados en el documento adjunto.
                              </p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <p className="section">
                                a) Descarga y revisa el archivo
                              </p>
                              {!!clarificationRequestFileToValidate && (
                                <FileCardAlt
                                  title={formatName(
                                    clarificationRequestFileToValidate
                                  )}
                                  subtitle={formatDate()}
                                  type={'File'}
                                  onFileClick={() =>
                                    handleFileDownload(
                                      clarificationRequestFileToValidate
                                    )
                                  }
                                  error={
                                    clarificationRequestFileToValidate.error
                                  }
                                  loading={
                                    clarificationRequestFileToValidate.activity
                                  }
                                  className="file-zone"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <p className="section">
                                b) Adjunta el archivo con tus aclaraciones
                              </p>
                              {!proxyClarificationFile &&
                                !uploadedClarificationFile && (
                                  <FileUploader
                                    className="file-zone"
                                    color="secondary"
                                    label="Subir archivo"
                                    onValue={handleFileUpload}
                                    type={clarificationFileType?.type}
                                    documentType={2}
                                  />
                                )}
                              {!!proxyClarificationFile &&
                                !uploadedClarificationFile && (
                                  <FileCardAlt
                                    title={formatName(proxyClarificationFile)}
                                    subtitle={formatDate()}
                                    type={'File'}
                                    actions={[
                                      {
                                        label: 'Reintentar',
                                        action: () =>
                                          handleFileRetryUpload(
                                            proxyClarificationFile
                                          ),
                                      },
                                      {
                                        label: 'Renombrar archivo',
                                        action: () =>
                                          setSelectedFile(
                                            proxyClarificationFile
                                          ),
                                      },
                                      {
                                        label: 'Eliminar archivo',
                                        action: () =>
                                          handleFileDelete(
                                            proxyClarificationFile
                                          ),
                                      },
                                    ]}
                                    error={proxyClarificationFile.error}
                                    loading={proxyClarificationFile.activity}
                                    className="file-zone"
                                  />
                                )}
                              {!proxyClarificationFile &&
                                !!uploadedClarificationFile && (
                                  <FileCardAlt
                                    title={formatName(
                                      uploadedClarificationFile
                                    )}
                                    subtitle={formatDate()}
                                    type={'File'}
                                    onFileClick={() =>
                                      handleFileDownload(
                                        uploadedClarificationFile
                                      )
                                    }
                                    actions={[
                                      {
                                        label: 'Renombrar archivo',
                                        action: () =>
                                          setSelectedFile(
                                            uploadedClarificationFile
                                          ),
                                      },
                                      {
                                        label: 'Eliminar archivo',
                                        action: () =>
                                          handleFileDelete(
                                            uploadedClarificationFile
                                          ),
                                      },
                                    ]}
                                    error={uploadedClarificationFile.error}
                                    loading={uploadedClarificationFile.activity}
                                    className="file-zone"
                                  />
                                )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="accounting-step-three-comments-column">
                              <p className="section">c) Agrega un comentario</p>
                              <div className="comments-column-content">
                                <div className="comments-column-content-message">
                                  <p>Último comentario:</p>
                                  <span className="content-message">
                                    {latestComment ??
                                      stepThreeComments[0]?.text ??
                                      ''}
                                  </span>
                                </div>

                                <Button
                                  onClick={() => setIsOpenCommentHistory(true)}
                                  endIcon={<ArrowForwardIcon />}>
                                  Ver historial y agregar un comentario
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={12} className="actions">
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                startIcon={<Check />}
                                disabled={!uploadedClarificationFile}>
                                Finalizar Carga
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    {!!clarificationRequestFileToValidate &&
                      !!clarificationFileToValidate &&
                      !uploadedAndValidatedClarificationFile && (
                        <Form
                          onSubmit={handleClarificationCheckFormSubmit}
                          onValidity={setValidForm}
                          reset>
                          <Grid
                            container
                            spacing={2}
                            className="step-addon clarification-check-form">
                            <Grid item xs={12} className="form-comments">
                              <div className="title-comments">
                                <p>Último comentario:</p>
                              </div>
                              <div className="comments">
                                <p>
                                  {latestComment ??
                                    stepThreeComments[0]?.text ??
                                    ''}
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <p className="section">
                                a) Valida la información proporcionada
                              </p>
                              {!proxyClarificationRequestFile &&
                                !uploadedClarificationRequestFile &&
                                !!clarificationFileToValidate && (
                                  <FileValidationCard
                                    variant="outlined"
                                    title={formatName(
                                      clarificationFileToValidate
                                    )}
                                    valid={clarificationFileStatus === 'valid'}
                                    invalid={
                                      clarificationFileStatus === 'invalid'
                                    }
                                    loading={
                                      clarificationFileToValidate.activity
                                    }
                                    onValidate={(
                                      value?: 'valid' | 'invalid'
                                    ) => {
                                      setClarificationFileStatus(value)
                                      value === 'valid'
                                        ? setValidForm(true)
                                        : setValidForm(false)
                                    }}
                                    onDownload={() =>
                                      handleFileDownload(
                                        clarificationFileToValidate
                                      )
                                    }
                                    className="file-zone"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={8}>
                              {clarificationFileStatus === 'invalid' && (
                                <>
                                  <p className="section">
                                    b) Agrega un comentario
                                  </p>
                                  <TextField
                                    variant="outlined"
                                    name="comment"
                                    inputProps={{ maxLength: 500 }}
                                    multiline
                                    minRows={5}
                                    maxRows={5}
                                    fullWidth
                                    required
                                    onChange={(text: string) =>
                                      text !== '' && setValidForm(true)
                                    }
                                  />
                                </>
                              )}
                            </Grid>
                            <Grid item xs={12} className="actions">
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                startIcon={<Check />}
                                disabled={!validForm}>
                                Finalizar Carga
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    {!!clarificationRequestFileToValidate &&
                      !!uploadedAndValidatedClarificationFile && (
                        <Grid
                          container
                          spacing={2}
                          className="step-addon clarification-process-form">
                          <Grid item xs={4}>
                            <p className="section">
                              a) Solicitud de aclaraciones
                            </p>
                            <FileCardAlt
                              title={formatName(
                                clarificationRequestFileToValidate
                              )}
                              subtitle={formatDate()}
                              type={'File'}
                              onFileClick={() =>
                                handleFileDownload(
                                  clarificationRequestFileToValidate
                                )
                              }
                              error={clarificationRequestFileToValidate.error}
                              loading={
                                clarificationRequestFileToValidate.activity
                              }
                              className="file-zone"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <p className="section">b) Aclaraciones</p>
                            <FileCardAlt
                              title={formatName(
                                uploadedAndValidatedClarificationFile
                              )}
                              subtitle={formatDate()}
                              type={'File'}
                              onFileClick={() =>
                                handleFileDownload(
                                  uploadedAndValidatedClarificationFile
                                )
                              }
                              error={
                                uploadedAndValidatedClarificationFile.error
                              }
                              loading={
                                uploadedAndValidatedClarificationFile.activity
                              }
                              className="file-zone"
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Collapse>
                )}
              <Divider />
              <div className="step-back-row">
                {idCheckList === 7 &&
                  !currentChecklist &&
                  updateAccountingProcessLoading &&
                  !stepChangeLoading && <Activity size={30} local />}
                {idCheckList === 7 &&
                  !currentChecklist &&
                  !updateAccountingProcessLoading && (
                    <Button
                      className="step-back"
                      startIcon={<ArrowBackIcon />}
                      variant="link"
                      color="primary"
                      onClick={() => handleIncompleteChecklist(7)}>
                      Regresar al paso anterior
                    </Button>
                  )}
              </div>
            </div>
          ))}
        </div>
        <div className="screen-actions">
          <Button
            color="secondary"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={handleStepBack}
            activity={stepChangeLoading}>
            Ir a la sección anterior
          </Button>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<Check />}
            disabled={!!currentChecklist}
            onClick={() =>
              handleStepSubmit(ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE)
            }
            activity={stepChangeLoading}>
            Continuar
          </Button>
        </div>

        <SydDialog
          open={Boolean(selectedFile)}
          size="sm"
          title="Renombrar un archivo"
          primaryButtonText="Renombrar"
          disablePrimaryButton={!validRenameDialog}
          secondaryButtonText="Cancelar"
          onCancel={() => {
            setSelectedFile(undefined)
            setValidRenameDialog(false)
          }}
          form="renameFileForm"
          sideButtons>
          <Form
            id="renameFileForm"
            onSubmit={handleRenameFormSubmit}
            onValidity={(v: boolean) => setValidRenameDialog(v)}
            reset>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nombre del archivo"
                  value={selectedFile?.name}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Form>
        </SydDialog>

        <Drawer
          anchor="right"
          open={isOpenCommentHistory}
          SlideProps={{ mountOnEnter: true, unmountOnExit: true }}
          className="accounting-comment-history-drawer"
          onClose={() => setIsOpenCommentHistory(false)}>
          <AccountingCommentHistory />
        </Drawer>
      </AccountingStepThreeContext.Provider>
    </div>
  )
}

export const useAccountingStepThreeContext = () => {
  const context = useContext(AccountingStepThreeContext)

  if (!context) {
    throw new Error(
      'useAccountingStepThreeContext must be used within an AccountingStepThreeContext'
    )
  }

  return context
}

export default AccountingStepThree
