import { Fragment } from 'react'
import { ParsedArchive } from 'models/general'
import {
  CONCILIATION_CFDI_ACCEPTED_FORMATS,
  CONCILIATION_MAX_FILESIZE,
} from '../../../constants'
import {
  parseFileList,
  parseUploadedFiles,
  toPeriodString,
} from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import {
  UploadArchiveToPeriodResponse,
  uploadCFDIDocuments,
} from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import Dropzone from 'components/Conciliations/Dropzone/Dropzone'
import MenuItemFileInput from '../MenuItemFileInput/MenuItemFileInput'

type WrapperMode = 'dropzone' | 'option'

interface ICFDIFileWrapperProps {
  mode?: WrapperMode
  onContinuousClick?: () => void
}

const CFDIFileWrapper = ({
  mode = 'dropzone',
  onContinuousClick,
}: ICFDIFileWrapperProps) => {
  const {
    workspaceInfo: { activeWorkspace },
  } = useConciliationsContainerContext()

  const {
    conciliationPeriod,
    updateScreenLoading,
    updateIsSyncCFDIOpen,
    setDrawerFilelist,
  } = useConciliationsContainerContext()

  const dialog = useDialog()

  const onUpload = async (data: UploadArchiveToPeriodResponse) => {
    const documents = parseUploadedFiles(data.documents)
    setDrawerFilelist({
      ocrMode: 'CFDIs',
      documents,
      onConfirm: () => {},
    })
  }

  const handleFileChange = async (files: FileList) => {
    updateScreenLoading(true)
    try {
      const archives = (await parseFileList(files)) as ParsedArchive[]
      const data = await uploadCFDIDocuments(
        activeWorkspace,
        archives,
        toPeriodString(conciliationPeriod)
      )
      updateScreenLoading(false)
      onUpload(data)
    } catch (error: any) {
      updateScreenLoading(false)
      if (error.status === 400) {
        return await dialog.primaryAsync({
          title: 'Archivo repetido',
          subtitle: 'El archivo que intentas subir ya existe en el sistema.',
          primaryButtonText: 'Continuar',
        })
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo cargar el archivo. Inténtalo mas tarde.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const handleSync = () => {
    updateIsSyncCFDIOpen(true)
  }

  if (mode === 'option') {
    return (
      <Fragment>
        <MenuItemFileInput
          onChange={handleFileChange}
          onContinuousClick={onContinuousClick}
          acceptedFormats={CONCILIATION_CFDI_ACCEPTED_FORMATS}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Dropzone
        {...{
          title: 'Sincroniza con el SAT o carga manualmente los CFDI`s',
          subtitle: 'Selecciona una opción a continuación',
          actions: [
            {
              label: 'Sincronizar',
              onClick: handleSync,
            },
          ],
          options: {
            accept: CONCILIATION_CFDI_ACCEPTED_FORMATS,
            multiple: true,
            maxSize: CONCILIATION_MAX_FILESIZE,
            maxFiles: 999,
          },
        }}
        onChange={handleFileChange}
      />
    </Fragment>
  )
}

export default CFDIFileWrapper
