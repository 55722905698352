import React from 'react'
import { useDidUpdate } from 'rooks'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import EditEmisorForm from 'containers/RazonesSociales/EditRazonSocialFormContainer/EditRazonSocialFormContainer'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'

interface Props {}

const EditRazonSocialScreen: React.FC<Props> = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const id = Number(params.get('id'))
  const ws = Number(params.get('ws'))

  const workspaceId = useSelector(selectActiveWorkspace)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('error')
  }, [workspaceId])

  const onRouteChange = (action: string, id: number = 0) => {
    const origin = _.get(location, 'state.from')
    const route =
      origin || `${ROUTES.WORKSPACE_DETAILS}?ws=${ws || workspaceId}`
    if (action === 'error') navigate(route, { replace: true })
    if (action === 'cancel' || action === 'success') navigate(route)
  }

  if (!workspaceId || !id) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-razones-sociales">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Editar emisor</h1>
            <Button
              id="cancelEditBusinessNameBtn"
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <EditEmisorForm
            id={id}
            workspaceId={ws || workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EditRazonSocialScreen
