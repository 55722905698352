import React from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Card, { CardProps } from '@material-ui/core/Card'
import Checkbox from '@material-ui/core/Checkbox'

import FacturacionImage from 'assets/ic-facturacion.png'
import ContabilidadImage from 'assets/ic-sci.png'
import NominaImage from 'assets/ic-nomina.png'
import RepseImage from 'assets/ic-repse.png'
import DeclaracionImage from 'assets/ic-d_anual.png'
import RegularizacionImage from 'assets/ic-reg.png'
import './style.scss'

import { Plan } from 'models/general'

interface PlanCardProps extends Omit<CardProps, 'onSelect'> {
  plan: Plan
  price?: number
  priceAdornment?: string
  startPriceAdornment?: string
  endPriceAdornment?: string
  disabled?: boolean
  selected?: boolean
  onSelect?: (plan: Plan, selected: boolean) => void
}

const getPlanImage = (plan: Plan): string => {
  let img = ''
  switch (plan) {
    case 'facturacion':
      img = FacturacionImage
      break
    case 'contabilidad':
      img = ContabilidadImage
      break
    case 'nomina':
      img = NominaImage
      break
    case 'repse':
      img = RepseImage
      break
    case 'declaracion':
      img = DeclaracionImage
      break
    case 'regularizacion':
      img = RegularizacionImage
      break
    default:
      break
  }
  return img
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  price,
  priceAdornment,
  startPriceAdornment,
  endPriceAdornment,
  disabled = false,
  selected = false,
  onSelect,
  className,
  onClick,
  children,
  ...props
}) => {
  const intl = useIntl()
  const image = getPlanImage(plan)

  const formatPrice = (v?: number) => {
    if (_.isNil(v)) return '$---.-- MXN'
    else if (v === 0) return 'GRATIS'
    else
      return `${intl.formatNumber(v, { style: 'currency', currency: 'MXN', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0, minimumFractionDigits: 0 })} MXN`
  }

  return (
    <Card
      {...props}
      className={`component-plan-card-alt ${className || ''} ${!((_.isNil(onSelect) && _.isNil(onClick)) || disabled) ? 'clickable' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={e => {
        e.stopPropagation()
        !disabled && onSelect && onSelect(plan, !selected)
      }}>
      {!_.isNil(onSelect) && (
        <div className="selection-container">
          <Checkbox
            color="secondary"
            checked={selected}
            onClick={e => e.stopPropagation()}
            onChange={e => onSelect && onSelect(plan, e.target.checked)}
            disabled={disabled}
          />
        </div>
      )}
      <div
        className={`image-container ${!_.isNil(onSelect) ? 'with-padding' : ''}`}>
        <img
          src={image}
          alt={intl.formatMessage({ id: `workspace.plan.${plan}` })}
        />
      </div>
      <div className="information-container">
        <p className="title">
          {intl.formatMessage({ id: `workspace.plan.${plan}` })}
        </p>
        {children}
      </div>
      {(plan === 'facturacion' || !_.isNil(price)) && (
        <div className="price-container">
          <p className="adornment start">{startPriceAdornment}</p>
          <p className="price">
            <b>
              {formatPrice(price)}
              {priceAdornment}
            </b>
          </p>
          <p className="adornment end">{endPriceAdornment}</p>
        </div>
      )}
    </Card>
  )
}

export default PlanCard
