/* Note: I'm using any and type aliases here instead of ReduxState because it's an incorrect typing for the store, we need to create the RootState type
and it implies a big refactor. */

import { PaymentStep } from 'lib/enums/payments.enums'
import { IPlanInformation, ISelectedRecurrentPlan } from 'models/payments'

export const selectPaymentStep = (store: any) =>
  store.payments.paymentStep as PaymentStep

export const selectSelectedRecurrentPlan = (store: any) =>
  store.payments.selectedRecurrentPlan as ISelectedRecurrentPlan

export const selectIsRecurringPayment = (store: any) =>
  store.payments.isRecurringPayment as boolean

export const selectSelectedPayment = (store: any) =>
  store.payments.selectedPlan as IPlanInformation
