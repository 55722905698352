import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider, MenuItem, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HistoryIcon from '@material-ui/icons/History'
import MoreVert from '@material-ui/icons/MoreVert'
import NoteAdd from '@material-ui/icons/NoteAdd'
import SearchIcon from '@material-ui/icons/Search'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import StyledIconButton from 'components/Shared/StyledIconButton/StyledIconButton'
import StyledMenu from 'components/Shared/StyledMenu/StyledMenu'
import './styles.scss'

interface ILoadFileButtonProps {
  handleClose: () => void
}

const LoadFileButton = ({ handleClose }: ILoadFileButtonProps) => {
  const { toggleAccountMovementsModal } = useConciliationsContainerContext()
  const handleClick = () => {
    toggleAccountMovementsModal()
    handleClose()
  }

  return (
    <Fragment>
      <MenuItem onClick={handleClick}>
        <NoteAdd />
        <Typography>Cargar Archivo</Typography>
      </MenuItem>
    </Fragment>
  )
}

const MenuWrapper = () => {
  const { screen } = useConciliationsContainerContext()

  const { handleSetTab } = screen

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const onHistoryClick = () => {
    handleSetTab(CONCILIATION_TABS.ACCOUNT_STATEMENT_HISTORY)
    handleClose()
  }

  return (
    <Box className="options-menu-wrapper">
      <StyledIconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVert />
      </StyledIconButton>
      <StyledMenu
        className="options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <LoadFileButton handleClose={handleClose} />
        <Divider />
        <MenuItem onClick={onHistoryClick}>
          <HistoryIcon />
          <Typography>Historial</Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}

const SearchButton = () => {
  const { statements } = useConciliationsContainerContext()

  const { statementFilters, setConciliationStatementFilters } = statements

  const dispatch = useDispatch()

  const toggleFilters = () =>
    dispatch(
      setConciliationStatementFilters({
        ...statementFilters,
        display: !statementFilters.display,
      })
    )

  return (
    <StyledIconButton
      onClick={toggleFilters}
      className={statementFilters.display ? 'close-filters-button' : ''}>
      {statementFilters.display ? <CloseIcon /> : <SearchIcon />}
    </StyledIconButton>
  )
}

const Options = () => {
  return (
    <Box className="options-component">
      <SearchButton />
      <MenuWrapper />
    </Box>
  )
}

export default Options
