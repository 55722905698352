import _ from 'lodash';
import QueryString from 'query-string';

/** Cliente HTTP Para consumir servicios externos */
class HTTPClient {
    /** Guarda la instancia y la persiste (singleton) */
    static client;
    /** Devuelve la instancia si ya existe, si no la crea */
    static getClient(host, defaults) {
        if (!HTTPClient.client) {
            HTTPClient.client = new HTTPClient(host, defaults)
        }
        return HTTPClient.client
    }
    
    /**
    * @param {string} [host] Host o dirección web del servicio. Si no es definida los valores apuntan a una URL relativa
    * @param {object} [defaults] Objeto configurador de "fetch" con valores por defecto
    */
    constructor(host, defaults) {
        this.host = host || ''
        this.defaults = defaults || {}
        this.beforeFilters = []
        this.afterFilters = []
        this.renegotiation = []
        this.refresh = null
        this.renegotiate = null
        this.queue = []
        this.putInQueue = false
    }

    /**
     * Adds an event listener to renegotiate the authorization token
     * @param {Array<number>} codes Array of HTTP Codes to trigger renegotiation.
     * @param {Function} fn Function that will perform the renegotiation.
     * */
    renegotiateOn(codes, fn) {
        this.renegotiation = codes || []
        this.renegotiate = fn
    }

    /**
    * Adds an event listener callback to any response that ends in certain status code
    * @param {Number[]} code Status code
    * @param {Function} callback to be called when that status code triggers
    */
    on(codes, callback) {
        this.addAfter((response) => {
            if (codes.indexOf(response.status) > -1) {
                callback(response)
            }
            return response
        })
    }

    /**
    * Agrega nuevos valores de defecto a la configuración de la instancia
    * @param {object} values
    */
    addDefaults(values) {
        this.defaults = _.merge(this.defaults || {}, values)
    }

    /**
    * Agrega el encabezado "Authorization" a los valores por defecto del cliente
    * @param {string} token
    * @param {string} [refresh] Optional refresh token
    */
    setAuthorization(token, refresh) {
        this.addDefaults({ headers: { 'token': `${token}` }})
        this.refresh = refresh
    }

    setDevice(device) {
        this.addDefaults({ headers: { 'device': `${device}` }})
    }

    /**
    * Agrega una funcion al arreglo de filtros a ejecutarse antes de mandar una petición
    * El objeto a filtrar es el objeto "config" para Fetch
    * @param {Function}
    */
    addBefore(execution){
        this.beforeFilters.push(execution)
    }
    /**
    * Agrega una funcion al arreglo de filtros a ejecutarse despues de mandar una petición
    * El objeto a filtrar es el objeto "response" de Fetch. El resultado del filtrado será
    * inyectado a la cadena de respuestas "then" de la promesa que regresan las peticiones.
    * @param {Function}
    */
    addAfter(execution){
        this.afterFilters.push(execution)
    }
    /**
    * Ejecuta una petición GET
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    get(uri, configs) {
        return this.request('GET', uri, null, configs)
    }
    /**
    * Ejecuta una petición POST
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    post(uri, body, configs) {
        return this.request('POST', uri, body, configs)
    }
    /**
    * Ejecuta una petición PUT
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    put(uri, body, configs) {
        return this.request('PUT', uri, body, configs)
    }
    /**
    * Ejecuta una petición DELETE
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    delete(uri, body ,configs) {
        return this.request('DELETE', uri, body, configs)
    }

    /** Ejecuta los filtros sobre el respectivo valor */
    filters(filters, value) {
        let v = value
        for(const i in filters) {
            v = filters[i](v)
        }
        return v
    }

    /**
    * Prepara el cuerpo de la petición con base al encabezado content-type
    * @param {Object} config
    * @return {Object}
    */
    parseBodyByContent(config) {
        if(config.body && !_.isString(config.body)) {
            const type = config.headers['Content-Type'] || ''

            //JSON
            if (type.includes('json')) {
                config.body = JSON.stringify(config.body)
            } else if (type.includes('x-www-form-urlencoded')) {
                config.body = QueryString.stringify(config.body)
            } else {
                return config
            }
        }
        return config
    }

    resolveQueue() {
        if (this.queue.length) {
            this.queue.forEach(q => {
                const { method, uri, body, configs } = q.request
                this.request(method, uri, body, configs, false).then((response) => {
                    q.resolve(response)
                }).catch(error => {
                    q.reject(error)
                })
            })
        }
        this.putInQueue = false
        this.queue = []
    }

    /**
    * Ejecuta una petición HTTP a un recurso
    * @param {string} method Método HTTP
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    request(method, uri, body, configs, retry = true) {
        return new Promise((resolve, reject) => {

            //Si se está en proceso de renegociación la petición se guarda para lanzarse hasta que termine
            //el proceso
            if (retry && this.putInQueue) {
                this.queue.push({ resolve, reject, request: { method, uri, body, configs }})
                return;
            }

            //Proceso de la petición
            const url = `${this.host}${uri}`
            const configHeaders = configs ? configs.headers : {}
            let headers = { ...this.defaults.headers, ...configHeaders }
            let config = {...this.defaults, method, body, ...configs, headers: headers }
            if (body instanceof FormData) {
                delete headers['Content-Type']
            }
            config = this.filters(this.beforeFilters, config)
            config = this.parseBodyByContent(config)
    
            fetch(url, config).then(async (response) => {
                if (this.renegotiation.includes(response.status) && this.refresh && this.renegotiate && retry) {
                    
                    this.putInQueue = true
                    this.queue.push({ resolve, reject, request: { method, uri, body, configs }})
                    try {
                        await this.renegotiate(this.refresh, this)
                    } catch(e) {
                        console.error(e)
                    }
                    this.resolveQueue()
                    return;
                }
    
                response = this.filters(this.afterFilters, response);
                if (response.ok) {
                    resolve(response)
                    return;
                }
                reject(response);
            }).catch((e) => {
                const errorSintetico = Response.error()
                reject(errorSintetico)
                // reject(e)
            })


        })
        
    }
}

export default HTTPClient

export const httpErrorParser = async (err) => {
    let error = null;
  
    try {
        const errClone = err.clone();
        error = await errClone.json();
    } catch {
        error = await err.text();
    }
    let parsedError = { status: err.status, statusText: err.statusText };
    !_.isString(error) ? parsedError = { ...parsedError, ...error } : parsedError = { ...parsedError, text: error };
  
    return parsedError;
}
