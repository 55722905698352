import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { useIntl } from 'react-intl'
import moment from 'moment'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Drawer from '@material-ui/core/Drawer'
import InputAdornment from '@material-ui/core/InputAdornment'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import Checkbox from '@material-ui/core/Checkbox'

import Button from 'components/Shared/Button/Button'
import SydDialog, { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import Activity from 'components/Shared/Activity/Activity'
import Table, {
  HeaderOptions,
} from 'components/Shared/DynamicTable/DynamicTable'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import DateField from 'components/FormComponents/IOComponents/DateField/DateField'

import useCfdiList, {
  useAllCfdiList,
  useCancelCfdi,
  useChangeCfdiPayStatus,
  useCreditNoteList,
  useDownloadCancelNoticeFile,
  useDownloadCreditNoteFiles,
  // useDownloadInvoicesFilesInZip,
  useDownloadPaymentComplementFiles,
  usePaymentComplementList,
  useSendCreditNoteByEmail,
  useSendInvoiceByEmail,
  useSendPayComplementByEmail,
} from 'hooks/queries/invoices'

import {
  setFilterValue,
  setSelectedInvoice as setSelectedInvoiceRedux,
  addSelectedInvoiceToList,
  deleteSelectedInvoiceToList,
  setSelectedInvoiceList,
  setPaginationValue,
  setFilter,
  setHeaderValue,
  resetInvoicesFilters,
} from 'actions/invoices'

import {
  selectCatalogue,
  selectInvoicesFilter,
  selectInvoicesHeaders,
  selectInvoicesList,
  selectInvoicesPagination,
  selectSelectedInvoiceList,
} from 'lib/helpers/selectors'
import {
  convertJsonArrayToCSV,
  downloadFileFromData,
} from 'lib/helpers/utilities'

import {
  CANCELATION_REASONS_CATALOGUE,
  CANCELATION_REASONS_CATALOGUE_V4,
  INVOICE_STATUS,
  CLIENTS_FLAGS,
  STATUS_PAYMENT,
} from '../../../constants'

import Add from '@material-ui/icons/Add'
import Close from '@material-ui/icons/Clear'
import { TaskAlt } from 'components/Shared/CustomIcons'
import Options from '@material-ui/icons/MoreVert'
import Help from '@material-ui/icons/HelpOutline'
import FileDownload from '@material-ui/icons/GetApp'
import SendEmail from '@material-ui/icons/Send'
import Rwd from '@material-ui/icons/FastRewind'
import Fwd from '@material-ui/icons/FastForward'
import PendingI from '@material-ui/icons/Remove'
import Dot from '@material-ui/icons/FiberManualRecord'
import Wallet from '@material-ui/icons/AccountBalanceWallet'
import CreditCard from '@material-ui/icons/CreditCard'
import FilterList from '@material-ui/icons/FilterList'
import './style.scss'

import Invoice from 'models/invoice'
import RazonSocial from 'models/razonSocial'
import { UnparsedClient } from 'models/client'
import { CatalogueEntry } from 'models/catalogues'
import { Box, FormControlLabel } from '@material-ui/core'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import { clearInvoicesLocalFilterValues } from 'lib/utils/Invoices/invoices.utils'
import useDownloadInvoiceFiles from 'hooks/Invoices/useDownloadInvoiceFiles'
import useDownloadInvoicesFiles from 'hooks/Invoices/useDownloadInvoicesFiles'
import { resetAppState } from 'store/shared.actions'

interface Props {
  workspaceId: number
  emisor: RazonSocial | null
  onRedirect: (action: string, id?: number, version?: number) => void
}

const InvoicesContainer: React.FC<Props> = ({
  workspaceId,
  emisor,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const dialog = useDialog()
  const intl = useIntl()

  const filters = useSelector(selectInvoicesFilter)
  const headers = useSelector(selectInvoicesHeaders)
  const pagination = useSelector(selectInvoicesPagination)
  const list = useSelector(selectInvoicesList)
  const selectedList = useSelector(selectSelectedInvoiceList)
  const tipoComprobantes = useSelector(selectCatalogue('tiposComprobantes'))

  const [seeMoreAnchor, setSeeMoreAnchor] = useState<null | HTMLElement>(null)
  const [tableHeadersAnchor, setTableHeadersAnchor] =
    useState<null | HTMLElement>(null)
  const [paymentStatusAnchor, setPaymentStatusAnchor] =
    useState<null | HTMLElement>(null)
  const [openInfo, setOpenInfo] = useState(false)

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const [selectedPayComplement, setSelectedPayComplement] = useState<any>()
  const [selectedCreditNote, setSelectedCreditNote] = useState<any>()

  const [cancelReason, setCancelReason] = useState<{
    label: string
    value: number
  } | null>(null)

  const [relatedDocsDialogOpen, setRelatedDocsOpen] = useState<boolean>(false)
  const [cancelCFDIDialogOpen, setCancelCFDIDialogOpen] =
    useState<boolean>(false)
  const [validDialog, setValidDialog] = useState(false)

  // #region Queries
  const invoicesQuery = useCfdiList(
    workspaceId,
    pagination.page,
    pagination.size,
    emisor?.rfc,
    filters?.search,
    filters?.advance,
    filters?.type?.value,
    filters?.status?.value,
    filters?.startDate,
    filters?.endDate,
    filters?.receptor,
    {
      refreshOn: [
        pagination.page,
        pagination.size,
        emisor?.rfc,
        filters?.search,
        filters?.advance,
        filters?.type?.value,
        filters?.status?.value,
        filters?.startDate,
        filters?.endDate,
        filters?.receptor,
      ],
      onError: () => onRedirect('error'),
    }
  )

  //
  const findAllCFDIs = useAllCfdiList(
    workspaceId,
    0,
    pagination.total,
    emisor?.rfc,
    filters?.search,
    filters?.advance,
    filters?.type?.value,
    filters?.status?.value,
    filters?.startDate,
    filters?.endDate,
    filters?.receptor,
    {
      onSuccess(data) {
        let selected: Invoice[] = []
        _.forEach(data.list, cfdi => {
          if (Boolean(cfdi?.uuid)) selected.push(cfdi)
        })
        dispatch(setSelectedInvoiceList(selected))
      },
    }
  )
  /*
  const invoicesAllQuery = useCfdiList(
    workspaceId, 0, pagination.total,
    emisor?.rfc, 
    filters?.search, filters?.advance,
    filters?.type?.value, filters?.status?.value, 
    filters?.startDate, filters?.endDate, 
    filters?.receptor,
    {
      enabled: false,
      noSave: true,
      refreshOn: [
        pagination.total, 
        emisor?.rfc, 
        filters?.search, filters?.advance,
        filters?.type?.value, filters?.status?.value, 
        filters?.startDate, filters?.endDate, 
        filters?.receptor
      ],
      onBefore: () => {
        dispatch(openDialogInfo("Espera un momento", "Cargando informacion adicional", "",
          { content: <Grid container><Grid item xs={12}><Activity size={60} /></Grid></Grid>, size: "xs" }
        ));
      },
      onSuccess: (data) => onDownloadCSV(data.list),
      onError: () => onRedirect("error")
    }
  );
  */

  const downloadInvoiceFilesMutation = useDownloadInvoiceFiles({
    onSuccess: () => setSeeMoreAnchor(null),
  })

  const downloadInvoicesFilesMutation = useDownloadInvoicesFiles()

  /*
  const downloadInvoicesFilesInZipQuery = useDownloadInvoicesFilesInZip(
    workspaceId, pagination.page, pagination.size, 
    emisor?.rfc, 
    filters?.search, filters?.advance,
    filters?.type?.value, filters?.status?.value, 
    filters?.startDate, filters?.endDate, 
    filters?.receptor, 
    {
      enabled: false,
      onBefore: () => {
        dispatch(openDialogInfo("Espera un momento", "Cargando informacion adicional", "",
          { content: <Grid container><Grid item xs={12}><Activity size={60} /></Grid></Grid>, size: "xs" }
        ));
      },
    }
  );
  */
  const downloadInvoiceCancelNoticeQuery = useDownloadCancelNoticeFile(
    workspaceId,
    selectedInvoice,
    {
      onSuccess: () => setSeeMoreAnchor(null),
    }
  )

  const payComplementListQuery = usePaymentComplementList(
    workspaceId,
    selectedInvoice,
    {
      enabled: relatedDocsDialogOpen,
      onError: () => onRedirect('error'),
    }
  )
  const downloadPayComplementQuery = useDownloadPaymentComplementFiles(
    workspaceId,
    selectedInvoice,
    selectedPayComplement
  )

  const creditNoteListQuery = useCreditNoteList(workspaceId, selectedInvoice, {
    enabled: relatedDocsDialogOpen,
    onError: () => onRedirect('error'),
  })
  const downloadCreditNoteQuery = useDownloadCreditNoteFiles(
    workspaceId,
    selectedInvoice,
    selectedCreditNote
  )
  // #endregion

  // #region Mutations
  const changeCfdiPaymentStatusMutation = useChangeCfdiPayStatus(workspaceId, {
    onSuccess: () => invoicesQuery.refetch(),
  })
  const cancelCfdiMutation = useCancelCfdi(workspaceId, {
    onSuccess: () => {
      invoicesQuery.refetch()
      setCancelCFDIDialogOpen(false)
      setValidDialog(false)
    },
  })
  const sendCfdiByEmailMutation = useSendInvoiceByEmail(workspaceId, {
    onSuccess: async () => {
      invoicesQuery.refetch()
      await dialog.primaryAsync({
        title: '¡Listo!',
        subtitle: 'El CFDI seleccionado fue enviado a tu correo.',
        icon: TaskAlt,
      })
      setSeeMoreAnchor(null)
    },
  })
  const sendPayComplementByEmailMutation = useSendPayComplementByEmail(
    workspaceId,
    {
      onSuccess: () =>
        dialog.primary({
          title: '¡Listo!',
          subtitle:
            'El Complemento de pago seleccionado fue enviado a tu correo.',
          icon: TaskAlt,
        }),
    }
  )
  const sendCreditNoteByEmailMutation = useSendCreditNoteByEmail(workspaceId, {
    onSuccess: () =>
      dialog.primary({
        title: '¡Listo!',
        subtitle:
          'El Complemento de pago seleccionado fue enviado a tu correo.',
        icon: TaskAlt,
      }),
  })
  // #endregion

  // #region Table Formatters
  // const formatType = (type: CatalogueEntry) => `${type.value} - ${type.label}`;
  const formatRfc = (person: RazonSocial | UnparsedClient) =>
    person?.rfc || '- - -'
  const formatEmisor = (emisor: RazonSocial) =>
    emisor?.name || emisor?.rfc || '- - -'
  const formatReceptor = (receptor: UnparsedClient) =>
    receptor?.name || receptor?.bussinessName || receptor?.rfc || '- - -'
  const formatCurrency = (type: CatalogueEntry) =>
    `${type.value} - ${type.label}`
  const formatMovement = (
    type: string,
    _row: any,
    addTooltip: boolean = false,
    returnLabel: boolean = false
  ) => {
    let label = 'Pendiente'
    let className = 'pending'
    let Icon = PendingI

    if (type === 'I') {
      label = 'Ingreso'
      className = 'income'
      Icon = Rwd
    } else if (type === 'E') {
      label = 'Egreso'
      className = 'egress'
      Icon = Fwd
    }

    if (returnLabel) return label

    if (addTooltip) {
      return (
        <span className="status-container">
          <Tooltip title={label}>
            <Icon className={`status-icon ${className}`} color="inherit" />
          </Tooltip>
        </span>
      )
    }

    return (
      <span className="status-container">
        <Icon className={`status-icon ${className}`} color="inherit" />
      </span>
    )
  }
  const formatStatus = (
    status: number,
    row: any,
    addTooltip: boolean = false,
    returnLabel: boolean = false
  ) => {
    let label = 'Pendiente'
    let className = 'pending'
    let Icon = PendingI

    if (!row?.uuid) {
      label = 'No timbrado'
    } else if (status === INVOICE_STATUS.ACTIVE.id) {
      label = 'Vigente'
      className = 'active'
      Icon = Dot
    } else if (status === INVOICE_STATUS.CANCELED.id) {
      label = 'Cancelado'
      className = 'canceled'
      Icon = Dot
    } else if (status === INVOICE_STATUS.PENDING.id) {
      label = 'Pendiente'
      className = 'pending'
      Icon = Dot
    }

    if (returnLabel) return label

    if (addTooltip) {
      return (
        <span className="status-container">
          <Tooltip title={label}>
            <Icon className={`status-icon ${className}`} color="inherit" />
          </Tooltip>
        </span>
      )
    }

    return (
      <span className="status-container">
        <Icon className={`status-icon ${className}`} color="inherit" />
      </span>
    )
  }
  const formatArticulo69bStatus = (
    receptor: UnparsedClient,
    addTooltip: boolean = false,
    returnLabel: boolean = false
  ) => {
    let label = 'Sin coincidencia'
    let className = 'active'
    let Icon = Dot

    if (
      (receptor.flags & CLIENTS_FLAGS.ARTICULO_69B) ===
      CLIENTS_FLAGS.ARTICULO_69B
    ) {
      label = 'Definitivo'
      className = 'canceled'
    }

    if (returnLabel) return label

    if (addTooltip) {
      return (
        <span className="status-container">
          <Tooltip title={label}>
            <Icon className={`status-icon ${className}`} color="inherit" />
          </Tooltip>
        </span>
      )
    }

    return (
      <span className="status-container">
        <Icon className={`status-icon ${className}`} color="inherit" />
      </span>
    )
  }
  const formatStatusPayment = (
    status: any,
    row: any,
    returnIcon: boolean = false,
    returnLabel: boolean = false,
    key?: any
  ) => {
    let label = 'Pendiente'
    let Icon = PendingI

    if (row?.status !== INVOICE_STATUS.ACTIVE.id || !row?.uuid) {
      if (returnLabel) return label
      return (
        <span className="status-container">
          <Icon className="status-payment-icon canceled" color="inherit" />
        </span>
      )
    }

    if (status?.value === STATUS_PAYMENT.CHARGED) {
      label = 'Cobrada'
      Icon = Dot
    } else if (status?.value === STATUS_PAYMENT.PART_CHARGED) {
      label = 'Cobrada parcialmente'
      Icon = Wallet
    } else if (status?.value === STATUS_PAYMENT.PAID) {
      label = 'Pagada'
      Icon = Dot
    } else if (status?.value === STATUS_PAYMENT.PART_PAID) {
      label = 'Pagada parcialmente'
      Icon = CreditCard
    }

    if (returnLabel) return label

    if (returnIcon) {
      return (
        <span className="status-container">
          <Icon
            className={`status-icon ${status?.value || ''}`}
            color="inherit"
          />
        </span>
      )
    }

    return (
      <span className="status-container">
        {changeCfdiPaymentStatusMutation.isLoading &&
        selectedInvoice?.idCfdi === row.idCfdi ? (
          <Activity size={20} color="primary" local />
        ) : (
          <Tooltip
            title={
              changeCfdiPaymentStatusMutation.isLoading ? 'Espera...' : label
            }>
            <IconButton
              id={`changePayStatusBtn_${key}`}
              size="small"
              onClick={e => {
                e.stopPropagation()
                if (changeCfdiPaymentStatusMutation.isLoading) return
                setPaymentStatusAnchor(e.currentTarget)
                setSelectedInvoice(row)
                dispatch(setSelectedInvoiceRedux(row))
              }}
              disableRipple>
              <Icon
                className={`status-payment-icon ${status?.value || ''}`}
                color="inherit"
              />
            </IconButton>
          </Tooltip>
        )}
      </span>
    )
  }
  const formatMoney = (money: number): string => {
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatDate = (dateString: string = ''): string => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const formatHour = (dateString: string = ''): string => {
    if (!dateString) return '- - -'
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatTime(date, {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    })
    return formatted
  }
  const formatSerieFolio = (serie: string, row: any): string => {
    const { folio } = row
    if (!serie && !folio) return '- - -'
    if (!serie && folio) return `${folio}`
    if (serie && !folio) return `${serie}`
    return `${serie}-${folio}`
  }
  const formatOptions = (id: number, row: any, key: any) => (
    <IconButton
      id={`cfdiOptionsBtn_${key}`}
      size="small"
      color="primary"
      onClick={e => {
        e.stopPropagation()
        setSelectedInvoice(row)
        dispatch(setSelectedInvoiceRedux(row))
        setSeeMoreAnchor(e.currentTarget)
      }}>
      <Options />
    </IconButton>
  )
  const formatActionsPayComplement = (id: number, row: any, key: any) => (
    <>
      <IconButton
        id={`downloadPayComplementBtn_${key}`}
        title="Descargar"
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          setSelectedPayComplement(row)
          downloadPayComplementQuery.refetch()
        }}>
        <FileDownload fontSize="inherit" />
      </IconButton>
      <IconButton
        id={`sendPayComplementBtn_${key}`}
        title="Enviar por email"
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          selectedInvoice &&
            sendPayComplementByEmailMutation.mutate({
              payComplement: row,
              invoice: selectedInvoice,
            })
        }}>
        <SendEmail fontSize="inherit" />
      </IconButton>
    </>
  )
  const formatActionsCreditNote = (id: number, row: any, key: any) => (
    <>
      <IconButton
        id={`downloadCreditNoteBtn_${key}`}
        title="Descargar"
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          setSelectedCreditNote(row)
          downloadCreditNoteQuery.refetch()
        }}>
        <FileDownload fontSize="inherit" />
      </IconButton>
      <IconButton
        id={`sendCreditNoteBtn_${key}`}
        title="Enviar por email"
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          selectedInvoice &&
            sendCreditNoteByEmailMutation.mutate({
              creditNote: row,
              invoice: selectedInvoice,
            })
        }}>
        <SendEmail fontSize="inherit" />
      </IconButton>
    </>
  )
  // #endregion

  // #region Table Headers
  const invoiceHeaders = [
    {
      label: 'Fecha',
      accesor: 'createdAt',
      renderer: formatDate,
      show: headers?.createdAt,
    },
    {
      label: 'Hora',
      accesor: 'hour',
      renderer: formatHour,
      show: headers?.hour,
    },
    {
      label: 'Serie-Folio',
      accesor: 'serie',
      renderer: formatSerieFolio,
      show: headers?.serie,
    },
    {
      label: 'UUID',
      accesor: 'uuid',
      renderer: _.toUpper,
      show: headers?.uuid,
    },
    {
      label: 'Receptor',
      accesor: 'clients',
      renderer: formatReceptor,
      show: headers?.clients,
    },
    {
      label: 'Emisor',
      accesor: 'emisor',
      renderer: formatEmisor,
      show: headers?.emisor,
    },
    {
      label: 'Subtotal',
      accesor: 'subtotal',
      renderer: formatMoney,
      show: headers?.subtotal,
    },
    {
      label: 'Impuestos',
      accesor: 'taxes',
      renderer: formatMoney,
      show: headers?.taxes,
    },
    {
      label: 'Total',
      accesor: 'total',
      renderer: formatMoney,
      show: headers?.total,
    },
    {
      label: 'Tipo',
      accesor: 'movementType',
      renderer: (type: string, row: any) => formatMovement(type, row, true),
      headerClassName: 'center',
      show: headers?.movementType,
    },
    {
      label: 'Estatus',
      accesor: 'status',
      renderer: (st: number, row: any) => formatStatus(st, row, true),
      headerClassName: 'center',
      show: headers?.status,
    },
    {
      label: 'Art. 69B',
      accesor: 'clients',
      renderer: (st: UnparsedClient, row: any) =>
        formatArticulo69bStatus(st, true),
      headerClassName: 'center',
      show: headers?.clients,
    },
    {
      label: 'Pago',
      accesor: 'statusPayment',
      renderer: (st: number, row: any, key: any) =>
        formatStatusPayment(st, row, undefined, undefined, key),
      headerClassName: 'center',
      show: headers?.statusPayment,
    },
    {
      label: 'Enviado',
      accesor: 'sent',
      renderer: formatDate,
      show: headers?.sent,
    },
    {
      label: '',
      accesor: 'idCfdi',
      renderer: formatOptions,
      cellClassName: 'options',
    },
  ]
  const paymentComplementHeaders = [
    { label: 'Fecha de Pago', accesor: 'paymentDate', renderer: formatDate },
    { label: 'UUID', accesor: 'uuid' },
    { label: 'Total', accesor: 'amount', renderer: formatMoney },
    {
      label: '',
      accesor: 'idCfdi',
      renderer: formatActionsPayComplement,
      cellClassName: 'options',
    },
  ]
  const creditNoteHeaders = [
    { label: 'Fecha de Pago', accesor: 'createdAt', renderer: formatDate },
    { label: 'UUID', accesor: 'uuid' },
    { label: 'Total', accesor: 'total', renderer: formatMoney },
    {
      label: '',
      accesor: 'idCfdi',
      renderer: formatActionsCreditNote,
      cellClassName: 'options',
    },
  ]

  const csvConfiguration = [
    { accesor: 'createdAt', label: 'Fecha', formatter: formatDate },
    { accesor: 'createdAt', label: 'Hora', formatter: formatHour },
    { accesor: 'uuid', label: 'UUID' },
    { accesor: 'serie', label: 'Serie/Folio', formatter: formatSerieFolio },
    { accesor: 'clients.rfc', label: 'RFC Receptor' },
    { accesor: 'clients.bussinessName', label: 'Nombre Receptor' },
    { accesor: 'emisor.rfc', label: 'RFC Emisor' },
    { accesor: 'emisor.name', label: 'Nombre Emisor' },
    {
      accesor: 'movementType',
      label: 'Tipo',
      formatter: (st: string, row: any) => formatMovement(st, row, false, true),
    },
    {
      accesor: 'status',
      label: 'Estatus',
      formatter: (st: number, row: any) => formatStatus(st, row, false, true),
    },
    {
      accesor: 'clients',
      label: 'Art. 69B',
      formatter: (st: UnparsedClient, row: any) =>
        formatArticulo69bStatus(st, false, true),
    },
    {
      accesor: 'statusPayment',
      label: 'Pago',
      formatter: (st: number, row: any) =>
        formatStatusPayment(st, row, false, true),
    },
    { accesor: 'subtotal', label: 'Subtotal', formatter: formatMoney },
    { accesor: 'taxes', label: 'Impuestos', formatter: formatMoney },
    { accesor: 'total', label: 'Total', formatter: formatMoney },
  ]
  // #endregion

  // #region Transitional Functions

  const onResetFilters = useCallback(() => {
    clearInvoicesLocalFilterValues()
    dispatch(resetInvoicesFilters())
  }, [dispatch])

  const onChangeCfdiPayStatus = (
    invoice?: Invoice,
    status: string = STATUS_PAYMENT.PENDING
  ) => {
    if (!invoice) return
    setPaymentStatusAnchor(null)
    changeCfdiPaymentStatusMutation.mutate({ invoice, status })
  }
  const onDownloadCSV = (list: Invoice[]) => {
    const headers = _.map(csvConfiguration, header => header.label)
    const rows = convertJsonArrayToCSV(csvConfiguration, list)
    const csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n${rows.map((e: any) => e.join(',')).join('\n')}`
    const encodedUri = encodeURI(csvContent)
    downloadFileFromData('lista_cfdis.csv', encodedUri)
  }

  const selectedIsCanceled =
    _.indexOf(
      [STATUS_PAYMENT.PENDING_CANCELED, STATUS_PAYMENT.CANCELED],
      selectedInvoice?.statusPayment?.value
    ) > -1
  const selectedAddonVersion = selectedInvoice?.version === 4 ? 2 : undefined
  // #endregion

  const handleSelectAll = useCallback(
    checked => {
      // if (!checked) { dispatch(setSelectedInvoiceList([])) }
      // else if (checked && selectedList.length > 0) { dispatch(setSelectedInvoiceList([])) }
      // else {
      //   let selected: string[] = [];
      //   _.forEach(list, (cfdi) => {
      //     if (Boolean(cfdi?.uuid)) selected.push(cfdi?.uuid);
      //   });
      //   dispatch(setSelectedInvoiceList(selected));
      // }
      if (!checked) {
        dispatch(setSelectedInvoiceList([]))
      } else if (checked && selectedList.length > 0) {
        dispatch(setSelectedInvoiceList([]))
      } else {
        findAllCFDIs.mutate()
      }
    },
    [findAllCFDIs, selectedList, dispatch]
  )

  useEffect(
    () => () => {
      dispatch(resetAppState())
    },
    [dispatch, onResetFilters]
  )

  return (
    <>
      <div className="container-invoices-filter">
        {/* General Search Bar */}
        <Form
          id="invoicesFilters"
          onSubmit={async form => {
            pagination.page = 0
            dispatch(setFilterValue('search', form.search))
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <TextField
                name="search"
                label="Buscar"
                placeholder="Busca por folio, serie, UUID o razón social"
                value={filters?.search}
                onValue={(v: string) =>
                  !v && dispatch(setFilterValue('search', v))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="reset"
                        size="small"
                        id="clearSearchBtn"
                        onClick={() => dispatch(setFilterValue('search', ''))}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} className="filter-actions">
              <div className="elements left">
                <Button
                  id="searchBtn"
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  disabled={filters?.advance}
                  activity={invoicesQuery.isLoading}>
                  {intl.formatMessage({ id: 'button.search' })}
                </Button>
                <Button
                  id="toggleAdvanceSearchBtn"
                  variant="hover"
                  color={filters.advance ? 'primary' : 'secondary'}
                  startIcon={filters.advance ? <Close /> : <FilterList />}
                  onClick={() => {
                    onResetFilters()
                    dispatch(setFilterValue('advance', !filters.advance))
                  }}>
                  {intl.formatMessage({ id: 'button.advance_search' })}
                </Button>
              </div>
              <div className="elements">
                <Button
                  id="toggleFiltersBtn"
                  variant="hover"
                  color={Boolean(tableHeadersAnchor) ? 'primary' : 'secondary'}
                  startIcon={
                    Boolean(tableHeadersAnchor) ? <Close /> : <FilterList />
                  }
                  onClick={e => setTableHeadersAnchor(e.currentTarget)}>
                  {intl.formatMessage({ id: 'button.filter_columns' })}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
        {/* Advance Search Section */}
        <Collapse in={filters?.advance}>
          <Form
            id="invoicesAdvancedFilters"
            onSubmit={async form => {
              pagination.page = 0
              dispatch(
                setFilter({
                  type: form.type,
                  status: form.status,
                  startDate: form.startDate,
                  endDate: form.endDate,
                  receptor: form.receptor,
                })
              )
            }}>
            <Grid
              className="collapsed-container advance-search"
              container
              spacing={4}
              alignItems="center">
              <Grid item xs={3}>
                <AutocompleteField
                  name="type"
                  label={'Ingreso/Egreso'}
                  catalogue={_.filter(
                    tipoComprobantes,
                    c => ['I', 'E'].indexOf(c.value) > -1
                  )}
                  value={filters?.type}
                  getOptionLabel={(o: CatalogueEntry) => `${o.label}`}
                  renderOption={(o: CatalogueEntry) => (
                    <span className="catalogue-option">{`${o.label}`}</span>
                  )}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <AutocompleteField
                  name="status"
                  label={'Estatus'}
                  catalogue={[
                    { idCatalogValues: 1, value: 1, label: 'Vigente' },
                    { idCatalogValues: 2, value: 2, label: 'Cancelado' },
                    { idCatalogValues: 3, value: 3, label: 'Pendiente' },
                  ]}
                  value={filters?.status}
                  getOptionLabel={(o: CatalogueEntry) => `${o.label}`}
                  renderOption={(o: CatalogueEntry) => (
                    <span className="catalogue-option">{`${o.label}`}</span>
                  )}
                  getOptionSelected={(
                    option: CatalogueEntry,
                    value: CatalogueEntry
                  ) => option.idCatalogValues === value.idCatalogValues}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <DateField
                  name="startDate"
                  label="Fecha inicial"
                  value={filters?.startDate ?? null}
                  filterIn={(v: any) => (v ? moment(v, 'YYYY-MM-DD') : v)}
                  filterOut={(v: any) => v?.format('YYYY-MM-DD')}
                  disableFuture
                  clearable
                  fullWidth
                  autoOk
                />
              </Grid>
              <Grid item xs={3}>
                <DateField
                  name="endDate"
                  label="Fecha final"
                  value={filters?.endDate ?? null}
                  filterIn={(v: any) => (v ? moment(v, 'YYYY-MM-DD') : v)}
                  filterOut={(v: any) => v?.format('YYYY-MM-DD')}
                  disableFuture
                  clearable
                  fullWidth
                  autoOk
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="receptor"
                  label="RFC del receptor"
                  value={filters?.receptor}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Incluir todos los espacios de trabajo"
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} className="advance-filters-actions">
                <Button
                  id="cleanFiltersBtn"
                  variant="hover"
                  color="secondary"
                  startIcon={<Close />}
                  onClick={onResetFilters}>
                  Limpiar filtros
                </Button>
                <Button
                  id="advanceSearchBtn"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  activity={invoicesQuery.isLoading}>
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Collapse>
      </div>

      {/* Headers List Selector */}
      <Popover
        anchorEl={tableHeadersAnchor}
        keepMounted
        open={Boolean(tableHeadersAnchor)}
        onClose={() => setTableHeadersAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <List id="tableHeadersList" className="table-headers-list" dense>
          {_.map(invoiceHeaders, (ih, i) => {
            if (!ih.label) return undefined
            const labelId = `checkboxListLabel_${ih.label}`
            return (
              <ListItem id={`tableHeadersListItem_${ih.accesor}`} key={i}>
                <ListItemText id={labelId} primary={ih.label} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    inputProps={{ 'aria-labelledby': labelId }}
                    tabIndex={-1}
                    checked={ih.show}
                    onClick={() =>
                      dispatch(setHeaderValue(ih.accesor, !ih.show))
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Popover>

      <Box display="flex" alignItems="center" my={4} gridGap="5rem">
        <Box fontSize="small">
          {selectedList.length > 0
            ? `${selectedList.length} Seleccionad${selectedList.length > 1 ? 'as' : 'a'}`
            : `Selecciona las facturas para descargar la factura o el CSV`}
        </Box>
        {selectedList.length > 0 && (
          <Box display="flex" gridGap="1.5rem">
            <Button
              id="downloadCfdisBtn"
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<SaveAltIcon />}
              disabled={selectedList.length === 0 || !list}
              onClick={() =>
                downloadInvoicesFilesMutation.mutate({
                  workspaceId,
                  selectedList,
                })
              }>
              {`Descargar factur${selectedList.length > 1 ? 'as' : 'a'}`}
            </Button>
            <Button
              id="downloadCfdisCsvBtn"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveAltIcon />}
              disabled={selectedList.length === 0 || !list}
              onClick={() => onDownloadCSV(selectedList)}>
              Descargar CSV
            </Button>
          </Box>
        )}
      </Box>

      {/* <div className="table-additional-actions">
        <Button
          id="downloadCfdisAllBtn"
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          disabled={!list}
          onClick={() => downloadInvoicesFilesInZipQuery.refetch()}
        >
          Descargar facturas (todas)
        </Button>
        <Button
          id="downloadCfdisCsvAllBtn"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          disabled={!list}
          onClick={() => invoicesAllQuery.refetch()}
        >
          Descargar CSV (todas)
        </Button>
        <Button
          id="downloadCfdisBtn"
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          disabled={selectedList.length === 0 || !list}
          onClick={() => downloadInvoicesFilesQuery.refetch()}
        >
          Descargar facturas (seleccionadas)
        </Button>
        <Button
          id="downloadCfdisCsvBtn"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          disabled={selectedList.length === 0 || !list}
          onClick={() => onDownloadCSV(_.filter(list, (invoice) => selectedList.indexOf(invoice.uuid) > -1))}
        >
          Descargar CSV (seleccionadas)
        </Button>
      </div> */}

      <Divider className="divider" />

      <Card elevation={1} className="container-invoices-list">
        <Table
          headers={
            _.filter(invoiceHeaders, ih =>
              _.get(headers, ih.accesor, true)
            ) as HeaderOptions[]
          }
          rows={_.isNil(emisor) ? [] : list}
          loading={invoicesQuery.isLoading}
          fetching={invoicesQuery.isFetching}
          pagination={{
            rowsPerPageOptions: [5, 10, 15, 20],
            count: pagination.total,
            rowsPerPage: pagination.size,
            page: pagination.page,
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count !== -1 ? count : `mas de ${to}`}`,
            labelRowsPerPage: "CFDI's por página:",
            onPageChange: (e, page) =>
              dispatch(setPaginationValue('page', page)),
            onRowsPerPageChange: e => {
              dispatch(setPaginationValue('size', Number(e.target.value)))
              dispatch(setPaginationValue('page', 0))
            },
          }}
          onSelect={(cfdi, checked) =>
            dispatch(
              checked
                ? addSelectedInvoiceToList(cfdi as Invoice)
                : deleteSelectedInvoiceToList(cfdi as Invoice)
            )
          }
          onSelectAll={handleSelectAll}
          getSelectDisabled={row => !Boolean(row?.uuid)}
          selectedRows={selectedList?.map(invoice => invoice.uuid)}
          identity="uuid"
        />
      </Card>

      {/* See More Menu */}
      <Menu
        id="seeMoreMenu"
        style={openInfo ? { zIndex: 1301 } : undefined}
        anchorEl={seeMoreAnchor}
        open={Boolean(seeMoreAnchor)}
        keepMounted
        onClose={() => setSeeMoreAnchor(null)}>
        {!openInfo && (
          <MenuItem
            onClick={() => {
              setOpenInfo(true)
              setSeeMoreAnchor(null)
            }}>
            Ver información
          </MenuItem>
        )}
        {!selectedInvoice?.imported && !!selectedInvoice?.uuid && (
          <MenuItem
            onClick={e => {
              e.stopPropagation()
              setRelatedDocsOpen(true)
              setSeeMoreAnchor(null)
            }}>
            Ver documentos relacionados
          </MenuItem>
        )}
        {!selectedInvoice?.imported &&
          !selectedIsCanceled &&
          !!selectedInvoice?.uuid && (
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                onRedirect(
                  'addComplement',
                  selectedInvoice?.idCfdi,
                  selectedAddonVersion
                )
              }}>
              Nuevo complemento de pago
            </MenuItem>
          )}
        {!selectedInvoice?.imported &&
          !selectedIsCanceled &&
          !!selectedInvoice?.uuid && (
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                onRedirect(
                  'addCreditNote',
                  selectedInvoice?.idCfdi,
                  selectedAddonVersion
                )
              }}>
              Nueva nota de crédito
            </MenuItem>
          )}
        {!selectedInvoice?.imported && !!selectedInvoice?.uuid && (
          <MenuItem
            onClick={e => {
              e.stopPropagation()
              selectedInvoice && sendCfdiByEmailMutation.mutate(selectedInvoice)
            }}>
            Enviar CFDI por correo
          </MenuItem>
        )}
        {!!selectedInvoice?.uuid && (
          <MenuItem
            onClick={e => {
              e.stopPropagation()
              selectedInvoice &&
                downloadInvoiceFilesMutation.mutate({
                  workspaceId,
                  selectedInvoice,
                })
            }}>
            Descargar CFDI
          </MenuItem>
        )}
        {!selectedInvoice?.imported && !selectedIsCanceled && (
          <MenuItem
            onClick={async e => {
              e.stopPropagation()
              const resp = await dialog.primaryAsync({
                title: '¿Estás seguro?',
                subtitle: 'Estás a punto de cancelar uno de tus CFDIs',
                icon: Help as TModalIcon,
                primaryButtonText: 'Si, cancelar',
                secondaryButtonText: 'No, cerrar',
              })
              if (!resp) return
              setSeeMoreAnchor(null)
              selectedInvoice && setCancelCFDIDialogOpen(true)
            }}>
            Cancelar CFDI
          </MenuItem>
        )}
        {!selectedInvoice?.imported && selectedIsCanceled && (
          <MenuItem
            onClick={e => {
              e.stopPropagation()
              selectedInvoice && downloadInvoiceCancelNoticeQuery.refetch()
            }}>
            Descargar acuse de cancelación
          </MenuItem>
        )}
      </Menu>

      {/* Change Status Menu */}
      <Menu
        id="changeStatusMenu"
        anchorEl={paymentStatusAnchor}
        open={Boolean(paymentStatusAnchor)}
        keepMounted
        onClose={() => setPaymentStatusAnchor(null)}
        className="change-status-payment-menu">
        {selectedInvoice?.statusPayment?.value !== STATUS_PAYMENT.CHARGED && (
          <MenuItem
            className="status-container"
            onClick={e => {
              e.stopPropagation()
              onChangeCfdiPayStatus(selectedInvoice, STATUS_PAYMENT.CHARGED)
            }}>
            <Dot className="status-payment-icon charged" fontSize="small" />
            Cobrada
          </MenuItem>
        )}
        {selectedInvoice?.statusPayment?.value !==
          STATUS_PAYMENT.PART_CHARGED && (
          <MenuItem
            className="status-container"
            onClick={e => {
              e.stopPropagation()
              onChangeCfdiPayStatus(
                selectedInvoice,
                STATUS_PAYMENT.PART_CHARGED
              )
            }}>
            <Wallet
              className="status-payment-icon part-charged"
              fontSize="small"
            />
            Cobrada parcialmente
          </MenuItem>
        )}
        {selectedInvoice?.movementType === 'E' &&
          selectedInvoice?.statusPayment?.value !== STATUS_PAYMENT.PAID && (
            <MenuItem
              className="status-container"
              onClick={e => {
                e.stopPropagation()
                onChangeCfdiPayStatus(selectedInvoice, STATUS_PAYMENT.PAID)
              }}>
              <Dot className="status-payment-icon paid" fontSize="small" />
              Pagada
            </MenuItem>
          )}
        {selectedInvoice?.movementType === 'E' &&
          selectedInvoice?.statusPayment?.value !==
            STATUS_PAYMENT.PART_PAID && (
            <MenuItem
              className="status-container"
              onClick={e => {
                e.stopPropagation()
                onChangeCfdiPayStatus(selectedInvoice, STATUS_PAYMENT.PART_PAID)
              }}>
              <CreditCard
                className="status-payment-icon part-paid"
                fontSize="small"
              />
              Pagada parcialmente
            </MenuItem>
          )}
        {!_.isUndefined(selectedInvoice?.statusPayment?.value) &&
          selectedInvoice?.statusPayment?.value !== STATUS_PAYMENT.PENDING && (
            <MenuItem
              className="status-container"
              onClick={e => {
                e.stopPropagation()
                onChangeCfdiPayStatus(selectedInvoice, STATUS_PAYMENT.PENDING)
              }}>
              <PendingI className="status-payment-icon" fontSize="small" />
              Pendiente
            </MenuItem>
          )}
      </Menu>

      {/* CFDI Information Drawer */}
      <Drawer
        id="cfdiInfoDrawer"
        anchor="right"
        open={openInfo}
        SlideProps={{ mountOnEnter: true, unmountOnExit: true }}>
        <Form onSubmit={() => true} onValidity={() => true}>
          <Grid container className="invoice-information-container">
            <Grid item xs={12} className="actions-wrapper">
              <IconButton
                id="closeDrawerBtn"
                size="small"
                color="primary"
                onClick={() => {
                  setSeeMoreAnchor(null)
                  setOpenInfo(false)
                }}>
                <Close />
              </IconButton>
              <IconButton
                id="drawerOptionsBtn"
                size="small"
                color="primary"
                onClick={e => {
                  setSeeMoreAnchor(e.currentTarget)
                }}>
                <Options />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="createdAt"
                label="Fecha"
                value={formatDate(selectedInvoice?.createdAt)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="serie"
                label="Serie/Folio"
                value={
                  selectedInvoice
                    ? formatSerieFolio(selectedInvoice.serie, selectedInvoice)
                    : ''
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="uuid"
                label="UUID"
                value={
                  _.toUpper(selectedInvoice?.uuid) ||
                  'XXXXXXXX-XXXX-XXXX-XXXXXXXXXXXX'
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="receptor"
                label="Receptor"
                value={
                  selectedInvoice ? formatReceptor(selectedInvoice.clients) : ''
                }
                addon={
                  <small>
                    <i>
                      {selectedInvoice
                        ? formatRfc(selectedInvoice.clients)
                        : ''}
                    </i>
                  </small>
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="emisor"
                label="RFC"
                value={
                  selectedInvoice ? formatEmisor(selectedInvoice.emisor) : ''
                }
                addon={
                  <small>
                    <i>
                      {selectedInvoice ? formatRfc(selectedInvoice.emisor) : ''}
                    </i>
                  </small>
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="currency"
                label="Moneda"
                value={
                  selectedInvoice
                    ? formatCurrency(selectedInvoice.currency)
                    : ''
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="subtotal"
                label="Subtotal"
                value={
                  selectedInvoice ? formatMoney(selectedInvoice.subtotal) : ''
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="taxes"
                label="Impuestos"
                value={
                  selectedInvoice ? formatMoney(selectedInvoice.taxes) : ''
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="total"
                label="Total"
                value={
                  selectedInvoice ? formatMoney(selectedInvoice.total) : ''
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="type"
                label="Tipo"
                value={formatMovement(
                  selectedInvoice?.movementType || '',
                  selectedInvoice,
                  false,
                  true
                )}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment className="adornment" position="start">
                      {formatMovement(
                        selectedInvoice?.movementType || '',
                        selectedInvoice
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="status"
                label="Estatus factura"
                value={formatStatus(
                  selectedInvoice?.status || 0,
                  selectedInvoice,
                  false,
                  true
                )}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment className="adornment" position="start">
                      {formatStatus(
                        selectedInvoice?.status || 0,
                        selectedInvoice,
                        false
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="articulo69bStatus"
                label="Art. 69B"
                value={formatArticulo69bStatus(
                  selectedInvoice?.clients || ({} as UnparsedClient),
                  false,
                  true
                )}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment className="adornment" position="start">
                      {formatArticulo69bStatus(
                        selectedInvoice?.clients || ({} as UnparsedClient),
                        false,
                        false
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="statusPayment"
                label="Estatus pago"
                value={formatStatusPayment(
                  selectedInvoice?.statusPayment || 0,
                  selectedInvoice,
                  false,
                  true
                )}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment className="adornment" position="start">
                      {formatStatusPayment(
                        selectedInvoice?.statusPayment || 0,
                        selectedInvoice,
                        true
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="sent"
                label="Enviado"
                value={formatDate(selectedInvoice?.sent)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
          </Grid>
        </Form>
      </Drawer>

      {/* Related Docs Dialog */}
      <SydDialog
        open={relatedDocsDialogOpen}
        className="dialog-invoices"
        onCancel={() => setRelatedDocsOpen(false)}
        hidePrimaryButton
        secondaryButtonText="Cerrar"
        sideButtons
        fullWidth>
        <div className="title-container">
          <h1>Lista de Complementos de Pago</h1>
          <Button
            id="addPaymentComplementBtn"
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() =>
              onRedirect(
                'addComplement',
                selectedInvoice?.idCfdi,
                selectedInvoice?.version === 4 ? 2 : undefined
              )
            }>
            Agregar complemento de pago
          </Button>
        </div>
        <Table
          headers={paymentComplementHeaders}
          rows={payComplementListQuery.data || []}
          loading={payComplementListQuery.isLoading}
          fetching={payComplementListQuery.isFetching}
        />

        <div className="title-container creditNote">
          <h1>Lista de Notas de credito</h1>
          <Button
            id="addCreditNoteBtn"
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() =>
              onRedirect(
                'addCreditNote',
                selectedInvoice?.idCfdi,
                selectedInvoice?.version === 4 ? 2 : undefined
              )
            }>
            Agregar nota de credito
          </Button>
        </div>
        <Table
          headers={creditNoteHeaders}
          rows={creditNoteListQuery.data || []}
          loading={creditNoteListQuery.isLoading}
          fetching={creditNoteListQuery.isFetching}
        />
      </SydDialog>

      {/* Cancel CFDI Dialog */}
      <SydDialog
        open={cancelCFDIDialogOpen}
        className="dialog-invoices"
        title="Cancelar CFDI"
        subtitle="Selecciona un motivo de cancelación para este CFDI"
        primaryButtonText="Cancelar"
        loadingPrimaryButton={cancelCfdiMutation.isLoading}
        disablePrimaryButton={!validDialog}
        secondaryButtonText="Cerrar"
        disableSecondaryButton={cancelCfdiMutation.isLoading}
        onCancel={() => {
          setCancelCFDIDialogOpen(false)
          setValidDialog(false)
          setCancelReason(null)
        }}
        form="cancelCFDIForm"
        sideButtons>
        <Form
          id="cancelCFDIForm"
          reset
          onSubmit={async (form: any) => {
            if (!selectedInvoice) return
            cancelCfdiMutation.mutate({
              invoice: selectedInvoice,
              reason: form.reason.value,
              substitute: form.substitute,
            })
          }}
          onValidity={(v: boolean) => setValidDialog(v)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="uuid"
                label="UUID"
                value={selectedInvoice?.uuid}
                inputProps={{ readOnly: true }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteField
                name="reason"
                label="Motivo de Cancelación"
                catalogue={
                  selectedInvoice?.version === 4
                    ? CANCELATION_REASONS_CATALOGUE_V4
                    : CANCELATION_REASONS_CATALOGUE
                }
                onChange={(v: any) => setCancelReason(v)}
                fullWidth
                required
              />
            </Grid>
            {selectedInvoice?.version === 4 && cancelReason?.value === 1 && (
              <Grid item xs={12}>
                <TextField
                  name="substitute"
                  label="Folio de sustitución"
                  fieldType="foil"
                  fullWidth
                  required
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </SydDialog>
    </>
  )
}

export default InvoicesContainer
