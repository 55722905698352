import React, { useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { selectWorkspaceActiveTab } from 'lib/helpers/selectors'

import { ROUTES } from '../../../constants'

import Back from '@material-ui/icons/ArrowBack'
import './style.scss'

import { WorkspaceSections } from 'lib/enums/shared.enums'
import EditWorkspaceFormContainer from 'containers/Workspaces/EditWorkspaceFormContainer/ EditWorkspaceFormContainer'

interface Props {}

const EditWorkspaceScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const id = Number(params.get('id'))

  const activeTab = useSelector(selectWorkspaceActiveTab)

  const [fetching, setFetching] = useState(false)

  const onRouteChange = (action: string, idGo: number = 0) => {
    if (action === 'back') navigate(ROUTES.WORKSPACES)
    if (action === 'error') navigate(ROUTES.WORKSPACES, { replace: true })
    if (action === 'edit' && activeTab === WorkspaceSections.EMISORES)
      navigate(`${ROUTES.RAZON_SOCIAL_DETAILS}?id=${idGo}&ws=${id}`, {
        state: { from: `${ROUTES.WORKSPACE_DETAILS}?id=${id}` },
      })
    if (action === 'add' && activeTab === WorkspaceSections.EMISORES)
      navigate(`${ROUTES.ADD_RAZON_SOCIAL}?ws=${id}`, {
        state: { from: `${ROUTES.WORKSPACE_DETAILS}?id=${id}` },
      })
  }

  if (!id) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-workspaces edit">
        <Grid item xs={12} className="actions-container">
          <Button
            id="cancelEditWorkspaceBtn"
            variant="hover"
            color="secondary"
            startIcon={<Back />}
            onClick={() => onRouteChange('back')}>
            Volver
          </Button>
          {fetching && <CircularProgress size={30} color="primary" />}
        </Grid>
        <Grid item xs={12}>
          <EditWorkspaceFormContainer
            id={id}
            onRedirect={onRouteChange}
            onFetching={setFetching}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EditWorkspaceScreen
