import Grid from '@material-ui/core/Grid'
import AccountingContainer from 'containers/Accounting/AccountingContainer/AccountingContainer'
import ChatContainer from 'containers/Accounting/ChatContainer/ChatContainer'
import './style.scss'

const AccountingScreen = () => {
  return (
    <>
      <Grid container spacing={0} className="screen-accounting">
        <Grid item xs={12}>
          <div className="title-container">
            <h2>Contabilidad</h2>
          </div>
        </Grid>
        <AccountingContainer />
      </Grid>
      <ChatContainer />
    </>
  )
}

export default AccountingScreen
