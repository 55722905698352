import React from 'react'
import { useDidUpdate } from 'rooks'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import EditProductContainer from 'containers/ProductsAndCategories/EditProductContainer/EditProductContainer'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'

interface Props {}

const EditProductScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const id = Number(params.get('id'))
  const workspaceId = useSelector(selectActiveWorkspace)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('error')
  }, [workspaceId])

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error')
      navigate(ROUTES.CATEGORIES_PRODUCTS, { replace: true })
    if (action === 'cancel' || action === 'success')
      navigate(ROUTES.CATEGORIES_PRODUCTS)
  }

  if (!workspaceId || !id) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-products-services">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Editar producto</h1>
            <Button
              id="cancelEditProductBtn"
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <EditProductContainer
            id={id}
            workspaceId={workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EditProductScreen
