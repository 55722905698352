import { useQuery } from '@tanstack/react-query'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'

import { getTaxCatalogue } from 'services/catalogues'
import { selectCatalogue } from 'lib/helpers/selectors'

// import Help from '@material-ui/icons/Help';
import ErrorI from '@material-ui/icons/ErrorOutline'

// import Catalogue from 'models/catalogues';
import { TaxCatalogue } from 'models/invoice'
import { QueryOptions } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

export const useTaxCatalogue = (options: QueryOptions<TaxCatalogue> = {}) => {
  const dialog = useDialog()
  const satCatalogue = useSelector(selectCatalogue('impuestos'))

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<TaxCatalogue, any>(
    ['get-tax-catalogue', ...refreshOn],
    () => {
      onBefore && onBefore()
      return getTaxCatalogue(satCatalogue)
    },
    {
      ...rest,
      onSuccess: data => {
        noSave && console.log(data)
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la información del catálogo de impuestos.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
