import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UseMutationResult } from '@tanstack/react-query'
import { InformationForm, PasswordForm } from 'models/account'
import { ROUTES } from '../../../../constants'
import { selectProfile } from 'lib/helpers/selectors'
import useProfile, {
  useUpdateInformation,
  useUpdatePassword,
} from 'hooks/queries/account'
import useDialog from 'hooks/Shared/useDialog'
import { TaskAlt } from 'components/Shared/CustomIcons'

export interface IUseProfileForm {
  name: string
  lastName: string
  email: string
  emailCobza1: string
  emailCobza2: string
  newPassword: string
  isFormValid: boolean
  updateInfoMutation: UseMutationResult<void, any, InformationForm, unknown>
  updatePassMutation: UseMutationResult<void, any, PasswordForm, unknown>
  setName: Dispatch<SetStateAction<string>>
  setLastName: Dispatch<SetStateAction<string>>
  setEmail: Dispatch<SetStateAction<string>>
  setEmailCobza1: Dispatch<SetStateAction<string>>
  setEmailCobza2: Dispatch<SetStateAction<string>>
  setCurrentPassword: Dispatch<SetStateAction<string>>
  setNewPassword: Dispatch<SetStateAction<string>>
  setConfirmPassword: Dispatch<SetStateAction<string>>
  setValidRules: Dispatch<SetStateAction<boolean>>
  onSubmit: (form: any) => Promise<void>
}

const useProfileForm = (): IUseProfileForm => {
  const dialog = useDialog()
  const navigate = useNavigate()

  const profile = useSelector(selectProfile) || undefined

  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailCobza1, setEmailCobza1] = useState<string>('')
  const [emailCobza2, setEmailCobza2] = useState<string>('')

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [validName, setValidName] = useState<boolean>(false)
  const [validPassword, setValidPassword] = useState<boolean>(true)
  const [validRules, setValidRules] = useState<boolean>(false)
  const [validEmails, setValidEmails] = useState<boolean>(false)

  const [isFormValid, setIsFormValid] = useState(false)

  const profileQuery = useProfile({
    enabled: false,
    onError: () => navigate(ROUTES.HOME, { replace: true }),
  })
  const updateInfoMutation = useUpdateInformation({
    onSuccess: () => profileQuery.refetch(),
  })
  const updatePassMutation = useUpdatePassword({
    onSuccess: () => {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
    },
  })

  const onSubmit = async (form: any) => {
    if (validPassword && validRules) await updatePassMutation.mutateAsync(form)
    if (validName || validEmails) await updateInfoMutation.mutateAsync(form)

    await dialog.primaryAsync({
      subtitle: 'Tus cambios se guardaron con éxito',
      title: '¡Hecho!',
      icon: TaskAlt,
      primaryButtonText: 'Entendido',
    })
  }

  useEffect(() => {
    setName(profile?.name)
    setLastName(profile?.lastname)
    setEmail(profile?.email)
    setEmailCobza1(profile?.emailCobza1)
    setEmailCobza2(profile?.emailCobza2)
  }, [
    profile?.name,
    profile?.lastname,
    profile?.email,
    profile?.emailCobza1,
    profile?.emailCobza2,
  ])

  useEffect(() => {
    const noEmptyNames = name.length >= 2 && lastName.length >= 2
    setValidName(noEmptyNames)
  }, [profile?.name, profile?.lastname, name, lastName])

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    const noEmptyEmails = email !== '' && emailCobza1 !== ''
    const noInvalidEmails =
      emailRegex.test(email) && emailRegex.test(emailCobza1)
    const noDuplicateEmailCobranza =
      emailCobza2 !== '' ? emailCobza2 !== emailCobza1 : emailCobza1 !== ''
    setValidEmails(noEmptyEmails && noInvalidEmails && noDuplicateEmailCobranza)
  }, [email, emailCobza1, emailCobza2])

  useEffect(() => {
    const noEmptyCurrentPassword = currentPassword !== ''
    const noEmptyConfirmPassword =
      newPassword !== '' ? confirmPassword !== '' : true
    const duplicateNewPassword = newPassword === currentPassword
    const isConfirmedPasswordEqual = confirmPassword === newPassword
    setValidPassword(
      noEmptyCurrentPassword &&
        noEmptyConfirmPassword &&
        !duplicateNewPassword &&
        isConfirmedPasswordEqual
    )
  }, [currentPassword, newPassword, confirmPassword])

  useEffect(() => {
    setIsFormValid(
      newPassword !== ''
        ? validName && validEmails && validPassword && validRules
        : validName && validEmails
    )
  }, [newPassword, validEmails, validName, validPassword, validRules])

  return {
    name,
    lastName,
    email,
    emailCobza1,
    emailCobza2,
    newPassword,
    isFormValid,
    updateInfoMutation,
    updatePassMutation,
    setName,
    setLastName,
    setEmail,
    setEmailCobza1,
    setEmailCobza2,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    setValidRules,
    onSubmit,
  }
}

export default useProfileForm
