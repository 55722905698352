import { useCallback } from 'react'
import { Drawer } from '@mui/material'
import { months } from '../../../constants'
import CloseIcon from 'assets/icons/CloseIcon'
import RightArrowIcon from 'assets/icons/RightArrowIcon'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import './styles.scss'

const AccountingIncompleteProcessesDrawer = () => {
  const {
    isAccountingIncompleteProcessesDrawerOpen,
    accountingIncompleteProcesses,
    handleChangeDateRange,
    handleToggleAccountingIncompleteProcessesDrawer,
  } = useAccountingContext()

  const redirectToIncompleteProcess = useCallback(
    (year: string, month: number) => {
      const periodSelected = new Date(parseInt(year), month - 1)
      handleChangeDateRange(periodSelected)
      handleToggleAccountingIncompleteProcessesDrawer()
    },
    [handleChangeDateRange, handleToggleAccountingIncompleteProcessesDrawer]
  )

  return (
    <Drawer
      anchor="right"
      open={isAccountingIncompleteProcessesDrawerOpen}
      SlideProps={{ mountOnEnter: true, unmountOnExit: true }}
      onClose={handleToggleAccountingIncompleteProcessesDrawer}
      className="syd-right-drawer">
      <div className="syd-right-drawer-content">
        {accountingIncompleteProcesses && (
          <div className="incomplete-accounting-processes">
            <div className="incomplete-accounting-processes-title">
              <span>Cierres incompletos</span>
              <div onClick={handleToggleAccountingIncompleteProcessesDrawer}>
                <CloseIcon />
              </div>
            </div>

            <div className="incomplete-accounting-processes-description">
              <span>
                Haz clic sobre un periodo para ver su detalle o completar el
                servicio
              </span>
            </div>

            <div className="incomplete-accounting-processes-content">
              {accountingIncompleteProcesses.map(
                ({ year, incompleteProcesses }) => (
                  <div className="processes-content">
                    <span>{year}</span>
                    {incompleteProcesses.map(({ month }) => (
                      <div
                        className="processes-detail"
                        onClick={() =>
                          redirectToIncompleteProcess(year, month)
                        }>
                        <span>Proceso contable - {months[month]}</span>
                        <RightArrowIcon />
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default AccountingIncompleteProcessesDrawer
