import update from 'immutability-helper'
import _ from 'lodash'
import {
  SET_MANUAL_LOGOUT,
  SET_AUTHORIZATION,
  SET_USER_DATA,
  SET_SESSION_KEYS,
  SET_DEVICE,
  SET_USER_ROLE,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  SET_INVITATIONS,
  ADD_INVITATIONS,
  DELETE_INVITATION,
} from 'actions/user'

/** interfaces */
import { Profile, UserSession, UserStore } from 'models/user'
import { ReduxAction } from 'models/thunk'

const initialUser: UserStore = {
  manualLogout: false,
  authorized: false,
  session: {} as UserSession,
  device: '',
  data: {} as Profile,
  role: null,
  notifications: [],
  invitations: [],
}

const defaultAction: ReduxAction<any> = {
  type: '',
  value: {},
}

const user = (state = initialUser, action = defaultAction) => {
  switch (action.type) {
    case SET_MANUAL_LOGOUT:
      return update(state, { manualLogout: { $set: action.value } })
    case SET_AUTHORIZATION:
      return update(state, { authorized: { $set: action.value } })
    case SET_USER_DATA:
      return update(state, { data: { $set: action.value } })
    case SET_USER_ROLE:
      return update(state, { role: { $set: action.value } })
    case SET_SESSION_KEYS:
      return update(state, { session: { $set: action.value } })
    case SET_DEVICE:
      return update(state, { device: { $set: action.value } })

    case SET_NOTIFICATIONS:
      return update(state, { notifications: { $set: action.value } })
    case ADD_NOTIFICATIONS:
      return update(state, { notifications: { $push: action.value } })
    case DELETE_NOTIFICATION: {
      const index = _.findIndex(
        state.notifications,
        n => n.id === action.value.id
      )
      if (index === -1) return state
      return update(state, { notifications: { $splice: [[index, 1]] } })
    }

    case SET_INVITATIONS:
      return update(state, { invitations: { $set: action.value } })
    case ADD_INVITATIONS:
      return update(state, { invitations: { $push: action.value } })
    case DELETE_INVITATION: {
      const index = _.findIndex(
        state.invitations,
        i => i.id === action.value.id
      )
      if (index === -1) return state
      return update(state, { invitations: { $splice: [[index, 1]] } })
    }

    default:
      return state
  }
}

export default user
