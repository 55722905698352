import React, { useEffect } from 'react'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import RegisterForm from 'containers/RegisterForm/RegisterFormContainer'
import RecoverPasswordFormContainer from 'containers/RecoverPasswordForm/RecoverPasswordFormContainer'

import { setActiveForm } from 'actions/login'

import { selectActiveForm } from 'lib/helpers/selectors'
import { doScrollToTop } from 'lib/helpers/utilities'
import { ROUTES } from '../../constants'

import { LoginScreenForms as Form } from 'models/general'

import Image01 from 'assets/SMPL_Signin_700x600_01.png'
import Image02 from 'assets/SMPL_Signin_700x600_02.png'
import Image03 from 'assets/SMPL_Signin_700x600_03.png'
import './style.scss'
import Carousel from 'components/Login/Carousel/Carousel'
import LoginFormContainer from 'containers/Login/LoginFormContainer'

interface Props {}

const LoginScreen: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const activeForm = useSelector(selectActiveForm)

  const matchRegister = useMatch(ROUTES.REGISTER)
  const matchLogin = useMatch(ROUTES.LOGIN)
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const form = params.get('form')
  const email = params.get('email') || undefined

  const isRegister = Boolean(matchRegister)
  const isLogin = Boolean(matchLogin)

  useEffect(() => {
    if (form) {
      dispatch(setActiveForm(form))
      navigate(email ? `${ROUTES.LOGIN}?email=${email}` : ROUTES.LOGIN, {
        replace: true,
      })
    } else if (matchRegister) {
      dispatch(setActiveForm(Form.REGISTER))
    }
    doScrollToTop()

    return () => {
      dispatch(setActiveForm(isRegister ? Form.REGISTER : Form.LOGIN))
    }
    //eslint-disable-next-line
  }, [])

  const handleRegisterClicks = () => {
    if (isRegister) {
      navigate(ROUTES.LOGIN)
      dispatch(setActiveForm(Form.LOGIN))
    } else if (isLogin) {
      dispatch(setActiveForm(Form.LOGIN))
    }
  }

  return (
    <Card className="screen-login">
      <Grid className="screen-container" container spacing={0}>
        <Grid item xs={12} md={5} lg={4}>
          {activeForm === Form.LOGIN && (
            <LoginFormContainer
              onClickForgetPassword={() =>
                dispatch(setActiveForm(Form.RECOVER))
              }
              onClickRegister={() => dispatch(setActiveForm(Form.REGISTER))}
            />
          )}
          {activeForm === Form.REGISTER && (
            <RegisterForm
              email={email || undefined}
              onClickLogin={handleRegisterClicks}
            />
          )}
          {activeForm === Form.RECOVER && (
            <RecoverPasswordFormContainer
              onClickCancel={() => dispatch(setActiveForm(Form.LOGIN))}
            />
          )}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={7} lg={8} className="screen-section">
            <Carousel>
              <img src={Image01} alt="Imagen 1" />
              <img src={Image02} alt="Imagen 2" />
              <img src={Image03} alt="Imagen 3" />
            </Carousel>
          </Grid>
        </Hidden>
      </Grid>
    </Card>
  )
}

export default LoginScreen
