import { operationIdGlobalEventTypeMap } from 'lib/utils/shared.utils'
import { useAppDispatch } from 'store/hooks'
import { setStoreEvent } from 'store/StoreEvent/storeEvent.reducer'
import { ISSEEventData } from 'services/Shared/shared.models'

const useFireStoreEventFromSSE = (): ((
  typedEventData: ISSEEventData
) => void) => {
  const dispatch = useAppDispatch()

  const fireStoreEvent = (typedEventData: ISSEEventData) => {
    const { idOperation } = typedEventData

    const operationEventType = operationIdGlobalEventTypeMap[idOperation]

    operationEventType && dispatch(setStoreEvent(operationEventType))
  }

  return fireStoreEvent
}

export default useFireStoreEventFromSSE
