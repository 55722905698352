import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import ArrowBack from 'assets/arrow_back.svg'
import { selectProfile } from 'lib/helpers/selectors'
import { nameBuilder } from 'lib/helpers/utilities'
import ProfileHeader from 'components/Shared/ProfileHeader/ProfileHeader'
import ProfileForm from 'components/Account/Profile/ProfileForm/ProfileForm'
import './styles.scss'

const AccountScreen = () => {
  const navigate = useNavigate()

  const profile = useSelector(selectProfile)

  return (
    <div className="screen-account">
      <div className="title-row">
        <h2>Mi perfil</h2>
        <Button
          startIcon={<img src={ArrowBack} alt="regresar" />}
          onClick={() => navigate(-1)}
          disableRipple>
          Volver
        </Button>
      </div>
      <div className="profile-card">
        <div className="header">
          <ProfileHeader
            name={
              nameBuilder(
                profile?.name,
                profile?.lastname,
                profile?.lastname2
              ) || '- - -'
            }
            email={profile?.email}
          />
        </div>
        <div className="content">
          <ProfileForm />
        </div>
      </div>
    </div>
  )
}

export default AccountScreen
