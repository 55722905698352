import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { CLIENTS_FLAGS } from '../../../constants'

import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Table from 'components/Shared/DynamicTable/DynamicTable'
import Tooltip from '@material-ui/core/Tooltip'
import Dot from '@material-ui/icons/FiberManualRecord'
//import Button from 'components/Button';
//import TextField from 'components/IOComponents/TextField';
//import SelectField from 'components/IOComponents/SelectField';

import useClientsList, { useDeleteClient } from 'hooks/queries/clients'

import { selectClientsList } from 'lib/helpers/selectors'

import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import './style.scss'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const ClientsContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const list = useSelector(selectClientsList)

  // #region Queries
  const listQuery = useClientsList(workspaceId, {
    onError: () => onRedirect('error'),
  })
  // #endregion

  // #region Mutations
  const deleteMutation = useDeleteClient(workspaceId, {
    onSuccess: () => listQuery.refetch(),
  })
  // #endregion

  // #region Table Formatters
  const formatArticulo69bStatus = (flags: number) => {
    let label = 'Sin coincidencia'
    let className = 'active'
    let Icon = Dot

    if ((flags & CLIENTS_FLAGS.ARTICULO_69B) === CLIENTS_FLAGS.ARTICULO_69B) {
      label = 'Definitivo'
      className = 'canceled'
    }

    return (
      <span className="status-container">
        <Tooltip title={label}>
          <Icon className={`status-icon ${className}`} color="inherit" />
        </Tooltip>
      </span>
    )
  }
  const formatZipCode = (v: string) => (v ? _.padStart(v, 5, '0') : '- - -')
  const formatActions = (id: number, row: any, key: any) => (
    <>
      <IconButton
        id={`editClientBtn_${key}`}
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          onRedirect('edit', row.id)
        }}>
        <Edit fontSize="inherit" />
      </IconButton>
      <IconButton
        id={`deleteClientBtn_${key}`}
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          deleteMutation.mutate(row)
        }}>
        <Delete fontSize="inherit" />
      </IconButton>
    </>
  )
  // #endregion

  // #region Table Headers
  const headers = [
    { label: 'Alias', accesor: 'alias' },
    { label: 'Nombre o razón social', accesor: 'name' },
    { label: 'RFC', accesor: 'rfc', renderer: _.toUpper },
    { label: 'Código postal', accesor: 'zipCode', renderer: formatZipCode },
    { label: 'Correo electrónico', accesor: 'email' },
    { label: 'Art. 69B', accesor: 'flags', renderer: formatArticulo69bStatus },
    {
      label: '',
      accesor: 'id',
      renderer: formatActions,
      cellClassName: 'actions',
    },
  ]
  // #endregion

  return (
    <>
      {/* 
        <div className="container-clients-filter">
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <TextField
                        name="search"
                        label={"Buscar"}
                        placeholder={"Busca por alias, nombre, razón social o RFC"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectField 
                        name="search"
                        label={"Filtro 2"}
                        options={[]}
                        value={""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectField 
                        name="search"
                        label={"Filtro 3"}
                        options={[]}
                        value={""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {}}
                    >
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </div>
        */}
      <Card elevation={1} className="container-clients-list">
        <Table
          headers={headers}
          rows={list}
          loading={listQuery.isLoading}
          fetching={listQuery.isFetching}
        />
      </Card>
    </>
  )
}

export default ClientsContainer
