/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const planUpdateValidationSchema = yup.object().shape({
  message: yup
    .string()
    .required('Descripción es requerida')
    .max(500, 'Máximo 500 caracteres'),
})

export const paymentValidationSchema = yup.object().shape({
  name: yup.string().min(2).required('El nombre en la tarjeta es requerido'),
  number: yup
    .string()
    .matches(/^([0-9]{4}\s){3}[0-9]{4}$/)
    .min(19)
    .required('El número de tarjeta es requerido'),
  expiration: yup
    .string()
    .matches(/^\d{2}\/\d{2}$/)
    .max(5)
    .required('La fecha de expiración es requerida'),
  cvc: yup
    .string()
    .matches(/\d{3}/)
    .max(3)
    .required('El código de seguridad es requerido'),
})
