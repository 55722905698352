import { FC } from 'react'
import { Box, Drawer, IconButton, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import AccountMovementDrawerContent from '../AccountMovementDrawerContent/AccountMovementsDrawerContent'
import ConciliationDetailsContent from '../ConciliationDetailsContent/ConciliationDetailsContent'
import CFDIMovementDrawerContent from '../CFDIMovementDrawerContent/CFDIMovementDrawerContent'
import FileListDrawerContent from '../FileListDrawerContent/FileListDrawerContent'

const drawerModes: Record<string, FC> = {
  account: AccountMovementDrawerContent,
  cfdi: CFDIMovementDrawerContent,
  filelist: FileListDrawerContent,
  conciliation: ConciliationDetailsContent,
}

const getDrawerTitle = (mode: string) => {
  switch (mode) {
    case 'account':
      return 'Detalle movimiento'
    case 'cfdi':
      return 'Detalle CFDI'
    case 'filelist':
      return 'Archivos cargados'
    case 'conciliation':
      return 'Movimientos conciliados'
    default:
      return 'Detalles'
  }
}

const DrawerToolbar: FC = () => {
  const { drawer, closeDrawer } = useConciliationsContainerContext()

  return (
    <Toolbar>
      <Typography variant="h6">
        {getDrawerTitle(drawer.mode || 'any')}
      </Typography>
      <Box flex={1} />
      <IconButton onClick={closeDrawer}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
  )
}

const ConciliationsDrawer: FC = () => {
  const { drawer, closeDrawer } = useConciliationsContainerContext()

  const DrawerContent = drawerModes[drawer.mode as string] || (() => null)

  const isDrawerFileList = drawer.mode === 'filelist'
  const isDrawerConciliation = drawer.mode === 'conciliation'

  return (
    <Drawer
      open={drawer.open}
      anchor="right"
      onClose={(event, reason) => {
        if (isDrawerFileList && reason === 'backdropClick') {
          return
        } else {
          closeDrawer()
        }
      }}
      ModalProps={{
        disableEscapeKeyDown: isDrawerFileList,
      }}>
      <Box width={isDrawerFileList || isDrawerConciliation ? 600 : 400}>
        <DrawerToolbar />
        <Box padding={3}>
          <DrawerContent />
        </Box>
      </Box>
    </Drawer>
  )
}

export default ConciliationsDrawer
