import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider, MenuItem } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HistoryIcon from '@material-ui/icons/History'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import SyncIcon from '@material-ui/icons/Sync'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import StyledIconButton from 'components/Shared/StyledIconButton/StyledIconButton'
import StyledMenu from 'components/Shared/StyledMenu/StyledMenu'
import CFDIFileWrapper from '../CFDIFileWrapper/CFDIFileWrapper'

const FiltersButton = () => {
  const { cfdis } = useConciliationsContainerContext()

  const { cfdiFilters, setConciliationCfdiFilters } = cfdis

  const { display } = cfdiFilters
  const dispatch = useDispatch()

  const toggle = () => {
    if (cfdiFilters.display === true) {
      dispatch(
        setConciliationCfdiFilters({
          ...cfdiFilters,
          display: false,
          text: '',
          status: '',
          date: null,
        })
      )
      return
    }
    dispatch(
      setConciliationCfdiFilters({
        ...cfdiFilters,
        display: !display,
      })
    )
  }

  return (
    <StyledIconButton
      onClick={toggle}
      className={display ? 'toolbar-button active' : 'toolbar-button '}>
      {display ? <CloseIcon /> : <SearchIcon />}
    </StyledIconButton>
  )
}

interface ILoadFileButtonProps {
  handleClose: () => void
}

const LoadFileButton = ({ handleClose }: ILoadFileButtonProps) => {
  return (
    <Fragment>
      <CFDIFileWrapper mode="option" onContinuousClick={handleClose} />
    </Fragment>
  )
}

interface ISyncButtonProps {
  handleClose: () => void
}

const SyncButton = ({ handleClose }: ISyncButtonProps) => {
  const { updateIsSyncCFDIOpen } = useConciliationsContainerContext()

  const dispatch = useDispatch()

  const handleSync = () => {
    handleClose()
    dispatch(updateIsSyncCFDIOpen(true))
  }

  return (
    <MenuItem onClick={handleSync}>
      <SyncIcon />
      Sincronizar facturas
    </MenuItem>
  )
}

const MenuButton = () => {
  const {
    screen: { handleSetTab },
  } = useConciliationsContainerContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onHistoryClick = () => {
    handleSetTab(CONCILIATION_TABS.CFDI_HISTORY)
    handleClose()
  }

  return (
    <Fragment>
      <StyledIconButton
        onClick={handleClick}
        aria-haspopup="true"
        aria-controls="cfdi-actions-menu">
        <MoreVertIcon />
      </StyledIconButton>
      <StyledMenu
        id="cfdi-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <SyncButton handleClose={handleClose} />
        <Divider />
        <MenuItem onClick={onHistoryClick}>
          <HistoryIcon />
          Ver historial
        </MenuItem>
        <Divider />
        <LoadFileButton handleClose={handleClose} />
      </StyledMenu>
    </Fragment>
  )
}

const CFDIActions = () => {
  return (
    <Box className="toolbar-actions">
      <FiltersButton />
      <MenuButton />
    </Box>
  )
}

export default CFDIActions
