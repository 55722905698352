import { SydIconsModel } from 'models/shared.models'

const PDFFileIcon = ({ color }: SydIconsModel) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H16.5V22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V16.5H3V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM2.4 17.775H0V23.7735H1.1865V21.7605H2.391C2.8215 21.7605 3.1875 21.675 3.489 21.501C3.7935 21.3255 4.026 21.0885 4.1835 20.79C4.34698 20.4768 4.42997 20.1278 4.425 19.7745C4.425 19.3995 4.3455 19.0605 4.188 18.759C4.03134 18.4593 3.79183 18.2109 3.498 18.0435C3.198 17.8635 2.8335 17.775 2.4 17.775ZM3.2175 19.7745C3.22291 19.9721 3.17909 20.168 3.09 20.3445C3.01007 20.4982 2.88565 20.6242 2.733 20.706C2.55838 20.7924 2.36524 20.8346 2.1705 20.829H1.182V18.72H2.172C2.499 18.72 2.7555 18.81 2.94 18.9915C3.1245 19.1745 3.2175 19.4355 3.2175 19.7745ZM5.043 17.775V23.7735H7.233C7.8345 23.7735 8.334 23.6535 8.73 23.418C9.13073 23.1782 9.44366 22.8159 9.6225 22.3845C9.8175 21.9345 9.9165 21.3915 9.9165 20.7585C9.9165 20.1285 9.819 19.5915 9.6225 19.146C9.44577 18.7196 9.13584 18.3618 8.739 18.126C8.343 17.892 7.8405 17.775 7.2315 17.775H5.043ZM6.2295 18.7425H7.074C7.446 18.7425 7.749 18.8175 7.9875 18.9705C8.23506 19.1324 8.42183 19.3719 8.5185 19.6515C8.637 19.953 8.6955 20.3295 8.6955 20.781C8.70015 21.0802 8.66586 21.3787 8.5935 21.669C8.54006 21.898 8.43998 22.1135 8.2995 22.302C8.16899 22.4702 7.99608 22.6007 7.7985 22.68C7.567 22.7666 7.32112 22.8084 7.074 22.803H6.2295V18.7425ZM11.844 21.387V23.7735H10.659V17.775H14.481V18.7545H11.844V20.43H14.253V21.387H11.844Z"
        fill={color ?? '#6885A0'}
      />
    </svg>
  )
}

export default PDFFileIcon
