import ReduxState from 'models/redux'

export const selectAccountingActiveDateRange = (store: ReduxState) =>
  store.accounting.activeDateRange
export const selectAccountingForm = (store: ReduxState) => store.accounting.form
export const selectAccountingTasks = (store: ReduxState) =>
  store.accounting.accountingTasks
export const selectRefetchAccountingProcess = (store: ReduxState) =>
  store.accounting.refetchAccountingProcess
export const selectCurrentAccountingProcess = (store: ReduxState) =>
  store.accounting.currentAccountingProcess
