import React from 'react'
import { useDidUpdate } from 'rooks'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import { selectActiveWorkspace } from 'lib/helpers/selectors'

import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'
import AddClientContainer from 'containers/Clients/AddClientContainer/AddClientContainer'

interface Props {}

const AddClientScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const intl = useIntl()

  const workspaceId = useSelector(selectActiveWorkspace)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('cancel')
  }, [workspaceId])

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error') navigate(ROUTES.CLIENTS, { replace: true })
    if (action === 'cancel' || action === 'success') navigate(ROUTES.CLIENTS)
  }

  if (!workspaceId) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-clients">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>{intl.formatMessage({ id: 'client.screen.title' })}</h1>
            <Button
              id="cancelNewClientBtn"
              variant="hover"
              color="primary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              {intl.formatMessage({ id: 'button.close' })}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <AddClientContainer
            workspaceId={workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddClientScreen
