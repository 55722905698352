import { Box, Drawer, IconButton, Toolbar, Typography } from "@material-ui/core";
import { OCRContext } from "containers/Conciliations/OCRProcessContainer/OCRProcessContainer";
import { FC, useContext } from "react";
import CloseIcon from 'mdi-material-ui/Close';
import OCRStatementEditForm from "../OCRStatementEditForm/OCRStatementEditForm";
import OCRTransactionForm from "../OCRTransactionForm/OCRTransactionForm";

const contents = {
  statement: OCRStatementEditForm,
  transaction: () => (
    <OCRTransactionForm mode="edit" />
  ),
  'transaction--create': () => (
    <OCRTransactionForm mode="create" />
  ),
};

const DrawerHeader: FC = () => {
  const { drawer, closeDrawer } = useContext(OCRContext);

  const title = (() => {
    if (drawer.type.is === 'statement') {
      return 'Editar información general';
    }
    if (drawer.type.is === 'transaction') {
      return 'Editar movimiento';
    }
    if (drawer.type.is === 'transaction--create') {
      return 'Agregar movimiento';
    }
    return 'nueva transacción';
  })();

  return (
    <Toolbar>
      <Box flex={1}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <IconButton
        onClick={closeDrawer}
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  );
};

// TODO: add money format to number input fields

const OCRDrawer: FC = () => {
  const { drawer } = useContext(OCRContext);
  const Content = contents[drawer.type.is] || (() => null);
  return (
    <Drawer
      anchor="right"
      open={drawer.open}
      
    >
      <Box
        width={600}
      >
        <DrawerHeader />
        <Content />
      </Box>
    </Drawer>
  );
};

export default OCRDrawer;