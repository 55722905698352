import _ from 'lodash'
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import ArrowForward from '@material-ui/icons/ArrowForward'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SwapIcon from '@material-ui/icons/SwapVert'
import { toCurrency } from 'lib/helpers/utilities'
import { ReportItem } from 'services/conciliations'
import useConciliationReportsTable from './useConciliationReportsTable'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#444',
    borderLeft: '4px solid',
    borderLeftColor: theme.palette.error.main,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    paddingBlock: 16,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
}))(Tooltip)

const ConciliationReportsTable = () => {
  const {
    reportsLoading,
    reports,
    classes,
    isRowActionLoading,
    setActiveReport,
    handleDownload,
  } = useConciliationReportsTable()

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Estatus',
      renderCell: params => {
        return (
          <Box
            className="cell"
            display="flex"
            justifyContent="center"
            color={Number(params.value) === 1 ? 'success.main' : 'error.main'}>
            {Number(params.value) === 1 ? (
              <CheckCircleIcon />
            ) : (
              <HtmlTooltip
                title="Esta conciliación contiene CFDIs expirados"
                aria-label="expired"
                placement="bottom-start">
                <ErrorIcon />
              </HtmlTooltip>
            )}
          </Box>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Tipo',
      flex: 1,
    },
    {
      field: 'period',
      headerName: 'Fecha',
      width: 150,
      valueFormatter: params => _.capitalize(params.value as string),
    },
    {
      field: 'amountConciliated',
      headerName: 'Monto',
      width: 170,
      valueFormatter: params => toCurrency(params.value as number, 'MXN'),
      renderCell(params) {
        return (
          <Box display="flex" width="100%" alignItems="center">
            <Box flex={1}>{params.formattedValue}</Box>
            <Box display="flex" alignItems="center" gridGap={10}>
              <IconButton
                size="small"
                style={{ color: orange[500] }}
                disabled={isRowActionLoading}
                onClick={() => handleDownload(params.row as ReportItem)}>
                {isRowActionLoading ? (
                  <CircularProgress
                    size={16}
                    variant="indeterminate"
                    style={{ color: orange[500] }}
                  />
                ) : (
                  <SaveAltIcon />
                )}
              </IconButton>
              <IconButton
                size="small"
                style={{ color: orange[500] }}
                onClick={() => setActiveReport(params.row.id as number)}>
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>
        )
      },
    },
  ]
  return (
    <DataGrid
      classes={classes}
      rows={reports}
      columns={columns}
      autoHeight
      headerHeight={100}
      density="compact"
      disableColumnMenu
      loading={reportsLoading}
      style={{
        border: 'none',
        fontSize: 14,
      }}
      components={{
        Footer: () => null,
        ColumnResizeIcon: () => null,
        ColumnSortedAscendingIcon: SwapIcon,
        ColumnSortedDescendingIcon: SwapIcon,
        LoadingOverlay: () => <LinearProgress variant="indeterminate" />,
        NoRowsOverlay: () => (
          <Box
            height={250}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Typography variant="h6">
              No hay movimientos conciliados para este periodo.
            </Typography>
          </Box>
        ),
      }}
    />
  )
}

export default ConciliationReportsTable
