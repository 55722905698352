import _ from 'lodash';
import colors from './colors.module.scss';
import _variables from './variables.module.scss';

interface IColor {
    primary: string
    secondary: string
    highlight: string
}

interface IGrayscale {
    white: string
    black: string
    gray: string
}

interface IComplementary {
    color1: string
    color2: string
    color3: string
    color4: string
    color5: string
    color6: string
    color7: string
    color8: string
    color9: string
    color10: string
}
interface IChart {
    color1: string
    color2: string
    color3: string
    color4: string
    color5: string
    color6: string
    color7: string
    color8: string
    color9: string
    color10: string
    color11: string
    color12: string
    color13: string
    color14: string
    color15: string
}
interface ISymbolic {
    info: string
    warning: string
    danger: string
    success: string
}

export interface IPalettes {
    Main: IColor
    Alt: IColor
    Mono: IGrayscale
    Complementary: IComplementary
    Chart: IChart
    Symbolic: ISymbolic
}

/**
 * Javascript Scope of the Color Palettes defined at colors.scss in theme
 * @type {object}
 */
export const Palettes: IPalettes = {
    /** Main color palette */
    Main: {
        primary: colors.colorPrimary,
        secondary: colors.colorSecondary,
        highlight: colors.colorHighlight
    },
    /** Alternative or Secondary color palette */
    Alt: {
        primary: colors.colorAltPrimary,
        secondary: colors.colorAltSecondary,
        highlight: colors.colorAltHighlight
    },
    /** Monochromatic Palette */
    Mono: {
        white: colors.colorWhite,
        black: colors.colorBlack,
        gray: colors.colorGray
    },
    /** Complementary Colors Palette */
    Complementary: {
        color1: colors.colorComplementary1,
        color2: colors.colorComplementary2,
        color3: colors.colorComplementary3,
        color4: colors.colorComplementary4,
        color5: colors.colorComplementary5,
        color6: colors.colorComplementary6,
        color7: colors.colorComplementary7,
        color8: colors.colorComplementary8,
        color9: colors.colorComplementary9,
        color10: colors.colorComplementary10,
    },
    /** Chart Colors Palette */
    Chart: {
        color1: colors.colorChart1,
        color2: colors.colorChart2,
        color3: colors.colorChart3,
        color4: colors.colorChart4,
        color5: colors.colorChart5,
        color6: colors.colorChart6,
        color7: colors.colorChart7,
        color8: colors.colorChart8,
        color9: colors.colorChart9,
        color10: colors.colorChart10,
        color11: colors.colorChart11,
        color12: colors.colorChart12,
        color13: colors.colorChart13,
        color14: colors.colorChart14,
        color15: colors.colorChart15,

    },
    /** Symbolic Colors Palette */
    Symbolic: {
        info: colors.colorInfo,
        warning: colors.colorWarning,
        danger: colors.colorDanger,
        success: colors.colorSuccess
    }
}


export interface IVariables {
    [key: string]: string
}

/**
 * Javascript Scope of the Color Palettes defined at colors.scss in theme
 * @type {object}
 */
const variables: IVariables = {
    ..._variables,
    fontFamily: _.replace(_variables.fontFamily, /"/g, "")
}
export const Variables = variables;

/**
 * Javascript Scope of the Color Palettes defined at colors.scss in theme
 * @type {object}
 */
export const Colors = colors;