import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { ContextColors } from 'models/general'
import { SydIconsModel } from 'models/shared.models'
import ErrorRed from 'assets/error-red.svg'
import Button, { ButtonProps } from 'components/Shared/Button/Button'
import './styles.scss'

export type TModalIcon =
  | string
  | ((props: SydIconsModel) => JSX.Element)
  | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>

export interface IModalButton extends ButtonProps {
  text: string
}

interface ISydDialogProps {
  open: boolean
  className?: string
  variant?: ContextColors
  size?: 'sm'
  fullWidth?: boolean
  icon?:
    | string
    | ((props: SydIconsModel) => JSX.Element)
    | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  title?: string
  subtitle?: string
  message?: string
  form?: string
  hidePrimaryButton?: boolean
  sideButtons?: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  disablePrimaryButton?: boolean
  loadingPrimaryButton?: boolean
  disableSecondaryButton?: boolean
  loadingSecondaryButton?: boolean
  onClose?: () => void
  onConfirm?: () => void
  onCancel?: () => void
}

const SydDialog = ({
  open,
  className,
  variant,
  size,
  fullWidth,
  icon: Icon,
  title,
  subtitle,
  message,
  form,
  hidePrimaryButton,
  sideButtons,
  primaryButtonText,
  secondaryButtonText,
  disablePrimaryButton,
  loadingPrimaryButton,
  disableSecondaryButton,
  loadingSecondaryButton,
  children,
  onClose,
  onConfirm,
  onCancel,
}: PropsWithChildren<ISydDialogProps>) => {
  const intl = useIntl()

  const isErrorVariant = variant === 'danger' || variant === 'dangerAsync'

  const onCloseHandler = (_e: object, reason: string) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return
    onClose && onClose()
  }

  return (
    <Dialog
      className={`syd-dialog-component ${isErrorVariant ? 'error' : ''} ${className ?? ''} ${size ?? ''} ${fullWidth ? 'full-width' : ''}`}
      open={open}
      onClose={onCloseHandler}>
      {!!onClose && (
        <div className="close-row">
          <button onClick={() => onClose && onClose()}>
            <CloseIcon />
          </button>
        </div>
      )}
      {isErrorVariant && !Icon && (
        <div className="modal-icon">
          <img src={ErrorRed} alt="error" />
        </div>
      )}
      {Icon && (
        <div className="modal-icon">
          {typeof Icon === 'string' ? <img src={Icon} alt="icon" /> : <Icon />}
        </div>
      )}
      {(title || isErrorVariant) && (
        <h1 className={isErrorVariant ? 'error-title' : ''}>
          {intl.formatMessage({
            id: title ? title : isErrorVariant ? 'generic.error.title' : '',
          })}
        </h1>
      )}
      {subtitle && <p>{intl.formatMessage({ id: subtitle })}</p>}
      {message && (
        <p className="message">{intl.formatMessage({ id: message })}</p>
      )}
      {children && <article className="modal-content">{children}</article>}
      {(!hidePrimaryButton || (hidePrimaryButton && secondaryButtonText)) && (
        <article
          className={`modal-actions ${sideButtons ? 'side-buttons' : ''}`}>
          {!hidePrimaryButton && (
            <Button
              variant="contained"
              color="success"
              onClick={onConfirm}
              disabled={disablePrimaryButton}
              activity={loadingPrimaryButton}
              type={form ? 'submit' : 'button'}
              form={form}>
              {intl.formatMessage({
                id: primaryButtonText
                  ? primaryButtonText
                  : isErrorVariant
                    ? 'button.continue'
                    : 'button.understand',
              })}
            </Button>
          )}
          {secondaryButtonText && onCancel && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={onCancel}
              disabled={disableSecondaryButton}
              activity={loadingSecondaryButton}>
              {secondaryButtonText}
            </Button>
          )}
        </article>
      )}
    </Dialog>
  )
}

export default SydDialog
