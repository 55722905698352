import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import { useNavigate } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import NewCFDIDialog from 'components/Shared/NewCFDIDialog/NewCFDIDialog'
import OtherServicesDialog from 'components/Sidebar/OtherServicesDialog/OtherServicesDialog'
import { logout } from 'actions/user'

import {
  selectActiveWorkspace,
  selectAuthorized,
  selectCreateWorkspaceFlag,
  selectUserRole,
} from 'lib/helpers/selectors'

import { ROLES, ROUTES } from '../../constants'

import NewCFDI from '@material-ui/icons/NoteAdd'
import Home from '@material-ui/icons/Home'
import CFDI from '@material-ui/icons/Description'
//import Workspaces from '@material-ui/icons/AssignmentInd';
import Clients from '@material-ui/icons/PeopleAlt'
// import Emisor from '@material-ui/icons/Business';
import CategoriesAndProducts from '@material-ui/icons/LibraryBooks'
import Documentation from '@material-ui/icons/Folder'
import Accounting from 'mdi-material-ui/CalculatorVariant'
import Employees from '@material-ui/icons/SupervisorAccount'
import Services from '@material-ui/icons/EmojiEvents'
import LogOut from '@material-ui/icons/ExitToApp'
import SwapOriz from '@material-ui/icons/SwapHoriz'
import './style.scss'

interface SidebarComponentProps extends IconButtonProps {
  icon: any
  action: () => void
  tooltip?: string
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({
  icon: Icon,
  action,
  color,
  tooltip,
  ...props
}) => {
  const renderIconButton = () => (
    <IconButton color={color || 'inherit'} onClick={action} {...props}>
      <Icon />
    </IconButton>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement="right">
          {renderIconButton()}
        </Tooltip>
      ) : (
        renderIconButton()
      )}
    </>
  )
}

interface SidebarProps {}

const SidebarContainer: React.FC<SidebarProps> = props => {
  const thunkDispatch = useThunkDispatch()
  const navigate = useNavigate()

  const workspaceId = useSelector(selectActiveWorkspace)
  const role = useSelector(selectUserRole)
  const authorized = useSelector(selectAuthorized)
  const isCreatingWorkspace = useSelector(selectCreateWorkspaceFlag)

  const [openCFDI, setOpenCFDI] = useState(false)
  const [openServices, setOpenServices] = useState(false)

  const changeRoute = (route: string) => {
    navigate(route)
    setOpenCFDI(false)
  }

  if (!authorized || isCreatingWorkspace) return <></>

  return (
    <>
      <Drawer className="component-sidebar" variant="permanent">
        <Toolbar className="spacer" />
        {!!workspaceId && (
          <SidebarComponent
            id="sbNewCfdiBtn"
            icon={NewCFDI}
            action={() => setOpenCFDI(true)}
            tooltip="Nuevo CFDI"
            color="secondary"
          />
        )}
        <SidebarComponent
          id="sbHomeBtn"
          icon={Home}
          action={() => changeRoute(ROUTES.HOME)}
          tooltip="Dashboard"
        />
        {/* <SidebarComponent
                icon={Workspaces}
                action={() => changeRoute(ROUTES.WORKSPACES)}
                tooltip="Espacios de trabajo"
            /> */}
        {!!workspaceId && (
          <>
            <SidebarComponent
              id="sbCfdisBtn"
              icon={CFDI}
              action={() => changeRoute(ROUTES.INVOICES)}
              tooltip="CFDIs"
            />
            {/* <SidebarComponent
                    icon={Emisor}
                    action={() => changeRoute(ROUTES.RAZONES_SOCIALES)}
                    tooltip="Emisores"
                /> */}
            <SidebarComponent
              id="sbClientsBtn"
              icon={Clients}
              action={() => changeRoute(ROUTES.CLIENTS)}
              tooltip="Clientes/receptores"
            />
            <SidebarComponent
              id="sbCategoriesAndProductsBtn"
              icon={CategoriesAndProducts}
              action={() => changeRoute(ROUTES.CATEGORIES_PRODUCTS)}
              tooltip="Categorías, productos y servicios"
            />
            <SidebarComponent
              id="sbDocumentationBtn"
              icon={Documentation}
              action={() => changeRoute(ROUTES.DOCUMENTATION)}
              tooltip="Documentos"
            />
            <SidebarComponent
              id="sbAccountingBtn"
              icon={Accounting}
              action={() => changeRoute(ROUTES.ADD_ACCOUNTING)}
              tooltip="Contabilidad"
            />
            {[
              ROLES.ADMINISTRADOR.id,
              ROLES.CONTADOR_MR.id,
              ROLES.CONTADOR_SR.id,
              ROLES.CONTADOR_JR.id,
            ].indexOf(role?.id || 4) > -1 && (
              <SidebarComponent
                id="sbConciliationBtn"
                icon={SwapOriz}
                action={() => changeRoute(ROUTES.CONCILIATIONS)}
                tooltip="Conciliación"
              />
            )}
            {[ROLES.ADMINISTRADOR.id, ROLES.CONTADOR_MR.id].indexOf(
              role?.id || 4
            ) > -1 && (
              <Fragment>
                <SidebarComponent
                  id="sbManagement"
                  icon={Employees}
                  action={() => changeRoute(ROUTES.MANAGEMENT)}
                  tooltip="Gestión"
                />
                {/* <SidebarComponent
                            id="sbEmployeesBtn"
                            icon={Employees}
                            action={() => changeRoute(ROUTES.EMPLOYEES)}
                            tooltip="Contadores"
                        /> */}
              </Fragment>
            )}
          </>
        )}
        <div className="icon-1" />
        <SidebarComponent
          id="sbServicesBtn"
          icon={Services}
          action={() => setOpenServices(true)}
          tooltip="Convenios y colaboraciones"
        />
        <SidebarComponent
          id="sbLogOutBtn"
          icon={LogOut}
          action={() => thunkDispatch(logout(true))}
          tooltip="Cerrar sesión"
        />
      </Drawer>
      <OtherServicesDialog
        open={openServices}
        onClose={() => setOpenServices(false)}
      />
      {!!workspaceId && (
        <NewCFDIDialog open={openCFDI} onClose={() => setOpenCFDI(false)} />
      )}
    </>
  )
}

export default SidebarContainer
