import { Button, CircularProgress } from '@material-ui/core'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { lime } from '@material-ui/core/colors'
import useDialog from 'hooks/Shared/useDialog'
import useGetCFDIs from 'hooks/useGetCFDIs'
import useQueryConciliations from 'hooks/useQueryConciliations'
import { useQueryStatements } from 'hooks/useQueryStatements'
import './styles.scss'
import { setLedgerSelectedRows } from 'actions/conciliations'
import useConciliate from 'hooks/useConciliate'
import {
  ConciliationBodyType,
  conciliateTransactions,
} from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const ConciliateButton = () => {
  const { workspaceInfo } = useConciliationsContainerContext()

  const { statements, cfdis, ledgers, conciliationPeriod } =
    useConciliationsContainerContext()

  const dispatch = useDispatch()
  const dialog = useDialog()

  const { refetch: refetchCFDIs } = useGetCFDIs()
  const { refetch: refetchStatements } = useQueryStatements()
  const { refetch: refetchConciliations } = useQueryConciliations()

  const [isLoading, setIsLoading] = useState(false)

  const { getBody } = useConciliate(statements, ledgers, cfdis)

  const parseConciliation = async () => {
    setIsLoading(true)
    try {
      const body = getBody(conciliationPeriod)
      await conciliateTransactions(
        workspaceInfo.activeWorkspace,
        body as ConciliationBodyType
      )
      setIsLoading(false)
      toast.success('Se ha realizado la conciliación correctamente')
      await refetchCFDIs()
      await refetchStatements()
      await refetchConciliations()
      dispatch(setLedgerSelectedRows([]))
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      return await dialog.primaryAsync({
        title: 'No conciliado',
        subtitle: 'No se ha podido realizar la conciliación',
      })
    }
  }

  return (
    <Button
      className="conciliate-button-component"
      variant="outlined"
      onClick={parseConciliation}>
      {isLoading ? (
        <CircularProgress size={20} style={{ color: lime[500] }} />
      ) : (
        'Conciliar'
      )}
    </Button>
  )
}

export default ConciliateButton
