import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDidMount, useDidUpdate } from 'rooks';

interface Props {
	promise: any,
	pending: any,
	children: any
}

interface State {
  solutionState: 'initial' | 'observed' | 'solved'
}

export const AsyncHubFC: React.FC<Props> = (props) => {
	const [solution, setSolution] = useState<'initial' | 'observed' | 'solved'>('initial');
	useDidMount(() => { observe() });
	useDidUpdate(() => { observe() });

	const observe = () => {
        const { promise } = props;
        if (promise && solution === 'initial') {
            promise
			    .then(() => { setSolution('solved'); })
			    .catch(() => { setSolution('solved'); });
            
            setSolution('observed');
        }
    }

	const { children, pending } = props;
	const node = (solution === 'solved') ? children : pending;
	return (node)
}

class AsyncHub extends React.Component<Props, State> {
	static propTypes = {
		children: PropTypes.node,
		pending: PropTypes.node,
		promise: PropTypes.any
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			solutionState: 'initial',
		}
	}

	componentDidMount() {
		this.observe()
	}

    componentDidUpdate() {
        this.observe()
    }

    observe() {
        const { promise } = this.props
        const { solutionState } = this.state
        if (promise && solutionState === 'initial') {
            promise
			    .then(() => { this.setState({ solutionState: 'solved' }); })
			    .catch(() => { this.setState({ solutionState: 'solved' }); });
            
            this.setState({ solutionState: 'observed' })
        }
    }

	render () {
		const { children, pending } = this.props;
        const { solutionState } = this.state;
        const node = (solutionState === 'solved') ? children : pending;
		return (node)
	}
}

export default AsyncHub;
