import React, { useCallback, useMemo, useState } from 'react'

import Loader from 'components/Shared/Loader/Loader'

import { ContextColors } from 'models/general'

interface LoaderProviderProps {}

interface LoaderProviderState {
  isOpen: boolean
  color: ContextColors
  text: string
  direction: 'row' | 'column'
}

export interface LoadingContextProps {
  open: Boolean
  primary: (text?: string, direction?: 'row' | 'column') => void
  secondary: (text?: string, direction?: 'row' | 'column') => void
  success: (text?: string, direction?: 'row' | 'column') => void
  warning: (text?: string, direction?: 'row' | 'column') => void
  danger: (text?: string, direction?: 'row' | 'column') => void
  info: (text?: string, direction?: 'row' | 'column') => void
  close: () => void
}

export const LoaderContext = React.createContext<LoadingContextProps>({
  open: false,
  primary: () => {},
  secondary: () => {},
  success: () => {},
  warning: () => {},
  danger: () => {},
  info: () => {},
  close: () => {},
})

const LoaderProvider: React.FC<LoaderProviderProps> = props => {
  const [state, setState] = useState<LoaderProviderState>({
    isOpen: false,
    color: 'primary',
    text: '',
    direction: 'row',
  })

  const close = useCallback(() => {
    setState(prevState => ({ ...prevState, isOpen: false }))
  }, [])

  const primary = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('primary', text, direction)
    },
    []
  )

  const secondary = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('secondary', text, direction)
    },
    []
  )

  const success = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('success', text, direction)
    },
    []
  )

  const warning = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('warning', text, direction)
    },
    []
  )

  const danger = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('danger', text, direction)
    },
    []
  )

  const info = useCallback(
    (text?: string, direction: 'row' | 'column' = 'row') => {
      open('info', text, direction)
    },
    []
  )

  const open = (
    color: ContextColors,
    text: string = '',
    direction: 'row' | 'column' = 'row'
  ) => {
    setState({
      isOpen: true,
      color,
      text,
      direction,
    })
  }

  const contextValue = useMemo(
    () => ({
      open: state.isOpen,
      primary,
      secondary,
      success,
      warning,
      danger,
      info,
      close,
    }),
    [state.isOpen, primary, secondary, success, warning, danger, info, close]
  )

  const { isOpen, color, text, direction } = state

  return (
    <LoaderContext.Provider value={contextValue}>
      <Loader open={isOpen} color={color} text={text} direction={direction} />
      {props.children}
    </LoaderContext.Provider>
  )
}

export default LoaderProvider
