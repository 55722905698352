import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'
import Activity from 'components/Shared/Activity/Activity'

import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import './style.scss'

interface ChecklistProps {
  className?: string
  step: number
  text: string
  status: 1 | 2
  disabled?: boolean
  loading: boolean
  current: boolean
  previous: boolean
  date?: string
  onComplete?: (step: number) => void
  onIncomplete?: (step: number) => void
  onClarification?: (step: number) => void
}

const Checklist: React.FC<ChecklistProps> = ({
  children,
  className,
  step,
  text,
  loading,
  disabled = false,
  current,
  previous,
  date = '',
  onComplete,
  onIncomplete,
  onClarification,
  ...props
}) => {
  const parsedDate = moment(date)

  return (
    <div className="component-checklist">
      <h4
        className={`step ${className ?? ''} ${current ? 'current' : ''} ${Boolean(date) ? 'success' : ''}`}>
        {Boolean(date) ? <Check /> : step}
      </h4>
      <Grid container className="step-container" alignItems="center">
        <div className="step-labels">
          <p className="step-text">{text}</p>
          {Boolean(date) && (
            <small className="step-date">
              Completado el {parsedDate.format('YYYY-MM-DD')} a las{' '}
              {parsedDate.format('HH:mm')}
            </small>
          )}
        </div>
        <Grid item xs={9} className="step-actions">
          {current && loading && <Activity size={30} local />}
          {current && !loading && (
            <>
              {step !== 1 && (
                <Button
                  startIcon={<ArrowBackRoundedIcon />}
                  variant="link"
                  color="primary"
                  onClick={() => onIncomplete && onIncomplete(step)}>
                  Regresar al paso anterior
                </Button>
              )}
              <Button
                variant="link"
                color="primary"
                disabled={disabled}
                startIcon={<Check />}
                onClick={() => onComplete && onComplete(step)}>
                Completar este paso
              </Button>
              {!_.isNil(onClarification) && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => onClarification(step)}
                  title="De ser necesario, puedes solicitar una aclaración de movimientos al cliente.">
                  Solicitar aclaración
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Checklist
