import { createTheme, ThemeOptions  } from '@material-ui/core/styles';
import { Colors, Variables } from './export';

export const newTheme: ThemeOptions  = {
    typography: {
        fontFamily: [Variables.fontFamily, 'sans-serif'].join(',')        
    },
    palette: {
        primary: {
            light: Colors.colorPrimaryLight,
            main: Colors.colorPrimary,
            dark: Colors.colorPrimaryDark,
            contrastText: Colors.colorWhite
        },
        secondary: {
            light: Colors.colorSecondaryLight,
            main: Colors.colorSecondary,
            dark: Colors.colorSecondaryDark,
            contrastText: Colors.colorPrimaryDarkestS
        },
        success: {
            light: Colors.colorSuccessLight,
            main: Colors.colorSuccess,
            dark: Colors.colorSuccessDark,
            contrastText: Colors.colorWhite
        },
        info: {
            light: Colors.colorInfoLight,
            main: Colors.colorInfo,
            dark: Colors.colorInfoDark,
            contrastText: Colors.colorWhite
        },
        warning: {
            light: Colors.colorWarningLight,
            main: Colors.colorWarning,
            dark: Colors.colorWarningDark,
            contrastText: Colors.colorPrimaryDarkestS
        },
        error: {
            light: Colors.colorDangerLight,
            main: Colors.colorDanger,
            dark: Colors.colorDangerDark,
            contrastText: Colors.colorWhite
        },
    },
    shadows: [
        "none",
        Variables.elevationY1,
        Variables.elevationY2,
        Variables.elevationY3,
        Variables.elevationY4,
        Variables.elevationY5,
        Variables.elevationY6,
        Variables.elevationY7,
        Variables.elevationY8,
        Variables.elevationY9,
        Variables.elevationY10,
        Variables.elevationY11,
        Variables.elevationY12,
        Variables.elevationY13,
        Variables.elevationY14,
        Variables.elevationY15,
        Variables.elevationY16,
        Variables.elevationY17,
        Variables.elevationY18,
        Variables.elevationY19,
        Variables.elevationY20,
        Variables.elevationY21,
        Variables.elevationY22,
        Variables.elevationY23,
        Variables.elevationY24
    ],
    overrides: {
        MuiPaper: {
            root: {
                color: 'inherit',
                backgroundColor: Colors.colorWhite
            },
            rounded: { borderRadius: 8 },
            elevation1:  { boxShadow: Variables.elevationY1  },
            elevation2:  { boxShadow: Variables.elevationY2  },
            elevation3:  { boxShadow: Variables.elevationY3  },
            elevation4:  { boxShadow: Variables.elevationY4  },
            elevation5:  { boxShadow: Variables.elevationY5  },
            elevation6:  { boxShadow: Variables.elevationY6  },
            elevation7:  { boxShadow: Variables.elevationY7  },
            elevation8:  { boxShadow: Variables.elevationY8  },
            elevation9:  { boxShadow: Variables.elevationY9  },
            elevation10: { boxShadow: Variables.elevationY10 },
            elevation11: { boxShadow: Variables.elevationY11 },
            elevation12: { boxShadow: Variables.elevationY12 },
            elevation13: { boxShadow: Variables.elevationY13 },
            elevation14: { boxShadow: Variables.elevationY14 },
            elevation15: { boxShadow: Variables.elevationY15 },
            elevation16: { boxShadow: Variables.elevationY16 },
            elevation17: { boxShadow: Variables.elevationY17 },
            elevation18: { boxShadow: Variables.elevationY18 },
            elevation19: { boxShadow: Variables.elevationY19 },
            elevation20: { boxShadow: Variables.elevationY20 },
            elevation21: { boxShadow: Variables.elevationY21 },
            elevation22: { boxShadow: Variables.elevationY22 },
            elevation23: { boxShadow: Variables.elevationY23 },
            elevation24: { boxShadow: Variables.elevationY24 },
        },
        MuiButton: {
            root: {
                color: Colors.colorAltHighlight,
                borderRadius: 20,
                textTransform: 'none',
                fontSize: Variables.fontSizeButton
            }
        },
        MuiFab: {
            root: {
                color: Colors.colorAltHighlight,
                textTransform: 'none',
                fontSize: Variables.fontSizeButton
            }
        }
    }
};

export const theme = createTheme(newTheme);