import React, { ReactElement, useEffect, useState } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';

import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@material-ui/core/Checkbox';

import './style.scss';
import { FormControl, FormControlProps } from '@material-ui/core';

//#region Types and Interfaces
type ExcludedKeys = "onChange" | "value" | "checked" | "required";
interface CheckboxProps extends Omit<MUICheckboxProps, ExcludedKeys> {
    ioProps: IOInputProps
    label: ReactElement
    setValue: (value: any) => void
    formControlProps?: Omit<FormControlProps, "disabled" | "error" | "required">
    listener?: (value: any) => void
}
//#endregion

const _Checkbox: React.FC<CheckboxProps> = (props) => {
    const {
        ioProps: { name, required, value, defaultValue },
        className = "",
        setValue,
        label,
        formControlProps,
        listener,
        ...rest
    } = props;
    const [ownValue, setOwnValue] = useState(false)

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
            setOwnValue(defaultValue)
        } else {
            setValue(false);
            setOwnValue(false)
        }

    }, [defaultValue, setValue]);

    return (
        <div className={`component-checkbox ${className}`.trim()}>
            <FormControl {...formControlProps}>
                <MUICheckbox
                    id={`checkbox-${name}`}
                    className="checkbox"
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setValue(checked);
                        setOwnValue(checked)
                        listener && listener(checked)
                    }}
                    checked={Boolean(value || ownValue)}
                    required={required}
                    {...rest}
                />
                {label && <p className={`label ${rest.size ?? "medium"}`}>{label}</p>}
            </FormControl>
        </div>
    )
}

const Checkbox = withIO("checkboxes")(_Checkbox);
export default Checkbox;