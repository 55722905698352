import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//import { useIntl } from 'react-intl';
import _ from 'lodash'

import { CardProps } from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Alert from 'components/Shared/Alert/Alert'
import Button from 'components/Shared/Button/Button'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import useEmisoresList, {
  useDeleteEmisor,
  useEmisorFiles,
} from 'hooks/queries/emisores'

import { selectRazonesSocialesList } from 'lib/helpers/selectors'

import { downloadFileFromData } from 'lib/helpers/utilities'

import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import './style.scss'

import IRazonSocial from 'models/razonSocial'
import { File } from 'models/general'
import { WorkspaceRole } from 'models/user'
import { Box } from '@material-ui/core'
import { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import moment from 'moment'

interface Props extends Omit<CardProps, 'role'> {
  workspaceId: number
  role: WorkspaceRole
  onRedirect: (action: string, id?: number) => void
  withHeader?: boolean
}

interface RazonSocial extends Omit<IRazonSocial, 'files'> {
  files: File[]
}

const RazonesSocialesContainer: React.FC<Props> = ({
  workspaceId,
  role,
  onRedirect,
  withHeader,
  className = '',
  ...props
}) => {
  const list = useSelector(selectRazonesSocialesList)
  const multiEmisor = list.length >= 1

  const [emisor, setEmisor] = useState<RazonSocial>()
  const [showPasswordFiel, setShowPasswordFiel] = useState(false)
  const [showPasswordCsd, setShowPasswordCsd] = useState(false)
  const [showCiec, setShowCiec] = useState(false)
  const [openAlert, setOpenAlert] = useState(true)

  // #region Queries
  const listQuery = useEmisoresList(workspaceId, {
    onError: () => onRedirect('error'),
  })

  const filesQuery = useEmisorFiles(
    workspaceId,
    emisor as unknown as IRazonSocial,
    {
      enabled: !_.isNil(emisor),
      refreshOn: [emisor?.idRfc],
      onSuccess: files => !_.isNil(emisor) && setEmisor({ ...emisor, files }),
      onError: () => setEmisor(undefined),
    }
  )
  // #endregion

  // #region Mutations
  const deleteMutation = useDeleteEmisor(workspaceId, {
    onSuccess: () => listQuery.refetch(),
  })
  // #endregion

  const filesFiel = _.filter(emisor?.files, f => f.type.indexOf('Fiel') > -1)
  const filesCsd = _.filter(emisor?.files, f => f.type.indexOf('Csd') > -1)

  useEffect(() => {
    setEmisor(list[0] as RazonSocial)
  }, [list])

  return (
    <div className={`container-razones-sociales-list ${className}`}>
      {withHeader && (
        <div className="title-container">
          <p>Emisor</p>
          <Button
            id="newIssuingCompanyBtn"
            variant="outlined"
            color="warning"
            startIcon={<Add />}
            onClick={() => onRedirect('add')}
            disabled={multiEmisor}>
            Nuevo emisor
          </Button>
        </div>
      )}
      <Collapse in={openAlert}>
        <div className="alert-container">
          <Alert
            variant="filled"
            severity="info"
            title={'¡Actualiza tus datos fiscales!'}
            onClose={() => setOpenAlert(false)}>
            Para usar la <strong>facturación 4.0</strong> es importante que tus
            datos y los datos de tus clientes estén actualizados, estos deben
            coincidir con la información de la constancia de situación fiscal
            actualizada emitida por el SAT.
          </Alert>
        </div>
      </Collapse>

      {listQuery.isLoading && (
        <div className="loading-container">
          <CircularProgress color="primary" size={80} />
        </div>
      )}

      {!listQuery.isLoading && emisor && (
        <Box
          padding={'20px'}
          display={'flex'}
          flexDirection={'column'}
          gridGap={'30px'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}>
            <p>Informacion general</p>
            <div>
              <IconButton
                id={'editButton'}
                size="small"
                color="primary"
                onClick={e => {
                  e.stopPropagation()
                  onRedirect('edit', emisor?.idRfc)
                }}>
                <Edit />
              </IconButton>
              <IconButton
                id={'deleteButton'}
                size="small"
                color="primary"
                onClick={e => {
                  e.stopPropagation()
                  emisor && deleteMutation.mutate(emisor as IRazonSocial)
                }}>
                <Delete />
              </IconButton>
            </div>
          </Box>

          <Box
            display={'grid'}
            gridTemplateColumns={'repeat(2, 1fr)'}
            width={'60%'}>
            <TextField
              name="name"
              label="Tipo"
              fieldType={'text'}
              value={emisor?.type.label}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
            <TextField
              name="name"
              label="Nombre completo"
              fieldType={'text'}
              value={emisor?.name}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
            <TextField
              name="rfc"
              label="RFC"
              fieldType={'text'}
              value={emisor?.rfc}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
            <TextField
              name="rfc"
              label="Regimen Fiscal"
              fieldType={'text'}
              value={emisor?.taxResidence.label}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
            <TextField
              name="rfc"
              label="Código postal"
              fieldType={'text'}
              value={emisor?.zipCode}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
            <TextField
              name="rfc"
              label="Domicilio fiscal (opcional)"
              fieldType={'text'}
              value={`${emisor?.address.zipcodes.municipality} ${emisor?.address.zipcodes.zipcode}, ${emisor?.address.zipcodes.state}`}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ readOnly: true }}
              fullWidth
              exclude
            />
          </Box>
          <Box
            display={'grid'}
            gridTemplateColumns={'repeat(2, 1fr)'}
            gridGap={'40px'}>
            <Box display={'flex'} flexDirection={'column'} gridRowGap={'20px'}>
              <p>Información de la e.Firma (FIEL)</p>

              <Box
                display={'flex'}
                flexDirection={'column'}
                gridRowGap={'20px'}>
                {filesQuery.isLoading && (
                  <div className="loading-container">
                    <CircularProgress color="primary" size={80} />
                    <p>Cargando archivos</p>
                  </div>
                )}
                {!filesQuery.isLoading &&
                  filesFiel.map(file => (
                    <FileCardAlt
                      name={`ARCHIVO.${file.extension.toUpperCase()}`}
                      title={file.fileName}
                      subtitle={moment(file.createdAt).format('DD/MM/YYYY')}
                      type={'File'}
                      actions={[]}
                      className="file-zone"
                      onFileClick={() =>
                        downloadFileFromData(file.fileName, file.base64)
                      }
                    />
                  ))}

                <TextField
                  name="passwordFiel"
                  label="Clave privada de e.Firma (FIEL)"
                  fieldType={showPasswordFiel ? undefined : 'password'}
                  value={emisor?.passwordFiel}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="showPasswordFielBtn"
                          onClick={() => setShowPasswordFiel(ps => !ps)}>
                          {showPasswordFiel ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  exclude
                />
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gridRowGap={'20px'}>
              <p>Información de la CSD</p>

              <Box
                display={'flex'}
                flexDirection={'column'}
                gridRowGap={'20px'}>
                {filesQuery.isLoading && (
                  <div className="loading-container">
                    <CircularProgress color="primary" size={80} />
                    <p>Cargando archivos</p>
                  </div>
                )}

                {!filesQuery.isLoading &&
                  filesCsd.map(file => (
                    <FileCardAlt
                      name={`ARCHIVO.${file.extension.toUpperCase()}`}
                      title={file.fileName}
                      subtitle={moment(file.createdAt).format('DD/MM/YYYY')}
                      type={'File'}
                      actions={[]}
                      className="file-zone"
                      onFileClick={() =>
                        downloadFileFromData(file.fileName, file.base64)
                      }
                    />
                  ))}

                <TextField
                  name="passwordCSD"
                  label="Clave privada de CSD"
                  fieldType={showPasswordCsd ? undefined : 'password'}
                  value={emisor?.passwordCsd}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="showPasswordCsdBtn"
                          onClick={() => setShowPasswordCsd(ps => !ps)}>
                          {showPasswordCsd ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  exclude
                />
                <TextField
                  name="passwordSAT"
                  label="Contraseña de acceso al SAT"
                  fieldType={showCiec ? undefined : 'password'}
                  value={emisor?.ciec}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="showPasswordCiebBtn"
                          onClick={() => setShowCiec(ps => !ps)}>
                          {showCiec ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  exclude
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  )
}

export default RazonesSocialesContainer
