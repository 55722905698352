import React, { ReactElement } from 'react'

import { ExtendedColorPalette } from 'models/general'

import './style.scss'

interface Props {
  text: string
  color?: ExtendedColorPalette
  onClick?: () => void
  href?: string
  icon?: ReactElement
}

const HelperText: React.FC<Props> = ({
  text,
  color = 'primary',
  onClick,
  href,
  icon,
}) => {
  return (
    <div className={`component-helper-text ${color}`} onClick={onClick}>
      {icon}
      {href && (
        <a target="_blank" rel="noreferrer" href={href}>
          {text}
        </a>
      )}
      {!href && <p>{text}</p>}
    </div>
  )
}

export default HelperText
