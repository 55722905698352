import React from 'react'
import { useDidMount, useDidUpdate } from 'rooks'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import AddComplementContainer from 'containers/Invoices/3.3/AddPaymentComplementContainer/AddPaymentComplementContainer'
import AddComplementContainerV2 from 'containers/Invoices/4.0/AddPaymentComplementV2Container/AddPaymentComplementV2Container'

import { resetForm } from 'actions/forms'
import { selectActiveWorkspace } from 'lib/helpers/selectors'

import { doScrollToTop } from 'lib/helpers/utilities'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'

interface Props {}

const AddComplementScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const { invoice } = useParams()
  const [params] = useSearchParams()

  const dispatch = useDispatch()

  const invoiceId = Number(invoice)
  const workspaceId = useSelector(selectActiveWorkspace)
  const version = params.get('version') || '1'

  useDidMount(() => () => {
    doScrollToTop()
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('cancel')
  }, [workspaceId, invoice])

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error') navigate(ROUTES.INVOICES, { replace: true })
    if (action === 'cancel' || action === 'success') {
      dispatch(resetForm('createInvoice'))
      dispatch(resetForm('createPaymentComplement'))
      navigate(ROUTES.INVOICES)
    }
  }

  if (!invoiceId) {
    return <Navigate to={ROUTES.INVOICES} replace />
  }

  if (!workspaceId) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-add-complement">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Nuevo complemento de pago</h1>
            <Button
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          {version === '1' && (
            <AddComplementContainer
              workspaceId={workspaceId}
              invoiceId={invoiceId}
              onRedirect={onRouteChange}
            />
          )}
          {version === '2' && (
            <AddComplementContainerV2
              workspaceId={workspaceId}
              invoiceId={invoiceId}
              onRedirect={onRouteChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default AddComplementScreen
