import ManagementWorkspacesActions from 'components/Management/ManagementWorkspaces/ManagementWorkspacesActions/ManagementWorkspacesActions'
import ManagementWorkspacesContextProvider from 'components/Management/ManagementWorkspaces/ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import ManagementWorkspacesTable from 'components/Management/ManagementWorkspaces/ManagementWorkspacesTable/ManagementWorkspacesTable'
import './styles.scss'
import WorkspacesFiltersForm from 'components/Management/Workspaces/WorkspacesFiltersForm/WorkspacesFiltersForm'

const ManagementWorkspacesContainer = () => {
  

  return (
    <div className="workspaces-container">
      <ManagementWorkspacesContextProvider>
        <WorkspacesFiltersForm />
        <ManagementWorkspacesActions />
        <ManagementWorkspacesTable />
      </ManagementWorkspacesContextProvider>
    </div>
  )
}

export default ManagementWorkspacesContainer
