import { IconButton, IconButtonProps, styled } from "@material-ui/core";

const StyledIconButton = styled(
  (props: IconButtonProps) => (
    <IconButton {...props} />
  ),
)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#98BBDC4D',
  color: theme.palette.primary.main,
  padding: 10,
}));

export default StyledIconButton;