import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import {
  AppBar,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
} from '@material-ui/core'

import Account from '@material-ui/icons/AccountCircle'
import Workspaces from '@material-ui/icons/AssignmentInd'
import Down from '@material-ui/icons/KeyboardArrowDown'
import Notifications from '@material-ui/icons/Notifications'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded'
import {
  IHeaderDownloadOption,
  NotificationType,
  TWorkspaceParams,
} from 'models/general'
import Logo from 'assets/logo-short.svg'
import { ROUTES } from '../../constants'
import {
  selectActiveWorkspace,
  selectCreateWorkspaceFlag,
  selectInvitations,
  selectNotifications,
  selectUser,
  selectWorkspacesList,
} from 'lib/helpers/selectors'
import { searchInCollection } from 'lib/helpers/utilities'
import useDebounce from 'hooks/useDebounce'
import useDownloadCIF from 'hooks/Shared/useDownloadCIF'
import useDownloadComplianceOpinions from 'hooks/Shared/useDownloadComplianceOpinions'
import useWorkspaceList, {
  useAcceptInvitation,
  useRejectInvitation,
  useWorkspaceSelection,
} from 'hooks/queries/workspaces'
import { deleteInvitation } from 'actions/user'
import Notification from 'components/Notifications/Notification/Notification'
import './style.scss'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const onSelectWorkspace = useWorkspaceSelection()

  const user = useSelector(selectUser)
  const activeWorkspaceId = useSelector(selectActiveWorkspace)
  const workspaces = useSelector(selectWorkspacesList)
  const isCreatingWorkspace = useSelector(selectCreateWorkspaceFlag)
  const notifications = useSelector(selectNotifications)
  const invitations = useSelector(selectInvitations)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGElement>(
    null
  )
  const [openWorkspaces, setOpenWorkspaces] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openDownloadDropdown, setOpenDownloadDropdown] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500)

  const filteredWorkspaces = useMemo(
    () =>
      searchInCollection(workspaces, debouncedSearchTerm, ['id', 'name'], 'id'),
    [workspaces, debouncedSearchTerm]
  )
  const activeWorkspaces = useMemo(
    () => filteredWorkspaces.filter(w => w.enabled),
    [filteredWorkspaces]
  )
  const activeWorkspace = useMemo(
    () => workspaces.find(w => w.id === activeWorkspaceId),
    [workspaces, activeWorkspaceId]
  )

  const workspaceListQuery = useWorkspaceList(undefined, { enabled: false })
  const acceptInvitationMutation = useAcceptInvitation({
    onSuccess: (_d, invitation) => {
      dispatch(deleteInvitation(invitation))
      workspaceListQuery.refetch()
    },
  })
  const rejectInvitationMutation = useRejectInvitation({
    onSuccess: (_d, invitation) => {
      dispatch(deleteInvitation(invitation))
      workspaceListQuery.refetch()
    },
  })

  const downloadCIFMutation = useDownloadCIF()

  const { isLoading: isCIFDownloadLoading } = downloadCIFMutation

  const downloadComplianceOpininonMutation = useDownloadComplianceOpinions([
    activeWorkspaceId,
  ])

  const { isLoading: isComplianceDownloadLoading } =
    downloadComplianceOpininonMutation

  const dropdownDownloadOptions: IHeaderDownloadOption[] = [
    {
      id: 'header-download-cif',
      name: 'Descargar CIF',
      onClick: () =>
        downloadCIFMutation.mutate(activeWorkspaceId as TWorkspaceParams),
    },
    {
      id: 'header-download-opinion',
      name: 'Opinión de cumplimiento',
      onClick: () =>
        downloadComplianceOpininonMutation.mutate([
          activeWorkspaceId,
        ] as TWorkspaceParams),
    },
  ]

  const headerDownloadLoadingMap: Record<
    'header-download-cif' | 'header-download-opinion',
    boolean
  > = {
    'header-download-cif': isCIFDownloadLoading,
    'header-download-opinion': isComplianceDownloadLoading,
  }

  const onWorkspaceSelect = (id: number) => {
    onSelectWorkspace(id)
    setAnchorEl(null)
    setOpenWorkspaces(false)
  }

  if (!user.authorized || isCreatingWorkspace) return <></>

  return (
    <>
      <AppBar className="component-header" color="inherit">
        <Toolbar disableGutters>
          <Link to={ROUTES.HOME} className="brand">
            <img src={Logo} alt="Logotipo Simmple" />
          </Link>
          <p className="workspaces-list">
            {intl.formatMessage({ id: 'header.workspace' })}
            <strong>
              {activeWorkspace?.name ||
                intl.formatMessage({ id: 'workspace.current.none' })}
            </strong>
            <IconButton
              id="sbWorkspaceSelectorBtn"
              size="small"
              onClick={e => {
                setAnchorEl(e.currentTarget)
                setOpenWorkspaces(true)
              }}>
              <Down />
            </IconButton>
          </p>
          <div className="downloadDropdown">
            <IconButton
              size="medium"
              onClick={e => {
                setAnchorEl(e.currentTarget)
                setOpenDownloadDropdown(true)
              }}>
              <DownloadForOfflineRoundedIcon
                className="downloadIcon"
                fontSize="large"
                color="primary"
              />
              <Down />
            </IconButton>
            <Divider
              className="divider-toolbar"
              orientation="vertical"
              flexItem
            />
          </div>
          <Link
            id="sbWorkspacesLink"
            to={ROUTES.WORKSPACES}
            className="workspaces-list-link">
            <Tooltip title="Espacios de Trabajo" placement="bottom">
              <Workspaces className="workspace" color="primary" />
            </Tooltip>
          </Link>
          <Link id="sbProfileLink" to={ROUTES.ACCOUNT} className="profile">
            <Tooltip title="Mi Perfil" placement="bottom">
              <Account className="avatar" color="primary" />
            </Tooltip>
            <Divider
              className="divider-toolbar"
              orientation="vertical"
              flexItem
            />
          </Link>
          <Tooltip title="Notificaciones" placement="bottom">
            <Notifications
              color="primary"
              className="notifications"
              onClick={e => {
                setAnchorEl(e.currentTarget)
                setOpenNotifications(true)
              }}
            />
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && openNotifications}
        onClose={() => {
          setAnchorEl(null)
          setOpenNotifications(false)
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <List className="header-notifications-list">
          {notifications.length === 0 && invitations.length === 0 && (
            <ListItem className="notification" alignItems="flex-start">
              <ListItemText primary="No tienes notificaciones." />
            </ListItem>
          )}
          {notifications.length > 0 &&
            notifications.map(({ id, type, message, relatedId, read }) => (
              <Notification
                key={id}
                id={id}
                type={type as NotificationType}
                message={message}
                relatedId={relatedId}
                read={read}
              />
            ))}
          {invitations.length > 0 &&
            invitations.map(n => (
              <Notification
                key={n.id}
                id={n.id}
                type={n.type as NotificationType}
                message={n.message}
                relatedId={n.relatedId}
                read={n.read}
                onAccept={() => acceptInvitationMutation.mutate(n)}
                onReject={() => rejectInvitationMutation.mutate(n)}
              />
            ))}
        </List>
      </Popover>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        className="header-workspaces-list"
        open={Boolean(anchorEl) && openWorkspaces}
        onClose={() => {
          setAnchorEl(null)
          setOpenWorkspaces(false)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ style: { maxHeight: 43.5 + 33 * 10.5 } }}>
        <ListSubheader
          className="search-bar-container"
          onKeyDown={e => e.stopPropagation()}>
          <TextField
            id="wsSearchInMenuTextField"
            variant="outlined"
            autoComplete="off"
            label="Busca tu espacio de trabajo"
            onChange={e => setSearchTerm(e.target.value)}
            onBlur={() => setSearchTerm('')}
            value={searchTerm}
            fullWidth
          />
        </ListSubheader>
        {activeWorkspaces.map(({ id, name }) => (
          <MenuItem
            key={id}
            disabled={activeWorkspaceId === id}
            selected={activeWorkspaceId === id}
            onClick={() => onWorkspaceSelect(id)}>
            {name}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && openDownloadDropdown}
        onClose={() => {
          setAnchorEl(null)
          setOpenDownloadDropdown(false)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ style: { maxHeight: 43.5 + 33 * 10.5 } }}>
        {dropdownDownloadOptions.map(({ id, name, onClick }) => (
          <MenuItem
            key={id}
            disabled={
              headerDownloadLoadingMap[
                id as keyof typeof headerDownloadLoadingMap
              ]
            }
            onClick={onClick}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Header
