import { MenuItem, Snackbar, Typography } from '@material-ui/core'
import { FC, Fragment, useRef, useState } from 'react'
import NoteAdd from '@material-ui/icons/NoteAdd'
import { verifyFiles } from 'components/Conciliations/Dropzone/Dropzone'
import {
  CONCILIATION_ACCEPTED_FORMATS,
  CONCILIATION_MAX_FILESIZE,
} from '../../../constants'
import Alert from '@material-ui/lab/Alert'
import useDialog from 'hooks/Shared/useDialog'

const MenuItemFileInput: FC<{
  onChange: (files: FileList) => void
  acceptedFormats?: string
  onContinuousClick?: () => void
}> = ({
  onChange,
  acceptedFormats = CONCILIATION_ACCEPTED_FORMATS,
  onContinuousClick,
}) => {
  const dialog = useDialog()
  const [error, setError] = useState({
    open: false,
    message: '',
  })

  const inputRef = useRef<HTMLInputElement>(null)

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.files = null
      inputRef.current.value = ''
    }
  }

  const handleFileChange = (files: FileList) => {
    onChange(files)
    clearInput()
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const verify = verifyFiles(event.target.files, {
        accept: acceptedFormats,
        multiple: true,
        maxFiles: 999,
        maxSize: CONCILIATION_MAX_FILESIZE,
      })
      if (verify) {
        await dialog.primaryAsync({
          title: 'Hay un problema',
          subtitle: verify,
          primaryButtonText: 'Continuar',
        })
        inputRef.current!.files = null
        clearInput()
        return setError({
          open: true,
          message: verify,
        })
      }
      handleFileChange(event.target.files)
    }
  }

  const clearError = () =>
    setError({
      open: false,
      message: '',
    })

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
      onContinuousClick && onContinuousClick()
    }
  }

  return (
    <Fragment>
      <MenuItem onClick={handleClick}>
        <NoteAdd />
        <Typography>Cargar Archivo</Typography>
      </MenuItem>
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleChange}
        accept={acceptedFormats}
        multiple
      />
      <Snackbar open={error.open} autoHideDuration={2000} onClose={clearError}>
        <Alert severity="warning">{error.message}</Alert>
      </Snackbar>
    </Fragment>
  )
}

export default MenuItemFileInput
