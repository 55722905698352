import React, { useEffect } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import { DatePicker, DatePickerProps } from '@material-ui/pickers';

import './style.scss';

//#region Types and Interfaces
type ExcludedKeys = "onChange" | "autoComplete" | "error" | "helperText" | "type";
interface TextFieldProps extends Omit<DatePickerProps, ExcludedKeys> { 
    ioProps: IOInputProps
    addon: any
    setValue: (value: any) => void
}
//#endregion

const _DateField: React.FC<TextFieldProps> = (props) => {
    const { 
        ioProps: { name, defaultValue, invalid, message },
        className = "",
        setValue, 
        InputProps,
        addon,
        value,
        ...rest
    } = props;

    useEffect(() => {
        if (!_.isUndefined(defaultValue)) {
            setValue(defaultValue);
        }
    }, [defaultValue, setValue])

    const hasAnError = invalid && !_.isEmpty(message);

    return (
        <div className={`component-date-field ${className} ${addon ? "with-addon" : ""}`.trim()}>
            <DatePicker
                id={`numericField-${name}`}
                autoComplete="off"
                className="text-field"
                helperText={message}
                error={hasAnError}
                value={value || null}
                onChange={(date) => setValue(date)}
                allowKeyboardControl={false}
                format="DD/MM/YYYY"
                clearLabel="Limpiar"
                cancelLabel="Cerrar"
                okLabel="Confirmar"
                {...rest}
            />
            {addon}
        </div>
    )
}

const DateField = withIO("datefield")(_DateField);
export default DateField;