import { UseQueryResult, useQuery } from '@tanstack/react-query'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { IBillingInformation } from 'models/payments'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { IApiError } from 'services/Shared/shared.models'
import { getBillingInformation } from 'services/Payments/payments.services'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useGetBillingInformation = (
  activeWorkspace: number
): UseQueryResult<IBillingInformation, IApiError> => {
  const dialog = useDialog()

  const query = useQuery<IBillingInformation, IApiError>(
    ['getBillingInformation', activeWorkspace],
    () => {
      return getBillingInformation(activeWorkspace)
    },
    {
      refetchOnWindowFocus: false,
      onError: async (error: IApiError) => {
        const op = selectErrorDialog(error)

        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (error.status !== 404) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la información de los planes contratados.',
            icon: ErrorOutlineIcon as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }
      },
    }
  )

  return query
}

export default useGetBillingInformation
