import React, { useRef } from 'react'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import { getBase64FromFile } from 'lib/helpers/utilities'

import Upload from 'assets/upload.svg'
import UploadGreen from 'assets/upload-green.svg'
import UploadDisabled from 'assets/upload-disabled.svg'
import './styles.scss'

import { ContextColors, UploadedFile } from 'models/general'
import { CircularProgress } from '@material-ui/core'

interface FileUploaderProps {
  label: string
  onValue: (fileList: UploadedFile[]) => void
  className?: string
  color?: ContextColors
  accept?: string
  limit?: number
  base64?: boolean
  type?: string
  loading?: boolean
  disabled?: boolean
  documentType?: number
}

const FileUploader: React.FC<FileUploaderProps> = ({
  label,
  onValue,
  className = '',
  color = 'primary',
  accept = '',
  type,
  disabled = false,
  documentType,
  loading = false,
  ...props
}) => {
  const inputFile: any = useRef()

  const extractValue = async (fileList: FileList | null) => {
    if (fileList && fileList.length > 0 && !disabled) {
      const filesPromises = _.map(
        fileList,
        async (file): Promise<UploadedFile> => {
          const base64 = await getBase64FromFile(file)
          const names = _.split(file.name, '.')
          return {
            uuid: uuid(),
            wsId: 0,
            name: _.get(names, '0', ''),
            description: type,
            fullName: file.name,
            mime: file.type,
            extension: _.get(names, '1', ''),
            base64: base64 ? (base64 as string) : '',
            activity: true,
            error: false,
            documentType,
          }
        }
      )
      const awaitedFiles = await Promise.all(filesPromises)
      onValue(_.filter(awaitedFiles, f => Boolean(f.base64)))
    } else {
      inputFile.current.value = null
    }
  }

  const onDrop = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    extractValue(e.dataTransfer.files)
  }

  const onDragOver = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'
  }

  const onClick = () => {
    inputFile.current.value = null
    inputFile.current.click()
  }

  return (
    <>
      <div
        className={`component-file-uploader ${className} ${color} ${disabled ? 'disabled' : ''}`.trim()}
        onClick={() => onClick()}
        onDrop={e => onDrop(e)}
        onDragOver={e => onDragOver(e)}>
        {loading ? (
          <CircularProgress size={18} variant="indeterminate" color="inherit" />
        ) : (
          <img
            src={
              disabled
                ? UploadDisabled
                : color === 'primary'
                  ? Upload
                  : UploadGreen
            }
            alt="subir un archivo"
          />
        )}
        <p>{label}</p>
      </div>
      <input
        ref={inputFile}
        onChange={e => extractValue(e.target.files)}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        multiple
        disabled={disabled}
      />
    </>
  )
}

export default FileUploader
