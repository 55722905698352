import { Button, Slide } from '@material-ui/core'
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined'
import { Dialog, PropTypes } from '@mui/material'
import { TWorkspaceParams } from 'models/general'
import Download from 'assets/download.svg'
import Sync from 'assets/sync.svg'
import MainButton from 'components/Shared/Button/Button'
import MassiveAlertDialog from '../ManagementWorkspacesMassiveAlertDialog/ManagementWorkspacesMassiveAlertDialog'
import GenerateReportDialog from '../ManagementWorkspacesReportDialog/ManagementWorkspacesReportDialog'
import ManagementWorkspacesSyncForm from '../ManagementWorkspacesSyncForm/ManagementWorkspacesSyncForm'
import useWorkspacesActions from './useWorkspacesActions'
import './styles.scss'

interface IWorkSpaceAction {
  icon: JSX.Element
  label: string
  className?: string
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  color?: PropTypes.Color
  disabled?: boolean
  tooltip?: string
  onClick?: () => void
}

const ManagementWorkspaceActions = () => {
  const {
    selectedWorkspaceIds,
    showMassiveAlert,
    showGenerateReport,
    showSyncForm,
    modalError,
    modalContent,
    shouldShow,
    label,
    downloadComplianceOpininons,
    complianceDownloadLoading,
    synchronizeComplianceOpinionsStatus,
    complianceSyncLoading,
    downloadCFDIs,
    downloadCFDIsLoading,
    curriedStateUpdate,
  } = useWorkspacesActions()

  const typedWorkspaces = selectedWorkspaceIds.map(idWorkspace => ({
    idWorkspace,
  })) as TWorkspaceParams

  const actionGroups: IWorkSpaceAction[][] = [
    [
      {
        label: 'Opinión de cumplimiento',
        icon: <img src={Download} alt="download" />,
        onClick: () =>
          downloadComplianceOpininons.mutate(
            selectedWorkspaceIds as TWorkspaceParams
          ),
        disabled: complianceDownloadLoading,
      },
      {
        label: 'Estatus de opinión',
        icon: <img src={Sync} alt="sync" />,
        onClick: () =>
          synchronizeComplianceOpinionsStatus.mutate(typedWorkspaces),
        disabled: complianceSyncLoading,
      },
    ],
    [
      {
        label: 'CFDI',
        icon: <img src={Download} alt="download" />,
        onClick: () => downloadCFDIs.mutate(typedWorkspaces),
        disabled: downloadCFDIsLoading,
        tooltip: 'Descargar CFDI',
      },
      {
        label: 'CFDI',
        icon: <img src={Sync} alt="sync" />,
        onClick: curriedStateUpdate({ showSyncForm: true }),
        tooltip: 'Sincronizar CFDI',
      },
    ],
    [
      {
        label: 'Generar reporte',
        icon: <DifferenceOutlinedIcon />,
        onClick: curriedStateUpdate({ showGenerateReport: true }),
      },
      {
        label: 'Alerta',
        icon: <AddAlertOutlinedIcon />,
        onClick: curriedStateUpdate({ showMassiveAlert: true }),
      },
    ],
    // [
    //   {
    //     label: 'Descargar tabla',
    //     icon: <img src={DownloadGreen} alt="download" />,
    //     className: 'download-table-button',
    //     variant: 'outlined',
    //     color: 'secondary',
    //     size: 'medium',
    //   },
    // ],
  ]

  return (
    <div className="workspaces-actions-component">
      <Slide direction="down" in={shouldShow} mountOnEnter unmountOnExit>
        <article className="workspaces-actions">
          <span className="selected-workspace">{label}</span>
          <div className="actions">
            {actionGroups.map((group, index) => (
              <div key={`group-${index}`} className="action-group">
                {group.map(
                  (
                    {
                      className,
                      icon,
                      label,
                      variant,
                      size,
                      color,
                      disabled,
                      tooltip,
                      onClick,
                    },
                    index
                  ) => (
                    <Button
                      className={className ?? ''}
                      key={index}
                      onClick={onClick}
                      startIcon={icon}
                      variant={variant ?? 'text'}
                      color={color ?? 'primary'}
                      size={size ?? 'small'}
                      disabled={disabled}
                      title={tooltip}>
                      {label}
                    </Button>
                  )
                )}
              </div>
            ))}
          </div>
          <ManagementWorkspacesSyncForm
            isOpen={showSyncForm}
            onClose={curriedStateUpdate({ showSyncForm: false })}
          />
          <GenerateReportDialog
            open={showGenerateReport}
            onClose={curriedStateUpdate({ showGenerateReport: false })}
          />
          <MassiveAlertDialog
            isOpen={showMassiveAlert}
            onClose={curriedStateUpdate({ showMassiveAlert: false })}
          />
        </article>
      </Slide>
      <Dialog
        className="workspaces-actions-error-modal"
        open={modalError}
        onClose={curriedStateUpdate({ modalContent: undefined })}>
        <div className="close-row">
          <button onClick={curriedStateUpdate({ modalError: false })}>
            <CloseIcon />
          </button>
        </div>
        <article className="modal-content">{modalContent}</article>
        <article className="modal-actions">
          <MainButton
            variant="contained"
            onClick={curriedStateUpdate({ modalError: false })}>
            Entendido
          </MainButton>
        </article>
      </Dialog>
    </div>
  )
}

export default ManagementWorkspaceActions
