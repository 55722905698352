import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { TStoreEvents } from 'store/StoreEvent/storeEvent.models'
import { resetStoreEvent } from 'store/StoreEvent/storeEvent.reducer'
import { selectStoreEventType } from 'store/StoreEvent/storeEvent.selectors'

const useStoreEvent = (typeToMatch: TStoreEvents, callback: () => void) => {
  const dispatch = useAppDispatch()

  const eventType = useSelector(selectStoreEventType)

  useEffect(() => {
    if (eventType === typeToMatch) {
      callback()
      dispatch(resetStoreEvent())
    }
  }, [dispatch, typeToMatch, callback, eventType])
}

export default useStoreEvent
