import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import { useCallback, useEffect, useState } from 'react'
import {
  useSaveAnswers,
} from 'hooks/queries/profiling'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import { CircularProgress, Divider, Grid } from '@material-ui/core'
import Button from 'components/Shared/Button/Button'
import { Answer } from 'models/profiling'
import './style.scss'
import Checkbox from 'components/FormComponents/IOComponents/Checkbox/Checkbox'
import RadioGroup from 'components/FormComponents/IOComponents/RadioGroup/RadioGroup'
import DateField from 'components/FormComponents/IOComponents/DateField/DateField'
import moment from 'moment'
import useGetProfilingData from 'hooks/Workspaces/Profiling/useGetProfilingData'

interface ProfilingProps {
  className?: string
  workspaceId: number;
  onRedirect: (action: string) => void;
}

const InputType = {
  TEXT: "text",
  SELECT: "select",
  RADIO: "radio",
  CHECK: "checkbox",
  DATE: "date"
}

const ProfilingContainer: React.FC<ProfilingProps> = ({ workspaceId, onRedirect, className = "" }) => {
  const [valid, setValid] = useState(false);
  const { isError, response,  isLoading, handleProfilingData } = useGetProfilingData();
  const [valuesListened, setValuesListened] = useState<any>(null);
  const [depndecyIds, setDependencyIds] = useState<{id: number, type?: string} []>([]);

  useEffect(() => {
    if(response.length > 0 && valuesListened === null){
      let idsToListen: any = {}
      let depndendencyIds: {id: number, type?: string} [] = [];
        response.forEach((profile) => {
          profile.questions.forEach((question) => {
            if(question.isRequiredBy !== null && question.isRequiredBy !== undefined){
              console.log("ISREDBY: ", question.isRequiredBy );
              
              depndendencyIds.push({id: question.id, type: question.type})
            }

            if(question.needToListen === true && question.type !== InputType.RADIO){
              idsToListen[`${question.id}`] = question.answer
            }else {
              idsToListen[`${question.id}`] = question.answer === "Positiva" ? true : false
            }
          })
        })        
        setValuesListened(idsToListen)
        setDependencyIds(depndendencyIds)
    }
  }, [valuesListened, response])

  useEffect(() => {
    handleProfilingData(workspaceId)
  }, [workspaceId, handleProfilingData])


  const saveAnswersQuery = useSaveAnswers(workspaceId,{
    onSuccess: () => handleProfilingData(workspaceId),
    onError: () => onRedirect("error")
  })

  // #endregion
  const transformAnswersRequest = useCallback((data: any): Answer[] => {    
    depndecyIds.forEach((id) => {
      if(Object.keys(data).find((key) => key === id.id.toString()) === undefined){
        data[id.id] = ""
      }
    })    

    var answers: Answer[] = [];

    Object.entries(data).forEach(([key, value]) => {
      const answer = typeof value === 'boolean' ? value.toString() : value as string;
      answers = [...answers, { question: { id: parseInt(key) }, answer: answer }];
    })
    return answers;
  }, [depndecyIds])

  const handleSubmit = useCallback(async (data) => {
    saveAnswersQuery.mutate(transformAnswersRequest(data));
  }, [saveAnswersQuery, transformAnswersRequest])

  const isRequiredBy = useCallback((idToListen: string, oposite?: boolean) => {
    if(valuesListened !== null && valuesListened[idToListen] === true){
      return oposite ? false : true
    }else {
      return oposite ? true : false
    }
  }, [valuesListened])

  const handleCheckValues = useCallback((value: boolean, elementId: number) => {
    setValuesListened((prevObject: any) => ({
      ...prevObject,
      [elementId]: value
    }))
  }, [])

  const handleRadioValues = useCallback((value: string, elementId: number) => {
    setValuesListened((prevObject: any) => ({
      ...prevObject,
      [elementId]: (value === "Positiva") ? true : false
    }))
  }, [])  

  return (
    <div className={`profiling-container ${className}`}>
      {isLoading &&
        <Grid item xs={12} className="loading-container">
          <CircularProgress color="primary" size={60} />
        </Grid>
      }

      {(isLoading === false && isError === true) && 
        <h3 className="without-data">
          No se pudo obtener las preguntas para el espacio de trabajo seleccionado
        </h3>
      }
      
      {(response.length === 0 && isLoading === false && isError === false) ?
        <h3 className="without-data">
          No existen formularios para el espacio de trabajo seleccionado
        </h3>
          :
        <Form onSubmit={handleSubmit} onValidity={(v: boolean) => setValid(v)}>
          {response.map((form, key) => form.questions.length > 0 && (
            <div className="form-section-container" key={key}>
              <h5 className="form-title">
                {form.name}
              </h5>

              <div className="question-container">
                {form.questions.map((question) => {
                  switch(question.type){
                    case InputType.TEXT:
                      return <TextField
                        name={`${question.id}`}
                        label={question.question}
                        value={question.answer}
                        fullWidth
                        className={question.responseSize && question.responseSize}
                        required={(question.isRequiredBy !== undefined) ?
                          isRequiredBy(question.isRequiredBy, true) 
                        : 
                          question.required
                        }
                        key={question.id}
                      />
                    case InputType.SELECT:
                      return <AutocompleteField
                        name={`${question.id}`}
                        label={question.question}
                        catalogue={question.options?.split("|")}
                        getOptionLabel={(option: string) => option}
                        value={question.answer}
                        fullWidth
                        className={question.responseSize && question.responseSize}
                        required={(question.isRequiredBy) ? 
                          isRequiredBy(question.isRequiredBy)
                        : 
                          question.required
                        }
                        key={question.id}
                      />
                    case InputType.CHECK:
                      return <Checkbox
                        name={`${question.id}`}
                        label={question.question}
                        defaultValue={question.answer && question.answer === "true" ? true : false}
                        size="small"
                        className={`${question.responseSize && question.responseSize} flex-column`}
                        key={question.id}
                        listener={question.needToListen === true ? 
                          (value: any) => handleCheckValues(value, question.id) 
                        : 
                          undefined
                        }
                      />
                    case InputType.RADIO:
                      return <RadioGroup
                        name={`${question.id}`}
                        label={question.question}
                        className={question.responseSize && question.responseSize}
                        defaultValue={question.answer}
                        radioOptions={question.options?.split("|").map((value, index) => ({
                          id: value,
                          label: value,
                          labelPlacement: "end"
                        }))}
                        formControlProps={{ fullWidth: true }}
                        disabled={question.options?.split("|").length === 0}
                        row
                        required={question.required}
                        key={question.id}
                        listener={question.needToListen === true ? 
                          (value: any) => handleRadioValues(value, question.id) 
                        : 
                          undefined
                        }
                      />
                    case InputType.DATE:
                      return <DateField
                        name={`${question.id}`}
                        label={question.question}
                        value={question.answer}
                        filterIn={(v: any) => v ? moment(v, "YYYY-MM-DD") : v}
                        filterOut={(v: any) => v?.format("YYYY-MM-DD")}
                        clearable
                        fullWidth
                        autoOk
                        key={question.id}
                      />
                    default:
                      return <></>
                  }
                })}
              </div>
            </div>
          ))}
          
          <Divider />
          
          <div className="actions">
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              activity={saveAnswersQuery.isLoading}
              disabled={!valid}
              className='component-button'
            >
              Guardar
            </Button>
          </div>
        </Form>
      }
    </div>
  );
};

export default ProfilingContainer;