import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
//import _ from 'lodash';

import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Table from 'components/Shared/DynamicTable/DynamicTable'
//import Button from 'components/Shared/Button/Button';
//import TextField from 'components/IOComponents/TextField';
//import SelectField from 'components/IOComponents/SelectField';

import useProductsList, { useDeleteProduct } from 'hooks/queries/products'

import { selectProductsList } from 'lib/helpers/selectors'

import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import './style.scss'

import Category from 'models/category'
import { CatalogueEntry } from 'models/catalogues'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const ProductsContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const intl = useIntl()

  const list = useSelector(selectProductsList)
  // const unidades = useSelector(selectCatalogue("unidades"));

  // #region Queries
  const listQuery = useProductsList(workspaceId, {
    onError: () => onRedirect('error'),
  })
  // #endregion

  // #region Mutations
  const deleteMutation = useDeleteProduct(workspaceId, {
    onSuccess: () => listQuery.refetch(),
  })
  // #endregion

  // #region Table Formatters
  const formatCatalogue = (v: CatalogueEntry): string => v.label || '- - -'
  const formatCategory = (v?: Category): string => v?.name || '- - -'
  const formatMoney = (v: number): string => {
    const formatted = intl.formatNumber(v, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatActions = (id: number, row: any, key: any) => (
    <>
      <IconButton
        id={`editProductBtn_${key}`}
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          onRedirect('edit-ps', row.idItem)
        }}>
        <Edit fontSize="inherit" />
      </IconButton>
      <IconButton
        id={`deleteProductBtn_${key}`}
        size="small"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          deleteMutation.mutate(row)
        }}>
        <Delete fontSize="inherit" />
      </IconButton>
    </>
  )
  // #endregion

  // #region Table Headers
  const headers = [
    { label: 'ID', accesor: 'internalId' },
    { label: 'Nombre', accesor: 'name' },
    { label: 'Unidades', accesor: 'claveUnitSat', renderer: formatCatalogue },
    {
      label: 'Tipo de Producto',
      accesor: 'claveSat',
      renderer: formatCatalogue,
    },
    { label: 'Precio Unitario', accesor: 'price', renderer: formatMoney },
    { label: 'Categoría', accesor: 'categories', renderer: formatCategory },
    {
      label: '',
      accesor: 'idItem',
      renderer: formatActions,
      cellClassName: 'actions',
    },
  ]
  // #endregion

  return (
    <>
      {/* <div className="container-products-filter">
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <TextField
                        name="search"
                        label={"Buscar"}
                        placeholder={"Busca por ID, nombre o código"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectField 
                        name="search"
                        label={"Filtro 2"}
                        options={[]}
                        value={""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectField 
                        name="search"
                        label={"Filtro 3"}
                        options={[]}
                        value={""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {}}
                    >
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </div>
         */}
      <Card elevation={1} className="container-products-crud">
        <Table
          headers={headers}
          rows={list}
          loading={listQuery.isLoading}
          fetching={listQuery.isFetching}
        />
      </Card>
    </>
  )
}

export default ProductsContainer
