import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import ClientsContainer from 'containers/Clients/ClientsContainer/ClientsContainer'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Add from '@material-ui/icons/Add'
import './style.scss'

interface Props {}

const ClientsScreen: React.FC<Props> = props => {
  const navigate = useNavigate()

  const workspaceId = useSelector(selectActiveWorkspace)

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'back') navigate(-1)
    if (action === 'add') navigate(ROUTES.ADD_CLIENT)
    if (action === 'edit') navigate(`${ROUTES.CLIENT_DETAILS}?id=${id}`)
    if (action === 'error') navigate(ROUTES.HOME, { replace: true })
  }

  return (
    <>
      <Grid container spacing={0} className="screen-clients">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Clientes</h1>
            <Button
              id="newClientBtn"
              variant="outlined"
              color="warning"
              startIcon={<Add />}
              onClick={() => onRouteChange('add')}>
              Nuevo cliente
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <ClientsContainer
            workspaceId={workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ClientsScreen
