import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useThunkDispatch } from 'models/thunk'
import Logo from 'assets/logo.svg'
import useDialog from 'hooks/Shared/useDialog'
import { getProfile, getRole } from 'actions/account'
import { login, setAuthorization } from 'actions/user'
import { getWorkspaceList } from 'actions/workspaces'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import './style.scss'

type LoginValues = {
  email: string
  password: string
}

interface LoginFormProps {
  onClickRegister: () => void
  onClickForgetPassword: () => void
}

const LoginFormContainer: React.FC<LoginFormProps> = ({
  onClickForgetPassword,
  onClickRegister,
}) => {
  const intl = useIntl()
  const dialog = useDialog()
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(false)

  const onSubmit = async (v: LoginValues) => {
    setLoading(true)
    try {
      await thunkDispatch(login(v.email, v.password))
      await thunkDispatch(getRole())
      await thunkDispatch(getProfile())
      await thunkDispatch(getWorkspaceList())
      dispatch(setAuthorization(true))
    } catch (err) {
      console.log(err)
      dialog.danger({
        subtitle: 'login.error.message',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container-login-form">
      <a
        className="section logo"
        href="https://www.simmple.mx/"
        target="_blank"
        rel="noopener noreferrer">
        <img src={Logo} alt="Logo" />
      </a>
      <div className="section form">
        <h3>{intl.formatMessage({ id: 'login.title' })}</h3>
        <Form onSubmit={onSubmit} lock={loading} onValidity={setValid}>
          <TextField
            name="email"
            validate={['isEmail', 'isEmailFormat']}
            label={intl.formatMessage({ id: 'form.email' })}
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <TextField
            name="password"
            label={intl.formatMessage({ id: 'form.password' })}
            fieldType="password"
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <Button
            id="loginBtn"
            color="secondary"
            type="submit"
            variant="contained"
            fullWidth
            disabled={!valid}
            activity={loading}>
            {intl.formatMessage({ id: 'button.login' })}
          </Button>
          <Button
            id="recoverBtn"
            color="primary"
            onClick={onClickForgetPassword}
            fullWidth
            disabled={loading}>
            {intl.formatMessage({ id: 'login.button.recover' })}
          </Button>
        </Form>
      </div>
      <div className="section no-account">
        <p>{intl.formatMessage({ id: 'login.no_account.title' })}</p>
        <Button
          id="registerBtn"
          color="secondary"
          variant="link"
          onClick={onClickRegister}>
          {intl.formatMessage({ id: 'login.no_account.link' })}
        </Button>
      </div>
    </div>
  )
}

export default LoginFormContainer
