export const API_RESPONSE_ERROR_CODE_KEYS = {
  401: 'error401',
  1001: 'error1001',
  1002: 'error1002',
  1003: 'error1003',
  2001: 'error2001',
  3002: 'error3002',
  9000: 'error9000',
} as const

export const SERVER_SENT_OPERATIONS = {
  COMPLIANCE: 1,
  CIF: 2,
  CFDIS: 3,
} as const

export const CONTROLLED_ERROR_CODES = {
  NET_002: 'NET_002',
  DB_003: 'DB_003',
} as const

export const STORE_EVENT_TYPES = {
  FETCH_MANAGEMENT_WORKSPACES: 'FETCH_MANAGEMENT_WORKSPACES',
} as const
export enum WorkspaceSections {
  INFORMATION = 'information',
  PAYMENTS = 'payments',
  SUBSCRIPTION = 'subscription',
  FORMS = 'forms',
  EMISORES = 'emisores',
  COLLABORATORS = 'collaborators',
  COMMENTS = 'comments',
  NOTIFICATIONS = 'notifications',
}
