import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { UploadedFile } from 'models/general'
import { UseStateSimpleCallbackModel } from 'models/shared.models'
import {
  IAccountingProcess,
  IAccountingChecklist,
  IGetAccountingRemindersResponseService,
  TAccountingProcessSteps,
} from 'models/accounting.models'
import ISydDocument from 'models/shared.models'
import RequestedDocument from 'models/documentRequest'
import { ROUTES } from '../../../constants'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import useLoader from 'hooks/Shared/useLoader'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import useGetAccounting from 'hooks/Accounting/useGetAccounting'
import useGetAccountingIncompleteProcesses from 'hooks/Accounting/useGetAccountingIncompleteProcesses'
import useGetAccountingTasks from 'hooks/Accounting/useGetAccountingTasks'
import useHandleAccountingDocuments from 'hooks/Accounting/useHandleAccountingDocuments'
import useHandleAccountingReminders from 'hooks/Accounting/useHandleaccountingReminders'
import useHandleAccountingSteps from 'hooks/Accounting/useHandleAccountingSteps'
import useUpdateAccountingRequestedDocuments from 'hooks/Accounting/useUpdateAccountingRequestedDocuments'
import useUpdateAccountingTask from 'hooks/Accounting/useUpdateAccountingTask'
import {
  setActiveDateRange,
  setRefetchAccountingProcess,
  toggleAccountingTaskStatus,
} from 'store/Accounting/accounting.reducer'
import {
  selectAccountingActiveDateRange,
  selectAccountingForm,
  selectAccountingTasks,
  selectCurrentAccountingProcess,
  selectRefetchAccountingProcess,
} from 'store/Accounting/accounting.selectors'
import { setPath } from 'actions/documentation'
import { IAccountingIncompleteProcess } from 'services/Accounting/accounting.models'
import { LoadingContextProps } from 'providers/LoaderProvider/LoaderProvider'

export interface IAccountingLocalState {
  accountingReminderEmailSent: string
  isSendAccountingReminderLoading: boolean
  showAccountingReminderSuccess: boolean
  isSendAccountingReminderError: boolean
  accountingHistoryReminders: UseStateSimpleCallbackModel<IGetAccountingRemindersResponseService>
  isAccountingTasksDrawerOpen: boolean
  updateAccountingProcessLoading: boolean
  stepChangeLoading: boolean
  isAccountingIncompleteProcessesDrawerOpen: boolean
}

export interface IUseAccounting extends IAccountingLocalState {
  loader: LoadingContextProps
  activeDateRange: Date
  activeStep: number
  incompleteProcesses: boolean
  isAllAccountingTasksComplete: boolean
  filesToUpload: UploadedFile[]
  filesInProcess: ISydDocument[]
  requiredFileList: RequestedDocument[]
  checklist: IAccountingChecklist[]
  stepThreeComments: {
    text: string
    type: number
  }[]
  currentAccountingProcess?: IAccountingProcess
  accountingIncompleteProcesses?: IAccountingIncompleteProcess[]
  goToDocumentation: () => void
  handleChangeDateRange: (date: Date) => void
  goToStep: (step: TAccountingProcessSteps) => Promise<void>
  handleStepBack: () => void
  handleStepSubmit: (
    nextStep: TAccountingProcessSteps,
    comments?: string,
    uploadedFiles?: ISydDocument[],
    invalidFiles?: ISydDocument[],
    commentType?: number
  ) => Promise<void>
  handleToggleAccountingTasksDrawer: () => void
  handleToggleAccountingTaskStatus: (id: number) => void
  handleToggleAccountingIncompleteProcessesDrawer: () => void
  handleFileUpload: (files: UploadedFile[]) => void
  handleFileRetryUpload: (file: UploadedFile) => void
  handleFileDelete: (f: UploadedFile | ISydDocument, noReload?: boolean) => void
  handleFileRename: (name: string, f: UploadedFile | ISydDocument) => void
  handleFileValidation: (
    f: ISydDocument,
    v?: string,
    refresh?: boolean
  ) => Promise<void>
  handleFileDownload: (d: ISydDocument) => void
  handleEditRequiredFileList: () => void
  handleShowAccountingReminders: (param: boolean) => void
  handleSendAccountingReminders: () => void
  handleProcessCheck: (c: IAccountingChecklist, value: number) => void
}

const useAccounting = (): IUseAccounting => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loader = useLoader()

  const workspaceId = useSelector(selectActiveWorkspace)
  const activeDateRange = useSelector(selectAccountingActiveDateRange)
  const accountingForm = useSelector(selectAccountingForm)
  const accountingTasks = useSelector(selectAccountingTasks)
  const shouldRefetchAccountingProcess = useSelector(
    selectRefetchAccountingProcess
  )
  const currentAccountingProcess = useSelector(selectCurrentAccountingProcess)

  const filesToUpload = accountingForm.files
  const checklist = currentAccountingProcess?.checklist ?? []
  const stepThreeComments = currentAccountingProcess?.commentsList ?? []
  const filesInProcess = currentAccountingProcess?.uploadedDocuments ?? []

  const [accountingState, setAccountingState] = useState<IAccountingLocalState>(
    {
      accountingReminderEmailSent: '',
      isSendAccountingReminderLoading: false,
      showAccountingReminderSuccess: false,
      isSendAccountingReminderError: false,
      accountingHistoryReminders: {
        isLoading: false,
        isError: false,
      },
      isAccountingTasksDrawerOpen: false,
      updateAccountingProcessLoading: false,
      stepChangeLoading: false,
      isAccountingIncompleteProcessesDrawerOpen: false,
    }
  )

  const updateAccountingState =
    useUpdateStateFunction<IAccountingLocalState>(setAccountingState)

  const isAllAccountingTasksComplete = accountingTasks?.every(t => t.checked)

  const { isLoading: mainLoading, refetch: getAccounting } = useGetAccounting(
    workspaceId,
    new Date(activeDateRange),
    currentAccountingProcess?.id
  )

  useGetAccountingTasks(workspaceId, new Date(activeDateRange))

  useUpdateAccountingRequestedDocuments({
    workspaceId,
    currentAccountingProcess,
    list: [4, 17, 19, 18, 20, 21],
    enabled: currentAccountingProcess?.requiredDocuments?.length === 0,
    getAccounting,
  })

  const { incompleteProcesses, query: getAccountingIncompleteProcessesQuery } =
    useGetAccountingIncompleteProcesses(workspaceId)

  const {
    isLoading: accountingIncompleteProcessesLoading,
    data: accountingIncompleteProcesses,
  } = getAccountingIncompleteProcessesQuery

  const toggleAccountingTaskStatusMutation =
    useUpdateAccountingTask(workspaceId)

  const {
    handleGetAccountingReminders,
    handleSendAccountingReminders,
    handleShowAccountingReminders,
  } = useHandleAccountingReminders({
    workspaceId,
    accountingReduxId: currentAccountingProcess?.id,
    updateAccountingState,
  })

  const {
    requiredFileList,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDelete,
    handleFileRename,
    handleFileValidation,
    handleFileDownload,
    handleEditRequiredFileList,
  } = useHandleAccountingDocuments({
    workspaceId,
    currentAccountingProcess,
    getAccounting,
  })

  const {
    activeStep,
    goToStep,
    handleStepBack,
    handleStepSubmit,
    handleProcessCheck,
  } = useHandleAccountingSteps({
    workspaceId,
    currentAccountingProcess,
    updateAccountingState,
    getAccounting,
    handleFileDelete,
    handleFileValidation,
  })

  const goToDocumentation = () => {
    if (!currentAccountingProcess) return
    const year = `${currentAccountingProcess.year}`
    const month = `${moment(currentAccountingProcess.month, 'M').format('MMMM').charAt(0).toUpperCase() + moment(currentAccountingProcess.month, 'M').format('MMMM').slice(1)}`
    dispatch(
      setPath([
        { id: 0, label: 'Documentos', goTo: '' },
        { id: 1, label: 'Contabilidad', goTo: 'Contabilidad' },
        { id: 2, label: year, goTo: year },
        { id: 3, label: month, goTo: month },
      ])
    )
    navigate(ROUTES.DOCUMENTATION)
  }

  const handleChangeDateRange = useCallback(
    (date: Date) => dispatch(setActiveDateRange(date)),
    [dispatch]
  )

  const handleToggleAccountingTasksDrawer = () => {
    updateAccountingState(prevState => ({
      ...prevState,
      isAccountingTasksDrawerOpen: !prevState.isAccountingTasksDrawerOpen,
    }))
  }

  const handleToggleAccountingTaskStatus = (id: number) => {
    const taskToUpdate = accountingTasks.find(t => t.idTask === id)

    if (!taskToUpdate) return

    dispatch(toggleAccountingTaskStatus(id))

    toggleAccountingTaskStatusMutation.mutate({
      ...taskToUpdate,
      checked: !taskToUpdate.checked,
    })
  }

  const handleToggleAccountingIncompleteProcessesDrawer = () => {
    updateAccountingState(prevState => ({
      ...prevState,
      isAccountingIncompleteProcessesDrawerOpen:
        !prevState.isAccountingIncompleteProcessesDrawerOpen,
    }))
  }

  useEffect(() => {
    mainLoading || accountingIncompleteProcessesLoading
      ? loader.primary('Cargando información')
      : loader.close()
  }, [loader, mainLoading, accountingIncompleteProcessesLoading])

  useEffect(() => {
    handleGetAccountingReminders()
  }, [handleGetAccountingReminders])

  useEffect(() => {
    if (shouldRefetchAccountingProcess) {
      getAccounting()
      dispatch(setRefetchAccountingProcess(false))
    }
  }, [dispatch, shouldRefetchAccountingProcess, getAccounting])

  return {
    ...accountingState,
    loader,
    activeDateRange,
    activeStep,
    incompleteProcesses,
    isAllAccountingTasksComplete,
    filesToUpload,
    filesInProcess,
    requiredFileList,
    checklist,
    stepThreeComments,
    currentAccountingProcess,
    accountingIncompleteProcesses,
    goToDocumentation,
    handleChangeDateRange,
    goToStep,
    handleStepBack,
    handleStepSubmit,
    handleToggleAccountingTasksDrawer,
    handleToggleAccountingTaskStatus,
    handleToggleAccountingIncompleteProcessesDrawer,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDelete,
    handleFileRename,
    handleFileValidation,
    handleFileDownload,
    handleEditRequiredFileList,
    handleShowAccountingReminders,
    handleSendAccountingReminders,
    handleProcessCheck,
  }
}

export default useAccounting
