import { FC, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Box, Button, Typography } from '@material-ui/core'
import DocumentIcon from '@material-ui/icons/Description'
import { ConciliationFile } from 'models/conciliations'
import { OCRBankStatement } from 'models/redux'
import { OCR_DRAWER_MODES } from '../../../constants'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import useGetCFDIs from 'hooks/useGetCFDIs'
import useLoader from 'hooks/Shared/useLoader'
import useQueryLedgerAccounts from 'hooks/useQueryLedgerAccounts'
import {
  deleteArchive,
  deleteCFDIDocument,
  deleteLedgerArchive,
  getCFDIOCR,
  processLedgerOCR,
} from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import DeleteButton from '../FileListDrawerDeleteButton/FileListDrawerDeleteButton'
import ReplaceButton from '../FileListDrawerReplaceButton/FileListDrawerReplaceButton'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import './styles.scss'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const Document: FC<{ document: ConciliationFile }> = ({ document }) => {
  const [currentDocument, setCurrentDocument] =
    useState<ConciliationFile>(document)
  const { original_name } = currentDocument
  return (
    <Box className="document-component">
      <Box className="name">
        <DocumentIcon fontSize="large" />
        <Typography className="filename">{original_name}</Typography>
      </Box>
      <ReplaceButton
        document={currentDocument}
        setCurrentDocument={setCurrentDocument}
      />
      <DeleteButton document={currentDocument} />
    </Box>
  )
}

const parseOCRResponse = (data: any) => {
  const statements = _.map(data.files, (file: any) => {
    return _.omit(file, ['transactions'])
  })
  const transactions = _.flatMap(data.files, (file: any) => {
    return _.map(file.transactions, (transaction: any) => transaction)
  })
  return { statements, transactions }
}

const FileListDrawerContent = () => {
  const {
    workspaceInfo,
    screen: { handleSetTab },
    OCR: { setOCRMovements, setOCRStatements, processOCR },
    drawer,
    closeDrawer,
  } = useConciliationsContainerContext()
  const { filelist } = drawer
  const dispatch = useDispatch()
  const loader = useLoader()
  const dialog = useDialog()
  const { refetch: refetchCFDIs } = useGetCFDIs()
  const { refetch: refetchLedgerAccounts } = useQueryLedgerAccounts()

  const title = useMemo(() => {
    if (!filelist) {
      return 'Archivos cargados'
    }
    if (filelist.documents?.length) {
      if (filelist.documents.length === 1) {
        return 'Se cargó un archivo correctamente'
      }
      return `Se cargaron ${filelist.documents.length} archivos correctamente`
    }
    return 'No se ha subido nada'
  }, [filelist])

  const handleStatementsOCR = async () => {
    if (!filelist) throw new Error('No se ha subido nada')
    try {
      closeDrawer()
      loader.info('Procesando archivos...')
      const data = await processOCR(filelist.conciliation as number)
      loader.close()
      const { transactions, statements } = parseOCRResponse(data)
      dispatch(setOCRMovements(transactions))
      dispatch(
        setOCRStatements(
          _.map(statements, (statement: OCRBankStatement) => {
            return {
              ...statement,
              conciliationId: filelist.conciliation,
            }
          })
        )
      )
      handleSetTab(CONCILIATION_TABS.OCR_PROCESS)
    } catch (error: any) {
      await dialog.danger({
        title:
          error && error.status === 400
            ? 'Archivo con formato incorrecto'
            : 'Error al cargar archivo',
        subtitle:
          error && error.status === 400
            ? 'No se puede continuar con el proceso de conciliación debido a que el archivo cargado no cumple con el formato requerido, te pedimos revisar el formato y reintentar este paso'
            : 'Hubo un error al procesar el archivo, te pedimos reintentar en otro momento',
        icon: ErrorOutline as TModalIcon,
        primaryButtonText: 'Entendido',
        onPrimaryClick: () => loader.close(),
      })
    }
  }

  const handleLedgerOCR = async () => {
    loader.info(
      filelist?.documents?.length === 1
        ? 'Procesando archivo...'
        : 'Procesando archivos...'
    )
    closeDrawer()
    await processLedgerOCR(workspaceInfo.activeWorkspace)
    await refetchLedgerAccounts()
    loader.close()
  }

  // TODO: reemplazar

  const handleCFDI = async () => {
    closeDrawer()
    loader.primary('Procesando CFDIs...')
    try {
      await getCFDIOCR(workspaceInfo.activeWorkspace)
      await refetchCFDIs()
      loader.close()
    } catch (error: any) {
      loader.close()
      if (error.status === 400) {
        return await dialog.primaryAsync({
          subtitle: 'El CFDI que intentas procesar ya existe',
          title: 'No se pudo procesar',
          primaryButtonText: 'Aceptar',
        })
      }
      return await dialog.dangerAsync({
        subtitle: 'No se pudo procesar el CFDI',
        title: 'generic.error.title',
        primaryButtonText: 'Aceptar',
      })
    }
  }

  const handleContinue = async () => {
    try {
      if (!filelist) throw new Error('No se ha subido nada')
      if (filelist.ocrMode === OCR_DRAWER_MODES.statements)
        handleStatementsOCR()
      if (filelist.ocrMode === OCR_DRAWER_MODES.ledger) handleLedgerOCR()
      if (filelist.ocrMode === OCR_DRAWER_MODES.CFDIs) handleCFDI()
    } catch (error: any) {
      const errorDialogConfig = selectErrorDialog(error)
      if (errorDialogConfig && errorDialogConfig.variant) {
        await dialog[errorDialogConfig.variant](errorDialogConfig)
        return
      }
      await dialog.dangerAsync({
        subtitle: 'No se pudieron procesar los archivos. Inténtalo mas tarde.',
        title: 'generic.error.title',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const handleDeleteArchives = async () => {
    if (filelist && filelist.documents) {
      await filelist.documents.forEach(async document => {
        try {
          if (filelist.ocrMode === OCR_DRAWER_MODES.statements)
            return await deleteArchive(
              workspaceInfo.activeWorkspace,
              document.idWsFile
            )
          if (filelist.ocrMode === OCR_DRAWER_MODES.ledger)
            return await deleteLedgerArchive(
              workspaceInfo.activeWorkspace,
              document.idWsFile
            )
          if (filelist.ocrMode === OCR_DRAWER_MODES.CFDIs)
            return await deleteCFDIDocument(
              workspaceInfo.activeWorkspace,
              document.idWsFile
            )
        } catch (error: any) {
          console.log('error while deleting file list')
        }
      })
    }
  }

  const handleCancel = async () => {
    const mustDelete = await dialog.primaryAsync({
      subtitle:
        'Al cancelar, los archivos subidos se descartan y tendrás que volver a subirlos.',
      title: '¿Desea cancelar?',
      primaryButtonText: 'Continuar y eliminar',
      secondaryButtonText: 'Volver',
    })
    if (mustDelete) {
      handleDeleteArchives()
      closeDrawer()
    }
  }

  if (!filelist || (filelist.documents && !filelist.documents.length)) {
    return <Box>No se ha subido nada</Box>
  }
  return (
    <Box className="filelist-component">
      <Typography className="title">{title}</Typography>
      <Box className="documents-list">
        {_.map(filelist.documents, document => (
          <Document document={document} key={document.id} />
        ))}
      </Box>
      <Box className="actions">
        <Button
          variant="outlined"
          className="cancel-button"
          onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          disableElevation
          className="continue-button"
          onClick={handleContinue}>
          Continuar
        </Button>
      </Box>
    </Box>
  )
}

export default FileListDrawerContent
