import React, { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Analytics from 'react-ga';

interface Props { }

const RouteChangeTracker: React.FC<Props> = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT !== "development") {
            Analytics.set({ page: location.pathname });
            Analytics.pageview(location.pathname);
        }
    }, [location]);

    return <Fragment />;
};

export default RouteChangeTracker;