import { ToastOptions } from 'react-toastify'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Button } from '@mui/material'
import { CSSProperties } from 'react'

const basicToastOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

const basicToastStyleOptions: CSSProperties = {
  padding: '8px 16px',
}

export const successToastOptions: ToastOptions = {
  ...basicToastOptions,
  theme: 'dark',
  icon(props) {
    return <DoneIcon htmlColor="#95D357" />
  },
  closeButton: () => {
    return (
      <Button className="toast-success-close" variant="text" size="small">
        OK
      </Button>
    )
  },
  style: {
    ...basicToastStyleOptions,
    backgroundColor: '#415364',
  },
}

export const errorToastOptions: ToastOptions = {
  ...basicToastOptions,
  theme: 'light',
  icon(props) {
    return <ErrorOutlineIcon htmlColor="#E36767" />
  },
  closeButton: () => {
    return (
      <Button className="toast-error-close" variant="text" size="small">
        OK
      </Button>
    )
  },
  style: {
    ...basicToastStyleOptions,
    border: '1px solid #E36767',
    backgroundColor: '#FEF4F4',
  },
}
