import { TAccountingCommentUserType } from 'models/accounting.models'
import './style.scss'

export interface AccountingCommentProps {
  isSender: boolean
  message: string
  userType: TAccountingCommentUserType
}

const AccountingComment = (props: AccountingCommentProps) => {
  const { isSender, message, userType } = props

  return (
    <div
      className={`accounting-comment ${isSender === true ? 'sender' : 'not-sender'}`}>
      {userType && (
        <span className="accounting-comment-emisor">{userType}</span>
      )}
      <span>{message}</span>
    </div>
  )
}

export default AccountingComment
