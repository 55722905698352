import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Activity from 'components/Shared/Activity/Activity'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import { useCategory, useUpdateCategory } from 'hooks/queries/categories'

import { setSelectedCategory } from 'actions/categories'
import { selectSelectedCategory } from 'lib/helpers/selectors'

import './style.scss'

import { CategoryForm } from 'models/category'

interface Props {
  id: number
  workspaceId: number
  onRedirect: (action: string) => void
}

const EditCategoryContainer: React.FC<Props> = ({
  id,
  workspaceId,
  onRedirect,
  ...props
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()

  const category = useSelector(selectSelectedCategory) || undefined

  const [valid, setValid] = useState(false)

  const categoryQuery = useCategory(workspaceId, id, {
    onError: () => onRedirect('error'),
  })

  const updateMutation = useUpdateCategory(workspaceId, category, {
    onSuccess: () => onRedirect('success'),
  })

  useEffect(() => {
    categoryQuery.isLoading
      ? loader.primary('Cargando información de la categoría')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryQuery.isLoading])
  useEffect(
    () => () => {
      dispatch(setSelectedCategory())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Card elevation={1} className="container-edit-category">
        <Form
          onSubmit={async (form: CategoryForm) => updateMutation.mutate(form)}
          onValidity={(v: boolean) => setValid(v)}>
          <div className="description">
            <p>
              Puedes editar la siguiente información para actualizar esta
              categoría
            </p>
            {categoryQuery.isFetching && <Activity size={22} local />}
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  label="Nombre"
                  value={category?.name}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={8}>
                <p className="form-label">Descripción</p>
                <TextField
                  variant="outlined"
                  name="description"
                  inputProps={{ maxLength: 500 }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  withCounter
                  value={category?.description}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="updateCategoryBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={updateMutation.isLoading}
              disabled={!valid}>
              Actualizar
            </Button>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default EditCategoryContainer
