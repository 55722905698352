import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { TWorkspaceParams } from 'models/general'
import { successToastOptions, errorToastOptions } from 'lib/utils/toast.utils'
import useCreateCFDIComplianceDownloadMutation from 'hooks/Shared/useCreateCFDIComplianceDownloadMutation'
import useDownloadComplianceOpinions from 'hooks/Shared/useDownloadComplianceOpinions'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import { downloadMultiWorkspaceCFDIs } from 'services/Management/management.services'
import { useManagementWorkspacesContext } from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import {
  IApiError,
  IWorkspaceRequest,
} from 'services/Shared/shared.models'
import { synchronizeComplianceOpinionsStatusService } from 'services/Shared/shared.services'

interface IWorkspaceActionsState {
  showMassiveAlert: boolean
  showGenerateReport: boolean
  showSyncForm: boolean
  modalError: boolean
  modalContent?: JSX.Element
}

interface IUseWorkspacesActions extends IWorkspaceActionsState {
  selectedWorkspaceIds: number[]
  shouldShow: boolean
  label: string
  selectedWorkspacesWithRFC: IWorkspaceRequest[]
  downloadComplianceOpininons: UseMutationResult<
    string,
    IApiError,
    TWorkspaceParams
  >
  complianceDownloadLoading: boolean
  synchronizeComplianceOpinionsStatus: UseMutationResult<
    void,
    unknown,
    TWorkspaceParams
  >
  complianceSyncLoading: boolean
  downloadCFDIs: UseMutationResult<string, IApiError, TWorkspaceParams>
  downloadCFDIsLoading: boolean
  curriedStateUpdate: (update: Partial<IWorkspaceActionsState>) => () => void
}

const useWorkspacesActions = (): IUseWorkspacesActions => {
  const { selectedWorkspaceIds, list } = useManagementWorkspacesContext()

  const [workspaceActionsState, setWorkSpaceActionsState] =
    useState<IWorkspaceActionsState>({
      showMassiveAlert: false,
      showGenerateReport: false,
      showSyncForm: false,
      modalError: false,
    })

  const updateState = useUpdateStateFunction<IWorkspaceActionsState>(
    setWorkSpaceActionsState
  )

  const curriedStateUpdate = (update: Partial<IWorkspaceActionsState>) => () =>
    updateState(update)

  const shouldShow = selectedWorkspaceIds.length > 0

  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const label = useMemo(() => {
    const count = selectedWorkspaceIds.length
    const suffix = count > 1 ? 's' : ''
    return `${count} seleccionado${suffix}`
  }, [selectedWorkspaceIds])

  const selectedWorkspacesWithRFC: IWorkspaceRequest[] = useMemo(() => {
    return list
      .filter(({ idWorkspace }) =>
        selectedWorkspaceIds.some(
          selectedWorkspaceId => selectedWorkspaceId === idWorkspace
        )
      )
      .map(({ idWorkspace, rfc }) => ({ idWorkspace, rfc }))
  }, [selectedWorkspaceIds, list])

  const downloadComplianceOpininons =
    useDownloadComplianceOpinions(selectedWorkspaceIds)

  const { isLoading: complianceDownloadLoading } = downloadComplianceOpininons

  const synchronizeComplianceOpinionsStatus = useMutation<
    void,
    IApiError,
    TWorkspaceParams
  >(
    async workspaces =>
      await synchronizeComplianceOpinionsStatusService(workspaces),
    {
      onMutate: () => {
        toast.loading(
          selectedWorkspaceIds.length > 1
            ? 'Sincronizando estatus de opiniones de cumplimiento'
            : `Sincronizando estatus de opinión de cumplimiento`,
          {
            ...loadingToastOptions,
            toastId: 'loading',
          }
        )
      },
      onSuccess: () => {
        toast.dismiss('loading')
        toast.success(
          'Se ha iniciado la sincronización, le notificaremos cuando termine',
          successToastOptions
        )
      },
      onError: () => {
        toast.dismiss('loading')
        toast.error(
          selectedWorkspaceIds.length > 1
            ? 'Hubo un error al sincronizar el estatus de las opiniones de cumplimiento'
            : `Hubo un error al sincronizar el estatus de la opinión de cumplimiento`,
          errorToastOptions
        )
      },
    }
  )

  const { isLoading: complianceSyncLoading } =
    synchronizeComplianceOpinionsStatus

  const downloadCFDIs = useCreateCFDIComplianceDownloadMutation({
    loadingToastOptions,
    errorToastOptions,
    service: downloadMultiWorkspaceCFDIs,
    fileName: 'CFDIs',
    loadingMessage: 'Preparando descarga',
    errorMessage:
      selectedWorkspaceIds.length > 1
        ? 'No fue posible descargar los CFDIs'
        : 'No fue posible descargar el CFDI',
  })

  const { isLoading: downloadCFDIsLoading } = downloadCFDIs

  return {
    ...workspaceActionsState,
    selectedWorkspaceIds,
    shouldShow,
    label,
    selectedWorkspacesWithRFC,
    downloadComplianceOpininons,
    complianceDownloadLoading,
    synchronizeComplianceOpinionsStatus,
    complianceSyncLoading,
    downloadCFDIs,
    downloadCFDIsLoading,
    curriedStateUpdate,
  }
}

export default useWorkspacesActions
