import { Box, CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import './styles.scss';

const LoadingOverlay: FC<{
  isLoading: boolean,
}> = ({ isLoading }) => {

  if (!isLoading) return null;
  return (
    <Box
      className="loading-overlay-component"
    >
      <CircularProgress variant="indeterminate" color="secondary" />
    </Box>
  );
};

export default LoadingOverlay;