import React, { useState } from 'react';

import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Card, { CardProps } from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import './style.scss';

export interface DropdownProps extends Omit<AccordionProps, "square"|"elevation"> {
    title: string
    className?: string
    bordered?: boolean
    headerBackgroundColor?: "primary"|"secondary"|"success"|"info"|"warning"|"danger"|"white"|"black"|"gray"
    contentBackgroundColor?: "primary"|"secondary"|"success"|"info"|"warning"|"danger"|"white"|"black"|"gray"
    tag?: "h1"|"h2"|"h3"|"h4"|"h5"|"h6"|"p"
    icon?: any
    capitalize?: boolean
    withDivider?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
    title,
    className = "",
    headerBackgroundColor,
    contentBackgroundColor,
    tag = "p",
    icon = ExpandMore,
    bordered = false,
    capitalize = false,
    withDivider = false,
    ...props
}) => {
    const Tag = tag as keyof JSX.IntrinsicElements;
    const Icon = icon;

    const generalClassName = `${className} ${bordered ? "bordered" : ""}`;
    const headerClassName = `${headerBackgroundColor ? `header-${headerBackgroundColor}` : ""}`;
    const contentClassName = `${contentBackgroundColor ? `background-${contentBackgroundColor}` : ""}`;
    const tagClassName = `${capitalize ? "capitalize" : ""}`;
    
    return (
        <Accordion {...props} elevation={0} square className={`component-dropdown ${generalClassName}`.trim()}>
            <AccordionSummary className={`accordion-header ${headerClassName}`.trim()} expandIcon={<Icon />}>
                <Tag className={`accordion-title ${tagClassName}`.trim()}>{title}</Tag>
            </AccordionSummary>
            {withDivider && <Divider />}
            <AccordionDetails className={`accordion-content ${contentClassName}`.trim()}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    )
}

export default Dropdown;

export interface DropdownCardProps extends CardProps {
    title: string
    className?: string
    headerBackgroundColor?: "primary"|"secondary"|"success"|"info"|"warning"|"danger"|"white"|"black"|"gray"
    contentBackgroundColor?: "primary"|"secondary"|"success"|"info"|"warning"|"danger"|"white"|"black"|"gray"
    tag?: "h1"|"h2"|"h3"|"h4"|"h5"|"h6"|"p"
    iconAlign?: "left"|"right"
    capitalize?: boolean
    defaultExpanded?: boolean
}

export const DropdownCard: React.FC<DropdownCardProps> = ({
    title,
    className = "",
    headerBackgroundColor,
    contentBackgroundColor,
    tag = "p",
    capitalize = false,
    defaultExpanded = false,
    iconAlign = "right",
    children,
    ...props
}) => {
    const [open, setOpen] = useState(defaultExpanded);

    const Tag = tag as keyof JSX.IntrinsicElements;

    const generalClassName = `${className}`;
    const headerClassName = `${headerBackgroundColor ? `header-${headerBackgroundColor}` : ""}`;
    const contentClassName = `${contentBackgroundColor ? `background-${contentBackgroundColor}` : ""}`;
    const tagClassName = `${capitalize ? "capitalize" : ""}`;

    return (
        <Card
            {...props}
            className={`component-dropdown-card ${generalClassName}`.trim()}
        >
            <CardContent className={`card-header ${headerClassName}`.trim()}>
                {iconAlign === "left" &&
                    <IconButton className="card-icon left" onClick={() => setOpen(!open)} size="small">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>   
                }
                <Tag className={`card-title ${tagClassName}`.trim()}>{title}</Tag>
                <span className="divisor" />
                {iconAlign === "right" &&
                    <IconButton className="card-icon" onClick={() => setOpen(!open)} size="small">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>   
                }
            </CardContent>
            <Collapse in={open}>
                <CardContent className={`card-content ${contentClassName}`.trim()}>
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    )
}