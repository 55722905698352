import update from 'immutability-helper'
import {
  SET_INVOICES_LIST,
  SET_SELECTED_INVOICE,
  SET_SELECTED_INVOICE_LIST,
  ADD_SELECTED_INVOICE_LIST_VALUE,
  DELETE_SELECTED_INVOICE_LIST_VALUE,
  SET_SELECTED_EMISOR,
  SET_PAGINATION_VALUE,
  SET_CREATE_FORM_VALUE,
  ADD_CONCEPT,
  DELETE_CONCEPT,
  SET_CONCEPT_VALUE,
  ADD_TAX,
  DELETE_TAX,
  RESET_CREATE_FORM,
  SET_FILTER_VALUE,
  RESET_INVOICES_FILTERS,
  SET_CREATE_PAYMENT_COMPLEMENT_FORM_VALUE,
  RESET_CREATE_PAYMENT_COMPLEMENT_FORM,
  SET_CREATE_CREDIT_NOTE_FORM_VALUE,
  ADD_CREDIT_NOTE_CONCEPT,
  SET_CREDIT_NOTE_CONCEPT_VALUE,
  DELETE_CREDIT_NOTE_CONCEPT,
  ADD_CREDIT_NOTE_TAX,
  DELETE_CREDIT_NOTE_TAX,
  RESET_CREATE_CREDIT_NOTE_FORM,
  SET_SYNC_VALUE,
  SET_SYNC_FORM_VALUE,
  RESET_SYNC_VALUES,
  SET_FILTER,
  SET_HEADER_VALUE,
  RESET_HEADERS,
} from 'actions/invoices'
import _ from 'lodash'

/** interfaces */
import { InvoicesStore } from 'models/redux'
import { ReduxAction } from 'models/thunk'
import { RESET_APP_STATE } from 'store/shared.actions'

const initialInvoices: InvoicesStore = {
  filter: {
    type: null,
    status: null,
    startDate: null,
    endDate: null,
    receptor: '',
    search: '',
    advance: false,
  },
  headers: {
    createdAt: true,
    hour: false,
    serie: true,
    uuid: false,
    clients: true,
    emisor: false,
    subtotal: false,
    taxes: false,
    total: true,
    movementType: true,
    status: true,
    statusPayment: true,
    sent: true,
  },
  form: {
    tipo: null,
    emisor: null,
    receptor: null,
    conceptos: [],
    moneda: null,
    tipoCambio: '',
    metodoPago: null,
    formaPago: null,
    usoCfdi: null,
    condiciones: '',
    version: '',
    serie: null,
    folio: '',
  },
  paymentComplementForm: {
    fechaPago: '',
    formaPago: null,
    moneda: null,
    tipoCambio: '',
    monto: '0.0',
    numeroOperacion: '',
    rfcOrigen: '',
    rfcBeneficiario: '',
    nombreBanco: '',
    cuentaBeneficiario: '',
    cuentaOrdenante: '',
    tipoCadenaPago: null,
    certificadoPago: '',
    cadenaPago: '',
    selloPago: '',
    serie: '',
    folio: '',
    metodoPago: null,
    numeroParcialidad: '',
    importeSaldoAnterior: '',
    importePagado: '',
  },
  creditNoteForm: {
    idCreditNote: 0,
    usoCfdi: null,
    moneda: null,
    metodoPago: null,
    formaPago: null,
    condiciones: '',
    conceptos: [],
    tipoRelacion: null,
    exchangeRate: 0,
    uuid: '',
    serie: '',
    folio: '',
  },
  list: [],
  pagination: {
    page: 1,
    size: 15,
    total: 0,
  },
  active: 0,
  selected: null,
  selectedList: [],
  sync: {
    form: {},
    createdAt: null,
    nextAt: null,
    active: false,
  },
}

const defaultAction: ReduxAction<any> = {
  type: '',
  value: '',
}

const invoice = (state = initialInvoices, action = defaultAction) => {
  switch (action.type) {
    // #region Invoices List functionality
    case SET_INVOICES_LIST:
      return update(state, { list: { $set: action.value } })
    case SET_SELECTED_INVOICE:
      return update(state, { selected: { $set: action.value } })
    case SET_SELECTED_EMISOR:
      return update(state, { selectedEmisor: { $set: action.value } })
    case SET_SELECTED_INVOICE_LIST:
      return update(state, { selectedList: { $set: action.value } })
    case ADD_SELECTED_INVOICE_LIST_VALUE:
      return update(state, { selectedList: { $push: [action.value] } })
    case DELETE_SELECTED_INVOICE_LIST_VALUE: {
      console.log(action.value)
      const index = _.findIndex(
        state.selectedList,
        c => c.uuid === action.value.uuid
      )
      if (index === -1) return state
      return update(state, { selectedList: { $splice: [[index, 1]] } })
    }
    case SET_PAGINATION_VALUE: {
      return update(state, {
        pagination: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    }
    // #endregion

    // #region Create Invoice Form
    case SET_CREATE_FORM_VALUE: {
      return update(state, {
        form: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    }
    case ADD_CONCEPT: {
      return update(state, {
        form: {
          conceptos: { $push: [action.value] },
        },
      })
    }
    case DELETE_CONCEPT: {
      const index = _.findIndex(
        state.form.conceptos,
        c => c.uuid === action.value
      )
      if (index === -1) return state
      return update(state, { form: { conceptos: { $splice: [[index, 1]] } } })
    }
    case SET_CONCEPT_VALUE: {
      const index = _.findIndex(
        state.form.conceptos,
        c => c.uuid === action.value.id
      )
      if (index === -1) return state
      return update(state, {
        form: {
          conceptos: {
            [index]: {
              [action.value.name]: { $set: action.value.value },
            },
          },
        },
      })
    }
    case ADD_TAX: {
      const conceptIndex = _.findIndex(
        state.form.conceptos,
        c => c.uuid === action.value.conceptId
      )
      if (conceptIndex === -1) return state
      return update(state, {
        form: {
          conceptos: {
            [conceptIndex]: {
              impuestos: { $push: [action.value.tax] },
            },
          },
        },
      })
    }
    case DELETE_TAX: {
      const conceptIndex = _.findIndex(
        state.form.conceptos,
        c => c.uuid === action.value.conceptId
      )
      if (conceptIndex === -1) return state
      const taxIndex = _.findIndex(
        state.form.conceptos[conceptIndex].impuestos,
        t => t.uuid === action.value.taxId
      )
      if (taxIndex === -1) return state
      return update(state, {
        form: {
          conceptos: {
            [conceptIndex]: {
              impuestos: { $splice: [[taxIndex, 1]] },
            },
          },
        },
      })
    }
    case RESET_CREATE_FORM:
      return update(state, { form: { $set: initialInvoices.form } })
    // #endregion

    // #region Create Payment Complement Form
    case SET_CREATE_PAYMENT_COMPLEMENT_FORM_VALUE:
      return update(state, {
        paymentComplementForm: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    case RESET_CREATE_PAYMENT_COMPLEMENT_FORM:
      return update(state, {
        paymentComplementForm: { $set: initialInvoices.paymentComplementForm },
      })
    // #endregion

    // #region Create credit note Form
    case SET_CREATE_CREDIT_NOTE_FORM_VALUE:
      return update(state, {
        creditNoteForm: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    case ADD_CREDIT_NOTE_CONCEPT: {
      return update(state, {
        creditNoteForm: {
          conceptos: { $push: [action.value] },
        },
      })
    }
    case SET_CREDIT_NOTE_CONCEPT_VALUE: {
      const index = _.findIndex(
        state.creditNoteForm.conceptos,
        c => c.uuid === action.value.id
      )
      if (index === -1) return state
      return update(state, {
        creditNoteForm: {
          conceptos: {
            [index]: {
              [action.value.name]: { $set: action.value.value },
            },
          },
        },
      })
    }
    case DELETE_CREDIT_NOTE_CONCEPT: {
      const index = _.findIndex(
        state.creditNoteForm.conceptos,
        c => c.uuid === action.value
      )
      if (index === -1) return state
      return update(state, {
        creditNoteForm: {
          conceptos: { $splice: [[index, 1]] },
        },
      })
    }
    case ADD_CREDIT_NOTE_TAX: {
      const conceptIndex = _.findIndex(
        state.creditNoteForm.conceptos,
        c => c.uuid === action.value.conceptId
      )
      if (conceptIndex === -1) return state
      return update(state, {
        creditNoteForm: {
          conceptos: {
            [conceptIndex]: {
              impuestos: { $push: [action.value.tax] },
            },
          },
        },
      })
    }
    case DELETE_CREDIT_NOTE_TAX: {
      const conceptIndex = _.findIndex(
        state.creditNoteForm.conceptos,
        c => c.uuid === action.value.conceptId
      )
      if (conceptIndex === -1) return state
      const taxIndex = _.findIndex(
        state.creditNoteForm.conceptos[conceptIndex].impuestos,
        t => t.uuid === action.value.taxId
      )
      if (taxIndex === -1) return state
      return update(state, {
        creditNoteForm: {
          conceptos: {
            [conceptIndex]: {
              impuestos: { $splice: [[taxIndex, 1]] },
            },
          },
        },
      })
    }
    case RESET_CREATE_CREDIT_NOTE_FORM:
      return update(state, {
        creditNoteForm: { $set: initialInvoices.creditNoteForm },
      })
    // #endregion

    // #region Filters Form
    case SET_FILTER:
      return update(state, {
        filter: {
          $merge: {
            type: action.value?.type ?? null,
            status: action.value?.status ?? null,
            startDate: action.value?.startDate ?? null,
            endDate: action.value?.endDate ?? null,
            receptor: action.value?.receptor ?? null,
          },
        },
      })
    case SET_FILTER_VALUE:
      return update(state, {
        filter: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    case RESET_INVOICES_FILTERS:
      return update(state, {
        filter: {
          $merge: {
            ...initialInvoices.filter,
            advance: state.filter.advance,
          },
        },
      });
    // #endregion

    // #region Selected Headers
    case SET_HEADER_VALUE:
      return update(state, {
        headers: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    case RESET_HEADERS:
      return update(state, {
        headers: { $set: initialInvoices.headers },
      })
    // #endregion

    // #region Sync Form and Functionality
    case SET_SYNC_VALUE:
      return update(state, {
        sync: {
          [action.value.key]: { $set: action.value.value },
        },
      })
    case SET_SYNC_FORM_VALUE:
      return update(state, {
        sync: {
          form: {
            [action.value.key]: { $set: action.value.value },
          },
        },
      })
    case RESET_SYNC_VALUES:
      return update(state, { sync: { $set: initialInvoices.sync } })
    // #endregion
    case RESET_APP_STATE:
      return update(state, {
        filter: {
          $set: initialInvoices.filter,
        },
        headers: { $set: initialInvoices.headers },
      })
    default:
      return state
  }
}

export default invoice
