import { UseMutationResult, useMutation } from '@tanstack/react-query'
import useDialog from 'hooks/Shared/useDialog'
import { IApiError } from 'services/Shared/shared.models'
import { requestPlanUpdate } from 'services/Payments/payments.services'
import { IUsePaymentsContainer } from 'containers/Account/Payments/PaymentsContainer/usePaymentsContainer'

type TUseRequestPLanUpdateProps = Pick<
  IUsePaymentsContainer,
  'methods' | 'onClosePlanUpdateModalHandler' | 'submitPlanUpdateRequest'
>

const useRequestPlanUpdate = ({
  methods: { watch },
  onClosePlanUpdateModalHandler,
  submitPlanUpdateRequest,
}: TUseRequestPLanUpdateProps): UseMutationResult<void, IApiError, string> => {
  const dialog = useDialog()

  const mutation = useMutation<void, IApiError, string>(
    (message: string) => requestPlanUpdate(message),
    {
      onSuccess: () => {
        onClosePlanUpdateModalHandler()
        dialog.primary({
          title: 'Mensaje enviado',
          subtitle:
            'Revisaremos tu solicitud y nos pondremos en contacto contigo para revisar tu cambio de plan.',
        })
      },
      onError: () => {
        dialog.danger({
          title: 'Algo falló',
          subtitle:
            'No fue posible enviar tu mensaje, por favor intenta de nuevo.',
          primaryButtonText: 'Reintentar ahora',
          onPrimaryClick: () => {
            setTimeout(() => {
              submitPlanUpdateRequest({ message: watch('message') })
            }, 300)
          },
          secondaryButtonText: 'Cancelar',
          onSecondaryClick: onClosePlanUpdateModalHandler,
        })
      },
    }
  )

  return mutation
}

export default useRequestPlanUpdate
