import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'
import ISydDocument from 'models/shared.models'
import { UploadedFile } from 'models/general'
import { IAccountingProcess } from 'models/accounting.models'
import RequestedDocument from 'models/documentRequest'
import { useThunkDispatch } from 'models/thunk'
import { FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE, ROUTES } from '../../constants'
import { downloadFileFromData } from 'lib/helpers/utilities'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import useDialog from 'hooks/Shared/useDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'
import {
  uploadFileToProcess,
  validateFileInProcess,
} from 'store/Accounting/accounting.actions'
import {
  addFormFile,
  deleteFormFile,
  editFormFile,
  editUploadedDocument,
} from 'store/Accounting/accounting.reducer'
import {
  deleteArchive,
  getDocumentationFileById,
  renameArchive,
} from 'actions/documentation'
import {
  setAccountingId,
  setInitialList,
  setMonth,
  setOrigin,
  setYear,
} from 'actions/documentRequest'
import { IApiError } from 'services/Shared/shared.models'
import { IUseAccounting } from 'containers/Accounting/AccountingContainer/useAccounting'

interface IUseHandleAccountingDocumentsProps {
  workspaceId: number
  currentAccountingProcess?: IAccountingProcess
  getAccounting: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IAccountingProcess, IApiError>>
}

type TUseHandleAccountingDocuments = Pick<
  IUseAccounting,
  | 'requiredFileList'
  | 'handleFileUpload'
  | 'handleFileRetryUpload'
  | 'handleFileDelete'
  | 'handleFileRename'
  | 'handleFileValidation'
  | 'handleFileDownload'
  | 'handleEditRequiredFileList'
>

const useHandleAccountingDocuments = ({
  workspaceId,
  currentAccountingProcess,
  getAccounting,
}: IUseHandleAccountingDocumentsProps): TUseHandleAccountingDocuments => {
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()
  const navigate = useNavigate()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const dialog = useDialog()

  const requiredFileList = useMemo(() => {
    if (!currentAccountingProcess) return []
    const docs = currentAccountingProcess.requiredDocuments
      .map(rd =>
        FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE.find(de => de.id === rd.id)
      )
      .filter(d => !!d)
    return (docs as RequestedDocument[]).filter(d => !!d)
  }, [currentAccountingProcess])

  const handleFileUpload = async (files: UploadedFile[]) => {
    if (!currentAccountingProcess) return
    const fileUploader = files.map(async file => {
      try {
        const proxyFile = { ...file, base64: '' }
        dispatch(addFormFile(proxyFile))
        await thunkDispatch(
          uploadFileToProcess(workspaceId, currentAccountingProcess, file)
        )
      } catch (error: any) {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          dialog[op.variant](op)
          return
        }

        dispatch(
          editFormFile({
            id: file.uuid,
            propertyKey: 'base64',
            propertyValue: file.base64,
          })
        )
        dispatch(
          editFormFile({
            id: file.uuid,
            propertyKey: 'error',
            propertyValue: true,
          })
        )
        dispatch(
          editFormFile({
            id: file.uuid,
            propertyKey: 'activity',
            propertyValue: false,
          })
        )
      }
    })
    await Promise.all(fileUploader)
    await getAccounting()
    files.forEach(file => dispatch(deleteFormFile(file.uuid)))
  }

  const handleFileRetryUpload = async (file: UploadedFile) => {
    if (!currentAccountingProcess) return
    try {
      dispatch(
        editFormFile({
          id: file.uuid,
          propertyKey: 'activity',
          propertyValue: true,
        })
      )
      dispatch(
        editFormFile({
          id: file.uuid,
          propertyKey: 'error',
          propertyValue: false,
        })
      )
      await thunkDispatch(
        uploadFileToProcess(workspaceId, currentAccountingProcess, file)
      )
      await getAccounting()
      dispatch(deleteFormFile(file.uuid))
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      dispatch(
        editFormFile({
          id: file.uuid,
          propertyKey: 'error',
          propertyValue: true,
        })
      )
      dispatch(
        editFormFile({
          id: file.uuid,
          propertyKey: 'activity',
          propertyValue: false,
        })
      )
    }
  }

  const handleFileDelete = async (
    f: UploadedFile | ISydDocument,
    noReload: boolean = false
  ) => {
    if (!currentAccountingProcess) return
    try {
      const fileAsUploadedFile = f as UploadedFile
      if (fileAsUploadedFile.uuid) {
        const { uuid } = fileAsUploadedFile
        dispatch(
          editFormFile({
            id: uuid,
            propertyKey: 'activity',
            propertyValue: true,
          })
        )
        dispatch(deleteFormFile(uuid))
        dispatch(
          editFormFile({
            id: uuid,
            propertyKey: 'activity',
            propertyValue: false,
          })
        )
      } else {
        const fileAsDocument = f as ISydDocument
        const { idWsfile } = fileAsDocument
        dispatch(
          editUploadedDocument({
            id: idWsfile,
            propertyKey: 'activity',
            propertyValue: true,
          })
        )
        await thunkDispatch(deleteArchive(workspaceId, fileAsDocument))
        !noReload && (await getAccounting())
        dispatch(
          editUploadedDocument({
            id: idWsfile,
            propertyKey: 'activity',
            propertyValue: false,
          })
        )
      }
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.danger({
        subtitle:
          'No se pudo realizar la operación solicitada. Intenta nuevamente',
        title: 'generic.error.title',
      })
    }
  }

  const handleFileRename = async (
    name: string,
    f: UploadedFile | ISydDocument
  ) => {
    if (!currentAccountingProcess) return
    try {
      const fileAsUploadedFile = f as UploadedFile
      if (fileAsUploadedFile.uuid) {
        const { uuid } = fileAsUploadedFile
        dispatch(
          editFormFile({
            id: uuid,
            propertyKey: 'activity',
            propertyValue: true,
          })
        )
        dispatch(
          editFormFile({
            id: uuid,
            propertyKey: 'fullName',
            propertyValue: `${name}.${fileAsUploadedFile.extension}`,
          })
        )
        dispatch(
          editFormFile({ id: uuid, propertyKey: 'name', propertyValue: name })
        )
        dispatch(
          editFormFile({
            id: uuid,
            propertyKey: 'activity',
            propertyValue: false,
          })
        )
      } else {
        const fileAsDocument = f as ISydDocument
        const { idWsfile } = fileAsDocument
        dispatch(
          editUploadedDocument({
            id: idWsfile,
            propertyKey: 'activity',
            propertyValue: true,
          })
        )
        await thunkDispatch(renameArchive(workspaceId, fileAsDocument, name))
        await getAccounting()
        dispatch(
          editUploadedDocument({
            id: idWsfile,
            propertyKey: 'activity',
            propertyValue: false,
          })
        )
      }
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      dialog.danger({
        title: 'generic.error.title',
        subtitle:
          'No se pudo realizar la operación solicitada. Intenta nuevamente.',
      })
    }
  }

  const handleFileValidation = async (
    f: ISydDocument,
    v?: string,
    refresh?: boolean
  ) => {
    if (!currentAccountingProcess || !f?.relatedId) return
    try {
      dispatch(
        editUploadedDocument({
          id: f.idWsfile,
          propertyKey: 'activity',
          propertyValue: true,
        })
      )
      await thunkDispatch(
        validateFileInProcess(
          workspaceId,
          currentAccountingProcess.id,
          f.relatedId,
          v
        )
      )
      dispatch(
        editUploadedDocument({
          id: f.idWsfile,
          propertyKey: 'statusText',
          propertyValue: v,
        })
      )
      dispatch(
        editUploadedDocument({
          id: f.idWsfile,
          propertyKey: 'activity',
          propertyValue: false,
        })
      )
      refresh && (await getAccounting())
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      dispatch(
        editUploadedDocument({
          id: f.idWsfile,
          propertyKey: 'activity',
          propertyValue: false,
        })
      )
    }
  }

  const handleFileDownload = async (d: ISydDocument) => {
    try {
      showAdditionalInformationLoading()
      const file = await thunkDispatch(
        getDocumentationFileById({ workspaceId, fileId: d.idWsfile })
      )

      dialog.close()
      downloadFileFromData(`${file.name}.${file.extension}`, file?.file ?? '')
    } catch (error: any) {
      setTimeout(async () => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        dialog.danger({
          title: 'generic.error.title',
          subtitle:
            'No se pudo descargar el archivo seleccionado. Intenta nuevamente.',
        })
      }, 600)
    }
  }

  const handleEditRequiredFileList = () => {
    if (!currentAccountingProcess) return

    dispatch(setOrigin('accounting'))
    dispatch(setAccountingId(currentAccountingProcess.id))
    dispatch(setMonth(Number(currentAccountingProcess.month)))
    dispatch(setYear(Number(currentAccountingProcess.year)))
    dispatch(
      setInitialList(
        currentAccountingProcess.requiredDocuments.map(rd => rd.id)
      )
    )
    navigate(ROUTES.DOCUMENT_REQUEST)
  }

  return {
    requiredFileList,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDelete,
    handleFileRename,
    handleFileValidation,
    handleFileDownload,
    handleEditRequiredFileList,
  }
}

export default useHandleAccountingDocuments
