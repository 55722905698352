import _ from 'lodash';
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient';

import Catalogue from 'models/catalogues';
import { TaxCatalogue } from 'models/invoice';

const route = process.env.REACT_APP_APIURL || "/api/";

export const getTaxCatalogue = async (satCat: Catalogue): Promise<TaxCatalogue> => {
    const client = HTTPClient.getClient();
    try {
        const resp = await client.get(`${route}/taxes/tasa`);
        const data = await resp.json();
        const catalogue: TaxCatalogue = _.map(data, (d) => {
            const id = Number(d["id:"]);
            let tax = null;
            if (id === 4) {
                tax = {
                    id: id,
                    label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
                    tr: d["Label"],
                    type: d["Type"],
                    factor: d["Factor"],
                    value: [0.10, 0.0125],
                    sat: (_.filter(satCat, (c) => c.label === d["Type"]))[0]
                }
            } else if (id === 5) {
                tax = {
                    id: id,
                    label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
                    tr: d["Label"],
                    type: d["Type"],
                    factor: d["Factor"],
                    value: [0.04, 0.106667, 0.16],
                    sat: (_.filter(satCat, (c) => c.label === d["Type"]))[0]
                }
            } else {
                tax = {
                    id: id,
                    label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
                    tr: d["Label"],
                    type: d["Type"],
                    factor: d["Factor"],
                    value: d["value"] || { min: d["Min value"], max: d["Max value"] },
                    sat: (_.filter(satCat, (c) => c.label === d["Type"]))[0]
                }
            }
            return tax;
        });
        return catalogue;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}