import { useMutation } from '@tanstack/react-query'
import {
  UploadArchiveToPeriodResponse,
  uploadArchiveToPeriod,
} from 'services/conciliations'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { ParsedArchive, QueryOptions } from 'models/general'
import ErrorI from '@material-ui/icons/ErrorOutline'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

export const useUploadAccountMovementsFiles = (
  workspaceId: number,
  period: string,
  options: QueryOptions<
    string,
    UploadArchiveToPeriodResponse,
    ParsedArchive[]
  > = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noDialog = false,
    refreshOn = [],
  } = options

  const query = useMutation<
    UploadArchiveToPeriodResponse,
    unknown,
    ParsedArchive[]
  >(
    ['post-upload-concilation-files', workspaceId, period, ...refreshOn],
    request => {
      onBefore && onBefore()
      return uploadArchiveToPeriod(workspaceId, request, period)
    },
    {
      onSuccess: async data => {
        onSuccess && onSuccess(data)
        // dialog.primary({
        //   title: 'Se subieron correctamente los estados de cuenta.',
        //   icon: <CheckCircleOutlineIcon />,
        //   primaryButtonText: 'Continuar',
        // })
      },
      onError: async (error: any, request: ParsedArchive[]) => {        
        onError && onError(error)

        const op = selectErrorDialog(error)

        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const errorMessage =
            error.status === 400
              ? 'El nombre del archivo con los estados de cuenta que intentas subir ya existe, por favor verifica el nombre del archivo y vuelve a intentar'
              : 'No se pudo subir el archivo.'
          await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: errorMessage,
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Entendido',
            //secondaryButtonText: 'Cancelar',
          })

          // if (rp) {
          //   query.mutate(request)
          //   return
          // }
        }

        //onError && onError(error)
      },
    }
  )

  return query
}
