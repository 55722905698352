import HTTPClient from 'lib/helpers/HTTPClient';
//import _ from 'lodash';

import { httpErrorParser } from 'lib/helpers/utilities';

import Product, { ProductForm } from 'models/product';

export const SET_PRODUCTS_LIST = '@Products/SET_PRODUCTS_LIST';
export const SET_SELECTED_PRODUCT = '@Products/SET_SELECTED_PRODUCT';
export const SET_CURRENT_TAB = '@Products/SET_CURRENT_TAB';

const api = process.env.REACT_APP_APIURL || "/api/";

export const setProductsList = (value: Product[]): object => ({
    type: SET_PRODUCTS_LIST,
    value
})

export const setSelectedProduct = (value: Product | null = null): object => ({
    type: SET_SELECTED_PRODUCT,
    value
})

export const setCurrentTab = (value: number = 0): object => ({
    type: SET_CURRENT_TAB,
    value
})

export const getProductById = (workspaceId: number, productId: number, noSave: boolean = false) => async (dispatch: any): Promise<Product> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/items/${productId}`);
        const product: Product = await petition.json();
        !noSave && dispatch(setSelectedProduct(product));
        return product;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getProductsList = (workspaceId: number) => async (dispatch: any): Promise<Product[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${api}/workspaces/${workspaceId}/items`);
        const list: Product[] = await petition.json();
        dispatch(setProductsList(list));
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const createProduct = (workspaceId: number, form: ProductForm) => async (): Promise<Product> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            internalId: form.internalId,
            name: form.name,
            description: form.description,
            itemsatcode: `${form.itemSat?.value}`,
            unitsatcode: `${form.unitSat?.value}`,
            idClaveSat: form.itemSat?.idCatalogValues,
            idClaveUnitSat: form.unitSat?.idCatalogValues,
            price: form.price,
            categoryId: form.category?.idCategory,
            idObjImp: form.tax?.idCatalogValues
        }
        const petition = await client.post(`${api}/workspaces/${workspaceId}/items`, body);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const updateProduct = (workspaceId: number, productId: number, form: ProductForm) => async (): Promise<Product> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            internalId: form.internalId,
            name: form.name,
            description: form.description,
            itemsatcode: `${form.itemSat?.value}`,
            unitsatcode: `${form.unitSat?.value}`,
            idClaveSat: form.itemSat?.idCatalogValues,
            idClaveUnitSat: form.unitSat?.idCatalogValues,
            price: form.price,
            categoryId: form.category?.idCategory,
            idObjImp: form.tax?.idCatalogValues
        }
        const petition = await client.put(`${api}/workspaces/${workspaceId}/items/${productId}`, body);
        const data: Product = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const deleteProduct = (workspaceId: number, productId: number) => async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${api}/workspaces/${workspaceId}/items/${productId}`);
        await petition.data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}