import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import { useIntl } from 'react-intl'
import { Money, Currencies } from 'ts-money'
import { validations } from 'lib/helpers/ioValidations'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from 'components/Shared/Button/Button'
import ScrollSpy from 'components/Invoices/ScrollSpy/ScrollSpy'
import DisplayTotal from 'components/Invoices/DisplayTotal/DisplayTotal'
import { DropdownCard } from 'components/FormComponents/Dropdown/Dropdown'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import { setSelectedInvoice } from 'actions/invoices'
import { storeFormValue } from 'actions/forms'
import {
  selectSelectedInvoice,
  selectCatalogue,
  selectCreatePaymentComplement,
} from 'lib/helpers/selectors'

import './style.scss'

import { CatalogueEntry } from 'models/catalogues'
import {
  useAddPaymentComplement,
  useCfdi,
  useCfdiPaidAmount,
} from 'hooks/queries/invoices'
import { useTaxCatalogue } from 'hooks/queries/catalogues'

interface Props {
  workspaceId: number
  invoiceId: number
  onRedirect: (action: string) => void
}

const AddPaymentComplementV2Container: React.FC<Props> = ({
  workspaceId,
  invoiceId,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()
  const intl = useIntl()

  const invoice = useSelector(selectSelectedInvoice) || undefined
  const form = useSelector(selectCreatePaymentComplement)
  const monedas = useSelector(selectCatalogue('monedas'))
  const formasPago = useSelector(selectCatalogue('formasPago'))
  //const metodosPago = useSelector(selectCatalogue("metodosPago"));

  const [valid, setValid] = useState(false)

  // #region Queries
  const taxesQuery = useTaxCatalogue()
  const incomeInvoiceQuery = useCfdi(workspaceId, invoiceId, {
    onError: () => onRedirect('cancel'),
  })
  const paidAmountQuery = useCfdiPaidAmount(workspaceId, invoice, {
    enabled: !_.isNil(invoice),
    // onSuccess: (data) => {
    //   const isa = Money.fromDecimal(invoice?.total || 0, Currencies.MXN).subtract(data);
    //   saveValueToStore("importeSaldoAnterior", isa.toString());
    // }
  })
  // #endregion

  // #region Mutations
  const addMutation = useAddPaymentComplement(workspaceId, invoice, {
    onSuccess: () => onRedirect('success'),
  })
  // #endregion

  // #region Effects
  // This effect assign default values for the specified Form field.
  useEffect(() => {
    if (_.isNil(form.moneda)) {
      const mxn = _.filter(monedas, m => m.value === 'MXN')[0]
      saveValueToStore('moneda', mxn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    taxesQuery.isLoading ||
    incomeInvoiceQuery.isLoading ||
    paidAmountQuery.isLoading
      ? loader.primary('Cargando información del comprobante')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    taxesQuery.isLoading,
    incomeInvoiceQuery.isLoading,
    paidAmountQuery.isLoading,
  ])
  useEffect(
    () => () => {
      dispatch(setSelectedInvoice())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // #region Formatters
  const formatCatalogueLabel = (type?: CatalogueEntry) => {
    if (!type) return '---'
    return `${type.value} - ${type.label}`
  }
  const formatMoney = (money?: number) => {
    if (!money && money !== 0) return '---'
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatDate = (dateString?: string) => {
    if (!dateString) return ''
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  // #endregion

  // #region Additional Functions
  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  const saveValueToStore = (name: string, value: any) => {
    dispatch(storeFormValue('createPaymentComplement', name, value))
  }
  // #endregion

  const paidAmount =
    paidAmountQuery.data || Money.fromDecimal(0, Currencies.MXN)
  const isa = Money.fromDecimal(invoice?.total || 0, Currencies.MXN).subtract(
    paidAmount
  )
  const monto = Money.fromDecimal(parseFloat(form.monto ? form.monto : "0"), Currencies.MXN)
  const tax = monto.multiply(Number(form?.tasa) || 0)
  const ip = monto.add(tax)

  const tasaCat = _.get(taxesQuery, 'data.0.value', [])

  return (
    <>
      <DropdownCard
        id="invoiceDropdown"
        title="Factura Original"
        headerBackgroundColor="secondary"
        capitalize
        iconAlign="left"
        defaultExpanded
        elevation={3}>
        <Form onSubmit={() => true} onValidity={() => true}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                name="fecha"
                label="Fecha"
                value={formatDate(invoice?.createdAt)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="uuid"
                label="UUID"
                value={invoice?.uuid}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="serie"
                label="Serie"
                value={invoice?.serie}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="folio"
                label="Folio"
                value={invoice?.folio}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="moneda"
                label="Moneda"
                value={formatCatalogueLabel(invoice?.currency)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                name="clientRfc"
                label="RFC Receptor"
                value={invoice?.clients?.rfc}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="clientName"
                label="Receptor"
                value={invoice?.clients?.name}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="emisorRfc"
                label="RFC Receptor"
                value={invoice?.emisor?.rfc}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="emisorName"
                label="Receptor"
                value={invoice?.emisor?.name}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="subtotal"
                label="Subtotal"
                value={formatMoney(invoice?.subtotal)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="total"
                label="Total"
                value={formatMoney(invoice?.total)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
          </Grid>
        </Form>
      </DropdownCard>
      <Card elevation={1} className="container-add-complement">
        <div className="scrollspy-container">
          <ScrollSpy
            items={[
              { id: 'section-1', label: '1', tooltip: 'Recepción de pagos' },
              {
                id: 'section-2',
                label: '2',
                tooltip: 'Documentos relacionados',
              },
            ]}
          />
        </div>
        <div className="form-container">
          <Form
            onSubmit={async () => addMutation.mutate('4.0')}
            onValidity={(v: boolean) => setValid(v)}>
            <div id="section-1" className="content">
              <h3 className="title">(1) Recepción de pagos</h3>
              <Divider className="separator" />
              <p className="subtitle">Datos del pago</p>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <NumericField
                    name="fechaPago"
                    label="Fecha de pago"
                    value={form?.fechaPago}
                    onValue={(v: string) => saveValueToStore('fechaPago', v)}
                    validate={['isDateFormatD', 'isValidDateD']}
                    format="date"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="formaPago"
                    label="Forma de pago"
                    catalogue={formasPago}
                    value={form?.formaPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('formaPago', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="moneda"
                    label="Moneda"
                    catalogue={monedas}
                    value={form?.moneda}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('moneda', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                    required
                  />
                </Grid>
                {form?.moneda?.value !== 'MXN' && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      value={form?.tipoCambio}
                      onValue={(v: string) => saveValueToStore('tipoCambio', v)}
                      format="money"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    fieldType="number"
                    name="numeroOperacion"
                    label="Número de operación (opcional)"
                    value={form?.numeroOperacion}
                    onValue={(v: string) =>
                      saveValueToStore('numeroOperacion', v)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <p className="subtitle">Monto del pago</p>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <NumericField
                    name="monto"
                    label="Monto"
                    value={form?.monto}
                    onValue={(v: string) => saveValueToStore('monto', v)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="tasa"
                    label="Tasa (%)"
                    catalogue={_.map(tasaCat, v => ({
                      label: `${v * 100}%`,
                      value: v,
                    }))}
                    value={
                      !_.isNil(form.tasa)
                        ? {
                            label: `${Number(form.tasa) * 100}%`,
                            value: form.tasa,
                          }
                        : undefined
                    }
                    onValue={(v: any) =>
                      saveValueToStore('tasa', v ? v.value : undefined)
                    }
                    getOptionLabel={(o: any) => o.label}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value.value
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericField
                    name="totalWithTax"
                    label="Total"
                    value={monto.add(tax).toString()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    inputProps={{ readOnly: true }}
                    format="money"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <p className="subtitle">Información Adicional</p>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="rfcOrigen"
                    label="RFC Emisor de cuenta origen (opcional)"
                    validate={(v: string) => ({
                      valid:
                        validations.isCompanyRFC(v) ||
                        validations.isPersonRFC(v),
                      message: 'El formato del RFC es incorrecto',
                    })}
                    value={form?.rfcOrigen}
                    onValue={(v: string) => saveValueToStore('rfcOrigen', v)}
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="rfcBeneficiario"
                    label="RFC Emisor cuenta beneficiaria (opcional)"
                    validate={(v: string) => ({
                      valid:
                        validations.isCompanyRFC(v) ||
                        validations.isPersonRFC(v),
                      message: 'El formato del RFC es incorrecto',
                    })}
                    value={form?.rfcBeneficiario}
                    onValue={(v: string) =>
                      saveValueToStore('rfcBeneficiario', v)
                    }
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="nombreBanco"
                    label="Nombre de banco (opcional)"
                    value={form?.nombreBanco}
                    onValue={(v: string) => saveValueToStore('nombreBanco', v)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cuentaBeneficiario"
                    label="Cuenta beneficiario (opcional)"
                    value={form?.cuentaBeneficiario}
                    onValue={(v: string) =>
                      saveValueToStore('cuentaBeneficiario', v)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cuentaOrdenante"
                    label="Cuenta ordenante (opcional)"
                    value={form?.cuentaOrdenante}
                    onValue={(v: string) =>
                      saveValueToStore('cuentaOrdenante', v)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <p className="subtitle" />
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="tipoCadenaPago"
                    label="Tipo cadena de pago (opcional)"
                    catalogue={[]}
                    value={form?.tipoCadenaPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('tipoCadenaPago', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="certificadoPago"
                    label="Certificado de pago (opcional)"
                    value={form?.certificadoPago}
                    onValue={(v: string) =>
                      saveValueToStore('certificadoPago', v)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cadenaPago"
                    label="Cadena de pago (opcional)"
                    value={form?.cadenaPago}
                    onValue={(v: string) => saveValueToStore('cadenaPago', v)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="selloPago"
                    label="Sello de pago (opcional)"
                    value={form?.selloPago}
                    onValue={(v: string) => saveValueToStore('selloPago', v)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <div id="section-2" className="content">
              <h3 className="title">(2) Documentos relacionados</h3>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="idDocumento"
                    label="ID del documento"
                    value={_.toUpper(invoice?.uuid)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="serie"
                    label="Serie"
                    value={invoice?.serie}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="folio"
                    label="Folio"
                    value={invoice?.folio}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="moneda"
                    label="Moneda"
                    value={formatCatalogueLabel(invoice?.currency)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                {invoice?.currency.value !== 'MXN' && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      value={''}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ readOnly: true }}
                      format="money"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    name="metodoPago"
                    label="Metodo de pago"
                    value={`${invoice?.paymentMethod?.value} - ${invoice?.paymentMethod?.label}`}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fieldType="number"
                    name="numeroParcialidad"
                    label="Numero de parcialidad"
                    validate={['isMoreThanZero']}
                    value={form?.numeroParcialidad}
                    onValue={(v: string) =>
                      saveValueToStore('numeroParcialidad', v)
                    }
                    fullWidth
                    required={invoice?.paymentMethod?.value === 'PPD'}
                  />
                </Grid>
              </Grid>
              <p className="subtitle" />
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <NumericField
                    name="importeSaldoAnterior"
                    label="Importe saldo anterior"
                    defaultValue={isa.toString()}
                    value={form?.importeSaldoAnterior}
                    onValue={(v: string) =>
                      saveValueToStore('importeSaldoAnterior', v)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericField
                    name="importePagado"
                    label="Importe pagado"
                    defaultValue={ip.toString()}
                    value={form?.importePagado}
                    onValue={(v: string) =>
                      saveValueToStore('importePagado', v)
                    }
                    disabled={!form?.importeSaldoAnterior}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericField
                    name="importeInsoluto"
                    label="importe insoluto"
                    value={isa.subtract(ip).toString()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    inputProps={{ readOnly: true }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <div className="invoice-breakdown">
                <DisplayTotal
                  money={form?.moneda?.value}
                  total={monto.toDecimal()}
                />
              </div>
            </div>
            <Divider className="separator" />
            <div className="actions">
              {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                <Button
                  variant="outlined"
                  color="secondary"
                  activity={addMutation.isLoading}
                  disabled={!valid}>
                  Vista previa
                </Button>
              )}
              <Button
                id="addPayComplementBtn"
                variant="contained"
                type="submit"
                color="secondary"
                activity={addMutation.isLoading}
                disabled={!valid}>
                Agregar
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  )
}

export default AddPaymentComplementV2Container
