import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import Card from '@material-ui/core/Card';

import Upload from '@material-ui/icons/Publish';
import Check from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import './style.scss';

interface FileInputProps extends IOInputProps {
    label: string
    accept?: string
    limit?: number
    base64?: boolean
}

const _FileField: React.FC<FileInputProps> = ({
    ioProps: { name, invalid, valid, message },
    value,
    setValue,
    defaultValue,
    label,
    accept = "",
    limit = 4,
    ...props
}) => {
    const intl = useIntl();
    const inputFile: any = useRef();

    const [updateValue, setUpdateValue] = useState<boolean>(false);

    useEffect(() => {
        setUpdateValue(true);
    }, []);

    useEffect(() => {
        if (defaultValue && updateValue) {
            setValue(defaultValue);
            updateValue && setUpdateValue(false);
        }
    }, [updateValue, defaultValue, setValue]);

    const getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const extractValue = async (files: FileList | null) => {
        if (files && files.length > 0) {
            const file = files[0];
            //if (validations(file)) {
                const resultado = { 
                    fileName: file.name,
                    extension: (_.split(file.name, '.')).pop(),
                    base64: await getBase64(file)
                };
                setValue(resultado);
            //}
        } else {
            inputFile.current.value = null;
            setValue(null);
        }
    }

    const onDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        extractValue(e.dataTransfer.files);
    }

    const onDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    const onClick = (force?: boolean) => {
        if (force || _.isEmpty(value)) {
            inputFile.current.value = null;
            setValue(null);
            inputFile.current.click();
        }
    }

    return (
        <Card 
            id={`fileField-${name}`}
            className={`component-file-field ${_.isEmpty(value) ? "no-value" : ""} ${!_.isEmpty(value) ? "valid" : ""} ${invalid ? "" : ""}`} 
            onClick={() => onClick()}
            onDrop={onDrop}
            onDragOver={onDragOver}
            elevation={0}
        >
            <input
                ref={inputFile}
                onChange={(e) => extractValue(e.target.files)}
                type="file"
                accept={accept}
                style={{ display: 'none' }}
            />
            <div className="icon-container">
                {_.isEmpty(value) ? <Upload fontSize="inherit" /> : (invalid ? <Error fontSize="inherit" /> : <Check fontSize="inherit" />)}
            </div>
            <div className="text-container">
                <h5 className="name-label">{!_.isEmpty(value) ? value.fileName : label}</h5>
                <p className="placeholder-label">{_.isEmpty(value)
                    ? intl.formatMessage({ id: "form.file.placeholder" })
                    : intl.formatMessage({ id: "form.file.message.valid" }, { extension: `.${_.upperCase(value.extension)}` })
                }</p>
                {!_.isEmpty(value) && <p className="remove-label" onClick={() => onClick(true)}>{intl.formatMessage({ id: "form.file.remove" })}</p>}
            </div>
        </Card>
    )
}

const FileField = withIO("filefield")(_FileField);

export default FileField;
