import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import _ from 'lodash'

import {
  getWorkspaceList,
  getWorkspaceById,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  WorkspaceParams,
  getCommentListForWorkspace,
  addCommentToWorkspace,
  getUserNotificationsList,
  changeUserNotification,
  getCollaboratorList,
  inviteCollaborator,
  updateCollaborator,
  deleteCollaborator,
  acceptInvitation,
  rejectInvitation,
  getBillingList,
  editBilling,
  BillingParams,
  uploadBillingFile,
  getPlan,
} from 'services/workspaces'
import {
  getWorkspaceById as getWorkspaceByIdR,
  setActiveWorkspace,
  setSelectedWorkspace,
  setWorkspaceList,
} from 'actions/workspaces'
import {
  selectActiveWorkspace,
  selectProfile,
  selectUserId,
} from 'lib/helpers/selectors'

import ErrorI from '@material-ui/icons/ErrorOutline'
import Help from '@material-ui/icons/Help'

import Workspace, {
  EditColaboratorForm,
  InvitationForm,
  Comment,
  UserNotifications,
  NewWorkspaceForm,
  Billing,
  SubscriptionPlan,
} from 'models/workspace'
import { Profile } from 'models/user'
import { Notification, QueryOptions, MutationOptions } from 'models/general'
import { useThunkDispatch } from 'models/thunk'
import { setNotifications } from 'actions/user'
import {
  resetSyncValues,
  setFilterValue,
  setInvoicesList,
  setPaginationValue,
} from 'actions/invoices'
import { resetPath } from 'actions/documentation'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

// #region Workspaces
const useWorkspaceList = (
  status?: boolean,
  options: QueryOptions<Workspace[]> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()
  const profile = useSelector(selectProfile)
  let active = useSelector(selectActiveWorkspace)

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Workspace[]>(
    ['get-workspace-list', ...refreshOn],
    () => {
      onBefore && onBefore()
      return getWorkspaceList(profile.idUser, active, status)
    },
    {
      ...rest,
      onSuccess: data => {
        if (!noSave) {
          dispatch(setWorkspaceList(data))
          active = !_.isNil(_.find(data, ws => ws.id === active)) ? active : 0
          dispatch(setActiveWorkspace(active))
        }
        onSuccess && onSuccess(data)
      },
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de espacios de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useWorkspace = (
  id: number,
  options: QueryOptions<Workspace> = {}
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()
  const idUser = useSelector(selectUserId)

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Workspace>(
    ['get-workspace-list', id, idUser, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getWorkspaceById(id, idUser)
    },
    {
      ...rest,
      onSuccess: data => {
        !noSave && dispatch(setSelectedWorkspace(data))
        onSuccess && onSuccess(data)
      },
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener el espacio de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useAddWorkspace = (
  options: MutationOptions<Workspace, NewWorkspaceForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<Workspace, any, NewWorkspaceForm>(
    form => {
      onBefore && onBefore(form)
      return createWorkspace(form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se puede crear el espacio de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}
export const useUpdateWorkspace = (
  options: MutationOptions<Workspace, { id: number } & WorkspaceParams> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<
    Workspace,
    any,
    { id: number } & WorkspaceParams
  >(
    ({ id, ...params }) => {
      return updateWorkspace(id, params)
    },
    {
      onMutate: () => {
        showAdditionalInformationLoading()
      },
      onSuccess: (data, variables, context) => {
        dialog.close()
        onSuccess && onSuccess(data, variables, context)
      },
      onError: async (error, params, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo actualizar el nombre del espacio de trabajo.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(params)
              return
            }
          }

          onError && onError(error, params, ctx)
        }, 600)
      },
    }
  )

  return mutation
}
export const useDeleteWorkspace = (
  options: MutationOptions<void, Workspace> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, onMutate, noCheck, noDialog, ...rest } = options

  const mutation = useMutation<void, any, Workspace>(
    workspace => {
      onBefore && onBefore(workspace)
      return deleteWorkspace(workspace.id)
    },
    {
      ...rest,
      onMutate: async workspace => {
        if (!noCheck) {
          const resp = await dialog.dangerAsync({
            title: '¿Estás seguro?',
            subtitle:
              'Estás a punto de eliminar uno de tus espacios de trabajo.',
            icon: Help as TModalIcon,
            primaryButtonText: 'Si, eliminar',
            secondaryButtonText: 'Cancelar',
          })
          if (!resp) throw new Error('mutation-cancelled')
        }
        onMutate && onMutate(workspace)
      },
      onError: async (error, workspace, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo eliminar el espacio de trabajo seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(workspace)
            return
          }
        }

        onError && onError(error, workspace, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region Collaborators
export const useCollaboratorList = (
  workspaceId: number,
  options: QueryOptions<Profile[]> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Profile[]>(
    ['get-collaborator-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCollaboratorList(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        noSave && console.log(data)
        onSuccess && onSuccess(data)
      },
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de colaboradores.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useAddCollaborator = (
  workspaceId: number,
  options: MutationOptions<void, InvitationForm> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, InvitationForm>(
    form => {
      onBefore && onBefore(form)
      return inviteCollaborator(workspaceId, form)
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo invitar al colaborador a este espacio de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}
export const useUpdateCollaborator = (
  workspaceId: number,
  options: MutationOptions<
    void,
    { collabId: number; form: EditColaboratorForm }
  > = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<
    void,
    any,
    { collabId: number; form: EditColaboratorForm }
  >(
    variables => {
      onBefore && onBefore(variables)
      return updateCollaborator(workspaceId, variables.collabId, variables.form)
    },
    {
      ...rest,
      onError: async (error, variables, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo actualizar al colaborador seleccionado.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(variables)
            return
          }
        }

        onError && onError(error, variables, ctx)
      },
    }
  )

  return mutation
}
export const useDeleteCollaborator = (
  workspaceId: number,
  options: MutationOptions<void, Profile> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<void, any, Profile>(
    async colaborator => {
      const resp = await dialog.dangerAsync({
        title: '¿Estás seguro?',
        subtitle:
          'Estás a punto de quitar al colaborador seleccionado de este espacio de trabajo',
        icon: Help as TModalIcon,
        primaryButtonText: 'Sí, eliminar',
        secondaryButtonText: 'Cancelar',
      })
      if (resp) {
        showAdditionalInformationLoading()
        return deleteCollaborator(workspaceId, colaborator.idUser)
      }
      if (!resp) throw new Error('mutation-cancelled')
    },
    {
      onSuccess: (data, variables, context) => {
        dialog.close()
        onSuccess && onSuccess(data, variables, context)
      },
      onError: async (error, colaborator, ctx) => {
        if (error.message === 'mutation-cancelled') return

        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }
          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle:
                'No se pudo quitar al colaborador seleccionado de este espacio de trabajo.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(colaborator)
              return
            }
          }

          onError && onError(error, colaborator, ctx)
        }, 600)
      },
    }
  )

  return mutation
}
// #endregion

// #region Comments
export const useCommentList = (
  workspaceId: number,
  options: QueryOptions<Comment[]> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Comment[]>(
    ['get-comment-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getCommentListForWorkspace(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        noSave && console.log(data)
        onSuccess && onSuccess(data)
      },
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de comentarios.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useAddComment = (
  workspaceId: number,
  options: MutationOptions<void, string> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, string>(
    comment => {
      onBefore && onBefore(comment)
      return addCommentToWorkspace(workspaceId, comment)
    },
    {
      ...rest,
      onError: async (error, comment, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo agregar el comentario a este espacio de trabajo.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(comment)
            return
          }
        }

        onError && onError(error, comment, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region User Notifications
export const useUserNotificationsList = (
  workspaceId: number,
  options: QueryOptions<UserNotifications[]> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onSuccess,
    onError,
    noSave = false,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<UserNotifications[], any>(
    ['get-user-notifications-list', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getUserNotificationsList(workspaceId)
    },
    {
      ...rest,
      onSuccess: data => {
        noSave && console.log(data)
        onSuccess && onSuccess(data)
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de notificaciones.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useChangeUserNotification = (
  workspaceId: number,
  options: MutationOptions<
    void,
    {
      userId: number
      roleId: number
      typeId: number
      consecutive: number
      value: boolean
    }
  > = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<
    void,
    any,
    {
      userId: number
      roleId: number
      typeId: number
      consecutive: number
      value: boolean
    }
  >(
    form => {
      onBefore && onBefore(form)
      return changeUserNotification(
        workspaceId,
        form.userId,
        form.roleId,
        form.typeId,
        form.consecutive,
        form.value
      )
    },
    {
      ...rest,
      onError: async (error, form, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo cambiar el estado de esta notificación.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(form)
            return
          }
        }

        onError && onError(error, form, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region Invitations
export const useAcceptInvitation = (
  options: MutationOptions<void, Notification> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, Notification>(
    invitation => {
      onBefore && onBefore(invitation)
      return acceptInvitation(invitation.id)
    },
    {
      ...rest,
      onError: async (error, invitation, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo realizar la operación solicitada.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(invitation)
            return
          }
        }

        onError && onError(error, invitation, ctx)
      },
    }
  )

  return mutation
}
export const useRejectInvitation = (
  options: MutationOptions<void, Notification> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, Notification>(
    invitation => {
      onBefore && onBefore(invitation)
      return rejectInvitation(invitation.id)
    },
    {
      ...rest,
      onError: async (error, invitation, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo realizar la operación solicitada.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(invitation)
            return
          }
        }

        onError && onError(error, invitation, ctx)
      },
    }
  )

  return mutation
}
// #endregion

export const useWorkspaceSelection = () => {
  const thunkDispatch = useThunkDispatch()
  const dispatch = useDispatch()
  const dialog = useDialog()

  const onSelect = async (id: number) => {
    try {
      // Get and set selected workspace information
      const ws = await thunkDispatch(getWorkspaceByIdR(id))
      dispatch(setActiveWorkspace(ws.id))
      // Clear Notifications
      dispatch(setNotifications([]))
      // Reset CFDIs List filters and sync values
      dispatch(setFilterValue('reset', true))
      dispatch(resetSyncValues())
      // Reset Documentation Path
      dispatch(resetPath())
      // Reset Pagination and List for CFDIs
      dispatch(setPaginationValue('size', 10))
      dispatch(setPaginationValue('page', 0))
      dispatch(setInvoicesList([]))
    } catch (err) {
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener la información del Workspace seleccionado. Intenta nuevamente.',
        icon: ErrorI as TModalIcon,
      })
    }
  }

  return onSelect
}

// #region Billing
export const useBillingList = (
  workspaceId: number,
  options: QueryOptions<Billing[]> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onError,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<Billing[]>(
    ['get-billing', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getBillingList(workspaceId)
    },
    {
      ...rest,
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información de la suscripción.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
export const useEditBilling = (
  workspaceId: number,
  options: MutationOptions<void, { id: number } & BillingParams> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, { id: number } & BillingParams>(
    ({ id, ...params }) => {
      onBefore && onBefore({ id, ...params })
      return editBilling(workspaceId, id, params)
    },
    {
      ...rest,
      onError: async (error, params, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo actualizar la información de la suscripción.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(params)
            return
          }
        }

        onError && onError(error, params, ctx)
      },
    }
  )

  return mutation
}
export const useUploadBillingFile = (
  workspaceId: number,
  options: MutationOptions<void, string> = {}
) => {
  const dialog = useDialog()
  const { onBefore, onError, noDialog, ...rest } = options

  const mutation = useMutation<void, any, string>(
    file => {
      onBefore && onBefore(file)
      return uploadBillingFile(workspaceId, file)
    },
    {
      ...rest,
      onError: async (error, params, ctx) => {
        if (error.message === 'mutation-cancelled') return
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle:
              'No se pudo actualizar la información de las suscripciones.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(params)
            return
          }
        }

        onError && onError(error, params, ctx)
      },
    }
  )

  return mutation
}
// #endregion

// #region Plan
export const useSubscriptionPlan = (
  workspaceId: number,
  options: QueryOptions<SubscriptionPlan> = {}
) => {
  const dialog = useDialog()

  const {
    onBefore,
    onError,
    noDialog = false,
    refreshOn = [],
    ...rest
  } = options

  const query = useQuery<SubscriptionPlan>(
    ['get-subscription-plan', workspaceId, ...refreshOn],
    () => {
      onBefore && onBefore()
      return getPlan(workspaceId)
    },
    {
      ...rest,
      onError: async (error: any) => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información de la suscripción.',
            icon: ErrorI as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }

        onError && onError(error)
      },
    }
  )

  return query
}
// #endregion

export default useWorkspaceList
