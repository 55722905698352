import { useNavigate } from 'react-router-dom'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'
import ISydDocument from 'models/shared.models'
import {
  IAccountingChecklist,
  IAccountingProcess,
  TAccountingProcessSteps,
  TAccountingStatusCatalogue,
} from 'models/accounting.models'
import { useThunkDispatch } from 'models/thunk'
import { ROUTES } from '../../constants'
import { ACCOUNTING_STATUS_CATALOGUE } from 'lib/enums/accounting.enums'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import {
  accountingStatusToAccountingStepMap,
  accountingStepToAccountingStatusIdMap,
} from 'lib/utils/Accounting/accounting.utils'
import useDialog from 'hooks/Shared/useDialog'
import {
  addCommentToProcess,
  updateProcessChecklist,
  updateProcessStatus,
} from 'store/Accounting/accounting.actions'
import { IApiError } from 'services/Shared/shared.models'
import {
  IAccountingLocalState,
  IUseAccounting,
} from 'containers/Accounting/AccountingContainer/useAccounting'

interface IUseHandleAccountingStepsProps
  extends Pick<IUseAccounting, 'handleFileDelete' | 'handleFileValidation'> {
  workspaceId: number
  currentAccountingProcess?: IAccountingProcess
  updateAccountingState: (
    update:
      | Partial<IAccountingLocalState>
      | ((prevState: IAccountingLocalState) => IAccountingLocalState)
  ) => void
  getAccounting: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IAccountingProcess, IApiError>>
}

type TUseHandleAccountingSteps = Pick<
  IUseAccounting,
  | 'activeStep'
  | 'goToStep'
  | 'handleStepBack'
  | 'handleStepSubmit'
  | 'handleProcessCheck'
>

const useHandleAccountingSteps = ({
  workspaceId,
  currentAccountingProcess,
  updateAccountingState,
  getAccounting,
  handleFileDelete,
  handleFileValidation,
}: IUseHandleAccountingStepsProps): TUseHandleAccountingSteps => {
  const navigate = useNavigate()
  const thunkDispatch = useThunkDispatch()

  const dialog = useDialog()

  const activeStep =
    accountingStatusToAccountingStepMap[
      (currentAccountingProcess?.status.id as TAccountingStatusCatalogue) ??
        ACCOUNTING_STATUS_CATALOGUE.DOCUMENTOS_SOLICITADOS
    ]

  const goToStep = async (step: TAccountingProcessSteps) => {
    const value = accountingStepToAccountingStatusIdMap[step]

    if (!currentAccountingProcess || !value) return
    try {
      updateAccountingState({
        stepChangeLoading: true,
      })
      await thunkDispatch(
        updateProcessStatus(workspaceId, currentAccountingProcess, value)
      )
      await getAccounting()
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        dialog[op.variant](op)
        return
      }

      dialog.danger({
        title: 'generic.error.title ',
        subtitle: 'No se pudo actualizar la información del proceso actual.',
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
        onPrimaryClick: async () => {
          await thunkDispatch(
            updateProcessStatus(workspaceId, currentAccountingProcess, value)
          )
          await getAccounting()
        },
        onSecondaryClick: () => {
          navigate(ROUTES.HOME, { replace: true })
        },
      })
    } finally {
      updateAccountingState({
        stepChangeLoading: false,
      })
    }
  }

  const handleStepBack = () => {
    goToStep((activeStep - 1) as TAccountingProcessSteps)
  }

  const handleStepSubmit = async (
    nextStep: TAccountingProcessSteps,
    comments?: string,
    uploadedFiles: ISydDocument[] = [],
    invalidFiles: ISydDocument[] = [],
    commentType: number = 1
  ) => {
    if (!currentAccountingProcess) return
    updateAccountingState({
      updateAccountingProcessLoading: true,
    })
    let validStep = true
    let result
    if (comments) {
      result = await thunkDispatch(
        addCommentToProcess(
          workspaceId,
          currentAccountingProcess,
          comments,
          commentType
        )
      )
      validStep = false
    }
    if (uploadedFiles.length > 0) {
      const fileEditor = uploadedFiles.map(f => handleFileValidation(f))
      await Promise.all(fileEditor)
      validStep = false
    }
    if (invalidFiles.length > 0) {
      const fileDeletor = invalidFiles.map(f => handleFileDelete(f, true))
      await Promise.all(fileDeletor)
      validStep = false
    }

    validStep && (await goToStep(nextStep))
    !validStep && (await getAccounting())
    updateAccountingState({
      updateAccountingProcessLoading: false,
    })
    return result
  }

  const handleProcessCheck = async (c: IAccountingChecklist, value: number) => {
    if (!currentAccountingProcess) return
    try {
      updateAccountingState({
        updateAccountingProcessLoading: true,
      })

      await thunkDispatch(
        updateProcessChecklist(workspaceId, currentAccountingProcess, c, value)
      )
      await getAccounting()
      updateAccountingState({
        updateAccountingProcessLoading: false,
      })
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      dialog.danger({
        title: 'generic.error.title',
        subtitle:
          'No se pudo realizar la operación solicitada. Intenta nuevamente',
      })
    }
  }

  return {
    activeStep,
    goToStep,
    handleStepBack,
    handleStepSubmit,
    handleProcessCheck,
  }
}

export default useHandleAccountingSteps
