import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

import './style.scss';

interface Props extends CircularProgressProps {
    message?: string
    fullSize?: boolean
    local?: boolean
}

const Activity: React.FC<Props> = ({ 
    color = "primary", 
    size, 
    message = "", 
    fullSize = false,
    local = false,
    ...props
}) => {
    let component = undefined;
    if (fullSize) {
        component = 
            <div className="activity-container">
                <div className="central-flex">
                    <CircularProgress size={size} color={color} {...props} />
                    {Boolean(message) && <h1>{message}</h1>}
                </div>
            </div>

    } else if (local) {
        component = 
            <div className="activity-container-local-loader">
                <CircularProgress size={size} color={color} {...props} />
                {Boolean(message) && <h1>{message}</h1>}
            </div>
    } else {
        component = 
            <div className="activity-container-local">
                <div className="central-flex">
                    <CircularProgress size={size} color={color} {...props} />
                    {Boolean(message) && <h1>{message}</h1>}
                </div>
            </div>
    }

    return component;
}

export default Activity;