import { FC } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  Transaction,
  useConciliationReportsContext,
} from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import Row from '../ConciliationReportDetailsReportDataRow/ConciliationReportDetailsReportDataRow'
import { ReportItem } from 'services/conciliations'
import { TYPES } from '../ConciliationReportDetails/ConciliationReportDetails'
import _ from 'lodash'

const ConciliationReportDetailsReportData: FC<{
  report: ReportItem
}> = ({ report }) => {
  const { transactions } = useConciliationReportsContext()

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              padding="none"
              style={{
                paddingLeft: 35,
              }}>
              Movimientos
            </TableCell>
            <TableCell padding="none">
              {report.type === TYPES.CFDIS ? 'CFDIs' : 'Cuenta Contable'}
            </TableCell>
            {report.type === TYPES.CFDIS && (
              <TableCell padding="none">Estatus</TableCell>
            )}
            <TableCell padding="none" align="right">
              Monto Conciliado
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(transactions, row => (
            <Row
              key={row.idTransaction}
              report={report}
              transaction={row as Transaction}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConciliationReportDetailsReportData
