import { setActiveTab } from 'actions/documentRequest'
import AccountStatementHistory from 'components/Conciliations/AccountStatementHistory/AccountStatementHistory'
import { createContext, useMemo } from 'react'

interface AccountStatementHistoryContextInterface {
  setParentTab: typeof setActiveTab
}

export const AccountStatementHistoryContext =
  createContext<AccountStatementHistoryContextInterface>(
    {} as AccountStatementHistoryContextInterface
  )

const AccountStatementHistoryContainer = () => {
  const setParentTab = setActiveTab

  const valueToProvide: AccountStatementHistoryContextInterface = useMemo(
    () => ({ setParentTab }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*setParentTab*/
    ]
  )

  return (
    <AccountStatementHistoryContext.Provider value={valueToProvide}>
      <AccountStatementHistory />
    </AccountStatementHistoryContext.Provider>
  )
}

export default AccountStatementHistoryContainer
