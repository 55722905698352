import { Box, Typography } from '@material-ui/core'
import { numberToCurrency } from 'lib/helpers/utilities'
import _ from 'lodash'
import { FC, Fragment, useMemo } from 'react'

type RowType = {
  id: number
  amount: number
}

const GridTableFooter: FC<{
  rows: RowType[]
  selectedRows: number[]
  property?: string
  totalElements?: number
}> = ({ rows, selectedRows, property = 'amount', totalElements }) => {
  const hasSelectedRows = useMemo(() => selectedRows.length > 0, [selectedRows])

  const calc = useMemo(() => {
    const selected = rows.filter(row => selectedRows.includes(row.id))
    const total = selected.reduce(
      (acc, row) => acc + Number(_.get(row, property, 0)),
      0
    )
    return numberToCurrency(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, selectedRows /*, property*/])

  return (
    <Box className="table-footer-component">
      {hasSelectedRows ? (
        <Fragment>
          <Typography>
            {selectedRows.length} movimientos seleccionados
          </Typography>
          <Typography>{calc}</Typography>
        </Fragment>
      ) : (
        <Typography>
          {rows.length}
          {totalElements ? ` de ${totalElements}` : ''} movimientos
        </Typography>
      )}
    </Box>
  )
}

export default GridTableFooter
