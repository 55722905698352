import _ from 'lodash';
import moment from 'moment';

export const validations = {
	isDateFormatD: (v) => {
		const pattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]\d{4}$/;
		return pattern.test(v.toString().trim());
	},
	isDateFormatM: (v) => {
		const pattern = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]\d{4}$/;
		return pattern.test(v.toString().trim());
	},
	isDateFormatY: (v) => {
		const pattern = /^\d{4}[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
		return pattern.test(v.toString().trim());
	},
	isValidDateD: (v) => {
		const formats = ["DD-MM-YYYY", "DD MM YYYY", "DD/MM/YYYY", "DD.MM.YYYY"];
        const d = moment(v, formats, true);
        return d && d.isValid();
	},
	isValidDateM: (v) => {
		const formats = ["MM-DD-YYYY", "MM DD YYYY", "MM/DD/YYYY", "MM.DD.YYYY"];
        const d = moment(v, formats, true);
        return d && d.isValid();
	},
	isValidDateY: (v) => {
		const formats = ["YYYY-MM-DD", "YYYY MM DD", "YYYY/MM/DD", "YYYY.MM.DD"];
        const d = moment(v, formats, true);
        return d && d.isValid();
	},
	isNumber: '^[0-9]+$',
	isEmail: (v) => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
			return pattern.test(v.toString().trim());
	},
	isEmailFormat: (v) => {
        const pattern = /^([A-ZÑa-zñ0-9._+@-]|\s|'-')*$/i;
        return pattern.test(v.toString().trim());
    },
	isPasswordLength: (v) => v.length >= 8,
	isPasswordWithNumber: (v) => {
		const pattern = /[0-9]+/;
		return pattern.test(v.toString().trim());
	},
	isPasswordWithCapitalLetter: (v) => {
		const pattern = /[A-Z]+/;
		return pattern.test(v.toString().trim());
	},
	isPasswordWithSpecialCharacter: (v) => {
		const pattern = /["'<>(){}[\]+\-*/=\\¿?¡!.:,;_|ºª@#%$€·¬&^~]+/;
		return pattern.test(v.toString().trim());
	},
	isCompanyRFC: v => {
        const pattern = /^([A-Z,Ñ,&]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;
        return pattern.test(v.toString().trim());
    },
    isPersonRFC: v => {
        const pattern = /^([A-Z,Ñ,&]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;
        return pattern.test(v.toString().trim());
    },
	isGenericOrPersonRFC: v => {
		const str = v.toString().trim();
		const pattern = /^([A-Z,Ñ,&]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;

		const rule1 = pattern.test(str);
		const rule2 = ["XAXX010101000", "XEXX010101000"].indexOf(str) > -1;

		return rule1 || rule2;
	},
	isGenericOrCompanyRFC: v => {
		const str = v.toString().trim();
		const pattern = /^([A-Z,Ñ,&]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;

		const rule1 = pattern.test(str);
		const rule2 = ["XAXX010101000", "XEXX010101000"].indexOf(str) > -1;

		return rule1 || rule2;
	},
	isZipCode: (v) => {
		const pattern = /^[0-9]{5}$/i;
		return pattern.test(v.toString().trim());
	},
	isRequired: (v) => {
        return v === true;
    },
	isTelephone: (v) => {
		return v.length === 10;
	},
	isValidTelephone: (v) => {
		const pattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
		return pattern.test(v);
	},
	isAtLeastEightCharacters: (v) => {
		return v.length >= 8;
	},
	isMoreThanZero: (v) => {
		return v > 0;
	},
	isValidPassword: (v) => {
		const rule1 = validations.isPasswordLength(v);
		const rule2 = validations.isPasswordWithNumber(v);
		const rule3 = validations.isPasswordWithCapitalLetter(v);
		const rule4 = validations.isPasswordWithSpecialCharacter(v);

		return rule1 && rule2 && rule3 && rule4;
	}
};

export const isUniqueId = (id, list) => ({
	valid: _.indexOf(list, id) === -1,
	message: "Este ID ya existe en el catálogo"
})

export const isNumberInRange = (v, min, max) => ({
	valid: v >= min && v <= max,
	message: "Numero fuera de rango"
})

export const isNumberOutOfMaximumRange = (v, max) => ({
	valid: v <= max,
	message: "Numero fuera de rango"
})

export const isNumberOutOfMinimumRange = (v, min) => ({
	valid: v >= min,
	message: "Numero fuera de rango"
})

export const isUniqueString = (str, list) => ({
	valid: _.indexOf(list, str) === -1,
	message: "Este nombre ya existe."
})

export const validationMessagesES = {
	default: 'Valor incorrecto',
	isDateFormatD: 'El formato de la fecha es inválido',
	isDateFormatM: 'El formato de la fecha es inválido',
	isDateFormatY: 'El formato de la fecha es inválido',
	isValidDateD: 'La fecha es invalida',
	isValidDateM: 'La fecha es invalida',
	isValidDateY: 'La fecha es invalida',
	isRequired: 'Este campo es requerido',
    isNumber: 'Solo debe contener números',
    isEmail: 'El formato del correo es inválido',
	isEmailFormat: 'El formato del correo es inválido',
	isPersonRFC: 'El formato del RFC es incorrecto',
	isCompanyRFC: 'El formato del RFC es incorrecto',
	isGenericOrPersonRFC: 'El formato del RFC es incorrecto',
	isGenericOrCompanyRFC: 'El formato del RFC es incorrecto',
	isZipCode: 'El formato de código postal es inválido',
	isTelephone: 'El formato del teléfono es inválido',
	isAtLeastEightCharacters: 'Este campo necesita tener al menos 8 caracteres',
	isMoreThanZero: 'Este campo necesita ser mayor a cero',
	isValidPassword: ' ',
};

export const validationMessagesEN = {
	default: 'Incorrect value',
	isRequired: 'This field is required',
    isNumber: 'Must contain numbers only',
    isEmail: 'Invalid email format',
	isEmailFormat: 'Invalid email format',
	isPersonRFC: 'Invalid RFC format',
	isCompanyRFC: 'Invalid RFC format',
	isZipCode: 'Invalid zip code format',
	isTelephone: 'Invalid telephone format',
	isAtLeastEightCharacters: 'Must contain at least 8 characters',
	isMoreThanZero: 'Must be greater than zero',
	isValidPassword: ' ',
};

export const getValidationMessages = (code) => {
    let messages = null;
	if (!code) {
		code = '';
	}
	switch (code.toLowerCase()) {
		case 'en' :
		case 'en-us' :
			messages = validationMessagesEN;
			break;
		case 'es':
        case 'es-419':
		default :
			messages = validationMessagesES;
			break;
	}
	return messages;
}