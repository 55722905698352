import React from 'react'
import { useIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'
// import HelperText from 'components/Workspaces/HelperText/Helpertext';
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import PersonSelector from 'components/FormComponents/IOComponents/PersonSelector/PersonSelector'

import { getPersonCatalogue, getPersonType } from 'lib/helpers/utilities'

import Catalogue, { CatalogueEntry } from 'models/catalogues'

import Help from '@material-ui/icons/Help'
import './style.scss'
import HelperText from 'components/Workspaces/HelperText/Helpertext'

interface Props {
  type: CatalogueEntry | null
  workspace: string
  name: string
  rfc: string
  taxSystem: CatalogueEntry | null
  zipCode: string
  taxAddress: string
  storeValue?: (name: string, value: any) => void
  tiposPersonaCatalogue: Catalogue
  taxSystemFisicaCatalogue: Catalogue
  taxSystemMoralCatalogue: Catalogue
}

const CreateWorkspaceStepOne: React.FC<Props> = props => {
  const intl = useIntl()

  return (
    <div className="create-workspace-step-one-container">
      <h3 className="title">
        {intl.formatMessage({ id: 'workspace.create.step.1.title' })}
      </h3>
      <p className="subtitle">
        {intl.formatMessage({ id: 'workspace.create.step.1.subtitle' })}
      </p>
      <Divider className="divider" />
      <p className="use-as">
        {intl.formatMessage({ id: 'workspace.create.step.1.form.title' })}
      </p>
      <PersonSelector
        name="person"
        value={props.type}
        filterIn={(v: CatalogueEntry) => v && getPersonType(v.idCatalogValues)}
        filterOut={(v: any) =>
          getPersonCatalogue(props.tiposPersonaCatalogue, v)
        }
        onValue={(v: string) => props.storeValue && props.storeValue('type', v)}
      />
      <div className="form-container">
        {props.type?.idCatalogValues === 1 && (
          <>
            <TextField
              name="workspace"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.workspace`,
              })}
              required
              value={props.workspace}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('workspace', v)
              }
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
            <TextField
              name="name"
              fieldType="lettersWithAccents"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.fisica.name`,
              })}
              required
              value={props.name}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('name', v)
              }
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
            <TextField
              name="rfc"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.fisica.rfc`,
              })}
              validate={['isPersonRFC']}
              required
              value={props.rfc}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('rfc', v)
              }
              inputProps={{
                maxLength: 13,
                style: { textTransform: 'uppercase' },
              }}
              fullWidth
            />
            <AutocompleteField
              name="taxSystem"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.fisica.tax_system`,
              })}
              catalogue={props.taxSystemFisicaCatalogue}
              required
              value={props.taxSystem}
              onValue={(v: any) =>
                props.storeValue && props.storeValue('taxSystem', v)
              }
              fullWidth
              addon={
                <HelperText
                  text={intl.formatMessage({
                    id: `workspace.create.step.1.form.tax_system.known`,
                  })}
                  color="secondary"
                  icon={<Help />}
                  href="http://omawww.sat.gob.mx/regimenesfiscales/Paginas/default.htm"
                />
              }
            />
            <TextField
              name="zipCode"
              fieldType="number"
              validate={['isZipCode']}
              label={intl.formatMessage({ id: `form.zip_code` })}
              required
              value={props.zipCode}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('zipCode', v)
              }
              inputProps={{ maxLength: 5 }}
              fullWidth
            />
            <TextField
              name="taxAddress"
              label={`${intl.formatMessage({ id: `form.tax_address` })} (${intl.formatMessage({ id: `form.optional` })})`}
              value={props.taxAddress}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('taxAddress', v)
              }
              inputProps={{ maxLength: 150 }}
              fullWidth
            />
          </>
        )}
        {props.type?.idCatalogValues === 2 && (
          <>
            <TextField
              name="workspace"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.workspace`,
              })}
              required
              value={props.workspace}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('workspace', v)
              }
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
            <TextField
              name="name"
              fieldType="alphanumeric"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.moral.name`,
              })}
              required
              value={props.name}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('name', v)
              }
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
            <TextField
              name="rfc"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.moral.rfc`,
              })}
              validate={['isCompanyRFC']}
              required
              value={props.rfc}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('rfc', v)
              }
              inputProps={{
                maxLength: 12,
                style: { textTransform: 'uppercase' },
              }}
              fullWidth
            />
            <AutocompleteField
              name="taxSystem"
              label={intl.formatMessage({
                id: `workspace.create.step.1.form.moral.tax_system`,
              })}
              catalogue={props.taxSystemMoralCatalogue}
              required
              value={props.taxSystem}
              onValue={(v: any) =>
                props.storeValue && props.storeValue('taxSystem', v)
              }
              fullWidth
              addon={
                <HelperText
                  text={intl.formatMessage({
                    id: `workspace.create.step.1.form.tax_system.known`,
                  })}
                  color="secondary"
                  icon={<Help />}
                  href="http://omawww.sat.gob.mx/regimenesfiscales/Paginas/default.htm"
                />
              }
            />
            <TextField
              name="zipCode"
              fieldType="number"
              validate={['isZipCode']}
              label={intl.formatMessage({ id: `form.zip_code` })}
              value={props.zipCode}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('zipCode', v)
              }
              inputProps={{ maxLength: 5 }}
              fullWidth
              required
            />
            <TextField
              name="taxAddress"
              label={`${intl.formatMessage({ id: `form.tax_address` })} (${intl.formatMessage({ id: `form.optional` })})`}
              value={props.taxAddress}
              onValue={(v: string) =>
                props.storeValue && props.storeValue('taxAddress', v)
              }
              inputProps={{ maxLength: 150 }}
              fullWidth
            />
          </>
        )}
      </div>
    </div>
  )
}

export default CreateWorkspaceStepOne
