import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UploadedFile } from 'models/general'
import { IAccountingProcess, IAccountingTask } from 'models/accounting.models'
import { IAccountingState } from './accounting.models'
import { RESET_APP_STATE } from 'store/shared.actions'
import ISydDocument from 'models/shared.models'

const initialYear = new Date().getFullYear()
const initialMonth = new Date().getMonth() - 1

const initialAccountingState: IAccountingState = {
  activeDateRange: new Date(initialYear, initialMonth),
  form: {
    files: [],
  },
  accountingTasks: [],
  refetchAccountingProcess: false,
}

const accountingSlice = createSlice({
  name: 'accountingSlice',
  initialState: initialAccountingState,
  reducers: {
    setCurrentProcess(
      state,
      action: PayloadAction<IAccountingProcess | undefined>
    ) {
      state.currentAccountingProcess = action.payload
    },
    setRefetchAccountingProcess(state, action: PayloadAction<boolean>) {
      state.refetchAccountingProcess = action.payload
    },
    setActiveDateRange(state, action: PayloadAction<Date>) {
      state.currentAccountingProcess = undefined
      state.activeDateRange = action.payload
    },
    setAccountingTasks(state, action: PayloadAction<IAccountingTask[]>) {
      state.accountingTasks = action.payload
    },
    toggleAccountingTaskStatus(state, action: PayloadAction<number>) {
      const task = state.accountingTasks.find(t => t.idTask === action.payload)
      if (!task) return

      task.checked = !task.checked
    },
    addFormFile(state, action: PayloadAction<UploadedFile>) {
      state.form.files.push(action.payload)
    },
    editFormFile<T extends keyof UploadedFile>(
      state: IAccountingState,
      action: PayloadAction<{
        id: string
        propertyKey: T
        propertyValue: UploadedFile[T]
      }>
    ) {
      const formFiles = state.form.files

      const { id, propertyKey, propertyValue } = action.payload

      const index = formFiles.findIndex(f => f.uuid === id)
      if (index === -1) return

      formFiles[index][propertyKey] = propertyValue
    },
    deleteFormFile(state, action: PayloadAction<string>) {
      const formFiles = state.form.files

      const index = formFiles.findIndex(f => f.uuid === action.payload)
      if (index === -1) return
      formFiles.splice(index, 1)
    },
    resetFormFiles(state) {
      state.form.files = []
    },
    setUploadedDocuments(state, action: PayloadAction<ISydDocument[]>) {
      const currentProcess = state.currentAccountingProcess

      if (!currentProcess) return
      currentProcess.uploadedDocuments = action.payload
    },
    editUploadedDocument<T extends keyof ISydDocument>(
      state: IAccountingState,
      action: PayloadAction<{
        id: number
        propertyKey: T
        propertyValue: ISydDocument[T]
      }>
    ) {
      const uploadedFiles = state.currentAccountingProcess?.uploadedDocuments

      if (!uploadedFiles) return

      const { id, propertyKey, propertyValue } = action.payload

      const index = uploadedFiles.findIndex(f => f.idWsfile === id)

      if (index === -1) return

      uploadedFiles[index][propertyKey] = propertyValue
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, state => {
      return initialAccountingState
    })
  },
})

export const {
  setCurrentProcess,
  setRefetchAccountingProcess,
  setActiveDateRange,
  setAccountingTasks,
  toggleAccountingTaskStatus,
  addFormFile,
  editFormFile,
  deleteFormFile,
  resetFormFiles,
  setUploadedDocuments,
  editUploadedDocument,
} = accountingSlice.actions

export default accountingSlice.reducer
