import _ from 'lodash'
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import { Archive } from 'models/general'
import ISydDocument from 'models/shared.models'

const route = process.env.REACT_APP_APIURL || '/api/'

export const getFolderContentByPath = async (
  workspaceId: number,
  path: string
): Promise<ISydDocument[]> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/files?path=${path}`
    )
    const list: ISydDocument[] = await petition.json()
    return list
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const createFolderInPath = async (
  workspaceId: number,
  name: string,
  path: string
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.post(
      `${route}/workspaces/${workspaceId}/wsfiles/upload`,
      {
        name: '',
        path: `${path}/${name}`,
        enabled: true,
        mimeType: '',
        extension: '',
        originalName: '',
        file: '',
        directory: true,
      }
    )
    await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const uploadArchiveInPath = async (
  workspaceId: number,
  file: Archive,
  path: string
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.post(
      `${route}/workspaces/${workspaceId}/wsfiles/upload`,
      {
        name: getFileName(file.fileName),
        path: `${path}`,
        enabled: true,
        mimeType: _.split(file.data, ',')[0],
        extension: getFileExtension(file.fileName),
        originalName: file.fileName,
        file: _.split(file.data, ',')[1],
      }
    )
    await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const renameArchive = async (
  workspaceId: number,
  fileId: number,
  name: string
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.put(
      `${route}/workspaces/${workspaceId}/wsfiles/${fileId}`,
      { name }
    )
    await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const deleteArchive = async (
  workspaceId: number,
  fileId: number
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.delete(
      `${route}/workspaces/${workspaceId}/wsfiles/${fileId}`
    )
    await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getFileExtension = (filename: string | undefined) : string => {
    if(filename === undefined) return ""

    const helper = filename.split(".")
    return helper[helper.length - 1]
}

export const getFileName = (filename: string | undefined) : string => {
    if(filename === undefined) return ""

    const extension = getFileExtension(filename)
    return filename.replace(new RegExp( "\\." +  extension + "$"), "")
} 