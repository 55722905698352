import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import BillingFileUploaderContainer from 'containers/Employees/BillingFileUploaderContainer/BillingFileUploaderContainer'

import useEmployeesList from 'hooks/queries/employees'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from 'components/Shared/Button/Button'
import Table from 'components/Shared/DynamicTable/DynamicTable'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import {
  setFilterValue,
  setPaginationValue,
  setSelectedEmployee as setSelectedEmployeeRedux,
} from 'actions/employees'
import {
  selectEmployeesFilter,
  selectEmployeesList,
  selectEmployeesPagination,
} from 'lib/helpers/selectors'

import Close from '@material-ui/icons/Clear'
import Star from '@material-ui/icons/Star'
import Options from '@material-ui/icons/MoreVert'
import Upload from '@material-ui/icons/Publish'
import './style.scss'

import Employee from 'models/employee'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const EmployeesContainer: React.FC<Props> = ({ workspaceId, onRedirect }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const filters = useSelector(selectEmployeesFilter)
  const pagination = useSelector(selectEmployeesPagination)
  const list = useSelector(selectEmployeesList)

  const [showCSVUploadDialog, setShowCSVUploadDialog] = useState(false)
  const [seeMoreAnchor, setSeeMoreAnchor] = useState<null | HTMLElement>(null)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>()

  // #region Queries
  const listQuery = useEmployeesList(
    workspaceId,
    pagination.page,
    pagination.size,
    filters?.search,
    {
      refreshOn: [pagination.page, pagination.size, filters?.search],
      onError: () => onRedirect('error'),
    }
  )
  // #endregion

  // #region Table Formatters
  const formatOptions = (id: number, row: any, key: any) => (
    <IconButton
      id={`employeeOptionsBtn_${key}`}
      size="small"
      color="primary"
      onClick={e => {
        e.stopPropagation()
        setSelectedEmployee(row)
        dispatch(setSelectedEmployeeRedux(row))
        setSeeMoreAnchor(e.currentTarget)
      }}>
      <Options />
    </IconButton>
  )
  const formatNPS = (nps: number, row: any) => (
    <div className="nps-stars">
      <Star /> {nps}
    </div>
  )
  // #endregion

  // #region Table Headers
  const headers = [
    { label: 'ID', accesor: 'id' },
    { label: 'Nombre', accesor: 'name' },
    { label: 'Correo', accesor: 'email' },
    { label: 'Espacios de trabajo', accesor: 'workspaces' },
    { label: 'NPS Promedio', accesor: 'nps', renderer: formatNPS },
    {
      label: '',
      accesor: 'id',
      renderer: formatOptions,
      cellClassName: 'options',
    },
  ]
  // #endregion

  return (
    <>
      <div className="container-employees-filters">
        <Form
          onSubmit={async form =>
            dispatch(setFilterValue('search', form.search))
          }>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <TextField
                name="search"
                label={intl.formatMessage({ id: 'button.search' })}
                placeholder="Buscar por nombre o ID"
                value={filters?.search}
                onValue={(v: string) =>
                  !v && dispatch(setFilterValue('search', v))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="reset"
                        size="small"
                        id="clearSearchBtn"
                        onClick={() => dispatch(setFilterValue('search', ''))}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} className="filter-actions">
              <Button
                id="searchBtn"
                type="submit"
                variant="outlined"
                color="primary"
                // disabled={filters?.advance}
                activity={listQuery.isLoading}>
                {intl.formatMessage({ id: 'button.search' })}
              </Button>
              <Button
                id="showAddCSVFileBtn"
                variant="outlined"
                color="secondary"
                startIcon={<Upload />}
                onClick={() => setShowCSVUploadDialog(true)}
                disabled={!Boolean(workspaceId)}>
                Subir CSV de Suscripciones
              </Button>
            </Grid>
          </Grid>
        </Form>
      </div>

      <Card className="container-employees-list">
        <Table
          headers={headers}
          rows={list}
          loading={listQuery.isLoading}
          fetching={listQuery.isFetching}
          pagination={{
            rowsPerPageOptions: [5, 10, 15, 20],
            count: pagination.total,
            rowsPerPage: pagination.size,
            page: pagination.page,
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count !== -1 ? count : `mas de ${to}`}`,
            labelRowsPerPage: 'Empleados por página:',
            onPageChange: (e, page) =>
              dispatch(setPaginationValue('page', page)),
            onRowsPerPageChange: e => {
              dispatch(setPaginationValue('size', Number(e.target.value)))
              dispatch(setPaginationValue('page', 0))
            },
          }}
        />
      </Card>

      {/* See More Menu */}
      <Menu
        id="seeMoreMenu"
        anchorEl={seeMoreAnchor}
        open={Boolean(seeMoreAnchor)}
        keepMounted
        onClose={() => setSeeMoreAnchor(null)}>
        <MenuItem
          onClick={e => {
            e.stopPropagation()
            onRedirect('edit', selectedEmployee?.id)
            setSeeMoreAnchor(null)
          }}>
          Ver perfil
        </MenuItem>
        <MenuItem disabled>Dar de baja (proximamente)</MenuItem>
      </Menu>

      {/* Upload Billing File  */}
      <BillingFileUploaderContainer
        workspaceId={workspaceId}
        open={showCSVUploadDialog}
        onCancel={() => setShowCSVUploadDialog(false)}
      />
    </>
  )
}

export default EmployeesContainer
