import _ from 'lodash'
import RequestedDocument, {
  RequestedDocumentCatalogue,
} from 'models/documentRequest'
import { Role } from 'models/user'
import { USER_ROLE_NAMES } from 'lib/enums/user.enums'

export const ROUTES = {
  ROOT: '/',
  ACCOUNT: '/account',
  ADD_ACCOUNTING: '/accounting_process',
  ADD_CATEGORY: '/add_category',
  ADD_CLIENT: '/add_client',
  ADD_CREDIT_NOTE: '/invoice_details/:invoice/add_credit_note',
  ADD_EMPLOYEE: '/add_employee',
  ADD_INVOICE: '/add_invoice/:type',
  ADD_PAYMENT_COMPLEMENT: '/invoice_details/:invoice/add_payment_complement',
  ADD_PRODUCT: '/add_product',
  ADD_RAZON_SOCIAL: '/add_emisor',
  ADD_WORKSPACE: '/add_workspace',
  CATEGORY_DETAILS: '/category_details',
  CATEGORIES_PRODUCTS: '/categories_products',
  CLIENT_DETAILS: '/client_details',
  CLIENTS: '/clients',
  CONCILIATIONS: '/conciliations',
  DOCUMENTATION: '/documentation',
  DOCUMENT_REQUEST: '/document_request',
  EMPLOYEE_DETAILS: '/employee_details',
  EMPLOYEES: '/employees',
  HOME: '/home',
  INVOICES: '/invoices',
  LOGIN: '/login',
  MANAGEMENT: '/management',
  NEW_CREDIT_NOTE_FROM_INVOICE: '/invoice/new/credit_note',
  NEW_PAYMENT_COMPLEMENT_FROM_INVOICE: '/invoice/new/payment_complement',
  PAYMENTS: '/payments',
  PRODUCT_DETAILS: '/product_details',
  RAZON_SOCIAL_DETAILS: '/emisor_details',
  RAZONES_SOCIALES: '/emisores',
  RECOVERY: '/recovery',
  REGISTER: '/register',
  WORKSPACE_DETAILS: '/workspace_details',
  WORKSPACES: '/workspaces',
}

export const DEFAULT_LANGUAGE = 'es-MX'

export const ROLES: { [key: string]: Role } = {
  [USER_ROLE_NAMES.ADMINISTRADOR]: {
    id: 1,
    name: USER_ROLE_NAMES.ADMINISTRADOR,
    label: 'Administrador',
    enabled: true,
  },
  [USER_ROLE_NAMES.CONTADOR_SR]: {
    id: 2,
    name: USER_ROLE_NAMES.CONTADOR_SR,
    label: 'Contador Sr',
    enabled: true,
  },
  [USER_ROLE_NAMES.CONTADOR_JR]: {
    id: 3,
    name: USER_ROLE_NAMES.CONTADOR_JR,
    label: 'Contador Jr',
    enabled: true,
  },
  [USER_ROLE_NAMES.USER]: {
    id: 4,
    name: USER_ROLE_NAMES.USER,
    label: 'Usuario',
    enabled: true,
  },
  [USER_ROLE_NAMES.CONTADOR_MR]: {
    id: 5,
    name: USER_ROLE_NAMES.CONTADOR_MR,
    label: 'Contador Mr',
    enabled: true,
  },
}

export const SUBSCRIPTION_STATUS_PAYMENT_CATALOGUE = [
  { label: '- - -', value: 0 },
  { label: 'Pendiente', value: 1 },
  { label: 'Pagada', value: 2 },
]

export const SUBSCRIPTION_STATUS_INVOICE_CATALOGUE = [
  { label: '- - -', value: 0 },
  { label: 'Pendiente', value: 1 },
  { label: 'Listo', value: 2 },
]

export const STATUS_PAYMENT_CATALOGUE = [
  { label: 'Pendiente', value: 'pending', in: 'IE' },
  { label: 'Pagada parcialmente', value: 'part-paid', in: 'E' },
  { label: 'Pagada', value: 'paid', in: 'E' },
  { label: 'Cobrada parcialmente', value: 'part-charged', in: 'I' },
  { label: 'Cobrada', value: 'charged', in: 'I' },
  { label: 'Cancelada', value: 'canceled', in: 'N' },
  { label: 'Pendiente de cancelacion', value: 'pending-canceled', in: 'N' },
]

export const INVOICE_STATUS = {
  ACTIVE: { id: 1, value: 'active', label: 'Vigente' },
  CANCELED: { id: 2, value: 'canceled', label: 'Cancelada' },
  PENDING: { id: 3, value: 'pending', label: 'Pendiente' },
}

export const CLIENTS_FLAGS = {
  ARTICULO_69B: 1,
}

export const STATUS_PAYMENT = {
  PENDING: 'pending',
  PART_CHARGED: 'part-charged',
  CHARGED: 'charged',
  PART_PAID: 'part-paid',
  PAID: 'paid',
  CANCELED: 'canceled',
  PENDING_CANCELED: 'pending-canceled',
}

export const CANCELATION_REASONS_CATALOGUE = [
  {
    label:
      'Contiene datos incorrectos, como RFC, nombre o razón social, domicilio, entre otros',
    value: 1,
  },
  { label: 'No se realizó el pago de la factura', value: 2 },
  { label: 'Hay errores en el manejo de los conceptos', value: 3 },
  {
    label: 'Presenta inconsistencia en los importes, impuestos, entre otros',
    value: 4,
  },
  {
    label:
      'No se respetaron las condiciones comerciales pactadas entre el emisor y receptor',
    value: 5,
  },
]

export const CANCELATION_REASONS_CATALOGUE_V4 = [
  {
    label:
      'Comprobante emitido con errores con relación. Requiere Folio de Sustitución',
    value: 1,
  },
  { label: 'Comprobante emitido con errores sin relación', value: 2 },
  { label: 'No se llevó a cabo la operación', value: 3 },
  {
    label: 'Operación nominativa relacionada en una factura global.',
    value: 4,
  },
]

export const DOCUMENTS_TO_REQUEST_CATALOGUE: RequestedDocumentCatalogue = [
  {
    id: 0,
    label: 'Contabilidad',
    origins: ['accounting'],
    sections: [
      {
        id: 1,
        label: 'Contabilidad',
        list: [
          {
            id: 1,
            label: 'Balanzas de comprobación',
            format: 'Formato excel y PDF',
            section: 1,
          },
          {
            id: 2,
            label: 'Conciliaciones bancarias',
            format: 'Auxiliares Contpaq',
            section: 1,
          },
          {
            id: 3,
            label: 'Estados financieros',
            format: 'BG / ERI en PDF',
            section: 1,
          },
          {
            id: 4,
            label: 'Estados de cuenta bancarios',
            format: 'PDF s/ Banco',
            section: 1,
          },
          {
            id: 17,
            label: 'Estado de cuenta bancario',
            format: 'Excel',
            section: 1,
          },
          {
            id: 19,
            label: 'Estado de cuenta bancario',
            format: 'Archivo .XML',
            section: 1,
          },
          {
            id: 5,
            label: 'XML de ingresos',
            format: 'Archivo .TXT',
            section: 1,
          },
          {
            id: 6,
            label: 'XML de egresos',
            format: 'Archivo .TXT',
            section: 1,
          },
          {
            id: 7,
            label: 'Contabilidad electrónica',
            format: 'Formato .ZIP',
            section: 1,
          },
          { id: 8, label: 'Activos', format: 'Formato .ZIP', section: 1 },
          {
            id: 18,
            label: 'Aclaración de movimientos bancarios',
            format: 'Excel',
            section: 1,
          },
          { id: 20, label: 'Inventario', format: 'Excel', section: 1 },
        ],
      },
      {
        id: 2,
        label: 'Impuestos',
        list: [
          { id: 9, label: 'Papeles de trabajo', format: 'Excel', section: 2 },
          {
            id: 10,
            label: 'Declaraciones y pagos de impuestos mensuales',
            format: 'Portal del SAT',
            section: 2,
          },
          { id: 11, label: 'DIOT', format: 'Portal del SAT', section: 2 },
          {
            id: 12,
            label: 'Declaración y pago de impuestos anual',
            format: 'Portal del SAT',
            section: 2,
          },
          {
            id: 13,
            label: 'Papeles de trabajo anual',
            format: 'Excel',
            section: 2,
          },
          {
            id: 14,
            label: 'Acuse declaración anual',
            format: 'Portal del SAT',
            section: 2,
          },
        ],
      },
      {
        id: 3,
        label: 'Nómina',
        list: [
          { id: 15, label: 'Papeles de trabajo', format: 'Excel', section: 3 },
          {
            id: 16,
            label: 'Declaraciones y pagos de impuestos mensuales',
            format: 'Portal del SAT',
            section: 3,
          },
          { id: 21, label: 'Incidencias', format: 'Excel', section: 3 },
        ],
      },
    ],
  },
]

export const months: Record<number, string> = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
}

const flatDocumentToRequestCatalogue = (): RequestedDocument[] => {
  let documents: RequestedDocument[] = []
  _.forEach(DOCUMENTS_TO_REQUEST_CATALOGUE, tab => {
    _.forEach(tab.sections, section => {
      let dl = _.map(section.list, document => ({
        ...document,
        section: section.id,
        tab: tab.id,
        origins: tab.origins,
      }))
      documents = [...documents, ...dl]
    })
  })
  return documents
}

export const FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE =
  flatDocumentToRequestCatalogue()

// taxes
export const EXENT_TAX = 12
export const EXENT_IVA_TAX = 10
export const TAXES_FACTORS = {
  rate: 'Tasa',
  fee: 'Cuota',
  exent: 'Exento',
}

export const CONCILIATION_REPORT_TYPE = {
  ledger: 'Cuenta contable',
  paper: 'Papel de trabajo',
}

export const CONCILIATION_ACCEPTED_FORMATS =
  'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const CONCILIATION_LEDGER_ACCEPTED_FORMATS =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
export const CONCILIATION_CFDI_ACCEPTED_FORMATS = 'application/xml, text/xml'
export const CONCILIATION_EXCEL_ACCEPTED_FORMATS =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const PDF_ACCEPTED_FORMATS = 'application/pdf'

// 4MB
export const CONCILIATION_MAX_FILESIZE = 15 * 1024 * 1024

// OCR PROCESS

export const ERRORS = {
  TRANSACTIONS_ERROR: 1,
  EXISTING_DOCUMENT: 2,
  UNKNOWN_FORMAT: 3,
  CONNECTION_ERROR: 4,
}

export const ERROR_LABELS: Record<number, string> = {
  1: 'No se completó correctamente la lectura de todos los movimientos, intenta nuevamente, si persiste el problema intenta con una mejor calidad del documento.',
  2: 'Este documento ha sido descartado porque ya se había agregado anteriormente',
  3: 'Tuvimos un problema con el archivo, las instituciones actuales para procesar los datos son: BBVA, Banamex, Banorte, Scotiabank y Santander, por favor intenta nuevamente',
  4: 'Tuvimos un problema al importar el archivo, por favor intenta nuevamente',
}

export const OCR_DRAWER_MODES = {
  statements: 'statements',
  CFDIs: 'CFDIs',
  ledger: 'ledger',
}

export const GENERIC_RFC = 'XEXX010101000'

export const CIF_ACCEPTED_FILES = 'application/pdf'

export const PERSONA_MORAL = 'PM'
export const PERSONA_FISICA = 'PF'

export const EXPIRED = 'expired'
export const WARNING = 'warning'
export const VALID = 'valid'

export const NPS_ACCEPTED_FILES = 'application/xlsx'

export const ACCOUNTING_STEP_FIVE_MAX_UPLOADS = 10

export const DEFAULT_TABLE_ITEMS_PER_PAGE = 15
