export const USER_ROLE_NAMES = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  CONTADOR_JR: 'CONTADOR_JR',
  CONTADOR_MR: 'CONTADOR_MR',
  CONTADOR_SR: 'CONTADOR_SR',
  USER: 'USER',
} as const

export const USER_ROLE_NAMES_MAP = {
  [USER_ROLE_NAMES.ADMINISTRADOR]: 'Contador',
  [USER_ROLE_NAMES.CONTADOR_JR]: 'Contador',
  [USER_ROLE_NAMES.CONTADOR_MR]: 'Contador',
  [USER_ROLE_NAMES.CONTADOR_SR]: 'Contador',
  [USER_ROLE_NAMES.USER]: 'Cliente',
} as const
