import { useIntl } from 'react-intl'
import { GridColDef } from '@material-ui/data-grid'
import { IPlanInformation, TPlanPaymentStatus } from 'models/payments'
import CreditCard from 'assets/credit_card.svg'
import CreditCardOff from 'assets/credit_card_off.svg'
import MoneyOff from 'assets/money_off.svg'
import { PLAN_PAYMENT_STATUS } from 'lib/enums/payments.enums'
import {
  PLAN_PAYMENT_STATUS_CLASSNAME_MAP,
  PLAN_PAYMENT_STATUS_LABEL_MAP,
} from 'lib/utils/payments.utils'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import Activity from 'components/Shared/Activity/Activity'
import Button from 'components/Shared/Button/Button'
import SydTable from 'components/Shared/SydTable/SydTable'
import './styles.scss'

const BillingInformation = () => {
  const {
    isBillingInformationLoading,
    billingInformation,
    openPlanUpdateModal,
    handleSelectRecurrentPlan,
    handleSelectPlan,
  } = usePaymentsContainerContext()

  const intl = useIntl()

  const billingInformationTableColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Plan',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { name, description } }) => (
        <div className="plan-description">
          <span>{name}</span>
          <span>[{description}]</span>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Estatus',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { status } }) => (
        <div className="payment-status">
          <span
            className={`bullet ${PLAN_PAYMENT_STATUS_CLASSNAME_MAP[status as TPlanPaymentStatus]}`}
          />

          <span>
            {PLAN_PAYMENT_STATUS_LABEL_MAP[status as TPlanPaymentStatus]}
          </span>
        </div>
      ),
    },
    {
      field: 'dateLimit',
      headerName: 'Fecha límite de pago',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { dateLimit } }) => <div>{dateLimit}</div>,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      minWidth: 160,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { amount } }) => (
        <div>
          {intl.formatNumber(parseFloat(amount), {
            style: 'currency',
            currency: 'MXN',
            currencyDisplay: 'narrowSymbol',
          })}
        </div>
      ),
    },
    {
      field: 'recurrent',
      headerName: 'Domiciliación',
      minWidth: 210,
      flex: 1,
      sortable: false,
      renderCell: ({ row: plan }) => {
        const isPlanExpired =
          (plan.status as TPlanPaymentStatus) === PLAN_PAYMENT_STATUS.VENCIDO

        return (
          <div className="recurrent-payment-options">
            {!isPlanExpired && !plan.recurrent && (
              <Button
                startIcon={<img src={CreditCard} alt="tarjeta de crédito" />}
                onClick={() =>
                  handleSelectPlan(plan as IPlanInformation, true)
                }>
                Domiciliar
              </Button>
            )}
            {!isPlanExpired && plan.recurrent && (
              <Button
                onClick={() =>
                  handleSelectRecurrentPlan({
                    id: plan.idPlan,
                    concept: plan.name,
                    description: plan.description,
                    amount: plan.amount,
                    annotations:
                      'Este servicio se cobra automáticamente en cada periodo con la siguiente tarjeta bancaria:',
                    lastDigits: `****${plan.last4}`,
                  })
                }
                startIcon={
                  <img
                    src={CreditCardOff}
                    alt="tarjeta de crédito con una raya"
                  />
                }>
                Cancelar domiciliación
              </Button>
            )}
            {isPlanExpired && (
              <div className="not-available">
                <img src={MoneyOff} alt="Símbolo de peso con una raya" />
                <span>N/A</span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 190,
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: ({ row: plan }) => {
        const isPlanPaid =
          (plan.status as TPlanPaymentStatus) === PLAN_PAYMENT_STATUS.PAGADO

        return (
          <div className="payment-actions">
            {plan.recurrent ? (
              <span>Domiciliado</span>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                disabled={isPlanPaid}
                onClick={() => handleSelectPlan(plan as IPlanInformation)}>
                Pagar
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="billing-information-component">
      {isBillingInformationLoading && (
        <Activity className="billing-information-loader" local />
      )}
      {!isBillingInformationLoading && (
        <>
          <article className="header">
            <div className="header-content period">
              <span>Periodo</span>
              <span>
                {billingInformation
                  ? `${billingInformation.startPeriod} - ${billingInformation.endPeriod}`
                  : `---`}
              </span>
            </div>
            <div className="header-content balance">
              <span>Saldo a pagar</span>
              <span>
                {Intl.NumberFormat('es-MX', {
                  currency: 'MXN',
                  style: 'currency',
                }).format(billingInformation?.totalAmount ?? 0)}
              </span>
            </div>
          </article>
          <hr />
          <article className="contracted-plans-header">
            <p>Planes contratados</p>
            <Button
              color="secondary"
              variant="outlined"
              onClick={openPlanUpdateModal}>
              Quiero cambiar mi plan
            </Button>
          </article>
          <SydTable
            className="billing-information-table"
            columns={billingInformationTableColumns}
            rowCount={billingInformation?.plans.length ?? 0}
            rows={billingInformation?.plans ?? []}
            checkboxSelection={false}
            disableColumnMenu
            noBorder
            noDataText="No hay información de pagos para mostrar"
          />
        </>
      )}
    </div>
  )
}

export default BillingInformation
