import Employee from "models/employee";
import { ReduxAction } from "models/thunk";

export const SET_EMPLOYEES_LIST = '@Employees/SET_EMPLOYEES_LIST';
export const SET_SELECTED_EMPLOYEE = '@Employees/SET_SELECTED_EMPLOYEE';
export const SET_FILTER_VALUE = '@Employees/SET_FILTER_VALUE';
export const SET_PAGINATION_VALUE = '@Employees/SET_PAGINATION_VALUE';
export const RESET_FILTER = '@Employees/RESET_FILTER';

// #region Acciones persistir informacion para de los Empleados
export const setEmployeesList = (list: Employee[]): ReduxAction<Employee[]> => ({
    type: SET_EMPLOYEES_LIST,
    value: list
});
export const setSelectedEmployee = (employee: Employee | null = null): ReduxAction<Employee | null> => ({
  type: SET_SELECTED_EMPLOYEE,
  value: employee
});
export const setPaginationValue = (name: string, value: any): ReduxAction<{ name: string, value: any }> => ({
  type: SET_PAGINATION_VALUE,
  value: { name, value }
});
// #endregion

// #region Acciones persistir informacion para el filtrado de Empleados
export const setFilterValue = (name: string, value: any): ReduxAction<{ name: string, value: any }> => ({
  type: SET_FILTER_VALUE,
  value: { name, value }
});
export const resetFilter = (): ReduxAction<undefined> => ({
  type: RESET_FILTER,
  value: undefined
});
// #endregion

