import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/Shared/Button/Button'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import NewCFDIDialog from 'components/Shared/NewCFDIDialog/NewCFDIDialog'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import InvoicesContainer from 'containers/Invoices/InvoicesContainer/InvoicesContainer'

import useEmisoresList from 'hooks/queries/emisores'
import { useCountToSync, useSyncWithSat } from 'hooks/queries/invoices'

import { setSelectedEmisor, setSyncFormValue } from 'actions/invoices'
import {
  selectActiveWorkspace,
  selectCatalogue,
  selectProfile,
  selectSelectedEmisor,
} from 'lib/helpers/selectors'

import { getYearList } from 'lib/helpers/utilities'
import { ROUTES } from '../../../constants'

import Add from '@material-ui/icons/Add'
import Sync from '@material-ui/icons/Sync'
import './style.scss'

import { SyncForm } from 'models/invoice'
import { CatalogueEntry } from 'models/catalogues'

interface Props {}

const InvoicesScreen: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const loader = useLoader()

  const navigate = useNavigate()

  const workspaceId = useSelector(selectActiveWorkspace)
  const emisor = useSelector(selectSelectedEmisor)

  const profile = useSelector(selectProfile)
  const years = getYearList(profile?.createdAt)
  const months = useSelector(selectCatalogue('meses'))
  const tipoComprobantes = useSelector(selectCatalogue('tiposComprobantes'))

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [openNewDialog, setOpenNewDialog] = useState(false)
  const [openSyncDialog, setOpenSyncDialog] = useState(false)

  const emisoresQuery = useEmisoresList(workspaceId, {
    noSave: true,
    onSuccess: rfcs => {
      if (!emisor) {
        rfcs.length > 0
          ? dispatch(setSelectedEmisor(rfcs[0]))
          : dispatch(setSelectedEmisor(undefined))
      }
    },
    onError: () => onRouteChange('error'),
  })

  const syncMutation = useSyncWithSat(workspaceId, emisor)

  const { displayCount, active, form } = useCountToSync(workspaceId, form =>
    syncMutation.mutate(form)
  )

  useEffect(() => {
    emisoresQuery.isLoading
      ? loader.primary('Cargando emisores')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emisoresQuery.isLoading])

  useEffect(() => {
    dispatch(setSelectedEmisor(undefined))
  }, [dispatch, workspaceId])

  const onRouteChange = (action: string, id: number = 0, version?: number) => {
    if (action === 'back') navigate(-1)
    if (action === 'error') navigate(ROUTES.HOME, { replace: true })
    if (action === 'addComplement') {
      let route = _.replace(ROUTES.ADD_PAYMENT_COMPLEMENT, ':invoice', `${id}`)
      if (version) route = `${route}?version=${version}`
      navigate(route)
    }
    if (action === 'addCreditNote') {
      let route = _.replace(ROUTES.ADD_CREDIT_NOTE, ':invoice', `${id}`)
      if (version) route = `${route}?version=${version}`
      navigate(route)
    }
  }

  return (
    <>
      <Grid container spacing={0} className="screen-invoices">
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className="title">Mis CFDIs</h1>
            {/* <div className="emisor">
            <strong>{emisor?.name || "Selecciona emisor"}</strong>
            <IconButton 
              id="openSelectEmisorBtn"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              disabled={emisoresQuery.isLoading || emisoresQuery.isFetching}
            >
              {(emisoresQuery.isLoading || emisoresQuery.isFetching) 
                ? <CircularProgress size={16} color="primary" />
                : <Down />
              }
            </IconButton>
          </div> */}
            <div className="title-actions">
              <Button
                id="openSyncCfdisDialog"
                variant="contained"
                color="primary"
                startIcon={<Sync />}
                onClick={() => setOpenSyncDialog(true)}
                activity={syncMutation.isLoading || active}>
                {active ? displayCount : 'Sincronizar'}
              </Button>
              <Button
                id="openNewCfdiDialog"
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => setOpenNewDialog(true)}>
                Nuevo CFDI
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {!(emisoresQuery.isLoading || emisoresQuery.isError) && (
            <InvoicesContainer
              workspaceId={workspaceId}
              emisor={emisor || null}
              onRedirect={onRouteChange}
            />
          )}
        </Grid>
      </Grid>

      <NewCFDIDialog
        open={openNewDialog}
        onClose={() => setOpenNewDialog(false)}
      />

      {/* Sync Dialog */}
      <SydDialog
        open={openSyncDialog}
        className="dialog-sync-invoices"
        title="Sincronizar con el SAT"
        subtitle="Para realizar la sincronización de CFDI's puedes configurar los siguientes datos:"
        primaryButtonText="Sincronizar"
        disablePrimaryButton={
          _.isNil(form.year) && _.isNil(form.month) && _.isNil(form.type)
        }
        secondaryButtonText="Cerrar"
        onCancel={() => {
          setOpenSyncDialog(false)
          dispatch(setSyncFormValue('year', undefined))
          dispatch(setSyncFormValue('month', undefined))
          dispatch(setSyncFormValue('type', undefined))
        }}
        form="syncForm"
        sideButtons>
        <Form
          id="syncForm"
          onSubmit={async (form: SyncForm) => {
            syncMutation.mutate(form)
            setOpenSyncDialog(false)
          }}
          onValidity={() => true}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <AutocompleteField
                name="year"
                label={'Año'}
                catalogue={years}
                value={form?.year}
                onValue={(v: CatalogueEntry) =>
                  dispatch(setSyncFormValue('year', v))
                }
                getOptionSelected={(
                  option: CatalogueEntry,
                  value: CatalogueEntry
                ) => option.value === value.value}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteField
                name="month"
                label={'Mes'}
                catalogue={_.slice(months, 0, 12)}
                value={form?.month}
                onValue={(v: CatalogueEntry) =>
                  dispatch(setSyncFormValue('month', v))
                }
                getOptionSelected={(
                  option: CatalogueEntry,
                  value: CatalogueEntry
                ) => option.value === value.value}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteField
                name="type"
                label={'Ingreso/Egreso'}
                catalogue={_.filter(
                  tipoComprobantes,
                  c => ['I', 'E'].indexOf(c.value) > -1
                )}
                value={form?.type}
                onValue={(v: CatalogueEntry) =>
                  dispatch(setSyncFormValue('type', v))
                }
                getOptionLabel={(o: CatalogueEntry) =>
                  `${o.value} - ${o.label}`
                }
                renderOption={(o: CatalogueEntry) => (
                  <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
                )}
                getOptionSelected={(
                  option: CatalogueEntry,
                  value: CatalogueEntry
                ) => option.idCatalogValues === value.idCatalogValues}
                fullWidth
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>

      {/* Select Emisor's Menu */}
      <Menu
        id="selectEmisorMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className="screen-emisor-list">
        {_.map(emisoresQuery.data, (e, i) => (
          <MenuItem
            key={i}
            className="screen-emisor-element"
            disabled={emisor?.idRfc === e.idRfc}
            selected={emisor?.idRfc === e.idRfc}
            onClick={() => {
              dispatch(setSelectedEmisor(e))
              setAnchorEl(null)
            }}>
            <span className="name">{e.name}</span>
            <span className="rfc">{e.rfc}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default InvoicesScreen
