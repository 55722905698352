import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Button from 'components/Shared/Button/Button';
import SydDialog from 'components/Shared/SydDialog/SydDialog';
import './styles.scss';

import { ROUTES } from '../../../constants';

interface NewCFDIDialogProps {
    open: boolean
    onClose: () => void
}

const NewCFDIDialog: React.FC<NewCFDIDialogProps> = (props) => {
    const navigate = useNavigate();

    const changeRoute = (route: string) => {
        navigate(route);
        props.onClose();
    }

    return (
        <SydDialog
            className='new-cfdi-dialog'
            open={props.open}
            title={"Nuevo CFDI"}
            subtitle={"Para comenzar, selecciona el tipo de comprobante."}
            hidePrimaryButton
            secondaryButtonText="Cancelar"
            onCancel={props.onClose}
        >
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Button 
                        id="newCfdi33Btn"
                        color="primary"
                        variant="link"
                        onClick={() => {
                            const route = _.replace(ROUTES.ADD_INVOICE, ":type", "income");
                            changeRoute(route);
                        }}
                        fullWidth
                        disabled
                        className="dialog-button"
                    >
                        Comprobante de ingresos 3.3
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button 
                        id="newCfdi40Btn"
                        color="primary"
                        variant="link"
                        onClick={() => {
                            const route = _.replace(ROUTES.ADD_INVOICE, ":type", "income");
                            changeRoute(`${route}?version=4.0`);
                        }}
                        fullWidth
                        className="dialog-button"
                    >
                        Comprobante de ingresos 4.0
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        id="newNominaBtn" 
                        color="primary"
                        variant="link"
                        onClick={() => {}}
                        disabled={process.env.REACT_APP_ENVIRONMENT !== "development"}
                        fullWidth
                        className="dialog-button"
                    >
                        Nómina (proximamente)
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button 
                        id="newTrasladoBtn"
                        color="primary"
                        variant="link"
                        onClick={() => {}}
                        disabled={process.env.REACT_APP_ENVIRONMENT !== "development"}
                        fullWidth
                        className="dialog-button"
                    >
                        Traslado (proximamente)
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button 
                        id="newCreditNote"
                        color="primary"
                        variant="link"
                        onClick={() => changeRoute(ROUTES.NEW_CREDIT_NOTE_FROM_INVOICE)}
                        fullWidth
                        className="dialog-button"
                    >
                        Nota de crédito
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        id="newPaymentComplement"
                        color="primary"
                        variant="link"
                        onClick={() => changeRoute(ROUTES.NEW_PAYMENT_COMPLEMENT_FROM_INVOICE)}
                        fullWidth
                        className="dialog-button"
                    >
                        Complemento de pago
                    </Button>
                </Grid>
            </Grid>
        </SydDialog>
    )
}

export default NewCFDIDialog;