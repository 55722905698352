import update from 'immutability-helper';
import { 
	SET_RAZONES_SOCIALES_LIST,
    SET_SELECTED_RAZON_SOCIAL
} from 'actions/razonesSociales';

/** interfaces */
import { RazonesSocialesStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialClients: RazonesSocialesStore = {
    form: {
        name: "",
        rfc: "",
        type: null,
        taxSystem: null,
        zipCode: "",
        taxAddress: "",
        fielCer: null,
        fielKey: null,
        passwordFiel: ""
    },
    list: [],
    active: 0,
    selected: null
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: ""
}

const razonesSociales = (state = initialClients, action = defaultAction) => {
    switch (action.type) {
        case SET_RAZONES_SOCIALES_LIST:
            return update(state, { list: { $set: action.value }});
        case SET_SELECTED_RAZON_SOCIAL:
            return update(state, { selected: { $set: action.value }});
        default:
            return state
    }
}

export default razonesSociales;