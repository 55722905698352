import NPSButton from '../NPSButton/NPSButton'
import { Box } from '@material-ui/core'
import { FC, useCallback, useMemo, useState } from 'react'
import './styles.scss'
import { UploadedFile } from 'models/general'
import { NPSFileResponse } from 'models/management.models'
import { useFetchNpsOcr, useUploadNPSFile } from 'hooks/queries/management'
import useDialog from 'hooks/Shared/useDialog'
import useGetAccountants from 'hooks/Management/Accountants/useGetAccountants'
import { selectSelectedWorkspace } from 'lib/helpers/selectors'
import { useSelector } from 'react-redux'
import SuccessI from '@material-ui/icons/CheckCircle'
import SearchAccountants from '../SearchAccountants/SearchAccountants'
import { useAccountantsContext } from '../AccountantsContextProvider/AccountantsContextProvider'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const NPSSection: FC = () => {
  const workspace = useSelector(selectSelectedWorkspace)
  const { refetch, fetchAccountants } = useGetAccountants()
  const wid = useMemo<number>(
    () => (workspace !== null ? workspace.id : 0),
    [workspace]
  )
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<UploadedFile | undefined>()

  const { page, elements } = useAccountantsContext()

  const dialog = useDialog()

  const { mutate: fetchNpsOcr, isLoading: npsOcrIsLoading } = useFetchNpsOcr(
    wid,
    {
      onSuccess: async data => {
        setIsOpen(false)
        setFile(undefined)
        dialog.primary({
          title: 'Exitoso!',
          subtitle: 'Se subió correctamente el archivo NPS.',
          icon: SuccessI as TModalIcon,
          primaryButtonText: 'OK',
        })

        await refetch()
      },
    }
  )

  const { mutate: uploadNPSFileMutate, isLoading } = useUploadNPSFile(wid, {
    onSuccess: (data: NPSFileResponse) => {
      fetchNpsOcr(data.uuid)
    },
  })

  const handleSubmit = useCallback(
    (file: UploadedFile) => {
      const base64 = file.base64
      const splitBase64 = base64.split(',')
      uploadNPSFileMutate({
        documents: [
          {
            file: splitBase64[1],
            name: file.name,
          },
        ],
      })
    },
    [uploadNPSFileMutate]
  )

  const onSubmit = useCallback(
    async (param: any) => {
      fetchAccountants(page, elements, param.param)
    },
    [fetchAccountants, page, elements]
  )

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={'center'}
      marginBottom={4}
      className="nps-section-component">
      <SearchAccountants onSubmit={onSubmit} />
      <NPSButton
        handleSumbit={handleSubmit}
        isLoading={isLoading || npsOcrIsLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFile={setFile}
        file={file}
      />
    </Box>
  )
}

export default NPSSection
