import {
  useState,
  createContext,
  useMemo,
  useCallback,
  PropsWithChildren,
} from 'react'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import SydDialog, { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

export type TDialogVariant =
  | 'primary'
  | 'danger'
  | 'primaryAsync'
  | 'dangerAsync'

export interface IDialogProviderState {
  isOpen: boolean
  variant?: TDialogVariant
  size?: 'sm'
  icon?: TModalIcon
  title?: string
  subtitle?: string
  message?: string
  modalContent?: JSX.Element
  hidePrimaryButton?: boolean
  sideButtons?: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  loadingPrimaryButton?: boolean
  loadingSecondaryButton?: boolean
  onPrimaryClick?: () => void
  onClose?: () => void
  onSecondaryClick?: () => void
}

type TOpenDialog = (
  stateProps: Partial<IDialogProviderState>
) => Promise<boolean> | undefined

export interface IDialogContext {
  primary: TOpenDialog
  danger: TOpenDialog
  primaryAsync: TOpenDialog
  dangerAsync: TOpenDialog
  close: () => void
}

export const DialogContext = createContext<IDialogContext | null>(null)

const DialogProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, setState] = useState<IDialogProviderState>({
    isOpen: false,
  })

  const {
    isOpen,
    variant,
    size,
    icon,
    title,
    subtitle,
    message,
    modalContent,
    hidePrimaryButton,
    sideButtons,
    primaryButtonText,
    secondaryButtonText,
    loadingPrimaryButton,
    loadingSecondaryButton,
    onClose,
    onPrimaryClick,
    onSecondaryClick,
  } = state

  const updateState = useUpdateStateFunction<IDialogProviderState>(setState)

  const resetState = useCallback(
    () =>
      updateState({
        icon: undefined,
        title: undefined,
        subtitle: undefined,
        message: undefined,
        modalContent: undefined,
        hidePrimaryButton: undefined,
        primaryButtonText: undefined,
        secondaryButtonText: undefined,
      }),
    [updateState]
  )

  /* 
     For specific cases of back to back modals with different content (mutations/queries with loading modal and error modal for example),
     calculatin whether to reset the content immediately of after the modal is closed within the close function does not work.
  */

  const immediateClose = () => {
    updateState({
      isOpen: false,
    })
    resetState()
  }

  const close = useCallback(() => {
    updateState({
      isOpen: false,
    })
    setTimeout(() => {
      resetState()
    }, 300)
  }, [updateState, resetState])

  const open = (
    variant: TDialogVariant,
    stateProps: Partial<IDialogProviderState>,
    isAsync: boolean = false
  ) => {
    const { onPrimaryClick, onSecondaryClick } = stateProps

    immediateClose()

    if (isAsync) {
      return new Promise<boolean>(resolve => {
        updateState({
          ...stateProps,
          isOpen: true,
          variant,
          onPrimaryClick: () => {
            immediateClose()
            resolve(true)
          },
          onSecondaryClick: () => {
            close()
            resolve(false)
          },
        })
      })
    }

    updateState({
      ...stateProps,
      isOpen: true,
      variant,
      onClose: stateProps.onClose
        ? () => {
            stateProps.onClose && stateProps.onClose()
            close()
          }
        : undefined,
      onPrimaryClick: () => {
        onPrimaryClick && onPrimaryClick()
        close()
      },
      onSecondaryClick: () => {
        onSecondaryClick && onSecondaryClick()
        close()
      },
    })
  }

  const openDialogWithVariant =
    (variant: TDialogVariant, isAsync = false) =>
    (stateProps: Partial<IDialogProviderState>) =>
      open(variant, stateProps, isAsync)

  const primary = openDialogWithVariant('primary')
  const danger = openDialogWithVariant('danger')

  const primaryAsync = openDialogWithVariant('primary', true)
  const dangerAsync = openDialogWithVariant('danger', true)

  const dialogContextValue = useMemo(
    (): IDialogContext => ({
      primary,
      danger,
      primaryAsync,
      dangerAsync,
      close,
    }),
    [close, primary, danger, primaryAsync, dangerAsync]
  )

  return (
    <DialogContext.Provider value={dialogContextValue}>
      <SydDialog
        open={isOpen}
        variant={variant}
        size={size}
        icon={icon}
        title={title}
        subtitle={subtitle}
        message={message}
        hidePrimaryButton={hidePrimaryButton}
        sideButtons={sideButtons}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        loadingPrimaryButton={loadingPrimaryButton}
        loadingSecondaryButton={loadingSecondaryButton}
        onClose={onClose}
        onConfirm={onPrimaryClick}
        onCancel={onSecondaryClick}>
        {modalContent}
      </SydDialog>
      {children}
    </DialogContext.Provider>
  )
}

export default DialogProvider
