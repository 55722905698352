import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { QueryOptions } from 'models/general'
import { IAccountingTask } from 'models/accounting.models'
import useDialog from 'hooks/Shared/useDialog'
import { setAccountingTasks } from 'store/Accounting/accounting.reducer'
import { IApiError } from 'services/Shared/shared.models'
import { getAccountingTasks } from 'services/Accounting/accounting.services'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useGetAccountingTasks = (
  workspaceId: number,
  activeDateRange: Date,
  options: QueryOptions<IAccountingTask[], IAccountingTask[], IApiError> = {
    noDialog: false,
  }
) => {
  const dialog = useDialog()
  const dispatch = useDispatch()

  const { noDialog = false } = options

  const query = useQuery<IAccountingTask[], IApiError>(
    [`get-task-to-complete`, workspaceId, activeDateRange],
    () => {
      return getAccountingTasks(
        workspaceId,
        activeDateRange.getMonth(),
        activeDateRange.getFullYear()
      )
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        dispatch(setAccountingTasks(data))
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la lista de tareas a completar',
            icon: ErrorOutlineIcon as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          }
        }
      },
    }
  )

  return query
}

export default useGetAccountingTasks
