import { Box, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import { useManagementWorkspacesContext } from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import './styles.scss'

const ManagementWorkspacesTableFooter = () => {
  const {
    loading,
    managementWorkspacesLoading,
    page,
    totalPages,
    totalResults,
    size,
    list,
    fetchManagementWorkspaces,
  } = useManagementWorkspacesContext()

  const canNextPage = !(page < totalPages)

  const canPrevPage = !(page > 1)

  const handleNextPage = () => {
    if (page < totalPages) {
      fetchManagementWorkspaces(page + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 1) {
      fetchManagementWorkspaces(page - 1)
    }
  }

  const handleFirstPage = () => {
    fetchManagementWorkspaces(1)
  }

  const handleLastPage = () => {
    fetchManagementWorkspaces(totalPages)
  }

  const initialLoading =
    (loading || managementWorkspacesLoading) && !list.length

  const currentFirstItemIndex = initialLoading
    ? 0
    : page === 1
      ? 1
      : (page - 1) * size + 1
  const currentLastItemIndex = initialLoading
    ? 0
    : page === totalPages
      ? totalResults
      : page * size

  const label = `${currentFirstItemIndex} - ${currentLastItemIndex} de ${initialLoading ? 0 : totalResults}`

  return (
    <Box className="table-footer">
      <Box className="info">{label}</Box>
      <Box className="actions">
        <IconButton
          onClick={handleFirstPage}
          disabled={canPrevPage}
          color="secondary"
          size="small">
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handlePrevPage}
          disabled={canPrevPage}
          color="secondary"
          size="small">
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={handleNextPage}
          disabled={canNextPage}
          color="secondary"
          size="small">
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          onClick={handleLastPage}
          disabled={canNextPage}
          color="secondary"
          size="small">
          <LastPageIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ManagementWorkspacesTableFooter
