import { FC, useMemo } from 'react'
import _ from 'lodash'
import { parseTransactions } from 'lib/helpers/utilities'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import GridTableFooter from '../GridTableFooter/GridTableFooter'
import './styles.scss'

const AccountMovementsTableFooter: FC = () => {
  const { statements } = useConciliationsContainerContext()

  const { statementRows, selectedStatementRows, activeStatementRow } =
    statements

  const rows = useMemo(() => {
    const filtered = _.find(
      statementRows,
      row => row.idFileBankStatement === activeStatementRow
    )
    return parseTransactions(_.get(filtered, 'transactions', []))
  }, [statementRows, activeStatementRow])

  const hasRows = useMemo(() => statementRows.length > 0, [statementRows])

  const actualSelected = useMemo(() => {
    return _.map(
      _.filter(
        rows,
        (row: any) =>
          selectedStatementRows.includes(row.id) && row.conciliated === false
      ),
      (row: any) => row.id
    )
  }, [selectedStatementRows, rows])

  if (!hasRows) {
    return null
  }

  return <GridTableFooter rows={rows} selectedRows={actualSelected} />
}

export default AccountMovementsTableFooter
