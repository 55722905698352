import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Box,
  Checkbox,
  Chip,
  LinearProgress,
  withStyles,
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSelectionModel,
} from '@material-ui/data-grid'
import SwapVertical from '@material-ui/icons/SwapVert'
import { OCRBankStatement } from 'models/redux'
import {
  CONCILIATION_ACCEPTED_FORMATS,
  CONCILIATION_MAX_FILESIZE,
} from '../../../constants'
import {
  parseTransactions,
  parseUploadedFiles,
  toPeriodString,
  useCustomDataGridStyle,
} from 'lib/helpers/utilities'
import {
  getActualSelectedCFDIs,
  getActualSelectedStatements,
} from 'hooks/useConciliate'
import useDialog from 'hooks/Shared/useDialog'
import { UploadArchiveToPeriodResponse } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import AccountMovementsTableFooter from '../AccountMovementsTableFooter/AccountMovementsTableFooter'
import ConciliationFileWrapper from '../ConciliationFileWrapper/ConciliationFileWrapper'
import './styles.scss'

export const OrangeCheckbox = withStyles({
  root: {
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const SelectFile = () => {
  const {
    workspaceInfo: { activeWorkspace },
    statements,
    conciliationPeriod,
    setDrawerFilelist,
  } = useConciliationsContainerContext()

  const { activeStatementRow } = statements

  const onUpload = async (data: UploadArchiveToPeriodResponse) => {
    const documents = parseUploadedFiles(data.documents)
    setDrawerFilelist({
      ocrMode: 'statements',
      documents,
      conciliation: data.idConciliation,
      onConfirm: () => {},
    })
  }

  return (
    <Box height={300}>
      <ConciliationFileWrapper
        dropzoneProps={{
          ...(() => {
            if (activeStatementRow !== null)
              return {
                title:
                  'Este estado de cuenta no contiene movimientos bancarios',
                subtitle:
                  'Selecciona otro estado de cuenta para comenzar a conciliar',
              }
            return {
              title: 'Por el momento no hay movimientos bancarios',
              subtitle:
                'Para comenzar, arrastra o selecciona estados de cuenta desde tu computadora',
            }
          })(),
          options: {
            accept: CONCILIATION_ACCEPTED_FORMATS,
            multiple: true,
            maxSize: CONCILIATION_MAX_FILESIZE,
          },
        }}
        uploadProps={{
          activePeriod: toPeriodString(conciliationPeriod),
          activeWorkspace,
        }}
        onUpload={onUpload}
      />
    </Box>
  )
}

// TODO: add undefined date validation

const AccountMovementsTable = () => {
  const { isLoadingStatements, statements, cfdis, ledgers, setDrawerMovement } =
    useConciliationsContainerContext()

  const {
    statementRows,
    selectedStatementRows,
    activeStatementRow,
    setSelectedConciliationStatementRows,
  } = statements

  const { cfdiActiveSection } = cfdis

  const { selectedLedgerRows } = ledgers

  const dispatch = useDispatch()
  const classes = useCustomDataGridStyle()

  const statementRowsData = useMemo(() => {
    const statement = _.find(statementRows, {
      idFileBankStatement: activeStatementRow,
    })
    const rows = _.get(statement, 'transactions', [])
    return parseTransactions(rows)
  }, [activeStatementRow, statementRows])

  useEffect(() => {
    if (_.isEmpty(selectedStatementRows)) {
      dispatch(
        setSelectedConciliationStatementRows(
          _.map(
            _.filter(statementRows, { conciliated: true }),
            (_: any) => _.id
          )
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statementRows /*, selectedStatementRows, dispatch, setSelectedConciliationStatementRows*/,
  ])

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Fecha',
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      valueFormatter: params => {
        return Intl.DateTimeFormat('es-MX', { dateStyle: 'short' }).format(
          new Date(String(params.value).replace(/-/g, '/'))
        )
      },
    },
    {
      field: 'name',
      headerName: 'Ordenante/Beneficiario',
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'concept',
      headerName: 'Concepto',
      minWidth: 200,
      editable: false,
      align: 'left',
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Box
            style={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            gridGap={4}
            onClick={() => setDrawerMovement(params.row)}>
            {(params.getValue(params.id, 'edited') as boolean) === true && (
              <Chip
                label="E"
                size="small"
                style={{ textDecoration: 'none' }}
                title="Este movimiento ha sido editado"
              />
            )}
            <Box style={{ textDecoration: 'underline' }}>{params.value}</Box>
          </Box>
        )
      },
    },
    {
      field: 'amount',
      headerName: 'Monto',
      type: 'number',
      align: 'right',
      disableColumnMenu: true,
      valueFormatter: params =>
        Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(params.value as number),
      renderCell(params) {
        return (
          <Box
            className="cell"
            display="flex"
            justifyContent="flex-end"
            color={
              (params.getValue(params.id, 'out') as boolean) === true
                ? 'black'
                : 'secondary.dark'
            }>
            {(params.getValue(params.id, 'out') as boolean) === true ? '-' : ''}
            {params.formattedValue}
          </Box>
        )
      },
    },
  ]

  const dialog = useDialog()

  const handleSelectionModelChange = async (params: GridSelectionModel) => {
    const selectedCFDIs = getActualSelectedCFDIs(cfdis)
    const selectedStatements = getActualSelectedStatements({
      ...statements,
      selectedStatementRows: params as number[],
    })
    if (
      selectedStatements.length >= 1 &&
      selectedLedgerRows.length > 1 &&
      cfdiActiveSection === 1
    ) {
      return await dialog.primaryAsync({
        subtitle: 'Sólo puedes conciliar con una cuenta contable a la vez',
        title: 'Acción no permitida',
        primaryButtonText: 'Aceptar',
      })
    }
    if (
      selectedCFDIs.length > 1 &&
      selectedStatements.length > 1 &&
      cfdiActiveSection === 0
    ) {
      return await dialog.primaryAsync({
        subtitle:
          'Sólo puedes seleccionar un movimiento bancario cuando varios CFDIs son seleccionados',
        title: 'Acción no permitida',
        primaryButtonText: 'Aceptar',
      })
    }
    dispatch(setSelectedConciliationStatementRows(params))
  }
  const getIsConcilied = (params: GridRowParams) => {
    const isConcilied = params.getValue(params.id, 'conciliated') as boolean
    return isConcilied
  }

  useEffect(() => {
    dispatch(
      setSelectedConciliationStatementRows(
        _.map(
          _.filter(statementRowsData, { conciliated: true }),
          (_: OCRBankStatement) => _.id
        )
      )
    )
  }, [statementRowsData, dispatch, setSelectedConciliationStatementRows])

  return (
    <Box className="account-movements-table-component">
      <DataGrid
        classes={classes}
        rows={statementRowsData}
        columns={columns}
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        density="compact"
        getRowClassName={params => {
          const isConcilied = getIsConcilied(params)
          return isConcilied ? 'concilied' : ''
        }}
        selectionModel={selectedStatementRows}
        onSelectionModelChange={handleSelectionModelChange}
        headerHeight={70}
        hideFooter
        loading={isLoadingStatements}
        style={{
          border: 'none',
          fontSize: 14,
        }}
        components={{
          NoRowsOverlay: () => <SelectFile />,
          ColumnSortedAscendingIcon: SwapVertical,
          ColumnSortedDescendingIcon: SwapVertical,
          ColumnResizeIcon: () => null,
          LoadingOverlay: LinearProgress,
          Checkbox: OrangeCheckbox,
        }}
      />
      <AccountMovementsTableFooter />
    </Box>
  )
}

export default AccountMovementsTable
