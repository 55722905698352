import React from 'react';
import MUICarousel from 'react-material-ui-carousel';

import './style.scss';

interface CarouselProps {

}

const indicatorContainerProps = {
    className: "indicators-container",
    style: {}
};

const indicatorIconButtonProps = {
    className: "indicator-button",
    style: {}
}

const activeIndicatorIconButtonProps = {
    className: "active",
    style: {}
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {    
    return (
        <div className="component-carousel">
            <MUICarousel
                className="mui-carousel"
                animation="slide" 
                indicatorContainerProps={indicatorContainerProps}
                indicatorIconButtonProps={indicatorIconButtonProps}
                activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
                navButtonsAlwaysInvisible
                autoPlay
            >
                {children}
            </MUICarousel>
        </div>
    )
}

export default Carousel;