import { SydIconsModel } from 'models/shared.models'

const XMLFileIcon = ({ color }: SydIconsModel) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24V22.5C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V16.5H3V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM5.2905 17.775H3.951L2.7165 19.9335H2.6625L1.4145 17.775H0.018L1.8585 20.7495L0 23.775H1.2915L2.571 21.6525H2.6235L3.8985 23.775H5.2605L3.3795 20.787L5.2905 17.775ZM6.7215 23.7735V19.7835H6.7785L8.2065 23.022H8.9805L10.3995 19.782H10.4565V23.7735H11.529V17.775H10.329L8.619 21.669H8.5815L6.87 17.775H5.661V23.7735H6.7215ZM13.7865 22.7625H16.3305V23.7735H12.6V17.775H13.7865V22.7625Z"
        fill={color ?? '#6885A0'}
      />
    </svg>
  )
}

export default XMLFileIcon
