/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { GENERIC_RFC, PERSONA_FISICA, PERSONA_MORAL } from '../../../constants'
import { selectCatalogue } from 'lib/helpers/selectors'
import _ from 'lodash'
import {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { boolean, object, string } from 'yup'
import { validations } from 'lib/helpers/ioValidations'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import './styles.scss'
import Alert from '@material-ui/lab/Alert'
import { UseMutationResult } from '@tanstack/react-query'
import Client from 'models/client'
import { ClientForm as ClientFormType } from 'models/client'
import CIFButton from '../CIFButton/CIFButton'

const ClientType: FC<{
  manualShrink: boolean
}> = ({ manualShrink }) => {
  const { register, watch, setValue } = useFormContext()
  const tiposPersona = useSelector(selectCatalogue('tiposPersona'))
  const currentType = watch('type')
  const [value, setCurrentValue] = useState<'PF' | 'PM' | null | undefined>(
    null
  )

  const handleChange = (data: ChangeEvent<any>) => {
    const value = data.target.value
    setCurrentValue(value)
    setValue('type', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  useEffect(() => {
    if (currentType !== value) {
      setCurrentValue(currentType)
    }
  }, [value, currentType])

  return (
    <FormControl fullWidth>
      <input type="hidden" {...register('type')} />
      <InputLabel
        id="personTypeLabel"
        shrink={manualShrink || !_.isEmpty(value)}>
        Tipo de persona
      </InputLabel>
      <Select
        labelId="personTypeLabel"
        value={value}
        onChange={handleChange}
        // native
        label="Tipo de persona">
        {/* <option value="" disabled>Tipo de persona</option> */}
        {_.map(tiposPersona, persona => (
          <MenuItem value={persona.value} key={persona.value}>
            {persona.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const IsForeign: FC = () => {
  const { register, watch } = useFormContext()
  const type = watch('type')

  return (
    <FormControlLabel
      control={<Checkbox {...register('foreign')} disabled={!type} />}
      label="Es extranjero"
    />
  )
}

const TaxPayerIDField: FC = () => {
  const { register, watch, formState, setValue } = useFormContext()

  const foreign = watch('foreign')

  useEffect(() => {
    if (!foreign) {
      setValue('taxPayerId', undefined, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      })
    }
  }, [foreign])

  if (!foreign) return null

  return (
    <FormControl fullWidth>
      <TextField
        placeholder="Tax Payer Identification Number"
        {...register('taxPayerId')}
        helperText={formState.errors.taxPayerId?.message}
      />
    </FormControl>
  )
}

const AliasField: FC = () => {
  const { register, watch } = useFormContext()
  const type = watch('type')
  return (
    <FormControl fullWidth>
      <TextField
        {...register('alias')}
        placeholder="Alias(opcional)"
        disabled={!type}
      />
    </FormControl>
  )
}

const NameField: FC = () => {
  const { register, watch } = useFormContext()
  const type = watch('type')

  return (
    <FormControl fullWidth>
      <TextField
        {...register('name')}
        placeholder="Nombre o razón social"
        disabled={!type}
      />
    </FormControl>
  )
}

const RFCField: FC = () => {
  const { register, watch, setValue, formState } = useFormContext()
  const type = watch('type')
  const foreign = watch('foreign')

  useEffect(() => {
    const opts = {
      shouldDirty: true,
      shouldTouch: true,
    }
    if (foreign) {
      setValue('rfc', GENERIC_RFC, opts)
    } else {
      setValue('rfc', null, opts)
    }
  }, [foreign])

  return (
    <FormControl fullWidth>
      <TextField
        {...register('rfc')}
        placeholder="RFC"
        disabled={!type}
        helperText={formState.errors.rfc?.message}
        error={!!formState.errors.rfc?.message}
      />
    </FormControl>
  )
}

const MailField: FC = () => {
  const { register, watch, formState } = useFormContext()
  const type = watch('type')

  return (
    <FormControl fullWidth>
      <TextField
        {...register('email')}
        placeholder="Correo electrónico"
        disabled={!type}
        error={!!formState.errors.email?.message}
        helperText={formState.errors.email?.message}
      />
    </FormControl>
  )
}

const PhoneField: FC = () => {
  const { register, watch, formState } = useFormContext()
  const type = watch('type')

  return (
    <FormControl fullWidth>
      {/* <TextField
        { ...register('phone') }
        placeholder="Teléfono (opcional)"
        disabled={!type}
        type="tel"
      /> */}
      <NumericField
        name="phone"
        placeholder={'Teléfono (opcional)'}
        validate={['isTelephone']}
        format="phone"
        fullWidth
        disabled={!type}
        inputProps={{ ...register('phone') }}
        error={!!formState.errors.phone?.message}
        helperText={formState.errors.phone?.message}
      />
    </FormControl>
  )
}

const ZipCodeField: FC = () => {
  const { register, watch, setValue, formState } = useFormContext()
  const type = watch('type')
  const foreign = watch('foreign')

  const handleUpdateZipCode = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    let zipCode = watch('zipCode') ?? ''

    if (key === 'Backspace') {
      zipCode = zipCode.slice(0, -1)
    } else if (!key.match(/[0-9]/) || zipCode.length >= 5) {
      e.preventDefault()
      return
    } else {
      zipCode += key
    }

    e.preventDefault()
    setValue('zipCode', zipCode, { shouldValidate: true })
  }

  return (
    <FormControl fullWidth>
      <TextField
        {...register('zipCode')}
        placeholder={foreign ? 'Código postal (opcional)' : 'Código postal'}
        error={!!formState.errors.zipCode?.message}
        helperText={formState.errors.zipCode?.message}
        disabled={!type}
        onKeyDown={handleUpdateZipCode}
      />
    </FormControl>
  )
}

const BranchNameField: FC = () => {
  const { register, watch } = useFormContext()
  const type = watch('type')

  return (
    <FormControl fullWidth>
      <TextField
        {...register('branchName')}
        placeholder="Nombre sucursal (opcional)"
        disabled={!type}
      />
    </FormControl>
  )
}

const ContactField: FC = () => {
  const { register, watch } = useFormContext()
  const type = watch('type')

  return (
    <FormControl fullWidth>
      <TextField
        {...register('contact')}
        placeholder="Contácto (opcional)"
        disabled={!type}
      />
    </FormControl>
  )
}

const TaxRegime: FC<{
  manualShrink: boolean
}> = ({ manualShrink }) => {
  const { register, watch, formState, setValue } = useFormContext()
  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))
  const [value, setCurrentValue] = useState<string | undefined>()
  const currentType = watch('taxResidence')

  const handleChange = (data: ChangeEvent<any>) => {
    const value = data.target.value
    setCurrentValue(value)
    setValue('taxResidence', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  useEffect(() => {
    if (currentType !== value) {
      setCurrentValue(currentType)
    }
  }, [value, currentType])

  const type = watch('type')

  const options = useMemo(() => {
    if (type === PERSONA_MORAL) return taxSystemMoral
    if (type === PERSONA_FISICA) return taxSystemFisica
    return []
  }, [type, taxSystemMoral, taxSystemFisica])

  return (
    <FormControl fullWidth>
      <Select
        {...register('taxResidence')}
        labelId="taxRegimeLabel"
        disabled={_.isEmpty(options)}
        value={value}
        onChange={handleChange}
        native>
        <option value="" disabled selected>
          Régimen fiscal
        </option>
        {_.map(options, option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormHelperText>{formState.errors.taxResidence?.message}</FormHelperText>
    </FormControl>
  )
}

const schema = object().shape({
  type: string().required(),
  foreign: boolean(),
  taxPayerId: string()
    .when('foreign', {
      is: true,
      then(schema) {
        return schema.required('Este campo es requerido')
      },
    })
    .when('foreign', {
      is: false,
      then(schema) {
        return schema.nullable()
      },
    }),
  alias: string(),
  name: string().required(),
  rfc: string()
    .required()
    .test('is-rfc', 'RFC no es valido', value => {
      if (validations.isGenericOrPersonRFC(value)) return true
      if (validations.isGenericOrCompanyRFC(value)) return true
      return false
    }),
  taxResidence: string().required('Debes seleccionar un régimen fiscal'),
  email: string()
    .email('El formato del correo es inválido')
    .required('El correo es requerido'),
  phone: string().test(
    'is-phone',
    'El formato del teléfono es inválido',
    value => {
      if (_.isEmpty(value)) return true
      return validations.isValidTelephone(value)
    }
  ),
  zipCode: string()
    .required('El código es requerido')
    .when('foreign', {
      is: true,
      then(schema) {
        return string()
      },
    })
    .test(
      'is-zipCode',
      'El formato de código postal es inválido',
      validations.isZipCode
    ),
  branchName: string(),
  constact: string(),
})

const SubmitAction: FC = () => {
  const { formState } = useFormContext()
  return (
    <Fragment>
      <Divider />
      <Box className="form-footer">
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!formState.isValid}
          className="submit-button">
          Agregar
        </Button>
      </Box>
    </Fragment>
  )
}

interface FormData {
  type: string
  foreign: boolean | undefined
  taxPayerId: string | undefined
  alias: string | undefined
  name: string
  rfc: string
  taxResidence: string
  email: string
  phone: string | undefined
  zipCode: string
  branchName: string | undefined
  constact: string | undefined
}

const FormHeader: FC<{ setExtractedInfo: (value: boolean) => void }> = ({
  setExtractedInfo,
}) => {
  return (
    <Fragment>
      <div className="content header">
        <div className="title">
          <p>
            Completa la siguiente información para agregar un nuevo cliente al
            catálogo
          </p>
        </div>
        <CIFButton setExtractedInfo={setExtractedInfo} />
      </div>
      <Divider />
    </Fragment>
  )
}

const AddClientForm: FC<{
  addMutation: UseMutationResult<Client, any, ClientFormType, unknown>
}> = ({ addMutation }) => {
  const tiposPersona = useSelector(selectCatalogue('tiposPersona'))
  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))
  const [extractedInfo, setExtractedInfo] = useState(false)

  const methods = useForm<FormData>({
    resolver: yupResolver(schema) as any,
    mode: 'all',
  })

  const normalizeObject = (data: FormData) => {
    const type = _.find(tiposPersona, { value: data.type })
    let taxResidence
    if (data.type === PERSONA_FISICA) {
      taxResidence = _.find(taxSystemFisica, { value: data.taxResidence })
    }

    if (data.type === PERSONA_MORAL) {
      taxResidence = _.find(taxSystemMoral, { value: data.taxResidence })
    }

    let body = {
      ...data,
      type,
      taxResidence,
    }
    return body
  }

  const onSubmit = (data: FormData) => {
    const body = normalizeObject(data)
    addMutation.mutate(body as ClientFormType)
  }

  const { isLoading } = addMutation

  return (
    <>
      <Backdrop
        open={isLoading}
        style={{
          zIndex: 99999,
        }}>
        <CircularProgress variant="indeterminate" size={32} color="secondary" />
      </Backdrop>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="form">
          <FormHeader setExtractedInfo={setExtractedInfo} />
          {extractedInfo && (
            <Box style={{ margin: '1.5rem', width: '45%' }}>
              <Alert color="success" icon={() => null}>
                CIF registrado correctamente, completa los datos faltantes para
                agregar al cliente al catálogo:
              </Alert>
            </Box>
          )}
          <Grid container spacing={4} className="fields">
            <Grid item xs={4}>
              <ClientType manualShrink={extractedInfo} />
            </Grid>
            <Grid item xs={4}>
              <IsForeign />
            </Grid>
            <Grid item xs={4}>
              <TaxPayerIDField />
            </Grid>
            <Grid item xs={4}>
              <AliasField />
            </Grid>
            <Grid item xs={4}>
              <NameField />
            </Grid>
            <Grid item xs={4}>
              <RFCField />
            </Grid>
            <Grid item xs={8}>
              <TaxRegime manualShrink={extractedInfo} />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <MailField />
            </Grid>
            <Grid item xs={4}>
              <PhoneField />
            </Grid>
            <Grid xs={4} />
            <Grid item xs={4}>
              <ZipCodeField />
            </Grid>
            <Grid item xs={4}>
              <BranchNameField />
            </Grid>
            <Grid item xs={4}>
              <ContactField />
            </Grid>
          </Grid>
          <SubmitAction />
        </form>
      </FormProvider>
    </>
  )
}

export default AddClientForm
