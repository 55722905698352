import React, { useEffect, useState, useMemo } from 'react'
import { useDidUpdate } from 'rooks'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import Activity from 'components/Shared/Activity/Activity'
import DropCard from 'components/Shared/DropCard/DropCard'
import FileCard, { NewFolderCard } from 'components/Shared/FileCard/FileCard'
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import { addToPath, resetPath, setPath } from 'actions/documentation'

import {
  selectDocumentationList,
  selectDocumentationPath,
} from 'lib/helpers/selectors'

import { cleanFilesNames, downloadFileFromData } from 'lib/helpers/utilities'
import { isUniqueString } from 'lib/helpers/ioValidations'

import './style.scss'

import useDocumentList, {
  useAddDocument,
  useAddFolder,
  useDeleteDocument,
  useDocument,
  useRenameDocument,
} from 'hooks/queries/documentation'
import ISydDocument from 'models/shared.models'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const DocumentsContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()
  const intl = useIntl()

  const path = useSelector(selectDocumentationPath)
  const list = useSelector(selectDocumentationList)

  const listForFolderNameValidation = useMemo(
    () =>
      _.map(
        _.filter(list, p => p.fileType === 1),
        d => d.name || d.path
      ),
    [list]
  )
  const listForFileNameValidation = useMemo(
    () =>
      _.map(
        _.filter(list, p => p.fileType === 2),
        d => d.name || d.originalName
      ),
    [list]
  )

  const [selectedFile, setSelectedFile] = useState<ISydDocument>()
  const [validDialog, setValidDialog] = useState(false)
  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false)
  const [openRenameFileDialog, setOpenRenameFileDialog] = useState(false)

  const listQuery = useDocumentList(workspaceId, path, {
    onError: () => onRedirect('error'),
    select: data =>
      _.orderBy(
        data,
        ['fileType', 'path', 'name', 'createdAt'],
        ['asc', 'asc', 'asc', 'asc']
      ),
  })

  const uploadQuery = useAddDocument(workspaceId, path, {
    onSuccess: () => listQuery.refetch(),
  })

  const downloadQuery = useDocument(workspaceId, selectedFile, {
    onSuccess: data => {
      downloadFileFromData(`${data.name}.${data.extension}`, data?.file || '')
      setSelectedFile(undefined)
    },
  })

  const addFolderMutation = useAddFolder(workspaceId, path, {
    onSuccess: () => {
      listQuery.refetch()
      setOpenNewFolderDialog(false)
      setValidDialog(false)
      setSelectedFile(undefined)
    },
  })

  const renameMutation = useRenameDocument(workspaceId, selectedFile, {
    onSuccess: () => {
      listQuery.refetch()
      setOpenRenameFileDialog(false)
      setValidDialog(false)
      setSelectedFile(undefined)
    },
  })

  const deleteMutation = useDeleteDocument(workspaceId, {
    onSuccess: () => listQuery.refetch(),
  })

  useEffect(() => {
    listQuery.isLoading
      ? loader.primary('Cargando documentación')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQuery.isLoading])
  useDidUpdate(() => {
    dispatch(resetPath())
  }, [workspaceId])

  // #region Transitional Functions
  const onClickFolder = (d: ISydDocument) => {
    dispatch(
      addToPath({ id: d.idWsfile, label: d.name || d.path, goTo: d.path })
    )
  }
  const onRenameFile = (d: ISydDocument) => {
    setSelectedFile(d)
    setOpenRenameFileDialog(true)
  }
  // #endregion

  // #region Formatters and Getters
  const formatDate = (dateString?: string) => {
    if (!dateString) return ''
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const formatName = (file: ISydDocument) => {
    if (file.name && file.fileType === 1) return `${file.name}`
    if (!file.name && file.fileType === 1) return `${file.path}`
    if (file.name) return `${file.name}.${file.extension.toUpperCase()}`
    if (!file.name && file.originalName) {
      const n = _.split(file.originalName, '.')
      return `${n[0]}.${n[1].toUpperCase()}`
    }
    return ''
  }
  const getFileCardActions = (file: ISydDocument) => {
    if (file.fileType === 1) {
      return [
        { label: 'Renombrar carpeta', action: () => onRenameFile(file) },
        {
          label: 'Eliminar carpeta',
          action: () => deleteMutation.mutate(file),
        },
      ]
    }
    return [
      { label: 'Descargar archivo', action: () => downloadQuery.refetch() },
      { label: 'Renombrar archivo', action: () => onRenameFile(file) },
      { label: 'Eliminar archivo', action: () => deleteMutation.mutate(file) },
    ]
  }
  // #endregion

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        spacing={4}
        className="container-documentation-list">
        <Grid item xs={12} className="section-crumbs">
          <Breadcrumbs
            currentPath={path}
            onClickBreadcrumb={p => dispatch(setPath(p))}
          />
          {listQuery.isFetching && <Activity local size={20} />}
        </Grid>
        {/*(!listQuery.isLoading && list.length === 0) &&
          <Grid item xs={12}>
            <p>No tienes documentos en esta carpeta.</p>
          </Grid>
        */}
        {!listQuery.isLoading &&
          list.length > 0 &&
          _.map(list, (d, i) => (
            <Grid item xs={3} key={i}>
              <FileCard
                className="document"
                title={formatName(d)}
                subtitle={
                  d.fileType === 2 ? formatDate(d.createdAt) : undefined
                }
                type={d.fileType === 1 ? 'Folder' : 'File'}
                onFileClick={
                  d.fileType === 1 ? () => onClickFolder(d) : undefined
                }
                onActionClick={() => setSelectedFile(d)}
                actions={getFileCardActions(d)}
              />
            </Grid>
          ))}
        <Grid item xs={3}>
          <DropCard
            name="newFile"
            label="Subir archivos"
            onFileChange={f => uploadQuery.mutate(f)}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <NewFolderCard onClick={() => setOpenNewFolderDialog(true)} />
        </Grid>
      </Grid>

      <SydDialog
        open={openNewFolderDialog}
        size="sm"
        title="Agregar una carpeta"
        primaryButtonText="Crear"
        loadingPrimaryButton={addFolderMutation.isLoading}
        disablePrimaryButton={!validDialog}
        secondaryButtonText="Cancelar"
        disableSecondaryButton={addFolderMutation.isLoading}
        onCancel={() => {
          setOpenNewFolderDialog(false)
          setValidDialog(false)
        }}
        form="newFolderForm"
        sideButtons>
        <Form
          id="newFolderForm"
          onSubmit={async (form: { name: string }) =>
            addFolderMutation.mutate(cleanFilesNames(form.name))
          }
          onValidity={(v: boolean) => setValidDialog(v)}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nombre de la carpeta"
                validate={(v: string) =>
                  isUniqueString(v, listForFolderNameValidation)
                }
                defaultValue=""
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>

      <SydDialog
        open={openRenameFileDialog}
        size="sm"
        title="Renombrar un archivo"
        primaryButtonText="Renombrar"
        loadingPrimaryButton={renameMutation.isLoading}
        disablePrimaryButton={!validDialog}
        secondaryButtonText="Cancelar"
        disableSecondaryButton={renameMutation.isLoading}
        onCancel={() => {
          setOpenRenameFileDialog(false)
          setSelectedFile(undefined)
          setValidDialog(false)
        }}
        form="renameArchiveForm"
        sideButtons>
        <Form
          id="renameArchiveForm"
          onSubmit={async (form: { name: string }) =>
            renameMutation.mutate(cleanFilesNames(form.name))
          }
          onValidity={(v: boolean) => setValidDialog(v)}
          reset>
          <TextField
            name="name"
            label={
              selectedFile?.fileType === 1
                ? 'Nombre de la carpeta'
                : 'Nombre del archivo'
            }
            value={
              selectedFile?.fileType === 1
                ? selectedFile?.path
                : selectedFile?.name
            }
            validate={(v: string) =>
              isUniqueString(
                v,
                selectedFile?.fileType === 1
                  ? listForFolderNameValidation
                  : listForFileNameValidation
              )
            }
            fullWidth
            required
          />
        </Form>
      </SydDialog>
    </>
  )
}

export default DocumentsContainer
