import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { Button, Divider, Grid } from '@mui/material'
import { formatDate, formatName } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import { TaskAlt } from 'components/Shared/CustomIcons'
import FileCard, { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import { useAccountingStepFiveContext } from '../AccountingStepFive/AccountingStepFive'

const AccountingStepFivePreviousDocuments = () => {
  const {
    handleFileDelete,
    handleFileUpload,
    handleFileRetryUpload,
    handleFileDownload,
  } = useAccountingContext()

  const {
    limitFileView,
    proxyFinancialStatesFiles,
    uploadedPreviousStepsRequiredFiles,
    uploadedFinancialStatesFiles,
    withFinancialStates,
    financialStatesFileType,
    updateStepFiveState,
  } = useAccountingStepFiveContext()

  return (
    <article className="accounting-step-five-previous-documents-component">
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container spacing={2} className="documentation-list">
            <Grid item xs={12}>
              <div className="documentation-title-container">
                <TaskAlt color="secondary" fontSize="large" />
                <h4>Documentación contable</h4>
              </div>
            </Grid>
            {!withFinancialStates && (
              <Grid item xs={4}>
                <FileUploader
                  className="file-zone"
                  color="secondary"
                  label="Subir archivos"
                  onValue={handleFileUpload}
                  type={financialStatesFileType?.type}
                />
              </Grid>
            )}
            {proxyFinancialStatesFiles.map((f, i) => (
              <Grid item xs={4} key={i}>
                <FileCardAlt
                  title={formatName(f)}
                  subtitle={formatDate()}
                  type={'File'}
                  actions={[
                    {
                      label: 'Reintentar',
                      action: () => handleFileRetryUpload(f),
                    },
                    {
                      label: 'Renombrar archivo',
                      action: () => updateStepFiveState({ selectedFile: f }),
                    },
                    {
                      label: 'Eliminar archivo',
                      action: () => handleFileDelete(f),
                    },
                  ]}
                  error={f.error}
                  loading={f.activity}
                  className="file-zone"
                />
              </Grid>
            ))}
            {uploadedFinancialStatesFiles.map((f, i) => (
              <Grid item xs={4} key={i}>
                <FileCardAlt
                  title={formatName(f)}
                  subtitle={formatDate(f.createdAt)}
                  type={'File'}
                  onFileClick={() => handleFileDownload(f)}
                  actions={[
                    {
                      label: 'Renombrar archivo',
                      action: () => updateStepFiveState({ selectedFile: f }),
                    },
                    {
                      label: 'Eliminar archivo',
                      action: () => handleFileDelete(f),
                    },
                  ]}
                  error={f.error}
                  loading={f.activity}
                  className="file-zone"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Divider className="step-divider" />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container spacing={2} className="documentation-list">
            <Grid item xs={12}>
              <div className="documentation-title-container">
                <TaskAlt color="secondary" fontSize="large" />
                <h4>Documentos cargados</h4>
              </div>
            </Grid>
            {uploadedPreviousStepsRequiredFiles.map((f, i) =>
              limitFileView && i > 3 ? null : (
                <Grid item xs={3} key={i}>
                  <FileCard
                    title={formatName(f)}
                    subtitle={formatDate(f.createdAt)}
                    type={'File'}
                    actions={[
                      {
                        label: 'Descargar archivo',
                        action: () => handleFileDownload(f),
                      },
                    ]}
                  />
                </Grid>
              )
            )}
            {uploadedPreviousStepsRequiredFiles.length > 4 && (
              <Grid item xs={12} className="dropdown-action">
                <Button
                  id="seeAllBtn"
                  color="secondary"
                  endIcon={limitFileView ? <ArrowDropDown /> : <ArrowDropUp />}
                  onClick={() =>
                    updateStepFiveState({ limitFileView: !limitFileView })
                  }>
                  {limitFileView ? 'Ver todos' : 'Ver menos'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </article>
  )
}

export default AccountingStepFivePreviousDocuments
