import { useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { ClassNameMap } from '@mui/material'
import {
  downloadFileFromData,
  formatBase64DataAsDataURL,
  useCustomDataGridStyle,
} from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { ReportItem, generateConciliationReport } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { useConciliationReportsContext } from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import { TYPES } from '../ConciliationReportDetails/ConciliationReportDetails'
import ISydDocument from 'models/shared.models'

moment.locale('es')

interface IUseConciliationReportsTable {
  reportsLoading: boolean
  reports: ReportItem[]
  classes: ClassNameMap<string>
  isRowActionLoading: boolean
  setActiveReport: (reportID: number) => void
  handleDownload: (report: ReportItem) => void
}

const useConciliationReportsTable = (): IUseConciliationReportsTable => {
  const { workspaceInfo } = useConciliationsContainerContext()
  const {
    isLoading: reportsLoading,
    reports,
    setSelectedReport,
  } = useConciliationReportsContext()

  const dispatch = useDispatch()
  const dialog = useDialog()

  const classes = useCustomDataGridStyle()

  const [isRowActionLoading, setIsRowActionLoading] = useState(false)

  const setActiveReport = (reportID: number) => {
    dispatch(setSelectedReport(reportID))
  }

  const fetchReport = async (start: string, type: number) => {
    try {
      setIsRowActionLoading(true)
      const data = await generateConciliationReport(
        workspaceInfo.activeWorkspace,
        start,
        type
      )
      const file = JSON.parse(await data.text()) as ISydDocument
      file.file &&
        downloadFileFromData(
          `${file.name}.${file.extension}`,
          formatBase64DataAsDataURL(file.file)
        )
      setIsRowActionLoading(false)
    } catch (error: any) {
      setIsRowActionLoading(false)
      if (error.status === 404) {
        await dialog.dangerAsync({
          title: 'No se ha podido generar el reporte',
          subtitle: 'No existe conciliación para el periodo seleccionado',
          primaryButtonText: 'Continuar',
        })
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido generar el reporte',
        primaryButtonText: 'Continuar',
      })
    }
  }

  const handleDownload = (report: ReportItem) => {
    const period = moment(report.period, 'MMMM YYYY').format('YYYY/MM/DD')
    const type = report.type === TYPES.CFDIS ? 1 : 2
    fetchReport(period, type)
  }

  return {
    reportsLoading,
    reports,
    classes,
    isRowActionLoading,
    setActiveReport,
    handleDownload,
  }
}

export default useConciliationReportsTable
