import _ from 'lodash'
import moment, { Moment } from 'moment'
import { Money, Currencies } from 'ts-money'
import numeroALetras from './numberToLetters'
import {
  DEFAULT_LANGUAGE,
  FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE,
} from '../../constants'

import { PropTypes, TextFieldClassKey, makeStyles } from '@material-ui/core'
import Workspace, { NewWorkspaceForm } from 'models/workspace'
import Client from 'models/client'
import { Profile, WorkspaceRole } from 'models/user'
import {
  ExtendedColorPalette,
  ExtendedIconColorPalette,
  ExtendedButtonVariant,
  Notification,
  Persona,
  Plan,
  CSVConfigurator,
  NotificationType,
  ParsedArchive,
} from 'models/general'
import Catalogue, { CatalogueEntry, DocumentCatalogue } from 'models/catalogues'
import { CreditNoteForm } from 'models/invoice'
import { IAccountingProcess, ChatMessage } from 'models/accounting.models'
import { PieChartOrigin, PieChartStatistics } from 'models/dashboard'
import { DefaultRawDatum } from '@nivo/pie/dist/types/types'
import Employee from 'models/employee'
import { GridClasses } from '@material-ui/data-grid'
import { green, orange } from '@material-ui/core/colors'
import {
  ConciliationsStore,
  OCRBankStatement,
  OCRBankStatementMovement,
} from 'models/redux'
import {
  ConciliationFile,
  ConciliationUploadedFile,
} from 'models/conciliations'
import {
  getStepByName,
  stepsForProfile,
} from 'lib/utils/Accounting/accounting.utils'
import ISydDocument from 'models/shared.models'

export const filterColorPalette = (
  color: ExtendedColorPalette
): PropTypes.Color | undefined => {
  const newColor =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color) > -1
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : undefined

  return newColor
}

export const filterIconColorPalette = (
  color: ExtendedIconColorPalette
): 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error' => {
  const newColor =
    ['primary', 'secondary', 'action', 'disabled', 'error'].indexOf(color) > -1
      ? (color as 'primary' | 'secondary' | 'action' | 'disabled' | 'error')
      : 'inherit'

  return newColor
}

export const filterVariant = (
  variant: ExtendedButtonVariant
): 'text' | 'outlined' | 'contained' | undefined => {
  const newVariant =
    ['text', 'outlined', 'contained'].indexOf(variant) > -1
      ? (variant as 'text' | 'outlined' | 'contained')
      : undefined

  return newVariant
}

export const filterFieldType = (type: string): string => {
  switch (type) {
    case 'number':
      return 'text'
    default:
      return type
  }
}

export const doScrollToTop = () => {
  window.scrollTo(0, 0)
  //window.scrollTo({ top: 0, behavior: 'smooth' });
}

export const sleep = (miliseconds: number) => {
  return new Promise<void>(resolve => setTimeout(resolve, miliseconds))
}

export const getBase64FromFile = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const nameBuilder = (
  name: string,
  lastname: string,
  lastname2: string = ''
): string => {
  if (!name && !lastname) return ''
  return `${name} ${lastname} ${lastname2}`.trim()
}

export const getInvoiceAmount = (
  form: NewWorkspaceForm
): number | undefined => {
  switch (form.plan) {
    case 'facturacion':
      // return form.invoiceAmount1;
      return 5000
    case 'contabilidad':
      return form.invoiceAmount2
    case 'declaracion':
      return 1
    default:
      return undefined
  }
}

export const getInvoiceQuantity = (
  form: NewWorkspaceForm
): number | undefined => {
  switch (form.plan) {
    case 'facturacion':
      return form.invoiceQuantity1
    case 'contabilidad':
      return form.invoiceQuantity2
    case 'declaracion':
      return 1
    default:
      return undefined
  }
}

export const getInventoryFlag = (
  form: NewWorkspaceForm
): boolean | undefined => {
  switch (form.plan) {
    case 'contabilidad':
      return form.withInventory1
    case 'facturacion':
    case 'declaracion':
    default:
      return false
  }
}

export const getPersonType = (id?: number): Persona | undefined => {
  switch (id) {
    case 1:
      return Persona.fisica
    case 2:
      return Persona.moral
    default:
      return undefined
  }
}

export const getPersonId = (catalogue: Catalogue, persona: string): number => {
  switch (persona) {
    case 'fisica':
      return _.filter(catalogue, tp => tp.idCatalogValues === 1)[0]
        .idCatalogValues
    case 'moral':
      return _.filter(catalogue, tp => tp.idCatalogValues === 2)[0]
        .idCatalogValues
    default:
      return 0
  }
}

export const getPersonCatalogue = (
  catalogue: Catalogue,
  persona: string
): CatalogueEntry | undefined => {
  switch (persona) {
    case 'fisica':
      return _.filter(catalogue, tp => tp.idCatalogValues === 1)[0]
    case 'moral':
      return _.filter(catalogue, tp => tp.idCatalogValues === 2)[0]
    default:
      return undefined
  }
}

export const getNotificationType = (
  m: string,
  type: string
): NotificationType => {
  if (type === NotificationType.INVITATION) return NotificationType.INVITATION
  if (type === NotificationType.VALIDITY) return NotificationType.VALIDITY
  if (type === NotificationType.TEXT) {
    if (_.includes(m, 'vigencia')) return NotificationType.VALIDITY
  }
  return NotificationType.TEXT
}

export const parseClient = (data: any): Client => {
  const newClient: Client = {
    id: data.idClient,
    alias: data.name,
    name: data.bussinessName,
    rfc: data.rfc,
    email: data.email,
    phone: data.phone,
    zipCode: data?.zipcodes?.zipcode || '',
    branch: data.subsidiary,
    contact: data.contact,
    address: data.address,
    type: data.typePerson,
    foreign: data.foreing,
    taxPayerId: data.taxPayerId,
    taxRegime: data.taxRegime,
    createdAt: data.createdAt,
    lastUpdate: data.lastUpdate,
    enabled: data.enabled,
    flags: data.flags,
  }

  return newClient
}

export const parseEmployee = (data: any): Employee => {
  const employee: Employee = {
    id: data.idUser,
    name: nameBuilder(data.name, data.lastname),
    email: data.email,
    workspaces: data.numWorkspaces,
    external: !Boolean(data.isSimmple),
    nps: data.nps,
  }

  return employee
}

export const parseWorkspace = (
  data: any,
  role?: any,
  lists?: any,
  accountingStatus?: any
): Workspace => {
  const step = getStepByName(accountingStatus?.monthlyStatus ?? 'Pendiente')
  const newWorkspace: Workspace = {
    id: data.idWorkspace,
    name: data.name,
    type: data.type,
    rfc: data.rfc,
    taxSystem: data.taxSystem,
    zipCode: data.zipCode,
    plan: data.plan,
    invoiceQuantity: data.invoiceQuantity,
    invoiceAmount: data.invoiceAmount,
    withInventory: data.withInventory,
    createdAt: data.createdAt,
    lastUpdate: data.lastUpdate,
    enabled: data.enabled,
    files: [
      { fileName: '', extension: '', data: '' },
      { fileName: '', extension: '', data: '' },
      { fileName: '', extension: '', data: '' },
      { fileName: '', extension: '', data: '' },
    ],
    users: data.numberUsers || 0,
    pendingInvitations: data.numberInvitations || 0,
    currentRole: role,
    userList: !_.isEmpty(lists?.userList)
      ? _.map(lists?.userList, user =>
          parseProfile(user['users'], user['roles'])
        )
      : [],
    invitationsList: lists?.invitationList || [],
    accountingStatus: {
      step,
      name: stepsForProfile[step - 1],
      detail: accountingStatus?.stepDetail,
    },
  }

  return newWorkspace
}

export const parseAccountingRequiredDocuments = (
  docsById: any,
  docsByName: any
) => {
  let rd: DocumentCatalogue = []
  if (!_.isNil(docsById) && docsById.length > 0) {
    _.forEach(docsById, id => {
      const doc = _.find(FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE, ['id', id])
      doc && rd.push({ id: doc.id, name: doc.label })
    })
  } else if (!_.isNil(docsByName) && docsByName.length > 0) {
    _.forEach(docsByName, d => {
      const doc = _.find(FLATTEN_DOCUMENTS_TO_REQUEST_CATALOGUE, c =>
        _.isEqual(c.label, d.name)
      )
      doc && rd.push({ id: doc.id, name: doc.label })
    })
  }
  return rd
}

export const parseAccounting = (
  acc: any,
  docs: any[],
  coms: any[],
  checklist: any[] = []
): IAccountingProcess => {
  const comment = _.first(coms)?.text
  const accounting: IAccountingProcess = {
    id: acc.idAccounting,
    year: acc.year,
    month: acc.month,
    requiredDocuments: parseAccountingRequiredDocuments(
      acc.accountingListInt,
      acc.accountingList
    ),
    uploadedDocuments: _.map(docs, d => ({
      ...d.wsfiles,
      relatedId: d?.idAccountingDocuments,
      statusText: d?.approved || undefined,
      validated: d?.approved !== 'false' ? !_.isEmpty(d?.approved) : false,
    })),
    checklist: checklist,
    status: {
      id: acc.accountingstatus.idAccountingStatus,
      name: acc.accountingstatus.name,
      createdAt: acc.accountingstatus.createdAt,
      lastUpdate: acc.accountingstatus.lastUpdate,
      enabled: acc.accountingstatus.enabled,
    },
    comments: comment,
    commentsList: _.map(coms, c => ({
      text: c.text,
      type: _.isNil(c?.commentType) ? 1 : Number(c?.commentType),
    })),
    createdAt: acc.createdAt,
    lastUpdate: acc.lastUpdate,
    enabled: acc.enabled,
  }

  return accounting
}

export const parseChatMessage = (data: any): ChatMessage => {
  const msg: ChatMessage = {
    id: data.idChat,
    text: data.text,
    date: data.timeSent,
    enabled: data.enabled,
    sentBy: data.type,
    emailUser: data.users?.email || '',
  }
  return msg
}

export const parseProfile = (data: any, role?: any): Profile => {
  const newProfile: Profile = { ...data, role }
  return newProfile
}

export const parseDocument = (data: any): ISydDocument => {
  const base64Content = _.split(data.file, ',')
  const mimeType =
    base64Content[0].indexOf('data:') > -1
      ? data.mimeType
      : `data:${data.mimeType};base64`
  const file = `${mimeType},${base64Content[1]}`
  const doc: ISydDocument = { ...data, mimeType, file }
  return doc
}

export const parseNotification = (
  data: any,
  i: number,
  type: string = 'text'
): Notification => {
  const n: Notification = {
    id: i,
    type: getNotificationType(data.message, type),
    message: data.message,
    relatedId: data.relatedId,
    read: data.read,
  }

  return n
}

export const getRoleInWorkspace = (id: number, list: any): WorkspaceRole => {
  const userData = _.filter(list, el => el.users.idUser === id)[0]
  return userData.roles
}

export const getPricing = (
  type: Plan,
  timbres: number,
  facturacion: number
): number => {
  const facturacion_structure = [
    [1, 5, 0],
    [6, 24, 549],
    [25, 49, 699],
    [50, 99, 999],
    [100, 199, 1199],
    [200, 399, 1599],
    [400, 599, 1999],
    [600, 999, 2499],
    [1000, 1499, 2999],
    [1500, 1999, 3499],
    [2000, 2999, 3999],
    [3000, 4999, 4499],
    [5000, 9999, 4999],
    [10000, 19999, 7500],
    [20000, 39999, 15000],
    [40000, 79999, 30000],
    [80000, 159998, 40000],
  ]

  const contabilidad_structure = [
    [
      749, 799, 849, 999, 1159, 1299, 1549, 1799, 1999, 2249, 2499, 2699, 2999,
      3359, 3919, 4479, 5039, 5599, 5719, 5999, 6759, 6999, 7199, 7479, 7699,
      7879, 7999, 8079, 8459, 8839, 9199, 9399, 10499, 11999, 12499, 13199,
      14699, 15999, 17099, 17999, 18699, 19199, 19499, 19599, 19499, 19999,
      20399, 20699, 20899, 20999,
    ],
    [
      819, 869, 919, 1069, 1229, 1369, 1619, 1869, 2069, 2319, 2569, 2779, 3079,
      3439, 3999, 4559, 5119, 5679, 5799, 6079, 6839, 7079, 7279, 7549, 7779,
      7949, 8079, 8149, 8539, 8909, 9279, 9479, 10579, 12079, 12579, 13279,
      14779, 16079, 17179, 18079, 18779, 19279, 19579, 19679, 19579, 20079,
      20479, 20779, 20979, 21079,
    ],
    [
      899, 949, 999, 1149, 1309, 1449, 1699, 1949, 2149, 2399, 2649, 2849, 3149,
      3509, 4069, 4629, 5189, 5749, 5869, 6149, 6909, 7149, 7349, 7619, 7849,
      8019, 8149, 8219, 8609, 8979, 9349, 9549, 10649, 12149, 12649, 13349,
      14849, 16149, 17249, 18149, 18849, 19349, 19649, 19749, 19649, 20149,
      20549, 20849, 21049, 21149,
    ],
    [
      1039, 1089, 1139, 1289, 1449, 1589, 1839, 2089, 2289, 2539, 2789, 2989,
      3289, 3649, 4209, 4769, 5329, 5889, 6009, 6289, 7049, 7289, 7489, 7769,
      7989, 8169, 8289, 8369, 8749, 9129, 9489, 9689, 10789, 12289, 12789,
      13489, 14989, 16289, 17389, 18289, 18989, 19489, 19789, 19889, 19789,
      20289, 20689, 20989, 21189, 21289,
    ],
    [
      1329, 1379, 1429, 1579, 1739, 1879, 2129, 2379, 2579, 2829, 3079, 3279,
      3579, 3939, 4499, 5059, 5619, 6179, 6299, 6579, 7339, 7579, 7779, 8049,
      8279, 8449, 8579, 8649, 9039, 9409, 9779, 9979, 11079, 12579, 13079,
      13779, 15279, 16579, 17679, 18579, 19279, 19779, 20079, 20179, 20079,
      20579, 20979, 21279, 21479, 21579,
    ],
    [
      1699, 1749, 1799, 1949, 2109, 2249, 2499, 2749, 2949, 3199, 3449, 3649,
      3949, 4309, 4869, 5429, 5989, 6549, 6669, 6949, 7709, 7949, 8149, 8429,
      8649, 8829, 8949, 9029, 9409, 9789, 10149, 10349, 11449, 12949, 13449,
      14149, 15649, 16949, 18049, 18949, 19649, 20149, 20449, 20549, 20449,
      20949, 21349, 21649, 21849, 21949,
    ],
    [
      2269, 2319, 2369, 2519, 2679, 2819, 3069, 3319, 3519, 3769, 4019, 4219,
      4519, 4879, 5439, 5999, 6559, 7119, 7239, 7519, 8279, 8519, 8719, 8999,
      9219, 9399, 9519, 9599, 9979, 10359, 10719, 10919, 12019, 13519, 14019,
      14719, 16219, 17519, 18619, 19519, 20219, 20719, 21019, 21119, 21019,
      21519, 21919, 22219, 22419, 22519,
    ],
    [
      3119, 3169, 3219, 3369, 3529, 3669, 3919, 4169, 4369, 4619, 4869, 5069,
      5369, 5729, 6289, 6849, 7409, 7969, 8089, 8369, 9129, 9369, 9569, 9849,
      10069, 10249, 10369, 10449, 10829, 11209, 11569, 11769, 12869, 14369,
      14869, 15569, 17069, 18369, 19469, 20369, 21069, 21569, 21869, 21969,
      21869, 22369, 22769, 23069, 23269, 23369,
    ],
    [
      4059, 4109, 4159, 4309, 4469, 4609, 4859, 5109, 5309, 5559, 5809, 6009,
      6309, 6669, 7229, 7789, 8349, 8909, 9029, 9309, 10069, 10309, 10509,
      10779, 11009, 11179, 11309, 11379, 11769, 12139, 12509, 12709, 13809,
      15309, 15809, 16509, 18009, 19309, 20409, 21309, 22009, 22509, 22809,
      22909, 22809, 23309, 23709, 24009, 24209, 24309,
    ],
    [
      5489, 5539, 5589, 5739, 5899, 6039, 6289, 6539, 6739, 6989, 7239, 7439,
      7739, 8099, 8659, 9219, 9779, 10339, 10459, 10739, 11499, 11739, 11939,
      12209, 12439, 12609, 12739, 12809, 13199, 13569, 13939, 14139, 15239,
      16739, 17239, 17939, 19439, 20739, 21839, 22739, 23439, 23939, 24239,
      24339, 24239, 24739, 25139, 25439, 25639, 25739,
    ],
    [
      8349, 8399, 8449, 8599, 8759, 8899, 9149, 9399, 9599, 9849, 10099, 10299,
      10599, 10959, 11519, 12079, 12639, 13199, 13319, 13599, 14359, 14599,
      14799, 15079, 15299, 15479, 15599, 15679, 16059, 16439, 16799, 16999,
      18099, 19599, 20099, 20799, 22299, 23599, 24699, 25599, 26299, 26799,
      27099, 27199, 27099, 27599, 27999, 28299, 28499, 28599,
    ],
    [
      15079, 15129, 15179, 15329, 15489, 15629, 15879, 16129, 16329, 16579,
      16829, 17029, 17329, 17689, 18249, 18809, 19369, 19929, 20049, 20329,
      21089, 21329, 21529, 21799, 22029, 22199, 22329, 22399, 22789, 23159,
      23529, 23729, 24829, 26329, 26829, 27529, 29029, 30329, 31429, 32329,
      33029, 33529, 33829, 33929, 33829, 34329, 34729, 35029, 35229, 35329,
    ],
    [
      29399, 29449, 29499, 29649, 29809, 29949, 30199, 30449, 30649, 30899,
      31149, 31349, 31649, 32009, 32569, 33129, 33689, 34249, 34369, 34649,
      35409, 35649, 35849, 36129, 36349, 36529, 36649, 36729, 37109, 37489,
      37849, 38049, 39149, 40649, 41149, 41849, 43349, 44649, 45749, 46649,
      47349, 47849, 48149, 48249, 48149, 48649, 49049, 49349, 49549, 49649,
    ],
    [
      58049, 58099, 58149, 58299, 58459, 58599, 58849, 59099, 59299, 59549,
      59799, 59999, 60299, 60659, 61219, 61779, 62339, 62899, 63019, 63299,
      64059, 64299, 64499, 64779, 64999, 65179, 65299, 65379, 65759, 66139,
      66499, 66699, 67799, 69299, 69799, 70499, 71999, 73299, 74399, 75299,
      75999, 76499, 76799, 76899, 76799, 77299, 77699, 77999, 78199, 78299,
    ],
    [
      115349, 115399, 115449, 115599, 115759, 115899, 116149, 116399, 116599,
      116849, 117099, 117299, 117599, 117959, 118519, 119079, 119639, 120199,
      120319, 120599, 121359, 121599, 121799, 122079, 122299, 122479, 122599,
      122679, 123059, 123439, 123799, 123999, 125099, 126599, 127099, 127799,
      129299, 130599, 131699, 132599, 133299, 133799, 134099, 134199, 134099,
      134599, 134999, 135299, 135499, 135599,
    ],
    [
      210749, 210799, 210849, 210999, 211159, 211299, 211549, 211799, 211999,
      212249, 212499, 212699, 212999, 213359, 213919, 214479, 215039, 215599,
      215719, 215999, 216759, 216999, 217199, 217479, 217699, 217879, 217999,
      218079, 218459, 218839, 219199, 219399, 220499, 221999, 222499, 223199,
      224699, 225999, 227099, 227999, 228699, 229199, 229499, 229599, 229499,
      229999, 230399, 230699, 230899, 230999,
    ],
  ]

  const contabilidad_columns = [
    35000, 45000, 55000, 65000, 75000, 85000, 100000, 115000, 130000, 145000,
    160000, 180000, 200000, 240000, 280000, 320000, 360000, 400000, 440000,
    480000, 520000, 560000, 600000, 650000, 700000, 750000, 800000, 850000,
    900000, 950000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000,
    7000000, 8000000, 9000000, 10000000, 11000000, 12000000, 13000000, 14000000,
    15000000, 16000000, 17000000, 18000000, 19000000, 200000000,
  ]

  const contabilidad_rows = [
    [1, 24],
    [25, 49],
    [50, 99],
    [100, 199],
    [200, 399],
    [400, 599],
    [600, 999],
    [1000, 1499],
    [1500, 1999],
    [2000, 2999],
    [3000, 4999],
    [5000, 9999],
    [10000, 19999],
    [20000, 39999],
    [40000, 79999],
    [80000, 159998],
  ]

  if (type === 'facturacion') {
    for (let i = 0; i < facturacion_structure.length; i++) {
      if (
        timbres >= facturacion_structure[i][0] &&
        timbres <= facturacion_structure[i][1]
      ) {
        return facturacion_structure[i][2]
      }
    }
  }

  if (type === 'contabilidad') {
    let column = 0
    for (let i = 0; i < contabilidad_columns.length; i++) {
      if (facturacion >= contabilidad_columns[i]) {
        column = i
      }
    }
    let row = null
    for (let i = 0; i < contabilidad_rows.length; i++) {
      if (
        timbres >= contabilidad_rows[i][0] &&
        timbres <= contabilidad_rows[i][1]
      ) {
        row = i
      }
    }
    if (row !== null) return contabilidad_structure[row][column]
    else return 749
  }

  if (type === 'nomina') {
    return 0
  }

  return 0
}

export const numberToText = (number: number) =>
  numeroALetras(number, {
    plural: 'PESOS',
    singular: 'PESO',
    centPlural: 'CENTAVOS',
    centSingular: 'CENTAVO',
  })

export const moneyToText = (m: number) => {
  const money = Money.fromDecimal(m, Currencies.MXN)
  const [integer, decimal] = _.split(money.toString(), '.', 2)

  const letra = numeroALetras(integer, {
    plural: 'PESOS',
    singular: 'PESO',
    centPlural: 'CENTAVOS',
    centSingular: 'CENTAVO',
  })

  return `${_.capitalize(letra)} ${_.padStart(`${decimal}`, 2, '0')}/100 M.N.`
}

export const safeJsonParse = (str: string) => {
  try {
    return [null, JSON.parse(str)]
  } catch (err) {
    return [err]
  }
}

export const httpErrorParser = async (err: any) => {
  let error = null

  try {
    error = await err.json()
  } catch {
    console.log(err)
    error = await err.text()
  }

  let parsedError: any = {
    status: err.status,
    statusText: err.statusText,
  }

  !_.isString(error)
    ? (parsedError = { ...parsedError, ...error })
    : (parsedError = { ...parsedError, text: error })

  return parsedError
}

export const getRfcValidation = (type?: CatalogueEntry) => {
  if (!type) return undefined
  return type?.idCatalogValues === 2 ? ['isCompanyRFC'] : ['isPersonRFC']
}

export const getMonthList = (
  locales: string | string[] = DEFAULT_LANGUAGE,
  format: 'long' | 'short' = 'long'
): Catalogue => {
  const year = new Date().getFullYear()
  const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  const formatter = new Intl.DateTimeFormat(locales, { month: format })

  const list: Catalogue = _.map(monthList, (monthIndex: number) => ({
    idCatalogValues: 0,
    label: _.capitalize(formatter.format(new Date(year, monthIndex))),
    value: monthIndex + 1,
    enabled: true,
  }))

  return list
}

export const getYearList = (date?: Date | string): Catalogue => {
  if (!date) return []
  let startDate =
    typeof date === 'string' ? new Date(_.split(date, '.')[0]) : date

  const maxYear = new Date().getFullYear() + 1
  const minYear = startDate.getFullYear() - 1
  const yearList = _.range(minYear, maxYear)

  const list: Catalogue = _.map(yearList, (year: number) => ({
    idCatalogValues: 0,
    label: `${year}`,
    value: `${year}`,
    enabled: true,
  }))

  return list
}

export const getDateInISO = (date: Date | Moment | string): string => {
  const formats = ['DD-MM-YYYY', 'DD MM YYYY', 'DD/MM/YYYY', 'DD.MM.YYYY']
  const d =
    typeof date === 'string'
      ? moment(date, formats)
      : date instanceof moment
        ? date
        : moment(date, formats)
  const isoDateTime = d.toISOString()
  return isoDateTime
}

export const filterCatalogueRelationByType = (
  invoiceType: string,
  catalogue: any[]
) => {
  switch (invoiceType) {
    case 'I':
      return catalogue.filter(element => element.value !== '5')
    case 'E':
      return catalogue.filter(
        element => element.value !== '5' || element.value !== '3'
      )
    case 'T':
      return catalogue.filter(
        element => element.value === '5' || element.value === '3'
      )
    case 'P':
      return catalogue.filter(
        element => element.value === '8' || element.value === '9'
      )
    case 'N':
      return catalogue.filter(
        element => element.value === '8' || element.value === '9'
      )
    default:
      return catalogue
  }
}

export const cleanFilesNames = (fileName: string) => {
  const cleanValue = fileName.trim()
  return cleanValue
}

export const validateCreditNoteComplete = (actualState: CreditNoteForm) => {
  if (_.isNil(actualState)) return false
  if (!actualState.usoCfdi) return false
  if (!actualState.moneda) return false
  if (actualState.moneda.value !== 'MXN' && !actualState.exchangeRate)
    return false
  if (actualState.conceptos.length === 0) return false
  if (!actualState.metodoPago) return false
  if (!actualState.formaPago) return false
  if (!actualState.tipoRelacion) return false
  return true
}

export const getGraphContent = (
  serie: PieChartStatistics[],
  origin: PieChartOrigin
): DefaultRawDatum[] | [] => {
  if (serie) {
    switch (origin) {
      case 'taxpayer':
        return serie.map((set: any) => {
          return { id: set.rfc, value: set.percentage }
        })
      case 'category':
        return serie.map((set: any) => {
          return { id: set.name, value: set.units }
        })
      default:
        return []
    }
  }
  return []
}

export const formatBase64DataAsDataURL = (data: string) =>
  `data:application/octet-stream;base64,${data}`

export const downloadFileFromData = (name: string, href: string): void => {
  const a = document.createElement('a')
  a.href = href
  a.download = name
  a.target = '_blank'
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const base64ToBlob = (
  base64: string,
  type: string = 'application/octet-stream'
): Blob => {
  const str = _.split(base64, ',')[1]
  if (!str) return new Blob()
  const binStr = window.atob(str)
  const len = binStr.length
  const arr = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }
  return new Blob([arr], { type: type })
}

export const convertJsonArrayToCSV = <T>(
  config: CSVConfigurator<T>[],
  jsonArray: T[]
): string[][] => {
  const rows: Array<string[]> = []
  _.forEach(jsonArray, json => {
    const r = _.map(config, c => {
      const v = `${c.formatter ? c.formatter(_.get(json, c.accesor), json) : _.get(json, c.accesor)}`
      return _.replace(v, /,/g, '')
    })
    rows.push(r)
  })
  return rows
}

export const toQueryParams = (obj: any) => {
  const params = new URLSearchParams()
  for (const key in obj) {
    if (obj[key]) {
      params.append(key, obj[key])
    }
  }
  return params
}

export const toQueryParamsV2 = (obj: Record<string, any>) =>
  new URLSearchParams(
    Object.fromEntries(Object.entries(obj).filter(([k, v]) => v !== undefined))
  )

export const searchInCollection = <T = Object>(
  collection: T[],
  term: string,
  searchInKeys: string[],
  identity?: string
): T[] =>
  _.filter(collection, item => {
    let flag = false
    if (!term) {
      flag = true
    } else {
      flag = _.some(searchInKeys, key => {
        if (Boolean(identity) && key === identity) {
          return `${(item as any)[identity]}` === term
        } else {
          return _.includes(_.toLower(`${(item as any)[key]}`), _.toLower(term))
        }
      })
    }
    return flag
  })

export const useCustomFieldStyles = makeStyles<TextFieldClassKey>(() => ({
  root: {
    border: '2px solid #999',
    padding: 4,
    borderRadius: 20,
  },
}))

export const useCustomUnderlineFieldStyle = makeStyles<TextFieldClassKey>(
  () => ({
    root: {
      borderBottom: '2px solid #999',
      '& .MuiInput-underline::before, & .MuiInput-underline::after': {
        display: 'none',
      },
      // disabled
      '& .MuiInputBase-root.Mui-disabled': {
        color: '#444',
      },
    },
  })
)

export const useCustomDataGridStyle = makeStyles<GridClasses>(() => {
  const reset = {
    padding: '0px !important',
    margin: '0px !important',
  }
  return {
    root: {
      '& .MuiDataGrid-columnsContainer': {
        border: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': reset,
      '& .MuiDataGrid-columnHeaderTitleContainer': reset,
      '& .MuiDataGrid-columnHeaderTitle': reset,
      '& .MuiDataGrid-cell': reset,
      '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon':
        {
          color: green[500],
        },
      '& .MuiDataGrid-columnHeaderCheckbox': {
        opacity: 0,
        pointerEvents: 'none',
      },
      '& .MuiDataGrid-iconButtonContainer': {
        visibility: 'visible',
      },
      '& .MuiDataGrid-sortIcon': {
        opacity: 'inherit !important',
      },
      '& .MuiDataGrid-row.concilied': {
        opacity: 0.5,
        '& .MuiDataGrid-cell': {
          '&[data-field="__check__"]': {
            pointerEvents: 'none',
            cursor: 'not-allowed',
          },
          '&[data-field="concept"]': {},
        },
      },
      '& .MuiDataGrid-row.suggested': {
        backgroundColor: orange[50],
        color: '#521300',
      },
    },
  }
})

export const useManagementTableStyle = makeStyles<GridClasses>(() => {
  const reset = {
    padding: '0px !important',
    margin: '0px !important',
  }
  return {
    root: {
      boxShadow: '0px 2px 4px 0px #4444441F',
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff',
        borderRadius: '8px 8px 0 0 !important',
      },
      '& .MuiDataGrid-row': {
        ...reset,
        '&.expired': {
          '& .MuiDataGrid-cell': {
            backgroundColor: 'rgba(254, 244, 244, 1)',
          },
        },
      },

      '& .MuiDataGrid-columnHeader': reset,
      '& .MuiDataGrid-columnHeaderTitleContainer': reset,
      '& .MuiDataGrid-columnHeaderTitle': reset,
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: '#fff !important',
        borderRadius: '0px 0px 8px 8px',
      },
      '& .MuiDataGrid-cell': {
        paddingInline: '0px !important',
        background: '#fff',
        '& .MuiTableCell-root': {
          flex: 1,
          border: 'none',
        },
      },
      '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon':
        {
          color: green[500],
        },
      '& .MuiDataGrid-sortIcon': {
        opacity: 'inherit !important',
      },
    },
  }
})

export const numberToCurrency = (
  value: number,
  currency: string = 'MXN'
): string => {
  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: currency,
  })
  return formatter.format(value)
}

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const parseFileList = async (fileList: FileList) => {
  const files = await Array.from(fileList).map(async file => {
    const data = (await getBase64(file)) as string
    const [, content] = _.split(data, ',')
    return {
      originalName: file.name,
      file: content,
    } as ParsedArchive
  })
  return Promise.all(files)
}

export const parseFileListV2 = async (fileList: File[]) => {
  const files = await fileList.map(async file => {
    const data = (await getBase64(file)) as string
    const [, content] = _.split(data, ',')
    return content
  })
  return Promise.all(files)
}

export const parseUploadedFiles = (
  documents: ConciliationUploadedFile[]
): ConciliationFile[] => {
  return _.map(documents, (document, index) => ({
    id: index,
    original_name: document.originalName,
    file: document.file,
    idWsFile: document.idWsfile,
  }))
}

export const parseTransactions = (transactions: OCRBankStatementMovement[]) => {
  return _.map(transactions, (row, index) => ({
    id: row.idTransaction,
    origin: _.get(row, 'originReference', 'Sin referencia'),
    created_at: row.date,
    name: _.get(row, 'ordBen', 'Sin beneficiario'),
    rfc: _.get(row, 'rfcOrdBen', 'Sin RFC'),
    concept: _.get(row, 'concept', 'Sin concepto'),
    amount: (() => {
      if (row.withdrawal && row.withdrawal > 0) return row.withdrawal
      if (row.deposit && row.deposit > 0) return row.deposit
      return 0
    })(),
    out: row.withdrawal ? true : false,
    edited: row.edited,
    conciliated: row.conciliated,
  }))
}

export const toPeriodString = (
  period: ConciliationsStore['conciliationPeriod'],
  alt?: boolean
): string => {
  const month = _.padStart(`${Number(period.month) + 1}`, 2, '0')
  if (alt) return `01/${month}/${period.year}`
  return `${period.year}-${month}-01`
}

export const toPeriodStringV2 = (
  period: ConciliationsStore['conciliationPeriod'],
  format?: 'YYYY/MM/DD' | 'DD/MM/YYYY'
) => {
  const month = _.padStart(`${Number(period.month) + 1}`, 2, '0')
  const date = moment(`${period.year}-${month}-01`)
  return date.format(format || 'YYYY/MM/DD')
}

export const getPeriodDays = (start: Date, end: Date): number => {
  console.log(start, end)
  const diffTime = Math.abs(end.getTime() - start.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays || 0
}

export const toCurrency = (value: number, currency: string = 'MXN'): string => {
  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: currency,
  })
  return formatter.format(value)
}

export const replaceDash = (str: string) => str.replace(/-/g, '/')

export const fakeSetConcilied = (data: OCRBankStatement[]) =>
  _.map(data, (row: any) => {
    const transactions = _.map(row.transactions, transaction => {
      const isConcilied = _.random(0, 1) === 1
      return {
        ...transaction,
        isConcilied,
      }
    })
    return {
      ...row,
      transactions,
    }
  })

export const formatDatetoSpanish = (
  inputDate: string,
  inputFormat: string,
  outputformat?: string
): string => {
  const date = moment(inputDate)
  date.locale('es')
  return date.format(outputformat ?? inputFormat).replace('.', '')
}

export const getFormattedDate = (value: string): String =>{
  console.log(`Value to format ${value}`);
  // Check if the value is a valid date string
  if (!value || isNaN(Date.parse(value))) {
      return 'Fecha no disponible';
  }

  try {
      const date = new Date(value);
      const monthName = date.toLocaleString('es-MX', { month: 'long', year: 'numeric' }).split(' ')[0];
      const year = date.toLocaleString('es-MX', { year: 'numeric' });
      return _.capitalize(`${monthName} ${year}`);
  } catch (error) {
      // Log the error for debugging
      console.error('Error formatting date:', error);
      return 'Fecha no disponible';
  }
}
