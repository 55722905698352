import { combineReducers } from 'redux'
import accounting from 'store/Accounting/accounting.reducer'
import catalogues from 'reducers/catalogues'
import categories from 'reducers/categories'
import clients from 'reducers/clients'
import conciliations from '../reducers/conciliations'
import documentation from 'reducers/documentation'
import documentRequest from 'reducers/documentRequest'
import employees from 'reducers/employees'
import loader from 'reducers/loader'
import login from 'reducers/login'
import invoices from 'reducers/invoices'
import management from '../reducers/management'
import payments from 'store/Payments/payments.reducer'
import products from 'reducers/products'
import razonesSociales from 'reducers/razonesSociales'
import sse from 'store/SSE/sse.reducer'
import storeEvent from 'store/StoreEvent/storeEvent.reducer'
import user from 'reducers/user'
import workspace from 'reducers/workspace'
// import { LOGOUT } from './shared.actions'
// import { PayloadAction } from '@reduxjs/toolkit'

// const appReducer = combineReducers({
//   accounting,
//   catalogues,
//   categories,
//   clients,
//   conciliations,
//   documentation,
//   documentRequest,
//   employees,
//   invoices,
//   loader,
//   login,
//   management,
//   payments,
//   products,
//   razonesSociales,
//   sse,
//   storeEvent,
//   user,
//   workspace,
// })

// export type RootState = ReturnType<typeof appReducer>

const rootReducer = combineReducers({
  accounting,
  catalogues,
  categories,
  clients,
  conciliations,
  documentation,
  documentRequest,
  employees,
  invoices,
  loader,
  login,
  management,
  payments,
  products,
  razonesSociales,
  sse,
  storeEvent,
  user,
  workspace,
})

export type RootState = ReturnType<typeof rootReducer>

// const rootReducer = (
//   state: RootState | undefined,
//   action: PayloadAction<any>
// ): RootState => {
//   if (action.type === LOGOUT) {
//     state = undefined
//   }
//   return appReducer(state, action)
// }

export default rootReducer
