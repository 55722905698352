import React from 'react'
import { useIntl } from 'react-intl'
import { IOInputProps, withIO } from 'react-io-forms'

import Button from 'components/Shared/Button/Button'
import Grid from '@material-ui/core/Grid'

import { Persona } from 'models/general'

import Account from '@material-ui/icons/AccountCircle'
import './style.scss'

interface PersonSelectorProps extends IOInputProps {
  value?: Persona | ''
}

const _PersonSelector: React.FC<PersonSelectorProps> = props => {
  const intl = useIntl()
  const {
    ioProps: { invalid, message },
    value,
    setValue,
  } = props

  return (
    <Grid container className="component-person-selector" spacing={3}>
      <Grid item xs={12} sm={6} className="button-container">
        <Button
          id="personSelectorFisica"
          variant="contained"
          color={value === 'fisica' ? 'primary' : undefined}
          onClick={() => (value === 'fisica' ? undefined : setValue('fisica'))}
          className={`binary-button ${value === 'fisica' ? 'selected' : ''}`}
          fullWidth
          disableElevation
          disableRipple
          disableTouchRipple
          disableFocusRipple>
          <Account />
          <h5>{intl.formatMessage({ id: 'form.person.fisica' })}</h5>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className="button-container">
        <Button
          id="personSelectorMoral"
          variant="contained"
          color={value === 'moral' ? 'primary' : undefined}
          onClick={() => (value === 'moral' ? undefined : setValue('moral'))}
          className={`binary-button ${value === 'moral' ? 'selected' : ''}`}
          fullWidth
          disableElevation
          disableRipple
          disableTouchRipple
          disableFocusRipple>
          <Account />
          <h5>{intl.formatMessage({ id: 'form.person.moral' })}</h5>
        </Button>
      </Grid>
      {invalid && <p>{message}</p>}
    </Grid>
  )
}

const PersonSelector = withIO('PersonSelector')(_PersonSelector)
export default PersonSelector
