import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import { parseAccounting } from 'lib/helpers/utilities'
import {
  AccountingComentsHistoryResponse,
  IAccountingChecklist,
  IAccountingProcess,
  IGetAccountingRemindersResponseService,
  IAccountingTask,
} from 'models/accounting.models'
import {
  IAccountingIncompleteProcess,
  IGetAccountingIncompleteProcessesResponse,
} from './accounting.models'

const route = process.env.REACT_APP_APIURL || '/api'

export const fetchAndCombineAccountingData = async (
  client: any,
  data: any,
  workspaceId: number,
  accountingId: number
): Promise<IAccountingProcess> => {
  try {
    const acc = data['Accounting:']
    const docs = data['DocumentList:']

    const petition2 = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}/comments?status=${acc.accountingstatus.name}`
    )
    const coms: Array<any> = await petition2.json()

    const petition3 = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}/checklist?step=8&section=1`
    )
    const data3: IAccountingChecklist[] = await petition3.json()

    const petition4 = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}/checklist?step=8&section=2`
    )
    const data4: IAccountingChecklist[] = await petition4.json()

    const accounting = parseAccounting(acc, docs, coms, [...data3, ...data4])

    return accounting
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountingByDateService = async (
  workspaceId: number,
  year: number,
  month: number
): Promise<IAccountingProcess> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/find?year=${year}&month=${month}`
    )
    const data: any = await petition.json()

    const accounting = await fetchAndCombineAccountingData(
      client,
      data,
      workspaceId,
      data['Accounting:'].idAccounting
    )

    return accounting
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountingByIdService = async (
  workspaceId: number,
  accountingId: number
): Promise<IAccountingProcess> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}`
    )
    const data = await petition.json()

    const accounting = await fetchAndCombineAccountingData(
      client,
      data,
      workspaceId,
      accountingId
    )

    return accounting
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountingTasks = async (
  workspaceId: number,
  month: number,
  year: number
): Promise<IAccountingTask[]> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/profiling/task?month=${month}&year=${year}`
    )
    const data: IAccountingTask[] = await petition.json()
    return data
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const updateAccountingTaskStatus = async (
  workspaceId: number,
  task: IAccountingTask
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.put(
      `${route}/workspaces/${workspaceId}/profiling/task/${task.idTask}?isChecked=${task.checked}`
    )
    await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const updateRequestedDocumentList = async (
  workspaceId: number,
  processId: number,
  list: number[]
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    return await client.put(
      `${route}/workspaces/${workspaceId}/accounting/${processId}/processId`,
      {
        accountingListInt: list,
      }
    )
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountingChecklist = async (
  workspaceId: number,
  accountingId: number,
  step: number,
  section: number = 1
): Promise<Array<IAccountingChecklist>> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}/checklist?step=${step}&seccion=${section}`
    )
    const data: Array<IAccountingChecklist> = await petition.json()
    return data
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const sendReminderService = async (
  workspaceId: number,
  accountingId: number
) => {
  const client = HTTPClient.getClient()
  const response = await client.post(
    `${route}/workspaces/${workspaceId}/accounting/${accountingId}/reminder`
  )
  const data: any = await response.json()
  return data
}

export const getRemindersService = async (
  workspaceId: number,
  accountingId: number
): Promise<IGetAccountingRemindersResponseService> => {
  const client = HTTPClient.getClient()
  const response = await client.get(
    `${route}/workspaces/${workspaceId}/accounting/${accountingId}/reminder`
  )
  const data: IGetAccountingRemindersResponseService = await response.json()
  return data
}

export const getClarificationRequestCommentsHistory = async (
  workspaceId: number,
  accountingId: number
): Promise<AccountingComentsHistoryResponse[]> => {
  const client = HTTPClient.getClient()
  try {
    const response = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/${accountingId}/comments/all?status=Servicio%20en%20Proceso`
    )
    const data: any = await response.json()
    return data
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getAccountingIncompleteProcesses = async (
  workspaceId: number
): Promise<IAccountingIncompleteProcess[]> => {
  const client = HTTPClient.getClient()
  try {
    const response = await client.get(
      `${route}/workspaces/${workspaceId}/accounting/incomplete`
    )
    const data: IGetAccountingIncompleteProcessesResponse =
      await response.json()
    return data.data
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}
