import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import Card from '@material-ui/core/Card'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import WorkspaceInformation from '../Sections/WorkspaceInformationContainer/WorkspaceInformationContainer'
import Profiling from '../Sections/ProfilingContainer/ProfilingContainer'
import RazonesSocialesContainer from 'containers/RazonesSociales/RazonesSocialesContainer/RazonesSocialesContainer'
import CollaboratorsContainer from 'containers/Workspaces/Sections/CollaboratorsContainer/CollaboratorsContainer'
import CommentsList from '../Sections/CommentsContainer/CommentsContainer'
import UserNotificationsList from '../Sections/UserNotificationsContainer/UserNotificationsContainer'

import { useUpdateWorkspace, useWorkspace } from 'hooks/queries/workspaces'
import useEmisoresList from 'hooks/queries/emisores'

import { setActiveTab, setSelectedWorkspace } from 'actions/workspaces'
import {
  selectSelectedWorkspace,
  selectWorkspaceActiveTab,
} from 'lib/helpers/selectors'

import Edit from '@material-ui/icons/Edit'
import Save from '@material-ui/icons/Save'
import Close from '@material-ui/icons/Close'
import Business from '@material-ui/icons/Business'
import './style.scss'

import { WorkspaceSections } from 'lib/enums/shared.enums'
import PaymentsContainer from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'

interface Props {
  id: number
  onRedirect: (action: string) => void
  onFetching: (active: boolean) => void
}

const EditWorkspaceFormContainer: React.FC<Props> = ({
  id,
  onRedirect,
  onFetching,
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()

  const selectedWs = useSelector(selectSelectedWorkspace)
  const activeTab = useSelector(selectWorkspaceActiveTab)

  const [editName, setEditName] = useState(false)
  const [validForm, setValidForm] = useState(false)

  const wsQuery = useWorkspace(id, {
    onError: () => onRedirect('error'),
  })
  const emisoresQuery = useEmisoresList(id, { noSave: true })

  const changeWsNameMutation = useUpdateWorkspace({
    onSuccess: () => {
      wsQuery.refetch()
      setEditName(false)
      setValidForm(false)
    },
  })

  useEffect(() => {
    wsQuery.isLoading
      ? loader.primary('Cargando información del espacio de trabajo')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsQuery.isLoading])
  useEffect(() => {
    onFetching(wsQuery.isFetching)
  }, [wsQuery.isFetching, onFetching])
  useEffect(
    () => () => {
      dispatch(setSelectedWorkspace())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Card elevation={1} className="container-edit-workspace">
        {/** Datos de un Workspace */}
        <div className="main-container">
          <div className="logo-container">
            <Avatar variant="circular" className="logo">
              <Business fontSize="inherit" />
            </Avatar>
          </div>
          <div className="ws-name">
            {!editName && !_.isNil(selectedWs) && (
              <>
                <h4>{selectedWs.name}</h4>
                <IconButton
                  className="edit-button"
                  size="small"
                  onClick={() => setEditName(true)}>
                  <Edit fontSize="inherit" />
                </IconButton>
              </>
            )}
            {editName && !_.isNil(selectedWs) && (
              <Form
                onSubmit={async ({ workspaceName }) =>
                  changeWsNameMutation.mutate({
                    id,
                    name: workspaceName,
                    enabled: selectedWs.enabled,
                  })
                }
                onValidity={setValidForm}>
                <TextField
                  name="workspaceName"
                  value={selectedWs?.name}
                  className="name-input"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!changeWsNameMutation.isLoading ? (
                          <>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEditName(false)
                                setValidForm(false)
                              }}>
                              <Close fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              size="small"
                              type="submit"
                              disabled={!validForm}>
                              <Save fontSize="inherit" />
                            </IconButton>
                          </>
                        ) : (
                          <CircularProgress color="primary" size={20} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Form>
            )}
          </div>
          {!_.isNil(emisoresQuery?.data) && emisoresQuery.data.length > 0 && (
            <div className="ws-emisor-info">
              <p className="person-type">{emisoresQuery.data[0].type.label}</p>
              {/* <Divider orientation="vertical" flexItem />
                        <p className="email" title={emisoresQuery.data[0].}>{props.email}</p> */}
            </div>
          )}
        </div>
        <Tabs
          className="tabs-group"
          textColor="secondary"
          value={activeTab}
          onChange={(e: React.ChangeEvent<{}>, value: WorkspaceSections) =>
            dispatch(setActiveTab(value))
          }>
          <Tab
            className="tab"
            label="Información"
            disableRipple
            value={WorkspaceSections.INFORMATION}
          />
          <Tab
            className="tab"
            label="Mis Pagos"
            disableRipple
            value={WorkspaceSections.PAYMENTS}
          />
          <Tab
            className="tab"
            label="Perfilamiento"
            disableRipple
            value={WorkspaceSections.FORMS}
          />
          <Tab
            className="tab"
            label="Emisor"
            disableRipple
            value={WorkspaceSections.EMISORES}
          />
          <Tab
            className="tab"
            label="Colaboradores"
            disableRipple
            value={WorkspaceSections.COLLABORATORS}
          />
          <Tab
            className="tab"
            label="Comentarios"
            disableRipple
            value={WorkspaceSections.COMMENTS}
          />
          <Tab
            className="tab"
            label="Notificaciones"
            disableRipple
            value={WorkspaceSections.NOTIFICATIONS}
          />
        </Tabs>
        <Divider />
        {activeTab === WorkspaceSections.INFORMATION &&
          !_.isNil(selectedWs) &&
          !_.isNil(emisoresQuery.data) && (
            <WorkspaceInformation
              className="content workspace-information"
              workspace={selectedWs}
              emisor={emisoresQuery.data[0]}
              onRedirect={onRedirect}
            />
          )}
        {activeTab === WorkspaceSections.PAYMENTS &&
          !wsQuery.isLoading &&
          !wsQuery.isError && (
            <PaymentsContainer />
          )}
        {activeTab === WorkspaceSections.FORMS &&
          !wsQuery.isLoading &&
          !wsQuery.isError && (
            <Profiling
              className="content profiling"
              workspaceId={id}
              onRedirect={onRedirect}
            />
          )}
        {activeTab === WorkspaceSections.EMISORES &&
          !_.isNil(selectedWs) &&
          !wsQuery.isLoading &&
          !wsQuery.isError && (
            <RazonesSocialesContainer
              className="content emisores-list"
              variant="outlined"
              workspaceId={id}
              role={selectedWs.currentRole}
              onRedirect={onRedirect}
              withHeader
            />
          )}
        {activeTab === WorkspaceSections.COLLABORATORS &&
          !wsQuery.isLoading &&
          !wsQuery.isError &&
          !_.isNil(selectedWs) && (
            <CollaboratorsContainer
              className="content collaborators-list"
              variant="outlined"
              workspace={selectedWs}
              onRedirect={onRedirect}
            />
          )}
        {activeTab === WorkspaceSections.COMMENTS &&
          !wsQuery.isLoading &&
          !wsQuery.isError && (
            <CommentsList
              className="content comments-list"
              workspaceId={id}
              onRedirect={onRedirect}
            />
          )}
        {activeTab === WorkspaceSections.NOTIFICATIONS &&
          !wsQuery.isLoading &&
          !wsQuery.isError && (
            <UserNotificationsList
              className="content users-notifications-list"
              workspaceId={id}
              onRedirect={onRedirect}
            />
          )}
      </Card>
    </>
  )
}

export default EditWorkspaceFormContainer
