import React, { useState } from 'react';

import Card, { CardProps } from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from 'components/Shared/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import DownloadI from '@material-ui/icons/GetApp';
import CheckI from '@material-ui/icons/CheckCircle';
import ErrorI from '@material-ui/icons/Error';
import './style.scss';

export interface MenuAction {
  label: string
  action: () => void;
}

interface FileValidationCardProps extends CardProps {
  title: string
  subtitle?: string
  valid?: boolean
  invalid?: boolean,
  loading?: boolean,
  onDownload: () => void
  onValidate: (value?: "valid" | "invalid") => void
}

export const FileValidationCard: React.FC<FileValidationCardProps> = ({ 
  title, 
  subtitle,
  className = "",
  onValidate,
  onDownload,
  valid,
  invalid,
  loading = false,
  ...props 
}) => {

  const [showBackButton, setShowBackButton] = useState(false);

  return (
    <>
    <Card className={`component-file-validation-card ${className}`.trim()} {...props}>
      <div className="card-content">
        <div className="actions">
          <IconButton
            color="primary"
            size="small"
            onClick={() => onDownload()}
          >
            <DownloadI />
          </IconButton>
        </div>
        <div className="description">
          <span className="title">{title}</span>
          <br />
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
      <Divider />
      {(loading) &&
        <Grid container className="validation-activity" onMouseEnter={() => setShowBackButton(true)} onMouseLeave={() => setShowBackButton(false)}>
          <Grid item xs={12}>
            <CircularProgress size={18} color="primary" />
          </Grid>
        </Grid>
      }
      {(!loading && !valid && !invalid) &&
        <Grid container className="validation-actions">
          <Grid item xs={6}>
            <Button
              startIcon={<CheckI />}
              color="secondary"
              size="large"
              onClick={() => onValidate("valid")}
              fullWidth
            >
              Correcto
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              startIcon={<ErrorI />}
              color="danger"
              size="large"
              onClick={() => onValidate("invalid")}
              fullWidth
            >
              Incorrecto
            </Button>
          </Grid>
        </Grid>
      }
      {(!loading && valid && !invalid) &&
        <Grid container className="validation-actions" onMouseEnter={() => setShowBackButton(true)} onMouseLeave={() => setShowBackButton(false)}>
          <Grid item xs={12}>
            {showBackButton
              ? <Button
                  size="large"
                  onClick={() => onValidate()}
                  fullWidth
                >
                  Regresar
                </Button>
              : <Button
                  startIcon={<CheckI />}
                  color="secondary"
                  size="large"
                  onClick={() => onValidate("valid")}
                  fullWidth
                >
                  Correcto
                </Button>
            }
          </Grid>
        </Grid>
      }
      {(!loading && !valid && invalid) &&
        <Grid container className="validation-actions" onMouseOver={() => setShowBackButton(true)} onMouseLeave={() => setShowBackButton(false)}>
          <Grid item xs={12}>
            {showBackButton 
              ? <Button
                  size="large"
                  onClick={() => onValidate()}
                  fullWidth
                >
                  Regresar
                </Button>
              : <Button
                  startIcon={<ErrorI />}
                  color="danger"
                  size="large"
                  onClick={() => onValidate("invalid")}
                  fullWidth
                >
                  Incorrecto
                </Button>
            }
            
          </Grid>
        </Grid>
      }
    </Card>
    </>
  )
}

export default FileValidationCard;
