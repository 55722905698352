import _ from 'lodash'

import { Slug } from 'models/catalogues'
import { RootState } from 'store/reducers'

export const selectUser = (store: RootState) => store.user
export const selectUserRole = (store: RootState) => store.user?.role
export const selectProfile = (store: RootState) => store.user?.data
export const selectUserId = (store: RootState) => store.user?.data?.idUser
export const selectAuthorized = (store: RootState) => store.user?.authorized
export const selectNotifications = (store: RootState) =>
  store.user?.notifications
export const selectInvitations = (store: RootState) => store.user?.invitations

// export const selectLocale = (store: ReduxState) => store.intl.locale
export const selectActiveForm = (store: RootState) => store.login.form

export const selectGlobalLoader = (store: RootState) => store.loader.global
export const selectLocalLoader = (store: RootState) => store.loader.local

export const selectWorkspace = (store: RootState) => store.workspace
export const selectCreateWorkspace = (store: RootState) => store.workspace.form
export const selectCreateWorkspaceFlag = (store: RootState) =>
  store.workspace.form.createFlag
export const selectWorkspacesList = (store: RootState) =>
  store.workspace.workspacesList
export const selectSelectedWorkspace = (store: RootState) =>
  store.workspace.selectedWorkspace
export const selectSelectedWorkspaceCollaborators = (store: RootState) =>
  store.workspace.selectedWorkspace?.userList || []
export const selectWorkspaceActiveTab = (store: RootState) =>
  store.workspace.activeTab
export const selectActiveWorkspace = (store: RootState) =>
  store.workspace.activeWorkspace
export const selectActiveWorkspaceRole = (store: RootState) => {
  const ws = _.filter(
    store.workspace.workspacesList,
    w => w.id === store.workspace.activeWorkspace
  )[0]
  return ws.currentRole
}

export const selectClients = (store: RootState) => store.clients
export const selectCreateClient = (store: RootState) => store.clients.form
export const selectClientsList = (store: RootState) => store.clients.list
export const selectSelectedClient = (store: RootState) => store.clients.selected

export const selectInvoices = (store: RootState) => store.invoices
export const selectCreateInvoice = (store: RootState) => store.invoices.form
export const selectCreatePaymentComplement = (store: RootState) =>
  store.invoices.paymentComplementForm
export const selectCreateCreditNote = (store: RootState) =>
  store.invoices.creditNoteForm
export const selectInvoicesFilter = (store: RootState) => store.invoices.filter
export const selectInvoicesHeaders = (store: RootState) =>
  store.invoices.headers
export const selectInvoicesPagination = (store: RootState) =>
  store.invoices.pagination
export const selectInvoicesList = (store: RootState) => store.invoices.list
export const selectSelectedInvoice = (store: RootState) =>
  store.invoices.selected || null
export const selectSelectedInvoiceList = (store: RootState) =>
  store.invoices.selectedList
export const selectInvoicesSync = (store: RootState) => store.invoices.sync
export const selectSelectedEmisor = (store: RootState) =>
  store.invoices.selectedEmisor

export const selectProducts = (store: RootState) => store.products
export const selectCreateProduct = (store: RootState) => store.products.form
export const selectProductsList = (store: RootState) => store.products.list
export const selectProductsListIds =
  (current?: number) => (store: RootState) => {
    const mappedList = _.map(store.products.list, product => product.internalId)
    if (_.isUndefined(current)) return mappedList
    return _.filter(mappedList, id => id !== current)
  }
export const selectSelectedProduct = (store: RootState) =>
  store.products.selected
export const selectCurrentTab = (store: RootState) => store.products.tab

export const selectCategories = (store: RootState) => store.categories
export const selectCreateCategory = (store: RootState) => store.categories.form
export const selectCategoriesList = (store: RootState) => store.categories.list
export const selectSelectedCategory = (store: RootState) =>
  store.categories.selected

export const selectRazonesSociales = (store: RootState) => store.razonesSociales
export const selectCreateRazonSocial = (store: RootState) =>
  store.razonesSociales.form
export const selectRazonesSocialesList = (store: RootState) =>
  store.razonesSociales.list
export const selectRazonesSocialesListRfcs =
  (current?: string) => (store: RootState) => {
    const mappedList = _.map(store.razonesSociales.list, rs => rs.rfc)
    if (_.isUndefined(current)) return mappedList
    return _.filter(mappedList, rfc => !_.isEqual(rfc, current))
  }
export const selectSelectedRazonSocial = (store: RootState) =>
  store.razonesSociales.selected

export const selectCatalogues = (store: RootState) => store.catalogues
export const selectCatalogue = (name: Slug) => (store: RootState) =>
  store.catalogues[name]

export const selectDocumentation = (store: RootState) => store.documentation
export const selectDocumentationPath = (store: RootState) =>
  store.documentation.currentPath
export const selectDocumentationList = (store: RootState) =>
  store.documentation.list
export const selectDocumentationUploadList = (store: RootState) =>
  store.documentation.uploadList

export const selectDocumentRequest = (store: RootState) => store.documentRequest
export const selectDocumentRequestActiveTab = (store: RootState) =>
  store.documentRequest.activeTab
export const selectDocumentRequestOrigin = (store: RootState) =>
  store.documentRequest.origin
export const selectDocumentRequestCatalogue = (store: RootState) =>
  store.documentRequest.catalogue
export const selectDocumentRequestSelectedList = (store: RootState) =>
  store.documentRequest.selectedList
export const selectDocumentRequestInitialList = (store: RootState) =>
  store.documentRequest.initialList
export const selectDocumentRequestAccountingId = (store: RootState) =>
  store.documentRequest.accountingId
export const selectDocumentRequestYear = (store: RootState) =>
  store.documentRequest.year
export const selectDocumentRequestMonth = (store: RootState) =>
  store.documentRequest.month

export const selectEmployees = (store: RootState) => store.employees
export const selectEmployeesList = (store: RootState) => store.employees.list
export const selectEmployeesFilter = (store: RootState) =>
  store.employees.filter
export const selectEmployeesPagination = (store: RootState) =>
  store.employees.pagination
export const selectSelectedEmployee = (store: RootState) =>
  store.employees.selected

// conciliation selectors
export const selectconciliationPeriod = (store: RootState) =>
  store.conciliations.conciliationPeriod
export const selectConciliationsTab = (store: RootState) =>
  store.conciliations.activeTab

// conciliation statements selectors
export const selectConciliationStatementRows = (store: RootState) =>
  store.conciliations.statements.statementRows
export const selectConciliationActiveStatementRow = (store: RootState) =>
  store.conciliations.statements.activeStatementRow
export const selectSelectedConciliationStatementRows = (store: RootState) =>
  store.conciliations.statements.selectedStatementRows
export const selectConciliationStatementRowsSearch = (store: RootState) =>
  store.conciliations.statements.statementSearch
export const selectConciliationStatementFilter = (store: RootState) =>
  store.conciliations.statements.statementFilters

export const selectConciliationCFDISRows = (store: RootState) =>
  store.conciliations.cfdis.cfdiRows
export const selectSelectedConciliationCFDISRows = (store: RootState) =>
  store.conciliations.cfdis.selectedCfdiRows
export const selectConciliationCFDISActiveSection = (store: RootState) =>
  store.conciliations.cfdis.cfdiActiveSection
export const selectConciliationCFDISSearch = (store: RootState) =>
  store.conciliations.cfdis.cfdiSearch
export const selectConciliationCFDIFilter = (store: RootState) =>
  store.conciliations.cfdis.cfdiFilters

export const selectLedgerRows = (store: RootState) =>
  store.conciliations.ledgers.ledgerRows
export const selectSelectedLedgerRows = (store: RootState) =>
  store.conciliations.ledgers.selectedLedgerRows
export const selectLedgerFilter = (store: RootState) =>
  store.conciliations.ledgers.ledgerFilters

export const selectReportsSelectedReport = (store: RootState) =>
  store.conciliations.reports.selectedReport
export const selectReportsRows = (store: RootState) =>
  store.conciliations.reports.reports
export const selectReportsFilterMonth = (store: RootState) =>
  store.conciliations.reports.filter.month
export const selectReportsFilterYear = (store: RootState) =>
  store.conciliations.reports.filter.year

// history selectors

export const selectAccountStatementsHistory = (store: RootState) =>
  store.conciliations.history.accountStatementsHistory
export const selectCFDISHistory = (store: RootState) =>
  store.conciliations.history.CFDIsHistory

// OCR

export const selectOCRStatements = (store: RootState) =>
  store.conciliations.OCR.OCRStatements
export const selectOCRMovements = (store: RootState) =>
  store.conciliations.OCR.OCRMovements

// conciliations
export const selectConciliationDisplayReconciled = (store: RootState) =>
  store.conciliations.conciliations.displayReconciled
export const selectConciliationRows = (store: RootState) =>
  store.conciliations.conciliations.conciliationRows

// MANAGEMENT
export const selectManagementAccountants = (store: RootState) =>
  store.management.accountants.list
