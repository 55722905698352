import _ from 'lodash'
import update from 'immutability-helper'
import {
  SET_CREATE_FLAG,
  SET_CREATE_STEP,
  SET_CREATE_FORM_VALUE,
  RESET_CREATE_FORM,
  SET_WORKSPACE_LIST,
  SET_ACTIVE_WORKSPACE,
  ADD_WORKSPACE_TO_LIST,
  SET_SELECTED_WORKSPACE,
  SET_CREATE_FREETRIAL,
  SET_ACTIVE_TAB,
} from 'actions/workspaces'

/** interfaces */
import { WorkspaceStore } from 'models/redux'
import { ReduxAction } from 'models/thunk'
import { WorkspaceSections } from 'lib/enums/shared.enums'

const initialWorkspace: WorkspaceStore = {
  form: {
    createStep: 1,
    createFlag: false,
    freeTrial: false,
    type: null,
    workspace: '',
    name: '',
    rfc: '',
    taxSystem: null,
    zipCode: '',
    acceptContract: false,
    acceptTerms: false,
    plan: '',
    additionalPlan: [],
    fielCer: null,
    fielKey: null,
    passwordFiel: '',
  },
  workspacesList: [],
  activeWorkspace: 0,
  selectedWorkspace: null,
  activeTab: WorkspaceSections.INFORMATION,
}

const defaultAction: ReduxAction<any> = {
  type: '',
  value: '',
}

const workspace = (state = initialWorkspace, action = defaultAction) => {
  switch (action.type) {
    case SET_CREATE_FLAG:
      return update(state, {
        form: {
          createFlag: { $set: action.value },
        },
      })
    case SET_CREATE_FREETRIAL:
      return update(state, {
        form: {
          freeTrial: { $set: action.value },
        },
      })
    case SET_CREATE_STEP:
      return update(state, {
        form: {
          createStep: { $set: action.value },
        },
      })
    case SET_CREATE_FORM_VALUE:
      if (action.value.name === 'type') {
        const initialForm = {
          ...initialWorkspace.form,
          createFlag: true,
        }
        state = update(state, { form: { $set: initialForm } })
      }
      if (action.value.name === 'additionalPlan') {
        if (action.value.value === 'reset')
          return update(state, { form: { additionalPlan: { $set: [] } } })
        const index = _.findIndex(
          state.form.additionalPlan,
          c => c === action.value.value
        )
        if (index !== -1)
          return update(state, {
            form: { additionalPlan: { $splice: [[index, 1]] } },
          })
        else
          return update(state, {
            form: { additionalPlan: { $push: [action.value.value] } },
          })
      }
      return update(state, {
        form: {
          [action.value.name]: { $set: action.value.value },
        },
      })
    case RESET_CREATE_FORM:
      return update(state, { form: { $set: initialWorkspace.form } })
    case SET_WORKSPACE_LIST:
      return update(state, { workspacesList: { $set: action.value } })
    case SET_ACTIVE_WORKSPACE:
      return update(state, { activeWorkspace: { $set: action.value } })
    case ADD_WORKSPACE_TO_LIST:
      return update(state, { workspacesList: { $push: [action.value] } })
    case SET_SELECTED_WORKSPACE:
      return update(state, { selectedWorkspace: { $set: action.value } })
    case SET_ACTIVE_TAB:
      return update(state, { activeTab: { $set: action.value } })
    default:
      return state
  }
}

export default workspace
