import { Box, Typography } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { FC, useContext } from 'react'
import SwapIcon from '@material-ui/icons/SwapVert'
import { useCustomDataGridStyle } from 'lib/helpers/utilities'
import { CFDIHistoryContext } from '../CFDIHistory/CFDIHistory'
import { DownloadButton } from 'components/Conciliations/AccountStatementHistoryTable/AccountStatementHistoryTable'

const NoHistoryFiles: FC = () => {
  const { isLoading } = useContext(CFDIHistoryContext)
  return (
    <Box
      height={250}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Typography variant="h6" color="textSecondary">
        {isLoading ? 'Cargando...' : 'No hay archivos cargados en este periodo'}
      </Typography>
    </Box>
  )
}

const CFDIHistoryTable: FC = () => {
  const { files } = useContext(CFDIHistoryContext)
  const classes = useCustomDataGridStyle()
  const columns: GridColDef[] = [
    {
      field: 'period',
      headerName: 'Periodo',
      width: 160,
    },
    {
      field: 'name',
      headerName: 'Nombre de archivo',
      flex: 1,
      renderCell({ value, row }) {
        return (
          <Box display="flex" alignItems="center" width={1}>
            <Typography>{value}</Typography>
            <Box flex={1} />
            {/* tricked DownloadButton into using inyected info since it downloads the desired info */}
            {/* TODO: refactor download button to be more reusable */}
            <DownloadButton
              statement={
                {
                  id: row.idWsfile,
                  idWsFile: row.idWsfile,
                  period: row.period,
                  account: row.name,
                } as any
              }
            />
          </Box>
        )
      },
    },
  ]
  return (
    <Box>
      <DataGrid
        classes={classes}
        density="compact"
        rows={files}
        columns={columns}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        autoPageSize
        hideFooterSelectedRowCount
        headerHeight={80}
        components={{
          ColumnResizeIcon: () => null,
          ColumnSortedAscendingIcon: SwapIcon,
          ColumnSortedDescendingIcon: SwapIcon,
          NoRowsOverlay: () => <NoHistoryFiles />,
        }}
        style={{
          border: 'none',
          fontSize: 14,
        }}
      />
    </Box>
  )
}

export default CFDIHistoryTable
