import React, { useState } from 'react'
import { useDidUpdate } from 'rooks'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'
import IncomesExpensesGraph from 'components/Dashboard/IncomesExpensesGraph/IncomesExpensesGraph'

import useEmisoresList from 'hooks/queries/emisores'
import { getSerie } from 'actions/dashboard'

import { getYearList } from 'lib/helpers/utilities'

import './style.scss'

import { IncomeExpensesDataType, Period } from 'models/dashboard'
import RazonSocial from 'models/razonSocial'

interface Props {
  workspaceId: number
}

const IncomesExpensesGraphContainer: React.FC<Props> = ({ workspaceId }) => {
  const thunkDispatch = useThunkDispatch()
  const yearList = getYearList(new Date())

  const [type, setType] = useState<IncomeExpensesDataType>('all')
  const [period, setPeriod] = useState<Period>('mensual')
  const [year, setYear] = useState<number | string>(new Date().getFullYear())
  const [rfc, setRfc] = useState<RazonSocial>()

  useDidUpdate(() => setRfc(undefined), [workspaceId])

  const { data: rfcList, ...qRfc } = useEmisoresList(workspaceId, {
    noSave: true,
    onSuccess: list => _.isNil(rfc) && setRfc(list[0]),
  })

  const { data, ...qData } = useQuery(
    [
      'get-incomes-expenses-graph-data',
      workspaceId,
      rfc?.idRfc,
      type,
      period,
      year,
    ],
    () =>
      thunkDispatch(
        getSerie(workspaceId, 'incomesExpenses', {
          data_type: type,
          idRfc: rfc?.idRfc || 0,
          period: period,
          year: year,
        })
      ),
    { enabled: !_.isNil(rfcList) }
  )

  const isLoading = qRfc.isLoading || qData.isLoading
  const isFetching = qRfc.isFetching || qData.isFetching

  return (
    <DashboardCard
      className="incomes-expense-graph-container"
      title={'Ingresos y Gastos'}
      titleTag="h3"
      loading={isLoading}
      fetching={isFetching}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="space-between"
        className="general-container">
        <Grid item xs className="filter-selects-container">
          <TextField
            className="filter-select rfcs"
            value={rfc?.name || rfc?.rfc}
            InputProps={{ disableUnderline: true }}
            inputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs
          className="filter-selects-container"
          container
          justifyContent="flex-end"
          spacing={0}>
          <FormControl className="filter-select">
            <Select
              value={period}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setPeriod(e.target.value as Period)
              }
              disableUnderline>
              <MenuItem value={'anual'}>Anual</MenuItem>
              <MenuItem value={'semestral'}>Semestral</MenuItem>
              <MenuItem value={'cuatrimestral'}>Cuatrimestral</MenuItem>
              <MenuItem value={'trimestral'}>Trimestral</MenuItem>
              <MenuItem value={'bimestral'}>Bimestral</MenuItem>
              <MenuItem value={'mensual'}>Mensual</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="filter-select">
            <Select
              value={year}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setYear(e.target.value as number)
              }
              disableUnderline
              // disabled={period === "anual"}
            >
              {yearList.map(year => (
                <MenuItem value={year.value} key={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className="filter-tabs-container">
          <Tabs
            className="type-tabs-group"
            textColor="secondary"
            value={type}
            onChange={(
              e: React.ChangeEvent<{}>,
              value: IncomeExpensesDataType
            ) => setType(value)}>
            <Tab
              className="type-tab"
              label="Todos"
              disableRipple
              value={'all'}
            />
            <Tab
              className="type-tab"
              label="Ingresos"
              disableRipple
              value={'incomes'}
            />
            <Tab
              className="type-tab"
              label="Gastos"
              disableRipple
              value={'expenses'}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <IncomesExpensesGraph period={period} data={data ? data.serie : []} />
        </Grid>
      </Grid>
    </DashboardCard>
  )
}

export default IncomesExpensesGraphContainer
