import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'

import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import Button from 'components/Shared/Button/Button'

import { recover } from 'actions/user'
import { setActiveForm } from 'actions/login'

import { TaskAlt } from 'components/Shared/CustomIcons'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Logo from 'assets/logo.svg'
import './style.scss'

import { LoginScreenForms } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

type RecoverPasswordValues = {
  email: string
}

interface RecoverPasswordFormProps {
  onClickCancel: () => void
}

const RecoverPasswordFormContainer: React.FC<RecoverPasswordFormProps> = ({
  onClickCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(false)
  const thunkDispatch = useThunkDispatch()
  const dispatch = useDispatch()
  const intl = useIntl()
  const dialog = useDialog()

  const onSubmit = async (v: RecoverPasswordValues) => {
    setLoading(true)
    try {
      await thunkDispatch(recover(v.email))
      await dialog.primaryAsync({
        title: 'recover.success.title',
        subtitle: 'recover.success.message',
        icon: TaskAlt,
      })
      dispatch(setActiveForm(LoginScreenForms.LOGIN))
    } catch (err) {
      console.log(err)
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'recover.error.message',
        icon: ErrorOutline as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  const onValidity = (v: boolean) => {
    setValid(v)
  }

  return (
    <div className="container-recover-password-form">
      <a
        className="section logo"
        href="https://www.simmple.mx/"
        target="_blank"
        rel="noopener noreferrer">
        <img src={Logo} alt="Logo" />
      </a>
      <div className="section form">
        <h3>{intl.formatMessage({ id: 'recover.title' })}</h3>
        <p>{intl.formatMessage({ id: 'recover.text' })}</p>
        <Form onSubmit={onSubmit} lock={loading} onValidity={onValidity}>
          <TextField
            name="email"
            validate={['isEmail', 'isEmailFormat']}
            label={intl.formatMessage({ id: 'form.email' })}
            required
            fullWidth
          />
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            fullWidth
            disabled={!valid}
            activity={loading}>
            {intl.formatMessage({ id: 'button.send' })}
          </Button>
          <Button
            color="primary"
            onClick={onClickCancel}
            fullWidth
            disabled={loading}>
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default RecoverPasswordFormContainer
