import { useSelector } from 'react-redux'
import { selectAccountingTasks } from 'store/Accounting/accounting.selectors'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import SideModal from 'components/Shared/SideModal/SideModal'
import './styles.scss'

const AccountingTasksDrawer = () => {
  const {
    isAccountingTasksDrawerOpen,
    handleToggleAccountingTasksDrawer,
    handleToggleAccountingTaskStatus,
  } = useAccountingContext()

  const accountingTasks = useSelector(selectAccountingTasks)

  return (
    <SideModal
      className="accounting-tasks-drawer"
      isOpen={isAccountingTasksDrawerOpen}
      onClose={handleToggleAccountingTasksDrawer}>
      {accountingTasks ? (
        <ul>
          {accountingTasks.map(item => (
            <div key={`${item.idTask}1`}>
              <li key={item.idTask}>
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleToggleAccountingTaskStatus(item.idTask)}
                />
                <label>{item.task}</label>
              </li>
              {item.checked && item.completedAt ? (
                <small key={`1${item.idTask}`} className="placeHolderText">
                  {item.completedAt}
                </small>
              ) : (
                item.checked &&
                !item.completedAt && (
                  <small className="placeHolderText">Cargando...</small>
                )
              )}
            </div>
          ))}
        </ul>
      ) : (
        <p className="noData">No hay tareas disponibles</p>
      )}
    </SideModal>
  )
}

export default AccountingTasksDrawer
