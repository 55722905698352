import { ConciliationUploadedFile, CreateTransactionBody, UpdateStatementBody, UpdateTransactionBody } from "models/conciliations";
import { ParsedArchive } from "models/general";
import { CFDIRow, OCRBankStatement, OCRBankStatementMovement } from "models/redux";
import _ from "lodash";
import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";
import { toQueryParams } from "lib/helpers/utilities";

const route = process.env.REACT_APP_APIURL || "/api/";

export interface UploadArchiveToPeriodResponse {
  idConciliation: number;
  period: string;
  documents: ConciliationUploadedFile[]
}

export const uploadArchiveToPeriod = async (
  workspaceId: number,
  documents: ParsedArchive[],
  period: string
): Promise<UploadArchiveToPeriodResponse> => {
  const client = HTTPClient.getClient();
  try {
    const body = { documents, period };
    const url = `${route}/workspaces/${workspaceId}/wsfiles/conciliation/upload`;
    const petition = await client.post(url, body);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const deleteArchive = async (workspaceId: number, fileId: number): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/workspaces/${workspaceId}/wsfiles/conciliation/${fileId}`;
      const petition = await client.delete(url);
      return await petition.text();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};

export const processOCR = async (
  conciliationId: number
): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/OCR/conciliation/${conciliationId}`;
      const petition = await client.get(url);
      return await petition.json();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};


export const replaceArchive = async (
  currentWorkspace: number,
  period: string,
  fileIdWs: number,
  document: ParsedArchive
): Promise<UploadArchiveToPeriodResponse> => {
  try {
    await deleteArchive(currentWorkspace, fileIdWs);
    const documents = [document];
    return await uploadArchiveToPeriod(currentWorkspace, documents, period);
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const updateStatement = async (
  currentWorkspace: number,
  idWsFile: number,
  statement: UpdateStatementBody,
): Promise<OCRBankStatement> => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${currentWorkspace}/wsfiles/conciliation/${idWsFile}`;
    const petition = await client.put(url, statement);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

// STATEMENTS

export const getStatementFilesByParams = async (
  workspaceId: number,
  account: string,
  month: number,
  year: number,
) => {
  const client = HTTPClient.getClient();
  try {
    const stringParams = _.join([`account=${account ? account : ''}`, `month=${month !== 0 ? month : ''}`, `year=${year !== 0 ? year : ''}`], '&');
    const url = `${route}/workspaces/${workspaceId}/wsfiles/conciliation?${stringParams}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const getTransactions = async (
  currentWorkspace: number,
  filters?: {
    fromDate?: string;
    type?: string;
    param?: string;
  }
) => {
  const client = HTTPClient.getClient();
  try {
    const queryParams = toQueryParams(filters);
    const url = `${route}/transaction/workspace/${currentWorkspace}?${queryParams.toString()}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const createTransaction = async (
  transaction: CreateTransactionBody
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/transaction`;
    const petition = await client.post(url, transaction);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const updateTransaction = async (
  transaction: UpdateTransactionBody
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/transaction`;
    const petition = await client.put(url, transaction);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

// LEDGER

export const getLedgerAccounts = async (
  workspaceId: number,
  param?: string,
) => {
  const client = HTTPClient.getClient();
  try {
    const queryParams = toQueryParams({ param });
    const url = `${route}/workspaces/${workspaceId}/ledgerAccount?${queryParams.toString()}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const uploadLedgerAccountFile = async (
  workspaceId: number,
  documents: ParsedArchive[],
): Promise<UploadArchiveToPeriodResponse> => {
  const client = HTTPClient.getClient();
  try {
    const body = { documents };
    const url = `${route}/workspaces/${workspaceId}/ledgerAccount`;
    const petition = await client.post(url, body);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const processLedgerOCR = async (
  workspaceId: number
): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/workspaces/${workspaceId}/ledgerAccount/ocr`;
      const petition = await client.get(url);
      return await petition.json();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};

export const deleteLedgerArchive = async (workspaceId: number, fileId: number): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/workspaces/${workspaceId}/ledgerAccount/${fileId}/file`;
      const petition = await client.delete(url);
      return await petition.text();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};

export const replaceLedgerArchive = async (
  currentWorkspace: number,
  fileIdWs: number,
  document: ParsedArchive
): Promise<UploadArchiveToPeriodResponse> => {
  try {
    await deleteLedgerArchive(currentWorkspace, fileIdWs);
    const documents = [document];
    return await uploadLedgerAccountFile(currentWorkspace, documents);
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const deleteLedgerAccountByLedgerId = async (workspaceId: number, ledgerId: number): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/workspaces/${workspaceId}/ledgerAccount/${ledgerId}`;
      const petition = await client.delete(url);
      return await petition.text();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};

// CFDIs

export interface CFDIQueryFilters {
  showConciliated?: boolean;
  param?: string;
  fromDate?: string;
  status?: string;
}

export const getCFDIsByWorkspace = async (
  workspaceId: number,
  filters?: {
    showConciliated?: boolean;
    param?: string;
    fromDate?: string;
    toDate?: string;
    status?: string;
  }
): Promise<{
  cfdis: CFDIRow[];
}> => {
  const client = HTTPClient.getClient();
  try {
    const queryParams = toQueryParams(filters);
    const url = `${route}/workspaces/${workspaceId}/cfdi/conciliation?${queryParams.toString()}`;
    const petition = await client.get(url);
    return await petition.json();

  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const deleteCFDIDocument = async (workspaceId: number, fileId: number): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
      const url = `${route}/workspaces/${workspaceId}/cfdi/${fileId}/file`;
      const petition = await client.delete(url);
      return await petition.text();
  } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
  }
};

export const replaceCFDIFile = async (
  currentWorkspace: number,
  fileIdWs: number,
  document: ParsedArchive,
  period: string
): Promise<UploadArchiveToPeriodResponse> => {
  try {
    await deleteCFDIDocument(currentWorkspace, fileIdWs);
    const documents = [document];
    return await uploadCFDIDocuments(currentWorkspace, documents, period);
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const uploadCFDIDocuments = async (
  workspaceId: number,
  documents: ParsedArchive[],
  period: string
): Promise<UploadArchiveToPeriodResponse> => {
  const client = HTTPClient.getClient();
  try {
    const body = { documents, period };
    const url = `${route}/workspaces/${workspaceId}/cfdi`;
    const petition = await client.post(url, body);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const syncWithSAT = async (
  workspaceId: number,
  body: {
    idRfc: number,
    year: string,
    startMonth: string,
    endMonth: string,
    rfc: string,
  }
): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/sat/request`;
    const petition = await client.post(url, body);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const getUploadedCFDIFilesHistory = async (
  workspaceId: number,
  period: {
    month: number;
    year: number;
  }
) => {
  const client = HTTPClient.getClient();
  try {
    const queryParams = toQueryParams(period);
    const url = `${route}/workspaces/${workspaceId}/cfdi/conciliation/files?${queryParams.toString()}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const getCFDISuggestions = async (
  workspaceId: number,
  movementId: number,
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/conciliation/transactions-conciliated/${movementId}/suggest`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

export const getCFDIOCR = async (
  workspaceId: number,
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/cfdi/ocr`;
    await client.get(url);
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
};

// REPORTS

export interface ReportItem {
  idConciliation: number;
  type: string;
  period: string;
  amountConciliated: number;
  status: number;
  id: number;
}

export const getConciliatedTransactions = async (
  workspaceId: number,
  filters?: {
    year?: number;
    month?: number;
  }
): Promise<ReportItem[]> => {
  const client = HTTPClient.getClient();
  try {

    const queryParams = toQueryParams(filters);
    const url = `${route}/workspaces/${workspaceId}/conciliation?${queryParams.toString()}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const getConciliatedTransactionsByConciliation = async (
  workspaceId: number,
  conciliationId: number,
  type: number
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/conciliation/${conciliationId}/transactions-conciliated/${type}`;
    const petition = await client.get(url);
    return await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const generateChangeReport = async (
  workspaceId: number,
  fromPeriod: string,
  toPeriod?: string,
  type?: number,
): Promise<Blob> => {
  const client = HTTPClient.getClient();
  try {
    const params = toQueryParams({ fromPeriod, toPeriod, type });
    const url = `${route}/workspaces/${workspaceId}/conciliation/report/history?${params.toString()}`;
    const petition = await client.get(url);
    return await petition.blob();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const generateConciliationReport = async (
  workspaceId: number,
  period: string,
  type?: number,
): Promise<Blob> => {
  const client = HTTPClient.getClient();
  try {
    const params = toQueryParams({ period, type });
    const url = `${route}/workspaces/${workspaceId}/conciliation/report?${params.toString()}`;
    const petition = await client.get(url);
    return await petition.blob();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

// CONCILIATION

export const getConciliatedTransactionsByPeriod = async (
  workspaceId: number,
  period: string,
) => {
  const client = HTTPClient.getClient();
  try {
    const params = toQueryParams({ period });
    const url = `${route}/workspaces/${workspaceId}/conciliation/transactions-conciliated?${params.toString()}`;
    const petition = await client.get(url);
    return await petition.blob();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};

export const deleteConciliatedTransaction = async (
  workspaceId: number,
  transactionId: number,
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/conciliation/transactions-conciliated/${transactionId}`;
    const petition = await client.delete(url);
    return await petition.text();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
}

export type ConciliationBodyType = {
  period: string;
  transactions: OCRBankStatementMovement[];
  cfdis?: { uuid: string }[];
  idLedgerAccount?: number;
}

export const conciliateTransactions = async (
  workspaceId: number,
  body: ConciliationBodyType
) => {
  const client = HTTPClient.getClient();
  try {
    const url = `${route}/workspaces/${workspaceId}/conciliation/transactions-conciliated`;
    const petition = await client.post(url, body);
    return await petition.text();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  };
};
