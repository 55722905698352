import { IPaymentMethodTab } from 'models/payments'
import CreditCardIcon from 'assets/CreditCardIcon'
import {
  HISTORY_PAYMENT_STATUS,
  PAYMENT_METHODS,
  PLAN_PAYMENT_STATUS,
} from 'lib/enums/payments.enums'
import DatabaseIcon from 'assets/DatabaseIcon'
import SendIcon from 'assets/SendIcon'

export const PLAN_PAYMENT_STATUS_LABEL_MAP = {
  [PLAN_PAYMENT_STATUS.PAGADO]: 'Pagado',
  [PLAN_PAYMENT_STATUS.POR_PAGAR]: 'Por pagar',
  [PLAN_PAYMENT_STATUS.VENCIDO]: 'Vencido',
} as const

export const HISTORY_PAYMENT_STATUS_LABEL_MAP = {
  [HISTORY_PAYMENT_STATUS.PAGADO]: 'Pagado',
  [HISTORY_PAYMENT_STATUS.POR_PAGAR]: 'Por pagar',
  [HISTORY_PAYMENT_STATUS.NO_DISPONIBLE]: 'Generando factura',
} as const

export const PLAN_PAYMENT_STATUS_CLASSNAME_MAP = {
  [PLAN_PAYMENT_STATUS.PAGADO]: 'success',
  [PLAN_PAYMENT_STATUS.POR_PAGAR]: 'warning',
  [PLAN_PAYMENT_STATUS.VENCIDO]: 'error',
} as const

export const HISTORY_PAYMENT_STATUS_CLASSNAME_MAP = {
  [HISTORY_PAYMENT_STATUS.PAGADO]: 'success',
  [HISTORY_PAYMENT_STATUS.POR_PAGAR]: 'warning',
  [HISTORY_PAYMENT_STATUS.NO_DISPONIBLE]: 'warning',
} as const

export const PAYMENT_METHODS_LABEL_MAP = {
  [PAYMENT_METHODS.TARJETA]: 'Tarjeta',
  [PAYMENT_METHODS.EFECTIVO]: 'Efectivo',
  [PAYMENT_METHODS.TRANSFERENCIA]: 'Transferencia',
} as const

export const paymentMethodTabs: Readonly<IPaymentMethodTab[]> = [
  {
    id: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.TARJETA],
    icon: CreditCardIcon,
    label: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.TARJETA],
    disabled: false,
    target: PAYMENT_METHODS.TARJETA,
  },
  {
    id: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.EFECTIVO],
    icon: DatabaseIcon,
    label: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.EFECTIVO],
    disabled: true,
    target: PAYMENT_METHODS.EFECTIVO,
  },
  {
    id: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.TRANSFERENCIA],
    icon: SendIcon,
    label: PAYMENT_METHODS_LABEL_MAP[PAYMENT_METHODS.TRANSFERENCIA],
    disabled: true,
    target: PAYMENT_METHODS.TRANSFERENCIA,
  },
] as const
