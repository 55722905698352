import React from 'react'
import { useIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'
import FileField from 'components/FormComponents/IOComponents/FileField/FileField'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'

import { Archive, Plan } from 'models/general'
import { CatalogueEntry } from 'models/catalogues'

import './style.scss'
import { getPersonType } from 'lib/helpers/utilities'

interface Props {
  type: CatalogueEntry | null
  plan: Plan
  fielCer: Archive | null
  fielKey: Archive | null
  passwordFiel: string
  csdCer?: Archive | null
  csdKey?: Archive | null
  passwordCsd?: string
  ciec?: string
  storeValue?: (name: string, value: any) => void
}

const CreateWorkspaceStepThree: React.FC<Props> = props => {
  const intl = useIntl()
  const personLabel = getPersonType(props.type?.idCatalogValues)

  return (
    <div className="create-workspace-step-three-container">
      <h3 className="title">
        {intl.formatMessage({ id: 'workspace.create.step.3.title' })}
      </h3>
      <p className="about-you">
        {intl.formatMessage({ id: 'workspace.create.step.3.subtitle' })}
      </p>
      <Divider className="divider" />
      <div className="form-container">
        <div className="fiel-container">
          <p className="fiel-title">
            {intl.formatMessage({
              id: 'workspace.create.step.3.form.fiel.title',
            })}
          </p>
          <FileField
            name="fielCer"
            label={intl.formatMessage(
              { id: 'form.file.label' },
              { extension: '.CER', more: '' }
            )}
            accept=".cer"
            value={props.fielCer}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('fielCer', v)
            }
            required
          />
          <FileField
            name="fielKey"
            label={intl.formatMessage(
              { id: 'form.file.label' },
              { extension: '.KEY', more: '' }
            )}
            accept=".key"
            value={props.fielKey}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('fielKey', v)
            }
            required
          />
          <p className="fiel-title no-margin">
            {intl.formatMessage({
              id: `workspace.create.step.3.form.fiel.subtitle`,
            })}
          </p>
          <TextField
            fieldType="password"
            name="passwordFiel"
            validate={['isAtLeastEightCharacters']}
            //label={intl.formatMessage({ id: `workspace.create.step.3.form.fiel.password.field` })}
            value={props.passwordFiel || ''}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('passwordFiel', v)
            }
            fullWidth
            required
          />
        </div>
        <Divider className="divider" />
        <div className="ciec-container">
          <p className="ciec-title">
            {intl.formatMessage({
              id: `workspace.create.step.3.form.${personLabel}.csd.title`,
            })}
          </p>
          <FileField
            name="csdCer"
            label={intl.formatMessage(
              { id: 'form.file.label' },
              { extension: '.CER', more: 'de tu CSD' }
            )}
            accept=".cer"
            value={props.csdCer}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('csdCer', v)
            }
            required={
              Boolean(props.csdCer) ||
              Boolean(props.csdKey) ||
              Boolean(props.passwordCsd)
            }
          />
          <FileField
            name="csdKey"
            label={intl.formatMessage(
              { id: 'form.file.label' },
              { extension: '.KEY', more: 'de tu CSD' }
            )}
            accept=".key"
            value={props.csdKey}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('csdKey', v)
            }
            required={
              Boolean(props.csdCer) ||
              Boolean(props.csdKey) ||
              Boolean(props.passwordCsd)
            }
          />
          <p className="ciec-title no-margin">
            {intl.formatMessage({
              id: `workspace.create.step.3.form.${personLabel}.csd.ciec`,
            })}
          </p>
          <TextField
            fieldType="password"
            name="passwordCsd"
            //label={intl.formatMessage({ id: `workspace.create.step.3.form.${getPersonType(2)}.ciec` })}
            value={props.passwordCsd || ''}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('passwordCsd', v)
            }
            fullWidth
            required={
              Boolean(props.csdCer) ||
              Boolean(props.csdKey) ||
              Boolean(props.passwordCsd)
            }
          />
          <p className="ciec-title no-margin">
            {intl.formatMessage({
              id: `workspace.create.step.3.form.${personLabel}.csd.subtitle`,
            })}
          </p>
          <TextField
            fieldType="password"
            name="ciec"
            //label={intl.formatMessage({ id: `workspace.create.step.3.form.${getPersonType(2)}.ciec` })}
            value={props.ciec || ''}
            onValue={(v: any) =>
              props.storeValue && props.storeValue('ciec', v)
            }
            fullWidth
            // required={props.type?.idCatalogValues === 2}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateWorkspaceStepThree
