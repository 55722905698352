import {
    setCreateFormValue as setCreateWorkspaceFormValue,
    resetCreateForm as resetCreateWorkspaceForm
} from 'actions/workspaces';
    
import { 
    setCreateFormValue as setCreateInvoiceFormValue,
    resetCreateForm as resetCreateInvoiceForm,
    setCreatePaymentComplementFormValue,
    setCreateCreditNoteFormValue,
    resetCreatePaymentComplementForm,
    resetCreateCreditNoteForm
} from 'actions/invoices';


type Form = "createWorkspace" 
          | "createInvoice" | "createPaymentComplement" | "createCreditNote"
          | "createClient" | "createRazonSocial" | "createCategory" | "createProduct";

export const storeFormValue = (form: Form, key: string, value: any) => {
    switch (form) {
        case "createWorkspace":
            return setCreateWorkspaceFormValue(key, value);
        case "createClient":
        case "createInvoice":
            return setCreateInvoiceFormValue(key, value);
        case "createPaymentComplement":
            return setCreatePaymentComplementFormValue(key, value);
        case "createCreditNote":
            return setCreateCreditNoteFormValue(key, value);
        case "createProduct":
        case "createRazonSocial":
        case "createCategory":
        default:
            break;
    }
}

export const resetForm = (form: Form) => {
    switch (form) {
        case "createWorkspace":
            return resetCreateWorkspaceForm();
        case "createClient":
        case "createInvoice":
            return resetCreateInvoiceForm();
        case "createPaymentComplement":
            return resetCreatePaymentComplementForm();
        case "createCreditNote":
            return resetCreateCreditNoteForm();
        case "createProduct":
        case "createRazonSocial":
        case "createCategory":
        default:
            break;
    }
}