import { CircularProgress, Drawer, IconButton } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import DocumentForm from '../DocumentForm/DocumentForm'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './styles.scss'

const AccountMovementsModal = () => {
  const {
    accountMovementsModalIsOpen,
    accountMovementsIsLoading,
    toggleAccountMovementsModal,
  } = useConciliationsContainerContext()
  return (
    <Drawer
      open={accountMovementsModalIsOpen}
      anchor={'right'}
      className="account-movements-drawer-component">
      <div className="content">
        <div className={`loader ${!accountMovementsIsLoading && 'hidden'}`}>
          <div className="content">
            <CircularProgress color="secondary" />
            <h1>Subiendo archivos</h1>
          </div>
        </div>
        <header className="header">
          <h1>Agregar estados de cuenta</h1>
          <IconButton onClick={toggleAccountMovementsModal}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </header>

        <p className="legend">
          Carga el archivo de Excel con los movimientos bancarios. Puedes cargar
          como referencia el archivo original en PDF del cual se extrajeron.
        </p>

        <DocumentForm />
      </div>
    </Drawer>
  )
}

export default AccountMovementsModal
