import { LoginScreenForms as Form } from 'models/general';

export const SET_ACTIVE_FORM = '@Login/SET_ACTIVE_FORM';
export const RESET_ACTIVE_FORM = '@Login/RESET_ACTIVE_FORM';

export const setActiveForm = (form: string | null): object => {
    let currentForm = "";
    switch (form) {
        case Form.RECOVER: 
            currentForm = Form.RECOVER;
            break;
        case Form.REGISTER: 
            currentForm = Form.REGISTER;
            break;
        case Form.LOGIN: 
        default:
            currentForm = Form.LOGIN;
            break;
    }
    
    return ({
        type: SET_ACTIVE_FORM,
        value: currentForm
    })
}

export const resetActiveForm = (): object => ({
    type: RESET_ACTIVE_FORM
})