import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'
import { UseFormReturn, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { List } from 'models/general'
import {
  IHistoryPaymentDownloadAction,
  IBillingInformation,
  IPlanInformation,
  Payment,
  ISelectedRecurrentPlan,
} from 'models/payments'
import PDFFileIcon from 'assets/PDFFileIcon'
import XMLFileIcon from 'assets/XMLFileIcon'
import ZIPFileIcon from 'assets/ZIPFileIcon'
import { PaymentStep } from 'lib/enums/payments.enums'
import { IPlanUpdateForm } from 'lib/forms/payments/payments.form.models'
import { planUpdateValidationSchema } from 'lib/forms/payments/payments.schemas'
import useDataGridServerPagination, {
  IUseDataGridServerPagination,
} from 'hooks/Shared/useDataGridServerPagination'
import useUpdateStateFunction from 'hooks/Shared/useUpdateStateFunction'
import useCancelRecurrentPayment from 'hooks/Payments/useCancelRecurrentPayment'
import useDownloadPaymentInvoice from 'hooks/Payments/useDownloadPaymentInvoice'
import useGetBillingInformation from 'hooks/Payments/useGetBillingInformation'
import useRequestPlanUpdate from 'hooks/Payments/useRequestPlanUpdate'
import {
  setPaymentStep,
  setSelectedRecurrentPlan,
  setIsRecurringPayment,
  setSelectedPlan,
} from 'store/Payments/payments.reducer'
import { selectPaymentStep } from 'store/Payments/payments.selectors'
import { IApiError } from 'services/Shared/shared.models'
import { TPaymentInvoiceFileTypeRequest } from 'services/Payments/payments.models'
import { getPaymentsHistory } from 'services/Payments/payments.services'
import BillingInformation from 'components/Account/Payments/BillingInformation/BillingInformation'
import SelectedPlanPayment from 'components/Account/Payments/SelectedPlanPayment/SelectedPlanPayment'
import RecurrentPlanInformation from 'components/Account/Payments/RecurrentPlanInformation/RecurrentPlanInformation'
import { IModalButton } from 'components/Shared/SydDialog/SydDialog'
import { useSearchParams } from 'react-router-dom'

interface IUsePaymentsContainerState {
  isPlanUpdateDialogOpen: boolean
  isRecurrentPlanCancellationModalOpen: boolean
  notificationModal: boolean
  modalContent?: JSX.Element
  modalButtons?: IModalButton[]
}

export interface IUsePaymentsContainer extends IUsePaymentsContainerState {
  methods: UseFormReturn<IPlanUpdateForm>
  isBillingInformationLoading: boolean
  isLoadingPlanUpdateRequest: boolean
  isLoadingRecurrentPaymentCancellation: boolean
  paymentsHistoryPaginatedData: Omit<
    IUseDataGridServerPagination<Payment>,
    'refetch'
  >
  historyPaymentDownloadActionItems: IHistoryPaymentDownloadAction[]
  refetchBillingInformation: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IBillingInformation, IApiError>>
  refetchPaymentsHistory: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<List<Payment[]>, IApiError>>
  goBackToPaymentsOverview: () => void
  openPlanUpdateModal: () => void
  onClosePlanUpdateModalHandler: () => void
  submitPlanUpdateRequest: ({ message }: IPlanUpdateForm) => void
  handleSelectRecurrentPlan: (plan: ISelectedRecurrentPlan) => void
  openRecurrentPlanCancellationModal: () => void
  onCloseRecurrentPlanModalHandler: () => void
  cancelRecurrentPayment: (planId: number) => () => void
  handleSelectPlan: (
    plan: IPlanInformation,
    isRecurringPayment?: boolean
  ) => void
  billingInformation?: IBillingInformation
  ActiveSelectedStep?: () => JSX.Element
}

const usePaymentsContainer = (): IUsePaymentsContainer => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const selectedWorkspaceId = Number(searchParams.get('id'))

  const paymentStep = useSelector(selectPaymentStep)

  const [state, setState] = useState<IUsePaymentsContainerState>({
    isPlanUpdateDialogOpen: false,
    isRecurrentPlanCancellationModalOpen: false,
    notificationModal: false,
  })

  const updatePaymentsContainerState =
    useUpdateStateFunction<IUsePaymentsContainerState>(setState)

  const methods = useForm<IPlanUpdateForm>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(planUpdateValidationSchema),
  })

  const {
    isLoading: isLoadingBillingInformation,
    isFetching: isFetchingBillingInformation,
    data: billingInformation,
    refetch: refetchBillingInformation,
  } = useGetBillingInformation(selectedWorkspaceId)

  const isBillingInformationLoading =
    isLoadingBillingInformation || isFetchingBillingInformation

  const requestPlanUpdateMutation = useRequestPlanUpdate({
    methods,
    onClosePlanUpdateModalHandler,
    submitPlanUpdateRequest,
  })

  const { isLoading: isLoadingPlanUpdateRequest } = requestPlanUpdateMutation

  const cancelRecurrentPaymentMutation = useCancelRecurrentPayment({
    goBackToPaymentsOverview,
    onCloseRecurrentPlanModalHandler,
    refetchBillingInformation,
  })

  const { isLoading: isLoadingRecurrentPaymentCancellation } =
    cancelRecurrentPaymentMutation

  const { refetch: refetchPaymentsHistory, ...paymentsHistoryPaginatedData } =
    useDataGridServerPagination<Payment>(
      selectedWorkspaceId,
      getPaymentsHistory,
      'No se pudo obtener la información del historial de facturación.'
    )

  const downloadPaymentInvoice = useDownloadPaymentInvoice(selectedWorkspaceId)

  const paymentStepComponents = useMemo(
    (): Partial<Record<PaymentStep, () => JSX.Element>> => ({
      [PaymentStep.BILLING_INFORMATION]: BillingInformation,
      [PaymentStep.SELECTED_PLAN_PAYMENT]: SelectedPlanPayment,
      [PaymentStep.RECURRENT_PLAN_INFORMATION]: RecurrentPlanInformation,
    }),
    []
  )

  const ActiveSelectedStep = paymentStepComponents[paymentStep]

  function goBackToPaymentsOverview() {
    dispatch(setPaymentStep(PaymentStep.BILLING_INFORMATION))
  }

  const openPlanUpdateModal = () =>
    updatePaymentsContainerState({ isPlanUpdateDialogOpen: true })

  function onClosePlanUpdateModalHandler() {
    methods.reset()
    updatePaymentsContainerState({ isPlanUpdateDialogOpen: false })
  }

  function submitPlanUpdateRequest({ message }: IPlanUpdateForm) {
    requestPlanUpdateMutation.mutate(message)
  }

  const handleSelectRecurrentPlan = (plan: ISelectedRecurrentPlan) => {
    dispatch(setSelectedRecurrentPlan(plan))
    dispatch(setPaymentStep(PaymentStep.RECURRENT_PLAN_INFORMATION))
  }

  const openRecurrentPlanCancellationModal = () =>
    updatePaymentsContainerState({
      isRecurrentPlanCancellationModalOpen: true,
    })

  function onCloseRecurrentPlanModalHandler() {
    updatePaymentsContainerState({
      isRecurrentPlanCancellationModalOpen: false,
    })
  }

  const cancelRecurrentPayment = (planId: number) => () => {
    cancelRecurrentPaymentMutation.mutate({ selectedWorkspaceId, planId })
  }

  const handleSelectPlan = (
    payment: IPlanInformation,
    isRecurringPayment: boolean = false
  ) => {
    dispatch(setSelectedPlan(payment))
    dispatch(setPaymentStep(PaymentStep.SELECTED_PLAN_PAYMENT))
    dispatch(setIsRecurringPayment(isRecurringPayment))
  }

  const handleDownloadPaymentInvoice = (
    payment: Payment,
    fileType: TPaymentInvoiceFileTypeRequest
  ) => downloadPaymentInvoice.mutate({ payment, fileType })

  const historyPaymentDownloadActionItems: IHistoryPaymentDownloadAction[] = [
    {
      id: 'payment-invoice-pdf',
      icon: PDFFileIcon,
      fileType: 'pdf',
      onClick: handleDownloadPaymentInvoice,
    },
    {
      id: 'payment-invoice-xml',
      icon: XMLFileIcon,
      fileType: 'xml',
      onClick: handleDownloadPaymentInvoice,
    },
    {
      id: 'payment-invoice-zip',
      icon: ZIPFileIcon,
      fileType: 'zip',
      onClick: handleDownloadPaymentInvoice,
    },
  ]

  return {
    ...state,
    methods,
    isBillingInformationLoading,
    billingInformation,
    isLoadingPlanUpdateRequest,
    isLoadingRecurrentPaymentCancellation,
    paymentsHistoryPaginatedData,
    historyPaymentDownloadActionItems,
    ActiveSelectedStep,
    refetchBillingInformation,
    refetchPaymentsHistory,
    goBackToPaymentsOverview,
    openPlanUpdateModal,
    onClosePlanUpdateModalHandler,
    submitPlanUpdateRequest,
    handleSelectRecurrentPlan,
    openRecurrentPlanCancellationModal,
    onCloseRecurrentPlanModalHandler,
    cancelRecurrentPayment,
    handleSelectPlan,
  }
}

export default usePaymentsContainer
