import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Tab,
  TabProps,
  Tabs,
  Theme,
  Toolbar,
  createStyles,
  withStyles,
} from '@material-ui/core'
import useConciliate from 'hooks/useConciliate'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import CFDIActions from '../CFDIActions/CFDIActions'
import ConciliateButton from '../ConciliateButton/ConciliateButton'
import LedgerAccountActions from '../LedgerAccountActions/LedgerAccountActions'
import './styles.scss'

export const SECTIONS = {
  CFDIS: 0,
  LEDGER_ACCOUNTS: 1,
}

interface StyledTabProps extends TabProps {
  label: string
}

const StyledTabs = withStyles({
  root: {
    alignItems: 'center',
  },
  indicator: {
    display: 'none',
  },
})(Tabs)

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 30,
      marginRight: theme.spacing(1),
      padding: theme.spacing(1, 2),
      backgroundColor: '#eee',
      color: theme.palette.primary.main,
      opacity: 1,
      fontSize: 14,
      minHeight: 0,
      width: 'auto',
      minWidth: 0,
      textTransform: 'none',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
)((props: StyledTabProps) => <Tab {...props} />)

const SectionSelector = () => {
  const { cfdis } = useConciliationsContainerContext()

  const { cfdiActiveSection, setConciliationCfdiActiveSection } = cfdis

  const dispatch = useDispatch()

  const onChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(setConciliationCfdiActiveSection(newValue))
  }

  return (
    <Box flex={1}>
      <StyledTabs value={cfdiActiveSection} onChange={onChange}>
        <StyledTab label="Ver CFDIs" />
        <StyledTab label="Ver Cuentas contables" />
      </StyledTabs>
    </Box>
  )
}

const LastUpdate = () => {
  const { lastSync, cfdis } = useConciliationsContainerContext()

  const { cfdiActiveSection, cfdiFilters } = cfdis

  if (cfdiActiveSection !== SECTIONS.CFDIS || !lastSync) {
    return null
  }

  return (
    <Box
      position="absolute"
      right={0}
      bottom={cfdiFilters.display ? -10 : -16}
      fontStyle="italic"
      fontSize="caption.fontSize">
      Última actualización: {lastSync}
    </Box>
  )
}

const ActiveActions = () => {
  const { statements, cfdis, ledgers } = useConciliationsContainerContext()

  const { cfdiActiveSection } = cfdis

  const actions: { [key: number]: () => JSX.Element } = {
    0: CFDIActions,
    1: LedgerAccountActions,
  }

  const ActiveActionsComponent = actions[cfdiActiveSection] || CFDIActions

  const { canConciliate } = useConciliate(statements, ledgers, cfdis)

  if (canConciliate) {
    return <ConciliateButton />
  }

  return <ActiveActionsComponent />
}

const CFDIToolbar = () => {
  const { lastSync } = useConciliationsContainerContext()

  const extraGap = useMemo(() => {
    if (lastSync !== undefined || lastSync !== null) {
      return true
    }
    return false
  }, [lastSync])
  return (
    <Box className={`cfdi-toolbar-component ${extraGap ? 'extra' : ''}`}>
      <Toolbar disableGutters className="toolbar">
        <Box className="toolbar-title">Contabilidad</Box>
        <SectionSelector />
        <ActiveActions />
      </Toolbar>
      <LastUpdate />
    </Box>
  )
}

export default CFDIToolbar
