import { Fragment, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { months } from 'moment'
import { Button } from '@material-ui/core'
import PenIcon from '@material-ui/icons/Create'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { toQueryParams } from 'lib/helpers/utilities'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

const DateRangeSelector = () => {
  const { statements, conciliationPeriod } = useConciliationsContainerContext()

  const { setConciliationActiveStatementRow } = statements

  const { month, year, setConciliationPeriodMonth, setConciliationPeriodYear } =
    conciliationPeriod

  const [search, setSearch] = useSearchParams()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())

  useEffect(
    () => {
      if (search.has('month') && search.has('year')) {
        const month = Number(search.get('month'))
        const year = Number(search.get('year'))
        dispatch(setConciliationPeriodMonth(month))
        dispatch(setConciliationPeriodYear(year))
        setDate(new Date(year, month))
        date.setMonth(month)
        date.setFullYear(year)
      } else if (date && conciliationPeriod) {
        date.setMonth(month)
        date.setFullYear(year)
        setSearch(
          toQueryParams({
            month,
            year,
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // search,
      // dispatch,
      // date,
      // month,
      // year,
      // setConciliationPeriodMonth,
      // setConciliationPeriodYear,
      // setSearch,
    ]
  )

  const handleDateChange = (
    _date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    if (_date) {
      dispatch(setConciliationPeriodMonth(_date.month()))
      dispatch(setConciliationPeriodYear(_date.year()))
      dispatch(setConciliationActiveStatementRow(null as never))
      setDate(_date.toDate())
      const params = {
        month: String(_date.month()),
        year: String(_date.year()),
      }
      setSearch(toQueryParams(params))
    }
  }

  const formattedDate = `${months()[month]} ${year}`

  const toggle = () => setIsOpen(!isOpen)

  // TODO: agregar validacion de dos años atras

  return (
    <Fragment>
      <Button
        onClick={toggle}
        endIcon={<PenIcon style={{ fontSize: 22 }} />}
        style={{
          fontSize: 22,
          fontWeight: 400,
          color: '#000',
          textTransform: 'capitalize',
          marginLeft: '-10px',
        }}>
        {formattedDate}
      </Button>
      <KeyboardDatePicker
        open={isOpen}
        value={date}
        onClose={toggle}
        onChange={handleDateChange}
        views={['year', 'month']}
        keyboardIcon={() => null}
        TextFieldComponent={() => null}
        autoOk
        showTodayButton
      />
    </Fragment>
  )
}

export default DateRangeSelector
