import { Dispatch, SetStateAction, useCallback } from 'react'

export function useUpdateStateFunction<T>(
  setState: Dispatch<SetStateAction<T>>
) {
  const updateStateFunction = useCallback(
    (update: Partial<T> | ((prevState: T) => T)) => {
      setState(prevState =>
        typeof update === 'function'
          ? update(prevState)
          : { ...prevState, ...update }
      )
    },
    [setState]
  )

  return updateStateFunction
}

export default useUpdateStateFunction
