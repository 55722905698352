import HTTPClient from 'lib/helpers/HTTPClient';
import _ from 'lodash';

import { httpErrorParser } from 'lib/helpers/utilities';

import { 
    CashflowDataType, IncomeExpensesDataType, PieChartDataType, 
    IndicatorType, ReportType, SerieType,
    ReportObject,  IndicatorStatistics, 
    Period, PieChartLapse, PieChartOrigin 
} from 'models/dashboard';
import ReduxState from 'models/redux';

interface IndicatorParams {
    year: number | string
}

interface ReportParams {
    date?: string
    month?: number
    year?: number | string
}

interface CashflowParams {
    data_type: CashflowDataType
    idRfc: number
    period: Period
    year: number | string
}

interface IncomeExpensesParams {
    data_type: IncomeExpensesDataType
    idRfc: number
    period: Period
    year: number | string
}

interface PieChartParams {
    data_type: PieChartDataType
    origin: PieChartOrigin
    lapse: PieChartLapse
    year: number | string
}

const api = process.env.REACT_APP_APIURL || "/api";

export const getIndicator = (workspaceId: number, type: IndicatorType, params?: IndicatorParams) => async (dispatch: any, _getStore: () => ReduxState): Promise<IndicatorStatistics> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${api}/workspaces/${workspaceId}/dashboard?request_type=indicator&type=${type}&`;
        if (!_.isEmpty(params)) url = `${url}${(_.map(params, (value, key) => `${key}=${value}`)).join("&")}&`
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);
        const data: IndicatorStatistics = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getSerie = (workspaceId: number, type: SerieType, params?: CashflowParams | IncomeExpensesParams | PieChartParams) => async (dispatch: any, _getStore: () => ReduxState): Promise<any> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${api}/workspaces/${workspaceId}/dashboard?request_type=serie&type=${type}&`;
        if (!_.isEmpty(params)) url = `${url}${(_.map(params, (value, key) => `${key}=${value}`)).join("&")}&`
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);
        const data: any = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getReport = (workspaceId: number, type: ReportType, params?: ReportParams) => async (dispatch: any, _getStore: () => ReduxState): Promise<ReportObject[]> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${api}/workspaces/${workspaceId}/dashboard?request_type=report&type=${type}&`;
        if (!_.isEmpty(params)) url = `${url}${(_.map(params, (value, key) => `${key}=${value}`)).join("&")}&`
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);
        const data = await petition.json();
        const info: ReportObject[] = data["report"];
        return info;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}