import React from 'react'
import { useDidMount, useDidUpdate } from 'rooks'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import AddCreditNoteContainer from 'containers/CreditNote/3.3/AddCreditNoteContainer/AddCreditNoteContainer'
import AddCreditNote4Container from 'containers/CreditNote/4.0/AddCreditNoteV2Container/AddCreditNoteV2Container'

import { resetForm } from 'actions/forms'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'

interface Props {}

const AddCreditNoteScreen: React.FC<Props> = props => {
  const workspaceId = useSelector(selectActiveWorkspace)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { invoice } = useParams()
  const invoiceId = Number(invoice)
  const [params] = useSearchParams()
  const version = params.get('version') || '1'

  useDidMount(() => () => {
    window.scrollTo(0, 0)
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('cancel')
  }, [workspaceId, invoice])

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error') navigate(ROUTES.INVOICES, { replace: true })
    if (action === 'cancel' || action === 'success') {
      dispatch(resetForm('createCreditNote'))
      navigate(ROUTES.INVOICES)
    }
  }

  if (!invoiceId) {
    return <Navigate to={ROUTES.INVOICES} replace />
  }

  if (!workspaceId) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-add-credit-note">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Nueva nota de crédito</h1>
            <Button
              id="cancelBtn"
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          {version === '1' && (
            <AddCreditNoteContainer
              workspaceId={workspaceId}
              invoiceId={invoiceId}
              onRedirect={onRouteChange}
            />
          )}
          {version === '2' && (
            <AddCreditNote4Container
              workspaceId={workspaceId}
              invoiceId={invoiceId}
              onRedirect={onRouteChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default AddCreditNoteScreen
