import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  TableCell,
  withStyles,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {
  DataGrid,
  GridAddIcon,
  GridCellParams,
  GridCloseIcon,
  GridColDef,
  GridColumnHeaderParams,
  GridFilterListIcon,
} from '@material-ui/data-grid'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { CatalogueEntry } from 'models/catalogues'
import { EXPIRED } from '../../../../constants'
import { useManagementTableStyle } from 'lib/helpers/utilities'
import {
  daysToExpire,
  workspaceStatuses,
} from 'lib/utils/Management/management.utils'
import { useUpdateWorkspaceStatus } from 'hooks/queries/management'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import {
  IWorkspaceItem,
  useManagementWorkspacesContext,
} from '../ManagementWorkspacesContextProvider/ManagementWorkspacesContextProvider'
import WorkspaceDetails from '../ManagementWorkspacesTableDetails/ManagementWorkspacesTableDetails'
import TableFooter from '../ManagementWorkspacesTableFooter/ManagementWorkspacesTableFooter'
import './styles.scss'

export const CUSTOM_ROW_HEIGHT = 300

export const GrayCheckbox = withStyles({
  root: {},
  checked: {
    color: grey[800],
  },
  indeterminate: {
    color: grey[800],
  },
})((props: any) => <Checkbox color="default" {...props} />)

interface IExpandButtonProps {
  params: GridCellParams
}

const ExpandButton = ({ params }: IExpandButtonProps) => {
  const [expanded, setExpanded] = useState(false)

  const formViewportSize = (height: number) => {
    const { state } = params.api
    const { containerSizes, viewportSizes } = state
    const { dataContainerSizes, windowSizes, totalSizes, renderingZone } =
      containerSizes

    const updateHeight = (obj: any) => ({ ...obj, height })

    return {
      ...state,
      containerSizes: {
        ...containerSizes,
        dataContainerSizes: updateHeight(dataContainerSizes),
        windowSizes: updateHeight(windowSizes),
        totalSizes: updateHeight(totalSizes),
        renderingZone: updateHeight(renderingZone),
      },
      viewportSizes: updateHeight(viewportSizes),
    }
  }

  const ELEMENT_ID = `workspace-details-${params.id}`

  const handleExpand = (params: GridCellParams) => (event: any) => {
    const rowElement = params.api.getRowElement(params.id) as HTMLDivElement
    const viewportHeight = params.api.state.viewportSizes.height
    if (!expanded) {
      const newRow = document.createElement('div')
      newRow.id = ELEMENT_ID
      rowElement.insertAdjacentElement('afterend', newRow)
      params.api.setState(formViewportSize(viewportHeight + CUSTOM_ROW_HEIGHT))
      setExpanded(true)
    } else {
      const nextRow = rowElement.nextElementSibling as HTMLDivElement
      nextRow.remove()
      setExpanded(false)
      params.api.setState(formViewportSize(viewportHeight - CUSTOM_ROW_HEIGHT))
    }

    params.api.updateViewport()
  }

  return (
    <>
      <IconButton onClick={handleExpand(params)}>
        {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
      </IconButton>
      {expanded &&
        createPortal(
          <WorkspaceDetails workspace={params.row as IWorkspaceItem} />,
          document.getElementById(ELEMENT_ID) as HTMLDivElement
        )}
    </>
  )
}

interface ISelectButtonProps {
  params: GridCellParams
}

const SelectButton = ({ params }: ISelectButtonProps) => {
  const [selected, setSelected] = useState(false)

  const isCellSelected = (params: GridCellParams) => {
    const selectedRows = params.api.getSelectedRows()
    return selectedRows.has(params.row.id)
  }

  useEffect(() => {
    if (isCellSelected(params)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [params])

  const handleSelect = (event: any) => {
    params.api.selectRows([params.id], event.target.checked)
    setSelected(event.target.checked)
  }

  return <GrayCheckbox onClick={handleSelect} checked={selected} />
}

interface ISelectionCellProps {
  params: GridCellParams
}

const SelectionCell = ({ params }: ISelectionCellProps) => {
  return (
    <Box className="options-cell">
      <ExpandButton params={params} />
      <SelectButton params={params} />
    </Box>
  )
}

interface ISelectionHeaderProps {
  params: GridColumnHeaderParams
}

const SelectionHeader = ({ params }: ISelectionHeaderProps) => {
  const isEveryRowSelected = useMemo(() => {
    const rowIds = params.api.getAllRowIds() as number[]
    const selectedRows = params.api.getSelectedRows()
    return selectedRows.size === rowIds.length
  }, [params])

  const hasRowsSelected = useMemo(() => {
    const selectedRows = params.api.getSelectedRows()
    return selectedRows.size > 0
  }, [params])

  const isIndeterminate = useMemo(() => {
    return hasRowsSelected && !isEveryRowSelected
  }, [hasRowsSelected, isEveryRowSelected])

  const handleSelectAll = (event: any) => {
    const rowIds = params.api.getAllRowIds() as number[]

    const selectAll = (checked: boolean) => {
      for (const rowId of rowIds) {
        params.api.selectRows([rowId], checked)
      }
    }

    if (isIndeterminate) {
      selectAll(true)
      return
    }
    selectAll(event.target.checked)
  }

  return (
    <Box className="options-header">
      <GrayCheckbox
        onClick={handleSelectAll}
        indeterminate={isIndeterminate}
        checked={isEveryRowSelected}
      />
    </Box>
  )
}

interface IWorkspacesStatusFieldProps {
  params: GridCellParams
}

const WorkspaceStatusField = ({ params }: IWorkspacesStatusFieldProps) => {
  const [value, setValue] = useState<CatalogueEntry | undefined>(undefined)
  const [isEnable, setIsEnable] = useState<boolean>(false)
  const { value: paramsValue, id: workspaceId } = params
  const { mutate, isLoading } = useUpdateWorkspaceStatus(workspaceId as number)
  const { updateManagementWorkspacesState } = useManagementWorkspacesContext()

  const onChange = (value: any) => {
    if (isEnable) {
      mutate({
        idStatus: value.value,
        name: value.label,
      })
    }

    setIsEnable(true)
  }

  useEffect(() => {
    updateManagementWorkspacesState({ loading: isLoading })
  }, [isLoading, updateManagementWorkspacesState])

  return (
    <Grid item xs={12}>
      <AutocompleteField
        label={''}
        catalogue={workspaceStatuses.map(
          status =>
            ({ label: status.label, value: status.value }) as CatalogueEntry
        )}
        getOptionLabel={(option: CatalogueEntry) => option.label}
        onSelectValue={(v: CatalogueEntry) => setValue(v)}
        onClear={(v: CatalogueEntry) => setValue(undefined)}
        defaultValue={
          workspaceStatuses.find(
            status => status.label === paramsValue
          ) as CatalogueEntry
        }
        value={value}
        fullWidth
        name={`workspaceStatus${params.id}`}
        onChange={onChange}
      />
    </Grid>
  )
}

const ManagementWorkspacesTable = () => {
  const {
    list,
    selectedWorkspaceIds,
    updateManagementWorkspacesState,
    size: pageSize,
    loading,
    managementWorkspacesLoading,
  } = useManagementWorkspacesContext()

  const hydrateWorkspaces = (workspaces: IWorkspaceItem[]) => {
    return _.map(workspaces, (workspace: IWorkspaceItem) => {
      return {
        ...workspace,
        id: workspace.idWorkspace,
      }
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'selection',
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderHeader: params => <SelectionHeader params={params} />,
      renderCell: params => <SelectionCell params={params} />,
      disableReorder: true,
    },
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'rfc',
      headerName: 'RFC',
      flex: 1,
    },
    {
      field: 'team',
      headerName: 'Equipo',
      flex: 1,
    },
    {
      field: 'emailAccountant',
      headerName: 'Contador',
      flex: 1,
      renderCell: ({ row }) => (
        <TableCell className="accountant-cell">
          <Box className="email">{row.emailAccountant}</Box>
        </TableCell>
      ),
    },
    {
      field: 'monthlyClosure',
      headerName: 'Cierre mensual',
      flex: 1,
    },
    {
      field: 'complianceOpinion',
      headerName: 'Opinión',
      flex: 1,
      renderCell: params => {
        if (!params.value) return 'Sin opinión'
        return (
          <TableCell className="rating-cell">
            {params.value === 'positiva' ? <GridAddIcon /> : <GridCloseIcon />}
            <Box>{_.capitalize(params.value as string)}</Box>
          </TableCell>
        )
      },
    },
    {
      field: 'coefficient',
      headerName: 'Coeficiente',
      flex: 1,
    },
    {
      field: 'workspaceStatus',
      headerName: 'Estatus de espacio de trabajo',
      flex: 1,
      renderCell: params => (
        <TableCell className="workspace-status-cell">
          <WorkspaceStatusField params={params} key={params.id} />
        </TableCell>
      ),
    },
  ]

  const classes = useManagementTableStyle()

  const workspaces = hydrateWorkspaces(list)

  return (
    <div className="workspaces-table-component">
      <DataGrid
        className={classes.root}
        columns={columns}
        rows={workspaces}
        autoHeight
        pageSize={pageSize}
        disableSelectionOnClick
        disableColumnMenu
        selectionModel={selectedWorkspaceIds}
        onSelectionModelChange={selectionModel => {
          updateManagementWorkspacesState({
            selectedWorkspaceIds: selectionModel.map(id => id as number),
          })
        }}
        style={{
          border: 'none',
          fontSize: 14,
        }}
        getRowClassName={params => {
          const eFirmaExpirationDate = params.getValue(
            params.id,
            'eFirmaExpirationDate'
          ) as string
          const csdExpirationDate = params.getValue(
            params.id,
            'csdExpirationDate'
          ) as string
          const eFirmaStatus = daysToExpire(new Date(eFirmaExpirationDate))
          const csdStatus = daysToExpire(new Date(csdExpirationDate))
          if (eFirmaStatus === EXPIRED || csdStatus === EXPIRED) {
            return 'expired'
          } else {
            return ''
          }
        }}
        loading={managementWorkspacesLoading || loading}
        components={{
          ColumnResizeIcon: () => null,
          ColumnSortedAscendingIcon: GridFilterListIcon,
          ColumnSortedDescendingIcon: GridFilterListIcon,
          Footer: TableFooter,
          LoadingOverlay: () => (
            <LinearProgress style={{ zIndex: 999 }} variant="query" />
          ),
        }}
      />
    </div>
  )
}

export default ManagementWorkspacesTable
