import { Box, Grid } from '@material-ui/core'
import {
  setActiveReport,
  setReportFilterMonth,
  setReportFilterYear,
  setReportsRows,
} from 'actions/conciliations'
import { TransactionType } from 'components/Conciliations/ConciliationReportDetailsReportDataRow/ConciliationReportDetailsReportDataRow'
import {
  selectReportsFilterMonth,
  selectReportsFilterYear,
  selectReportsRows,
  selectReportsSelectedReport,
} from 'lib/helpers/selectors'
import { ConciliationsStore } from 'models/redux'
import { createContext, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ConciliationsReport from 'components/Conciliations/ConciliationsReports/ConciliationsReports'

interface ConciliationReportsContextInterface {
  selectedReport: ConciliationsStore['reports']['selectedReport']
  setSelectedReport: typeof setActiveReport
  reports: ConciliationsStore['reports']['reports']
  setReports: typeof setReportsRows
  filter: {
    month: ConciliationsStore['reports']['filter']['month']
    setMonth: typeof setReportFilterMonth
    year: ConciliationsStore['reports']['filter']['year']
    setYear: typeof setReportFilterYear
  }
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  transactions: Transaction[]
  setTransactions: (transactions: Transaction[]) => void
}

export const ConciliationReportsContext =
  createContext<ConciliationReportsContextInterface | null>(null)

export type Transaction = {
  idTransaction: number
  concept: string
  amount: number
  cfdis?: TransactionType[]
  ledgerAccounts?: TransactionType[]
}

const ConciliationReportsContainer = () => {
  const reportsRows = useSelector(selectReportsRows)
  const selectedReport = useSelector(selectReportsSelectedReport)
  const reportsFilterMonth = useSelector(selectReportsFilterMonth)
  const reportsFilterYear = useSelector(selectReportsFilterYear)

  const setSelectedReport = setActiveReport
  const setReports = setReportsRows
  const setMonth = setReportFilterMonth
  const setYear = setReportFilterYear

  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const valueToProvide: ConciliationReportsContextInterface = useMemo(
    () => ({
      selectedReport,
      setSelectedReport,
      reports: reportsRows,
      setReports,
      filter: {
        month: reportsFilterMonth,
        setMonth,
        year: reportsFilterYear,
        setYear,
      },
      isLoading,
      setIsLoading,
      transactions,
      setTransactions,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      reportsRows,
      selectedReport,
      reportsFilterMonth,
      reportsFilterYear,
      isLoading,
      transactions,
      // setMonth,
      // setYear,
      // setSelectedReport,
      // setReports,
    ]
  )

  return (
    <ConciliationReportsContext.Provider value={valueToProvide}>
      <Box className="conciliations-container" marginY={4}>
        <Box fontSize={16} marginBottom={4}>
          Selecciona una conciliación para ver los movimientos bancarios y CFDIs
          que incluye.
        </Box>
        <Grid container>
          <ConciliationsReport />
        </Grid>
      </Box>
    </ConciliationReportsContext.Provider>
  )
}

export const useConciliationReportsContext = () => {
  const context = useContext(ConciliationReportsContext)

  if (!context) {
    throw new Error(
      'useConciliationReportsContext must be used within a ConciliationReportsContext'
    )
  }

  return context
}

export default ConciliationReportsContainer
