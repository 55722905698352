import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import WorkspacesList from 'containers/Workspaces/WorkspacesContainer/WorkspacesContainer';

import { ROUTES } from '../../../constants';

import './style.scss';

interface Props { };

const WorkspacesScreen: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const [fetching, setFetching] = useState(false);

    const onRouteChange = (action: string, id: number = 0) => {
        if (action === "add") 
            navigate(ROUTES.ADD_WORKSPACE);
        if (action === "edit")
            navigate(`${ROUTES.WORKSPACE_DETAILS}?id=${id}`);
        if (action === "error") 
            navigate(ROUTES.HOME, { replace: true });
    }

    return (
        <>
        <Grid container spacing={0} className="screen-workspaces">
            <Grid item xs={12}>
                <div className="title-container">
                    <h1>Mis espacios de trabajo</h1>
                    {fetching && <CircularProgress size={30} color="primary" />}
                </div>
            </Grid>
            <Grid item xs={12}>
                <WorkspacesList 
                    onRedirect={onRouteChange} 
                    onFetching={setFetching}
                />
            </Grid>
        </Grid>
        </>
    )
}

export default WorkspacesScreen;