import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDidUpdate } from 'rooks'
import moment from 'moment'
import _ from 'lodash'
import ErrorI from '@mui/icons-material/ErrorOutline'
import { Card, Divider, Grid, Tabs, Tab } from '@mui/material'
import { useThunkDispatch } from 'models/thunk'
import {
  selectDocumentRequestActiveTab,
  selectDocumentRequestCatalogue,
  selectDocumentRequestInitialList,
  selectDocumentRequestMonth,
  selectDocumentRequestOrigin,
  selectDocumentRequestSelectedList,
  selectDocumentRequestYear,
} from 'lib/helpers/selectors'
import useDialog from 'hooks/Shared/useDialog'
import useUpdateAccountingRequestedDocuments from 'hooks/Accounting/useUpdateAccountingRequestedDocuments'
import {
  addToSelectedList,
  getRequestDocumentCatalogue,
  removeFromSelectedList,
  resetDocumentRequest,
  setActiveTab,
  setSelectedList,
} from 'actions/documentRequest'
import { setRefetchAccountingProcess } from 'store/Accounting/accounting.reducer'
import { selectCurrentAccountingProcess } from 'store/Accounting/accounting.selectors'

import Button from 'components/Shared/Button/Button'
import DropdownCheckboxGroup, {
  CheckboxAlt,
} from 'components/FormComponents/DropdownCheckboxGroup/DropdownCheckboxGroup'
import './style.scss'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const DocumentRequestContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()

  const origin = useSelector(selectDocumentRequestOrigin)
  const activeTab = useSelector(selectDocumentRequestActiveTab)
  const initialList = useSelector(selectDocumentRequestInitialList)
  const catalogue = useSelector(selectDocumentRequestCatalogue)
  const selectedList = useSelector(selectDocumentRequestSelectedList)
  const currentAccountingProcess = useSelector(selectCurrentAccountingProcess)
  const month = useSelector(selectDocumentRequestMonth)
  const year = useSelector(selectDocumentRequestYear)

  const accountingId = currentAccountingProcess?.id

  const [loading, setLoading] = useState(false)

  const getInformation = async () => {
    setLoading(true)
    try {
      await thunkDispatch(getRequestDocumentCatalogue())
      setLoading(false)
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      const rp = await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo obtener la lista de clientes para el workspace seleccionado.',
        icon: ErrorI as TModalIcon,
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })

      if (rp) {
        setLoading(false)
        getInformation()
      } else {
        setLoading(false)
        onRedirect('error')
      }
    }
  }

  useEffect(() => {
    if (!origin) {
      onRedirect('error')
    } else {
      getInformation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId])

  const filteredCatalogue = useMemo(() => {
    const fc = _.filter(catalogue, c => c.origins.indexOf(origin) > -1)
    dispatch(setActiveTab(0))
    return fc
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogue, origin])

  useDidUpdate(() => {
    const sections: any[] = []
    const elements: any[] = []
    _.forEach(filteredCatalogue, fc => sections.push(...fc.sections))
    _.forEach(sections, section => elements.push(...section.list))
    const prevSelected =
      _.filter(elements, el => initialList.indexOf(el.id) > -1) || []
    dispatch(setSelectedList(prevSelected))
  }, [filteredCatalogue, initialList])

  const { isLoading, isFetching } = useUpdateAccountingRequestedDocuments({
    workspaceId,
    currentAccountingProcess,
    list: _.map(selectedList, rd => rd.id),
  })

  const isUpdateRequestedDocumentsLoading = isLoading || isFetching

  const selectedIds = useMemo(() => {
    const ids = _.map(selectedList, rd => rd.id)
    return ids
  }, [selectedList])

  const getDisabledByOrigin = () => {
    switch (origin) {
      case 'accounting':
        return 'disabled'
      default:
        return ''
    }
  }

  const handleGroupCheck = (sectionId: number, value: boolean) => {
    const tab = filteredCatalogue[activeTab]
    const section = _.find(tab.sections, ['id', sectionId])
    if (!section) return
    value && dispatch(addToSelectedList(section.list))
    !value && dispatch(removeFromSelectedList(section.list))
  }
  const handleCheck = (
    sectionId: number,
    elementId: number,
    value: boolean
  ) => {
    const tab = filteredCatalogue[activeTab]
    const section = _.find(tab.sections, ['id', sectionId])
    if (!section) return
    const element = _.find(section.list, ['id', elementId])
    if (!element) return
    value && dispatch(addToSelectedList([element]))
    !value && dispatch(removeFromSelectedList([element]))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (origin === 'accounting' && !_.isNil(accountingId)) {
        // const mappedList = _.map(selectedList, rd => rd.id)

        // await updateRequestedDocumentList(workspaceId, accountingId, mappedList)
        dispatch(resetDocumentRequest())
        dispatch(setRefetchAccountingProcess(true))
        onRedirect('accounting')
      }
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle:
          'No se pudo actualizar la lista de documentos. Inténtalo más tarde.',
        icon: ErrorI as TModalIcon,
        primaryButtonText: 'De acuerdo',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Card elevation={1} className="container-required-documentation">
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={8}>
            <div className="container">
              <p className="title">
                Selecciona los documentos que deseas solicitar al cliente.
              </p>
            </div>
            <Tabs
              className="container tabs"
              value={activeTab}
              onChange={(_e: any, newValue: number) =>
                dispatch(setActiveTab(newValue))
              }>
              {_.map(filteredCatalogue, fc => (
                <Tab id={`${fc.label}-${fc.id}`} key={fc.id} label={fc.label} />
              ))}
            </Tabs>
            <Divider />
            <div className="container out-tab">
              <div className="date-container">
                <div className={`year ${getDisabledByOrigin()}`}>{year}</div>
                <div className={`month ${getDisabledByOrigin()}`}>
                  {_.capitalize(moment(month, 'M').format('MMMM'))}
                </div>
              </div>
            </div>
            {_.map(filteredCatalogue, (fc, i) => (
              <div key={i} className="container tab" hidden={activeTab !== i}>
                {_.map(fc.sections, fs => (
                  <DropdownCheckboxGroup
                    id={fs.id}
                    key={fs.id}
                    label={fs.label}
                    onGroupCheck={handleGroupCheck}
                    onCheck={handleCheck}
                    list={_.map(fs.list, el => ({
                      id: el.id,
                      label: el.label,
                      description: el?.format || '',
                      selected: selectedIds.indexOf(el.id) > -1,
                      show: _.isNil(el.origins)
                        ? true
                        : el.origins.indexOf(origin) > -1,
                    }))}
                  />
                ))}
              </div>
            ))}
          </Grid>
          <Grid item xs className="container border-left border-bottom">
            <p className="title">Documentos seleccionados</p>
            {selectedList.length === 0 && (
              <p className="text">No hay documentos para mostrar.</p>
            )}
            {_.map(selectedList, (el, i) => (
              <CheckboxAlt
                key={i}
                id={el.id}
                label={el.label}
                description={el?.format}
                selected={true}
                sectionId={el?.section || 0}
                onCheck={handleCheck}
              />
            ))}
          </Grid>
          <Grid item xs={8}>
            <div className="actions border-top">
              <Button
                variant="contained"
                color="secondary"
                disabled={selectedList.length === 0}
                activity={loading || isUpdateRequestedDocumentsLoading}
                onClick={handleSubmit}>
                Finalizar
              </Button>
            </div>
          </Grid>
          <Grid item xs={4} className="container centered border-left">
            <p>
              <span className="header">Total documentos:</span>{' '}
              {selectedList.length}
            </p>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default DocumentRequestContainer
