import update from 'immutability-helper'
import { SET_GLOBAL_LOADER, SET_LOCAL_LOADER } from 'actions/loader'

/** interfaces */
import { LoaderStore } from 'models/redux'
import { ReduxAction } from 'models/thunk'

const initialLoader: LoaderStore = {
  global: {
    show: true,
    message: '',
  },
  local: {
    show: false,
    message: '',
  },
}

const defaultAction: ReduxAction<any> = {
  type: '',
  value: '',
}

const loader = (state = initialLoader, action = defaultAction) => {
  switch (action.type) {
    case SET_GLOBAL_LOADER:
      return update(state, {
        global: {
          show: { $set: action.value.show },
          message: { $set: action.value.message },
        },
      })
    case SET_LOCAL_LOADER:
      return update(state, {
        local: {
          show: { $set: action.value.show },
          message: { $set: action.value.message },
        },
      })
    default:
      return state
  }
}

export default loader
