//
import { useMutation } from '@tanstack/react-query'
import ErrorI from '@mui/icons-material/ErrorOutline'
import { MutationOptions } from 'models/general'
import Invoice from 'models/invoice'
import { downloadFileFromData } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { getCfdisFiles } from 'services/invoices'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

interface IUseDownloadInvoicesFilesVariables {
  workspaceId: number
  selectedList: Invoice[]
}

export const useDownloadInvoicesFiles = (
  options: MutationOptions<string, any, IUseDownloadInvoicesFilesVariables> = {}
) => {
  const { onSuccess, onError, noDialog } = options

  const dialog = useDialog()
  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const mutation = useMutation<string, any, IUseDownloadInvoicesFilesVariables>(
    ({ workspaceId, selectedList }) => {
      return getCfdisFiles(
        workspaceId,
        selectedList.map(invoice => invoice.uuid),
        selectedList
      )
    },
    {
      onMutate: () => {
        showAdditionalInformationLoading()
      },
      onSuccess: (file, variables, ctx) => {
        dialog.close()
        onSuccess && onSuccess(file, variables, ctx)
        downloadFileFromData(
          'Facturas.zip',
          `data:application/zip;base64,${file}`
        )
      },
      onError: async (error, variables, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              title: 'generic.error.title',
              subtitle: 'No se pudieron descargar los CFDIs seleccionados.',
              icon: ErrorI as TModalIcon,
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(variables)
              return
            }
          }

          onError && onError(error, variables, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export default useDownloadInvoicesFiles
