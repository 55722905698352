import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';

const DetailsItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <Box>
      <Typography
        style={
          {
            fontSize: 14,
          }
        }
      >
        {label}
      </Typography>
      <Typography
        style={
          {
            fontSize: 16,
          }
        }
      >
        {value}
      </Typography>
    </Box>
  );
};

export default DetailsItem;