import React, { useEffect } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel, { FormLabelProps } from '@material-ui/core/FormLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import MUIRadioGroup from '@material-ui/core/RadioGroup';
import Radio, { RadioProps } from '@material-ui/core/Radio';

import './style.scss';

interface RadioOptions {
  id: string
  label: string
  className?: string
  disabled?: boolean
  labelPlacement?: "bottom" | "end" | "start" | "top"
}

interface RadioGroupProps extends IOInputProps {
  radioOptions: RadioOptions[]
  radioProps?: Omit<RadioProps, "checked" | "required">
  formControlProps?: Omit<FormControlProps, "disabled" | "error" | "required">
  formLabelProps?: FormLabelProps
  labelPlacement?: "bottom" | "end" | "start" | "top"
  className?: string
  row?: boolean
  listener?: (value: any) => void
}

const _RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    // ioProps: { name, required, invalid, message, defaultValue }, 
    ioProps: { name, required, defaultValue },
    formControlProps,
    formLabelProps,
    radioProps,
    label,
    setValue,
    value,
    radioOptions,
    disabled,
    className,
    listener,
    row
  } = props;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setValue(value);
    listener && listener(value)
  }
  return (
    <FormControl {...formControlProps} required={required} disabled={disabled} className={`component-radio-group ${className || ""}`.trim()}>
      {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <MUIRadioGroup row={row} value={value} id={name} name={name} onChange={onChange}>
        {_.map(radioOptions, (o, i) => (
          <FormControlLabel
            key={i}
            id={o.id}
            label={o.label}
            value={o.id}
            control={<Radio {...radioProps} />}
            labelPlacement={o.labelPlacement}
            disabled={o.disabled}
            className={o.className}
          />
        ))}
      </MUIRadioGroup>
      {/* {invalid &&
                <FormHelperText error={invalid}>
                    {message}
                </FormHelperText>
            } */}
    </FormControl>
  )
}

const RadioGroup = withIO('radiogroup')(_RadioGroup);
export default RadioGroup;