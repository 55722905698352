import { useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowUpI from '@mui/icons-material/ArrowDropUp'
import ArrowDownI from '@mui/icons-material/ArrowDropDown'
import ErrorI from '@mui/icons-material/ErrorOutline'
import { Divider, Grid } from '@mui/material'
import { UploadedFile } from 'models/general'
import { isUniqueString } from 'lib/helpers/ioValidations'
import { ACCOUNTING_FILE_TYPE_CATALOGUE } from 'lib/utils/Accounting/accounting.utils'
import useDialog from 'hooks/Shared/useDialog'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import Button from 'components/Shared/Button/Button'
import { TaskAlt } from 'components/Shared/CustomIcons'
import FileCard, { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import SydDialog, { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import FileValidationCard from '../FileValidationCard/FileValidationCard'
import './styles.scss'
import ISydDocument from 'models/shared.models'

const AccountingStepFour = () => {
  const intl = useIntl()
  const dialog = useDialog()

  const {
    stepChangeLoading,
    filesToUpload,
    filesInProcess,
    currentAccountingProcess,
    handleStepBack,
    handleStepSubmit,
    handleFileUpload,
    handleFileDelete,
    handleFileRename,
    handleFileValidation,
    handleFileDownload,
  } = useAccountingContext()

  const comment = currentAccountingProcess?.comments

  const [limitFileView, setLimitFileView] = useState(true)
  const [commentsDialog, setCommentsDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState<ISydDocument>()
  const [validCommentsDialog, setValidCommentsDialog] = useState(false)
  const [validRenameDialog, setValidRenameDialog] = useState(false)

  const expectedFileType = _.find(ACCOUNTING_FILE_TYPE_CATALOGUE, ['id', 3])

  const formatName = (file: UploadedFile | ISydDocument) =>
    `${file.name}.${file.extension.toUpperCase()}`
  const formatDate = (dateString?: string) => {
    const date = dateString ? new Date(_.split(dateString, '.')[0]) : new Date()
    const formatted = intl.formatDate(date, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }

  const filesToValid =
    _.filter(
      filesInProcess,
      f =>
        !f.validated &&
        f.statusText !== 'false' &&
        f?.description === expectedFileType?.type
    ) || []
  const uploadedFiles =
    _.filter(
      filesInProcess,
      f =>
        !f.validated &&
        f.statusText === 'false' &&
        f?.description === expectedFileType?.type
    ) || []
  const invalidFiles =
    _.filter(
      filesInProcess,
      f =>
        f.validated &&
        f.statusText === 'invalid' &&
        f?.description === expectedFileType?.type
    ) || []
  const previousUploadesFiles =
    _.filter(filesInProcess, f => f?.description !== expectedFileType?.type) ||
    []

  const listForFileNameValidation = _.map(
    [...uploadedFiles, ...previousUploadesFiles],
    d => d.name
  )

  const allFilesChecked = _.reduce(
    filesToValid,
    (acc, f) => acc && !_.isUndefined(f.statusText) && f.statusText !== 'false',
    filesToValid.length > 0
  )
  const allFilesValid = _.reduce(
    filesToValid,
    (acc, f) => acc && f.statusText === 'valid',
    filesToValid.length > 0
  )
  const withComment = Boolean(comment)

  const handleCommentsFormSubmit = async (form: any) => {
    handleStepSubmit(5, form.comment, undefined, filesToValid)
    setCommentsDialog(false)
  }

  const handleRenameFormSubmit = async (form: any) => {
    if (!selectedFile) return
    handleFileRename(form.name, selectedFile)
    setSelectedFile(undefined)
  }

  const handleSkipSubmit = async () => {
    const resp = await dialog.dangerAsync({
      title: '¿Omitir la Documentación Contable?',
      message: 'Podrás subirlos en el apartado correspondiente',
      icon: ErrorI as TModalIcon,
      primaryButtonText: 'Omitir Documentación Contable',
      secondaryButtonText: 'Regresar',
    })
    resp && handleStepSubmit(5)
  }

  return (
    <div className="step-container step-four">
      <div className="content">
        <p className="step-description">
          {filesToValid.length > 0
            ? 'Valida la “Documentación Contable” cargada por el contador.'
            : 'Espera a que el contador suba la "Documentación Contable".'}
        </p>

        <Grid container direction="row">
          {withComment && (
            <Grid item xs={12} className="step-comments">
              <div className="title-comments">
                <p>Comentarios cliente:</p>
              </div>
              <div className="comments">
                <p>{comment}</p>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container direction="row" spacing={2}>
          {uploadedFiles.length === 0 &&
            filesToUpload.length === 0 &&
            filesToValid.length > 0 &&
            _.map(filesToValid, (f, i) => (
              <Grid item xs={4} key={i}>
                <FileValidationCard
                  variant="outlined"
                  title={formatName(f)}
                  valid={f?.statusText === 'valid'}
                  invalid={f?.statusText === 'invalid'}
                  loading={f?.activity}
                  onValidate={v => handleFileValidation(f, v)}
                  onDownload={() => handleFileDownload(f)}
                />
              </Grid>
            ))}
          {uploadedFiles.length === 0 &&
            filesToUpload.length === 0 &&
            filesToValid.length === 0 && (
              <Grid item xs={12} className="uploaded-documentation-list">
                <FileUploader
                  className="drop-zone"
                  color="secondary"
                  label="Subir archivos"
                  onValue={handleFileUpload}
                  type={expectedFileType?.type}
                />
              </Grid>
            )}
          {(uploadedFiles.length > 0 || filesToUpload.length > 0) &&
            filesToValid.length === 0 && (
              <>
                <Grid item xs={4} className="uploaded-documentation-list">
                  <FileUploader
                    className="drop-zone"
                    color="secondary"
                    label="Subir archivos"
                    onValue={handleFileUpload}
                    type={expectedFileType?.type}
                  />
                </Grid>
                {_.map(uploadedFiles, (f, i) => (
                  <Grid
                    item
                    xs={4}
                    key={i}
                    className="uploaded-documentation-list">
                    <FileCardAlt
                      title={formatName(f)}
                      subtitle={formatDate(f.createdAt)}
                      type={'File'}
                      onFileClick={() => handleFileDownload(f)}
                      actions={[
                        {
                          label: 'Renombrar archivo',
                          action: () => setSelectedFile(f),
                        },
                        {
                          label: 'Eliminar archivo',
                          action: () => handleFileDelete(f),
                        },
                      ]}
                      error={f.error}
                      loading={f.activity}
                      className="file-zone"
                    />
                  </Grid>
                ))}
                {_.map(filesToUpload, (f, i) => (
                  <Grid
                    item
                    xs={4}
                    key={i}
                    className="uploaded-documentation-list">
                    <FileCardAlt
                      title={formatName(f)}
                      subtitle={formatDate()}
                      type={'File'}
                      error={f.error}
                      loading={f.activity}
                      className="file-zone"
                    />
                  </Grid>
                ))}
              </>
            )}
          <Grid item xs={12}>
            <p className="step-indicator">Documentación contable (Contador)</p>
            <div className="step-buttons-actions">
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleStepBack}
                activity={stepChangeLoading}>
                Ir a la sección anterior
              </Button>

              <div className="buttons-actions-right">
                {uploadedFiles.length === 0 && filesToValid.length === 0 && (
                  <Button
                    id="skipDocumentationBtn"
                    variant="outlined"
                    color="warning"
                    onClick={() => handleSkipSubmit()}
                    activity={stepChangeLoading}>
                    Omitir Documentación Contable
                  </Button>
                )}
                {filesToValid.length > 0 && (
                  <Button
                    id="continueBtn"
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      allFilesValid
                        ? handleStepSubmit(5)
                        : setCommentsDialog(true)
                    }
                    disabled={!allFilesChecked}
                    activity={stepChangeLoading}>
                    Continuar
                  </Button>
                )}
                {uploadedFiles.length > 0 && (
                  <Button
                    id="finishBtn"
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleStepSubmit(
                        5,
                        undefined,
                        uploadedFiles,
                        invalidFiles
                      )
                    }
                    activity={stepChangeLoading}>
                    Finalizar carga
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12}>
            <Divider className="step-divider" />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container spacing={2} className="documentation-list">
              <Grid item xs={12}>
                <div className="documentation-title-container">
                  <TaskAlt color="secondary" fontSize="large" />
                  <h4>Documentos cargados</h4>
                </div>
              </Grid>
              {_.map(previousUploadesFiles, (f, i) =>
                limitFileView && i > 3 ? (
                  <></>
                ) : (
                  <Grid item xs={3} key={i}>
                    <FileCard
                      title={formatName(f)}
                      subtitle={formatDate(f.createdAt)}
                      type={'File'}
                      actions={[
                        {
                          label: 'Descargar archivo',
                          action: () => handleFileDownload(f),
                        },
                      ]}
                    />
                  </Grid>
                )
              )}
              {previousUploadesFiles.length > 4 && (
                <Grid item xs={12} className="dropdown-action">
                  <Button
                    id="seeAllBtn"
                    color="secondary"
                    endIcon={limitFileView ? <ArrowDownI /> : <ArrowUpI />}
                    onClick={() => setLimitFileView(!limitFileView)}>
                    {limitFileView ? 'Ver todos' : 'Ver menos'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <SydDialog
        open={commentsDialog}
        title="Agregar comentarios"
        subtitle="Antes de terminar la revisión puedes agregar comentarios para ayudar al cliente a completar la documentación."
        primaryButtonText="Agregar"
        disablePrimaryButton={!validCommentsDialog}
        secondaryButtonText="Cancelar"
        onCancel={() => {
          setCommentsDialog(false)
          setValidCommentsDialog(false)
        }}
        form="commentsForm"
        sideButtons
        fullWidth>
        <Form
          id="commentsForm"
          onSubmit={handleCommentsFormSubmit}
          onValidity={(v: boolean) => setValidCommentsDialog(v)}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="comment"
                inputProps={{ maxLength: 500 }}
                multiline
                minRows={5}
                maxRows={5}
                withCounter
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>

      <SydDialog
        open={Boolean(selectedFile)}
        size="sm"
        title="Renombrar un archivo"
        primaryButtonText="Renombrar"
        disablePrimaryButton={!validRenameDialog}
        secondaryButtonText="Cancelar"
        onCancel={() => {
          setSelectedFile(undefined)
          setValidRenameDialog(false)
        }}
        form="renameFileForm"
        sideButtons>
        <Form
          id="renameFileForm"
          onSubmit={handleRenameFormSubmit}
          onValidity={(v: boolean) => setValidRenameDialog(v)}
          reset>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nombre del archivo"
                value={selectedFile?.name}
                validate={(v: string) =>
                  isUniqueString(v, listForFileNameValidation)
                }
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </SydDialog>
    </div>
  )
}

export default AccountingStepFour
