import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { IConektaCard, IConektaTokenResponse } from 'models/payments'
import { successToastOptions } from 'lib/utils/toast.utils'
import { IApiError } from 'services/Shared/shared.models'
import { createPaymentOrder } from 'services/Payments/payments.services'
import { IUsePaymentsContainer } from 'containers/Account/Payments/PaymentsContainer/usePaymentsContainer'
import useDialog from 'hooks/Shared/useDialog'

interface IUseConektaTokenizeCardProps
  extends Pick<
    IUsePaymentsContainer,
    | 'refetchBillingInformation'
    | 'refetchPaymentsHistory'
    | 'goBackToPaymentsOverview'
  > {
  activeWorkspace: number
  idPlan: number
  periodEnd: string
  periodStart: string
  recurrent: boolean
}

const useConektaTokenizeCard = ({
  refetchBillingInformation,
  refetchPaymentsHistory,
  goBackToPaymentsOverview,
  activeWorkspace,
  idPlan,
  periodEnd,
  periodStart,
  recurrent,
}: IUseConektaTokenizeCardProps): UseMutationResult<
  void,
  IApiError,
  IConektaCard
> => {
  const dialog = useDialog()

  const cleanup = () => {
    refetchBillingInformation()
    refetchPaymentsHistory()
    goBackToPaymentsOverview()
  }

  const mutation = useMutation<void, IApiError, IConektaCard>(
    async requestBody => {
      const tokenizedCardHeaders = new Headers()
      tokenizedCardHeaders.append('Accept-Language', 'es')
      tokenizedCardHeaders.append('content-type', 'application/json')
      tokenizedCardHeaders.append(
        'accept',
        'application/vnd.conekta-v2.1.0+json'
      )
      tokenizedCardHeaders.append(
        'Conekta-Client-User-Agent',
        '{"agent":"Conekta JavascriptBindings-AJAX/v2.0.0 build 2.0.17"}'
      )
      tokenizedCardHeaders.append(
        'authorization',
        `Basic ${window.btoa(process.env.REACT_APP_CONEKTA_API_KEY!)}`
      )

      const tokenizedCardResponse = await fetch(
        'https://api.conekta.io/tokens',
        {
          method: 'POST',
          body: JSON.stringify({
            card: requestBody,
          }),
          headers: tokenizedCardHeaders,
        }
      ).then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response
      })

      const tokenizedCardData: IConektaTokenResponse =
        await tokenizedCardResponse.json()

      const makePayment = {
        idPlanToPay: idPlan,
        recurrent,
        periodStart,
        periodEnd,
        tokenCard: tokenizedCardData.id,
      }

      return await createPaymentOrder(activeWorkspace, makePayment)
    },
    {
      onSuccess: () => {
        const message = recurrent ? 'Domiciliación exitosa : ' : 'Pago exitoso'
        cleanup()
        toast.success(message, successToastOptions)
      },

      onError: () => {
        const errorTitle = recurrent
          ? 'No fue posible realizar la domiciliación'
          : 'No se pudo realizar el pago'
        const errorMessage = recurrent
          ? 'Por favor intenta de nuevo'
          : 'Algo falló al intentar realizar el pago. Por favor intenta de nuevo más tarde'

        dialog.danger({
          title: errorTitle,
          subtitle: errorMessage,
          onPrimaryClick: cleanup,
          secondaryButtonText: 'Cancelar',
        })
      },
    }
  )

  return mutation
}

export default useConektaTokenizeCard
