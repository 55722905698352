import { useMutation } from '@tanstack/react-query'
import { MutationOptions } from 'models/general'
import Invoice, { InvoiceFiles } from 'models/invoice'
import { downloadFileFromData } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { getCfdiFiles } from 'services/invoices'
import useLoadingAdditionalInformation from 'hooks/Shared/useLoadingAdditionalInformation'

interface IUseDownloadInvoiceFilesVariables {
  workspaceId: number
  selectedInvoice: Invoice
}

export const useDownloadInvoiceFiles = (
  options: MutationOptions<
    InvoiceFiles,
    any,
    IUseDownloadInvoiceFilesVariables
  > = {}
) => {
  const dialog = useDialog()

  const showAdditionalInformationLoading = useLoadingAdditionalInformation()

  const { onSuccess, onError, noDialog } = options

  const mutation = useMutation<
    InvoiceFiles,
    any,
    IUseDownloadInvoiceFilesVariables
  >(
    ({ workspaceId, selectedInvoice }) => {
      if (!selectedInvoice) throw new Error('CFDI cargado incorrectamente')
      return getCfdiFiles(workspaceId, selectedInvoice)
    },
    {
      onMutate: () => {
        showAdditionalInformationLoading()
      },
      onSuccess: (files, variables, ctx) => {
        dialog.close()
        const { workspaceId, selectedInvoice } = variables
        onSuccess && onSuccess(files, { workspaceId, selectedInvoice }, ctx)
        downloadFileFromData(`${selectedInvoice?.uuid || 'PDF'}.pdf`, files.pdf)
        downloadFileFromData(`${selectedInvoice?.uuid || 'XML'}.xml`, files.xml)
      },
      onError: async (error, variables, ctx) => {
        setTimeout(async () => {
          const op = selectErrorDialog(error)
          if (op?.variant) {
            await dialog[op.variant](op)
            return
          }

          if (!noDialog) {
            const rp = await dialog.dangerAsync({
              subtitle: 'No se pudo descargar el CFDI seleccionado.',
              primaryButtonText: 'Reintentar',
              secondaryButtonText: 'Cancelar',
            })

            if (rp) {
              mutation.mutate(variables)
              return
            }
          }

          onError && onError(error, variables, ctx)
        }, 600)
      },
    }
  )

  return mutation
}

export default useDownloadInvoiceFiles
