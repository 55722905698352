import _ from 'lodash'
import HTTPClient from 'lib/helpers/HTTPClient'
import {
  getInventoryFlag,
  getInvoiceAmount,
  getInvoiceQuantity,
  getRoleInWorkspace,
  httpErrorParser,
  parseWorkspace,
  sleep,
} from 'lib/helpers/utilities'

import Workspace, {
  WorkspaceForm,
  InvitationForm,
  EditColaboratorForm,
} from 'models/workspace'
import ReduxState from 'models/redux'
import { WorkspaceSections } from 'lib/enums/shared.enums'

export const SET_CREATE_FLAG = '@Workspace/SET_CREATE_FLAG'
export const SET_CREATE_FREETRIAL = '@Workspace/SET_CREATE_FREETRIAL'
export const SET_CREATE_STEP = '@Workspace/SET_CREATE_STEP'
export const SET_CREATE_FORM_VALUE = '@Workspace/SET_CREATE_FORM_VALUE'
export const RESET_CREATE_FORM = '@Workspace/RESET_FORM_VALUE'

export const ADD_WORKSPACE_TO_LIST = '@Workspace/ADD_WORKSPACE_TO_LIST'
export const SET_WORKSPACE_LIST = '@Workspace/SET_WORKSPACE_LIST'
export const SET_SELECTED_WORKSPACE = '@Workspace/SET_SELECTED_WORKSPACE'
export const SET_ACTIVE_WORKSPACE = '@Workspace/SET_ACTIVE_WORKSPACE'
export const SET_ACTIVE_TAB = '@Workspace/SET_ACTIVE_TAB'

const api = process.env.REACT_APP_APIURL || '/api'

export const setCreateFlag = (value: boolean): object => ({
  type: SET_CREATE_FLAG,
  value,
})
export const setCreateFREETRIAL = (value: boolean): object => ({
  type: SET_CREATE_FREETRIAL,
  value,
})
export const setCreateStep = (id: number): object => ({
  type: SET_CREATE_STEP,
  value: id,
})
export const setCreateFormValue = (name: string, value: any): object => ({
  type: SET_CREATE_FORM_VALUE,
  value: { name, value },
})
export const resetCreateForm = (): object => ({
  type: RESET_CREATE_FORM,
})
export const setWorkspaceList = (list: Workspace[]): object => ({
  type: SET_WORKSPACE_LIST,
  value: list,
})
export const setSelectedWorkspace = (
  value: Workspace | null = null
): object => ({
  type: SET_SELECTED_WORKSPACE,
  value,
})
export const setActiveWorkspace = (id: number): object => ({
  type: SET_ACTIVE_WORKSPACE,
  value: id,
})
export const setActiveTab = (value: WorkspaceSections): object => ({
  type: SET_ACTIVE_TAB,
  value,
})

export const getWorkspaceById =
  (id: number, noSave: boolean = false) =>
  async (dispatch: any, getStore: () => ReduxState): Promise<Workspace> => {
    const client = HTTPClient.getClient()
    const store = getStore()
    try {
      const petition = await client.get(`${api}/workspaces/${id}/details`)
      const data = await petition.json()
      const information = data['workspace:']
      const details = {
        userList: data['userList:'],
        invitationList: data['InvitationList:'],
      }
      const userId = store.user.data.idUser
      const role = getRoleInWorkspace(userId, data['userList:'])
      const ws = parseWorkspace(information, role, details)
      !noSave && dispatch(setSelectedWorkspace(ws))
      return ws
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getWorkspaceList =
  (noSave: boolean = false) =>
  async (dispatch: any, getStore: () => ReduxState): Promise<Workspace[]> => {
    const client = HTTPClient.getClient()
    const store = getStore()
    try {
      const petition = await client.get(`${api}/workspaces`)
      const data = await petition.json()
      const wsList = _.map(data, ws => parseWorkspace(ws.workspaces, ws.roles))
      let active = store.workspace.activeWorkspace
      !noSave && dispatch(setWorkspaceList(wsList))
      if (!_.filter(wsList, ws => ws.id === active).length) {
        active = 0
      }
      !noSave && dispatch(setActiveWorkspace(active))
      return wsList
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

// ToDo: Se necesita adaptar los multiples planes de un Workspace
export const createWorkspace =
  () =>
  async (_dispatch: any, getStore: () => ReduxState): Promise<Workspace> => {
    const client = HTTPClient.getClient()
    const { workspace } = getStore()
    const { form } = workspace
    try {
      let files: any = []
      files.push({ ...form.fielCer, type: 'cerFiel' })
      files.push({ ...form.fielKey, type: 'keyFiel' })
      !!form.csdCer && files.push({ ...form.csdCer, type: 'cerCsd' })
      !!form.csdKey && files.push({ ...form.csdKey, type: 'keyCsd' })
      console.log(form)
      const body = {
        name: form.workspace,
        acceptContract: form.acceptContract,
        plan: form.plan,
        free: form.freeTrial,
        invoiceAmount: `${getInvoiceAmount(form)}`,
        invoiceQuantity: `${getInvoiceQuantity(form)}`,
        withInventory: getInventoryFlag(form),
        promotionalCode: form.promotionalCode,
        rfcs: [
          {
            idType: form.type?.idCatalogValues,
            name: form.name,
            rfc: form.rfc,
            taxSystem: '1231231',
            idTaxResidence: form.taxSystem?.idCatalogValues,
            zipCode: form.zipCode,
            files: files,
            passwordFiel: form.passwordFiel,
            ciec: form.ciec,
            passwordCsd: form.passwordCsd,
          },
        ],
      }

      const petition = await client.post(`${api}/workspaces`, body)
      const data = await petition.json()
      return parseWorkspace(data)
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const updateWorkspace =
  (workspaceId: number, form: WorkspaceForm) =>
  async (): Promise<Workspace> => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        name: form.name,
      }
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}`,
        body
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const deleteWorkspace =
  (workspaceId: number) =>
  async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.delete(`${api}/workspaces/${workspaceId}`)
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const scheduleCall =
  (phone: string, hour: string) => async (_dispatch: any) => {
    // const client = HTTPClient.getClient();
    try {
      await sleep(3000)
      const body = { phone, hour }
      console.log(body)
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const sendInvitation =
  (workspaceId: number, form: InvitationForm) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        email: form.email,
        message: '',
        rol: form.role,
      }
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/add_user`,
        body
      )
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const acceptInvitation =
  (invitationId: number) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.post(`${api}/invitations/${invitationId}`)
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const rejectInvitation =
  (invitationId: number) => async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.delete(`${api}/invitations/${invitationId}`)
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const updateColaborator =
  (workspaceId: number, colaboratorId: number, form: EditColaboratorForm) =>
  async (_dispatch: any) => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        roleName: form.role,
      }
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}/details/${colaboratorId}`,
        body
      )
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const deleteColaborator =
  (workspaceId: number, colaboratorId: number) =>
  async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.delete(
        `${api}/workspaces/${workspaceId}/details/${colaboratorId}`
      )
      await petition.data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getWorkspaceDashboard =
  (id: number) => async (): Promise<Workspace[]> => {
    const client = HTTPClient.getClient()
    try {
      console.log(id)
      const petition = await client.get(
        `${api}/workspaces/${id}/dashboardhttp://localhost:8088/simmple/v1/workspaces/16/dashboard?request_type=serie&type=pieChart&data_type=payable&idRfc=25&period=anual&lapse=1-6&year=2022&origin=taxpayer`
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }
