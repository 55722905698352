import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import ISydDocument from 'models/shared.models'
import { parseDocument } from 'lib/helpers/utilities'
import {
  IDownloadWorkspaceDocumentRequest,
  IinitializeSSEInstanceResponse,
  IWorkspaceRequest,
} from './shared.models'

const route = process.env.REACT_APP_APIURL || '/api/'

export const syncMultipleWithSAT = async (
  workspaceId: number,
  body: {
    year?: number
    startMonth?: number
    endMonth?: number
    request: IWorkspaceRequest[]
  }
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/workspaces/${workspaceId}/sat/request/many`
    return await client.post(url, body)
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getCIFService = async (workspaceId: number): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/workspaces/${workspaceId}/CIF`
    return await client.get(url)
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getComplianceOpinionsService = async (
  workspaceIds: number[]
): Promise<string> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/compliance?workspaces=${workspaceIds.join(',')}`
    const petition = await client.get(url)
    return await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const synchronizeComplianceOpinionsStatusService = async (
  workspaces: IWorkspaceRequest[]
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/compliance`
    return await client.post(url, {
      request: workspaces,
    })
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const initializeSSEInstance = async (): Promise<string> => {
  const client = HTTPClient.getClient()
  try {
    const url = `${route}/events`
    const petition = await client.post(url)
    const response: IinitializeSSEInstanceResponse =
      await petition.json()
    return response.clientId
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getDocumentationFileByIdService = async ({
  workspaceId,
  fileId,
}: IDownloadWorkspaceDocumentRequest): Promise<ISydDocument> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/wsfiles/download?idWsfile=${fileId}`
    )
    const data: any = await petition.json()
    return parseDocument(data)
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}
