// import { Tab, TabProps, styled } from '@material-ui/core';

import { Tab, TabProps, styled } from '@mui/material'

interface StyledTabProps extends TabProps {
  id: string
  label: string
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  textTransform: 'none',
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 400,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}))

export default StyledTab
