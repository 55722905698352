import React from 'react'
import { useThunkDispatch } from 'models/thunk'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import moment from 'moment'

import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'

import { getIndicator } from 'actions/dashboard'

import './style.scss'

import { IndicatorStatistics } from 'models/dashboard'

interface Props {
  workspaceId: number
}

const MonthlyIncomeContainer = ({ workspaceId }: Props) => {
  const thunkDispatch = useThunkDispatch()
  const intl = useIntl()

  const { data, ...query } = useQuery<IndicatorStatistics>(
    ['get-monthly-income', workspaceId],
    () => {
      const today = moment()
      const year = today.year()
      return thunkDispatch(
        getIndicator(workspaceId, 'monthlyIncome', { year: year })
      )
    }
  )

  const formatMoney = (money: number) => {
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }

  const indicator = data?.indicator || 'equal'

  return (
    <DashboardCard
      className="monthly-income-container"
      title="Ingresos promedio mensuales"
      loading={query.isLoading}
      fetching={query.isFetching}
      indicator={indicator}
      value={data?.value ? formatMoney(data?.value) : '$--.--'}
      extra="MXN"
    />
  )
}

export default MonthlyIncomeContainer
