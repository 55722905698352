import { ReactNode } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import './styles.scss'

interface SideModalProps {
  isOpen: boolean
  children: ReactNode
  className?: string
  onClose: () => void
}

const SideModal = ({
  isOpen,
  children,
  className,
  onClose,
}: SideModalProps) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className={`side-modal-overlay ${className ?? ''}`} onClick={onClose}>
      <div className="side-modal" onClick={e => e.stopPropagation()}>
        <div id="header">
          <label>Marca las tareas que ya hayas completado</label>
          <IconButton id="closeIcon" size="small" onClick={onClose}>
            <CloseIcon color="success" />
          </IconButton>
        </div>
        {children}
      </div>
    </div>
  )
}

export default SideModal
