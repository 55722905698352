import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCountdown, useDidUpdate } from 'rooks'
import Account from '@mui/icons-material/AccountCircle'
import CloseI from '@mui/icons-material/Close'
import SmsIcon from '@mui/icons-material/Sms'
import { Drawer, IconButton, Slide } from '@mui/material'
import { ChatMessage } from 'models/accounting.models'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { selectActiveWorkspace, selectUser } from 'lib/helpers/selectors'
import {
  getChatMessages,
  sendChatMessage,
} from 'store/Accounting/accounting.actions'
import { selectCurrentAccountingProcess } from 'store/Accounting/accounting.selectors'
import ChatInputText from 'components/Accounting/ChatInputText/ChatInputText'
import MessagesContainer from '../MessagesContainer/MessagesContainer'
import './style.scss'

const ChatContainer = () => {
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()

  const workspaceId = useSelector(selectActiveWorkspace)
  const user = useSelector(selectUser)
  const currentAccountingProcess = useSelector(selectCurrentAccountingProcess)

  const [visibleChat, setVisibleChat] = useState(false)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [endTime, setEndTime] = useState(new Date(Date.now() + 10000))

  const accountingId = currentAccountingProcess?.id

  const getMessages = async () => {
    if (!accountingId || !visibleChat) return
    setLoading(true)
    setError(false)
    try {
      const msgs = await thunkDispatch(
        getChatMessages(workspaceId, accountingId)
      )
      setMessages(msgs)
    } catch (err: any) {
      setError(true)
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSendMessage = async (message: string) => {
    if (!accountingId || !visibleChat) return
    setLoading(true)
    setError(false)
    try {
      await thunkDispatch(sendChatMessage(workspaceId, accountingId, message))
      const msgs = await thunkDispatch(
        getChatMessages(workspaceId, accountingId)
      )
      setMessages(msgs)
    } catch (err: any) {
      setError(true)
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
    } finally {
      setLoading(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    getMessages()
  }, [workspaceId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    visibleChat && getMessages()
  }, [visibleChat])

  useEffect(() => setEndTime(new Date(Date.now() + 10000)), [messages])

  useCountdown(endTime, {
    interval: 1000,
    onEnd: getMessages,
  })

  return (
    <>
      <Drawer
        anchor="right"
        open={visibleChat}
        SlideProps={{ mountOnEnter: true, unmountOnExit: true }}>
        <div className="chat-container">
          <div className="content">
            <div className="user-wrapper">
              <IconButton
                size="small"
                color="primary"
                onClick={() => setVisibleChat(false)}>
                <CloseI />
              </IconButton>
              <div className="profile">
                <Account
                  className="avatar"
                  color="primary"
                  fontSize="inherit"
                />
                <p className="email">{user?.data?.email ?? ''}</p>
              </div>
            </div>
            <div className="messages-wrapper scrollbar">
              <MessagesContainer
                messages={messages}
                loading={loading}
                error={error}
                onRetry={getMessages}
              />
            </div>
            <div className="input-wrapper">
              <ChatInputText
                name={'message'}
                onSendMessage={handleSendMessage}
                placeholder="Escribe aqui tu mensaje..."
                rows={5}
              />
            </div>
          </div>
        </div>
      </Drawer>

      {!visibleChat && (
        <div
          onClick={() => setVisibleChat(true)}
          className={`chat-button closed`}>
          <SmsIcon />
        </div>
      )}

      <Slide direction="left" in={visibleChat} mountOnEnter unmountOnExit>
        <div
          onClick={() => setVisibleChat(false)}
          className={`chat-button open`}>
          <SmsIcon />
        </div>
      </Slide>
    </>
  )
}

export default ChatContainer
