import React, { useEffect } from 'react';
import { withIO, IOInputProps } from 'react-io-forms';
import _ from 'lodash';

import NumberMask, { NumberFormatProps } from 'react-number-format';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField';

import './style.scss';

//#region Types and Interfaces
type ExcludedKeys = "onChange" | "autoComplete" | "error" | "helperText" | "type";
interface TextFieldProps extends Omit<MUITextFieldProps, ExcludedKeys> { 
    ioProps: IOInputProps
    addon: any
    setValue: (value: any) => void
    format: "money"|"quantity"|"phone"|"date"|"dateY"
}
//#endregion

const NumberFormat: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.value } } as unknown as any)}
        allowNegative={false}
    />
)

const MoneyFormat: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.value } } as unknown as any)}
        thousandSeparator=","
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
    />
)

const QuantityFormat: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.value } } as unknown as any)}
        thousandSeparator=","
        allowNegative={false}
        decimalScale={0}
        fixedDecimalScale
    />
)

const PhoneFormat: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.value } } as unknown as any)}
        allowNegative={false}
        format="(###) ### ####"
    />
)

const DateFormat: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.formattedValue } } as unknown as any)}
        allowNegative={false}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
    />
)

const DateFormatY: React.FC<NumberFormatProps> = ({ inputRef, onChange, ...other }) => (
    <NumberMask
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => onChange && onChange({ target: { value: values.formattedValue } } as unknown as any)}
        allowNegative={false}
        format="####/##/##"
        placeholder="YYYY/MM/DD"
    />
)

const _NumericField: React.FC<TextFieldProps> = (props) => {
    const { 
        ioProps: { name, defaultValue, invalid, message },
        className = "",
        setValue, 
        InputProps,
        addon,
        format,
        ...rest
    } = props;

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue, setValue])

    const hasAnError = invalid && !_.isEmpty(message);

    const getMask = () => {
        switch (format) {
            case "money":
                return MoneyFormat as any;
            case "quantity":
                return QuantityFormat as any;
            case "phone":
                return PhoneFormat as any;
            case "date":
                return DateFormat as any;
            case "dateY":
                return DateFormatY as any;
            default:
                return NumberFormat as any;
        }
    }

    return (
        <div className={`component-numeric-field ${className} ${addon ? "with-addon" : ""}`.trim()}>
            <MUITextField
                id={`numericField-${name}`}
                autoComplete="off"
                className="text-field"
                helperText={message}
                error={hasAnError}
                onChange={(e) => setValue(e.target.value)}
                InputProps={{ 
                    ...InputProps,
                    inputComponent: getMask()
                }}
                {...rest}
            />
            {addon}
        </div>
    )
}

const NumericField = withIO("numericfield")(_NumericField);
export default NumericField;