import { Box } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { useManagementTableStyle } from 'lib/helpers/utilities'
import { PropsWithChildren, ReactNode } from 'react'
import { useAccountantsContext } from '../AccountantsContextProvider/AccountantsContextProvider'
import AccountantsTableFooter from '../AccountantsTableFooter/AccountantsTableFooter'
import TableLoadingOverlay from 'components/Management/Accountants/TableLoadingOverlay/TableLoadingOverlay'
import DeleteAccountantButton from '../DeleteAccountantButton/DeleteAccountantButton'
import { Accountant } from 'models/management.models'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../constants'

const Star = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <mask
        id="mask0_1249_703"
        style={{ maskType: 'alpha' }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_1249_703)">
        <path
          fill="#FFAD5F"
          d="M7.625 6.4l2.8-3.625c.2-.267.438-.462.712-.587C11.412 2.062 11.7 2 12 2c.3 0 .588.063.863.188.274.124.512.32.712.587l2.8 3.625 4.25 1.425c.433.133.775.38 1.025.737.25.359.375.755.375 1.188 0 .2-.03.4-.087.6-.059.2-.155.392-.288.575l-2.75 3.9.1 4.1c.017.583-.175 1.075-.575 1.475-.4.4-.867.6-1.4.6-.033 0-.217-.025-.55-.075L12 19.675l-4.475 1.25a.949.949 0 01-.275.063c-.1.008-.192.012-.275.012-.533 0-1-.2-1.4-.6-.4-.4-.592-.892-.575-1.475l.1-4.125-2.725-3.875a1.861 1.861 0 01-.288-.575c-.058-.2-.087-.4-.087-.6 0-.417.12-.804.362-1.162.242-.359.58-.613 1.013-.763L7.625 6.4z"></path>
      </g>
    </svg>
  )
}

const AccountantsTable = () => {
  const { accountants, isLoading } = useAccountantsContext()

  const navigate = useNavigate()

  const classes = useManagementTableStyle()

  const PaddedBox = ({ children }: PropsWithChildren<ReactNode>) => (
    <Box paddingLeft={2}>{children}</Box>
  )

  const accountantsHeader: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      renderHeader(params) {
        return <PaddedBox>{params.colDef.headerName}</PaddedBox>
      },
      renderCell(params) {
        return <PaddedBox>{params.formattedValue}</PaddedBox>
      },
    },
    {
      field: 'name',
      headerName: 'Nombre',
      sortable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Correo',
      sortable: false,
      flex: 1,
    },
    {
      field: 'numWorkspaces',
      headerName: 'Espacios de trabajo',
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'team',
      headerName: 'Equipo asignado',
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'complexity',
      headerName: 'Coeficiente de complejidad',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'nps',
      headerName: 'NPS promedio',
      sortable: false,
      renderCell(params) {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={1}
            gridGap={4}>
            <Star />
            <Box>{params.formattedValue}</Box>
          </Box>
        )
      },
    },
    {
      field: 'pendingWorkspaces',
      headerName: 'Cierres incompletos',
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      renderCell: params => (
        <DeleteAccountantButton accountant={params.row as Accountant} />
      ),
    },
  ]

  const onRouteChange = (action: string, id: number = 0, row: GridColDef) => {
    if (row.field !== '') {
      if (action === 'back') navigate(-1)
      if (action === 'edit') navigate(`${ROUTES.EMPLOYEE_DETAILS}?id=${id}`)
      if (action === 'add') console.log(ROUTES.EMPLOYEE_DETAILS)
      if (action === 'error') navigate(ROUTES.HOME, { replace: true })
    }
  }

  return (
    <Box>
      <DataGrid
        columns={accountantsHeader}
        rows={accountants}
        classes={classes}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoading}
        style={{
          border: 'none',
          fontSize: 14,
        }}
        components={{
          ColumnResizeIcon: () => null,
          Footer: AccountantsTableFooter,
          LoadingOverlay: TableLoadingOverlay,
        }}
        onCellClick={row => onRouteChange('edit', row.row.idUser, row)}
      />
    </Box>
  )
}

export default AccountantsTable
