import _ from 'lodash';
import { Money, Currencies } from 'ts-money';
import HTTPClient from 'lib/helpers/HTTPClient';
import { getDateInISO, httpErrorParser } from 'lib/helpers/utilities';

import ReduxState from 'models/redux';
import Invoice, { Concept, CreditNoteForm, InvoiceFilters, Tax, TaxCatalogue } from 'models/invoice';
import { STATUS_PAYMENT_CATALOGUE } from '../constants';
import RazonSocial from 'models/razonSocial';

export const SET_PAGINATION_VALUE = '@Invoices/SET_PAGINATION_VALUE';
export const SET_CREATE_FORM_VALUE = '@Invoices/SET_CREATE_FORM_VALUE';
export const RESET_CREATE_FORM = '@Invoices/RESET_FORM_VALUE';
export const ADD_CONCEPT = '@Invoices/ADD_CONCEPT';
export const DELETE_CONCEPT = '@Invoices/DELETE_CONCEPT';
export const ADD_TAX = '@Invoices/ADD_TAX';
export const DELETE_TAX = '@Invoices/DELETE_TAX';
export const SET_CONCEPT_VALUE = '@Invoices/SET_CONCEPT_VALUE';

export const SET_FILTER = '@Invoices/SET_FILTER';
export const SET_FILTER_VALUE = '@Invoices/SET_FILTER_VALUE';
export const RESET_INVOICES_FILTERS = '@Invoices/RESET_FILTERS';

export const SET_HEADER_VALUE = '@Invoices/SET_HEADER_VALUE';
export const RESET_HEADERS = '@Invoices/RESET_HEADERS';

export const SET_CREATE_PAYMENT_COMPLEMENT_FORM_VALUE = '@Invoices/SET_CREATE_PAYMENT_COMPLEMENT_FORM_VALUE';
export const RESET_CREATE_PAYMENT_COMPLEMENT_FORM = '@Invoices/RESET_CREATE_PAYMENT_COMPLEMENT_FORM';

export const SET_CREATE_CREDIT_NOTE_FORM_VALUE = '@Invoices/SET_CREATE_CREDIT_NOTE_FORM_VALUE';
export const RESET_CREATE_CREDIT_NOTE_FORM = '@Invoices/RESET_CREATE_CREDIT_NOTE_FORM';
export const ADD_CREDIT_NOTE_CONCEPT = '@Invoices/ADD_CREDIT_NOTE_CONCEPT';
export const DELETE_CREDIT_NOTE_CONCEPT = '@Invoices/DELETE_CREDIT_NOTE_CONCEPT';
export const ADD_CREDIT_NOTE_TAX = '@Invoices/ADD_CREDIT_NOTE_TAX';
export const DELETE_CREDIT_NOTE_TAX = '@Invoices/DELETE_CREDIT_NOTE_TAX';
export const SET_CREDIT_NOTE_CONCEPT_VALUE = '@Invoices/SET_CREDIT_NOTE_CONCEPT_VALUE';

export const SET_INVOICES_LIST = '@Invoices/SET_INVOICES_LIST';
export const SET_SELECTED_INVOICE = '@Invoices/SET_SELECTED_INVOICE';
export const SET_SELECTED_INVOICE_LIST = '@Invoices/SET_SELECTED_INVOICE_LIST';
export const ADD_SELECTED_INVOICE_LIST_VALUE = '@Invoices/ADD_SELECTED_INVOICE_LIST_VALUE';
export const DELETE_SELECTED_INVOICE_LIST_VALUE = '@Invoices/DELETE_SELECTED_INVOICE_LIST_VALUE';

export const SET_SELECTED_EMISOR = '@Invoices/SET_SELECTED_EMISOR';

export const SET_SYNC_VALUE = '@Invoices/SET_SYNC_VALUE';
export const SET_SYNC_FORM_VALUE = '@Invoices/SET_SYNC_FORM_VALUE';
export const RESET_SYNC_VALUES = '@Invoices/RESET_SYNC_VALUES';

const api = process.env.REACT_APP_APIURL || "/api/";

//#region Acciones para persistir informacion durante la creacion de CFDIs
export const setCreateFormValue = (name: string, value: any): object => ({
  type: SET_CREATE_FORM_VALUE,
  value: { name, value }
})
export const resetCreateForm = (): object => ({
  type: RESET_CREATE_FORM
})
export const createConcept = (value: Concept): object => ({
  type: ADD_CONCEPT,
  value
})
export const deleteConcept = (id: string): object => ({
  type: DELETE_CONCEPT,
  value: id
})
export const createTax = (conceptId: string, value: Tax): object => ({
  type: ADD_TAX,
  value: { conceptId, tax: value }
})
export const deleteTax = (conceptId: string, taxId: string): object => ({
  type: DELETE_TAX,
  value: { conceptId, taxId }
})
export const setConceptValue = (id: string, name: string, value: any): object => ({
  type: SET_CONCEPT_VALUE,
  value: { id, name, value }
})
export const setPaginationValue = (name: string, value: any): object => ({
  type: SET_PAGINATION_VALUE,
  value: { name, value }
})
//#endregion

//#region Acciones persistir informacion para el filtrado de CFDIs
export const setFilter = (value: Partial<InvoiceFilters>): object => ({
  type: SET_FILTER,
  value
})
export const setFilterValue = (name: string, value: any): object => ({
  type: SET_FILTER_VALUE,
  value: { name, value }
})
export const resetInvoicesFilters = (): object => ({
  type: RESET_INVOICES_FILTERS
})
//#endregion

//#region Acciones persistir informacion para el filtrado de la tabla de CFDIs
export const setHeaderValue = (name: string, value: any): object => ({
  type: SET_HEADER_VALUE,
  value: { name, value }
})
export const resetHeaders = (): object => ({
  type: RESET_HEADERS
})
//#endregion

//#region Acciones para persistir informacion durante la creacion de Complementos de Pago
export const setCreatePaymentComplementFormValue = (name: string, value: any): object => ({
  type: SET_CREATE_PAYMENT_COMPLEMENT_FORM_VALUE,
  value: { name, value }
})
export const resetCreatePaymentComplementForm = (): object => ({
  type: RESET_CREATE_PAYMENT_COMPLEMENT_FORM
})
//#endregion

//#region Acciones para persistir informacion durante la creacion de Notas de Credito
export const setCreateCreditNoteFormValue = (name: string, value: any): object => ({
  type: SET_CREATE_CREDIT_NOTE_FORM_VALUE,
  value: { name, value }
})
export const resetCreateCreditNoteForm = (): object => ({
  type: RESET_CREATE_CREDIT_NOTE_FORM
})
export const createCreditNoteConcept = (value: Concept): object => ({
  type: ADD_CREDIT_NOTE_CONCEPT,
  value
})
export const deleteCreditNoteConcept = (id: string): object => ({
  type: DELETE_CREDIT_NOTE_CONCEPT,
  value: id
})
export const createCreditNoteTax = (conceptId: string, value: Tax): object => ({
  type: ADD_CREDIT_NOTE_TAX,
  value: { conceptId, tax: value }
})
export const deleteCreditNoteTax = (conceptId: string, taxId: string): object => ({
  type: DELETE_CREDIT_NOTE_TAX,
  value: { conceptId, taxId }
})
export const setCreditNoteConceptValue = (id: string, name: string, value: any): object => ({
  type: SET_CREDIT_NOTE_CONCEPT_VALUE,
  value: { id, name, value }
})
//#endregion

//#region Acciones para persistir informacion funcional de CFDIs
export const setInvoicesList = (value: Invoice[]): object => ({
  type: SET_INVOICES_LIST,
  value
})
export const setSelectedInvoice = (value: Invoice | null = null): object => ({
  type: SET_SELECTED_INVOICE,
  value
})
export const setSelectedEmisor = (value?: RazonSocial): object => ({
  type: SET_SELECTED_EMISOR,
  value
})
export const setSelectedInvoiceList = (value: Array<Invoice>): object => ({
  type: SET_SELECTED_INVOICE_LIST,
  value
})
export const addSelectedInvoiceToList = (value: Invoice): object => ({
  type: ADD_SELECTED_INVOICE_LIST_VALUE,
  value
})
export const deleteSelectedInvoiceToList = (value: Invoice): object => ({
  type: DELETE_SELECTED_INVOICE_LIST_VALUE,
  value
})
//#endregion

//#region Acciones para persistir informacion para la sincronización con el SAT
export const setSyncValue = (key: string, value: any): object => ({
  type: SET_SYNC_VALUE,
  value: { key, value }
})
export const setSyncFormValue = (key: string, value: any): object => ({
  type: SET_SYNC_FORM_VALUE,
  value: { key, value }
})
export const resetSyncValues = (): object => ({
  type: RESET_SYNC_VALUES
})
//#endregion

export const getTaxCatalogue = () => async (_dispatch: any, getStore: () => ReduxState): Promise<TaxCatalogue> => {
  const client = HTTPClient.getClient();
  const store = getStore();
  const taxCat = store.catalogues["impuestos"];
  try {
    const resp = await client.get(`${api}/taxes/tasa`);
    const data = await resp.json();
    const catalogue: TaxCatalogue = _.map(data, (d) => {
      const id = Number(d["id"]);
      let tax = null;
      if (id === 4) {
        tax = {
          id: id,
          label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
          tr: d["Label"],
          type: d["Type"],
          factor: d["Factor"],
          value: [0.01,0.0125,0.021,0.04,0.10],
          sat: (_.filter(taxCat, (c) => c.label === d["Type"]))[0]
        }
      } else if (id === 5) {
        tax = {
          id: id,
          label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
          tr: d["Label"],
          type: d["Type"],
          factor: d["Factor"],
          value: [0.04, 0.08, 0.106667, 0.16],
          sat: (_.filter(taxCat, (c) => c.label === d["Type"]))[0]
        }
      } else {
        tax = {
          id: id,
          label: `${d["Label"]} ${d["Type"]} (${d["Factor"]})`,
          tr: d["Label"],
          type: d["Type"],
          factor: d["Factor"],
          value: d["value"] || { min: d["Min value"], max: d["Max value"] },
          sat: (_.filter(taxCat, (c) => c.label === d["Type"]))[0]
        }
      }
      return tax;
    });
    return catalogue;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getIncomeInvoiceById = (workspaceId: number, invoiceId: number, noSave: boolean = false) => async (dispatch: any): Promise<Invoice> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}`);
    const data = await petition.json();
    const subtotal = Money.fromDecimal(Number(data["cfdiTaxes"]["subtotal"]), Currencies.MXN);
    const total = Money.fromDecimal(Number(data["cfdiTaxes"]["total"]), Currencies.MXN);

    const invoice: Invoice = {
      ...data["cfdiInformation"],
      conceptos: data['cfdiConcepts'],
      emisor: data["cfdiEmisor"],
      subtotal: subtotal.toDecimal(),
      taxes: total.subtract(subtotal).toDecimal(),
      total: total.toDecimal(),
      statusPayment: _.find(STATUS_PAYMENT_CATALOGUE, (c) => c.value === data["statusPayment"]),
      imported: data["imported"] || false
    }
    !noSave && dispatch(setSelectedInvoice(invoice));
    return invoice;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getIncomeInvoicesList = (workspaceId: number, noSave: boolean = false) => async (dispatch: any, getStore: () => ReduxState): Promise<Invoice[]> => {
  const client = HTTPClient.getClient();
  const { invoices: { filter: filters, selectedEmisor: emisor } } = getStore();
  try {
    let url = `${api}/workspaces/${workspaceId}/cfdis`;

    if (_.some(filters, (el) => !_.isNil(el))) {
      url = `${url}?`;
      if (emisor) url = `${url}rfc=${emisor?.rfc}&`;
      // if (filters.year) url = `${url}year=${filters.year?.value}&`;
      // if (filters.month) url = `${url}month=${filters.month?.value}&`;
      if (filters.type) url = `${url}type=${filters.type?.value}&`;
      url = url.substring(0, url.length - 1);
    }

    const petition = await client.get(url);
    const data = await petition.json();

    const parsedList: Invoice[] = _.map(data, (d): Invoice => {
      const subtotal = Money.fromDecimal(Number(d["cfdiTaxes"]["subtotal"]), Currencies.MXN);
      const total = Money.fromDecimal(Number(d["cfdiTaxes"]["total"]), Currencies.MXN);
      return ({
        ...d["cfdiInformation"],
        statusPayment: _.find(STATUS_PAYMENT_CATALOGUE, (c) => c.value === d["cfdiInformation"]["statusPayment"]),
        emisor: d["cdfiEmisor"],
        subtotal: subtotal.toDecimal(),
        taxes: total.subtract(subtotal).toDecimal(),
        total: total.toDecimal(),
        imported: d["imported"] || false
      });
    });
    const list = _.orderBy(parsedList, ["createdAt"], ['desc']);
    
    !noSave && dispatch(setInvoicesList(list));
    return list;
  } catch (err: any) {
    console.log(err);
    
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getFilteredIncomeInvoicesList = (workspaceId: number, rfc?: string, year?: number, month?: number, type?: string, noSave: boolean = false) => async (dispatch: any, getStore: () => ReduxState): Promise<Invoice[]> => {
  const client = HTTPClient.getClient();
  try {
    let url = `${api}/workspaces/${workspaceId}/cfdis?`;
    if (rfc) url = `${url}rfc=${rfc}&`;
    if (year) url = `${url}year=${year}&`;
    if (month) url = `${url}month=${month}&`;
    if (type) url = `${url}type=${type}&`;
    url = url.substring(0, url.length - 1);
    

    const petition = await client.get(url);
    const data = await petition.json();

    const parsedList: Invoice[] = _.map(data, (d): Invoice => {
      const subtotal = Money.fromDecimal(Number(d["cfdiTaxes"]["subtotal"]), Currencies.MXN);
      const total = Money.fromDecimal(Number(d["cfdiTaxes"]["total"]), Currencies.MXN);
      return ({
        ...d["cfdiInformation"],
        statusPayment: _.find(STATUS_PAYMENT_CATALOGUE, (c) => c.value === d["cfdiInformation"]["statusPayment"]),
        emisor: d["cdfiEmisor"],
        subtotal: subtotal.toDecimal(),
        taxes: total.subtract(subtotal).toDecimal(),
        total: total.toDecimal(),
        imported: d["imported"] || false
      });
    });
    const list = _.orderBy(parsedList, ["createdAt"], ['desc']);
    
    !noSave && dispatch(setInvoicesList(list));
    return list;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const formRequestBody = (form: any) => {
  return {
    idType: form.tipo?.idCatalogValues,
    emisorId: form.emisor?.idRfc,
    clientId: form.receptor?.id,
    currencyId: form.moneda?.idCatalogValues,
    exchangeRate: ['MXN', 'XXX'].indexOf(form.moneda?.value) > -1 ? 0 : Number(form?.tipoCambio),
    paymenteMethodId: form.metodoPago?.idCatalogValues,
    wayOfPaymentId: form.formaPago?.idCatalogValues,
    idUsoCfdi: form.usoCfdi?.idCatalogValues,
    additionalInformation: form.condiciones,
    serie: form.serie?.value,
    folio: form.folio,
    concept: _.map(form.conceptos, (c) => ({
      objImp: c.objImp?.idCatalogValues,
      satKey: c.satKey?.idCatalogValues || c.claveSat?.idCatalogValues,
      unit: c.claveUnitSat?.idCatalogValues,
      price: c.price,
      amount: c.cantidad,
      description: c.description,
      tax: _.map(c.impuestos, (i) => ({
        impuesto: i.id,
        rate: i.selectedValue
      }))
    })),
    idPeriodicity: ["XAXX010101000", "XEXX010101000"].indexOf(form?.receptor?.rfc || '') > -1 ? form.period?.idCatalogValues : null,
    periodicityYear: ["XAXX010101000", "XEXX010101000"].indexOf(form?.receptor?.rfc || '') > -1 ? Number(form.year?.value) : null,
    idPMonth: ["XAXX010101000", "XEXX010101000"].indexOf(form?.receptor?.rfc || '') > -1 ? form.month?.idCatalogValues : null
  };
}

export const createIncomeInvoice = (
  workspaceId: number,
  version: string = "3.3",
  prefact?: boolean,
) => async (_dispatch: any, getStore: () => ReduxState) => {
  const client = HTTPClient.getClient();
  const { invoices: { form } } = getStore();
  try {
    const body = formRequestBody(form);

    let url = `${api}/workspaces/${workspaceId}/cfdis`;
    if (version === "4.0") url = `${url}/version/4`;
    if (prefact) url = `${url}?prefact=true`;

    const petition = await client.post(url, body);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const sendIncomeInvoiceByEmail = (workspaceId: number, invoiceId: number) => async (_dispatch: any): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/send`);
    await petition.data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getIncomeInvoiceFiles = (workspaceId: number, invoice: Invoice, includeInvoice?: boolean) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    let pdfBase64 = "";
    let xmlBase64 = "";

    if (!invoice.imported) {
      const petitions = await Promise.all([
        client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/download/pdf`),
        client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/download/xmlCfdi`)
      ]);
      const dataPdf = await petitions[0].text();
      const dataXml = await petitions[1].text();
  
      pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
      xmlBase64 = `data:application/xml;base64,${dataXml}`;
    } else {
      const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdiImported/${invoice.uuid}/download`);
      const data = await petition.json();

      pdfBase64 = `data:application/pdf;base64,${data.pdf}`;
      xmlBase64 = `data:application/xml;base64,${data.xml}`;
    }

    const data = { pdf: pdfBase64, xml: xmlBase64 };

    if (includeInvoice) return ({ ...data, invoice });
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const updateIncomeInvoicePaymentStatus = (workspaceId: number, invoice: Invoice, status: string) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.put(`${api}/workspaces/${workspaceId}/cfdis/${invoice.uuid}/payment`, {
      statusPayment: status
    });
    await petition.data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const deleteIncomeInvoice = (workspaceId: number, invoice: Invoice, reason: number, substitute?: string) => async (_dispatch: any): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    let url = `${api}/workspaces/${workspaceId}/cancellation/request`;
    if (invoice?.version === 4) url = `${url}40`;
    const petition = await client.post(url, {
      uuid: invoice.uuid,
      idCancellation: reason,
      replacementFolio: substitute
    });
    await petition.json();
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getIncomeInvoiceCancelNotice = (workspaceId: number, invoice: Invoice) => async (_dispatch: any, ) => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cancellation/acuse?rfcEmisor=${invoice.emisor.rfc}&uuid=${invoice.uuid}`);
    const data = await petition.json();
    return {
      pdf: `data:application/pdf;base64,${data.pdf}`
    };
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getPaymentComplementList = (workspaceId: number, invoiceId: number) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment`);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const createPaymentComplement = (workspaceId: number, invoice: Invoice) => async (_dispatch: any, getStore: () => ReduxState) => {
  const client = HTTPClient.getClient();
  const { invoices: { paymentComplementForm: form } } = getStore();
  try {
    const body = {
      paymentDate: getDateInISO(form.fechaPago),
      idWayOfPayment: form.formaPago?.idCatalogValues,
      operationNumber: form.numeroOperacion,
      idCurrency: form.moneda?.idCatalogValues,
      exchangeRate: form.tipoCambio,
      amount: Money.fromDecimal(parseFloat(form.monto), Currencies.MXN).toDecimal(),
      additionalInformation: [{
        rfcOrigen: form.rfcOrigen,
        rfcReceptor: form.rfcBeneficiario,
        bank: form.nombreBanco,
        beneficiaryAccount: form.cuentaBeneficiario,
        orderingAccount: form.cuentaOrdenante,
        typePaymentChain: form.tipoCadenaPago?.idCatalogValues || 0,
        paymentChain: form.cadenaPago,
        paymentCertificate: form.certificadoPago,
        paymentStamp: form.selloPago,
      }],
      relatedDocuments: [{
        idRelatedDocument: invoice.uuid,
        previousBalance: form.importeSaldoAnterior,
        amountPaid: form.importePagado,
        insolteAmount: Money.fromDecimal(parseFloat(form.importeSaldoAnterior), Currencies.MXN).subtract(Money.fromDecimal(parseFloat(form.importePagado), Currencies.MXN)).toDecimal(),
        partialityNumber: Number(form.numeroParcialidad),
      }]
    }
    const petition = await client.post(`${api}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/Payment`, body);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getPaymentComplementFiles = (workspaceId: number, invoiceId: number, paymentComplementId: number) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petitions = await Promise.all([
      client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/pdfDownload`),
      client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/xmlDownload`)
    ]);
    const dataPdf = await petitions[0].text();
    const dataXml = await petitions[1].text();

    const pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
    const xmlBase64 = `data:application/xml;base64,${dataXml}`;

    return {
      pdf: pdfBase64,
      xml: xmlBase64
    };
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const sendPaymentComplementByEmail = (workspaceId: number, invoiceId: number, paymentComplementId: number) => async (_dispatch: any): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/send`);
    await petition.data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const createCreditNote = (workspaceId: number, invoice: Invoice, version: string = "3.3") => async (_dispatch: any, getStore: () => ReduxState) => {
  const client = HTTPClient.getClient();
  const { invoices: { creditNoteForm: form, selectedEmisor: emisor, selected }, catalogues: { tiposComprobantes } } = getStore();
  const egress = tiposComprobantes.filter((tipo) => tipo.idCatalogValues === 55459)[0]
  const concepts = form.conceptos.map((concepto) => {
    const tax = concepto.impuestos.map((tax) => ({ impuesto: tax.id, rate: tax.selectedValue.toString() }));
    return {
      objImp: concepto?.objImp,
      satKey: concepto.satKey?.idCatalogValues,
      unit: concepto.claveUnitSat?.idCatalogValues,
      price: concepto.price,
      amount: concepto.cantidad,
      description: concepto.description,
      discount: concepto.descuento,
      tax: tax
    }
  });
  
  try {
    const body = {
      idClient: selected?.clientId,
      idCurrency: form.moneda?.idCatalogValues,
      idPaymentMethod: form.metodoPago?.idCatalogValues,
      idWayOfPayment: form.formaPago?.idCatalogValues,
      idUsoCfdi: form.usoCfdi?.idCatalogValues,
      idType: egress.idCatalogValues,
      exchangeRate: form.exchangeRate,
      idRfc: emisor?.idRfc,
      idRelation: form.tipoRelacion?.idCatalogValues,
      conceptCredit: concepts,
      folio: form.folio,
      serie: form.serie,
      conditions: form.condiciones
    }

    let url = `${api}/workspaces/${workspaceId}/cfdis/${selected?.idCfdi}/CreditNote`;
    if (version === "4.0") url = `${url}/version/4`;

    const petition = await client.post(url, body);
    const data: CreditNoteForm = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getCreditNoteFiles = (workspaceId: number, invoiceId: number, creditNoteId: number) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petitions = await Promise.all([
      client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/pdfDownload`),
      client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/xmlDownload`)
    ]);
    const dataPdf = await petitions[0].text();
    const dataXml = await petitions[1].text();

    const pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
    const xmlBase64 = `data:application/xml;base64,${dataXml}`;

    return {
      pdf: pdfBase64,
      xml: xmlBase64
    };
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const sendCreditNoteByEmail = (workspaceId: number, invoiceId: number, creditNoteId: number) => async (_dispatch: any): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/send`);
    await petition.data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getCreditNoteList = (workspaceId: number, invoiceId: number) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/cfdis/${invoiceId}/Creditnotes`);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getSerieFolioHistorial = (workspaceId: number, idRfc: number ) => async (_dispatch: any) => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/rfc/${idRfc}/folios`);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}
