import moment from 'moment'
import _ from 'lodash'
import { UploadedFile } from 'models/general'
import {
  ChatMessage,
  IAccountingChecklist,
  IAccountingProcess,
  TAccountingStatusCatalogue,
} from 'models/accounting.models'
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import { parseChatMessage } from 'lib/helpers/utilities'

const api = process.env.REACT_APP_APIURL || '/api'

export const uploadFileToProcess =
  (workspaceId: number, process: IAccountingProcess, file: UploadedFile) =>
  async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const path = `/Contabilidad/${process.year}/${_.capitalize(moment(process.month, 'M').format('MMMM'))}`
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/wsfiles/upload`,
        {
          idAccounting: process.id,
          name: getFileName(file.fullName),
          description: file.description,
          path: path,
          enabled: true,
          mimeType: file.mime,
          extension: getFileExtension(file.fullName),
          originalName: file.fullName,
          file: _.split(file.base64, ',')[1],
          documentType: file.documentType,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const validateFileInProcess =
  (
    workspaceId: number,
    accountingId: number,
    fileRelatedId: number,
    status: string = ''
  ) =>
  async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}/accounting/${accountingId}/approved/${fileRelatedId}`,
        {
          approved: status,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const addCommentToProcess =
  (
    workspaceId: number,
    process: IAccountingProcess,
    comment: string,
    commentType: number
  ) =>
  async (_dispatch: any): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/accounting/${process.id}/comments`,
        {
          text: comment,
          commentType,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const updateProcessStatus =
  (
    workspaceId: number,
    process: IAccountingProcess,
    status: TAccountingStatusCatalogue,
    noSave: boolean = false
  ) =>
  async (_dispatch: any): Promise<any> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}/accounting/${process.id}/status`,
        {
          idAccountingStatus: status,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const updateProcessChecklist =
  (
    workspaceId: number,
    process: IAccountingProcess,
    checklist: IAccountingChecklist,
    status: number
  ) =>
  async (_dispatch: any): Promise<any> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}/accounting/${process.id}/checklist`,
        {
          idCheckList: checklist.idCheckList,
          status: status,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getChatMessages =
  (workspaceId: number, accountingId: number) =>
  async (_dispatch: any): Promise<ChatMessage[]> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.get(
        `${api}/workspaces/${workspaceId}/accounting/${accountingId}/chat`
      )
      const data = await petition.json()
      return _.map(data, parseChatMessage)
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const sendChatMessage =
  (workspaceId: number, accountingId: number, message: string) =>
  async (_dispatch: any): Promise<ChatMessage> => {
    const client = HTTPClient.getClient()
    try {
      const body = {
        text: message,
      }
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/accounting/${accountingId}/chat`,
        body
      )
      const data = await petition.json()
      return parseChatMessage(data)
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getFileExtension = (filename: string) : string => {
    const helper = filename.split(".")
    return helper[helper.length - 1]
}

export const getFileName = (filename: string) : string => {
    const extension = getFileExtension(filename)
    return filename.replace(new RegExp( "\\." +  extension + "$"), "")
} 