import { toQueryParamsV2 } from "lib/helpers/utilities";
import { ParamType } from "./management.models";

export const route = process.env.REACT_APP_APIURL || '/api/';

export const SCHEMA_ID: Record<string, number> = {
  MonthlyClosing: 1,
  NPS: 2,
  WorkLoad: 3,
};

export const formURL = (workspaceId: number, rest: string, params?: ParamType) => {
  if (params) {
    const queryParams = toQueryParamsV2(params as ParamType);
    return `${route}/workspaces/${workspaceId}/${rest}?${queryParams.toString()}`;
  }
  return `${route}/workspaces/${workspaceId}/${rest}`;
};