import { ReduxAction } from 'models/thunk'

export const SET_ACTIVE_TAB = '@DocumentRequest/SET_ACTIVE_TAB'
export const SET_CONCILIATION_PERIOD_MONTH =
  '@DocumentRequest/SET_CONCILIATION_PERIOD_MONTH'
export const SET_CONCILIATION_PERIOD_YEAR =
  '@DocumentRequest/SET_CONCILIATION_PERIOD_YEAR'

// statements
export const SET_CONCILIATION_STATEMENT_ROWS =
  '@DocumentRequest/SET_CONCILIATION_STATEMENT_ROWS'
export const SET_CONCILIATION_ACTIVE_STATEMENT_ROW =
  '@DocumentRequest/SET_CONCILIATION_ACTIVE_STATEMENT_ROW'
export const SET_SELECTED_CONCILIATION_STATEMENT_ROWS =
  '@DocumentRequest/SET_SELECTED_CONCILIATION_STATEMENT_ROWS'
export const SET_CONCILIATION_STATEMENT_ROWS_SEARCH =
  '@DocumentRequest/SET_CONCILIATION_STATEMENT_SEARCH'
export const SET_CONCILIATION_STATEMENT_FILTER =
  '@DocumentRequest/SET_CONCILIATION_STATEMENT_FILTER'

export const SET_CONCILIATION_CFDI_ACTIVE_SECTION =
  '@DocumentRequest/SET_CONCILIATION_CFDI_ACTIVE_SECTION'
export const SET_CONCILIATION_CFDI_ROWS =
  '@DocumentRequest/SET_CONCILIATION_CFDI_ROWS'
export const SET_CONCILIATION_CFDI_FILTER =
  '@DocumentRequest/SET_CONCILIATION_CFDI_FILTER'
export const SET_CONCILIATION_SELECTED_CFDI_ROWS =
  '@DocumentRequest/SET_CONCILIATION_SELECTED_CFDI_ROWS'

export const SET_LEDGER_ROWS = '@DocumentRequest/SET_LEDGER_ROWS'
export const SET_LEDGER_SELECTED_ROWS =
  '@DocumentRequest/SET_LEDGER_SELECTED_ROWS'
export const SET_LEDGER_FILTER = '@DocumentRequest/SET_LEDGER_FILTER'

export const SET_REPORTS_ROWS = '@DocumentRequest/SET_REPORTS_ROWS'
export const SET_ACTIVE_REPORT = '@DocumentRequest/SET_ACTIVE_REPORT'
export const SET_REPORT_FILTER_MONTH =
  '@DocumentRequest/SET_REPORT_FILTER_MONTH'
export const SET_REPORT_FILTER_YEAR = '@DocumentRequest/SET_REPORT_FILTER_YEAR'

export const SET_ACCOUNT_STATEMENTS_HISTORY =
  '@DocumentRequest/SET_ACCOUNT_STATEMENTS_HISTORY'
export const SET_CFDIS_HISTORY = '@DocumentRequest/SET_CFDIS_HISTORY'

export const SET_OCR_STATEMENTS = '@DocumentRequest/SET_OCR_STATEMENTS'
export const SET_OCR_MOVEMENTS = '@DocumentRequest/SET_OCR_MOVEMENTS'

// conciliatons
export const SET_CONCILIATION_DISPLAY_CONCILIED =
  '@DocumentRequest/SET_CONCILIATION_DISPLAY_CONCILIED'
export const SET_CONCILIATION_ROWS = '@DocumentRequest/SET_CONCILIATION_ROWS'

// MANAGEMENT
export const SET_MANAGEMENT_ACCOUNTANTS =
  '@DocumentRequest/SET_MANAGEMENT_ACCOUNTANTS'

export const setConciliationPeriodMonth = (
  value: number
): ReduxAction<number> => ({
  type: SET_CONCILIATION_PERIOD_MONTH,
  value,
})

export const setConciliationPeriodYear = (
  value: number
): ReduxAction<number> => ({
  type: SET_CONCILIATION_PERIOD_YEAR,
  value,
})

export const setActiveTab = (value: number): ReduxAction<number> => ({
  type: SET_ACTIVE_TAB,
  value,
})

// statements

export const setConciliationStatementRows = (value: any): ReduxAction<any> => ({
  type: SET_CONCILIATION_STATEMENT_ROWS,
  value,
})

export const setConciliationActiveStatementRow = (
  value: number
): ReduxAction<number> => ({
  type: SET_CONCILIATION_ACTIVE_STATEMENT_ROW,
  value,
})

export const setSelectedConciliationStatementRows = (
  value: any
): ReduxAction<any> => ({
  type: SET_SELECTED_CONCILIATION_STATEMENT_ROWS,
  value,
})

export const setConciliationStatementSearch = (
  value: any
): ReduxAction<any> => ({
  type: SET_CONCILIATION_STATEMENT_ROWS_SEARCH,
  value,
})

export const setConciliationStatementFilters = (
  value: any
): ReduxAction<any> => ({
  type: SET_CONCILIATION_STATEMENT_FILTER,
  value,
})

export const setConciliationCfdiActiveSection = (
  value: number
): ReduxAction<number> => ({
  type: SET_CONCILIATION_CFDI_ACTIVE_SECTION,
  value,
})

export const setConciliationCfdiRows = (value: any): ReduxAction<any> => ({
  type: SET_CONCILIATION_CFDI_ROWS,
  value,
})

export const setConciliationSelectedCfdiRows = (
  value: any
): ReduxAction<any> => ({
  type: SET_CONCILIATION_SELECTED_CFDI_ROWS,
  value,
})

export const setConciliationCfdiFilters = (value: any): ReduxAction<any> => ({
  type: SET_CONCILIATION_CFDI_FILTER,
  value,
})

export const setReportsRows = (value: any): ReduxAction<any> => ({
  type: SET_REPORTS_ROWS,
  value,
})

export const setActiveReport = (value: any): ReduxAction<any> => ({
  type: SET_ACTIVE_REPORT,
  value,
})

export const setReportFilterMonth = (value: number): ReduxAction<number> => ({
  type: SET_REPORT_FILTER_MONTH,
  value,
})

export const setReportFilterYear = (value: number): ReduxAction<number> => ({
  type: SET_REPORT_FILTER_YEAR,
  value,
})

export const setLedgerRows = (value: any): ReduxAction<any> => ({
  type: SET_LEDGER_ROWS,
  value,
})

export const setLedgerSelectedRows = (value: any): ReduxAction<any> => ({
  type: SET_LEDGER_SELECTED_ROWS,
  value,
})

export const setLedgerFilters = (value: any): ReduxAction<any> => ({
  type: SET_LEDGER_FILTER,
  value,
})

// history

export const setAccountStatementsHistory = (value: any): ReduxAction<any> => ({
  type: SET_ACCOUNT_STATEMENTS_HISTORY,
  value,
})

export const setCFDISHistory = (value: any): ReduxAction<any> => ({
  type: SET_CFDIS_HISTORY,
  value,
})

// OCR

export const setOCRStatements = (value: any): ReduxAction<any> => ({
  type: SET_OCR_STATEMENTS,
  value,
})

export const setOCRMovements = (value: any): ReduxAction<any> => ({
  type: SET_OCR_MOVEMENTS,
  value,
})

// conciliations
export const setDisplayReconciled = (value: boolean): ReduxAction<boolean> => ({
  type: SET_CONCILIATION_DISPLAY_CONCILIED,
  value,
})

export const setConciliationRows = (value: any): ReduxAction<any> => ({
  type: SET_CONCILIATION_ROWS,
  value,
})

// MANAGEMENT

export const setManagementAccountants = (value: any[]): ReduxAction<any> => ({
  type: SET_MANAGEMENT_ACCOUNTANTS,
  value,
})
