import { Tabs } from '@mui/material'
import StyledTab from '../StyledTab/StyledTab'
import './styles.scss'

export interface ISydTab {
  id: string
  label: string
  className?: string
}

interface ISydTabsProps<TTab> {
  tabs: Readonly<ISydTab[]>
  activeTab: number
  onChangeTab: (event: React.ChangeEvent<{}>, newValue: TTab) => void
  className?: string
}

const SydTabs = <TTab,>({
  tabs,
  activeTab,
  onChangeTab,
  className,
}: ISydTabsProps<TTab>) => {
  return (
    <Tabs
      className={`syd-tabs-component ${className ?? ''}`}
      value={activeTab}
      onChange={onChangeTab}
      variant="fullWidth">
      {tabs.map(({ id, label, className }) => (
        <StyledTab
          key={id}
          id={id}
          className={`tab ${className}`}
          label={label}
        />
      ))}
    </Tabs>
  )
}

export default SydTabs
