import { Box, Button, Grid, LinearProgress, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { OCRContext } from 'containers/Conciliations/OCRProcessContainer/OCRProcessContainer'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { OCRBankStatement, OCRBankStatementMovement } from 'models/redux'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { updateStatement as updateStatementQuery } from 'services/conciliations'
import _ from 'lodash'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'

interface EditFormFields {
  bank: string
  account: string
  bankCode: string
  cutoffDate: string
  period: string
  periodDays: string
  currency: string
  bankBranch: string
}

const OCRStatementEditForm: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [period, setPeriod] = useState<{
    start: Date | null
    end: Date | null
  }>({
    start: new Date(),
    end: new Date(),
  })
  const [cutoffDate, setCutoffDate] = useState<Date>(new Date())
  const { drawer, closeDrawer } = useContext(OCRContext)
  const {
    workspaceInfo,
    OCR: { updateOCRStatement, OCRMovements: transactions },
  } = useConciliationsContainerContext()
  const statement = drawer.type.value as OCRBankStatement
  const dialog = useDialog()

  const methods = useForm<EditFormFields>({
    defaultValues: {
      bank: statement.bank,
      account: statement.account,
      bankCode: statement.bankCode,
      cutoffDate: statement.cutoffDate,
      period: statement.period,
      periodDays: '30',
      currency: 'MXN',
      bankBranch: 'Red de sucursales',
    },
  })

  useEffect(
    () => {
      if (statement.period) {
        const [end, start] = statement.period.split('-')
        setPeriod({ start: new Date(start), end: new Date(end) })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*statement.period*/
    ]
  )

  useEffect(
    () => {
      if (statement.cutoffDate) {
        setCutoffDate(new Date(statement.cutoffDate))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /*statement.cutoffDate*/
    ]
  )

  const statementTransactions = useMemo(() => {
    return _.filter(
      transactions,
      (t: OCRBankStatementMovement) =>
        t.idFilesBankStatement === statement.idFileBankStatement
    )
  }, [statement, transactions])

  const depositTotal = useMemo(() => {
    return _.sumBy(statementTransactions, t => t.deposit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement, statementTransactions /*, statement*/])

  const withdrawalTotal = useMemo(() => {
    return _.sumBy(statementTransactions, t => t.withdrawal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement, statementTransactions /*, statement*/])

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  const onSubmit = async (data: EditFormFields) => {
    const startDate = formatDate(period.start as Date)
    const endDate = formatDate(period.end as Date)
    const cutDate = formatDate(cutoffDate)

    const updatedStatement = {
      bank: data.bank,
      account: data.account,
      bankCode: data.bankCode,
      cutoffDate: cutDate,
      startPeriod: startDate,
      endPeriod: endDate,
      initialBalance: statement.initialBalance,
      finalBalance: statement.finalBalance,
      currency: data.currency,
      deposit: depositTotal,
      withdrawal: withdrawalTotal,
    }
    try {
      setIsLoading(true)
      await updateStatementQuery(
        workspaceInfo.activeWorkspace,
        statement.idWsFile,
        updatedStatement
      )
      updateOCRStatement({
        ...statement,
        ...data,
        cutoffDate: cutDate,
        period: `${endDate}-${startDate}`,
        periodDays: statement.periodDays,
      })
      closeDrawer()
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido actualizar el estado de cuenta.',
        primaryButtonText: 'Continuar',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleStartDate = (_date: MaterialUiPickersDate) => {
    _date && setPeriod({ ...period, start: _date.toDate() })
  }

  const handleEndDate = (_date: MaterialUiPickersDate) => {
    _date && setPeriod({ ...period, end: _date.toDate() })
  }

  const handleCutoffDate = (_date: MaterialUiPickersDate) => {
    _date && setCutoffDate(_date.toDate())
  }

  return (
    <FormProvider {...methods}>
      {isLoading && <LinearProgress variant="indeterminate" />}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box padding={3} display="flex" flexDirection="column" gridGap={22}>
          <TextField
            {...methods.register('bank')}
            placeholder="Nombre de banco"
            label="Banco"
            fullWidth
          />
          <TextField
            {...methods.register('account')}
            placeholder="Número de cuenta"
            label="Cuenta"
            fullWidth
          />
          <TextField
            {...methods.register('bankCode')}
            placeholder="CLABE"
            label="CLABE"
            fullWidth
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {/* <TextField { ...methods.register('cutoffDate') } placeholder="Fecha de corte" label="Fecha de corte" fullWidth /> */}
              <Box fontSize={10} marginBottom="1px" color="#999">
                Fecha de corte
              </Box>
              <DatePicker
                format="DD/MM/yyyy"
                value={cutoffDate}
                onChange={handleCutoffDate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box fontSize={10} color="#999">
                Periodo
              </Box>
              <Box display="flex" marginTop="1px">
                <DatePicker
                  value={period.start}
                  onChange={handleStartDate}
                  format="DD/MM/yyyy"
                />
                <DatePicker
                  value={period.end}
                  onChange={handleEndDate}
                  format="DD/MM/yyyy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField { ...methods.register('periodDays') } placeholder="No. días en el periodo" label="No. días en el periodo" fullWidth /> */}
              <Box fontSize={10} color="#999">
                No. días en el periodo
              </Box>
              <Box borderBottom="1px solid #999" paddingY="4px">
                {statement.periodDays}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...methods.register('currency')}
                placeholder="Moneda"
                label="Moneda"
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            {...methods.register('bankBranch')}
            placeholder="Banca"
            label="Red de sucursales"
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="secondary"
              style={{ color: '#fff' }}
              className="secondary-alt-button">
              Guardar
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default OCRStatementEditForm
