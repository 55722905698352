import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import {
  selectConciliationCFDIFilter,
  selectConciliationCFDISActiveSection,
} from 'lib/helpers/selectors'
import CFDIFilters from '../CFDIFilters/CFDIFilters'
import CFDIMovementsTable from '../CFDIMovementsTable/CFDIMovementsTable'
import CFDIToolbar from '../CFDIToolbar/CFDIToolbar'
import LedgerAccounts from '../LedgerAccounts/LedgerAccounts'
import SyncForm from '../SyncForm/SyncForm'

const sections: Record<number, () => JSX.Element> = {
  0: CFDIMovementsTable,
  1: LedgerAccounts,
  99: () => <Fragment />,
}

const CFDIMovements = () => {
  const cfdiActiveSection = useSelector(selectConciliationCFDISActiveSection)
  const cfdiFilters = useSelector(selectConciliationCFDIFilter)

  const ActiveSectionElement = useMemo(
    () => sections[cfdiActiveSection] || sections[99],
    [cfdiActiveSection]
  )

  const shouldDisplayFilters = useMemo(
    () => cfdiFilters.display && cfdiActiveSection === 0,
    [cfdiFilters.display, cfdiActiveSection]
  )

  return (
    <Grid item xs={12} md={6}>
      <CFDIToolbar />
      <SyncForm />
      {shouldDisplayFilters && <CFDIFilters />}
      <ActiveSectionElement />
    </Grid>
  )
}

export default CFDIMovements
