import { useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import useDialog from 'hooks/Shared/useDialog'
import { IApiError } from 'services/Shared/shared.models'
import { IAccountingIncompleteProcess } from 'services/Accounting/accounting.models'
import { getAccountingIncompleteProcesses } from 'services/Accounting/accounting.services'

interface IUseGetAccoutingIncompleteProcesses {
  incompleteProcesses: boolean
  query: UseQueryResult<IAccountingIncompleteProcess[], IApiError>
}

const useGetAccountingIncompleteProcesses = (
  activeWorkspace: number
): IUseGetAccoutingIncompleteProcesses => {
  const dialog = useDialog()

  const [incompleteProcesses, setIncompleteProcesses] = useState(false)

  const query = useQuery<IAccountingIncompleteProcess[], IApiError>(
    ['getAccountingIncompleteProcesses', activeWorkspace],
    () => getAccountingIncompleteProcesses(activeWorkspace),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setIncompleteProcesses(true),
      onError: (error: IApiError) => {
        if (error.status !== 404) {
          dialog.danger({
            title: 'generic.error.title',
            subtitle:
              'No se pudo obtener la información de los cierres incompletos.',
            primaryButtonText: 'Reintentar',
            onPrimaryClick: () => query.refetch(),
            secondaryButtonText: 'Cancelar',
          })
        }
      },
    }
  )

  return {
    incompleteProcesses,
    query,
  }
}

export default useGetAccountingIncompleteProcesses
