/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import _ from 'lodash'
import { FC, useContext, useEffect, useMemo } from 'react'
import { FinderContext, useQueryCFDI } from '../CFDIFinder/CFDIFinder'
import { toCurrency } from 'lib/helpers/utilities'
import PendingI from '@material-ui/icons/Remove'
import Rwd from '@material-ui/icons/FastRewind'
import Fwd from '@material-ui/icons/FastForward'
import Invoice from 'models/invoice'
import './styles.scss'
import { INVOICE_STATUS } from '../../../constants'
import Dot from '@material-ui/icons/FiberManualRecord'

const movementTypes: Record<string, any> = {
  I: {
    label: 'Ingreso',
    className: 'income',
    Icon: Rwd,
  },
  E: {
    label: 'Egreso',
    className: 'egress',
    Icon: Fwd,
  },
  default: {
    label: 'Pendiente',
    className: 'pending',
    Icon: PendingI,
  },
}

const formatMovement = (
  type: string,
  _row: any,
  addTooltip: boolean = false,
  returnLabel: boolean = false
) => {
  const { label, className, Icon } =
    movementTypes[type] || movementTypes.default

  if (returnLabel) return label

  if (addTooltip) {
    return (
      <span className="status-container">
        <Tooltip title={label}>
          <Icon className={`status-icon ${className}`} color="inherit" />
        </Tooltip>
      </span>
    )
  }
}

const formatStatus = (
  status: number,
  row: any,
  addTooltip: boolean = false,
  returnLabel: boolean = false
) => {
  let label = 'Pendiente'
  let className = 'pending'
  let Icon = PendingI

  if (!row?.uuid) {
    label = 'No timbrado'
  } else if (status === INVOICE_STATUS.ACTIVE.id) {
    label = 'Vigente'
    className = 'active'
    Icon = Dot
  } else if (status === INVOICE_STATUS.CANCELED.id) {
    label = 'Cancelado'
    className = 'canceled'
    Icon = Dot
  } else if (status === INVOICE_STATUS.PENDING.id) {
    label = 'Pendiente'
    className = 'pending'
    Icon = Dot
  }

  if (returnLabel) return label

  if (addTooltip) {
    return (
      <span className="status-container">
        <Tooltip title={label}>
          <Icon className={`status-icon ${className}`} color="inherit" />
        </Tooltip>
      </span>
    )
  }

  return (
    <span className="status-container">
      <Icon className={`status-icon ${className}`} color="inherit" />
    </span>
  )
}

interface CellInterface {
  id: string
  label: string
  getter: (row: Invoice) => any
  options?: TableCellProps
}

const Row: FC<{ row: Invoice; cells: CellInterface[] }> = ({ row, cells }) => {
  const { handleSelect } = useContext(FinderContext)

  const isDisabled = useMemo(() => {
    if (
      row.status === INVOICE_STATUS.CANCELED.id ||
      row.imported === undefined ||
      row.uuid === undefined
    )
      return true
    return false
  }, [row])

  const handleClick = () => {
    if (isDisabled) return
    handleSelect(row)
  }

  if (isDisabled) return null

  return (
    <TableRow
      hover
      style={{
        cursor: 'pointer',
        opacity: isDisabled ? 0.5 : 1,
      }}
      onClick={handleClick}>
      {_.map(cells, cell => (
        <TableCell key={cell.id} {...cell.options}>
          {cell.getter(row)}
        </TableCell>
      ))}
    </TableRow>
  )
}

const InvoicesTable: FC = () => {
  const { list } = useContext(FinderContext)
  const { query, loading } = useQueryCFDI()

  const cells: CellInterface[] = [
    {
      id: 'date',
      label: 'Fecha',
      getter: row => {
        const date = new Date(row.createdAt)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
      },
    },
    {
      id: 'folio',
      label: 'Serie-Folio',
      getter: row => `${row.serie}-${row.folio}`,
    },
    {
      id: 'receptor',
      label: 'Receptor',
      options: {
        width: '30%',
      },
      getter: row => row.clients.bussinessName,
    },
    {
      id: 'tipo',
      label: 'Tipo',
      getter: row => formatMovement(row.type.value, row, true),
    },
    {
      id: 'estatus',
      label: 'Estatus',
      getter: row => formatStatus(row.status, row, true),
    },
    {
      id: 'pago',
      label: 'Pago',
      getter: row => toCurrency(Number(row.total)),
    },
    {
      id: 'enviado',
      label: 'Enviado',
      getter: row => {
        if (!row.sent) return <PendingI />
        const date = new Date(row.sent)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
      },
    },
  ]

  useEffect(() => {
    query()
  }, [])

  return (
    <Box marginY={8}>
      <TableContainer component={Paper}>
        {loading && <LinearProgress variant="query" color="secondary" />}
        <Table size="small">
          <TableHead>
            <TableRow>
              {_.map(cells, cell => (
                <TableCell key={cell.id} {...cell.options}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(list, (row, index) => (
              <Row key={index} row={row} cells={cells} />
            ))}
            {_.isEmpty(list) && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  align="center"
                  padding="normal"
                  style={{
                    padding: 32,
                  }}>
                  No hay facturas para mostrar.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default InvoicesTable
