import { getEmisoresList } from 'services/emisores'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const TaxResidenceLabel: FC = () => {
  const workspaceId = useSelector(selectActiveWorkspace)
  const [label, setLabel] = useState<string>()
  useEffect(() => {
    if (workspaceId) {
      setLabel('...')
      getEmisoresList(workspaceId)
        .then(data => {
          const rfc = _.first(data)
          if (rfc) {
            setLabel(rfc.taxResidence.label)
          } else {
            setLabel('No hay RFCs')
          }
        })
        .catch(error => {
          setLabel('')
        })
    }
  }, [workspaceId])

  if (!workspaceId) return null

  return (
    <div>
      <b>Régimen fiscal:</b> {label}
    </div>
  )
}

export default TaxResidenceLabel
