import _ from 'lodash'
import { UploadedFile } from 'models/general'
import {
  IAccountingCommentInfo,
  IAccountingFileType,
  IAccountingStepFiveCategoryFileStatus,
  TAccountingCommentClassName,
  TAccountingCommentUserType,
  TAccountingStepFiveStepConditionPair,
  TAccountingProcessSteps,
  TAccountingStatusCatalogue,
  TAccountingStepStatus,
} from 'models/accounting.models'
import { Profile, Role, TUserRoleNames } from 'models/user'
import { ROLES } from '../../../constants'
import {
  ACCOUNTING_PROCESS_STEPS,
  ACCOUNTING_STATUS_CATALOGUE,
  ACCOUNTING_STEP_FIVE_VALIDATION_STEPS,
} from 'lib/enums/accounting.enums'
import { USER_ROLE_NAMES_MAP } from 'lib/enums/user.enums'
import {} from 'store/Accounting/accounting.models'
import AccountingStepOne from 'components/Accounting/AccountingStepOne/AccountingStepOne'
import AccountingStepTwo from 'components/Accounting/AccountingStepTwo/AccountingStepTwo'
import AccountingStepThree from 'components/Accounting/AccountingStepThree/AccountingStepThree'
import AccountingStepFour from 'components/Accounting/AccountingStepFour/AccountingStepFour'
import AccountingStepFive from 'components/Accounting/AccountingStepFive/AccountingStepFive'
import AccountingStepSix from 'components/Accounting/AccountingStepSix/AccountingStepSix'
import ISydDocument from 'models/shared.models'

export const accountingFileTypes = {
  requiredFile: 'required-file',
  requiredFileCorrection: 'required-file-correction',
  financialReport: 'financial-report',
  provisionalAccountingNotification: 'provisional-accounting-notification',
  paymentReceipt: 'payment-receipt',
  taxPaymentNotification: 'tax-payment-notification',
  clarificationRequest: 'clarification-request',
  clarification: 'clarification',
  additionalDocuments: 'additional-documents',
} as const

export const stepsForProfile = [
  'Pendiente',
  'Documentos solicitados',
  'Documentos en revisión',
  'Servicio en proceso',
  'Revisión del cliente',
  'Impuestos pagados',
]

export const accountingProcessStepsLabelMap: Readonly<
  Record<TAccountingProcessSteps, string>
> = {
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_SOLICITADOS]: 'Doc. solicitados',
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION]: 'Doc. en revisión',
  [ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO]: 'Servicio en proceso',
  [ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE]: 'Revisión del cliente',
  [ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES]:
    'Revisión del cliente',
  [ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS]: 'Impuestos pagados',
} as const

export const accountingStepToAccountingStatusIdMap: Readonly<
  Partial<Record<TAccountingProcessSteps, TAccountingStatusCatalogue>>
> = {
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_SOLICITADOS]:
    ACCOUNTING_STATUS_CATALOGUE.DOCUMENTOS_SOLICITADOS,
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION]:
    ACCOUNTING_STATUS_CATALOGUE.DOCUMENTOS_EN_REVISION,
  [ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO]:
    ACCOUNTING_STATUS_CATALOGUE.SERVICIO_EN_PROCESO,
  [ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE]:
    ACCOUNTING_STATUS_CATALOGUE.REVISION_DEL_CLIENTE,
  [ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES]:
    ACCOUNTING_STATUS_CATALOGUE.SUBIDA_DOCUMENTOS_MULTIPLES,
  [ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS]:
    ACCOUNTING_STATUS_CATALOGUE.IMPUESTOS_PAGADOS,
} as const

export const accountingStatusToAccountingStepMap: Readonly<
  Record<TAccountingStatusCatalogue, TAccountingProcessSteps>
> = {
  [ACCOUNTING_STATUS_CATALOGUE.DOCUMENTOS_SOLICITADOS]:
    ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_SOLICITADOS,
  [ACCOUNTING_STATUS_CATALOGUE.DOCUMENTOS_EN_REVISION]:
    ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION,
  [ACCOUNTING_STATUS_CATALOGUE.SERVICIO_EN_PROCESO]:
    ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO,
  [ACCOUNTING_STATUS_CATALOGUE.REVISION_DEL_CLIENTE]:
    ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE,
  [ACCOUNTING_STATUS_CATALOGUE.SUBIDA_DOCUMENTOS_MULTIPLES]:
    ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES,
  [ACCOUNTING_STATUS_CATALOGUE.IMPUESTOS_PAGADOS]:
    ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS,
} as const

export const accountingSteps: Readonly<TAccountingProcessSteps[]> = [
  ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_SOLICITADOS,
  ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION,
  ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO,
  ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE,
  ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS,
] as const

export const accountingComponentsMap: Readonly<
  Record<TAccountingProcessSteps, () => JSX.Element>
> = {
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_SOLICITADOS]: AccountingStepOne,
  [ACCOUNTING_PROCESS_STEPS.DOCUMENTOS_EN_REVISION]: AccountingStepTwo,
  [ACCOUNTING_PROCESS_STEPS.SERVICIO_EN_PROCESO]: AccountingStepThree,
  [ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE]: AccountingStepFour,
  [ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES]: AccountingStepFive,
  [ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS]: AccountingStepSix,
} as const

export const ACCOUNTING_FILE_TYPE_CATALOGUE: Readonly<IAccountingFileType[]> = [
  {
    id: 1,
    type: accountingFileTypes.requiredFile,
    label: 'Archivo requerido en solicitud de documentos',
  },
  {
    id: 2,
    type: accountingFileTypes.requiredFileCorrection,
    label: 'Archivo requerido en validación de documentos',
  },
  { id: 3, type: 'financial-report', label: 'Archivo de estados financieros' },
  {
    id: 4,
    type: accountingFileTypes.provisionalAccountingNotification,
    label:
      'Acuse de contribuciones de recibo de declaración provisional o definitiva de impuestos federales',
  },
  {
    id: 5,
    type: accountingFileTypes.paymentReceipt,
    label: 'Comprobante de pago',
  },
  {
    id: 6,
    type: accountingFileTypes.taxPaymentNotification,
    label: 'Acuse de pago del SAT',
  },
  { id: 7, type: accountingFileTypes.clarificationRequest, label: '' },
  { id: 8, type: accountingFileTypes.clarification, label: '' },
  { id: 9, type: accountingFileTypes.additionalDocuments, label: '' },
] as const

const roleMap = Object.fromEntries(
  Object.values(ROLES).map(role => [role.id, role.name])
)

export const getStepByName = (status: string): number => {
  const name = _.toLower(status)
  switch (name) {
    case 'pendiente':
      return 1
    case 'solicitud de documentos':
      return 2
    case 'revisión de documentos':
      return 3
    case 'servicio en proceso':
      return 4
    case 'carga de estados financieros':
    case 'acuse de contribuciones recibido':
      return 5
    case 'comprobante de pago recibido':
      return 6
    default:
      return 0
  }
}

const getStepStatus = (
  index: number,
  activeStep: TAccountingProcessSteps
): TAccountingStepStatus => {
  if (
    index === activeStep &&
    activeStep !== ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES &&
    ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS
  )
    return 'current'

  if (
    index === 4 &&
    (activeStep === ACCOUNTING_PROCESS_STEPS.REVISION_DEL_CLIENTE ||
      activeStep === ACCOUNTING_PROCESS_STEPS.SUBIDA_DOCUMENTOS_MULTIPLES)
  )
    return 'current'

  if (index === 5 && activeStep === ACCOUNTING_PROCESS_STEPS.IMPUESTOS_PAGADOS)
    return 'success'

  if (index < activeStep) return 'success'

  return ''
}

export const getMappedSteps = (
  stepsList: Readonly<TAccountingProcessSteps[]>,
  activeStep: TAccountingProcessSteps
) => {
  const steps = stepsList.map((step, i) => ({
    label: accountingProcessStepsLabelMap[step],
    status: getStepStatus(i + 1, activeStep),
  }))
  return steps
}

export const getAccountingFileCategoryType = (id: number) =>
  ACCOUNTING_FILE_TYPE_CATALOGUE.find(item => item.id === id)

export const getTotalCategoryFiles = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.filter(f => fileType?.type === f?.description)

export const getUploadingFile = (
  uploadingFiles: UploadedFile[],
  fileType?: IAccountingFileType
) => uploadingFiles.find(f => fileType?.type === f?.description)

const uploadedCheck = (file: ISydDocument, fileType?: IAccountingFileType) =>
  !file.validated &&
  file.statusText === 'false' &&
  fileType?.type === file?.description

export const getUploadedFile = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.find(f => uploadedCheck(f, fileType))

export const getUploadedFiles = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.filter(f => uploadedCheck(f, fileType))

const validatedCheck = (file: ISydDocument, fileType?: IAccountingFileType) =>
  !file.validated &&
  file.statusText !== 'false' &&
  fileType?.type === file?.description

export const getFileToValidate = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.find(f => validatedCheck(f, fileType))

export const getFilesToValidate = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.filter(f => validatedCheck(f, fileType))

export const getValidFiles = (
  totalUploadItems: ISydDocument[],
  fileStatuses: IAccountingStepFiveCategoryFileStatus[]
) =>
  totalUploadItems.filter(file =>
    fileStatuses.some(
      ({ id, status }) => id === file.idWsfile && status === 'valid'
    )
  )

export const getInvalidFiles = (
  totalUploadItems: ISydDocument[],
  fileStatuses: IAccountingStepFiveCategoryFileStatus[]
) =>
  totalUploadItems.filter(file =>
    fileStatuses.some(
      ({ id, status }) => id === file.idWsfile && status === 'invalid'
    )
  )

const uploadedAndValidatedCheck = (
  file: ISydDocument,
  fileType?: IAccountingFileType
) =>
  file.validated &&
  file.statusText === 'valid' &&
  fileType?.type === file?.description

export const getUploadedAndValidatedFile = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.find(f => uploadedAndValidatedCheck(f, fileType))

export const getUploadedAndValidatedFiles = (
  totalUploadItems: ISydDocument[],
  fileType?: IAccountingFileType
) => totalUploadItems.filter(f => uploadedAndValidatedCheck(f, fileType))

export const formatDate = (dateString?: string) => {
  const date = dateString ? new Date(dateString.split('.')[0]) : new Date()
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })
  const formatted = formatter.format(date)
  return formatted
}

export const formatName = (file: UploadedFile | ISydDocument) =>
  `${file.name}.${file.extension.toUpperCase()}`

export const checkForValidationStep = (filesToValidate: ISydDocument[]) =>
  filesToValidate.length > 0

export const getValidationConditionsAndSteps = (
  totalThirdCategoryFiles: ISydDocument[],
  uploadedFirstCategoryFiles: ISydDocument[],
  uploadedThirdCategoryFiles: ISydDocument[],
  firstCategoryFilesToValidate: ISydDocument[],
  thirdCategoryFilesToValidate: ISydDocument[],
  firstCategoryFileStatuses: IAccountingStepFiveCategoryFileStatus[],
  uploadedAndValidatedFirstCategoryFiles: ISydDocument[],
  uploadedAndValidatedThirdCategoryFiles: ISydDocument[]
): TAccountingStepFiveStepConditionPair[] => [
  [
    () => uploadedFirstCategoryFiles.length > 0,
    ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.FIRST_CATEGORY_FILES_UPLOADED,
  ],
  [
    () =>
      checkForValidationStep(firstCategoryFilesToValidate) ||
      (uploadedAndValidatedFirstCategoryFiles.length > 0 &&
        firstCategoryFileStatuses.some(({ status }) => status === 'invalid')),
    ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.FIRST_CATEGORY_FILES_VALIDATION,
  ],
  [
    () => uploadedThirdCategoryFiles.length > 0,
    ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.THIRD_CATEGORY_FILES_UPLOADED,
  ],
  [
    () =>
      checkForValidationStep(thirdCategoryFilesToValidate) ||
      (totalThirdCategoryFiles.length > 0 &&
        totalThirdCategoryFiles.length ===
          uploadedAndValidatedThirdCategoryFiles.length),
    ACCOUNTING_STEP_FIVE_VALIDATION_STEPS.THIRD_CATEGORY_FILES_VALIDATION,
  ],
]

export const checkforInvalidUploadContinue = (
  fileStatuses: IAccountingStepFiveCategoryFileStatus[]
) => fileStatuses.some(({ status }) => !status)

const setSenderInfo = (commentInfo: IAccountingCommentInfo) => {
  commentInfo.isSender = true
  commentInfo.className = 'right'
}

export const getCommentInfo = (
  userRole: Role | null,
  userProfile: Profile,
  index: number,
  roleId?: number,
  sentBy?: string
) => {
  const commentInfo = {
    isSender: false,
    userType: '' as TAccountingCommentUserType,
    className: 'left' as TAccountingCommentClassName,
  }

  const roleName = roleId && roleMap[roleId]
  roleName &&
    (commentInfo.userType = USER_ROLE_NAMES_MAP[roleName as TUserRoleNames])
  userRole?.id === roleId && setSenderInfo(commentInfo)
  sentBy === userProfile.email && setSenderInfo(commentInfo)
  !roleId && !sentBy && index % 2 === 0 && setSenderInfo(commentInfo)

  return commentInfo
}
