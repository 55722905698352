import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ProfileHeader from 'components/Shared/ProfileHeader/ProfileHeader'

import { useEmployee } from 'hooks/queries/employees'

import { setSelectedEmployee } from 'actions/employees'
import { selectSelectedEmployee } from 'lib/helpers/selectors'

import './style.scss'

interface Props {
  workspaceId: number
  employeeId: number
  onRedirect: (action: string) => void
}

const EmployeeProfileContainer: React.FC<Props> = ({
  workspaceId,
  employeeId,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const loader = useLoader()

  const employee = useSelector(selectSelectedEmployee) || undefined

  const employeeQuery = useEmployee(workspaceId, employeeId, {
    refreshOn: [workspaceId],
    onError: () => onRedirect('error'),
  })

  const [step, setStep] = useState(0)

  useEffect(() => {
    employeeQuery.isLoading
      ? loader.primary('Cargando información del contador')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeQuery.isLoading])
  useEffect(
    () => () => {
      dispatch(setSelectedEmployee())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const filteredWsList = useMemo(
    () =>
      _.filter(employee?.assignedWorkspaces, w =>
        step === 0 ? true : w.status.step === step
      ),
    [employee?.assignedWorkspaces, step]
  )

  return (
    <div className="container-employee-profile">
      <Card className="profile-card">
        <ProfileHeader
          id={employee?.id ?? 0}
          name={employee?.name ?? '- - -'}
          email={employee?.email ?? '- - -'}
          rate={employee?.nps ?? 0.0}
          fetching={employeeQuery.isFetching}
        />
        <Divider />
        <div className="accounting-status-list">
          <div className="header">
            <span className="workspaces">Espacios de trabajo</span>
            <FormControl className="status">
              <InputLabel>Estatus de cierre mensual</InputLabel>
              <Select
                value={step}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setStep(e.target.value as number)
                }
                disableUnderline>
                <MenuItem value={0}>Todo</MenuItem>
                <MenuItem value={1}>Pendiente</MenuItem>
                <MenuItem value={2}>Documentos solicitados</MenuItem>
                <MenuItem value={3}>Documentos en revisión</MenuItem>
                <MenuItem value={4}>Servicio en proceso</MenuItem>
                <MenuItem value={5}>Revisión del cliente</MenuItem>
                <MenuItem value={6}>Impuestos pagados</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="list">
            {filteredWsList?.length === 0 && (
              <p>No tienes workspaces con el Estatus seleccionado</p>
            )}
            {_.map(filteredWsList, (ws, i) => (
              <div className="item" key={i}>
                <span className="workspace">{ws.name}</span>
                <div className="status-container">
                  <div className="step">
                    <span className="name">{ws.status.name}</span>
                    {!_.isNil(ws.status.detail) && (
                      <>
                        <br />
                        <span className="detail">{ws.status.detail}</span>
                      </>
                    )}
                  </div>
                  <span className="step-number">{ws.status.step}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default EmployeeProfileContainer
