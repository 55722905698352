import { StandardTextFieldProps } from '@material-ui/core'
import { KeyboardEvent } from 'react'
import { Controller } from 'react-hook-form'

interface TextInputHOCProps extends Omit<StandardTextFieldProps, 'variant'> {
  name: string
  control: any
  variant?: 'standard' | 'filled' | 'outlined'
  pattern?: string
  justNumbers?: boolean
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const withTextInputController = (
  WrappedComponent: (
    props: Omit<StandardTextFieldProps, 'variant'>
  ) => JSX.Element
) => {
  const WithTextInputController = (props: TextInputHOCProps) => {
    const { name, control, onKeyDown } = props
    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          //TODO: to be added some global validations here
          const fieldValue = field.value
          return (
            <WrappedComponent
              {...props}
              value={fieldValue}
              onChange={field.onChange}
              onBlur={field.onBlur}
              onKeyDown={onKeyDown}
            />
          )
        }}
      />
    )
  }
  return WithTextInputController
}

export default withTextInputController
