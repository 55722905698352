import { useEffect, useMemo, useRef } from 'react'
import Button from 'components/Shared/Button/Button'
import { IconButton } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Controller, useFormContext } from 'react-hook-form'
import {
  CONCILIATION_EXCEL_ACCEPTED_FORMATS,
  PDF_ACCEPTED_FORMATS,
} from '../../../constants'
import './styles.scss'
import useDialog from 'hooks/Shared/useDialog'

interface FileInputWrapperProps {
  ext: 'XLSX' | 'PDF'
  handleInputName: (name: string) => void
  uuid?: string
}

const FileInputWrapper = (props: FileInputWrapperProps) => {
  const { ext, handleInputName, uuid } = props

  const dialog = useDialog()

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { control, setValue, watch, resetField } = useFormContext()
  const name = useMemo(() => `${uuid}.file-input-${ext}`, [uuid, ext])
  const watchInput = watch(name)
  const anotherInput = watch(
    `${uuid}.file-input-${ext === 'XLSX' ? 'PDF' : 'XLSX'}`
  )
  const isRequired = useMemo(() => anotherInput === undefined, [anotherInput])

  const allowedMimeTypes =
    ext === 'XLSX'
      ? CONCILIATION_EXCEL_ACCEPTED_FORMATS
      : PDF_ACCEPTED_FORMATS

  const handleClick = () => {
    if (watchInput === undefined) {
      fileInputRef && fileInputRef.current?.click()
    }
  }

  const handleEdit = () => {
    fileInputRef && fileInputRef.current?.click()
  }

  const handleDelete = () => {
    setValue(name, undefined)
    resetField(name)
  }

  useEffect(() => {
    name !== undefined && handleInputName(name)
  }, [name, handleInputName])

  return (
    <div
      className={`dropzone  ${watchInput === undefined && 'without_file'}`}
      onClick={handleClick}>
      {name && (
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired,
          }}
          render={({ field, fieldState: { error } }) => (
            <input
              type="file"
              id={name}
              name={name}
              ref={fileInputRef}
              multiple={false}
              onChange={(event: any) => {
                const file = event.target.files[0]
                if (file.type && allowedMimeTypes.includes(file.type)) {
                  field.onChange(file)
                } else {
                  dialog.danger({
                    title: 'Archivo inválido',
                    message: 'Por favor seleccione un archivo válido',
                  })
                }
              }}
              onBlur={field.onBlur}
              accept={allowedMimeTypes}
              value={field.value?.fileName}
              hidden
            />
          )}
        />
      )}
      {watchInput === undefined && (
        <>
          <FileUploadIcon />
          Cargar {ext}
        </>
      )}

      {watchInput !== undefined && (
        <>
          <div className="file-information">
            <DescriptionIcon fontSize="large" />
            <div className="information">
              <p className="name">{watchInput.name}</p>
              <div>
                <span className="size">
                  Tamaño: {`${Number(watchInput.size / 1000).toFixed(2)} kB`}
                </span>
                <span className="extension">Tipo: {ext}</span>
              </div>
            </div>
          </div>
          <div className="actions">
            <Button type="button" variant="text" onClick={handleEdit}>
              Reemplazar
            </Button>
            <IconButton type="button" onClick={handleDelete}>
              <DeleteForeverIcon fontSize="medium" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  )
}

export default FileInputWrapper
