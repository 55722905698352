import React from 'react'
import _ from 'lodash'

import Switch from '@material-ui/core/Switch'

import Account from '@material-ui/icons/AccountCircle'
import Divider from '@material-ui/core/Divider'
import './style.scss'

import { Notification } from 'models/workspace'
import { WorkspaceRole } from 'models/user'

interface CommentProps {
  id: number
  name: string
  role: WorkspaceRole
  list: Notification[]
  onChange: (
    idUser: number,
    idRol: number,
    idType: number,
    consecutive: number,
    value: boolean
  ) => void
  className?: string
}

const UserNotifications: React.FC<CommentProps> = ({
  id,
  name,
  role,
  list,
  onChange,
  className = '',
}) => {
  return (
    <div className={`component-user-notifications ${className}`}>
      <div className="header">
        <Account className="avatar" fontSize="inherit" color="primary" />
        <div className="user">
          <h4 className="name">{name}</h4>
          <span className="role">{_.capitalize(role.roleName)}</span>
        </div>
      </div>
      {_.map(list, l => (
        <React.Fragment key={`${id}-${l.idType}`}>
          <div className="component-notification">
            <div className="label">
              <p>{l.type}</p>
              <small>{l.description}</small>
            </div>
            <Switch
              className="notification-switch"
              checked={l.enabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(
                  id,
                  role.idRole,
                  l.idType,
                  l.consecutive,
                  e.target.checked
                )
              }
            />
          </div>
          <Divider />
        </React.Fragment>
      ))}
    </div>
  )
}

export default UserNotifications
