import { UploadedFile } from 'models/general'
import {
  IAccountingFileType,
  IAccountingStepFiveUploadColumn,
} from 'models/accounting.models'
import { accountingFileTypes } from 'lib/utils/Accounting/accounting.utils'
import { IUseAccountingStepFive } from './useAccountingStepFive'
import ISydDocument from 'models/shared.models'

interface IUseAccountingStepFiveUploadColumnsProps
  extends Pick<
    IUseAccountingStepFive,
    | 'firstCategoryFileStatuses'
    | 'uploadedFirstCategoryFiles'
    | 'firstCategoryFilesToValidate'
    | 'thirdCategoryFileStatuses'
    | 'uploadedFourthCategoryFiles'
  > {
  totalFirstCategoryFiles: ISydDocument[]
  totalSecondCategoryFiles: ISydDocument[]
  totalThirdCategoryFiles: ISydDocument[]
  totalFourthCategoryFiles: ISydDocument[]
  uploadedSecondCategoryFiles: ISydDocument[]
  uploadedThirdCategoryFiles: ISydDocument[]
  thirdCategoryFilesToValidate: ISydDocument[]
  firstCategoryValidFiles: ISydDocument[]
  thirdCategoryValidFiles: ISydDocument[]
  firstCategoryInvalidFiles: ISydDocument[]
  thirdCategoryInvalidFiles: ISydDocument[]
  uploadedAndValidatedFirstCategoryFiles: ISydDocument[]
  uploadedAndValidatedThirdCategoryFiles: ISydDocument[]
  firstFileCategoryType?: IAccountingFileType
  secondFileCategoryType?: IAccountingFileType
  thirdFileCategoryType?: IAccountingFileType
  fourthFileCategoryType?: IAccountingFileType
  uploadingFirstFile?: UploadedFile
  uploadingSecondFile?: UploadedFile
  uploadingThirdFile?: UploadedFile
  uploadingFourthFile?: UploadedFile
}

interface IUseAccountingStepFiveUploadColumns {
  dynamicFileUploadColumns: IAccountingStepFiveUploadColumn[]
}

const useAccountingStepFiveUploadColumns = ({
  totalFirstCategoryFiles,
  totalSecondCategoryFiles,
  totalThirdCategoryFiles,
  totalFourthCategoryFiles,
  uploadedSecondCategoryFiles,
  uploadedThirdCategoryFiles,
  thirdCategoryFilesToValidate,
  firstCategoryValidFiles,
  thirdCategoryValidFiles,
  firstCategoryInvalidFiles,
  thirdCategoryInvalidFiles,
  uploadedAndValidatedFirstCategoryFiles,
  uploadedAndValidatedThirdCategoryFiles,
  firstCategoryFileStatuses,
  uploadedFirstCategoryFiles,
  firstCategoryFilesToValidate,
  thirdCategoryFileStatuses,
  uploadedFourthCategoryFiles,
  firstFileCategoryType,
  secondFileCategoryType,
  thirdFileCategoryType,
  fourthFileCategoryType,
  uploadingFirstFile,
  uploadingSecondFile,
  uploadingThirdFile,
  uploadingFourthFile,
}: IUseAccountingStepFiveUploadColumnsProps): IUseAccountingStepFiveUploadColumns => {
  const dynamicFileUploadColumns: IAccountingStepFiveUploadColumn[] = [
    {
      id: accountingFileTypes.provisionalAccountingNotification,
      label:
        'Acuse de contribuciones de recibo de declaración provisional o definitiva de impuestos federales (Contador)',
      disabled: false,
      totalUploadItems: totalFirstCategoryFiles,
      uploadedFiles: uploadedFirstCategoryFiles,
      fileType: firstFileCategoryType?.type,
      fileStatuses: firstCategoryFileStatuses,
      fileStatusesKey: 'firstCategoryFileStatuses',
      uploadingFile: uploadingFirstFile,
      filesToValidate: firstCategoryFilesToValidate,
      uploadedAndValidatedFiles: uploadedAndValidatedFirstCategoryFiles,
      additionalValidFiles: thirdCategoryValidFiles,
      additionalInvalidFiles: thirdCategoryInvalidFiles,
    },
    {
      id: accountingFileTypes.paymentReceipt,
      label: 'Comprobante de Pago (Cliente)',
      disabled: !uploadedAndValidatedFirstCategoryFiles.length,
      totalUploadItems: totalSecondCategoryFiles,
      uploadedFiles: uploadedSecondCategoryFiles,
      fileType: secondFileCategoryType?.type,
      uploadingFile: uploadingSecondFile,
      additionalValidFiles: [
        ...firstCategoryValidFiles,
        ...thirdCategoryValidFiles,
      ],
      additionalInvalidFiles: [
        ...firstCategoryInvalidFiles,
        ...thirdCategoryInvalidFiles,
      ],
    },
    {
      id: accountingFileTypes.taxPaymentNotification,
      label: 'Acuse de pago del SAT (Contador)',
      disabled: !uploadedAndValidatedFirstCategoryFiles.length,
      totalUploadItems: totalThirdCategoryFiles,
      uploadedFiles: uploadedThirdCategoryFiles,
      fileType: thirdFileCategoryType?.type,
      fileStatuses: thirdCategoryFileStatuses,
      fileStatusesKey: 'thirdCategoryFileStatuses',
      uploadingFile: uploadingThirdFile,
      filesToValidate: thirdCategoryFilesToValidate,
      uploadedAndValidatedFiles: uploadedAndValidatedThirdCategoryFiles,
      additionalValidFiles: firstCategoryValidFiles,
      additionalInvalidFiles: firstCategoryInvalidFiles,
    },
    {
      id: accountingFileTypes.additionalDocuments,
      label: 'Documentos adicionales (Cliente o contador)',
      disabled: false,
      totalUploadItems: totalFourthCategoryFiles,
      uploadedFiles: uploadedFourthCategoryFiles,
      fileType: fourthFileCategoryType?.type,
      uploadingFile: uploadingFourthFile,
      additionalValidFiles: [
        ...firstCategoryValidFiles,
        ...thirdCategoryValidFiles,
      ],
      additionalInvalidFiles: [
        ...firstCategoryInvalidFiles,
        ...thirdCategoryInvalidFiles,
      ],
    },
  ]

  return {
    dynamicFileUploadColumns,
  }
}

export default useAccountingStepFiveUploadColumns
