import _ from 'lodash';
import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";
import { nameBuilder, parseEmployee } from "lib/helpers/utilities";

import Employee from 'models/employee';
import { List } from 'models/general';
import { getStepByName, stepsForProfile } from 'lib/utils/Accounting/accounting.utils';

const route = process.env.REACT_APP_APIURL || "/api/";

export const getEmployeesList = async (workspaceId: number, page: number, size: number, search?: string): Promise<List<Employee[]>> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${route}/workspaces/${workspaceId}/score/accountants`;
        if (search) url = `${url}/search?search=${search}&page=${page}&elements=${size}`;
        else url = `${url}?page=${page}&elements=${size}`;

        const petition = await client.get(url);
        const data = await petition.json();
        
        return {
            list: _.map(data.accountants, parseEmployee),
            page: data.pageNumber,
            totalPages: data.pages,
            total: data.totalElements
        }
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getEmployeeById = async (workspaceId: number, employeeId: number): Promise<Employee> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/score/accountantdetail?idUser=${employeeId}`);
        const data = await petition.json();
        const accountant = data.accountant;
        const workspaces = data.workspaces;
        
        const employee: Employee = {
            id: accountant.idUser,
            name: nameBuilder(accountant.name, accountant.lastName),
            email: accountant.email,
            workspaces: accountant.numWorkspaces,
            nps: accountant.nps,
            external: true,
            assignedWorkspaces: _.map(workspaces, (d) => {
                const step = getStepByName(d.monthlyStatus ?? "Pendiente");
                return {
                    id: d.idWorkspace, 
                    name: d.name, 
                    status: { step, name: stepsForProfile[step - 1], detail: d.stepDetail }
                }
            })
        };

        return employee;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}