import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Accountant } from 'models/management.models'
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline'
import { FC, Fragment, useState } from 'react'
import './styles.scss'
import { toast } from 'react-toastify'
import CloseIcon from '@material-ui/icons/Close'
import { deleteAccountantByID } from 'services/Management/management.services'
import { useSelector } from 'react-redux'
import { selectActiveWorkspace } from 'lib/helpers/selectors'
import _ from 'lodash'
import { useGetAccountants } from 'hooks/Management/Accountants/useGetAccountants'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'


const DeleteAccountantButton: FC<{
  accountant: Accountant
}> = ({ accountant }) => {
  const [isOpen, setIsOpen] = useState(false)
  const workspaceId = useSelector(selectActiveWorkspace)
  const { refetch } = useGetAccountants()

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleDelete = async () => {
    try {
      toast.loading('Eliminando usuario', {
        ..._.omit(successToastOptions, ['icon', 'closeButton']),
        toastId: 'loading',
      })
      setIsOpen(false)
      await deleteAccountantByID(workspaceId, accountant.idUser)
      await refetch()
      toast.dismiss('loading')
      toast.success('Usuario eliminado correctamente', successToastOptions)
    } catch (err: any) {
      toast.dismiss('loading')
      if (err.status === 403) {
        toast.error(
          'No tienes los permisos suficientes para realizar esta acción',
          errorToastOptions)
        return
      }
      toast.error(
        'No se ha podido eliminar el usuario',
        _.omit(errorToastOptions)
      )
    }
  }

  return (
    <Fragment>
      <IconButton onClick={handleClick}>
        <TrashCanOutline style={{ color: red[500], fontSize: '18pt' }} />
      </IconButton>
      <Dialog onClose={handleClick} open={isOpen} className="dialog">
        <DialogContent className="content">
          <Box className="close">
            <IconButton onClick={handleClick}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="labels">
            <Box className="title">Eliminar contador</Box>
            <Box className="subtitle">
              ¿Estás seguro de que deseas eliminar a este contador?
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          <Button className="button cancel" onClick={handleClick}>
            Cancelar
          </Button>
          <Button className="button primary" onClick={handleDelete}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default DeleteAccountantButton
