import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { MutationOptions } from 'models/general'
import { IAccountingTask } from 'models/accounting.models'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import useDialog from 'hooks/Shared/useDialog'
import { toggleAccountingTaskStatus } from 'store/Accounting/accounting.reducer'
import { updateAccountingTaskStatus } from 'services/Accounting/accounting.services'
import { IApiError } from 'services/Shared/shared.models'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useUpdateAccountingTask = (
  workspaceId: number,
  options: MutationOptions<void, IAccountingTask, IApiError> = {
    noDialog: false,
  }
) => {
  const dispatch = useDispatch()
  const dialog = useDialog()

  const { noDialog } = options

  const mutation = useMutation<void, IApiError, IAccountingTask>(
    task => {
      return updateAccountingTaskStatus(workspaceId, task)
    },
    {
      onError: async (error, task) => {

        dispatch(toggleAccountingTaskStatus(task.idTask))

        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }
        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo actualizar la tarea seleccionada.',
            icon: ErrorOutlineIcon as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            mutation.mutate(task)
            return
          }
        }
      },
    }
  )

  return mutation
}

export default useUpdateAccountingTask
