import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from 'components/Shared/Button/Button'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import BackI from '@material-ui/icons/ArrowBack'
import './style.scss'
import EmployeeProfileContainer from 'containers/Employees/EmployeeProfileContainer/EmployeeProfileContainer'

interface Props {}

const EditEmployeeScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const id = Number(params.get('id'))

  const workspaceId = useSelector(selectActiveWorkspace)

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'error') navigate(ROUTES.EMPLOYEES, { replace: true })
    if (action === 'cancel' || action === 'success') navigate(ROUTES.MANAGEMENT)
  }

  if (!id) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  return (
    <>
      <div className="screen-employees edit">
        <div className="title-container">
          <Button
            variant="hover"
            color="secondary"
            startIcon={<BackI />}
            onClick={() => onRouteChange('cancel')}>
            Volver
          </Button>
        </div>
        <div className="container-form">
          <EmployeeProfileContainer
            workspaceId={workspaceId}
            employeeId={id}
            onRedirect={onRouteChange}
          />
        </div>
      </div>
    </>
  )
}

export default EditEmployeeScreen
