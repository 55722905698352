import React from 'react';

import Card, { CardProps as MUICardProps } from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Activity from 'components/Shared/Activity/Activity';

import ArrowAlt from '@material-ui/icons/Forward';
import { SameAlt } from 'components/Shared/CustomIcons';
import './style.scss';

interface DashboardCardProps extends MUICardProps {
    title?: string
    titleTag?: "h1"|"h2"|"h3"|"h4"|"h5"|"h6"|"p"
    loading?: boolean
    fetching?: boolean
    indicator?: "up"|"equal"|"down"
    value?: string | number
    extra?: string | number
    hideTitleDivider?: boolean
}

const DashboardCard: React.FC<DashboardCardProps> = ({ 
    title = "",
    titleTag = "p",
    className = "",
    loading = false,
    fetching = false,
    indicator = "equal",
    value,
    extra,
    hideTitleDivider = false,
    children,
    ...props 
}) => {
    const Tag = titleTag as keyof JSX.IntrinsicElements;

    return (
        <Card className={`component-dashboard-card ${className}`} {...props}>
            <div className="title-container">
                <Tag className="title">{title}</Tag>
                {(fetching && !loading) && 
                    <Activity className={`loader ${titleTag}`} local />
                }
            </div>
            {!hideTitleDivider && <Divider className="card-divider" />}
            <div className="card-content">
                {loading && 
                    <div className="loading-container">
                        <Activity size={50} local />
                    </div>
                }
                {(!loading && Boolean(value)) &&
                    <div className="status-container">
                        {indicator === "up"    && <ArrowAlt className={`icon gain`} fontSize="inherit" color="inherit" />}
                        {indicator === "equal" && <SameAlt className={`icon equal`} fontSize="inherit" color="inherit" />}
                        {indicator === "down"  && <ArrowAlt className={`icon loss`} fontSize="inherit" color="inherit" />}
                        <h1 className="value">{value || "- - -"}</h1>
                        {Boolean(extra) && <p className="extra">{extra}</p>}
                    </div>
                }
                {!loading && Boolean(children) && children}
            </div>
        </Card>
    );
}

export default DashboardCard;