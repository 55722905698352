import HTTPClient from 'lib/helpers/HTTPClient';
import _ from 'lodash';

import { httpErrorParser, parseClient } from 'lib/helpers/utilities';

import Client, { ClientForm } from 'models/client';
import ReduxState from 'models/redux';

export const SET_CLIENTS_LIST = '@Clients/SET_CLIENTS_LIST';
export const SET_SELECTED_CLIENT = '@Clients/SET_SELECTED_CLIENT';

const api = process.env.REACT_APP_APIURL || "/api";

export const setClientsList = (value: Client[]): object => ({
  type: SET_CLIENTS_LIST,
  value
})

export const setSelectedClient = (value: Client | null = null): object => ({
  type: SET_SELECTED_CLIENT,
  value
})

export const getClientById = (workspaceId: number, clientId: number, noSave: boolean = false) => async (dispatch: any): Promise<Client> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/clients/${clientId}`);
    const data = await petition.json();
    const c = parseClient(data);
    !noSave && dispatch(setSelectedClient(c));
    return c;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getClientsList = (workspaceId: number, noSave: boolean = false, search?: string, filter2?: string, filter3?: string) => async (dispatch: any, _getStore: () => ReduxState): Promise<Client[]> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(`${api}/workspaces/${workspaceId}/clients`);
    const data = await petition.json();
    const list = _.map(data, parseClient);
    !noSave && dispatch(setClientsList(list));
    return list;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const createClient = (workspaceId: number, form: ClientForm) => async (): Promise<Client> => {
  const client = HTTPClient.getClient();
  try {
    const body = {
      name: form.alias,
      bussinessName: form.name,
      rfc: _.toUpper(form.rfc),
      email: form.email,
      phone: form.phone,
      zipcode: form.zipCode,
      address: null,
      subsidiary: form.branch,
      contact: form.contact,
      idTypePerson: form.type?.idCatalogValues,
      taxPayerId: form.taxPayerId,
      foreing: form.foreign,
      idTaxRegime: form.taxResidence?.idCatalogValues
    }
    const petition = await client.post(`${api}/workspaces/${workspaceId}/clients`, body);
    const data = await petition.json();
    return parseClient(data);
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const updateClient = (workspaceId: number, clientId: number, form: ClientForm) => async (): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const body = {
      name: form.alias,
      bussinessName: form.name,
      rfc: _.toUpper(form.rfc),
      email: form.email,
      phone: form.phone,
      zipcode: form.zipCode,
      address: null,
      subsidiary: form.branch,
      contact: form.contact,
      idTypePerson: form.type?.idCatalogValues,
      taxPayerId: form.taxPayerId,
      foreing: form.foreign,
      idTaxRegime: form.taxResidence?.idCatalogValues
    }
    const petition = await client.put(`${api}/workspaces/${workspaceId}/clients/${clientId}`, body);
    const data = await petition.json();
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const deleteClient = (workspaceId: number, clientId: number) => async (): Promise<void> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.delete(`${api}/workspaces/${workspaceId}/clients/${clientId}`);
    await petition.data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}
