// import _ from 'lodash';
import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient';

import Category, { CategoryForm } from 'models/category';

const route = process.env.REACT_APP_APIURL || "/api/";

export const getCategoryById = async (workspaceId: number, categoryId: number): Promise<Category> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/categories/${categoryId}`);
        const category: Category = await petition.json();
        return category;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCategoriesList = async (workspaceId: number): Promise<Category[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/categories`);
        const list: Category[] = await petition.json();
        return list;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const createCategory = async (workspaceId: number, form: CategoryForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            name: form.name,
            description: form.description
        }
        const petition = await client.post(`${route}/workspaces/${workspaceId}/categories`, body);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const updateCategory = async (workspaceId: number, categoryId: number, form: CategoryForm): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            name: form.name,
            description: form.description
        }
        const petition = await client.put(`${route}/workspaces/${workspaceId}/categories/${categoryId}`, body);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const deleteCategory = async (workspaceId: number, categoryId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.delete(`${route}/workspaces/${workspaceId}/categories/${categoryId}`);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}