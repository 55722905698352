import { createContext, useContext, useMemo } from 'react'
import PaymentsHistoryTable from 'components/Account/Payments/PaymentsHistoryTable/PaymentsHistoryTable'
import PlanUpdateModal from 'components/Account/Payments/PlanUpdateModal/PlanUpdateModal'
import RecurrentPaymentCancellationModal from 'components/Account/Payments/RecurrentPaymentCancellationModal/RecurrentPaymentCancellationModal'
import usePaymentsContainer, {
  IUsePaymentsContainer,
} from './usePaymentsContainer'
import './styles.scss'

declare global {
  interface Window {
    ConektaCheckoutComponents: any
  }
}

const PaymentsContainerContext = createContext<IUsePaymentsContainer | null>(
  null
)

const PaymentsContainer = () => {
  const paymentContainerState = usePaymentsContainer()

  const { ActiveSelectedStep } = paymentContainerState

  const contextValue = useMemo(
    () => paymentContainerState,
    [paymentContainerState]
  )

  return (
    <PaymentsContainerContext.Provider value={contextValue}>
      <div className="payments-container">
        <div className="plan-info">
          {ActiveSelectedStep && <ActiveSelectedStep />}
        </div>
        <div className="payments-history-info">
          <h2>Historial de facturación</h2>
          <PaymentsHistoryTable />
        </div>
        <PlanUpdateModal />
        <RecurrentPaymentCancellationModal />
      </div>
    </PaymentsContainerContext.Provider>
  )
}

export const usePaymentsContainerContext = () => {
  const context = useContext(PaymentsContainerContext)
  if (!context) {
    throw new Error(
      'usePaymentsContainerContext must be used within a PaymentsContainerContext provider'
    )
  }
  return context
}

export default PaymentsContainer
