import update from 'immutability-helper';
import { 
	SET_CLIENTS_LIST,
    SET_SELECTED_CLIENT
} from 'actions/clients';

/** interfaces */
import { ClientsStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialClients: ClientsStore = {
    form: {
        type: null,
        alias: "",
        name: "",
        rfc: "",
        email: "",
        phone: "",
        zipCode: "",
        taxResidence: null,
    },
    filter: {},
    list: [],
    active: 0,
    selected: null
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: ""
}

const workspace = (state = initialClients, action = defaultAction) => {
    switch (action.type) {
        case SET_CLIENTS_LIST:
            return update(state, { list: { $set: action.value }});
        case SET_SELECTED_CLIENT: 
            return update(state, { selected: { $set: action.value }});
        default:
            return state
    }
}

export default workspace;