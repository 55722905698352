import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { filterIconColorPalette } from 'lib/helpers/utilities'

import { ReactComponent as TaskAltIcon } from 'assets/task_alt_black_24dp.svg'
import { ReactComponent as ArrowSameContained } from 'assets/ArrowSameContained.svg'
import './style.scss'

import { ExtendedIconColorPalette } from 'models/general'

interface IconProps extends Omit<SvgIconProps, 'color' | 'component'> {
  color?: ExtendedIconColorPalette
}

export const TaskAlt = ({ color = 'inherit', ...rest }: IconProps) => {
  return (
    <SvgIcon
      className={`custom-icon ${color}`}
      color={filterIconColorPalette(color)}
      component={TaskAltIcon}
      {...rest}
    />
  )
}

export const SameAlt = ({
  color = 'inherit',
  className = '',
  ...props
}: IconProps) => {
  return (
    <SvgIcon
      className={`custom-icon ${color} ${className}`}
      color="inherit"
      component={ArrowSameContained}
      viewBox="0 0 24 8"
      {...props}
    />
  )
}
