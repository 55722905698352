import { useState } from 'react'
import { Grid } from '@material-ui/core'
import ConciliationsContainer from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay'
import './style.scss'

const ConciliationsScreen = () => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)

  const updateScreenLoading = (isLoading: boolean) =>
    setIsScreenLoading(isLoading)

  return (
    <section className="screen-conciliations">
      <LoadingOverlay isLoading={isScreenLoading} />
      <Grid container spacing={0} className="main-container">
        <ConciliationsContainer
          isScreenLoading={isScreenLoading}
          updateScreenLoading={updateScreenLoading}
        />
      </Grid>
    </section>
  )
}

export default ConciliationsScreen
