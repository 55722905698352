import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridFilterListIcon,
  GridRowData,
  GridSelectionModel,
} from '@material-ui/data-grid'
import { TablePagination } from '@mui/material'
import { DEFAULT_TABLE_ITEMS_PER_PAGE } from '../../../constants'
import './styles.scss'

interface ISydTableProps {
  columns: GridColDef[]
  rows: GridRowData[]
  rowCount: number
  className?: string
  isLoading?: boolean
  itemsPerPage?: number
  page?: number
  pagination?: true
  paginationMode?: GridFeatureMode
  checkboxSelection?: boolean
  disableColumnMenu?: boolean
  rowHeight?: number
  noBorder?: boolean
  noDataText?: string
  onPageChange?: (newPage: number) => void
  handleSelectionModelChange?: (data: GridSelectionModel) => void
}

const SydTable = forwardRef(
  (props: ISydTableProps, ref: ForwardedRef<HTMLDivElement | null>) => {
    const {
      columns,
      rows,
      page,
      rowCount,
      itemsPerPage,
      pagination = undefined,
      paginationMode = 'client',
      className,
      isLoading,
      checkboxSelection = true,
      disableColumnMenu,
      rowHeight,
      noBorder,
      noDataText,
      onPageChange,
      handleSelectionModelChange,
    } = props

    const [localPage, setLocalPage] = useState(0)

    const pageSize = itemsPerPage ?? DEFAULT_TABLE_ITEMS_PER_PAGE
    const finalPage = page ?? localPage
    const finalOnPageChangeHandler = onPageChange ?? setLocalPage

    useEffect(() => {
      finalOnPageChangeHandler(0)
    }, [rowCount, finalOnPageChangeHandler])

    return (
      <DataGrid
        ref={ref}
        className={`syd-table ${className ?? ''} ${noBorder ? 'no-border' : ''} ${!pagination ? 'no-pagination' : ''}`}
        loading={isLoading}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        pageSize={pageSize}
        page={finalPage}
        pagination={pagination}
        paginationMode={paginationMode}
        autoHeight
        rowHeight={rowHeight ?? 58}
        checkboxSelection={checkboxSelection}
        disableColumnMenu={disableColumnMenu}
        disableSelectionOnClick
        onPageChange={onPageChange}
        onSelectionModelChange={handleSelectionModelChange}
        components={{
          ColumnResizeIcon: () => null,
          ColumnSortedAscendingIcon: GridFilterListIcon,
          ColumnSortedDescendingIcon: GridFilterListIcon,
          Pagination: pagination
            ? () => (
                <TablePagination
                  rowsPerPageOptions={[]}
                  rowsPerPage={pageSize}
                  count={rowCount ?? 0}
                  page={finalPage}
                  onPageChange={(event, newPage) =>
                    finalOnPageChangeHandler(newPage)
                  }
                  showFirstButton
                  showLastButton
                  component={'div'}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                  style={{ borderBottom: 'none' }}
                />
              )
            : undefined,
          LoadingOverlay: () => (
            <LinearProgress style={{ zIndex: 999 }} variant="query" />
          ),
          NoRowsOverlay: () => (
            <div className="syd-table-no-data">
              <p>{noDataText ?? 'No hay datos para mostrar'}</p>
            </div>
          ),
        }}
      />
    )
  }
)

export default SydTable
