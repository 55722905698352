import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { TextField } from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { CatalogueEntry } from 'models/catalogues'
import { ICFDISyncForm } from 'lib/forms/management/management.form.models'
import { CFDISyncFormValidationSchema } from 'lib/forms/management/management.schemas'
import {
  selectActiveWorkspace,
  selectCatalogue,
  selectProfile,
} from 'lib/helpers/selectors'
import { getYearList } from 'lib/helpers/utilities'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import { syncMultipleWithSAT } from 'services/Shared/shared.services'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import useWorkspacesActions from '../ManagementWorkspacesActions/useWorkspacesActions'
import './styles.scss'

const YearField = () => {
  const profile = useSelector(selectProfile)
  const years = getYearList(profile?.createdAt)
  const { register } = useFormContext()
  return (
    <div className="sync-input">
      <input type="hidden" />
      <Autocomplete
        options={years}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        renderInput={params => (
          <TextField
            {...params}
            {...register('year')}
            label="Año"
            variant="standard"
            fullWidth
          />
        )}
        fullWidth
      />
    </div>
  )
}

const MonthField = () => {
  const months = useSelector(selectCatalogue('meses'))
  const { register } = useFormContext()
  return (
    <div className="sync-input half">
      <input type="hidden" />
      <Autocomplete
        options={_.slice(months, 0, 12)}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        renderInput={params => (
          <TextField
            {...params}
            {...register('month')}
            label="Mes de inicio"
            variant="standard"
            fullWidth
          />
        )}
        fullWidth
      />
    </div>
  )
}

const EndMonthField = () => {
  const months = useSelector(selectCatalogue('meses'))
  const { register } = useFormContext()
  return (
    <div className="sync-input half">
      <input type="hidden" />
      <Autocomplete
        options={_.slice(months, 0, 12)}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        renderInput={params => (
          <TextField
            {...params}
            {...register('endMonth')}
            label="Mes de fin"
            variant="standard"
            fullWidth
          />
        )}
        fullWidth
      />
    </div>
  )
}

interface IManagementWorkspacesSyncFormProps {
  isOpen: boolean
  onClose: () => void
}

const ManagementWorkspacesSyncForm = ({
  isOpen,
  onClose,
}: IManagementWorkspacesSyncFormProps) => {
  const { selectedWorkspacesWithRFC } = useWorkspacesActions()

  const workspaceId = useSelector(selectActiveWorkspace)
  const months = useSelector(selectCatalogue('meses'))

  const methods = useForm<ICFDISyncForm>({
    mode: 'onChange',
    defaultValues: {
      year: '',
      month: '',
      endMonth: '',
    },
    resolver: yupResolver(CFDISyncFormValidationSchema),
  })

  const {
    formState: { isValid },
  } = methods

  const { icon, closeButton, ...loadingToastOptions } = successToastOptions

  const mutation = useMutation(
    (data: ICFDISyncForm) => {
      const { year, month, endMonth } = data

      const body = {
        request: selectedWorkspacesWithRFC,
        year: Number(year),
        month: Number(months.find(mo => mo.label === month)?.value),
        endMonth: Number(months.find(mo => mo.label === endMonth)?.value),
      }

      return syncMultipleWithSAT(workspaceId, body)
    },
    {
      onMutate: () => {
        toast.loading('Iniciando sincronización', {
          ...loadingToastOptions,
          toastId: 'loading',
        })
      },
      onSuccess: () => {
        toast.dismiss('loading')
        toast.success(
          'Se ha iniciado la sincronización de CFDIs',
          successToastOptions
        )
        onClose()
      },
      onError: () => {
        toast.dismiss('loading')
        toast.error('No fue posible sincronizar los CFDIs', errorToastOptions)
      },
    }
  )

  const { isLoading } = mutation

  const onSubmit = (data: ICFDISyncForm) => {
    mutation.mutate(data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SydDialog
          className="management-workspaces-sync-form-component"
          open={isOpen}
          title="Sincronizar con el SAT"
          subtitle="Para realizar la sincronización de CFDI's puedes configurar los siguientes datos:"
          primaryButtonText="Sincronizar"
          onConfirm={() => methods.handleSubmit(onSubmit)()}
          disablePrimaryButton={!isValid || isLoading}
          loadingPrimaryButton={isLoading}
          form=""
          secondaryButtonText="Cancelar"
          onCancel={onClose}
          sideButtons>
          <YearField />
          <MonthField />
          <EndMonthField />
        </SydDialog>
      </form>
    </FormProvider>
  )
}

export default ManagementWorkspacesSyncForm
