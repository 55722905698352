import { useCallback, useEffect, useRef } from 'react'
import { selectActiveWorkspace, selectAuthorized } from 'lib/helpers/selectors'
import { clearInvoicesLocalFilterValues } from 'lib/utils/Invoices/invoices.utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { resetAppState } from 'store/shared.actions'
import { selectSSEClientId } from 'store/SSE/sse.selectors'
import useInitializeSSEClientId from './SSE/useInitializeSSEClientId'

const useGlobalEvents = () => {
  const dispatch = useAppDispatch()

  const authorized = useAppSelector(selectAuthorized)
  const activeWorkspace = useAppSelector(selectActiveWorkspace)
  const sseClientId = useAppSelector(selectSSEClientId)

  const initializeSSEClientIdMutation = useInitializeSSEClientId()

  const resetState = useCallback(() => {
    dispatch(resetAppState())
    clearInvoicesLocalFilterValues()
  }, [dispatch])

  /* IMPORTANT: The way the 'old' reducers are set up trigger state updates as objects even if for primitive values, which
  can cause confusion as to the stability of dependencies, like activeWorkspace in this case. Updating all reducers to slices,
  on top of saving hundreds of lines, can probably fix this issue although we need to do the update in order to be sure. */

  const prevActiveWorkspaceRef = useRef(JSON.stringify(activeWorkspace))

  useEffect(() => {
    const serializedActiveWorkspace = JSON.stringify(activeWorkspace)
    if (prevActiveWorkspaceRef.current !== serializedActiveWorkspace) {
      resetState()
      prevActiveWorkspaceRef.current = serializedActiveWorkspace
    }
  }, [resetState, activeWorkspace])

  useEffect(() => {
    if (authorized && !sseClientId) {
      initializeSSEClientIdMutation.mutate()
    }
  }, [dispatch, authorized, sseClientId, initializeSSEClientIdMutation])
}

export default useGlobalEvents
