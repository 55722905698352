import { SydIconsModel } from 'models/shared.models'

const ZIPFileIcon = ({ color }: SydIconsModel) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49992 11.25C7.49992 10.8522 7.65795 10.4706 7.93926 10.1893C8.22056 9.90804 8.60209 9.75 8.99992 9.75H10.4999C10.8977 9.75 11.2793 9.90804 11.5606 10.1893C11.8419 10.4706 11.9999 10.8522 11.9999 11.25V12.657L12.5999 15.0555C12.6747 15.3556 12.6548 15.6715 12.5431 15.9599C12.4314 16.2482 12.2333 16.4951 11.9759 16.6665L10.5809 17.5965C10.3344 17.761 10.0447 17.8487 9.74842 17.8487C9.4521 17.8487 9.16241 17.761 8.91592 17.5965L7.52242 16.6665C7.26529 16.4949 7.06748 16.248 6.95605 15.9596C6.84462 15.6712 6.82501 15.3554 6.89992 15.0555L7.49992 12.657V11.25ZM10.4999 11.25H8.99992V12.657C8.99991 12.7799 8.98479 12.9023 8.95492 13.0215L8.35492 15.4185L9.74992 16.3485L11.1434 15.4185L10.5434 13.0215C10.514 12.9022 10.4994 12.7798 10.4999 12.657V11.25Z"
        fill={color ?? '#6885A0'}
      />
      <path
        d="M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H6C5.20435 24 4.44129 23.6839 3.87868 23.1213C3.31607 22.5587 3 21.7956 3 21V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM16.5 6.75C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H11.25V3H9.75V4.5H11.25V6H9.75V7.5H11.25V9H9V7.5H7.5V6H9V4.5H7.5V3H9V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5Z"
        fill={color ?? '#6885A0'}
      />
    </svg>
  )
}

export default ZIPFileIcon
