import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from 'components/Shared/Button/Button'
import NewCFDIDialog from 'components/Shared/NewCFDIDialog/NewCFDIDialog'

import PlanInformationContainer from 'containers/Dashboard/PlanInformationContainer/PlanInformationContainer'
import MonthlyIncomeContainer from 'containers/Dashboard/MonthlyIncomeContainer/MonthlyIncomeContainer'
import UserCountContainer from 'containers/Dashboard/UserCountContainer/UserCountContainer'
import ClientCountContainer from 'containers/Dashboard/ClientCountContainer/ClientCountContainer'
import AccountingStatusContainer from 'containers/Dashboard/AccountingStatusContainer/AccountingStatusContainer'
import IncomesExpensesGraphContainer from 'containers/Dashboard/IncomesExpensesGraphContainer/IncomesExpensesGraphContainer;'
// import CashflowGraph from 'containers/Dashboard/CashflowGraphContainer';
// import CategoryPieGraphContainer from 'containers/Dashboard/CategoryPieGraphContainer';
import EmisorCountContainer from 'containers/Dashboard/EmisorCountContainer/EmisorCountContainer'
import IncomeInvoiceCountContainer from 'containers/Dashboard/IncomeInvoiceCountContainer/IncomeInvoiceCountContainer'
import CategoryCountContainer from 'containers/Dashboard/CategoryCountContainer/CategoryCountContainer'
import ProductCountContainer from 'containers/Dashboard/ProductCountContainer/ProductCountContainer'
import InvoiceList from 'containers/Dashboard/InvoiceListContainer/InvoiceListContainer'

import {
  selectActiveWorkspace,
  selectWorkspacesList,
  selectProfile,
} from 'lib/helpers/selectors'

import { ROUTES } from '../../constants'

import Banner from 'assets/SMPL_Home_Banner_610x160.png'
import Add from '@material-ui/icons/Add'
import './style.scss'
import TaxResidenceLabel from 'components/Dashboard/TaxResidenceLabel/TaxResidenceLabel'

interface Props {}

const DashboardScreen: React.FC<Props> = props => {
  const intl = useIntl()
  const navigate = useNavigate()

  const profile = useSelector(selectProfile)
  const wsList = useSelector(selectWorkspacesList)
  const wsActive = useSelector(selectActiveWorkspace)

  const [open, setOpen] = useState(false)

  return (
    <>
      {wsList.length > 0 && (
        <div className="screen-home-title">
          <Grid container spacing={0} direction="row" justifyContent="center">
            <Grid item xs={10}>
              {!profile?.name ? (
                <h1 id="dbProfileName">Hola</h1>
              ) : (
                <h1 id="dbProfileName">Hola, {profile?.name}</h1>
              )}
              <TaxResidenceLabel />
            </Grid>
            <Grid item xs={2}>
              <Button
                id="newCfdiBtn"
                color="secondary"
                variant="contained"
                onClick={() => setOpen(true)}
                startIcon={<Add />}
                disabled={!wsActive}
                fullWidth>
                {intl.formatMessage({ id: 'home.workspace.new_cfdi' })}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {wsList.length > 0 && Boolean(wsActive) && (
        <div className="screen-home-dashboard">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={6} md={3}>
              <PlanInformationContainer workspaceId={wsActive} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MonthlyIncomeContainer workspaceId={wsActive} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <UserCountContainer workspaceId={wsActive} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ClientCountContainer workspaceId={wsActive} />
            </Grid>
            <Grid item xs={12}>
              <AccountingStatusContainer workspaceId={wsActive} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IncomesExpensesGraphContainer workspaceId={wsActive} />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
                        <CashflowGraph workspaceId={wsActive} />
                    </Grid> */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <EmisorCountContainer workspaceId={wsActive} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <IncomeInvoiceCountContainer workspaceId={wsActive} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CategoryCountContainer workspaceId={wsActive} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ProductCountContainer workspaceId={wsActive} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                        <CategoryPieGraphContainer workspaceId={wsActive} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <EmisorCountContainer workspaceId={wsActive} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <IncomeInvoiceCountContainer workspaceId={wsActive} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CategoryCountContainer workspaceId={wsActive} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ProductCountContainer workspaceId={wsActive} />
                            </Grid>
                        </Grid>
                    </Grid> */}
            <Grid item xs={12}>
              <InvoiceList workspaceId={wsActive} />
            </Grid>
          </Grid>
        </div>
      )}
      {wsList.length > 0 && (
        <NewCFDIDialog open={open} onClose={() => setOpen(false)} />
      )}
      {wsList.length === 0 && (
        <Grid container spacing={0} direction="row" justifyContent="center">
          <Grid item xs={12} className="screen-home-container">
            <Card className="screen-home" elevation={1}>
              <CardContent className="header">
                <h1>{intl.formatMessage({ id: 'home.workspace.title' })}</h1>
                <h2>{intl.formatMessage({ id: 'home.workspace.subtitle' })}</h2>
              </CardContent>
              <CardMedia className="image" image={Banner} title="Placeholder" />
              <CardContent className="content">
                <p>{intl.formatMessage({ id: 'home.workspace.text' })}</p>
              </CardContent>
              <CardActions className="actions">
                <Button
                  id="newCfdiBtn"
                  color="secondary"
                  variant="contained"
                  onClick={() => navigate(ROUTES.ADD_WORKSPACE)}>
                  {intl.formatMessage({ id: 'home.workspace.button.create' })}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default DashboardScreen
