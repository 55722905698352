import update from 'immutability-helper';
import _ from 'lodash';
import { 
    SET_ORIGIN,
    SET_ACTIVE_TAB,
    SET_MONTH,
    SET_YEAR,
    SET_CATALOGUE,
    SET_INITIAL_LIST,
    SET_SELECTED_LIST,
    ADD_TO_SELECTED_LIST,
    REMOVE_FROM_SELECTED_LIST,
    SET_ACCOUNTING_ID,
    RESET_DOCUMENT_REQUEST
} from 'actions/documentRequest';

/** interfaces */
import { DocumentRequestStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialState: DocumentRequestStore = {
    origin: "",
    activeTab: 0,
    catalogue: [],
    initialList: [],
    selectedList: []
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: {}
}

const documentRequest = (state = initialState, action = defaultAction) => {
    switch (action.type) {
        case SET_ORIGIN:
            return update(state, { origin: { $set: action.value } });
        case SET_MONTH:
            return update(state, { month: { $set: action.value } });
        case SET_YEAR:
            return update(state, { year: { $set: action.value } });
        case SET_ACTIVE_TAB:
            return update(state, { activeTab: { $set: action.value } });
        case SET_CATALOGUE:
            return update(state, { catalogue: { $set: action.value } });
        case SET_ACCOUNTING_ID:
            return update(state, { accountingId: { $set: action.value } });
        case SET_INITIAL_LIST:
            return update(state, { initialList: { $set: action.value } });
        case RESET_DOCUMENT_REQUEST:
            return initialState;
        case SET_SELECTED_LIST:
            return update(state, { selectedList: { $set: action.value } });
        case ADD_TO_SELECTED_LIST:
            return update(state, { selectedList: { $push: action.value } });
        case REMOVE_FROM_SELECTED_LIST:
            const ids = _.map(action.value, (e) => e.id);
            const filteredList = _.filter(state.selectedList, (el) => ids.indexOf(el.id) === -1) || [];
            return update(state, { selectedList: { $set: filteredList } });
        default:
            return state;
    }
}

export default documentRequest;
