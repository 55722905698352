import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import _ from 'lodash'

import usePathListener from 'hooks/usePathListener'
import { selectAuthorized, selectUserRole } from 'lib/helpers/selectors'

import { ROUTES } from '../constants'

import { Role } from 'models/user'

interface Props {
  inverse?: boolean
  roles: Role[]
}

export const AuthorizationHub: React.FC<Props> = ({
  inverse = false,
  roles,
  children,
  ...props
}) => {
  const { pathname } = useLocation()

  const logged = useSelector(selectAuthorized)
  const currentRole = useSelector(selectUserRole)
  const authorized = logged && _.some(roles, r => currentRole?.id === r.id)

  usePathListener(pathname, !authorized)

  const showComponent = (authorized && !inverse) || (!authorized && inverse)
  const redirect = inverse ? ROUTES.HOME : ROUTES.LOGIN

  return (
    <>
      {!showComponent && <Navigate to={redirect} replace />}
      {showComponent && children}
    </>
  )
}

export default AuthorizationHub
