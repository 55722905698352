import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { AccountingComentsHistoryResponse } from 'models/accounting.models'
import {
  selectActiveWorkspace,
  selectProfile,
  selectUserRole,
} from 'lib/helpers/selectors'
import { getCommentInfo } from 'lib/utils/Accounting/accounting.utils'
import { getClarificationRequestCommentsHistory } from 'services/Accounting/accounting.services'
import Activity from 'components/Shared/Activity/Activity'
import AccountingComment from '../AccountingComment/AccountingComment'
import AccountingCommentInput from '../AccountingCommentInput/AccountingCommentInput'
import { useAccountingStepThreeContext } from '../AccountingStepThree/AccountingStepThree'
import './style.scss'
import { selectCurrentAccountingProcess } from 'store/Accounting/accounting.selectors'

const AccountingCommentHistory = () => {
  const { setIsOpenCommentHistory, setLatestComment, handleSendMessage } =
    useAccountingStepThreeContext()

  const workspaceId = useSelector(selectActiveWorkspace)
  const userRole = useSelector(selectUserRole)
  const userProfile = useSelector(selectProfile)
  const currentAccountingProcess = useSelector(selectCurrentAccountingProcess)

  const [localCommentsHistory, setLocalCommentsHistory] =
    useState<AccountingComentsHistoryResponse[]>()

  const accountingId = currentAccountingProcess?.id

  const {
    isLoading: commentsHistoryLoading,
    isFetching: commentsHistoryFetching,
  } = useQuery<AccountingComentsHistoryResponse[]>(
    ['get-comments-history', workspaceId, accountingId],
    () => {
      if (!accountingId) {
        throw new Error('Accounting ID is required')
      }
      return getClarificationRequestCommentsHistory(workspaceId, accountingId)
    },
    {
      enabled: !!accountingId,
      onSuccess: data => {
        setLocalCommentsHistory(data)
      },
    }
  )

  const finalLoading = commentsHistoryLoading || commentsHistoryFetching

  const handleClose = () => {
    setIsOpenCommentHistory(false)
  }

  const sendMessageMutation = useMutation(
    (message: string) => handleSendMessage(message),
    {
      onSuccess: data => {
        const typedData = data as unknown as { text: string }
        setLatestComment(typedData.text)
        localCommentsHistory &&
          setLocalCommentsHistory([
            ...localCommentsHistory,
            {
              message: typedData.text,
              roleId: localCommentsHistory.every(({ roleId }) => !!roleId)
                ? userRole?.id
                : undefined,
              sentBy: localCommentsHistory.every(({ sentBy }) => !!sentBy)
                ? userProfile.email
                : undefined,
            },
          ])
      },
    }
  )

  const sendMessage = (message: string) => sendMessageMutation.mutate(message)

  return (
    <div className="accounting-comment-history">
      <div className="comment-history-title">
        <span>Historial de comentarios: </span>
        <div className="comment-history-title-close-icon" onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>

      <div className="accounting-comment-zone">
        {finalLoading && (
          <Activity size={40} color="primary" message="Cargando el historial" />
        )}

        {!finalLoading &&
          localCommentsHistory &&
          localCommentsHistory.map(({ id, message, roleId, sentBy }, index) => (
            <div
              key={`accounting-comment-${id ?? index}`}
              className={
                getCommentInfo(userRole, userProfile, index, roleId, sentBy)
                  .className
              }>
              <AccountingComment
                isSender={
                  getCommentInfo(userRole, userProfile, index, roleId, sentBy)
                    .isSender
                }
                message={message}
                userType={
                  getCommentInfo(userRole, userProfile, index, roleId, sentBy)
                    .userType
                }
              />
            </div>
          ))}
      </div>

      <div className="accounting-comment-send-messages">
        <AccountingCommentInput handleSendMessage={sendMessage} />
      </div>
    </div>
  )
}

export default AccountingCommentHistory
