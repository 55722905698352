import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'

import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import Button from 'components/Shared/Button/Button'
import PasswordRules from 'components/Shared/PasswordRules/PasswordRules'

import { register } from 'actions/user'
import { setActiveForm } from 'actions/login'

import { TaskAlt } from 'components/Shared/CustomIcons'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Logo from 'assets/logo.svg'
import './style.scss'

import { LoginScreenForms } from 'models/general'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

type RegisterValues = {
  name?: string
  lastname?: string
  email: string
  telephone?: string
  password: string
  promoCode?: string
}

interface RegisterFormProps {
  email?: string
  onClickLogin: () => void
}

const RegisterFormContainer: React.FC<RegisterFormProps> = ({
  email,
  onClickLogin,
}) => {
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [validForm, setValidForm] = useState<boolean>(false)
  const [validRules, setValidRules] = useState<boolean>(false)
  const thunkDispatch = useThunkDispatch()
  const dispatch = useDispatch()
  const intl = useIntl()
  const dialog = useDialog()

  const isConfirmedPasswordEqual = password === confirmPassword

  const onSubmit = async (v: RegisterValues) => {
    setLoading(true)
    try {
      await thunkDispatch(
        register(
          v.email,
          v.password,
          v.name,
          v.lastname,
          v.telephone,
          v.promoCode
        )
      )
      await dialog.primaryAsync({
        title: 'register.success.title',
        subtitle: 'register.success.message',
        icon: TaskAlt,
      })
      dispatch(setActiveForm(LoginScreenForms.LOGIN))
    } catch (err) {
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'register.error.message',
        icon: ErrorOutline as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container-register-form">
      <a
        className="section logo"
        href="https://www.simmple.mx/"
        target="_blank"
        rel="noopener noreferrer">
        <img src={Logo} alt="Logo" />
      </a>
      <div className="form scrollbar">
        <h3>{intl.formatMessage({ id: 'register.title' })}</h3>
        <Form
          onSubmit={onSubmit}
          lock={loading}
          onValidity={(v: boolean) => setValidForm(v && validRules)}>
          <TextField
            name="name"
            label={intl.formatMessage({ id: 'form.name' })}
            fieldType="lettersWithAccents"
            inputProps={{ maxLength: 60 }}
            fullWidth
          />
          <TextField
            name="lastname"
            label={intl.formatMessage({ id: 'form.lastname' })}
            fieldType="lettersWithAccents"
            inputProps={{ maxLength: 60 }}
            fullWidth
          />
          <TextField
            name="email"
            validate={['isEmail', 'isEmailFormat']}
            value={email}
            label={intl.formatMessage({ id: 'form.email' })}
            disabled={!!email}
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <NumericField
            name="telephone"
            validate={['isTelephone']}
            label={intl.formatMessage({ id: 'form.phone' })}
            format="phone"
            fullWidth
          />
          <TextField
            name="password"
            label={intl.formatMessage({ id: 'form.password' })}
            onChange={(v: string) => setPassword(v)}
            validate={['isValidPassword']}
            fieldType="password"
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <TextField
            name="cpassword"
            label={intl.formatMessage({ id: 'form.confirm_password' })}
            onChange={(v: string) => setConfirmPassword(v)}
            fieldType="password"
            inputProps={{ maxLength: 60 }}
            fullWidth
            required
          />
          <PasswordRules
            password={password}
            onValidity={(v: boolean) => setValidRules(v)}
          />
          <p>{intl.formatMessage({ id: 'register.promoCode' })}</p>
          <TextField
            name="promoCode"
            //label={intl.formatMessage({ id: "form.promo_code" })}
            inputProps={{ maxLength: 25 }}
            fullWidth
            fieldType={'alphanumeric'}
          />
          <Button
            id="createAccountBtn"
            color="secondary"
            type="submit"
            variant="contained"
            fullWidth
            disabled={!(validForm && isConfirmedPasswordEqual)}
            activity={loading}>
            {intl.formatMessage({ id: 'register.button.create_account' })}
          </Button>
        </Form>
        <div className="with-account">
          <p>{intl.formatMessage({ id: 'register.with_account.title' })}</p>
          <Button
            id="loginBtn"
            color="secondary"
            variant="link"
            onClick={onClickLogin}>
            {intl.formatMessage({ id: 'register.with_account.link' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RegisterFormContainer
