import { IDropdownOption } from 'models/general'
import { TManagementTabs } from 'models/management.models'
import { EXPIRED, WARNING, VALID } from '../../../constants'
import { MANAGEMENT_TABS } from 'lib/enums/management.enums'
import { ISydTab } from 'components/Shared/SydTabs/SydTabs'

export const managementTabLabels: Record<TManagementTabs, string> = {
  [MANAGEMENT_TABS.WORKSPACES]: 'Espacios de Trabajo',
  [MANAGEMENT_TABS.ACCOUNTANTS]: 'Contadores',
} as const

export const managementTabs: Readonly<ISydTab[]> = [
  {
    id: 'management-tab-1',
    label: managementTabLabels[MANAGEMENT_TABS.WORKSPACES],
  },
  {
    id: 'management-tab-2',
    label: managementTabLabels[MANAGEMENT_TABS.ACCOUNTANTS],
  },
] as const

export const workspaceStatuses: IDropdownOption[] = [
  {
    label: 'Activo',
    value: '1',
  },
  {
    label: 'Inactivo',
    value: '2',
  },
  {
    label: 'Pendiente',
    value: '3',
  },
  {
    label: 'Suspendido Moroso',
    value: '4',
  },
  {
    label: 'Suspendido no contesta',
    value: '5',
  },
]

export const daysToExpire = (date: Date) => {
  const currentDate = new Date()
  const oneMonthFromNow = new Date()
  oneMonthFromNow.setMonth(currentDate.getMonth() + 1)

  if (date <= currentDate) {
    return EXPIRED
  } else if (date <= oneMonthFromNow) {
    return WARNING
  } else {
    return VALID
  }
}
