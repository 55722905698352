import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import _ from 'lodash';

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import EmployeesContainer from 'containers/Employees/EmployeesContainer/EmployeesContainer'

import { selectActiveWorkspace } from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Add from '@material-ui/icons/Add'
import './style.scss'

interface Props {}

const ListEmployeesScreen: React.FC<Props> = props => {
  const navigate = useNavigate()

  const workspaceId = useSelector(selectActiveWorkspace)

  const onRouteChange = (action: string, id: number = 0) => {
    if (action === 'back') navigate(-1)
    if (action === 'edit') navigate(`${ROUTES.EMPLOYEE_DETAILS}?id=${id}`)
    if (action === 'add') console.log(ROUTES.EMPLOYEE_DETAILS)
    if (action === 'error') navigate(ROUTES.HOME, { replace: true })
  }

  return (
    <>
      <Grid container spacing={0} className="screen-employees">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Contadores</h1>
            <Button
              id="newEmployeeBtn"
              variant="outlined"
              color="warning"
              startIcon={<Add />}
              onClick={() => onRouteChange('add')}
              disabled>
              Nuevo Contador (Proximamente)
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <EmployeesContainer
            workspaceId={workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ListEmployeesScreen
