import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { useIntl } from 'react-intl'
import { Money, Currencies } from 'ts-money'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from 'components/Shared/Button/Button'
import ScrollSpy from 'components/Invoices/ScrollSpy/ScrollSpy'
import DisplayTotal from 'components/Invoices/DisplayTotal/DisplayTotal'
import { DropdownCard } from 'components/FormComponents/Dropdown/Dropdown'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import { setLocalLoader } from 'actions/loader'
import {
  createPaymentComplement,
  getIncomeInvoiceById,
  setSelectedInvoice,
} from 'actions/invoices'
import { storeFormValue } from 'actions/forms'

import {
  selectSelectedInvoice,
  selectCatalogue,
  selectCreatePaymentComplement,
} from 'lib/helpers/selectors'

import ErrorI from '@material-ui/icons/ErrorOutline'
import './style.scss'

import { CatalogueEntry } from 'models/catalogues'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import { getSafeDecimalValue } from 'lib/helpers/shared.helpers'

interface Props {
  workspaceId: number
  invoiceId: number
  onRedirect: (action: string) => void
}

const AddPaymentComplementContainer: React.FC<Props> = ({
  workspaceId,
  invoiceId,
  onRedirect,
}) => {
  const dispatch = useDispatch()
  const thunkDispatch = useThunkDispatch()
  const dialog = useDialog()
  const intl = useIntl()

  const invoice = useSelector(selectSelectedInvoice)
  const form = useSelector(selectCreatePaymentComplement)
  const monedas = useSelector(selectCatalogue('monedas'))
  const formasPago = useSelector(selectCatalogue('formasPago'))
  //const metodosPago = useSelector(selectCatalogue("metodosPago"));

  const [loading, setLoading] = useState(false)
  const [valid, setValid] = useState(false)

  const getInformation = async () => {
    dispatch(setLocalLoader(true, 'Cargando catálogos personalizados'))
    try {
      await Promise.all([
        thunkDispatch(getIncomeInvoiceById(workspaceId, invoiceId)),
      ])
      dispatch(setLocalLoader(false))
    } catch (err) {
      dispatch(setLocalLoader(false))
      const rp = await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo obtener la informacion del CFDI.',
        icon: ErrorI as TModalIcon,
        primaryButtonText: 'Reintentar',
        secondaryButtonText: 'Cancelar',
      })
      if (rp) {
        getInformation()
      } else {
        dispatch(setLocalLoader(false))
        onRedirect('cancel')
      }
    }
  }

  useEffect(() => {
    getInformation()
    return () => {
      dispatch(setSelectedInvoice())
      dispatch(setLocalLoader(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, invoiceId])

  // Se encarga de poner los valores por defecto a ciertos campos.
  useEffect(() => {
    if (_.isNil(form.moneda)) {
      const mxn = _.filter(monedas, m => m.value === 'MXN')[0]
      saveValueToStore('moneda', mxn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatCatalogueLabel = (type?: CatalogueEntry) => {
    if (!type) return '---'
    return `${type.value} - ${type.label}`
  }
  const formatMoney = (money?: number) => {
    if (!money && money !== 0) return '---'
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }
  const formatDate = (dateString?: string) => {
    if (!dateString) return ''
    const date = new Date(_.split(dateString, '.')[0])
    const formatted = intl.formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    return formatted
  }
  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )
  const saveValueToStore = (name: string, value: any) => {
    dispatch(storeFormValue('createPaymentComplement', name, value))
  }

  const onAdd = async (form: any) => {
    setLoading(true)
    try {
      if (!invoice) throw new Error('CFDI cargado incorrectamente.')
      await thunkDispatch(createPaymentComplement(workspaceId, invoice))
      onRedirect('success')
    } catch (err: any) {
      const op = selectErrorDialog(err)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }

      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo crear el CFDI. Intenta nuevamente.',
        icon: ErrorI as TModalIcon,
      })
    } finally {
      setLoading(false)
    }
  }

  const isa = Money.fromDecimal(
    getSafeDecimalValue(form.importeSaldoAnterior),
    Currencies.MXN
  )

  const ip = Money.fromDecimal(
    getSafeDecimalValue(form.importePagado),
    Currencies.MXN
  )
  const monto = Money.fromDecimal(
    getSafeDecimalValue(form.monto),
    Currencies.MXN
  )

  return (
    <>
      <DropdownCard
        id="invoiceDropdown"
        title="Factura Original"
        headerBackgroundColor="secondary"
        capitalize
        iconAlign="left"
        defaultExpanded
        elevation={3}>
        <Form onSubmit={onAdd} onValidity={() => true}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                name="fecha"
                label="Fecha"
                value={formatDate(invoice?.createdAt)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="serie"
                label="Serie"
                value={invoice?.serie}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="folio"
                label="Folio"
                value={invoice?.folio}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="razonSocial"
                label="Razón social"
                value={invoice?.emisor?.name}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="moneda"
                label="Moneda"
                value={formatCatalogueLabel(invoice?.currency)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="total"
                label="Total"
                value={formatMoney(invoice?.total)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: true }}
                fullWidth
                exclude
              />
            </Grid>
          </Grid>
        </Form>
      </DropdownCard>
      <Card elevation={1} className="container-add-complement">
        <div className="scrollspy-container">
          <ScrollSpy
            items={[
              { id: 'section-1', label: '1', tooltip: 'Recepción de pagos' },
              {
                id: 'section-2',
                label: '2',
                tooltip: 'Documentos relacionados',
              },
            ]}
          />
        </div>
        <div className="form-container">
          <Form onSubmit={onAdd} onValidity={(v: boolean) => setValid(v)}>
            <div id="section-1" className="content">
              <h3 className="title">(1) Recepción de pagos</h3>
              <Divider className="separator" />
              <p className="subtitle">Datos del pago</p>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <NumericField
                    name="fechaPago"
                    label="Fecha de pago"
                    value={form?.fechaPago}
                    onValue={(v: string) => saveValueToStore('fechaPago', v)}
                    validate={['isDateFormatD', 'isValidDateD']}
                    format="date"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="formaPago"
                    label="Forma de pago"
                    catalogue={formasPago}
                    value={form?.formaPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('formaPago', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="moneda"
                    label="Moneda"
                    catalogue={monedas}
                    value={form?.moneda}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('moneda', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    getOptionSelected={(
                      option: CatalogueEntry,
                      value: CatalogueEntry
                    ) => option.idCatalogValues === value.idCatalogValues}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                    required
                  />
                </Grid>
                {form?.moneda?.value !== 'MXN' && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      value={form?.tipoCambio}
                      onValue={(v: string) => saveValueToStore('tipoCambio', v)}
                      format="money"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <NumericField
                    name="monto"
                    label="Monto"
                    value={form?.monto}
                    onValue={(v: string) => saveValueToStore('monto', v)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fieldType="number"
                    name="numeroOperacion"
                    label="Número de operación (opcional)"
                    value={form?.numeroOperacion}
                    onValue={(v: string) =>
                      saveValueToStore('numeroOperacion', v)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <p className="subtitle">Información Adicional</p>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="rfcOrigen"
                    label="RFC Emisor de cuenta origen (opcional)"
                    validate={['isCompanyRFC', 'isPersonRFC']}
                    value={form?.rfcOrigen}
                    onValue={(v: string) => saveValueToStore('rfcOrigen', v)}
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="rfcBeneficiario"
                    label="RFC Emisor cuenta beneficiaria (opcional)"
                    validate={['isCompanyRFC', 'isPersonRFC']}
                    value={form?.rfcBeneficiario}
                    onValue={(v: string) =>
                      saveValueToStore('rfcBeneficiario', v)
                    }
                    inputProps={{
                      maxLength: 13,
                      style: { textTransform: 'uppercase' },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="nombreBanco"
                    label="Nombre de banco (opcional)"
                    value={form?.nombreBanco}
                    onValue={(v: string) => saveValueToStore('nombreBanco', v)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cuentaBeneficiario"
                    label="Cuenta beneficiario (opcional)"
                    value={form?.cuentaBeneficiario}
                    onValue={(v: string) =>
                      saveValueToStore('cuentaBeneficiario', v)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cuentaOrdenante"
                    label="Cuenta ordenante (opcional)"
                    value={form?.cuentaOrdenante}
                    onValue={(v: string) =>
                      saveValueToStore('cuentaOrdenante', v)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <p className="subtitle" />
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <AutocompleteField
                    name="tipoCadenaPago"
                    label="Tipo cadena de pago (opcional)"
                    catalogue={[]}
                    value={form?.tipoCadenaPago}
                    onValue={(v: CatalogueEntry) =>
                      saveValueToStore('tipoCadenaPago', v)
                    }
                    getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                    renderOption={(o: any) => renderCatalogueOption(o)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="certificadoPago"
                    label="Certificado de pago (opcional)"
                    value={form?.certificadoPago}
                    onValue={(v: string) =>
                      saveValueToStore('certificadoPago', v)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="cadenaPago"
                    label="Cadena de pago (opcional)"
                    value={form?.cadenaPago}
                    onValue={(v: string) => saveValueToStore('cadenaPago', v)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="selloPago"
                    label="Sello de pago (opcional)"
                    value={form?.selloPago}
                    onValue={(v: string) => saveValueToStore('selloPago', v)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <div id="section-2" className="content">
              <h3 className="title">(2) Documentos relacionados</h3>
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    name="idDocumento"
                    label="ID del documento"
                    value={_.toUpper(invoice?.uuid)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="serie"
                    label="Serie"
                    value={invoice?.serie}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="folio"
                    label="Folio"
                    value={invoice?.folio}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="moneda"
                    label="Moneda"
                    value={formatCatalogueLabel(invoice?.currency)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                {invoice?.currency.value !== 'MXN' && (
                  <Grid item xs={4}>
                    <NumericField
                      name="tipoCambio"
                      label="Tipo de cambio"
                      value={''}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ readOnly: true }}
                      format="money"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    name="metodoPago"
                    label="Metodo de pago"
                    value={`${invoice?.paymentMethod?.value} - ${invoice?.paymentMethod?.label}`}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fieldType="number"
                    name="numeroParcialidad"
                    label="Numero de parcialidad"
                    validate={['isMoreThanZero']}
                    value={form?.numeroParcialidad}
                    onValue={(v: string) =>
                      saveValueToStore('numeroParcialidad', v)
                    }
                    fullWidth
                    required={invoice?.paymentMethod?.value === 'PPD'}
                  />
                </Grid>
              </Grid>
              <p className="subtitle" />
              <Divider className="separator" />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <NumericField
                    name="importeSaldoAnterior"
                    label="Importe saldo anterior"
                    value={form?.importeSaldoAnterior}
                    onValue={(v: string) =>
                      saveValueToStore('importeSaldoAnterior', v)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericField
                    name="importePagado"
                    label="Importe pagado"
                    value={form?.importePagado}
                    onValue={(v: string) =>
                      saveValueToStore('importePagado', v)
                    }
                    disabled={!form?.importeSaldoAnterior}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericField
                    name="importeInsoluto"
                    label="importe insoluto"
                    value={isa.subtract(ip).toString()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    inputProps={{ readOnly: true }}
                    format="money"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <div className="invoice-breakdown">
                <DisplayTotal
                  money={form?.moneda?.value}
                  total={monto.toDecimal()}
                />
              </div>
            </div>
            <Divider className="separator" />
            <div className="actions">
              {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                <Button
                  variant="outlined"
                  color="secondary"
                  activity={loading}
                  disabled={!valid}>
                  Vista previa
                </Button>
              )}
              <Button
                id="addPayComplementBtn"
                variant="contained"
                type="submit"
                color="secondary"
                activity={loading}
                disabled={!valid}>
                Agregar
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  )
}

export default AddPaymentComplementContainer
