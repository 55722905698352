import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { IAccountingProcess } from 'models/accounting.models'
import { IApiError } from 'services/Shared/shared.models'
import { updateRequestedDocumentList } from 'services/Accounting/accounting.services'

interface IuseUpdateAccountingRequestedDocumentsProps {
  workspaceId: number
  currentAccountingProcess: IAccountingProcess | undefined
  list: number[]
  enabled?: boolean
  getAccounting?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IAccountingProcess, IApiError>>
}

const useUpdateAccountingRequestedDocuments = ({
  workspaceId,
  currentAccountingProcess,
  list,
  enabled = true,
  getAccounting = undefined,
}: IuseUpdateAccountingRequestedDocumentsProps): UseQueryResult<
  void,
  IApiError
> => {
  const query = useQuery<void, IApiError>(
    [
      'get-accounting-requested-documents',
      workspaceId,
      currentAccountingProcess,
      list,
      enabled,
    ],
    () => {
      if (!currentAccountingProcess)
        throw new Error('No accounting process found')

      return updateRequestedDocumentList(
        workspaceId,
        currentAccountingProcess.id,
        list
      )
    },
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setTimeout(() => {
          getAccounting && getAccounting()
        }, 600)
      },
    }
  )

  return query
}

export default useUpdateAccountingRequestedDocuments
