import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { GridColDef } from '@material-ui/data-grid'
import EditIcon from '@material-ui/icons/Edit'
import Alert from '@material-ui/lab/Alert'
import Pencil from 'mdi-material-ui/Pencil'
import { OCRBankStatement, OCRBankStatementMovement } from 'models/redux'
import { ERROR_LABELS, ERRORS } from '../../../constants'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import { toCurrency } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { setConciliationActiveStatementRow } from 'actions/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { OCRContext } from 'containers/Conciliations/OCRProcessContainer/OCRProcessContainer'
import OCRStatementsCancelButton from '../OCRStatementsCancelButton/OCRStatementsCancelButton'

const Details: FC<{ statement: OCRBankStatement; discarded: boolean }> = ({
  statement,
  discarded,
}) => {
  const { setDrawerStatement } = useContext(OCRContext)

  const details = useMemo(
    () => [
      {
        label: 'Banco',
        value: statement.bank,
      },
      {
        label: 'Fecha de corte',
        value: statement.cutoffDate,
      },
      {
        label: 'Moneda',
        value: 'Nacional',
      },
      {
        label: 'Cuenta',
        value: statement.account,
      },
      {
        label: 'Periodo',
        value: statement.period,
      },
      {
        label: 'Banca',
        value: 'Red de sucursal',
      },
      {
        label: 'CLABE',
        value: statement.bankCode,
      },
      {
        label: 'No. días en el periodo',
        value: statement.periodDays,
      },
    ],
    [statement]
  )

  const handleOpenDrawerEditStatement = () => {
    setDrawerStatement(statement)
  }

  return (
    <Box className="ocr-statement-details">
      <Grid container>
        <Grid item xs={12} sm={11}>
          <Grid container spacing={2}>
            {_.map(details, (detail, index) => {
              return (
                <Fragment key={index}>
                  <Grid item xs={12} sm={4}>
                    <Box className="label">
                      {detail.label}:&nbsp;{detail.value}
                    </Box>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenDrawerEditStatement}
              disabled={discarded}>
              <Pencil />
              <Box marginLeft={1}>Editar</Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const ContinueButton: FC<{
  statement: OCRBankStatement
  active: number
  setActive: (active: number) => void
}> = ({ statement, active, setActive }) => {
  const { screen, OCR } = useConciliationsContainerContext()

  const { handleSetTab } = screen

  const { OCRStatements } = OCR

  const dispatch = useDispatch()

  const text = useMemo(() => {
    const index = _.findIndex(OCRStatements, {
      idFileBankStatement: statement.idFileBankStatement,
    })
    if (index === OCRStatements.length - 1) return 'Confirmar'
    return 'Continuar'
  }, [statement, OCRStatements])

  const handleNext = () => {
    const index = _.findIndex(OCRStatements, {
      idFileBankStatement: statement.idFileBankStatement,
    })
    if (index === OCRStatements.length - 1) {
      dispatch(setConciliationActiveStatementRow(statement.idFileBankStatement))
      dispatch(handleSetTab(CONCILIATION_TABS.CONCILIATIONS))
    } else {
      setActive(OCRStatements[index + 1].idFileBankStatement)
    }
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      disableElevation
      className="secondary-alt-button"
      onClick={handleNext}>
      {text}
    </Button>
  )
}

const Actions: FC<{
  statement: OCRBankStatement
  active: number
  setActive: (active: number) => void
  discarded: boolean
  index: number
}> = ({ statement, active, setActive, index, discarded }) => {
  const { setDrawerCreateTransaction } = useContext(OCRContext)

  const handleOpenDrawer = () => setDrawerCreateTransaction(statement)

  return (
    <Box my={6}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenDrawer}
            className="outlined-button"
            disabled={discarded}>
            Agregar movimiento
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="flex-end" gridGap={18}>
            <OCRStatementsCancelButton />
            <ContinueButton
              active={active}
              setActive={setActive}
              statement={statement}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const Balance: FC<{ statement: OCRBankStatement }> = ({ statement }) => {
  const { OCR } = useConciliationsContainerContext()

  const { OCRMovements } = OCR

  const balanceWithdrawals = useMemo(() => {
    const transactions = _.filter(OCRMovements, transaction => {
      return (
        transaction.idFilesBankStatement === statement.idFileBankStatement &&
        transaction.withdrawal !== undefined
      )
    })
    return _.sumBy(transactions, transaction => {
      return transaction.withdrawal
    })
  }, [statement, OCRMovements])

  const balanceDeposits = useMemo(() => {
    const transactions = _.filter(OCRMovements, transaction => {
      return (
        transaction.idFilesBankStatement === statement.idFileBankStatement &&
        transaction.deposit !== undefined
      )
    })
    return _.sumBy(transactions, transaction => {
      return transaction.deposit
    })
  }, [statement, OCRMovements])

  const balanceDetails = useMemo(
    () => [
      {
        label: 'Saldo inicial',
        value: statement.initialBalance,
      },
      {
        label: 'Depósitos',
        value: balanceDeposits,
      },
      {
        label: 'Retiros',
        value: balanceWithdrawals,
      },
      {
        label: 'Saldo final',
        value: statement.finalBalance || 0,
      },
    ],
    [balanceWithdrawals, statement, balanceDeposits]
  )

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        borderBottom: '1px solid #E0E0E0',
        paddingBottom: 20,
      }}>
      {_.map(balanceDetails, (detail, index) => {
        return (
          <Fragment key={index}>
            <Grid item xs={12} sm={2}>
              <Box className="label">
                {detail.label}:&nbsp;
                <strong>{toCurrency(Number(detail.value), 'MXN')}</strong>
              </Box>
            </Grid>
          </Fragment>
        )
      })}
    </Grid>
  )
}

const EmptyRows: FC<{ discarded: boolean }> = ({ discarded }) => {
  return (
    <Box
      height={500}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <Typography variant="h6">
        {discarded
          ? 'No hay movimientos debido a que el documento está descartado'
          : 'No se han agregado movimientos'}
      </Typography>
    </Box>
  )
}

const Transactions: FC<{ statement: OCRBankStatement; discarded: boolean }> = ({
  statement,
  discarded,
}) => {
  const { OCR } = useConciliationsContainerContext()

  const { OCRMovements } = OCR

  const { setDrawerTransaction } = useContext(OCRContext)

  const handleSetDrawerTransaction = (
    transaction: OCRBankStatementMovement
  ) => {
    setDrawerTransaction(transaction)
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      editable: false,
      align: 'left',
      width: 220,
      disableColumnMenu: true,
      valueFormatter: params => {
        return Intl.DateTimeFormat('es-MX', { dateStyle: 'short' }).format(
          new Date(params.value as string)
        )
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'concept',
      headerName: 'Concepto',
      editable: false,
      flex: 1,
      sortable: false,
      width: 220,
    },
    {
      field: 'rfcOrdBen',
      headerName: 'RFC ordenante/beneficiario',
      editable: false,
      flex: 1,
      sortable: false,
      width: 220,
    },
    {
      field: 'ordBen',
      headerName: 'Ordenante/beneficiario',
      editable: false,
      flex: 1,
      sortable: false,
      width: 220,
    },
    {
      field: 'originReference',
      headerName: 'Origen/Referencia',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'deposit',
      headerName: 'Depósito',
      editable: false,
      flex: 1,
      width: 220,
      sortable: false,
      valueFormatter: params => {
        return params.value ? toCurrency(Number(params.value), 'MXN') : ''
      },
    },
    {
      field: 'withdrawal',
      headerName: 'Retiro',
      flex: 1,
      valueFormatter: params => {
        return params.value ? toCurrency(Number(params.value), 'MXN') : ''
      },
      width: 220,
      renderCell: params => {
        return (
          <Box display="flex" alignItems="center" width={1}>
            <Box flex={1}>{params.formattedValue}</Box>
            <IconButton
              size="small"
              onClick={() =>
                handleSetDrawerTransaction(
                  params.row as OCRBankStatementMovement
                )
              }>
              <EditIcon />
            </IconButton>
          </Box>
        )
      },
    },
  ]

  const transactions = useMemo(() => {
    return _.map(
      _.filter(OCRMovements, transaction => {
        return (
          transaction.idFilesBankStatement === statement.idFileBankStatement
        )
      }),
      transaction => {
        return {
          ...transaction,
          id: transaction.idTransaction,
        }
      }
    )
  }, [statement, OCRMovements])

  const cellStyles = {
    flex: 1,
    padding: 0,
    paddingBlock: '1rem',
    fontSize: '10pt',
  }

  if (_.isEmpty(transactions)) {
    return <EmptyRows discarded={discarded} />
  }

  return (
    <>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              {_.map(columns, (column, index) => {
                return (
                  <TableCell
                    key={index}
                    style={{
                      ...cellStyles,
                      width: column.width,
                    }}>
                    {column.headerName}
                  </TableCell>
                )
              })}
            </TableHead>
            <TableBody>
              {_.map(transactions, transaction => (
                <TableRow key={transaction.id}>
                  {_.map(columns, (column, index) => {
                    const isWithdraw = column.field === 'withdrawal'
                    return (
                      <TableCell
                        key={index}
                        style={{
                          ...cellStyles,
                          paddingBlock: '.5rem',
                        }}>
                        <Box display="flex" alignItems="center" width={1}>
                          <Box flex={1}>
                            {column.valueFormatter
                              ? String(
                                  column.valueFormatter({
                                    value: transaction[column.field as never],
                                  } as never)
                                )
                              : String(
                                  transaction[column.field as never] || ''
                                )}
                          </Box>
                          {isWithdraw && (
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleSetDrawerTransaction(
                                  transaction as OCRBankStatementMovement
                                )
                              }>
                              <EditIcon />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

const Statement: FC<{
  statement: OCRBankStatement
  active: number
  setActive: (id: number) => void
  index: number
}> = ({ statement, active, setActive, index }) => {
  const { OCR } = useConciliationsContainerContext()

  const { OCRStatements } = OCR

  const dialog = useDialog()

  const errorMessage = (() => {
    if (statement.status >= ERRORS.TRANSACTIONS_ERROR) {
      switch (statement.status) {
        case ERRORS.TRANSACTIONS_ERROR:
          return ERROR_LABELS[ERRORS.TRANSACTIONS_ERROR]
        case ERRORS.EXISTING_DOCUMENT:
          return ERROR_LABELS[ERRORS.EXISTING_DOCUMENT]
        case ERRORS.UNKNOWN_FORMAT:
          return ERROR_LABELS[ERRORS.UNKNOWN_FORMAT]
        case ERRORS.CONNECTION_ERROR:
          return ERROR_LABELS[ERRORS.CONNECTION_ERROR]
        default:
          return null
      }
    } else return null
  })()

  const isDiscarded = useMemo(() => {
    if (errorMessage) {
      dialog.primary({
        title: 'generic.error.title',
        subtitle: errorMessage,
        primaryButtonText: 'Continuar',
      })
      return true
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement /*, dialog, errorMessage*/])

  if (statement.idFileBankStatement !== active) {
    return null
  }
  return (
    <Box marginTop={4}>
      <Box
        display="flex"
        alignItems="center"
        fontSize={18}
        marginBottom={2}
        gridGap={12}>
        <strong>{`${index + 1} de ${OCRStatements.length}`}:&nbsp;</strong>
        <span>{statement.originalName}</span>
        {isDiscarded && (
          <Tooltip title={errorMessage || ''}>
            <Alert severity="warning">Documento descartado</Alert>
          </Tooltip>
        )}
      </Box>
      {!isDiscarded && (
        <Details statement={statement} discarded={isDiscarded} />
      )}
      <Actions
        statement={statement}
        index={index}
        active={active}
        setActive={setActive}
        discarded={isDiscarded}
      />
      {!isDiscarded && <Balance statement={statement} />}
      <Transactions statement={statement} discarded={isDiscarded} />
    </Box>
  )
}

const OCRStatements: FC = () => {
  const { OCR } = useConciliationsContainerContext()

  const { OCRStatements } = OCR

  const [active, setActive] = useState<number>(0)

  useEffect(() => {
    const initial = _.first(OCRStatements)
    if (initial && !active) {
      setActive(initial.idFileBankStatement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OCR /*, active*/])

  return (
    <Fragment>
      {_.map(OCRStatements, (statement, index) => {
        return (
          <Statement
            key={index}
            statement={statement}
            active={active}
            setActive={setActive}
            index={index}
          />
        )
      })}
    </Fragment>
  )
}

export default OCRStatements
