import { FC, Fragment, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import useDialog from 'hooks/Shared/useDialog'
import useGetCFDIs from 'hooks/useGetCFDIs'
import useQueryConciliations from 'hooks/useQueryConciliations'
import { useQueryStatements } from 'hooks/useQueryStatements'
import { deleteConciliatedTransaction } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import './styles.scss'

export const Deconciliate: FC<{
  movementId: number
  onComplete?: () => void
}> = ({ movementId, onComplete = () => {} }) => {
  const { workspaceInfo, screen } = useConciliationsContainerContext()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dialog = useDialog()

  const { refetch: refetchCFDIs } = useGetCFDIs()
  const { refetch: refetchStatements } = useQueryStatements()
  const { refetch: refetchConciliations } = useQueryConciliations()

  const handleOpenDialog = () => {
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handleDeconciliate = async () => {
    setIsOpen(false)
    setIsLoading(true)
    if (!workspaceInfo || !screen) return
    try {
      await deleteConciliatedTransaction(
        workspaceInfo.activeWorkspace,
        movementId
      )
      await dialog.primaryAsync({
        title: 'Desconciliación exitosa',
        subtitle: 'Se ha desconciliado el movimiento.',
        primaryButtonText: 'Continuar',
      })
      onComplete()
      if (screen.activeTab === CONCILIATION_TABS.CONCILIATIONS) {
        await refetchCFDIs()
        await refetchStatements()
        await refetchConciliations()
      }
      setIsLoading(false)
    } catch (error: any) {
      console.log(error)
      setIsLoading(false)
      await dialog.primaryAsync({
        title: 'generic.error.title',
        subtitle: 'No se ha podido desconciliar el movimiento.',
        primaryButtonText: 'Continuar',
      })
    }
  }

  return (
    <Fragment>
      <Button
        variant="text"
        onClick={handleOpenDialog}
        style={{
          padding: 0,
          color: red[500],
          textDecoration: 'underline',
          fontSize: 'small',
        }}>
        {isLoading && (
          <CircularProgress
            size={12}
            style={{ marginRight: 8, color: '#f00' }}
          />
        )}
        Desconciliar
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        className="decon-dialog">
        <DialogTitle className="decon-dialog-title">
          <Typography className="decon-dialog-title-content">
            Desconciliar movimiento
          </Typography>
        </DialogTitle>
        <DialogContent className="decon-dialog-content">
          <DialogContentText className="decon-dialog-content-text">
            Al deshacer una conciliación, los movimientos bancarios y CFDIs
            conciliados volverán a la lista “Por conciliar”.
          </DialogContentText>
          <DialogContentText className="decon-dialog-content-text">
            ¿Deseas continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="decon-dialog-actions">
          <Button
            onClick={handleCloseDialog}
            className="decon-dialog-action-button secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleDeconciliate}
            className="decon-dialog-action-button primary">
            Si, desconciliar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default Deconciliate
