import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";
import Profiling, { Answer } from "models/profiling";

const route = process.env.REACT_APP_APIURL || "/api/";

export const getFormsByWorkspaceId = async (
  workspaceId: number,
): Promise<Profiling[]> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/forms`
    );
    const profiling: Profiling[] = await petition.json();

    return profiling;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const getAnswersByWorkspaceId = async (
  workspaceId: number,
): Promise<Answer[]> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.get(
      `${route}/workspaces/${workspaceId}/forms/answers`
    );
    // console.log(petition)
    const answers: Answer[] = await petition.json();
    return answers;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}

export const saveAnswers = async (
  workspaceId: number,
  answers: Answer[],
): Promise<string> => {
  const client = HTTPClient.getClient();
  try {
    const petition = await client.post(
      `${route}/workspaces/${workspaceId}/forms/answers`, 
      answers
    );
    const data = await petition;
    return data;
  } catch (err: any) {
    const error = await httpErrorParser(err);
    throw error;
  }
}