import React from 'react'
import { useIntl } from 'react-intl'

import IOForm, { ValidatorContext, IOFormProps } from 'react-io-forms'

import { validations, getValidationMessages } from 'lib/helpers/ioValidations'

interface CustomProps {
  onForm?: (form: HTMLFormElement) => void
}

const IOCustomForm: React.FC<IOFormProps<any> & CustomProps> = props => {
  const { onSubmit, onValidity, children, onForm, ...rest } = props

  const intl = useIntl()
  const validationMessages = getValidationMessages(intl.locale)

  return (
    <ValidatorContext
      validations={validations}
      messages={validationMessages}
      defaultMessage={validationMessages.default}
      requiredMessage={validationMessages.isRequired}>
      <IOForm
        onSubmit={onSubmit}
        onValidity={onValidity}
        innerProps={{ ref: onForm ? onForm : undefined }}
        {...rest}>
        {children}
      </IOForm>
    </ValidatorContext>
  )
}

export default IOCustomForm
