import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNotificationList } from './queries/account'

import { setCreateFlag } from 'actions/workspaces'

import {
  selectActiveWorkspace,
  selectCreateWorkspace,
} from 'lib/helpers/selectors'

import { ROUTES } from '../constants'

// const disabledOnRoutes = (path?: string | readonly string[]): boolean => {
//     let flag = false;
//     if (path === ROUTES.HOME)
//         flag = true;

//     return flag;
// }

const usePathListener = (
  path?: string | readonly string[],
  disableFetch: boolean = false
) => {
  const dispatch = useDispatch()
  const { createFlag } = useSelector(selectCreateWorkspace)
  const workspaceId = useSelector(selectActiveWorkspace)

  // useProfile({
  //     enabled: !(disableFetch || disabledOnRoutes(path)),
  //     retry: 0,
  //     noSave: true,
  //     noDialog: true
  // });

  useNotificationList(workspaceId, {
    refreshOn: [workspaceId],
    enabled: !disableFetch && Boolean(workspaceId),
    retry: 0,
    noDialog: true,
  })

  useEffect(() => {
    if (!createFlag && path === ROUTES.ADD_WORKSPACE)
      dispatch(setCreateFlag(true))
    else if (createFlag && path !== ROUTES.ADD_WORKSPACE)
      dispatch(setCreateFlag(false))
  }, [path, createFlag, dispatch])
}

export default usePathListener
