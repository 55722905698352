import { createContext, useContext, useMemo } from 'react'
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined'
import { TAccountingProcessSteps } from 'models/accounting.models'
import CheckSmall from 'assets/check-small.svg'
import InfoRed from 'assets/info-red.svg'
import FileWarningIcon from 'assets/icons/FileWarningIcon'
import {
  accountingComponentsMap,
  accountingSteps,
  getMappedSteps,
} from 'lib/utils/Accounting/accounting.utils'
import useAccounting, {
  IUseAccounting,
} from 'containers/Accounting/AccountingContainer/useAccounting'
import Button from 'components/Shared/Button/Button'
import Stepper from 'components/Shared/Stepper/Stepper'
import AccountingIncompleteProcessesDrawer from 'components/Accounting/AccountingIncompleteProcessesDrawer/AccountingIncompleteProcessesDrawer'
import AccountingTasksDrawer from 'components/Accounting/AccountingTasksDrawer/AccountingTasksDrawer'
import DateRangeSelector from 'components/FormComponents/DateRangeSelector/DateRangeSelector'
import './styles.scss'

export const AccountingContext = createContext<IUseAccounting | null>(null)

const AccountingContainer = () => {
  const accountingState = useAccounting()

  const {
    loader,
    activeDateRange,
    activeStep,
    incompleteProcesses,
    isAllAccountingTasksComplete,
    currentAccountingProcess,
    handleChangeDateRange,
    handleToggleAccountingTasksDrawer,
    handleToggleAccountingIncompleteProcessesDrawer,
  } = accountingState

  const contextValue = useMemo(() => accountingState, [accountingState])

  const ActiveStepComponent =
    accountingComponentsMap[activeStep as TAccountingProcessSteps]

  if (loader.open) return <></>

  return (
    <div className="accounting-container">
      <AccountingContext.Provider value={contextValue}>
        <Stepper
          steps={getMappedSteps(
            accountingSteps,
            activeStep as TAccountingProcessSteps
          )}
        />
        <div className="accounting-container-title">
          {currentAccountingProcess ? (
            <DateRangeSelector
              defaultDate={new Date(activeDateRange)}
              onChange={handleChangeDateRange}
            />
          ) : (
            <h1 className="process-date">Sin fecha</h1>
          )}

          <div className="accounting-container-top-actions">
            {incompleteProcesses && (
              <Button
                className="accounting-tasks-button"
                color="primary"
                startIcon={<FileWarningIcon />}
                onClick={handleToggleAccountingIncompleteProcessesDrawer}>
                Ver cierres incompletos
              </Button>
            )}
            <Button
              className="accounting-tasks-button"
              color="primary"
              startIcon={<BookmarkAddedOutlinedIcon />}
              endIcon={
                isAllAccountingTasksComplete ? (
                  <img src={CheckSmall} alt="todas las tareas se completaron" />
                ) : (
                  <img src={InfoRed} alt="hay tareas incompletas" />
                )
              }
              onClick={handleToggleAccountingTasksDrawer}>
              Ver tareas
            </Button>
          </div>
        </div>
        <ActiveStepComponent />
        <AccountingTasksDrawer />
        <AccountingIncompleteProcessesDrawer />
      </AccountingContext.Provider>
    </div>
  )
}

export const useAccountingContext = () => {
  const context = useContext(AccountingContext)

  if (!context) {
    throw new Error(
      'useAccountingContext must be used within a AccountingContext'
    )
  }

  return context
}

export default AccountingContainer
