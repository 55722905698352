import HTTPClient, { httpErrorParser } from 'lib/helpers/HTTPClient'
import { parseNotification } from 'lib/helpers/utilities'
import _ from 'lodash'

import { InformationForm, PasswordForm } from 'models/account'
import { Notification } from 'models/general'
import { Profile } from 'models/user'

const route = process.env.REACT_APP_APIURL || '/api'

// onSuccess
// dispatch(setUserRole(ROLES.ADMINISTRADOR));
// dispatch(setUserData(user));
// dispatch(setAuthorization(true));
// await dispatch(getWorkspaceList());
export const getProfile = async (): Promise<Profile> => {
  const client = HTTPClient.getClient()
  try {
    const petition = await client.get(`${route}/profile`)
    const profile: Profile = await petition.json()
    return profile
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const getNotifications = async (
  workspaceId: number
): Promise<Notification[]> => {
  const client = HTTPClient.getClient()
  try {
    let petition = await client.get(
      `${route}/workspaces/${workspaceId}/notices`
    )
    let data = await petition.json()
    const notices = _.map(data, (d: any, i: number) => parseNotification(d, i))

    // petition = await client.get(`${route}/profile`);
    // data = petition.json();
    // const invitations = _.map(data, (d: any, i: number) => parseNotification(d, i, "invitation"));
    const invitations: Notification[] = []

    return [...notices, ...invitations]
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const changeInformation = async (
  form: InformationForm
): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const body = {
      name: form.name,
      lastname: form.lastname,
      personsList: [
        {
          address: 'usa',
          birthday: '2021-04-25',
          curp: 'aqui va la curp',
          phone: '123',
          rfc: 'aqui rfc',
        },
      ],
      email: form.email,
      emailCobza1: form.emailCobza1,
      emailCobza2: form.emailCobza2,
    }
    const petition = await client.put(`${route}/profile`, body)
    await petition.json()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}

export const changePassword = async (form: PasswordForm): Promise<void> => {
  const client = HTTPClient.getClient()
  try {
    const body = {
      password: form.oldPassword,
      recoverPassword: form.newPassword,
    }
    const petition = await client.put(`${route}/profile/newPassword`, body)
    await petition.text()
  } catch (err: any) {
    const error = await httpErrorParser(err)
    throw error
  }
}
