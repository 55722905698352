import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Box, Button, Grid, LinearProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { CatalogueEntry } from 'models/catalogues'
import {
  selectCatalogue,
  selectProfile,
  selectSelectedEmisor,
} from 'lib/helpers/selectors'
import { getYearList } from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import useGetCFDIs from 'hooks/useGetCFDIs'
import useEmisoresList from 'hooks/queries/emisores'
import { setSelectedEmisor } from 'actions/invoices'
import { syncWithSAT } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import SydDialog from 'components/Shared/SydDialog/SydDialog'

interface SyncFields {
  year: CatalogueEntry
  month: CatalogueEntry
  type: CatalogueEntry
}

const YearField = () => {
  const profile = useSelector(selectProfile)
  const years = getYearList(profile?.createdAt)
  const { register, setValue } = useFormContext()
  return (
    <Grid item xs={6}>
      <input type="hidden" {...register('year')} />
      <Autocomplete
        options={years}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        onChange={(event: any, value: CatalogueEntry | null) =>
          setValue('year', value)
        }
        renderInput={params => (
          <TextField {...params} label="Año" variant="standard" fullWidth />
        )}
        fullWidth
      />
    </Grid>
  )
}

const MonthField = () => {
  const months = useSelector(selectCatalogue('meses'))
  const { register, setValue } = useFormContext()
  return (
    <Grid item xs={6}>
      <input type="hidden" {...register('month')} />
      <Autocomplete
        options={_.slice(months, 0, 12)}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        onChange={(event: any, value: CatalogueEntry | null) =>
          setValue('month', value)
        }
        renderInput={params => (
          <TextField {...params} label="Mes" variant="standard" fullWidth />
        )}
        fullWidth
      />
    </Grid>
  )
}

const TypeField = () => {
  const tipoComprobantes = useSelector(selectCatalogue('tiposComprobantes'))
  const comprobantes = useMemo(
    () =>
      _.map(
        _.filter(tipoComprobantes, c => ['I', 'E'].indexOf(c.value) > -1),
        c => ({
          ...c,
          value: c.value,
          label: `${c.value} - ${c.label}`,
        })
      ),
    [tipoComprobantes]
  )
  const { register, setValue } = useFormContext()
  return (
    <Grid item xs={12}>
      <input type="hidden" {...register('type')} />
      <Autocomplete
        options={comprobantes}
        getOptionLabel={(o: CatalogueEntry) => o.label}
        onChange={(event: any, value: CatalogueEntry | null) =>
          setValue('type', value)
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Ingreso/Egreso"
            variant="standard"
            fullWidth
          />
        )}
      />
    </Grid>
  )
}

const SyncForm = () => {
  const { workspaceInfo, isSyncCFDIOpen, updateIsSyncCFDIOpen } =
    useConciliationsContainerContext()

  const dispatch = useDispatch()
  const dialog = useDialog()
  const { refetch: refetchCFDIs } = useGetCFDIs()

  const emisor = useSelector(selectSelectedEmisor)

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<SyncFields>()

  const emisoresQuery = useEmisoresList(workspaceInfo.activeWorkspace, {
    noSave: true,
    onSuccess: rfcs => {
      rfcs.length > 0
        ? dispatch(setSelectedEmisor(_.first(rfcs)))
        : dispatch(setSelectedEmisor(undefined))
    },
  })

  const onSubmit = async (data: SyncFields) => {
    setIsLoading(true)
    try {
      if (!emisor) throw new Error('emitter')
      const body = {
        idRfc: emisor.idRfc,
        rfc: emisor.rfc,
        year: data.year.value,
        startMonth: data.month.value,
        endMonth: data.month.value,
      }
      await syncWithSAT(workspaceInfo.activeWorkspace, body)
      setIsLoading(false)
      await dialog.primaryAsync({
        title: 'Solicitud aceptada',
        subtitle: "Se ha iniciado la sincronización de CFDI's.",
        primaryButtonText: 'Continuar',
      })
      await refetchCFDIs()
      updateIsSyncCFDIOpen(false)
    } catch (error: any) {
      setIsLoading(false)
      if (error === 'emitter') {
        const retryEmitters = await dialog.primaryAsync({
          title: 'generic.error.title',
          subtitle: 'No se ha podido obtener el RFC del emisor.',
          primaryButtonText: 'Reintentar',
          secondaryButtonText: 'Cancelar',
        })
        if (retryEmitters) {
          emisoresQuery.refetch()
          onSubmit(data)
        } else {
          updateIsSyncCFDIOpen(false)
        }
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: "No se ha podido iniciar la sincronización de CFDI's.",
        primaryButtonText: 'Continuar',
      })
      updateIsSyncCFDIOpen(false)
    }
  }

  const month = methods.watch('month')
  const year = methods.watch('year')
  // const type = methods.watch("type");

  const isButtonDisabled = useMemo(() => {
    return !month || !year || isLoading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState /*, isLoading, month, year*/])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SydDialog
          open={isSyncCFDIOpen}
          title="Sincronizar con el SAT"
          subtitle="Para realizar la sincronización de CFDI's puedes configurar los siguientes datos:"
          hidePrimaryButton>
          {(isLoading || emisoresQuery.isLoading) && (
            <LinearProgress variant="indeterminate" />
          )}
          <Grid container spacing={4}>
            <YearField />
            <MonthField />
            <TypeField />
            <Grid item xs={12}>
              <Box display="flex" justifyContent="end" gridGap={10}>
                <Button
                  onClick={() => updateIsSyncCFDIOpen(false)}
                  variant="contained"
                  disableElevation
                  disabled={isLoading}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                  onClick={() => methods.handleSubmit(onSubmit)()}
                  disabled={isButtonDisabled}>
                  Sincronizar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </SydDialog>
      </form>
    </FormProvider>
  )
}

export default SyncForm
