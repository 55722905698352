import { FC, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  CircularProgress,
  Theme,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { ParsedArchive } from 'models/general'
import { ConciliationFile } from 'models/conciliations'
import {
  CONCILIATION_ACCEPTED_FORMATS,
  CONCILIATION_CFDI_ACCEPTED_FORMATS,
  CONCILIATION_LEDGER_ACCEPTED_FORMATS,
  CONCILIATION_MAX_FILESIZE,
  OCR_DRAWER_MODES,
} from '../../../constants'
import {
  parseFileList,
  parseUploadedFiles,
  toPeriodString,
} from 'lib/helpers/utilities'
import useDialog from 'hooks/Shared/useDialog'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { replaceCFDIFile, replaceLedgerArchive } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import { verifyFiles } from 'components/Conciliations/Dropzone/Dropzone'
import './styles.scss'

const useWarningTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.warning.light,
  },
  tooltip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    fontSize: 'medium',
  },
}))

const FileListDrawerReplaceButton: FC<{
  document: ConciliationFile
  setCurrentDocument: (document: ConciliationFile) => void
}> = ({ document, setCurrentDocument }) => {
  const [error, setError] = useState({
    open: false,
    message: '',
  })
  const {
    globalMethods: { replaceArchive },
    workspaceInfo,
  } = useConciliationsContainerContext()
  const {
    conciliationPeriod,
    drawer,
    removeDrawerFilelistItem,
    setDrawerFilelist,
  } = useConciliationsContainerContext()

  const [isLoading, setIsLoading] = useState(false)
  const classes = useWarningTooltip()
  const inputRef = useRef<HTMLInputElement>(null)
  const dialog = useDialog()

  const options = useMemo(
    () => ({
      accept: (() => {
        if (
          drawer.filelist &&
          drawer.filelist.ocrMode === OCR_DRAWER_MODES.ledger
        )
          return CONCILIATION_LEDGER_ACCEPTED_FORMATS
        if (
          drawer.filelist &&
          drawer.filelist.ocrMode === OCR_DRAWER_MODES.CFDIs
        )
          return CONCILIATION_CFDI_ACCEPTED_FORMATS
        return CONCILIATION_ACCEPTED_FORMATS
      })(),
      maxFiles: 1,
      multiple: false,
      maxSize: CONCILIATION_MAX_FILESIZE,
    }),
    [drawer]
  )

  const clearError = () =>
    setError({
      open: false,
      message: '',
    })

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.files = null
    }
  }

  const handleReplace = async (file: ParsedArchive) => {
    setIsLoading(true)
    try {
      if (!drawer.filelist)
        throw new Error('No se ha encontrado la lista de archivos')
      const { ocrMode } = drawer.filelist
      let data
      if (ocrMode === OCR_DRAWER_MODES.statements) {
        return await replaceArchive(
          workspaceInfo.activeWorkspace,
          toPeriodString(conciliationPeriod),
          document.idWsFile,
          file
        )
      }
      if (ocrMode === OCR_DRAWER_MODES.ledger) {
        return await replaceLedgerArchive(
          workspaceInfo.activeWorkspace,
          document.idWsFile,
          file
        )
      }
      if (ocrMode === OCR_DRAWER_MODES.CFDIs) {
        return await replaceCFDIFile(
          workspaceInfo.activeWorkspace,
          document.idWsFile,
          file,
          toPeriodString(conciliationPeriod)
        )
      }
      return data
    } catch (error: any) {
      const op = selectErrorDialog(error)
      if (op?.variant) {
        await dialog[op.variant](op)
        return
      }
      await dialog.dangerAsync({
        title: 'generic.error.title',
        subtitle: 'No se pudo cargar el archivo. Inténtalo mas tarde.',
        primaryButtonText: 'Continuar',
      })
    } finally {
      setIsLoading(false)
      clearInput()
    }
  }

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      const verify = verifyFiles(files, options)
      if (verify) {
        setError({ open: true, message: verify })
        clearInput()
        return setTimeout(() => clearError(), 3000)
      }
      const documents = await parseFileList(files)
      const replaced = await handleReplace(documents[0])
      if (replaced && drawer.filelist) {
        const doc = _.first(replaced.documents)
        if (!doc) throw new Error('No se ha encontrado el documento')
        removeDrawerFilelistItem(document.idWsFile)
        setDrawerFilelist({
          ...drawer.filelist,
          documents: _.merge(
            drawer.filelist.documents,
            parseUploadedFiles(replaced.documents)
          ),
        })
        setCurrentDocument({
          id: doc.idWsfile,
          original_name: doc.originalName,
          file: doc.file,
          idWsFile: doc.idWsfile,
        })
        clearInput()
      }
    } else {
      setError({
        open: true,
        message: 'No se ha seleccionado ningun archivo',
      })
      clearInput()
      setTimeout(() => clearError(), 3000)
    }
  }

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleTooltipClose = () => {
    setError({
      open: false,
      message: '',
    })
  }

  const acceptedFiles = (() => {
    if (!drawer.filelist) return ''
    const { ocrMode } = drawer.filelist
    if (ocrMode === OCR_DRAWER_MODES.statements)
      return CONCILIATION_ACCEPTED_FORMATS
    if (ocrMode === OCR_DRAWER_MODES.CFDIs)
      return CONCILIATION_CFDI_ACCEPTED_FORMATS
    if (ocrMode === OCR_DRAWER_MODES.ledger)
      return CONCILIATION_LEDGER_ACCEPTED_FORMATS
    return ''
  })()

  return (
    <Box className="replace-button-component">
      <input
        type="file"
        ref={inputRef}
        accept={acceptedFiles}
        onChange={onChange}
      />
      {isLoading && (
        <Box className="loading-overlay">
          <CircularProgress variant="indeterminate" size={34} />
        </Box>
      )}
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        classes={classes}
        onClose={handleTooltipClose}
        open={error.open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={error.message}
        arrow
        placement="bottom-end">
        <Button
          className={`replace-button ${error.open && 'error'}`}
          onClick={onClick}
          disabled={isLoading}>
          Reemplazar
        </Button>
      </Tooltip>
    </Box>
  )
}

export default FileListDrawerReplaceButton
