import React from 'react'

import './style.scss'

interface FooterProps {}

const Footer: React.FC<FooterProps> = props => {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer-component">
      <span className="footer-text">
        Simmple® {currentYear} | Todos los derechos reservados
      </span>
    </div>
  )
}

export default Footer
