import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Button from 'components/Shared/Button/Button';

import Edit from '@material-ui/icons/Edit';
import Delete from 'mdi-material-ui/AccountMinus';
import Star from '@material-ui/icons/Star';
import Simmple from 'assets/favicon.png';
import './style.scss';

interface ColaboratorCardProps {
  id: number
  fullname: string
  email: string
  role: string
  isEmployee?: boolean
  rate?: number
  onEdit?: (id: number) => void
  onDelete?: (id: number) => void
}

const CollaboratorCard: React.FC<ColaboratorCardProps> = (props) => {
  const intl = useIntl();

  const onClickOption = (menu: "edit" | "delete") => {
    if (menu === "edit") props.onEdit && props.onEdit(props.id);
    if (menu === "delete") props.onDelete && props.onDelete(props.id);
  }

  return (
    <>
      <Card variant="outlined" className={`component-colaborator-card`}>
        <div className="content">
          <div className="user">
            {props.isEmployee && <img className="user-logo" src={Simmple} alt="Simmple" />}
            <p className="name">{props.fullname || "- - -"}</p>
          </div>
          <div className="additional-info">
            <p className="role">{props.role}</p>
            <Divider orientation="vertical" flexItem />
            <p className="email" title={props.email}>{props.email}</p>
            {props.isEmployee && <>
              <Divider orientation="vertical" flexItem />
              <Star className="rate-logo" />
              <p className="rate">{props.rate || "- - -"}</p>
            </>}
          </div>
        </div>
        <Divider />
        <Grid container className="colaborator-actions">
          <Grid item xs={6}>
            <Button
              variant="hover"
              startIcon={<Edit />}
              color="secondary"
              size="large"
              onClick={() => onClickOption("edit")}
              disabled={!props.onEdit}
              fullWidth
            >
              {intl.formatMessage({ id: `button.edit` })}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="hover"
              startIcon={<Delete />}
              color="danger"
              size="large"
              onClick={() => onClickOption("delete")}
              disabled={!props.onDelete}
              fullWidth
            >
              {intl.formatMessage({ id: `button.delete` })}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default CollaboratorCard;
