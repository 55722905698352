import React from 'react';
import { useIntl } from 'react-intl';

import { BarDatum, ResponsiveBar } from '@nivo/bar';

import { Palettes, Variables } from "theme/export";
import "./style.scss";
import _ from 'lodash';

const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]

interface IncomesExpensesGraphicI {
    data: BarDatum[];
    period: string;
}

interface ToolTipType {
    id: any;
    value: any;
    color: any;
}

const IncomesExpensesGraph: React.FC<IncomesExpensesGraphicI> = ({ period, data }) => {
    const intl = useIntl();

    const formatMoney = (money: number, integerOnly = false) => {
        const formatted = intl.formatNumber(money, {
            style: "currency",
            currency: "MXN",
            currencyDisplay: "narrowSymbol",
            maximumSignificantDigits: integerOnly ? 1 : undefined
        });
        return formatted;
    }

    const keys = ["income", "expense"];
    const colors = [Palettes.Main.secondary, Palettes.Complementary.color9];

    return (
        <div className="component-income-expenses-graph">
            {/* {data && data.length === 0 ? */}
            { _.isEmpty(data) ?
                <span className="no-data">
                    No existe información para los filtros seleccionados
                </span>
                :
                <ResponsiveBar
                    data={data}
                    keys={keys}
                    enableLabel={false}
                    // label={d => `${d.id}: ${d.value}`}
                    groupMode="grouped"
                    indexBy="period"
                    margin={{ top: 30, right: 20, bottom: 30, left: 90 }}
                    padding={0.85}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={colors}
                    borderRadius={6}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: v => period === "mensual" ? months[v - 1] : v
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: v => formatMoney(v, true)
                    }}
                    tooltip={({ id, value, color }: ToolTipType) => (
                        <div
                            style={{
                                padding: 5,
                                color: Palettes.Mono.white,
                                background: color,
                                fontFamily: Variables.fontFamily,
                                fontSize: 14,
                                borderRadius: 7,
                            }}
                        >
                            <span>
                                {formatMoney(value)}
                            </span>
                        </div>
                    )}
                />
            }
        </div>
    );
};

export default IncomesExpensesGraph;