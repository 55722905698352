import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import useDialog from 'hooks/Shared/useDialog'
import _ from 'lodash'

import { useUploadBillingFile } from 'hooks/queries/workspaces'

import Grid from '@material-ui/core/Grid'
import SydDialog from 'components/Shared/SydDialog/SydDialog'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'

import { TaskAlt } from 'components/Shared/CustomIcons'
import FileField from 'components/FormComponents/IOComponents/FileField/FileField'

interface Props {
  open: boolean
  workspaceId: number
  onCancel: () => void
}

const BillingFileUploaderContainer: React.FC<Props> = ({
  open,
  workspaceId,
  onCancel,
}) => {
  const intl = useIntl()
  const dialog = useDialog()

  const uploadMutation = useUploadBillingFile(workspaceId, {
    onSuccess: () => {
      onCancel()
      dialog.primary({
        title: '¡Listo!',
        subtitle: 'El archivo fue subido exitosamente.',
        icon: TaskAlt,
        hidePrimaryButton: true,
        secondaryButtonText: 'Cerrar',
      })
    },
  })

  const [validDialog, setValidDialog] = useState(false)

  return (
    <SydDialog
      open={open}
      className="csv-uploader-dialog"
      title="Subir Archivo de Suscripciones"
      subtitle="Actualiza o agrega registros de pago a las suscripciones de manera masiva a traves de un archivo de excel (.xlsx)"
      primaryButtonText="Subir"
      loadingPrimaryButton={uploadMutation.isLoading && !uploadMutation.isError}
      disablePrimaryButton={!validDialog}
      secondaryButtonText="Cancelar"
      disableSecondaryButton={
        uploadMutation.isLoading && !uploadMutation.isError
      }
      onCancel={() => {
        onCancel()
        setValidDialog(false)
      }}
      form="uploadForm"
      sideButtons
      fullWidth
      >
      <Form
        id="uploadForm"
        onSubmit={async (form: { file: any }) =>
          uploadMutation.mutate(_.split(form.file.base64, ',')[1])
        }
        onValidity={(v: boolean) => setValidDialog(v)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FileField
              name="file"
              label={intl.formatMessage(
                { id: 'form.file.label' },
                { extension: '.XLSX', more: '' }
              )}
              accept=".xlsx"
              required
            />
          </Grid>
        </Grid>
      </Form>
    </SydDialog>
  )
}

export default BillingFileUploaderContainer
