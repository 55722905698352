import { Fragment, useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { toCurrency } from 'lib/helpers/utilities'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import DetailsItem from '../DetailsItem/DetailsItem'

const AccountMovementsDrawerContent = () => {
  const { drawer } = useConciliationsContainerContext()

  const details = useMemo(() => {
    if (!drawer.movement) return null
    return [
      {
        label: 'Fecha de movimiento',
        value: Intl.DateTimeFormat('es-MX', { dateStyle: 'short' }).format(
          new Date(drawer.movement.created_at.replaceAll('-', '/'))
        ),
      },
      {
        label: 'Concepto de movimiento',
        value: drawer.movement.concept,
      },
      {
        label: 'Origen/referencia bancaria',
        value: drawer.movement.origin,
      },
      {
        label: 'Ordenante/Beneficiario',
        value: drawer.movement.name,
      },
      {
        label: 'RFC del ordenante o beneficiario',
        value: drawer.movement.rfc,
      },
      {
        label: 'Monto del pago',
        value: toCurrency(drawer.movement.amount, 'MXN'),
      },
      {
        label: 'Tipo de operación',
        value: drawer.movement.out ? 'Retiro' : 'Depósito',
      },
    ]
  }, [drawer.movement])

  if (!details) {
    return <Typography>No se ha podido encontrar el movimiento</Typography>
  }

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gridGap={22}>
        {details.map((detail, index) => (
          <DetailsItem key={index} label={detail.label} value={detail.value} />
        ))}
      </Box>
    </Fragment>
  )
}

export default AccountMovementsDrawerContent
