import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import usePathListener from 'hooks/usePathListener'
import { selectAuthorized } from 'lib/helpers/selectors'

import { ROUTES } from '../constants'

interface Props {
  inverse?: boolean
}

export const PrivateHub: React.FC<Props> = ({
  inverse = false,
  children,
  ...props
}) => {
  const { pathname } = useLocation()

  const authorized = useSelector(selectAuthorized)

  usePathListener(pathname, !authorized)

  const showComponent = (authorized && !inverse) || (!authorized && inverse)
  const redirect = inverse ? ROUTES.HOME : ROUTES.LOGIN

  return (
    <>
      {!showComponent && <Navigate to={redirect} replace />}
      {showComponent && children}
    </>
  )
}

export default PrivateHub
