import _ from 'lodash';
import JSZip from 'jszip';
import { Currencies, Money } from 'ts-money';

import HTTPClient, { httpErrorParser } from "lib/helpers/HTTPClient";

import { getDateInISO } from 'lib/helpers/utilities';
import { STATUS_PAYMENT_CATALOGUE } from '../constants';

import Invoice, { CreditNote, CreditNoteFiles, InvoiceFiles, PaymentComplement, PaymentComplementFiles } from 'models/invoice';
import RazonSocial from 'models/razonSocial';
import { CatalogueEntry } from 'models/catalogues';
import { List } from 'models/general';

const route = process.env.REACT_APP_APIURL || "/api/";

export const getCfdiList = async (
    workspaceId: number, 
    page: number, 
    size: number, 
    rfc?: string, 
    search?: string, 
    advance?: boolean, 
    type?: string, 
    status?: number, 
    startDate?: string, 
    endDate?: string, 
    receptor?: string
): Promise<List<Invoice[]>> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${route}/workspaces/${workspaceId}/cfdis?page=${page + 1}&size=${size}&`;
        if (rfc) url = `${url}rfc=${rfc}&`;
        if (search) url = `${url}search=${search}&`;
        if (advance) url = `${url}advanced_search=true&`;
        if (type) url = `${url}type=${type}&`;
        if (status) url = `${url}status=${status}&`;
        if (startDate) url = `${url}fecha_inicio=${startDate}&`;
        if (endDate) url = `${url}fecha_final=${endDate}&`;
        if (receptor) url = `${url}receptor=${receptor}&`;
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);

        const data = await petition.json();
        const cfdis = data.cfdis;

        const parsedList: Invoice[] = _.map(cfdis, (d): Invoice => {
            const subtotal = Money.fromDecimal(Number(d["cfdiTaxes"]["subtotal"]), Currencies.MXN);
            const total = Money.fromDecimal(Number(d["cfdiTaxes"]["total"]), Currencies.MXN);
            return ({
                ...d["cfdiInformation"],
                createdAt: d["cfdiCreated"],
                hour: d["cfdiCreated"],
                statusPayment: _.find(STATUS_PAYMENT_CATALOGUE, (c) => c.value === d["cfdiInformation"]["statusPayment"]),
                emisor: d["cdfiEmisor"],
                subtotal: subtotal.toDecimal(),
                taxes: total.subtract(subtotal).toDecimal(),
                total: total.toDecimal(),
                imported: d["cfdiInformation"]["imported"] || false
            });
        });
        const list = _.orderBy(parsedList, ["createdAt"], ['desc']);

        return {
            list,
            page: data.pageNumber,
            totalPages: data.pages,
            total: data.totalElements
        };
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCfdiListFilesInZip = async (
    workspaceId: number, 
    page: number, 
    size: number, 
    rfc?: string, 
    search?: string, 
    advance?: boolean, 
    type?: string, 
    status?: number, 
    startDate?: string, 
    endDate?: string, 
    receptor?: string
): Promise<string> => {
    const client = HTTPClient.getClient();
    try {
        let url = `${route}/workspaces/${workspaceId}/cfdis/download/zip?page=${page + 1}&size=${size}&`;
        if (rfc) url = `${url}rfc=${rfc}&`;
        if (search) url = `${url}search=${search}&`;
        if (advance) url = `${url}advanced_search=true&`;
        if (type) url = `${url}type=${type}&`;
        if (status) url = `${url}status=${status}&`;
        if (startDate) url = `${url}fecha_inicio=${startDate}&`;
        if (endDate) url = `${url}fecha_final=${endDate}&`;
        if (receptor) url = `${url}receptor=${receptor}&`;
        url = url.substring(0, url.length - 1);

        const petition = await client.get(url);
        const data: string = await petition.text();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCfdiById = async (workspaceId: number, invoiceId: number): Promise<Invoice> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}`);
        const data = await petition.json();
        const subtotal = Money.fromDecimal(Number(data["cfdiTaxes"]["subtotal"]), Currencies.MXN);
        const total = Money.fromDecimal(Number(data["cfdiTaxes"]["total"]), Currencies.MXN);
    
        const invoice: Invoice = {
            ...data["cfdiInformation"],
            conceptos: data['cfdiConcepts'],
            emisor: data["cfdiEmisor"],
            subtotal: subtotal.toDecimal(),
            taxes: total.subtract(subtotal).toDecimal(),
            total: total.toDecimal(),
            statusPayment: _.find(STATUS_PAYMENT_CATALOGUE, (c) => c.value === data["statusPayment"]),
            imported: data["imported"] || false
        }

        return invoice;
    } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
    }
}
export const getCfdiFiles = async (workspaceId: number, invoice: Invoice, includeInvoice?: boolean): Promise<InvoiceFiles> => {
    const client = HTTPClient.getClient();
    try {
        let pdfBase64 = "";
        let xmlBase64 = "";
  
        if (!invoice.imported) {
            const petitions = await Promise.all([
                client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/download/pdf`),
                client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/download/xmlCfdi`)
            ]);
            const dataPdf = await petitions[0].text();
            const dataXml = await petitions[1].text();
    
            pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
            xmlBase64 = `data:application/xml;base64,${dataXml}`;
        } else {
            const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdiImported/${invoice.uuid}/download`);
            const data = await petition.json();
    
            pdfBase64 = `data:application/pdf;base64,${data.pdf}`;
            xmlBase64 = `data:application/xml;base64,${data.xml}`;
        }
  
        const data = { pdf: pdfBase64, xml: xmlBase64 };
  
        if (includeInvoice) return ({ ...data, invoice });
        return data;
    } catch (err: any) {
      const error = await httpErrorParser(err);
      throw error;
    }
}
export const getCfdisFiles = async (workspaceId: number, invoices: (number|string)[], list: Invoice[]): Promise<string> => {
    const invoiceList = _.filter(list, (invoice) => invoices.indexOf(invoice.uuid) > -1);
    const files = await Promise.all(_.map(invoiceList, (invoice) => getCfdiFiles(workspaceId, invoice, true)));
    const zip = new JSZip();
    _.forEach(files, (file) => {
        const uuid = _.get(file, "invoice.uuid", "XXXX0000-0000-XXXX-0000-XXXX0000XXXX");
        zip.file(`${uuid}.pdf`, file.pdf.split(",")[1], { base64: true });
        zip.file(`${uuid}.xml`, file.xml.split(",")[1], { base64: true });
    });
    return zip.generateAsync({ type: "base64" });
} 
export const getCfdiCancelNotice = async (workspaceId: number, invoice: Invoice): Promise<string> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cancellation/acuse?rfcEmisor=${invoice.emisor.rfc}&uuid=${invoice.uuid}`);
        const data = await petition.json();
        return `data:application/pdf;base64,${data.pdf}`;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const sendCfdiByEmail = async (workspaceId: number, invoiceId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/send`);
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const changeCfdiPaymentStatus = async (workspaceId: number, invoice: Invoice, status: string): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.put(`${route}/workspaces/${workspaceId}/cfdis/${invoice.uuid}/payment`, {
            statusPayment: status
        });
        await petition.text();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const getCfdiAmountPaid = async (workspaceId: number, invoice: Invoice): Promise<Money> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/amountpaid`);
        const data = await petition.json();
        const amount = Money.fromDecimal(Number(data["amountPaid"]), Currencies.MXN);
        return amount;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const syncWithSat = async (workspaceId: number, emisor?: RazonSocial, year?: CatalogueEntry, month?: CatalogueEntry, type?: CatalogueEntry): Promise<any> => {
    const client = HTTPClient.getClient();
    const isMonth = !_.isNil(month)
    const startMonth =  isMonth ?  month?.value : 1;
    const endMont =  isMonth ? month?.value : 12;
    try {
        const body = {
            idRfc: emisor?.idRfc,
            year: year?.value,
            startMonth: startMonth,
            endMonth: endMont,
            rfc: emisor?.rfc
        }
        const petition = await client.post(`${route}/workspaces/${workspaceId}/sat/request`, body);
        const data = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const cancelCfdi = async (workspaceId: number, invoice: Invoice, reason: number, substitute?: string) => {
    const client = HTTPClient.getClient();
    try {
        let url = `${route}/workspaces/${workspaceId}/cancellation/request`;
        if (invoice?.version === 4) url = `${url}40`;
        const petition = await client.post(url, {
            uuid: invoice.uuid,
            idCancellation: reason,
            replacementFolio: substitute
        });
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getPaymentComplementList = async (workspaceId: number, invoiceId: number): Promise<PaymentComplement[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment`);
        const data: PaymentComplement[] = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const getPaymentComplementFiles = async (workspaceId: number, invoiceId: number, paymentComplementId: number): Promise<PaymentComplementFiles> => {
    const client = HTTPClient.getClient();
    try {
        const petitions = await Promise.all([
            client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/pdfDownload`),
            client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/xmlDownload`)
        ]);
        const dataPdf = await petitions[0].text();
        const dataXml = await petitions[1].text();
  
        const pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
        const xmlBase64 = `data:application/xml;base64,${dataXml}`;
  
        return {
            pdf: pdfBase64,
            xml: xmlBase64
        };
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const createPaymentComplement = async (workspaceId: number, invoice: Invoice, form: any, version: string = "3.3"): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const body = {
            paymentDate: getDateInISO(form.fechaPago),
            idWayOfPayment: form.formaPago?.idCatalogValues,
            operationNumber: form.numeroOperacion,
            idCurrency: form.moneda?.idCatalogValues,
            exchangeRate: form.tipoCambio,
            amount: Money.fromDecimal(form.monto, Currencies.MXN).toDecimal(),
            impuesto: !_.isNil(form.tasa) ? "2" : undefined,
            rate: !_.isNil(form.tasa) ? form.tasa : undefined,
            additionalInformation: [{
                rfcOrigen: form.rfcOrigen,
                rfcReceptor: form.rfcBeneficiario,
                bank: form.nombreBanco,
                beneficiaryAccount: form.cuentaBeneficiario,
                orderingAccount: form.cuentaOrdenante,
                typePaymentChain: form.tipoCadenaPago?.idCatalogValues || 0,
                paymentChain: form.cadenaPago,
                paymentCertificate: form.certificadoPago,
                paymentStamp: form.selloPago,
            }],
            relatedDocuments: [{
                idRelatedDocument: invoice.uuid,
                previousBalance: form.importeSaldoAnterior,
                amountPaid: form.importePagado,
                insolteAmount: Money.fromDecimal(form.importeSaldoAnterior, Currencies.MXN).subtract(Money.fromDecimal(form.importePagado, Currencies.MXN)).toDecimal(),
                partialityNumber: Number(form.numeroParcialidad),
            }]
        }
        
        let url = `${route}/workspaces/${workspaceId}/cfdis/${invoice.idCfdi}/Payment`;
        if (version === "4.0") url = `${url}/v4`;

        const petition = await client.post(url, body);
        await petition.json();
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const sendPaymentComplementByEmail = async (workspaceId: number, invoiceId: number, paymentComplementId: number): Promise<void> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/Payment/${paymentComplementId}/send`);
        await petition.data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}

export const getCreditNoteList = async (workspaceId: number, invoiceId: number): Promise<CreditNote[]> => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/Creditnotes`);
        const data: CreditNote[] = await petition.json();
        return data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const getCreditNoteFiles = async (workspaceId: number, invoiceId: number, creditNoteId: number): Promise<CreditNoteFiles> => {
    const client = HTTPClient.getClient();
    try {
        const petitions = await Promise.all([
            client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/pdfDownload`),
            client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/xmlDownload`)
        ]);
        const dataPdf = await petitions[0].text();
        const dataXml = await petitions[1].text();
    
        const pdfBase64 = `data:application/pdf;base64,${dataPdf}`;
        const xmlBase64 = `data:application/xml;base64,${dataXml}`;
    
        return {
            pdf: pdfBase64,
            xml: xmlBase64
        };
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
export const sendCreditNoteByEmail = async (workspaceId: number, invoiceId: number, creditNoteId: number) => {
    const client = HTTPClient.getClient();
    try {
        const petition = await client.get(`${route}/workspaces/${workspaceId}/cfdis/${invoiceId}/creditNote/${creditNoteId}/send`);
        await petition.data;
    } catch (err: any) {
        const error = await httpErrorParser(err);
        throw error;
    }
}
