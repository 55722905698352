import React from 'react'
import _ from 'lodash'

import MUIBreadcrumbs, {
  BreadcrumbsProps as MUIBreadcrumbsProps,
} from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { Path } from 'models/documentation'

interface Props extends MUIBreadcrumbsProps {
  currentPath: Path[]
  onClickBreadcrumb: (path: Path[]) => void
}

const Breadcrumbs: React.FC<Props> = ({
  currentPath,
  onClickBreadcrumb,
  className,
  ...props
}) => {
  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault()
    const path = _.filter(currentPath, (p, i) => i <= index)
    onClickBreadcrumb(path)
  }

  return (
    <MUIBreadcrumbs
      className={`component-breadcrumbs ${className || ''}`.trim()}
      {...props}>
      {_.map(currentPath, (p: Path, i: number) => {
        const el =
          i < currentPath.length - 1 ? (
            <Link
              key={p.id}
              color="inherit"
              href="/"
              onClick={(e: any) => onClick(e, i)}>
              {p.label}
            </Link>
          ) : (
            <Typography key={p.id}>{p.label}</Typography>
          )
        return el
      })}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
