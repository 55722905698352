import React, { useState } from 'react';
import Card, { CardProps } from '@material-ui/core/Card';
import _ from 'lodash';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from "@material-ui/core/CircularProgress";

import Description from '@material-ui/icons/Description';
import Folder from '@material-ui/icons/Folder';
import NewFolder from '@material-ui/icons/CreateNewFolder';
import Download from '@material-ui/icons/GetApp';
import More from '@material-ui/icons/MoreVert';
import ErrorI from '@material-ui/icons/ErrorOutline';
import './style.scss';

export interface MenuAction {
  label: string
  action: () => void;
}

interface NewFolderCardProps {
  onClick?: () => void
}
interface FileCardProps extends CardProps {
  name?: string
  title: string
  subtitle?: string
  type: 'File' | 'Folder'
  error?: boolean
  loading?: boolean
  actions?: MenuAction[]
  onDownload?: () => void
  onFileClick?: () => void
  onActionClick?: () => void
}

export const NewFolderCard: React.FC<NewFolderCardProps> = ({ onClick, ...props }) => (
  <div className="component-new-folder-card" onClick={() => onClick && onClick()}>
      <NewFolder className="card-icon" fontSize="inherit" />
      <p className="card-text">Nueva carpeta</p>
  </div>
)

const FileCard: React.FC<FileCardProps> = ({ 
  name,
  title, 
  type, 
  className = "", 
  loading = false, 
  error = false, 
  actions = [], 
  subtitle, 
  onDownload,
  onFileClick,
  onActionClick,
  ...props 
}) => {
  const isLoading = loading;
  const isError = !loading && error;
  const isFile = !loading && !error && type === 'File';
  const isFolder = !loading && !error && type === 'Folder';
  const withActions = !loading && actions.length > 0;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
    <Tooltip title={title}>
      <Card className={`component-file-card ${type === 'Folder' ? "folder" : ""} ${type === 'File' ? "file" : ""} ${className}`.trim()} {...props}>
        <div className="card-content">
          {isLoading && <CircularProgress size={24} className="progress primary" disableShrink />}
          {isError   && <ErrorI className="icon danger" color="inherit" fontSize="inherit" />}
          {(isFile && !onDownload) && <Description className="icon" color="primary" fontSize="inherit" />}
          {(isFile && onDownload) && 
            <IconButton
              className="icon-button"
              color="primary"
              size="small"
              onClick={() => onDownload && onDownload()}
            >
              <Download fontSize="inherit" />
            </IconButton>
          }
          {isFolder  && <Folder className="icon" color="secondary" fontSize="inherit" />}
          <div className="description">
            <span className="title" onClick={() => onFileClick && onFileClick()}>{title}</span>
            <br />
            <span className="subtitle">{subtitle}</span>
          </div>
          {withActions &&
            <div className="actions">
              <IconButton
                size="small"
                className="menu-dots"
                onClick={(e) => {
                  e.stopPropagation();
                  onActionClick && onActionClick();
                  setAnchorEl(e.currentTarget)
                }}
              >
                <More />
              </IconButton>
            </div>
          }
        </div>
      </Card>
    </Tooltip>
    <Menu 
      anchorEl={anchorEl} 
      keepMounted
      open={Boolean(anchorEl)} 
      onClose={() => setAnchorEl(null)}
    >
      {withActions && _.map(actions, (action, i) =>
        <MenuItem key={i} onClick={(e) => { e.stopPropagation(); action.action(); setAnchorEl(null); }}>
          {action.label}
        </MenuItem>
      )}
    </Menu>
    </>
  )
}

export const FileCardAlt: React.FC<FileCardProps> = ({
  name,
  title, 
  type, 
  className = "", 
  loading = false, 
  error = false, 
  actions = [], 
  subtitle, 
  onFileClick, 
  ...props 
}) => {
  const isLoading = loading;
  const isError = !loading && error;
  const isFile = !loading && !error && type === 'File';
  const isFolder = !loading && !error && type === 'Folder';
  const withActions = !loading && actions.length > 0;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
    <Tooltip title={title}>
      <Card className={`component-file-card-alt ${type === 'Folder' ? "folder" : ""} ${type === 'File' ? "file" : ""} ${className}`.trim()} {...props}>
        <div className="card-status-actions">
          {isLoading && <CircularProgress size={30} className="progress primary" disableShrink />}
          {isError   && <ErrorI className="icon danger" color="inherit" fontSize="large" />}
          {isFolder  && <Folder className="icon" color="secondary" fontSize="large" />}
          {isFile && 
            <IconButton
              color="primary"
              size="small"
              onClick={() => onFileClick && onFileClick()}
            >
              <Download fontSize="large" />
            </IconButton>
          }
          {name &&
            <p>
              {name}
            </p>
          }
          {((isFile || isFolder) && withActions) && 
            <IconButton
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <More />
            </IconButton>
          }
        </div>
        <div className="card-description">
          <span className="title">{title}</span>
          <br />
          <span className="subtitle">{subtitle}</span>
        </div>
      </Card>
    </Tooltip>
    <Menu 
      anchorEl={anchorEl} 
      keepMounted
      open={Boolean(anchorEl)} 
      onClose={() => setAnchorEl(null)}
    >
      {withActions && _.map(actions, (action, i) =>
        <MenuItem key={i} onClick={() => { action.action(); setAnchorEl(null); }}>
          {action.label}
        </MenuItem>
      )}
    </Menu>
    </>
  )
}

export default FileCard;
