import { useEffect } from 'react'
import { toast } from 'react-toastify'
import {
  CONTROLLED_ERROR_CODES,
  SERVER_SENT_OPERATIONS,
} from 'lib/enums/shared.enums'
import HTTPClient from 'lib/helpers/HTTPClient'
import { selectAuthorized } from 'lib/helpers/selectors'
import { sseErrorCodeToastMessagesMapp } from 'lib/utils/shared.utils'
import { errorToastOptions } from 'lib/utils/toast.utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setClientId } from 'store/SSE/sse.reducer'
import { selectSSEClientId } from 'store/SSE/sse.selectors'
import { ISSEEventData } from 'services/Shared/shared.models'
import useHandleSSE from './useHandleSSE'

const useEventSource = () => {
  const dispatch = useAppDispatch()

  const authorized = useAppSelector(selectAuthorized)
  const sseClientId = useAppSelector(selectSSEClientId)

  const handleSSE = useHandleSSE()

  useEffect(() => {
    if (!sseClientId) {
      return
    }

    const client = HTTPClient.getClient()

    const url = `${client.host}${process.env.REACT_APP_APIURL}/events/${sseClientId}`

    const eventSource = new EventSource(url)

    eventSource.onmessage = event => {
      if (event.data === 'heartbeat') return
      const eventData = JSON.parse(event.data)
      console.log('SOURCE EVENT DATA', eventData)

      const errorCode = Object.values(CONTROLLED_ERROR_CODES).find(code =>
        eventData.message.includes(code)
      )

      if (
        !eventData.success &&
        eventData.idOperation === SERVER_SENT_OPERATIONS.CIF
      ) {
        errorCode &&
          sseErrorCodeToastMessagesMapp[errorCode] &&
          toast.error(
            sseErrorCodeToastMessagesMapp[errorCode],
            errorToastOptions
          )
        ;(!errorCode || !sseErrorCodeToastMessagesMapp[errorCode]) &&
          toast.error('Hubo un error al descargar el CIF', errorToastOptions)
        return
      }

      const typedEventData = eventData as ISSEEventData

      handleSSE(typedEventData)
    }

    eventSource.onopen = () => {
      console.log('EventSource connected')
    }

    eventSource.onerror = error => {
      // console.error('EventSource failed:', error)
    }

    if (!authorized) {
      eventSource.close()
      dispatch(setClientId(null))
    }

    return () => {
      eventSource.close()
    }
  }, [dispatch, authorized, sseClientId, handleSSE])
}
export default useEventSource
