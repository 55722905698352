import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IServerSentEventsState } from './sse.models'

const initialSSEState: IServerSentEventsState = {
  clientId: null,
}

const sseSlice = createSlice({
  name: 'sseSlice',
  initialState: initialSSEState,
  reducers: {
    setClientId(state, action: PayloadAction<string | null>) {
      state.clientId = action.payload
    },
  },
})

export const { setClientId } = sseSlice.actions

export default sseSlice.reducer
