import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorI from '@material-ui/icons/ErrorOutline'
import ReduxState from 'models/redux'
import { CONCILIATION_TABS } from 'lib/enums/conciliations.enums'
import useDialog from 'hooks/Shared/useDialog'
import { deleteArchive } from 'services/conciliations'
import { useConciliationsContainerContext } from 'containers/Conciliations/ConciliationsContainer/ConciliationsContainer'
import Button from 'components/Shared/Button/Button'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'
import { useIntl } from 'react-intl'

const OCRStatementsCancelButton = () => {
  const intl = useIntl()

  const OCRStatements = useSelector(
    (state: ReduxState) => state.conciliations.OCR.OCRStatements
  )
  const workspaceId = useSelector(
    (state: ReduxState) => state.workspace.activeWorkspace
  )
  const {
    OCR: { setOCRStatements, setOCRMovements },
    screen,
  } = useConciliationsContainerContext()
  const dialog = useDialog()
  const dispatch = useDispatch()

  const handleErrorConciliationProcess = useCallback(async () => {
    const clic = await dialog.dangerAsync({
      subtitle:
        'No se ha podido cancelar tu proceso correctamente. Favor de contactarte con el área de soporte para cancelar este proceso',
      title: 'Error en el flujo de Conciliación',
      icon: ErrorI as TModalIcon,
      primaryButtonText: 'Entendido',
    })

    if (clic) {
      dispatch(setOCRStatements([]))
      dispatch(setOCRMovements([]))
      dispatch(screen.handleSetTab(CONCILIATION_TABS.CONCILIATIONS))
    }
  }, [dialog, dispatch, screen, setOCRStatements, setOCRMovements])

  const cancelConciliationProcess = useCallback(async () => {
    if (OCRStatements.length === 1) {
      try {
        await deleteArchive(workspaceId, OCRStatements[0].idWsFile)
        dispatch(setOCRStatements([]))
        dispatch(setOCRMovements([]))
        dispatch(screen.handleSetTab(CONCILIATION_TABS.CONCILIATIONS))
      } catch (error: any) {
        if (error.status !== undefined) {
          handleErrorConciliationProcess()
        }
      }
    } else {
      handleErrorConciliationProcess()
    }
  }, [
    dispatch,
    screen,
    setOCRStatements,
    setOCRMovements,
    OCRStatements,
    workspaceId,
    handleErrorConciliationProcess,
  ])

  const handleConfirmCancel = useCallback(async () => {
    const confirm = await dialog.primaryAsync({
      title:
        '¿Estás seguro de cancelar la extracción de movimientos bancarios?',
      primaryButtonText: intl.formatMessage({ id: 'button.confirm' }),
      secondaryButtonText: 'Cancelar',
    })

    if (confirm) {
      cancelConciliationProcess()
    }
  }, [dialog, intl,  cancelConciliationProcess])

  return (
    <Button
      variant="outlined"
      color="primary"
      className="outlined-button"
      onClick={handleConfirmCancel}>
      Cancelar
    </Button>
  )
}

export default OCRStatementsCancelButton
