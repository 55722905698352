import update from 'immutability-helper';
import { 
	SET_CATEGORIES_LIST,
    SET_SELECTED_CATEGORY
} from 'actions/categories';

/** interfaces */
import { CategoriesStore } from 'models/redux';
import { ReduxAction } from 'models/thunk';

const initialCategories: CategoriesStore = {
    form: {
        name: "",
        description: ""
    },
    list: [],
    active: 0,
    selected: null
}

const defaultAction: ReduxAction<any> = {
	type: "",
	value: ""
}

const workspace = (state = initialCategories, action = defaultAction) => {
    switch (action.type) {
        case SET_CATEGORIES_LIST:
            return update(state, { list: { $set: action.value }});
        case SET_SELECTED_CATEGORY:
            return update(state, { selected: { $set: action.value }});
        default:
            return state
    }
}

export default workspace;