import React from 'react';
import ScrollSpyC from 'react-scrollspy-nav';
import _ from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';

import './style.scss';

interface Item {
    id: string
    label: string
    tooltip: string
}

interface Props {
    items: Item[]
}

const ScrollSpy: React.FC<Props> = (props) => {
    return (
        <div className="component-scrollspy">
            <ScrollSpyC
                scrollTargetIds={_.map(props.items, (item) => item.id)}
                offset={1}
                activeNavClass="active"
                headerBackground="true"
                router="BrowserRouter"
            >
                {_.map(props.items, (item, i) => 
                    <Tooltip key={i} title={item.tooltip} placement="right">
                        <a key={item.id} href={`#${item.id}`} className="item">
                            <strong>{item.label}</strong>
                        </a>
                    </Tooltip>
                )}
            </ScrollSpyC>
        </div>
    )
}

export default ScrollSpy;