import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { QueryOptions } from 'models/general'
import { IAccountingProcess } from 'models/accounting.models'
import { ROUTES } from '../../constants'
import useDialog from 'hooks/Shared/useDialog'
import { setCurrentProcess } from 'store/Accounting/accounting.reducer'
import { IApiError } from 'services/Shared/shared.models'
import {
  getAccountingByDateService,
  getAccountingByIdService,
} from 'services/Accounting/accounting.services'
import { selectErrorDialog } from 'lib/helpers/errors.helpers'
import { TModalIcon } from 'components/Shared/SydDialog/SydDialog'

const useGetAccounting = (
  workspaceId: number,
  date: Date,
  accountingId?: number,
  options: QueryOptions<IAccountingProcess, IAccountingProcess, IApiError> = {
    noDialog: false,
  }
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const dialog = useDialog()

  const { noDialog } = options

  const query = useQuery<IAccountingProcess, IApiError>(
    [`get-accounting`, workspaceId, date, accountingId],
    () => {
      if (accountingId) {
        return getAccountingByIdService(workspaceId, accountingId)
      }  

      return getAccountingByDateService(
        workspaceId,
        date.getFullYear(),
        date.getMonth() + 1
      )
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: accounting => {
        dispatch(setCurrentProcess(accounting))
      },
      onError: async error => {
        const op = selectErrorDialog(error)
        if (op?.variant) {
          await dialog[op.variant](op)
          return
        }

        if (!noDialog) {
          const rp = await dialog.dangerAsync({
            title: 'generic.error.title',
            subtitle: 'No se pudo obtener la información del proceso.',
            icon: ErrorOutlineIcon as TModalIcon,
            primaryButtonText: 'Reintentar',
            secondaryButtonText: 'Cancelar',
          })

          if (rp) {
            query.refetch()
            return
          } else {
            navigate(ROUTES.HOME, { replace: true })
          }
        }
      },
    }
  )

  return query
}

export default useGetAccounting
