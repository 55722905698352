import React from 'react'

import Card from '@material-ui/core/Card'

import { useAddClient } from 'hooks/queries/clients'

import './style.scss'

import AddClientForm from 'components/Clients/AddClientForm/AddClientForm'
import { errorToastOptions, successToastOptions } from 'lib/utils/toast.utils'
import { toast } from 'react-toastify'

interface Props {
  workspaceId: number
  onRedirect: (action: string) => void
}

const AddClientContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  ...props
}) => {
  const addMutation = useAddClient(workspaceId, {
    onSuccess: () => {
      toast.success('Cliente agregado correctamente', successToastOptions)
      onRedirect('success')
    },
    onError: () => {
      toast.error(
        'No se ha agregado correctamente el cliente',
        errorToastOptions
      )
    },
  })

  return (
    <>
      <Card elevation={1} className="container-add-client">
        <AddClientForm addMutation={addMutation} />
      </Card>
    </>
  )
}

export default AddClientContainer
