import React from 'react'
import { useDidUpdate } from 'rooks'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Button from 'components/Shared/Button/Button'

import AddRazonSocialForm from 'containers/RazonesSociales/AddRazonSocialFormContainer/AddRazonSocialFormContainer'

import {
  selectActiveWorkspace,
  selectRazonesSocialesList,
} from 'lib/helpers/selectors'
import { ROUTES } from '../../../constants'

import Clear from '@material-ui/icons/Clear'
import './style.scss'

interface Props {}

const AddRazonSocialScreen: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const ws = Number(params.get('ws'))

  const workspaceId = useSelector(selectActiveWorkspace)
  const list = useSelector(selectRazonesSocialesList) || []
  const multiEmisor = list?.length >= 1

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDidUpdate(() => {
    onRouteChange('cancel')
  }, [workspaceId])

  const onRouteChange = (action: string, id: number = 0) => {
    const origin = _.get(props, 'location.state.from')
    const route =
      origin || `${ROUTES.WORKSPACE_DETAILS}?id=${ws || workspaceId}`
    if (action === 'error') navigate(route, { replace: true })
    if (action === 'cancel' || action === 'success') navigate(route)
  }

  if (!workspaceId) {
    return <Navigate to={ROUTES.HOME} replace />
  }

  if (multiEmisor) {
    const origin = _.get(props, 'location.state.from')
    const route = origin || ROUTES.RAZONES_SOCIALES
    return <Navigate to={route} replace />
  }

  return (
    <>
      <Grid container spacing={0} className="screen-razones-sociales">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Agregar nuevo emisor</h1>
            <Button
              id="cancelNewBusinessNameBtn"
              variant="hover"
              color="secondary"
              startIcon={<Clear />}
              onClick={() => onRouteChange('cancel')}>
              Cancelar
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <AddRazonSocialForm
            workspaceId={ws || workspaceId}
            onRedirect={onRouteChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddRazonSocialScreen
