import React from 'react'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserNotifications from 'components/Workspaces/UserNotifications/UserNotifications'

import {
  useChangeUserNotification,
  useUserNotificationsList,
} from 'hooks/queries/workspaces'

import { nameBuilder } from 'lib/helpers/utilities'

import './style.scss'

interface Props {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
  className?: string
}

const UserNotificationsContainer: React.FC<Props> = ({
  workspaceId,
  onRedirect,
  className = '',
}) => {
  // #region Queries
  const listQuery = useUserNotificationsList(workspaceId, {
    onError: () => onRedirect('error'),
  })
  // #endregion

  // #region Mutation
  const changeMutation = useChangeUserNotification(workspaceId, {
    onSuccess: () => listQuery.refetch(),
  })
  // #endregion

  return (
    <div className={`container-user-notifications-list ${className}`}>
      <Grid container spacing={3} className="user-notifications-list">
        {listQuery.isLoading && (
          <Grid item xs={12} className="loading-container">
            <CircularProgress color="primary" size={60} />
          </Grid>
        )}
        {!(listQuery.isLoading || listQuery.isError) &&
          _.map(listQuery?.data, user => (
            <Grid key={user.idUser} item xs={12}>
              <UserNotifications
                id={user.idUser}
                name={nameBuilder(
                  user.name || '',
                  user.lastname || '',
                  user?.lastname2
                )}
                role={user.role}
                list={user.notifications}
                onChange={(userId, roleId, typeId, consecutive, value) =>
                  changeMutation.mutate({
                    userId,
                    roleId,
                    typeId,
                    consecutive,
                    value,
                  })
                }
              />
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default UserNotificationsContainer
