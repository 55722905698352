import { createContext, useMemo, useState } from 'react'
import { TManagementTabs } from 'models/management.models'
import { MANAGEMENT_TABS } from 'lib/enums/management.enums'
import { managementTabs } from 'lib/utils/Management/management.utils'
import SydTabs from 'components/Shared/SydTabs/SydTabs'
import AccountantsContainer from '../Accountants/AccountantsContainer/AccountantsContainer'
import ManagementWorkspacesContainer from '../ManagementWorkspaces/ManagementWorkspacesContainer/ManagementWorkspacesContainer'

interface IManagementContext {
  activeTab: TManagementTabs
  handleSetTab: (tab: TManagementTabs) => void
}

export const ManagementContext = createContext<IManagementContext | null>(null)

const ManagementContainer = () => {
  const [activeTab, setActiveTab] = useState<TManagementTabs>(
    MANAGEMENT_TABS.WORKSPACES
  )

  const tabComponents: Record<TManagementTabs, React.ComponentType> = {
    [MANAGEMENT_TABS.WORKSPACES]: ManagementWorkspacesContainer,
    [MANAGEMENT_TABS.ACCOUNTANTS]: AccountantsContainer,
  }

  const ActiveTabComponent = tabComponents[activeTab]

  const handleSetTab = (tab: TManagementTabs) => {
    setActiveTab(tab)
  }

  const handleChangeTab = (
    event: React.ChangeEvent<{}>,
    newTab: TManagementTabs
  ) => {
    handleSetTab(newTab)
  }

  const contextObject = useMemo(() => {
    return {
      activeTab,
      handleSetTab,
    }
  }, [activeTab])

  return (
    <div className="management-container">
      <ManagementContext.Provider value={contextObject}>
        <SydTabs
          tabs={managementTabs}
          activeTab={activeTab}
          onChangeTab={handleChangeTab}
        />
        <ActiveTabComponent />
      </ManagementContext.Provider>
    </div>
  )
}

export default ManagementContainer
