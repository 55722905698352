import { Typography } from '@material-ui/core'
import ManagementContainer from 'containers/Management/ManagementContainer/ManagementContainer'
import './styles.scss'

const ManagementScreen = () => {
  return (
    <section className="management-screen">
      <Typography className="title">Gestión</Typography>
      <ManagementContainer />
    </section>
  )
}

export default ManagementScreen
