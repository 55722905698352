import { Box, IconButton, Toolbar, Typography } from '@material-ui/core'
import { FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { ReportItem } from 'services/conciliations'
import _ from 'lodash'
import { useConciliationReportsContext } from 'containers/Conciliations/ConciliationsReportsContainer/ConciliationReportsContainer'
import { months } from 'lib/utils/shared.utils'

const ConciliationReportDetailsHeader: FC<{
  report: ReportItem
}> = ({ report }) => {
  const { setSelectedReport } = useConciliationReportsContext()
  const dispatch = useDispatch()

  const closeReport = () => {
    dispatch(setSelectedReport(null))
  }

  const period = useMemo(() => {
    const [m, y] = report.period.split(' ')
    const periodDate = new Date()
    periodDate.setMonth(months[m])
    periodDate.setFullYear(parseInt(y))
    const month = periodDate.toLocaleString('es-MX', { month: 'long' })
    const year = periodDate.getFullYear()
    return `${year} - ${_.capitalize(month)}`
  }, [report.period])

  return (
    <Toolbar
      disableGutters
      style={{
        marginBottom: 10,
      }}>
      <Typography variant="h5">{period}</Typography>
      <Box flex={1} />
      <IconButton
        onClick={closeReport}
        style={{
          padding: 0,
        }}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
  )
}

export default ConciliationReportDetailsHeader
