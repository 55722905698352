import { useSelector } from 'react-redux'
import { selectSelectedRecurrentPlan } from 'store/Payments/payments.selectors'
import { usePaymentsContainerContext } from 'containers/Account/Payments/PaymentsContainer/PaymentsContainer'
import SydDialog from 'components/Shared/SydDialog/SydDialog'

const RecurrentPaymentCancellationModal = () => {
  const {
    isRecurrentPlanCancellationModalOpen,
    isLoadingRecurrentPaymentCancellation,
    onCloseRecurrentPlanModalHandler,
    cancelRecurrentPayment,
  } = usePaymentsContainerContext()

  const recurrentPlan = useSelector(selectSelectedRecurrentPlan)

  return (
    <SydDialog
      className="recurrent-payment-cancellation"
      open={isRecurrentPlanCancellationModalOpen}
      onClose={onCloseRecurrentPlanModalHandler}
      title={'Cancelar domiciliación'}
      subtitle="Si decides cancelar la domiciliación, tendrás que hacer el cargo manualmente cada periodo."
      message="¿Deseas continuar?"
      primaryButtonText="Cancelar domiciliación"
      onConfirm={cancelRecurrentPayment(recurrentPlan?.id ?? 0)}
      loadingPrimaryButton={isLoadingRecurrentPaymentCancellation}
      secondaryButtonText="Volver"
      onCancel={onCloseRecurrentPlanModalHandler}></SydDialog>
  )
}

export default RecurrentPaymentCancellationModal
