import update from 'immutability-helper'

/** interfaces */
import { ConciliationsStore } from 'models/redux'
import { ReduxAction } from 'models/thunk'
import {
  SET_ACCOUNT_STATEMENTS_HISTORY,
  SET_ACTIVE_REPORT,
  SET_ACTIVE_TAB,
  SET_CFDIS_HISTORY,
  SET_CONCILIATION_ACTIVE_STATEMENT_ROW,
  SET_CONCILIATION_CFDI_ACTIVE_SECTION,
  SET_CONCILIATION_CFDI_FILTER,
  SET_CONCILIATION_CFDI_ROWS,
  SET_CONCILIATION_DISPLAY_CONCILIED,
  SET_CONCILIATION_PERIOD_MONTH,
  SET_CONCILIATION_PERIOD_YEAR,
  SET_CONCILIATION_ROWS,
  SET_CONCILIATION_SELECTED_CFDI_ROWS,
  SET_CONCILIATION_STATEMENT_FILTER,
  SET_CONCILIATION_STATEMENT_ROWS,
  SET_CONCILIATION_STATEMENT_ROWS_SEARCH,
  SET_LEDGER_FILTER,
  SET_LEDGER_ROWS,
  SET_LEDGER_SELECTED_ROWS,
  SET_OCR_MOVEMENTS,
  SET_OCR_STATEMENTS,
  SET_REPORTS_ROWS,
  SET_REPORT_FILTER_MONTH,
  SET_REPORT_FILTER_YEAR,
  SET_SELECTED_CONCILIATION_STATEMENT_ROWS,
} from 'actions/conciliations'
import { RESET_APP_STATE } from 'store/shared.actions'

const initialConciliations: ConciliationsStore = {
  activeTab: 0,
  history: {
    accountStatementsHistory: [],
    CFDIsHistory: [],
  },
  conciliationPeriod: {
    month: new Date().getMonth() - 1,
    year: new Date().getFullYear(),
  },
  statements: {
    statementsAccount: null,
    statementRows: [],
    activeStatementRow: null,
    selectedStatementRows: [],
    statementSearch: '',
    statementFilters: {
      display: false,
      text: '',
      type: '',
      fromDate: '',
      toDate: '',
    },
  },
  cfdis: {
    cfdiRows: [],
    selectedCfdiRows: [],
    cfdiActiveSection: 0,
    cfdiSearch: '',
    cfdiFilters: {
      display: false,
      text: '',
      status: '',
      fromDate: '',
      toDate: '',
    },
  },
  ledgers: {
    ledgerRows: [],
    selectedLedgerRows: [],
    ledgerFilters: {
      display: true,
      text: '',
      type: '',
      fromDate: '',
      toDate: '',
    },
  },
  reports: {
    selectedReport: null,
    reports: [],
    filter: {
      month: new Date().getMonth() - 1,
      year: new Date().getFullYear(),
    },
  },
  OCR: {
    OCRStatements: [],
    OCRMovements: [],
  },
  conciliations: {
    displayReconciled: false,
    conciliationRows: [],
  },
}

const defaultAction: ReduxAction<any> = {
  type: '',
  value: {
    name: '',
    entries: [],
  },
}

const conciliations = (
  state = initialConciliations,
  action = defaultAction
) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return update(state, { activeTab: { $set: action.value } })
    case SET_CONCILIATION_PERIOD_MONTH:
      return update(state, {
        conciliationPeriod: { month: { $set: action.value } },
      })
    case SET_CONCILIATION_PERIOD_YEAR:
      return update(state, {
        conciliationPeriod: { year: { $set: action.value } },
      })

    // statements
    case SET_CONCILIATION_STATEMENT_ROWS:
      return update(state, {
        statements: { statementRows: { $set: action.value } },
      })
    case SET_CONCILIATION_ACTIVE_STATEMENT_ROW:
      return update(state, {
        statements: { activeStatementRow: { $set: action.value } },
      })
    case SET_SELECTED_CONCILIATION_STATEMENT_ROWS:
      return update(state, {
        statements: { selectedStatementRows: { $set: action.value } },
      })
    case SET_CONCILIATION_STATEMENT_FILTER:
      return update(state, {
        statements: { statementFilters: { $set: action.value } },
      })
    case SET_CONCILIATION_STATEMENT_ROWS_SEARCH:
      return update(state, {
        statements: { statementSearch: { $set: action.value } },
      })

    // cfdis
    case SET_CONCILIATION_CFDI_ACTIVE_SECTION:
      return update(state, {
        cfdis: { cfdiActiveSection: { $set: action.value } },
      })
    case SET_CONCILIATION_CFDI_ROWS:
      return update(state, { cfdis: { cfdiRows: { $set: action.value } } })
    case SET_CONCILIATION_SELECTED_CFDI_ROWS:
      return update(state, {
        cfdis: { selectedCfdiRows: { $set: action.value } },
      })
    case SET_CONCILIATION_CFDI_FILTER:
      return update(state, { cfdis: { cfdiFilters: { $set: action.value } } })
    case SET_REPORTS_ROWS:
      return update(state, { reports: { reports: { $set: action.value } } })
    case SET_ACTIVE_REPORT:
      return update(state, {
        reports: { selectedReport: { $set: action.value } },
      })
    case SET_REPORT_FILTER_MONTH:
      return update(state, {
        reports: { filter: { month: { $set: action.value } } },
      })
    case SET_REPORT_FILTER_YEAR:
      return update(state, {
        reports: { filter: { year: { $set: action.value } } },
      })
    case SET_LEDGER_ROWS:
      return update(state, { ledgers: { ledgerRows: { $set: action.value } } })
    case SET_LEDGER_FILTER:
      return update(state, {
        ledgers: { ledgerFilters: { $set: action.value } },
      })
    case SET_LEDGER_SELECTED_ROWS:
      return update(state, {
        ledgers: { selectedLedgerRows: { $set: action.value } },
      })

    // history
    case SET_ACCOUNT_STATEMENTS_HISTORY:
      return update(state, {
        history: { accountStatementsHistory: { $set: action.value } },
      })
    case SET_CFDIS_HISTORY:
      return update(state, {
        history: { CFDIsHistory: { $set: action.value } },
      })

    // OCR
    case SET_OCR_STATEMENTS:
      return update(state, { OCR: { OCRStatements: { $set: action.value } } })
    case SET_OCR_MOVEMENTS:
      return update(state, { OCR: { OCRMovements: { $set: action.value } } })

    // conciliations
    case SET_CONCILIATION_DISPLAY_CONCILIED:
      return update(state, {
        conciliations: { displayReconciled: { $set: action.value } },
      })
    case SET_CONCILIATION_ROWS:
      return update(state, {
        conciliations: { conciliationRows: { $set: action.value } },
      })
    case RESET_APP_STATE:
      return update(state, {
        statements: {
          activeStatementRow: {
            $set: initialConciliations.statements.activeStatementRow,
          },
          statementSearch: {
            $set: initialConciliations.statements.statementSearch,
          },
          statementFilters: {
            $set: initialConciliations.statements.statementFilters,
          },
        },
        cfdis: {
          cfdiSearch: { $set: initialConciliations.cfdis.cfdiSearch },
          cfdiFilters: { $set: initialConciliations.cfdis.cfdiFilters },
        },
        ledgers: {
          ledgerFilters: { $set: initialConciliations.ledgers.ledgerFilters },
        },
        reports: {
          filter: {
            month: { $set: initialConciliations.reports.filter.month },
            year: { $set: initialConciliations.reports.filter.year },
          },
        },
      })
    default:
      return state
  }
}

export default conciliations
