import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useThunkDispatch } from 'models/thunk'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import SearchSatCodeWizardContainer from 'containers/Shared/SearchSatCodeWizardContainer/SearchSatCodeWizardContainer'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Activity from 'components/Shared/Activity/Activity'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import NumericField from 'components/FormComponents/IOComponents/NumericField/NumericField'
import SearchField from 'components/FormComponents/IOComponents/SearchField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'

import useProductsList, {
  useProduct,
  useUpdateProduct,
} from 'hooks/queries/products'
import useCategoriesList from 'hooks/queries/categories'

import { setSelectedProduct } from 'actions/products'
import { searchInCatalogue } from 'actions/catalogues'
import {
  selectCatalogue,
  selectProductsListIds,
  selectCategoriesList,
  selectSelectedProduct,
} from 'lib/helpers/selectors'

import Search from '@material-ui/icons/Search'
import './style.scss'

import { ProductForm } from 'models/product'
import { CatalogueEntry } from 'models/catalogues'

interface Props {
  id: number
  workspaceId: number
  onRedirect: (action: string) => void
}

const EditProductContainer: React.FC<Props> = ({
  id,
  workspaceId,
  onRedirect,
  ...props
}) => {
  const thunkDispatch = useThunkDispatch()
  const dispatch = useDispatch()
  const loader = useLoader()

  const units = useSelector(selectCatalogue('unidades'))
  const tax = useSelector(selectCatalogue('objImp'))
  const categories = useSelector(selectCategoriesList)
  const product = useSelector(selectSelectedProduct) || undefined
  const productsId = useSelector(selectProductsListIds(product?.internalId))

  const [valid, setValid] = useState(false)
  const [openWizard, setOpenWizard] = useState(false)
  const [item, setItem] = useState<CatalogueEntry | null>(null)

  const categoriesListQuery = useCategoriesList(workspaceId, {
    onError: () => onRedirect('error'),
  })
  const productsListQuery = useProductsList(workspaceId, {
    onError: () => onRedirect('error'),
  })
  const productQuery = useProduct(workspaceId, id, {
    onSuccess: data => setItem(data.claveSat),
    onError: () => onRedirect('error'),
  })

  const updateMutation = useUpdateProduct(workspaceId, product, {
    onSuccess: () => onRedirect('success'),
  })

  useEffect(() => {
    productQuery.isLoading ||
    productsListQuery.isLoading ||
    categoriesListQuery.isLoading
      ? loader.primary('Cargando información del producto')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productQuery.isLoading,
    productsListQuery.isLoading,
    categoriesListQuery.isLoading,
  ])

  useEffect(
    () => () => {
      dispatch(setSelectedProduct())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const renderCatalogueOption = (o: CatalogueEntry) => (
    <span className="catalogue-option">{`${o.value} - ${o.label}`}</span>
  )

  return (
    <>
      <Card elevation={1} className="container-edit-product">
        <Form
          onSubmit={async (form: ProductForm) => updateMutation.mutate(form)}
          onValidity={(v: boolean) => setValid(v)}>
          <div className="description">
            <p>
              Puedes editar la siguiente información para actualizar este
              producto
            </p>
            {(productQuery.isFetching ||
              productsListQuery.isFetching ||
              categoriesListQuery.isFetching) && <Activity size={22} local />}
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  fieldType="alphanumeric"
                  name="internalId"
                  label="ID"
                  value={product?.internalId}
                  validate={(v: number) => ({
                    valid: _.indexOf(productsId, v) === -1,
                    message: 'Este ID ya existe en el catálogo.',
                  })}
                  placeholder="Serie, Modelo, No. de Lote, etc."
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  label="Nombre"
                  value={product?.name}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <NumericField
                  name="price"
                  label="Precio"
                  value={product?.price}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  format="money"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <SearchField
                  name="itemSat"
                  label="Clave SAT"
                  onSearch={(v: string) =>
                    thunkDispatch(searchInCatalogue('items', v))
                  }
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  value={item}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setOpenWizard(true)}
                        size="small">
                        <Search />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name="unitSat"
                  label="Unidad o medida"
                  catalogue={units}
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  value={product?.claveUnitSat}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name="tax"
                  label="Objeto de Impuesto"
                  catalogue={tax}
                  getOptionLabel={(o: any) => `${o.value} - ${o.label}`}
                  renderOption={(o: any) => renderCatalogueOption(o)}
                  value={product?.objImp}
                  fullWidth
                  required // ToDo: Revisar si es requerido
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name="category"
                  label="Categoría"
                  catalogue={categories}
                  getOptionLabel={(o: any) => `${o.name}`}
                  value={product?.categories}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={8}>
                <p className="form-label">Descripción</p>
                <TextField
                  variant="outlined"
                  name="description"
                  inputProps={{ maxLength: 500 }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  withCounter
                  value={product?.description}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="updateProductBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={updateMutation.isLoading}
              disabled={!valid}>
              Actualizar
            </Button>
          </div>
        </Form>
      </Card>

      <SearchSatCodeWizardContainer
        open={openWizard}
        onSelect={v => setItem(v)}
        onCancel={() => setOpenWizard(false)}
      />
    </>
  )
}

export default EditProductContainer
