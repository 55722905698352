import _ from 'lodash'
import HTTPClient from 'lib/helpers/HTTPClient'
import { httpErrorParser, parseDocument } from 'lib/helpers/utilities'

import ReduxState from 'models/redux'
import { Path } from 'models/documentation'
import { Archive } from 'models/general'
import ISydDocument from 'models/shared.models'
import { IDownloadWorkspaceDocumentRequest } from 'services/Shared/shared.models'

export const SET_DOCUMENTATION_LIST = '@Documentation/SET_DOCUMENTATION_LIST'
export const SET_PATH = '@Documentation/SET_PATH'
export const ADD_TO_PATH = '@Documentation/ADD_PATH'
export const POP_FROM_PATH = '@Documentation/ADD_PATH'
export const RESET_PATH = '@Documentation/RESET_PATH'
export const EDIT_ARCHIVE = '@Documentation/EDIT_ARCHIVE'

const api = process.env.REACT_APP_APIURL || '/api/'

//#region Acciones para persistir informacion funcional de Documentation
export const setDocumentationList = (value: ISydDocument[]): object => ({
  type: SET_DOCUMENTATION_LIST,
  value,
})
export const setPath = (value: Path[]): object => ({
  type: SET_PATH,
  value,
})
export const addToPath = (value: Path): object => ({
  type: ADD_TO_PATH,
  value,
})
export const popFromPath = (value: Path): object => ({
  type: POP_FROM_PATH,
  value,
})
export const resetPath = (): object => ({
  type: RESET_PATH,
})
export const editArchive = (id: number, name: string, value: any) => ({
  type: EDIT_ARCHIVE,
  value: { id, name, value },
})
//#endregion

export const getFolderContentByPath =
  (workspaceId: number, noSave: boolean = false) =>
  async (
    dispatch: any,
    getStore: () => ReduxState
  ): Promise<ISydDocument[]> => {
    const client = HTTPClient.getClient()
    const {
      documentation: { currentPath },
    } = getStore()
    const arrayPath = _.map(currentPath, p => p.goTo)
    const path = _.join(arrayPath, '/') || '/'

    try {
      const petition = await client.get(
        `${api}/workspaces/${workspaceId}/files?path=${path}`
      )
      const data = await petition.json()

      const parsedList: ISydDocument[] = data
      const list = _.orderBy(
        parsedList,
        ['fileType', 'path', 'name', 'createdAt'],
        ['asc', 'asc', 'asc', 'asc']
      )

      !noSave && dispatch(setDocumentationList(list))
      return list
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getDocumentationFileById =
  ({ workspaceId, fileId }: IDownloadWorkspaceDocumentRequest) =>
  async (): Promise<ISydDocument> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.get(
        `${api}/workspaces/${workspaceId}/wsfiles/download?idWsfile=${fileId}`
      )
      const data: any = await petition.json()
      return parseDocument(data)
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }
export const createFolderInPath =
  (workspaceId: number, name: string) =>
  async (_dispatch: any, getStore: () => ReduxState): Promise<ISydDocument> => {
    const client = HTTPClient.getClient()
    const {
      documentation: { currentPath },
    } = getStore()
    const arrayPath = _.map(currentPath, p => p.goTo)
    const path = _.join(arrayPath, '/')

    try {
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/wsfiles/upload`,
        {
          name: '',
          path: `${path}/${name}`,
          enabled: true,
          mimeType: '',
          extension: '',
          originalName: '',
          file: '',
          directory: true,
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const uploadArchiveInPath =
  (workspaceId: number, file: Archive) =>
  async (_dispatch: any, getStore: () => ReduxState): Promise<void> => {
    const client = HTTPClient.getClient()
    const {
      documentation: { currentPath },
    } = getStore()
    const arrayPath = _.map(currentPath, p => p.goTo)
    const path = _.join(arrayPath, '/') || '/'

    try {
      const petition = await client.post(
        `${api}/workspaces/${workspaceId}/wsfiles/upload`,
        {
          name: getFileName(file.fileName),
          path: `${path}`,
          enabled: true,
          mimeType: _.split(file.data, ',')[0],
          extension: getFileExtension(file.fileName),
          originalName: file.fileName,
          file: _.split(file.data, ',')[1],
        }
      )
      const data = await petition.json()
      return data
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const renameArchive =
  (workspaceId: number, file: ISydDocument, name: string) =>
  async (_dispatch: any, _getStore: () => ReduxState): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.put(
        `${api}/workspaces/${workspaceId}/wsfiles/${file.idWsfile}`,
        { name }
      )
      await petition.json()
    } catch (err: any) {
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const deleteArchive =
  (workspaceId: number, file: ISydDocument) =>
  async (_dispatch: any, _getStore: () => ReduxState): Promise<void> => {
    const client = HTTPClient.getClient()
    try {
      const petition = await client.delete(
        `${api}/workspaces/${workspaceId}/wsfiles/${file.idWsfile}`
      )
      await petition.text()
    } catch (err: any) {
      console.log(err)
      const error = await httpErrorParser(err)
      throw error
    }
  }

export const getFileExtension = (filename: string | undefined) : string => {
    if(filename === undefined) return ""

    const helper = filename.split(".")
    return helper[helper.length - 1]
}

export const getFileName = (filename: string | undefined) : string => {
    if(filename === undefined) return ""

    const extension = getFileExtension(filename)
    return filename.replace(new RegExp( "\\." +  extension + "$"), "")
} 