import React from 'react'
import { useIntl } from 'react-intl'
import { Money, Currencies } from 'ts-money'
import _ from 'lodash'

import { moneyToText } from 'lib/helpers/utilities'

import './style.scss'

import { Concept } from 'models/invoice'

interface DisplayTotalDetailsProps {
  concepts: Concept[]
}

interface DisplayTotalProps {
  total: number
  subtotal?: number
  money?: string
}

// const calculateTax = (base: number, factor: string, value: number) => {
//     switch (factor) {
//         case "Tasa":  return value * base;
//         case "Cuota": return value;
//         default:      return 0;
//     }
// }

const calculateTax = (base: Money, factor: string, value: number) => {
  switch (factor) {
    case 'Tasa':
      return base.multiply(value)
    case 'Cuota':
      return Money.fromDecimal(value, Currencies.MXN)
    default:
      return Money.fromDecimal(0, Currencies.MXN)
  }
}

export const DisplayTotalDetails: React.FC<DisplayTotalDetailsProps> = ({
  concepts,
}) => {
  const intl = useIntl()

  const subtotal = _.reduce(
    concepts,
    (sum, c) => {
      const p = Money.fromDecimal(parseFloat(c.price ? c.price : "0"), Currencies.MXN)
      return sum.add(p.multiply(Number(c.cantidad)))
    },
    Money.fromDecimal(0, Currencies.MXN)
  )

  const discount = _.reduce(
    concepts,
    (sum, c) => {
      const d = Money.fromDecimal(parseFloat(c.descuento ? c.descuento : "0"), Currencies.MXN)
      return sum.add(d)
    },
    Money.fromDecimal(0, Currencies.MXN)
  )

  const totalTTaxes = _.reduce(
    concepts,
    (sum, c) => {
      const p = Money.fromDecimal(parseFloat(c.price ? c.price : "0"), Currencies.MXN)
      const d = Money.fromDecimal(parseFloat(c.descuento ? c.descuento : "0"), Currencies.MXN)
      const base = p.multiply(Number(c.cantidad)).subtract(d)

      const taxes = _.filter(c.impuestos, t => t.tr === 'Traslado')
      const conceptTotalTaxes = _.reduce(
        taxes,
        (sum, t) => sum.add(calculateTax(base, t.factor, t.selectedValue)),
        Money.fromDecimal(0, Currencies.MXN)
      )

      return sum.add(conceptTotalTaxes)
    },
    Money.fromDecimal(0, Currencies.MXN)
  )

  const totalRTaxes = _.reduce(
    concepts,
    (sum, c) => {
      const p = Money.fromDecimal(parseFloat(c.price ? c.price : "0"), Currencies.MXN)
      const d = Money.fromDecimal(parseFloat(c.descuento ? c.descuento : "0"), Currencies.MXN)
      const base = p.multiply(Number(c.cantidad)).subtract(d)

      const taxes = _.filter(c.impuestos, t => t.tr === 'Retencion')
      const conceptTotalTaxes = _.reduce(
        taxes,
        (sum, t) => sum.add(calculateTax(base, t.factor, t.selectedValue)),
        Money.fromDecimal(0, Currencies.MXN)
      )

      return sum.add(conceptTotalTaxes)
    },
    Money.fromDecimal(0, Currencies.MXN)
  )

  const total = subtotal
    .subtract(discount)
    .add(totalTTaxes)
    .subtract(totalRTaxes)

  const formatMoney = (money: number) => {
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }

  return (
    <div className="component-display-total-details">
      <div className="line">
        <div className="label">
          Subtotal:
          {/* {intl.formatMessage({ id: `button.edit` })} */}
        </div>
        <div className="value">
          {subtotal.toDecimal() ? formatMoney(subtotal.toDecimal()) : '$--.--'}
        </div>
      </div>
      <div className="line">
        <div className="label">Descuento:</div>
        <div className="value">
          {discount.toDecimal() ? formatMoney(discount.toDecimal()) : '$--.--'}
        </div>
      </div>
      <div className="line">
        <div className="label">Total impuestos trasladados:</div>
        <div className="value">
          {totalTTaxes.toDecimal()
            ? formatMoney(totalTTaxes.toDecimal())
            : '$--.--'}
        </div>
      </div>
      <div className="line">
        <div className="label">Total impuestos retenidos:</div>
        <div className="value">
          {totalRTaxes.toDecimal()
            ? formatMoney(totalRTaxes.toDecimal())
            : '$--.--'}
        </div>
      </div>
      <div className="line">
        <div className="label">Total:</div>
        <div className="value">
          <strong>
            {total.toDecimal() ? formatMoney(total.toDecimal()) : '$--.--'}
          </strong>
        </div>
      </div>
    </div>
  )
}

const DisplayTotal: React.FC<DisplayTotalProps> = ({
  total,
  subtotal = 0,
  money = '',
}) => {
  const intl = useIntl()

  const formatMoney = (money: number) => {
    const formatted = intl.formatNumber(money, {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'narrowSymbol',
    })
    return formatted
  }

  return (
    <div className="component-display-total">
      {Boolean(subtotal) && (
        <div className="line">
          <div className="label">Subtotal:</div>
          <div className="value">{formatMoney(subtotal)}</div>
        </div>
      )}
      <div className="line">
        {/* Revisar XXX */}
        <div className="label">{money ? 'Total' : `Total ${money}`}:</div>
        <div className="value secondary">{formatMoney(total)}</div>
      </div>
      <div className="line">
        <div className="label">{moneyToText(total)}</div>
      </div>
    </div>
  )
}

export default DisplayTotal
