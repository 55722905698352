import { Grid } from '@mui/material'
import useGetConciliationReports from 'hooks/useGetConciliationReports'
import ConciliationReportsDateSelector from '../ConciliationReportsDateSelector/ConciliationReportsDateSelector'
import ConciliationReportsToolbar from '../ConciliationReportsToolbar/ConciliationReportsToolbar'
import ConciliationReportsTable from '../ConciliationReportsTable/ConciliationReportsTable'
import ConciliationReportDetails from '../ConciliationReportDetails/ConciliationReportDetails'

const ConciliationsReports = () => {

  useGetConciliationReports()

  return (
    <>
      <Grid item xs={12} md={6}>
        <ConciliationReportsDateSelector />
        <ConciliationReportsToolbar />
        <ConciliationReportsTable />
      </Grid>
      <ConciliationReportDetails />
    </>
  )
}

export default ConciliationsReports

