import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import './style.scss'

import { SortDirection } from 'models/general'

export interface HeaderOptions {
  label: string | number
  accesor: string
  headerClassName?: string
  cellClassName?: string
  sortable?: boolean
  identifier?: string
  renderer?: (value: any, row?: IRow, i?: any) => any
}

export interface IRow {
  [key: string]: any
}

interface HeaderProps {
  header: HeaderOptions
  sortBy?: string
  sortDirection?: SortDirection
  onSort?: (identifier: string, e?: any) => void
}

interface RowProps {
  row: IRow
  headers: HeaderOptions[]
  onRowClick?: (r: IRow, e?: any) => void
  renderMore?: (r: IRow) => any
  rowSize: number
  onCheck?: (r: IRow, checked: boolean) => void
  disabled?: boolean
  selected?: boolean
  identity?: any
}

interface DynamicTableProps {
  headers: HeaderOptions[]
  rows: IRow[]
  loading?: boolean
  fetching?: boolean
  bordered?: boolean
  sortBy?: string
  sortDirection?: SortDirection
  onSort?: (identifier: string, e?: any) => void
  onRowClick?: (r: IRow, e?: any) => void
  renderMore?: (r: IRow) => JSX.Element
  pagination?: TablePaginationProps
  onSelect?: (r: IRow, checked: any) => void
  onSelectAll?: (checked: any) => void
  getSelectDisabled?: (row: IRow) => boolean
  selectedRows?: Array<number | string>
  identity?: string
}

const Header: React.FC<HeaderProps> = ({
  header: c,
  sortBy,
  sortDirection,
  onSort,
}) => {
  const activeOrder = sortBy === c.identifier ? sortDirection : undefined
  const order =
    activeOrder === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC

  if (c.sortable) {
    return (
      <TableCell
        sortDirection={order}
        align="left"
        className={`table-header sortable ${c.headerClassName || ''}`.trim()}>
        <TableSortLabel
          active={!!activeOrder}
          direction={order}
          onClick={() => onSort && onSort(c.identifier || '')}>
          {c.label}
        </TableSortLabel>
      </TableCell>
    )
  } else {
    return (
      <TableCell
        align="left"
        className={`table-header ${c.headerClassName || ''}`.trim()}>
        {c.label}
      </TableCell>
    )
  }
}

const Row: React.FC<RowProps> = ({
  row: r,
  headers,
  onRowClick,
  renderMore,
  rowSize,
  onCheck,
  disabled,
  selected,
  identity,
}) => {
  const [open, setOpen] = useState(false)

  const renderNoData = (v: any) => (_.isNil(v) ? '- - -' : v)

  return (
    <>
      <TableRow
        className={`table-row ${!!onRowClick ? 'clickable' : ''}`}
        onClick={e => onRowClick && onRowClick(r, e)}>
        {onCheck && (
          <TableCell className="table-cell checkbox">
            <Checkbox
              id={`selectRowCheckbox_${identity}`}
              checked={selected}
              onClick={e => e.stopPropagation()}
              onChange={(_e, checked) => onCheck(r, checked)}
              disabled={disabled}
              color="primary"
            />
          </TableCell>
        )}
        {renderMore && (
          <TableCell className="table-cell more">
            <IconButton
              id={`renderMoreBtn_${identity}`}
              onClick={e => {
                e.stopPropagation()
                setOpen(!open)
              }}
              size="small"
              color="primary">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {_.map(headers, (c, i) => (
          <TableCell
            key={i}
            className={`table-cell ${c.cellClassName || ''}`.trim()}>
            {c.renderer
              ? renderNoData(c.renderer(r[c.accesor], r, identity))
              : renderNoData(r[c.accesor])}
          </TableCell>
        ))}
      </TableRow>
      {!!renderMore && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={rowSize}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {renderMore(r)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  headers,
  rows: allRows,
  loading,
  fetching,
  bordered,
  sortBy,
  sortDirection,
  onSort,
  onRowClick,
  renderMore,
  pagination,
  onSelect,
  onSelectAll,
  getSelectDisabled,
  selectedRows = [],
  identity = 'id',
  ...props
}) => {
  const intl = useIntl()

  let allItems = pagination ? pagination.count : 0

  let rowSize = headers.length
  !!renderMore && rowSize++
  !!onSelect && rowSize++

  const rows: IRow[] = allRows

  const renderHeaders = (c: HeaderOptions, i: number) => {
    let h = c
    if (headers.length - 1 === i && fetching && !loading) {
      return (
        <TableCell key="more" className="table-header more">
          <CircularProgress size={16} color="primary" />
        </TableCell>
      )
    }
    return (
      <Header
        key={i}
        header={h}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
      />
    )
  }

  const renderRows = (r: IRow, i: number) => (
    <Row
      key={i}
      row={r}
      headers={headers}
      onRowClick={onRowClick}
      renderMore={renderMore}
      rowSize={rowSize}
      onCheck={onSelect}
      disabled={getSelectDisabled ? getSelectDisabled(r) : false}
      selected={selectedRows.indexOf(r[identity]) > -1}
      identity={i}
    />
  )

  return (
    <div
      className={`component-dynamic-table ${bordered ? 'with-borders' : ''}`.trim()}>
      <Table>
        <TableHead>
          <TableRow>
            {(onSelect || onSelectAll) && (
              <TableCell key="checkbox" className="table-header checkbox">
                {onSelectAll && (
                  <Checkbox
                    id="selectAllCheckbox"
                    indeterminate={
                      selectedRows.length > 0 &&
                      (selectedRows.length < allRows.length ||
                        selectedRows.length < allItems)
                    }
                    checked={
                      selectedRows.length > 0 &&
                      (selectedRows.length === allRows.length ||
                        selectedRows.length === allItems)
                    }
                    disabled={loading || rows.length === 0}
                    onClick={e => e.stopPropagation()}
                    onChange={(e, checked) => {
                      e.stopPropagation()
                      onSelectAll(checked)
                    }}
                    color="primary"
                  />
                )}
              </TableCell>
            )}
            {!!renderMore && <TableCell className="table-header more" />}
            {_.map(headers, renderHeaders)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && !loading && _.map(rows, renderRows)}
          {rows.length === 0 && !loading && (
            <TableRow style={{ height: 150 }}>
              <TableCell
                colSpan={rowSize}
                className="table-cell cell-no-results"
                align="center">
                <h3>{intl.formatMessage({ id: 'general.no_results' })}</h3>
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow style={{ height: 150 }}>
              <TableCell
                colSpan={rowSize}
                className="table-cell cell-loading"
                align="center">
                <CircularProgress size={48} color="primary" />
                <h3>{intl.formatMessage({ id: 'general.loading' })}</h3>
              </TableCell>
            </TableRow>
          )}
          {pagination && (
            <TableRow>
              <TablePagination {...pagination} colSpan={rowSize} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default DynamicTable
