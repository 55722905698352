/* eslint-disable react-hooks/exhaustive-deps */
import { FC, Fragment, useEffect, useState } from 'react'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Button } from '@material-ui/core'
import { months } from 'moment'

interface DateRangeSelectorProps {
  defaultDate?: Date
  onChange: (date: Date) => void
}

const DateRangeSelector: FC<DateRangeSelectorProps> = (
  props: DateRangeSelectorProps
) => {
  const { defaultDate, onChange } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())

  useEffect(() => {
    defaultDate && setDate(defaultDate)
  }, [defaultDate])

  const handleDateChange = (
    _date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    if (_date) {
      setDate(_date.toDate())
      onChange(_date.toDate())
    }
  }

  const formattedDate = `${months()[date.getMonth()]} ${date.getFullYear()}`

  const toggle = () => setIsOpen(!isOpen)

  // TODO: agregar validacion de dos años atras

  return (
    <Fragment>
      <Button
        onClick={toggle}
        endIcon={<ArrowDropDown style={{ marginLeft: 8, fontSize: 24 }} />}
        style={{
          fontSize: 22,
          fontWeight: 400,
          color: '#000',
          textTransform: 'capitalize',
          marginLeft: '-10px',
        }}>
        {formattedDate}
      </Button>
      <KeyboardDatePicker
        open={isOpen}
        value={date}
        maxDate={new Date()}
        onClose={toggle}
        onChange={handleDateChange}
        views={['year', 'month']}
        keyboardIcon={() => null}
        TextFieldComponent={() => null}
        autoOk
        showTodayButton
      />
    </Fragment>
  )
}

export default DateRangeSelector
