import { UploadedFile } from 'models/general'
import { IAccountingStepFiveCategoryFileStatus } from 'models/accounting.models'
import { formatDate, formatName } from 'lib/utils/Accounting/accounting.utils'
import { useAccountingContext } from 'containers/Accounting/AccountingContainer/AccountingContainer'
import { FileCardAlt } from 'components/Shared/FileCard/FileCard'
import FileUploader from 'components/FormComponents/FileUploader/FileUploader'
import AccountingFileDropzoneContentMultiple from '../AccountingFileDropzoneContentMultiple/AccountingFileDropzoneContentMultiple'
import { useAccountingStepFiveContext } from '../AccountingStepFive/AccountingStepFive'
import './styles.scss'
import ISydDocument from 'models/shared.models'

export interface IAccountingFileDropzoneMultipleProps {
  label: string
  disabled: boolean
  totalUploadItems: ISydDocument[]
  uploadedFiles: ISydDocument[]
  fileType?: string
  fileStatuses?: IAccountingStepFiveCategoryFileStatus[]
  fileStatusesKey?: string
  uploadingFile?: UploadedFile
  filesToValidate?: ISydDocument[]
  additionalValidFiles?: ISydDocument[]
  additionalInvalidFiles?: ISydDocument[]
  uploadedAndValidatedFiles?: ISydDocument[]
  className?: string
}

const AccountingFileDropzoneMultiple = ({
  label,
  disabled,
  totalUploadItems,
  uploadedFiles,
  uploadedAndValidatedFiles,
  fileType,
  fileStatuses,
  fileStatusesKey,
  uploadingFile,
  filesToValidate,
  additionalValidFiles,
  additionalInvalidFiles,
  className,
}: IAccountingFileDropzoneMultipleProps) => {
  const { handleFileRetryUpload } = useAccountingContext()

  const { fileUploadHandler } = useAccountingStepFiveContext()

  return (
    <article
      className={`accounting-file-dropzone-multiple-component ${className ?? ''}`}>
      <p>{label}</p>
      <div className="initial-upload">
        <FileUploader
          className="file-zone"
          color="secondary"
          label="Subir archivo"
          onValue={fileUploadHandler(
            totalUploadItems,
            additionalValidFiles,
            additionalInvalidFiles
          )}
          type={fileType}
          disabled={disabled}
        />
        {!!uploadingFile && (
          <FileCardAlt
            title={formatName(uploadingFile)}
            subtitle={formatDate()}
            type={'File'}
            actions={[
              {
                label: 'Reintentar',
                action: () => handleFileRetryUpload(uploadingFile),
              },
            ]}
            error={uploadingFile.error}
            loading={uploadingFile.activity}
            className="file-zone"
          />
        )}
      </div>

      <div className="upload-items">
        {totalUploadItems.length > 0 &&
          totalUploadItems.map(({ idWsfile }) => (
            <AccountingFileDropzoneContentMultiple
              key={`accounting-file-dropzone-content-${idWsfile}`}
              uploadItemId={idWsfile}
              uploadedFiles={uploadedFiles}
              fileStatuses={fileStatuses}
              fileStatusesKey={fileStatusesKey}
              filesToValidate={filesToValidate}
              uploadedAndValidatedFiles={uploadedAndValidatedFiles}
            />
          ))}
      </div>
    </article>
  )
}

export default AccountingFileDropzoneMultiple
