import { useSelector } from 'react-redux'
import { GridColDef } from '@material-ui/data-grid'
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import { Card, IconButton } from '@mui/material'
import { selectCategoriesList } from 'lib/helpers/selectors'
import useCategoriesList, { useDeleteCategory } from 'hooks/queries/categories'
import SydTable from 'components/Shared/SydTable/SydTable'
import './styles.scss'
import Category from 'models/category'

interface ICategoriesTableProps {
  workspaceId: number
  onRedirect: (action: string, id?: number) => void
}

const CategoriesTable = ({
  workspaceId,
  onRedirect,
  ...props
}: ICategoriesTableProps) => {
  const categories = useSelector(selectCategoriesList)

  const categoriesQuery = useCategoriesList(workspaceId, {
    onError: () => onRedirect('error'),
  })

  const { isLoading, isFetching } = categoriesQuery

  const deleteMutation = useDeleteCategory(workspaceId, {
    onSuccess: () => categoriesQuery.refetch(),
  })

  const categoriesTableColumns: GridColDef[] = [
    {
      field: 'idCategory',
      headerName: 'ID',
      width: 100,
      sortable: false,
      renderCell: ({ row: { idCategory } }) => <>{idCategory}</>,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { name } }) => (
        <div title={name} className="ellipsis">
          {name}
        </div>
      ),
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 300,
      flex: 5,
      sortable: false,
      renderCell: ({ row: { description } }) => (
        <div title={description} className="ellipsis">
          {description}
        </div>
      ),
    },
    {
      field: '',
      headerName: '',
      width: 100,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <div className="actions">
          {' '}
          <IconButton
            id={`editCategotyBtn_${row.idCategory}`}
            size="small"
            color="primary"
            onClick={e => {
              e.stopPropagation()
              onRedirect('edit-cat', row.idCategory)
            }}>
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            id={`deleteCategotyBtn_${row.idCategory}`}
            size="small"
            color="primary"
            onClick={e => {
              e.stopPropagation()
              deleteMutation.mutate(row as Category)
            }}>
            <Delete fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ]

  const categoryRowsWithId = categories.map(cat => ({
    ...cat,
    id: cat.idCategory,
  }))

  return (
    <Card elevation={1} className="categories-table-component">
      <SydTable
        columns={categoriesTableColumns}
        rows={categoryRowsWithId}
        rowCount={categoryRowsWithId.length}
        isLoading={isLoading || isFetching}
        pagination
        noBorder
        checkboxSelection={false}
        disableColumnMenu
      />
    </Card>
  )
}

export default CategoriesTable
