import { useContext } from 'react'
import { LoaderContext } from 'providers/LoaderProvider/LoaderProvider'

const useLoader = () => {
  const loaderContext = useContext(LoaderContext)

  if (!loaderContext) {
    throw new Error('useLoader must be used within a LoaderProvider')
  }

  return loaderContext
}

export default useLoader
