import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import {
  IAccountingProcess,
  TAccountingStatusCatalogue,
} from 'models/accounting.models'
import {
  accountingStatusToAccountingStepMap,
  accountingSteps,
  getMappedSteps,
} from 'lib/utils/Accounting/accounting.utils'
import { getAccountingByDateService } from 'services/Accounting/accounting.services'
import Stepper from 'components/Shared/Stepper/Stepper'
import DashboardCard from 'components/Dashboard/DashboardCard/DashboardCard'
import './style.scss'

interface IAccountingStatusContainerProps {
  workspaceId: number
}

const AccountingStatusContainer = ({
  workspaceId,
}: IAccountingStatusContainerProps) => {
  const { data: process, ...query } = useQuery<IAccountingProcess | undefined>(
    ['get-accounting-status', workspaceId],
    () => {
      const today = moment().subtract(1, 'months')
      const year = today.year()
      const month = today.month() + 1
      return getAccountingByDateService(workspaceId, year, month)
    }
  )

  return (
    <DashboardCard
      className="accounting-status-container"
      title="Estatus de Cierre Mensual"
      titleTag="h3"
      fetching={query.isFetching}
      loading={query.isLoading}>
      <Stepper
        steps={getMappedSteps(
          accountingSteps,
          accountingStatusToAccountingStepMap[
            (process?.status.id ?? 1) as TAccountingStatusCatalogue
          ]
        )}
      />
    </DashboardCard>
  )
}

export default AccountingStatusContainer
