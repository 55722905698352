import Activity from 'components/Shared/Activity/Activity'
import useDialog from './useDialog'

const useLoadingAdditionalInformation = (): (() =>
  | Promise<boolean>
  | undefined) => {
  const dialog = useDialog()

  const fireDialog = () =>
    dialog.primary({
      title: 'Cargando información adicional',
      subtitle: 'Espera un momento',
      modalContent: (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Activity size={60} local />
        </div>
      ),
      hidePrimaryButton: true,
    })

  return fireDialog
}

export default useLoadingAdditionalInformation
