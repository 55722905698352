import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import useDialog from 'hooks/Shared/useDialog'
import useLoader from 'hooks/Shared/useLoader'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Activity from 'components/Shared/Activity/Activity'
import Button from 'components/Shared/Button/Button'
import Form from 'components/FormComponents/IOComponents/IOCustomForm/IOCustomForm'
import TextField from 'components/FormComponents/IOComponents/TextField/TextField'
import AutocompleteField from 'components/FormComponents/IOComponents/AutocompleteField/AutoCompleteField'
import FileField from 'components/FormComponents/IOComponents/FileField/FileField'

import {
  useEmisor,
  useEmisorFiles,
  useUpdateEmisor,
} from 'hooks/queries/emisores'

import { setSelectedRazonSocial } from 'actions/razonesSociales'
import {
  selectCatalogue,
  selectSelectedRazonSocial,
} from 'lib/helpers/selectors'

import { getPersonType } from 'lib/helpers/utilities'

import { TaskAlt } from 'components/Shared/CustomIcons'
import './style.scss'

import { File, Persona } from 'models/general'
import { RazonSocialForm } from 'models/razonSocial'
import { CatalogueEntry } from 'models/catalogues'

interface Props {
  id: number
  workspaceId: number
  onRedirect: (action: string) => void
}

const EditRazonSocialFormContainer: React.FC<Props> = ({
  id,
  workspaceId,
  onRedirect,
  ...props
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const dialog = useDialog()
  const loader = useLoader()

  const taxSystemFisica = useSelector(selectCatalogue('regimenesFisica'))
  const taxSystemMoral = useSelector(selectCatalogue('regimenesMoral'))
  const emisor = useSelector(selectSelectedRazonSocial) || undefined

  const [valid, setValid] = useState(false)
  const [fielCer, setFielCer] = useState<File>()
  const [fielKey, setFielKey] = useState<File>()
  const [csdCer, setCsdCer] = useState<File>()
  const [csdKey, setCsdKey] = useState<File>()
  const [csdPass, setCsdPass] = useState('')
  const [ciec, setCiec] = useState('')

  const infoQuery = useEmisor(workspaceId, id, {
    onError: () => onRedirect('error'),
  })
  const filesQuery = useEmisorFiles(workspaceId, emisor, {
    enabled: !_.isNil(emisor),
    refreshOn: [emisor?.idRfc],
  })

  const updateMutation = useUpdateEmisor(workspaceId, emisor, {
    onSuccess: async () => {
      infoQuery.refetch()
      await dialog.primaryAsync({
        title: '¡Listo!',
        subtitle: 'Hemos actualizado la información del emisor.',
        icon: TaskAlt,
        hidePrimaryButton: true,
        secondaryButtonText: 'Cerrar',
      })
    },
  })

  useEffect(() => {
    infoQuery.isLoading
      ? loader.primary('Cargando información del emisor')
      : loader.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoQuery.isLoading])
  useEffect(
    () => () => {
      dispatch(setSelectedRazonSocial())
      loader.close()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    const _fielCer = _.find(filesQuery?.data, f => f.type === 'cerFiel')
    const _fielKey = _.find(filesQuery?.data, f => f.type === 'keyFiel')
    const _csdCer = _.find(filesQuery?.data, f => f.type === 'cerCsd')
    const _csdKey = _.find(filesQuery?.data, f => f.type === 'keyCsd')
    setFielCer(_fielCer)
    setFielKey(_fielKey)
    setCsdCer(_csdCer)
    setCsdKey(_csdKey)
    setCsdPass(emisor?.passwordCsd ?? '')
    setCiec(emisor?.ciec ?? '')
  }, [emisor, filesQuery.data])

  return (
    <>
      <Card elevation={1} className="container-edit-razon-social">
        <Form
          onSubmit={async (form: RazonSocialForm) =>
            updateMutation.mutate(form)
          }
          //onSubmit={async (form) => console.log(form)}
          onValidity={setValid}>
          <div className="content">
            <p>
              Puedes editar la siguiente información para actualizar este emisor
            </p>
            {infoQuery.isFetching && <Activity size={22} local />}
          </div>
          <Divider />
          <div className="content">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <p className="section-header">Información general</p>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      name="type"
                      label="Tipo"
                      defaultValue={emisor?.type.label}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ readOnly: true }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="name"
                      fieldType="letters"
                      label="Nombre completo"
                      defaultValue={emisor?.name}
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="rfc"
                      label="RFC"
                      validate={
                        getPersonType(emisor?.type.idCatalogValues) ===
                        Persona.moral
                          ? ['isCompanyRFC']
                          : ['isPersonRFC']
                      }
                      defaultValue={emisor?.rfc}
                      inputProps={{
                        maxLength:
                          getPersonType(emisor?.type.idCatalogValues) ===
                          Persona.moral
                            ? 12
                            : 13,
                        style: { textTransform: 'uppercase' },
                        readOnly: true,
                      }}
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <AutocompleteField
                      name="taxSystem"
                      label={intl.formatMessage({
                        id: `emisor.create.form.tax_system`,
                      })}
                      catalogue={
                        getPersonType(emisor?.type.idCatalogValues) ===
                        Persona.moral
                          ? taxSystemMoral
                          : taxSystemFisica
                      }
                      getOptionSelected={(
                        option: CatalogueEntry,
                        value: CatalogueEntry
                      ) => option.idCatalogValues === value.idCatalogValues}
                      defaultValue={emisor?.taxResidence}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fieldType="number"
                      name="zipCode"
                      label={intl.formatMessage({ id: `form.zip_code` })}
                      validate={['isZipCode']}
                      defaultValue={
                        emisor?.zipCode
                          ? _.padStart(`${emisor?.zipCode}`, 5, '0')
                          : undefined
                      }
                      inputProps={{ maxLength: 5 }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="taxAddress"
                      label="Domicilio fiscal (opcional)"
                      inputProps={{ maxLength: 150, readOnly: true }}
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <p className="section-header">
                  Información de la e.Firma (FIEL)
                </p>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FileField
                      name="fielCer"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.CER', more: '' }
                      )}
                      accept=".cer"
                      value={fielCer}
                      onValue={(v: any) => setFielCer(v)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileField
                      name="fielKey"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.KEY', more: '' }
                      )}
                      accept=".key"
                      value={fielKey}
                      onValue={(v: any) => setFielKey(v)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="passwordFiel"
                      validate={['isAtLeastEightCharacters']}
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.fiel`,
                      })}
                      defaultValue={emisor?.passwordFiel}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <p className="section-header">
                  {_.isNil(csdCer) &&
                  _.isNil(csdKey) &&
                  !Boolean(csdPass) &&
                  !Boolean(ciec)
                    ? 'Información de la CSD (opcional)'
                    : 'Información de la CSD'}
                </p>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FileField
                      name="csdCer"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.CER', more: '' }
                      )}
                      accept=".cer"
                      value={csdCer}
                      onValue={(v: any) => setCsdCer(v)}
                      required={
                        !_.isNil(csdKey) || Boolean(csdPass) || Boolean(ciec)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileField
                      name="csdKey"
                      label={intl.formatMessage(
                        { id: 'form.file.label' },
                        { extension: '.KEY', more: '' }
                      )}
                      accept=".key"
                      value={csdKey}
                      onValue={(v: any) => setCsdKey(v)}
                      required={
                        !_.isNil(csdCer) || Boolean(csdPass) || Boolean(ciec)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="passwordCsd"
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.csd`,
                      })}
                      defaultValue={emisor?.passwordCsd}
                      onValue={(f: string) => setCsdPass(f)}
                      fullWidth
                      required={
                        !_.isNil(csdCer) || !_.isNil(csdKey) || Boolean(ciec)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fieldType="password"
                      name="ciec"
                      label={intl.formatMessage({
                        id: `emisor.create.form.password.ciec`,
                      })}
                      defaultValue={emisor?.ciec}
                      onValue={(f: string) => setCiec(f)}
                      fullWidth
                      required={
                        !_.isNil(csdCer) || !_.isNil(csdKey) || Boolean(csdPass)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="actions">
            <Button
              id="updateBusinessNameBtn"
              variant="contained"
              type="submit"
              color="secondary"
              activity={updateMutation.isLoading}
              disabled={!valid}>
              Actualizar
            </Button>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default EditRazonSocialFormContainer
